<template>
  <div
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <swiper
      id="REMIXSwiper"
      :modules="[FreeMode,Mousewheel,Autoplay]"
      slides-per-view="auto"
      :space-between="12"
      :auto-height="true"
      class="tw-py-10"
      :breakpoints="{
        680:{
          spaceBetween:20,
        },
      }"
      :free-mode="{
        enabled:true,
        momentum:false,
      }"
      :autoplay="{
        disableOnInteraction:false,
        pauseOnMouseEnter:false,
        waitForTransition:true,
        delay:0
      }"
      :speed="5000"
      loop
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(event,index) in events"
        :key="index"
        :class="[
          'tw-rounded-md tw-bg-white',
          'tw-h-[260px] sgTablet2:tw-h-[310px] sgDesktop:tw-h-[314px]',
          'tw-w-[164px] sgTablet:tw-w-[260px]',
        ]"
      >
        <remix-event-card
          :event="event"
        />
      </swiper-slide>
    </swiper>
    <div class="tw-flex tw-justify-center">
      <a
        :class="[
          'hover:tw-bg-[#33DED1]',
          'active:tw-text-white active:tw-bg-[#00897E]',
          'catBtn tw-rounded-[32px] tw-bg-[#00D6C6]  tw-py-[18px] tw-uppercase tw-h-[52px] tw-w-[175px] tw-flex tw-justify-center tw-items-center tw-text-center',
          { 'tw-font-akkuratbold tw-text-sg-remix-bt1' : checkLang() == 'en' },
          { 'tw-font-notosansbold tw-text-sg-remix-bt1-zh' : checkLang() != 'en' },
        ]"
        :href="goToEventsPage()"
        target="_self"
      >
        {{ t('remix_swiper_main_btn') }}
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade } from 'swiper/modules';
import {FreeMode,Mousewheel,Autoplay} from 'swiper/modules';

import SwiperCore from 'swiper/core';
import { onBeforeMount,ref } from 'vue';
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import {  RemixEvent } from '../../../types/types';
import { checkLang } from '../../../support/helper';

let events = ref<Array<RemixEvent>>([]);
let swiperObj = ref();
const { t } = useI18n({ useScope: 'global' });
SwiperCore.use([EffectFade]);
SwiperCore.use([Mousewheel]);
SwiperCore.use([FreeMode]);
SwiperCore.use([Autoplay]);

const onSwiper = (swiper:any) => {
  swiperObj.value = swiper;
};
const onMouseEnter = () => {
  swiperObj.value?.autoplay.stop();
};
const onMouseLeave = () => {
  swiperObj.value?.autoplay.start();
};

function getFeaturedEvents() {

  let currentDomain = process.env.WP_SITEURL;

  if (currentDomain === undefined) {
    return;
  }

  const lang = checkLang();

  let url = currentDomain + '/wp-json/wp/v2/events?per_page=12&page=1&featured=1&category=remix-event' + '&lang=' + lang;

  axios.get(url)
    .then((response) => {
      let data: Array<any> = response.data.data;

      events.value = [];

      for (let event of data) {
        events.value.push({
          id: event.ID,
          title: event.post_title,
          date: event.post_date,
          content: event.content.rendered,
          permalink: event.link,
          acf: {
            event_date: event.acf.event_date,
            event_card_date: event.acf.event_card_date,
            event_featured: event.acf.event_featured,
            event_location: event.acf.event_location,
            event_present_by: event.acf.event_present_by,
            event_ticket_price: event.acf.event_ticket_price,
            event_type: event.acf.event_type,
            event_visit_page_link: event.acf.event_visit_page_link,
            event_gallery: event.acf.event_gallery ?? [],
            event_is_paid_event: event.acf.event_is_paid_event,
            event_status_free: event.acf.event_status_free,
            event_status_paid: event.acf.event_status_paid,
            event_expire_date: event.acf.event_expire_date
          }
        });
      }

      let defaultCount = 12;
      if(window.innerWidth >= 1440){
        defaultCount = 48;
      }
      const neededCount = events.value.length > defaultCount ?  events.value : defaultCount;

      const copyCount = Math.floor(neededCount / events.value.length);

      events.value = Array.from({length:copyCount}).flatMap(()=> events.value);

      swiperObj.value?.autoplay.start();
    })
    .catch((error) => {
      console.error(error);
    });
}

function getEventTypeText(id:string) {
  let text = '';

  switch (id) {
    case '0':
      text = t('event.type_concert');
      break;
    case '1':
      text = t('event.type_theatre');
      break;
    case '2':
      text = t('event.type_exhibition');
      break;
    default:
      text = '';
      break;
  }

  return text;

}

function goToEventsPage() {
  return checkLang() != 'zh-hans' ? process.env.WP_SITEURL + '/experience/remix/events' : process.env.WP_SITEURL + '/experience/remix/events/?lang=zh-hans';
}

onBeforeMount(() => {
  getFeaturedEvents();
});

</script>
<style>
@media (min-width: 1440px) {
  #REMIXSwiper .swiper-slide{
    width:260px!important;
  }
}
#REMIXSwiper .swiper-slide{
  box-shadow: 0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A;
}

#REMIXSwiper .swiper-slide:hover .swiper-slide-desc{
  background-color: rgba(0, 0, 0, 0.06);
}

#REMIXSwiper .swiper-slide:hover  .swiper-btn-text{
  text-decoration: underline;
}
/*
.catBtn:hover{
  background-color: #49B194;
}

.catBtn:active{
  background-color: #12654E;
} */

#REMIXSwiper .swiper-wrapper{
  transition-timing-function: linear !important;
}
</style>
