<template>
  <div
    class="tw-w-[100vw] tw-relative tw-overflow-visible"
    :style="{ height: swiperContainerHeight + 'px' }"
  >
    <swiper
      id="activity-swiper"
      :slides-per-view="'auto'"
      :slides-offset-before="16"
      :center-insufficient-slides="true"
      style="height: 410px;"
      @swiper="onSwiper"
    >
      <swiper-slide>
        <zy-image-card
          :style="transformStyle"
          image="images/zy2024/zhongyuan-101/activities/activity-1.jpg"
          bg-color="#88D8DF"
          :height="checkLang() == 'en' ? '358px' : '338px'"
        >
          <p>{{ $t('zy_zhongyuan_101_activities_activity_1_caption') }}
            <sup
              class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-tw-text-sg-ref-desktop-body tw-font-akkurat"
              @click="handleOpenImageSourceModal"
            >[A]</sup>
          </p>
        </zy-image-card>
      </swiper-slide>
      <swiper-slide>
        <zy-image-card
          style="transform: translateX(-8px);"
          image="images/zy2024/zhongyuan-101/activities/activity-2.jpg"
          bg-color="#6BDE99"
          height="338px"
        >
          <p>{{ $t('zy_zhongyuan_101_activities_activity_2_caption') }}
            <sup
              class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-tw-text-sg-ref-desktop-body tw-font-akkurat"
              @click="handleOpenImageSourceModal"
            >[B]</sup>
          </p>
        </zy-image-card>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref, computed } from 'vue';
import { checkLang } from '../../support/helper';

const emit = defineEmits(['update:imageSourceOpen']);

const swiperContainerHeight = ref(350);

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const transformStyle = computed(() => {
  const translateY = checkLang() === 'en' ? 42 : 62; // 42px or 62px
  return `transform: translateY(${translateY}px);`;
});

const props = defineProps<{
  imageSourceOpen: boolean,
}>();


const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

</script>

<style scoped lang="scss">
.swiper-wrapper {
  height: 453px !important;
}

.swiper-slide {
  width: 352px;
  height: 400px;
}
</style>
