<template>
  <div>
    <ap-top-nav-bar
      class="tw-z-50"
      :is-transparent="true"
      :is-hidden="false"
    />
    <div class="tw-relative">

      <div class="tw-h-full">

        <!-- Header -->
        <div
          ref="header"
          class="headerCustom"
        >
          <!-- Inner -->
          <div
            ref="inner"
            class="inner"
          />
        </div>
      </div>
      <div
        class="tw-absolute tw-flex tw-flex-col tw-inset-0 tw-justify-center tw-items-start tw-max-h-[100vh] tw-max-w-[100vw] tw-px-4 tablet:tw-px-10 desktop:tw-px-20"
      >
        <h1
          id="ceMainTitle"
          class="tw-flex tw-flex-row tw-justify-start tw-mb-2 tw-text-white tw-max-w-[300px]
          tw-text-sg-ce-h1-mobile tablet:tw-text-sg-ce-h1-tablet
          desktop:tw-text-sg-ce-h1
          tw-w-full md:tw-max-w-[640px]
          tw-whitespace-pre-line"
        >
          {{ $t('cultural_extravaganza_main_title') }}
        </h1>

      </div>
      <div
        id="section-id2"
        ref="section2"
      >
        <div>

          <ce-videos
            v-show="props.wordpressData.ce_about_video_url !== ''"
            class="tw-h-[100vh]"
            :video-url="props.wordpressData.ce_about_video_url"
            :poster-url="getImageURL(props.wordpressData.ce_about_video_thumbnail)"
          />

          <!-- Section 1 -->
          <ce-text-layout
            :title="wordpressData.ce_about_paragraph_title_1"
            :text="wordpressData.ce_about_paragraph_1"
            div-id="div-section-1"
          />
          <!-- Section 2 -->
          <ce-text-layout
            :title="wordpressData.ce_about_paragraph_title_2"
            :text="wordpressData.ce_about_paragraph_2"
            div-id="div-section-2"
          />

        </div>

      </div>
      <!-- Events Swiper -->
      <div class=" tw-bg-white tw-relative">
        <h2 class="tw-text-sg-h2-mobile tablet:tw-text-sg-h2-tablet desktop:tw-text-sg-h2 tw-text-center  tw-pt-5 tablet:tw-pt-10 tw-uppercase tw-px-4">
          {{ $t('ce_about_subheader_1') }}
        </h2>
        <ce-swiper />
        <h2
          v-show="props.wordpressData.ce_about_merchandise_giveaway_image !== 'false' && props.wordpressData.ce_about_merchandise_giveaway_image !== 'null' "
          class="tw-text-sg-h2-mobile tablet:tw-text-sg-h2-tablet desktop:tw-text-sg-h2 tw-text-center tw-pt-5 tablet:tw-pt-10 tw-uppercase tw-px-4"
        >
          {{ $t('ce_about_subheader_2') }}
        </h2>
        <div
          v-show="props.wordpressData.ce_about_merchandise_giveaway_image !== 'false' && props.wordpressData.ce_about_merchandise_giveaway_image !== 'null' "
          class="tw-pt-5 tablet:tw-pt-10 "
        >
          <div class="tw-flex tw-justify-center">
            <div
              class="merchandiseImage tw-mx-4 tablet:tw-mx-10 desktop:tw-mx-[80px] tw-aspect-video tw-h-[198px] sgTablet:tw-h-[340px] sgTablet2:tw-h-[425px] sgDesktop:tw-h-[555px] sgDesktop2:tw-h-[720px] tw-bg-cover tw-relative"
              :style="{backgroundImage: `url(${getImageURL(wordpressData.ce_about_merchandise_giveaway_image)})`}"
            >
              <div
                class="enlargeBtn tw-w-11 tw-h-11 tw-rounded-lg tw-bg-[#48484880] tw-absolute tw-right-0 tw-mr-5 tw-mt-5 tw-p-2 tw-cursor-pointer tw-z-10"
                @click="openImageModal()"
              >
                <img
                  class="tw-w-7 tw-h-7 "
                  :src="$cdn('/images/experience/ce/open_in_full_icon.png')"
                >
              </div>
            </div>
          </div>
          <!-- Merchandice Giveaway Description -->
          <div class="tw-pt-5 tw-px-4 tablet:tw-pt-10 tw-flex tw-justify-center">
            <div class="tablet:tw-max-w-[800px] tw-text-center">
              <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-text-[#484848]">
                {{ wordpressData.ce_about_merchandise_giveaway_description }}
              </p>
            </div>
          </div>

        </div>
        <!-- Social Buttons -->
        <div class="tw-pt-5 tw-pb-[104px] tablet:tw-pb-[144px] tablet:tw-pt-10 tw-flex tw-justify-center">
          <ce-social-btn />
        </div>
      </div>
      <ce-navigation-bar />

    </div>
    <ce-partner-section />
    <ap-footer
      id="exhibition-footer"
      class=" tw-bg-white tw-relative"
    />
    <ce-image-modal
      :open-modal="openModal"
      :image-src="getImageURL(wordpressData.ce_about_merchandise_giveaway_image)"
      @close-modal="closeModal"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, onBeforeUnmount, nextTick } from 'vue';

let openModal = ref(false);

const props = defineProps({
  // part:{
  //   type:Number,
  //   required:true,
  // },
  wordpressData: {
    type: Object,
    default: null,
  },
  reinitGsap: {
    type: Function,
    default: undefined,
  }
});

const inner: Ref<HTMLElement | null> = ref(null);

const handleScroll = () => {
  if (inner.value) {
    const delayedOffsetY = Math.max(0, window.pageYOffset);
    let value = delayedOffsetY / 8000 + 1;
    inner.value.style.transform = `scale(${value})`;
  }
};

onMounted(async () => {

  console.log('wordpressData', props.wordpressData);

  if (inner.value) {
    const headerImage = JSON.parse(props.wordpressData.ce_about_header_image);
    inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(${headerImage.url})`;
    await nextTick();
  }
  window.addEventListener('scroll', handleScroll);

  //workaround for ios tablet issue on rendering text shadow
  if(window.innerWidth >= 728 && window.innerWidth < 1280) {
    const ceMainTitle = document.getElementById('ceMainTitle');
    if (ceMainTitle) {
      ceMainTitle.style.textShadow = '-4px 4px 50px rgba(0, 0, 0, 0.3)';
    }
  }
});


onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

function getImageURL(obj: string) {

  if (obj === 'false' || obj === 'null') {
    return '';
  }

  try {
    return JSON.parse(obj).url;
  } catch (e) {
    return '';
  }

  const imgObj = JSON.parse(obj);
  return imgObj.url;
}

function openImageModal(){
  openModal.value = true;
}

function closeModal(){
  openModal.value = false;
}
</script>

<style scoped>
#ceMainTitle{
  text-shadow:  -4px 4px 10px rgba(0, 0, 0, 0.3);

}
.headerCustom {
  position: relative;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
}
.inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.75)), url(https://picsum.photos/1200?random=2) ;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.merchandiseImage{
  box-shadow: 0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A;

}

.enlargeBtn:hover{
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 768px) {
  .inner {
    max-height: 100lvh;
  }
}

@media screen and (max-width: 500px) {
  .inner {
    background-position: center center;
  }
}
</style>
