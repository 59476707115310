<template>
  <div
    class="tw-relative"
    :class="[
      'tw-h-[540px] sgTablet:tw-h-[604px]',
      'tw-my-4 tw-rounded-[12px] sgTablet:tw-rounded-[20px]'
    ]"
    :style="[
      `box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.30)`,
    ]"
  >
    <!-- Content -->
    <div class="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-p-5">
      <div class="iframe-container">
        <iframe
          class="iframe-content"
          :src="getEmbedUrl(data.youtube_facebook_embed_link)"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <section class="tw-flex tw-flex-col tw-px-3">
        <div
          :class="[
            'tw-text-sg-ws-h4-mobile sgTablet:tw-text-sg-ws-h4-tablet sgDesktop:tw-text-sg-ws-h4-desktop tw-text-sg-ws24-maroon',
            'tw-w-full tw-max-w-[800px] tw-pb-2',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ data.title }}
        </div>
        <div
          id="sup-handler-container"
          class="tw-text-sg-ws-body"
          v-html="data.description"
        />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { checkLang } from '../../support/helper';
interface Item {
  youtube_facebook_embed_link: string;
  title: string;
  description: string;
}

defineProps<{
  data: Item;
  cardWidth: string;
}>();

function getEmbedUrl(url: string): string {
  // Match standard YouTube URL
  const youtubeMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/);
  // Match shortened YouTube URL
  const youtubeShortMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
  // Match Facebook video URL with /videos/
  const facebookMatch = url.match(/(?:https?:\/\/)?(?:www\.)?facebook\.com\/.*\/videos\/([0-9]+)/);
  // Match Facebook /watch/?v= format
  const facebookWatchMatch = url.match(/(?:https?:\/\/)?(?:www\.)?facebook\.com\/watch\/?\?v=([0-9]+)/);

  if (youtubeMatch) {
    return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
  }

  if (youtubeShortMatch) {
    return `https://www.youtube.com/embed/${youtubeShortMatch[1]}`;
  }

  if (facebookMatch) {
    return `https://www.facebook.com/plugins/video.php?href=https://www.facebook.com${facebookMatch[0]}`;
  }

  if (facebookWatchMatch) {
    return `https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/watch/?v=${facebookWatchMatch[1]}`;
  }

  return ''; // Return empty if no match is found
}

</script>

<style scoped>
.swiper-slide {
  width: 352px;
  height: 316px;
}

#sup-handler-container:deep(a) {
  text-decoration: underline;
  text-underline-offset: 2px;

  cursor: pointer;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
  margin-bottom: 20px; /* Adjust as needed */
}

.iframe-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

</style>
