<template>
  <Teleport to="body">
    <div
      v-if="props.openModal"
      id="modal"
      class="tw-fixed tw-flex tw-inset-0 tw-bg-ap-white-smoke tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[9999999]"
    >
      <!-- Modal Content -->
      <div class="tw-mx-4 sgTablet:tw-mx-10 sgDesktop:tw-mx-20">
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div
            class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
            @click="closeModal"
          >
            <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div class="tw-w-full tw-shadow-ap-preset-1">
          <!-- Image Box -->
          <div class="">
            <img
              class="tw-object-cover tw-h-full tw-w-full tw-object-center tw-max-h-[80vh]"
              :src="props.imageSrc"
            >
          </div>

        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { watch } from 'vue';

const props = defineProps({
  openModal: {
    type: Boolean,
    default: false
  },
  imageSrc:{
    type:String,
    default:'https://picsum.photos/1200?random=1'
  }
    
});

const emit = defineEmits(['closeModal']);

function closeModal(){
  emit('closeModal', false);
}

watch(() => props.openModal, changeBodyOverflow);

function changeBodyOverflow(){
  if(props.openModal){
    document.body.style.overflow = 'hidden';
  }else{
    document.body.style.overflow = 'auto';
  }
}

</script>