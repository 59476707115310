<template>
  <div
    :class="[
      'tw-relative tw-rounded-lg tw-bg-sg-ma24-orange-w40',
      'tw-h-[258px]',
      'tw-w-[164px] sgTablet:tw-w-[240px]',
      'tw-rounded-lg',
      'custom-box-shadow' // Custom box-shadow
    ]"
    style="box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);"
  >
    <!-- Content -->
    <div class="tw-absolute tw-inset-0 tw-flex tw-flex-col">
      <div
        :class="[
          'tw-relative',
          'tw-flex tw-items-center tw-h-[142px]'
        ]"
      >
        <img
          :class="[
            'tw-object-cover tw-h-full tw-w-full',
            'tw-max-h-[142px]',
            'tw-rounded-t-lg'
          ]"
          :src="image"
        >
        <!-- past event overlay -->
        <div
          v-if="pastEvent"
          :class="[
            'tw-absolute tw-top-0 tw-w-full tw-h-full',
            'tw-bg-black tw-bg-opacity-40',
            'tw-rounded-t-lg',
            'tw-flex tw-flex-col tw-items-center tw-justify-center',
          ]"
        >
          <p
            :class="[
              'tw-uppercase tw-text-sg-ma-sh1 tw-text-white',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_whats_open_past_event') }}
          </p>
        </div>
      </div>
      <div
        :class="[
          'tw-px-4 tw-py-5',
          'tw-pb-3',
          'tw-flex tw-flex-col tw-items-end',
          'tw-h-full'
        ]"
      >
        <div
          :class="[
            'tw-flex-grow',
            'tw-text-sg-ma-sh3',
            'tw-w-full ',
            { 'tw-h-10': checkLang() == 'en' },
            { 'tw-h-[72px]': checkLang() != 'en' },
          ]"
        >
          <div
            :class="[
              { 'tw-font-akkuratbold tw-h-10 tw-multiline-ellipsis-en' : checkLang() == 'en' },
              { 'tw-font-notosansbold tw-h-[60px] tw-multiline-ellipsis-cn' : checkLang() != 'en' },
            ]"
          >{{ label }}</div>
        </div>

        <!-- find out more button -->
        <button
          :class="[
            'tw-flex tw-gap-2 tw-items-center',
            'tw-pr-2'
          ]"
          @click="emit('openModal')"
        >
          <div class="tw-uppercase tw-text-sg-ma-bt2">
            {{ $t('ma_whats_open_find_out_more') }}
          </div>
          <img :src="$cdn('icons/ma/ma_arrow_forward.svg')">
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { checkLang } from '../../support/helper';
const emit = defineEmits(['openModal']);
defineProps({
  image: {
    type: String,
    default: 'images/ma2024/whats-open/ma_placeholder.jpg'
  },
  label: {
    type: String,
    default: 'Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet'
  },
  bgColor: {
    type: String,
    default: '#F2B579'
  },
  pastEvent: {
    type: Boolean,
    default: false
  }
});
</script>

<style scoped>
.tw-multiline-ellipsis-en {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tw-multiline-ellipsis-cn {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


</style>
