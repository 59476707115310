<template>
  <div class="headerCustom">
    <div
      ref="inner"
      class="inner"
    />
    <div
      class="tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-left-0 tw-w-full tw-h-full tw-overflow-hidden tw-bg-fixed tw-flex tw-flex-row"
    >
      <div
        class="tw-flex tw-h-full tw-w-full tw-px-4 tw-pt-5
      tablet:tw-px-10 tablet:tw-py-10
      desktop:tw-px-20 desktop:tw-py-10 "
        :class="[isBuiltLeft ? 'tw-justify-start' : 'tw-justify-end']"
      >
        <div
          class="tw-flex tw-flex-row tw-min-w-[290px]
        tablet:tw-w-[504px]
        desktop:tw-w-[700px]"
        >
          <!-- Number in circle -->
          <ap-number-in-a-circle :number="instructionNo" />
          <div class="tw-pr-5 tablet:tw-pr-10 desktop:tw-pr-10" />
          <!-- Title, Content, Note -->
          <div>
            <p
              class="tw-text-white tw-text-sg-h3-mobile tw-pb-5 tw-pt-3
            tablet:tw-text-sg-h3-tablet tablet:tw-pb-10 tablet:tw-pt-0
            desktop:tw-text-sg-h3 desktop:tw-pb-[60px] desktop:tw-pt-0"
            >
              {{ instructionTitle }}
            </p>
            <p
              class="tw-whitespace-pre-line tw-text-white tw-text-sg-sh2-mobile
            tablet:tw-text-sg-sh2-tablet
            desktop:tw-text-sg-sh2"
              :class="{
                'tw-break-keep':checkLang()=='en'
              }"
              v-html="instructionContent"
            />
            <ul class="prohibited-behaviors">
              <li
                v-for="(behavior, index) in instructionContentList"
                :key="index"
                class=" tw-text-white tw-text-sg-sh2-mobile
            tablet:tw-text-sg-sh2-tablet
            desktop:tw-text-sg-sh2"
              >{{ behavior }}
              </li>
            </ul>
            <div class="tw-pb-5 tablet:tw-pb-10 desktop:tw-pb-[60px]" />
            <p
              class="tw-italic tw-text-white tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1"
              :class="{
                'tw-break-keep':checkLang()=='en'
              }"
            >
              {{ instructionBottomNote }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, toRefs } from 'vue';
import { checkLang } from '../../support/helper';
export default {
  props: {
    imageUrl: {
      type: String,
      default: 'https://picsum.photos/1200?random=1'
    },
    instructionNo: {
      type: Number,
      required: true,
      default: 0,
    },
    instructionTitle: {
      type: String,
      default: 'RESPECT THE EXHIBITION',
    },
    instructionContent: {
      type: String,
      default: 'To ensure the safety of visitors and the exhibition, the following behaviours are prohibited:',
    },
    instructionContentList: {
      type: Array,
      default: () => [],
    },
    instructionBottomNote: {
      type: String,
      default: 'The exhibition operates closed-circuit video monitoring for the safety of exhibits and visitors.',
    },
    isBuiltLeft: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const inner: Ref<HTMLElement | null> = ref(null);
    const {imageUrl} = toRefs(props);
    function getImageUrl(imageUrl: string) {
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(' + imageUrl + ')';
    }

    onMounted(() => {
      if (inner.value) {
        inner.value.style.backgroundImage = getImageUrl(imageUrl.value);
      }
    });

    return {
      inner,
      checkLang
    };
  }
};
</script>

<style scoped>
.headerCustom {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .sectionCustom {
    width: 100%;
    height: 1000px;
  }

.prohibited-behaviors {
  list-style-type: disc;
  padding-left: 20px; /* Adjust as needed for alignment */
}

p {
  text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
}
</style>
