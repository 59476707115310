<template>
  <div
    :class="{
      'tw-font-akkurat': checkLang() === 'en',
      'tw-font-notosans': checkLang() !== 'en'
    }"
  >
    <ap-top-nav-bar
      class="tw-z-50"
      :is-transparent="true"
      :is-hidden="false"
    />
    <div class="tw-relative">

      <div class="sgTablet2:tw-h-full tw-h-[100vh]">
        <!-- Header -->
        <div
          ref="header"
          class="headerCustom"
        >
          <!-- inner_ce -->
          <div
            ref="inner_ce"
            class="inner_ce"
          />
        </div>
      </div>
      <div
        class="tw-absolute tw-flex tw-flex-col tw-inset-0 tw-items-start tw-max-h-[100vh] tw-max-w-[100vw] tw-px-4
        sgTablet:tw-px-20 desktop:tw-px-20 sgDesktop:tw-px-30 sgDesktop2:tw-px-30
        tw-justify-start sgDesktop:tw-justify-center
        tw-pt-25 sgDesktop:tw-pt-0"
      >
        <h1
          id="ceMainTitle"
          class="tw-flex tw-flex-row tw-justify-start tw-mb-2 tw-text-white tw-max-w-[300px]
          tw-text-sg-ce-kv-title-mobile sgDesktop:tw-text-sg-ce-kv-title
          sgTablet:tw-text-sg-ce-kv-title-tablet
          tw-w-full md:tw-max-w-[640px]
          tw-whitespace-pre-line"
        >
          {{ $t('cultural_extravaganza_main_title') }}
        </h1>
      </div>
      <div
        id="section-id2"
        ref="section2"
      >
        <div>
          <ce-videos
            v-show="props.wordpressData.ce_about_video_url !== ''"
            class="tw-h-[100vh]"
            :video-url="props.wordpressData.ce_about_video_url"
            :poster-url="getImageURL(props.wordpressData.ce_about_video_thumbnail)"
          />
          <div class="tw-relative sgTablet2:tw-h-[90vh] tw-h-[100vh] tw-min-h-[660px]">
            <!-- Section 1 -->
            <ce-text-layout
              :title="wordpressData.ce_about_paragraph_title_1"
              :text="wordpressData.ce_about_paragraph_1"
              :is-text-left="true"
              div-id="div-section-1"
              class="tw-sticky tw-top-[25%] mobilePro:tw-top-[30%] sgTablet:tw-top-[35%]"
            />
          </div>
          <div class="tw-relative tw-h-[100vh] tw-min-h-[660px]">
            <!-- Section 2 -->
            <ce-text-layout
              :title="wordpressData.ce_about_paragraph_title_2"
              :text="wordpressData.ce_about_paragraph_2"
              :is-text-left="true"
              div-id="div-section-2"
              class="tw-sticky tw-top-[15%] mobilePro:tw-top-[25%] sgTablet:tw-top-[35%]"
              :show-button="true"
            />
          </div>
        </div>
      </div>
      <!-- May reuse in about page again after 2025 -->
      <!-- Events Swiper -->
      <!-- <CEEventSwiper :wordpressData="wordpressData" /> -->
      <ce-navigation-bar />
    </div>
    <ce-partner-section />
    <ap-footer
      id="exhibition-footer"
      class=" tw-bg-white tw-relative"
    />
    <ce-image-modal
      :open-modal="openModal"
      :image-src="getImageURL(wordpressData.ce_about_merchandise_giveaway_image)"
      @close-modal="closeModal"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { getSgScreenSize } from '../../../support/helper';
import { checkLang } from '../../../support/helper';

let openModal = ref(false);
const inner_ce: Ref<HTMLElement | null> = ref(null);

const props = defineProps({
  wordpressData: {
    type: Object,
    default: null,
  },
  reinitGsap: {
    type: Function,
    default: undefined,
  }
});

const handleScrollHeader = async () => {
  if (!inner_ce.value) return;

  const delayedOffsetY = Math.max(0, window.pageYOffset);
  let value = delayedOffsetY / 8000 + 1;
  inner_ce.value.style.transform = `scale(${value})`;

  let headerImage;
  const screenSize = getSgScreenSize();

  if (screenSize === 'desktop') {
    headerImage = JSON.parse(props.wordpressData.ce_about_header_image);
  } else if (screenSize === 'tablet') {
    headerImage = JSON.parse(props.wordpressData.ce_about_header_image_tablet);
  } else {
    headerImage = JSON.parse(props.wordpressData.ce_about_header_image_mobile);
  }

  // Apply dynamic gradient logic based on scroll
  let newGradient = ((value - 1) / (1.2 - 1)) * (0.75 - 0.5) + 0.5;
  let newGradient2 = ((value - 1) / 0.2) * 0.75;

  newGradient = Math.min(newGradient, 0.75);
  newGradient2 = Math.min(newGradient2, 0.75);

  const gradient = `linear-gradient(180deg, rgba(0, 0, 0, ${newGradient2}) 0%, rgba(0, 0, 0, ${newGradient}) 100%)`;
  inner_ce.value.style.backgroundImage = `${gradient}, url(${headerImage})`;

  await nextTick();
};

// Attach scroll event listener
window.addEventListener('scroll', handleScrollHeader);

onMounted(async () => {
  await handleScrollHeader(); // Call initially
  window.addEventListener('resize', handleScrollHeader); // Listen for resizes
  window.addEventListener('scroll', handleScrollHeader); // Listen for scroll events

  // Workaround for iOS tablet issue on rendering text shadow
  const screenSize = getSgScreenSize();
  if (screenSize === 'tablet') {
    const ceMainTitle = document.getElementById('ceMainTitle');
    if (ceMainTitle) {
      ceMainTitle.style.textShadow = '-4px 4px 50px rgba(0, 0, 0, 0.3)';
    }
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleScrollHeader); // Cleanup on unmount
  window.removeEventListener('scroll', handleScrollHeader);
});

function getImageURL(obj: string) {
  if (obj === 'false' || obj === 'null') {
    return '';
  }

  try {
    return JSON.parse(obj).url;
  } catch (e) {
    return '';
  }
}

function closeModal() {
  openModal.value = false;
}
</script>

<style scoped>
#ceMainTitle{
  text-shadow:  -4px 4px 10px rgba(0, 0, 0, 0.3);

}
.headerCustom {
  position: relative;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
}
.inner_ce {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.75)), url(https://picsum.photos/1200?random=2);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.merchandiseImage{
  box-shadow: 0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A;

}

.enlargeBtn:hover{
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 768px) {
  .inner_ce {
    max-height: 100lvh;
  }
}

@media screen and (max-width: 500px) {
  .inner_ce {
    background-position: center center;
  }
}
</style>
