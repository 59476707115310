<template>
  <h1 class="tw-text-3xl tw-bg-ap-red-orange">
    AP Awesome component
  </h1>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(() => {
  false && console.log('ap awesome mounted');
});
</script>
