<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <swiper
      id="burning_joss_card"
      ref="burningJossCardSwiper"
      :space-between="20"
      :pagination="{
        clickable:true,
        el:'.burning-joss-swiper-pagination'
      }"
      :slides-per-view="'auto'"
      :slides-per-group="1"
      class="burning_joss_card_swiper tw-w-full"
      centered-slides
      centered-slides-bounds
      :breakpoints="{
        680:{
          // centeredSlides:false,
          // centeredSlidesBounds:false,
        }
      }"
    >
      <swiper-slide
        v-for="(item, index) in props.data"
        :key="index"
        class="tw-max-w-[280px] sgTablet:tw-max-w-[320px] sgDesktop:tw-max-w-[800px] tw-relative"
      >
        <img
          v-if="item.is_singaporen_way"
          class="tw-absolute -tw-top-5 -tw-left-5 tw-w-[100px] tw-h-[72px]  tw-z-20"
          :src="$cdn(checkLang() !== 'zh-hans' ? 'icons/singaporen_way_stamp-4.png' : 'icons/singaporen_way_stamp_cn-4.png')"
        >

        <div class="tw-bg-ap-white tw-flex tw-flex-col sgDesktop:tw-flex-row tw-items-center tw-pb-20 tw-h-[636px] sgTablet:tw-h-[628px] sgDesktop:tw-h-[260px] sgTablet:tw-py-5 sgTablet:tw-px-5 sgDesktop:tw-pr-10 tw-px-4 tw-py-4 tw-gap-y-5 sgDesktop:tw-gap-x-5">
          <div>
            <!-- Image -->
            <img
              class="tw-w-[248px] tw-h-[248px] sgTablet:tw-w-[280px] sgTablet:tw-h-[280px] sgDesktop:tw-w-[220px] sgDesktop:tw-h-[220px]"
              :src="item.image"
            >
          </div>
          <div
            class="tw-max-w-[520px] tw-w-full tw-text-sg-body-1 tw-text-sg-qm24-green-b60 tw-text-left"

            v-html="item.description"
          />
        </div>
      </swiper-slide>
    </swiper>

    <div class="burning-joss-swiper-pagination tw-flex tw-h-20 tw-mx-auto" />

  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { Pagination, Mousewheel} from 'swiper/modules';
import SwiperCore from 'swiper';
import { onMounted, ref, Ref, computed, nextTick} from 'vue';
import { checkLang } from '../../support/helper';
import { onBeforeUnmount } from 'vue';

SwiperCore.use([Pagination, Mousewheel]);

const datas: Ref<Array<any>> = ref([]);
const burningJossCardSwiper = ref(null);

interface BurningJossCard {
  description: string;
  is_singaporen_way: boolean;
  image: any;
}


const props = defineProps<{
  data: BurningJossCard[],
  imageSourceOpen: boolean,

}>();

const emit = defineEmits(['update:imageSourceOpen']);


const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});




onMounted(() => {
  nextTick(() => {

    // Add <sup> click listener
    const burningJossCard = document.getElementById('burning_joss_card');
    if(burningJossCard){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = burningJossCard.querySelectorAll('#burning_joss_card sup');
      supElements.forEach((supElement) => {

        supElement.addEventListener('click', handleSupClick);
      });
    }
  });

});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('burning_joss_card');

  if(burningJossCard){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#burning_joss_card sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});


</script>

<style lang="scss">
.burning-joss-swiper-pagination {
  padding-top: 32px;
  justify-content: center;
  display: flex;
}

.burning-joss-swiper-pagination .swiper-pagination-bullet {
  @apply tw-w-3 tw-h-3;
}

.burning-joss-swiper-pagination .swiper-pagination-bullet-active {
  @apply tw-bg-ap-dim-grey #{!important};
  @apply tw-w-3 tw-h-3;
}

#burning_joss_card .swiper-wrapper .swiper-slide {
  position: relative;
  @apply first:tw-ml-4 last:tw-mr-4 sgTablet:first:tw-ml-10 sgTablet:last:tw-mr-10 sgDesktop:first:tw-ml-[calc((100vw-800px)/2)] sgDesktop:last:tw-mr-[calc((100vw-800px)/2)];
}

#burning_joss_card .swiper-wrapper {
  padding-top: 36px;
  //padding-left: 12px;
}

#burning_joss_card sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}



</style>
