<template>
  <div>
    <ap-top-nav-bar
      class="tw-z-50"
      :is-transparent="true"
      :is-hidden="false"
    />
    <div>
      <!-- Second Nav bar -->

      <div class="tw-h-full">

        <!-- Header -->
        <div
          ref="header"
          class="headerCustom"
        >
          <!-- Inner -->
          <div
            ref="inner"
            class="inner"
          />
          <!-- Stacked Text and Image -->
          <div
            class="tw-absolute tw-flex tw-flex-col tw-inset-0 tw-justify-center tw-items-center tw-max-h-[100vh] tw-max-w-[100vw] tw-px-4 tablet:tw-px-10 desktop:tw-px-[25%]"
          >
            <p class="tw-flex tw-flex-row tw-w-full tw-justify-start tw-mb-2 tw-text-white tw-text-sg-h4-mobile tablet:tw-text-sg-h4-tablet desktop:tw-text-sg-h4">
              {{ $t('visitor_information.experience_the') }}
            </p>
            <img
              class="tw-h-auto tw-w-full tw-justify-center tw-mb-2 big-big-logo"
              :src="$cdn('images/singaporen-exhibition.png')"
              alt="Singaporen"
            >
            <p class="tw-flex tw-flex-row tw-w-full tw-justify-end tw-text-white tw-text-sg-h4-mobile tablet:tw-text-sg-h4-tablet desktop:tw-text-sg-h4">
              {{ $t('visitor_information.exhibition') }}
            </p>
            <!-- Tabs -->

          </div>
        </div>
        <!-- Section view base on tabs -->
        <div
          id="section-id"
          ref="section"
        >
          <Transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="currentTab === 'about'"
              key="ABOUT"
            >
              <page-about
                :about-text-section-1="aboutTextSection1"
                :about-image-section-1="aboutImageSection1"
                :about-text-section-2="aboutTextSection2"
                :about-text-section-2-italic="aboutTextSection2Italic"
                :about-image-section-2="aboutImageSection2"
                :wordpress-data="wordpressData"
                :reinit-gsap="initGsap"
                :part="1"
              />
            </div>
            <div
              v-else-if="currentTab === 'general_information'"
              key="GENERAL INFORMATION"
            >
              <page-visitor-information
                :about-location-section-image="aboutLocationSectionImage"
                :group-event-image="groupEventImage"
                :part="1"
                :show-notice="!wordpressData.canView.includes('dialog.notice.exhibition')"
              />
            </div>
            <div
              v-else-if="currentTab === 'singaporen_m'"
              key="#SINGAPO人"
            >
              <ap-photo-grid
                class="tw-z-20 tw-bg-white tw-relative"
                :social-media-post="socialMediaPost"
                @light-box-src="getLightBoxSrc"
                @is-light-box-open="getLightBoxOpen"
              />
            </div>
            <div
              v-else-if="currentTab === 'gallery_etiquette'"
              key="GALLERY ETIQUETTE"
            >
              <page-gallery-etiquette
                :gallery-image-1="galleryImage1"
                :gallery-image-2="galleryImage2"
                :gallery-image-3="galleryImage3"
                :part="1"
              />
            </div>
          </Transition>
        </div>
        <ap-exhibition-second-nav
          v-model="currentTab"
          class="second-nav tw-z-[50] tw-sticky tw-bottom-0 -tw-mt-[72px] tablet:-tw-mt-[80px]"
        />
      </div>
      <div
        id="section-id2"
        ref="section2"
      >
        <Transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="currentTab === 'about'"
            key="ABOUT"
          >
            <page-about
              :about-text-section-1="aboutTextSection1"
              :about-image-section-1="aboutImageSection1"
              :about-text-section-2="aboutTextSection2"
              :about-text-section-2-italic="aboutTextSection2Italic"
              :about-image-section-2="aboutImageSection2"
              :wordpress-data="wordpressData"
              :reinit-gsap="initGsap"
              :part="2"
            />
          </div>
          <div
            v-else-if="currentTab === 'general_information'"
            key="GENERAL INFORMATION"
          >
            <page-visitor-information
              :about-location-section-image="aboutLocationSectionImage"
              :part="2"
            />
            <!-- SG CLEAN Certified -->
            <div
              class="tw-z-20 tw-relative tw-bg-white tw-flex tw-flex-col tw-px-4 tw-py-5
    tablet:tw-px-10 tablet:tw-py-10
    desktop:tw-px-20 desktop:tw-py-10"
            >
              <!-- SG Clean Text -->
              <div
                class="tw-flex tw-flex-col
        tablet:tw-flex-row tablet:tw-justify-center
        desktop:tw-flex-row desktop:tw-justify-center"
              >
                <p
                  class="tw-flex tw-flex-row tw-justify-center tw-pb-2 tw-text-sg-sccc1 tw-text-sg-h4-mobile
        tablet:tw-text-sg-h4-tablet
        desktop:tw-text-sg-h4"
                >
                  {{ $t('visitor_information.sccc_is') }}
                </p>
                <img
                  class="tw-pb-2 tablet:tw-pb-6 tw-max-h-[72px] tw-min-h-[52px] tw-max-w-[250px] tablet:tw-max-w-none tw-self-center"
                  :src="$cdn('images/sg_clean.png')"
                  alt="SG Clean"
                >
                <p
                  class="tw-flex tw-flex-row tw-justify-center tw-text-sg-sccc1 tw-text-sg-h4-mobile
        tablet:tw-text-sg-h4-tablet
        desktop:tw-text-sg-h4"
                >
                  {{ $t('visitor_information.certified') }}
                </p>
              </div>
              <div class="tw-flex tw-flex-row tw-justify-center tw-pt-4 tablet:tw-pt-5 desktop:tw-pt-5">
                <p class="tw-text-sg-body-1-mobile tw-text-center tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">
                  {{ $t('visitor_information.sg_clean_desc') }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else-if="currentTab === 'gallery_etiquette'"
            key="GALLERY ETIQUETTE"
          >
            <page-gallery-etiquette
              :gallery-image-1="galleryImage1"
              :gallery-image-2="galleryImage2"
              :gallery-image-3="galleryImage3"
              :part="2"
            />
          </div>
        </Transition>
      </div>
      <ap-footer
        id="exhibition-footer"
        class=" tw-bg-white tw-relative"
      />

      <!-- LightBox Modal -->
      <ap-light-box
        v-model="isLightBoxOpen"
        :light-box-src="lightBoxSrc"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, onBeforeUnmount, toRefs, computed, nextTick } from 'vue';
import { getScreenSize } from '../../support/helper';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { watch } from 'vue';


export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
    exhibitionType: {
      type: String,
      default: 'about',
    }
  },
  setup(props) {
    gsap.registerPlugin(ScrollTrigger);
    // Refs for the inner element and the section element
    const { wordpressData } = toRefs(props);
    const inner: Ref<HTMLElement | null> = ref(null);
    const section: Ref<HTMLElement | null> = ref(null);
    const currentTab: Ref<string> = ref('');
    const aboutTextSection1 = computed(() => wordpressData.value.about_text_section_1);
    const aboutImageSection1 = computed(() => wordpressData.value.about_image_section_1);
    const aboutTextSection2 = computed(() => wordpressData.value.about_text_section_2);
    const aboutTextSection2Italic = computed(() => wordpressData.value.about_text_section_2_italic);
    const aboutImageSection2 = computed(() => wordpressData.value.about_image_section_2);
    const aboutLocationSectionImage = computed(() => wordpressData.value.about_location_section_image);
    const groupEventImage = computed(() => wordpressData.value.group_event_image);
    const galleryImage1 = computed(() => wordpressData.value.gallery_image_1);
    const galleryImage2 = computed(() => wordpressData.value.gallery_image_2);
    const galleryImage3 = computed(() => wordpressData.value.gallery_image_3);
    const socialMediaPost = computed(() => wordpressData.value.social_media_post);

    interface LightBoxData {
      image: string,
      username: string,
      description: string,
      social_platform: string,
      social_link: string,
    }

    const isLightBoxOpen = ref<boolean>(false);
    const lightBoxSrc = ref<LightBoxData>();

    const handleScroll = () => {
      if (section.value && inner.value) {
        const delayedOffsetY = Math.max(0, window.pageYOffset);
        let value = delayedOffsetY / 8000 + 1;
        inner.value.style.transform = `scale(${value})`;
      }
    };

    onMounted(async () => {
      currentTab.value = props.exhibitionType;
      if (inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(${wordpressData.value.exhibition_header_about})`;
        await nextTick();
      }
      // false && console.log('wordpressData', wordpressData.value);
      window.addEventListener('scroll', handleScroll);
      //initGsap();

      //window.addEventListener('resize',initGsap);
    });


    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      //window.removeEventListener('resize',initGsap);
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    });

    watch(() => currentTab.value, async (_, __)=> {
      if (inner.value) {
        const backgroundImg = currentTab.value == 'general_information' ? wordpressData.value.exhibition_header_general_information :
          currentTab.value == 'singaporen_m' ? wordpressData.value.exhibition_header_singaporen :
            currentTab.value == 'gallery_etiquette' ?  wordpressData.value.exhibition_header_gallery : wordpressData.value.exhibition_header_about;
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(${backgroundImg})`;
        await nextTick();
      }
      //initGsap();

      scrollToTop();
    });

    const initGsap = () => {
      return;
      // const scrollId = ScrollTrigger.getById('second-nav-bar');
      // if(scrollId) {
      //   scrollId.kill();
      // }


      // const secondNavBar = document.getElementById('exhibition-nav-bar');

      // nextTick();
      // setTimeout(() => {
      //   gsap.to(secondNavBar, {
      //     zIndex: -20,
      //     scrollTrigger: {
      //       id: 'second-nav-bar',
      //       trigger: secondNavBar,
      //       start: 'bottom bottom',
      //       end: 'bottom bottom',
      //       endTrigger: '#section-pin', // Replace with the actual ID of the section
      //       pin: true,
      //       pinSpacing: false,

      //     },
      //   });
      // },500);
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const getLightBoxSrc = (data: LightBoxData) => {
      lightBoxSrc.value = data;
    };

    const getLightBoxOpen = (data: boolean) => {
      isLightBoxOpen.value = data;
    };


    return {
      inner,
      section,
      currentTab,
      getScreenSize,
      aboutTextSection1,
      aboutImageSection1,
      aboutTextSection2,
      aboutTextSection2Italic,
      aboutImageSection2,
      aboutLocationSectionImage,
      groupEventImage,
      galleryImage1,
      galleryImage2,
      galleryImage3,
      socialMediaPost,
      isLightBoxOpen,
      lightBoxSrc,
      getLightBoxSrc,
      getLightBoxOpen,
      initGsap,
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100lvh;
  }
  .inner {
    max-height: 100lvh;
  }
}

.inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  background-image: url(https://picsum.photos/1200?random=2);
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
}
</style>
