<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar :is-transparent="false" :is-hidden="false" :cny-navigation="true" />

    <secondary-navigation :menu-items-data="menuItems" :do-emit="true" @select-story="selectStory" />

    <!-- 
      :offset-y="70"

       -->

    <!-- Header -->
    <section>
      <div class="tw-relative">
        <picture>
          <source media="(max-width: 375px)" :srcset="$cdn('images/cny2024/banner_375.png')" />
          <source media="(max-width: 680px)" :srcset="$cdn('images/cny2024/banner_680.png')" />
          <source media="(max-width: 800px)" :srcset="$cdn('images/cny2024/banner_800.png')" />
          <source media="(max-width: 1025px)" :srcset="$cdn('images/cny2024/banner_1025.png')" />
          <img :src="$cdn('images/cny2024/banner_1440.png')" class="tw-w-full tw-object-cover" />
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny tw-pb-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_origin_stories') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="tw-max-w-[800px] tw-m-auto tw-pt-10 tw-px-4 tablet:tw-px-7 desktop:tw-px-3">
      <div class="tw-text-sg-label-3" v-html="props.wordpressData.description" />
      <div class="tw-pt-10 tw-pb-[70px]">
        <cny-origin-card-section :nav-select-id="(selectedId as number)" @select-story="selectStory" />
      </div>
    </div>
    <div>
      <cny-origin-story-section id="storySection" ref="storySection" />
    </div>

    <ap-footer />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { nextTick } from 'vue';
import { checkLang } from '../../support/helper';

const { t } = useI18n();

const props = defineProps<{
  wordpressData: any;
}>();

let selectedId = ref(1);
const storySection = ref();
const firstLoad = ref(true);

const selectStory = async (id: number) => {
  selectedId.value = id;

  // Ensure storySection is properly bound and initialized
  if (storySection.value) {
    await storySection.value.getStoryData(selectedId.value);
  }

  // Use nextTick to ensure DOM updates are complete before scrolling
  await nextTick();

  const section = document.getElementById('storySection');

  if (section && !firstLoad.value) {
    const headerOffset = 50; // Adjust this value to scroll slightly higher
    const elementPosition = section.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    // Perform the scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  } else {
    firstLoad.value = false;
  }
};
const menuItems = [
  {
    label: 'cny_the_legend_nian',
    id: '1',
  },
  {
    label: 'cny_the_story_zodiac_race',
    id: '2',
  },
  {
    label: 'cny_bai_tian_gong_festival',
    id: '3',
  },
  {
    label: 'cny_the_origins_of_ren_ri',
    id: '4',
  },
];
</script>
