<template>
  <div
    :class="{
      'tw-font-akkurat': checkLang() === 'en',
      'tw-font-notosans': checkLang() !== 'en'
    }"
  >    <ap-top-nav-bar
         :is-transparent="false"
         :is-hidden="false"
       />
    <!-- Header -->
    <div class="slideshow-container tw-w-full tw-overflow-hidden">
      <div
        v-for="(imageUrl, index) in imageUrls"
        :key="index"
      >
        <img
          class="the-slide fade tw-absolute tw-object-cover tw-w-full tw-h-full tw-top-0 tw-left-0"
          :src="imageUrl"
        >
      </div>

      <!-- Next and previous buttons -->
      <inline-svg
        v-if="imageUrls?.length > 1"
        alt="Previous Slide"
        class="ce-arrow-icon tw-left-4 sgTablet:tw-left-20 md:tw-left-10 lg:tw-left-20"
        :src="$cdn('icons/carousel/carousel-navigation-left.svg')"
        @click="plusSlides(-1)"
      />
      <inline-svg
        v-if="imageUrls?.length > 1"
        alt="Next Slide"
        class="ce-arrow-icon tw-right-4 sgTablet:tw-right-20 md:tw-right-10 lg:tw-right-20"
        :src="$cdn('icons/carousel/carousel-navigation-right.svg')"
        @click="plusSlides(1)"
      />

      <div
        v-if="isPastEvent"
        class="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-bg-black tw-opacity-40"
      />
      <div
        v-if="isPastEvent"
        :class="[
          'tw-text-white',
          'tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center',
          'tw-text-sg-remix-mobile-h2 sgTablet:tw-text-sg-remix-tablet-h2 sgDesktop:tw-text-sg-remix-mobile-h2',
          { 'tw-font-akkuratbold': checkLang() == 'en' },
          { 'tw-font-notosansbold': checkLang() != 'en' },
        ]"
      >
        <p>{{ $t('ce_past_event') }}</p>
      </div>

      <!-- Sold Out / Paid Event / Free Event / Fully Booked / Selling Fast -->
      <div
        v-show="showEventStatus()"
        :class="[getEventStatusColor()]"
        class="tw-absolute tw-bottom-0 tw-w-full tw-h-6 tw-flex tw-justify-center tw-items-center tw-text-sg-body-3 tw-text-white tw-min-w-[164px] md:tw-h-8"
      >
        {{ getEventStatusText() }}
      </div>
    </div>

    <!-- Content -->
    <div class="tw-flex tw-w-full tw-justify-center">
      <div
        :class="[
          'tw-flex tw-flex-col-reverse tw-justify-center tw-w-full tw-max-w-[1440px] sgTablet:tw-px-20 ',
          'tw-px-4 tw-py-5',
          'sgDesktop:tw-pt-10 sgDesktop:tw-pb-5 sgDesktop:tw-px-[120px] sgTablet2:tw-flex-row sgTablet2:tw-py-10',
        ]"
      >
        <div
          class="tw-flex tw-flex-col tw-flex-grow sgTablet2:tw-max-w-[660px] sgDesktop2:tw-max-w-[915px] tw-pr-0 sgTablet2:tw-pr-5 sgDesktop:tw-pr-10"
        >
          <div class="tw-pb-8 tablet:tw-pb-10 tw-hidden sgTablet2:tw-flex">
            <h1
              class="tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1"
              :class="{
                'sgTablet:tw-leading-[4.5rem]': checkLang() !== 'en'
              }"
            >
              {{ props.wordpressData.the_title }}
            </h1>
          </div>
          <!-- WYSIWYG -->
          <ce-wysiwyg-viewer
            :class="[
              'tw-flex tw-flex-col tw-flex-grow tw-max-w-[-webkit-fill-available] tw-pb-[84px] md:tw-pb-[104px]',
            ]"
            :data="props.wordpressData.the_content"
          />
        </div>

        <!-- Details -->
        <div class="tw-flex tw-flex-col tw-gap-8">
          <div class="tw-flex sgTablet2:tw-hidden">
            <h1
              class="tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1"
              :class="{
                'sgTablet:tw-leading-[4.5rem]': checkLang() !== 'en'
              }"
            >
              {{ props.wordpressData.the_title }}
            </h1>
          </div>
          <div
            class="tw-flex tw-flex-col sgTablet2:tw-w-[204px] sgDesktop2:tw-w-[285px] tw-max-w-none tw-pb-8
          sgTablet2:tw-pl-5 sgDesktop:tw-pl-10
        "
          >
            <div
              class="tw-flex tw-flex-col tw-w-full tw-gap-8 sgDesktop:tw-sticky sgTablet:tw-top-20 sgDesktop:tw-pb-0"
            >
              <div>
                <p class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3 tw-font-bold">
                  {{ getEventPresentByText(0, $t('ce_event_present_by')) }}
                </p>
                <p class="tw-pt-2 tw-text-sg-body-1-mobile md:tw-text-sg-body-1-tablet lg:tw-text-sg-body-1">
                  {{ getEventPresentByText(1) }}
                </p>
              </div>

              <div v-show="getProducedByText(0) !== null || getProducedByText(1) !== null">
                <p class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3 tw-font-bold">
                  {{ getProducedByText(0, $t('ce_event_produced_by')) }}
                </p>
                <p class="tw-pt-2 tw-text-sg-body-1-mobile md:tw-text-sg-body-1-tablet lg:tw-text-sg-body-1">
                  {{ getProducedByText(1) }}
                </p>
              </div>

              <div class="tw-flex tw-flex-col tw-gap-5">
                <!-- Date -->
                <div class="tw-flex tw-flex-row tw-gap-4 tw-items-start">
                  <img :src="$cdn('icons/calendar_month.svg')">
                  <div class="tw-flex tw-flex-col  lg:tw-max-w-[225px]">
                    <div
                      v-for="(dateTime, index) in eventDateTime"
                      :key="index"
                    >
                      <p
                        class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3 tw-font-bold"
                        v-text="index !== 0 ? '\n' + dateTime.trim() : dateTime.trim()"
                      />
                    </div>
                  </div>
                </div>
                <!-- Location -->
                <div class="tw-flex tw-flex-row tw-gap-4 tw-items-start">
                  <img :src="$cdn('icons/location.svg')">
                  <div class="tw-flex tw-flex-col lg:tw-max-w-[225px]">
                    <div
                      v-for="(address, index) in eventAddress"
                      :key="index"
                    >
                      <p
                        class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3 tw-font-bold"
                        v-text="index !== 0 ? '\n' + address : address"
                      />
                    </div>
                  </div>
                </div>
                <!-- Price -->
                <div class="tw-flex tw-flex-row tw-gap-4 tw-items-start">
                  <img :src="$cdn('icons/local_offer.svg')">
                  <div class="tw-flex tw-flex-col md:tw-max-w-[184px] lg:tw-max-w-[225px]">
                    <p class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3 tw-font-bold">
                      {{ getEventTicketPrice() }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                v-if="props.wordpressData.event_visit_page_link !== '' || props.wordpressData.event_book_now_link !== '' || props.wordpressData.event_booklet_link !== ''"
                class="tw-flex tw-flex-col sgDesktop:tw-pb-[104px] tw-w-full tw-gap-5 sgDesktop:tw-min-w-[161px] sgDesktop:tw-max-w-[265px]"
              >
                <!-- Buy Now CTA -->
                <a
                  v-if="isPaidEvent && props.wordpressData.event_visit_page_link !== ''"
                  class="tw-w-full tw-h-[52px] tw-pl-6 tw-pr-5 tw-py-2 tw-flex tw-flex-row tw-justify-center tw-items-center tw-text-nowrap"
                  :class="
                    isEventSoldOut || isPastEvent
                      ? 'tw-cursor-not-allowed tw-bg-[#D1D1D1] tw-opacity-60 tw-rounded-[26px] tw-pointer-events-none'
                      : 'tw-cursor-pointer tw-bg-[#8C12D4] tw-text-white hover:tw-bg-[#9945C1] hover:tw-text-white active:tw-bg-[#520E72] active:tw-text-white tw-rounded-[26px]'
                  "
                  :href="isEventSoldOut ? 'javascript:void(0)' : props.wordpressData.event_visit_page_link"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="tw-flex tw-items-center tw-text-white tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1">
                    <p class="tw-text-sg-button-1 tw-uppercase">{{ $t('btn.buy_now') }}</p>
                    <inline-svg
                      class="tw-w-5 tw-h-5 tw-ml-2"
                      :src="$cdn('icons/arrow_outward_white.svg')"
                    />
                  </span>
                </a>
                <!-- Book Now CTA -->
                <a
                  v-if="props.wordpressData.event_book_now_link !== ''"
                  class="tw-w-full tw-h-[52px] tw-pl-6 tw-pr-5 tw-py-2 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer tw-bg-[#8C12D4] tw-text-white hover:tw-bg-[#9945C1] hover:tw-text-white active:tw-bg-[#520E72] active:tw-text-white tw-rounded-[26px]"
                  :class="
                    isPastEvent
                      ? 'tw-cursor-not-allowed tw-bg-[#D1D1D1] tw-opacity-60 tw-rounded-[26px] tw-pointer-events-none'
                      : 'tw-cursor-pointer tw-bg-[#8C12D4] tw-text-white hover:tw-bg-[#9945C1] hover:tw-text-white active:tw-bg-[#520E72] active:tw-text-white tw-rounded-[26px]'
                  "
                  :href="props.wordpressData.event_book_now_link"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="tw-flex tw-items-center tw-text-white tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1">
                    <p class="tw-text-sg-button-1 tw-uppercase">
                      {{
                        props.wordpressData.event_CTA_button_name != '' && props.wordpressData.event_CTA_button_name != null
                          ? props.wordpressData.event_CTA_button_name
                          : $t('btn.book_now')
                      }}
                    </p>
                    <inline-svg
                      class="tw-w-5 tw-h-5 tw-ml-2"
                      :src="$cdn('icons/arrow_outward_white.svg')"
                    />
                  </span>
                </a>
                <!-- E Booklet CTA -->
                <a
                  v-if="props.wordpressData.event_booklet_link !== ''"
                  class="ebookCTA tw-w-full tw-h-[52px] tw-pl-6 tw-pr-5 tw-py-2 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer"
                  :class="'tw-border-[#8C12D4] tw-border-2 tw-rounded-full hover:tw-bg-[#c485e9] hover:tw-border-[#5a0b88] active:tw-bg-[#8C12D4] active:tw-border-[#8C12D4]'"
                  :href="props.wordpressData.event_booklet_link"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="tw-flex tw-items-center tw-text-[#8C12D4] tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1">
                    <p class="tw-text-sg-button-1 tw-uppercase">{{ $t('btn.booklet') }}</p>
                    <inline-svg
                      class="downloadIcon tw-w-5 tw-h-5 tw-ml-2"
                      :src="$cdn('icons/ce/download.svg')"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ce-navigation-bar />
    <ce-partner-section />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeMount, computed} from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
const { t } = useI18n({ useScope: 'global' });

let imageUrls: string[] = [];
let eventDateTime: string[] = [];
let eventAddress: string[] = [];
let slideIndex = 1;
let isPaidEvent = false;
let isEventSoldOut = false;

onMounted(() => {
  showSlides(slideIndex);
  // false && console.log('wordpressData:', props.wordpressData);
});

onBeforeMount(() => {
  getEventDateTime();
  getEventAddress();

  isPaidEvent = props.wordpressData.event_is_paid_event == '1';
  isEventSoldOut = isPaidEvent && props.wordpressData.event_status == '2';
});

const props = defineProps({
  wordpressData: {
    type: Object,
    default: null,
  },
});

if (props.wordpressData && props.wordpressData.event_gallery) {
  try {
    imageUrls = JSON.parse(props.wordpressData.event_gallery).map((image: any) => image.url);
  } catch (error) {
    console.error('Error parsing event_gallery:', error);
  }
}

function showEventStatus(): boolean {
  if (props.wordpressData.event_is_paid_event !== '0') {
    switch (props.wordpressData.event_status) {
      case '1':
        return true;
      case '2':
        return true;
      default:
        return false;
    }
  } else {
    switch (props.wordpressData.event_status) {
      case '1':
        return true;
      default:
        return false;
    }
  }
}

function getEventTicketPrice() {
  if (props.wordpressData.event_is_paid_event == '1') {
    return props.wordpressData.event_ticket_price == '' ? '-' : props.wordpressData.event_ticket_price;
  } else {
    return t('ce_price_free');
  }
}

function getEventStatusColor() {
  let color = '';

  if (props.wordpressData.event_is_paid_event !== '0') {
    switch (props.wordpressData.event_status) {
      case '0':
        color = 'tw-bg-[#0366D8]';
        break;
      case '1':
        color = 'tw-bg-[#F6610C]';
        break;
      case '2':
        color = 'tw-bg-[#E4100E]';
        break;
      default:
        color = 'tw-bg-[#E4100E]';
        break;
    }
  } else {
    switch (props.wordpressData.event_status) {
      case '0':
        color = 'tw-bg-[#36A93F]';
        break;
      case '1':
        color = 'tw-bg-[#E4100E]';
        break;
      default:
        color = 'tw-bg-[#E4100E]';
        break;
    }
  }

  return color;
}

function getEventStatusText(): string {
  const eventStatus = props.wordpressData.event_status;

  if (props.wordpressData.event_is_paid_event !== '0') {
    if (eventStatus === '0') return t('ce_paid_event');
    if (eventStatus === '1') return t('ce_selling_fast');
    if (eventStatus === '2') return t('ce_sold_out');
  } else {
    if (eventStatus === '0') return t('ce_free_event');
    if (eventStatus === '1') return t('ce_fully_booked');
  }

  return '';
}

/**
 * Retrieves the event presenter by text at the specified index.
 *
 * @param {number} index - The index of the desired presenter text.
 * @param {string | null} defaultText - The default text to return if an error occurs.
 * @returns {string} - The event presenter text at the specified index, or the default text if an error occurs.
 */
function getEventPresentByText(index: number, defaultText: string | null = null): string | null {
  const have = props.wordpressData.event_present_by.includes('|') && props.wordpressData.event_present_by.split('|').length >= 2;
  const splitText = props.wordpressData.event_present_by.split('|').map((item: string) => item.trim());
  if (have) {
    try {
      if (index == 0 && splitText[index] == '') {
        return defaultText;
      }
      return splitText[index];
    } catch (error) {
      console.error('Error parsing event_present_by:', error);
      return defaultText;
    }
  } else {
    return index == 0 ? defaultText : props.wordpressData.event_present_by;
  }
}

/**
 * Retrieves the "Produced By" text for an event.
 *
 * @param index - The index of the event.
 * @param defaultText - The default text to return if the "Produced By" value is null or empty.
 * @returns The "Produced By" text for the event, or the default text if the value is null or empty.
 */
function getProducedByText(index: number, defaultText: string | null = null): string | null {
  const producedByFromJson = props.wordpressData.event_produced_by;

  if (producedByFromJson === undefined || producedByFromJson === '') {
    return null;
  }

  const have = props.wordpressData.event_produced_by.includes('|') && props.wordpressData.event_produced_by.split('|').length >= 2;
  const splitText = props.wordpressData.event_produced_by.split('|').map((item: string) => item.trim());

  if (have) {
    try {
      if (index == 0 && splitText[index] == '') {
        return defaultText;
      }
      return splitText[index];
    } catch (error) {
      console.error('Error parsing event_present_by:', error);
      return defaultText;
    }
  } else {
    return index == 0 ? defaultText : props.wordpressData.event_produced_by;
  }
}

/**
 * Function to get the event date and time.
 * It splits the event_date string by '|' and trims each item.
 * If an error occurs during parsing, it logs the error and assigns the event_date as a single item array.
 */
function getEventDateTime() {
  try {
    eventDateTime = props.wordpressData.event_date.split('|').map((item: string) => item.trim());
  } catch (error) {
    eventDateTime = [props.wordpressData.event_date];
    console.error('Error parsing event_date:', error);
  }
}

/**
 * Retrieves the event address from the WordPress data.
 * If the event location is a comma-separated string, it splits the string and trims each item.
 * If an error occurs during parsing, it falls back to using the original event location.
 */
function getEventAddress() {
  try {
    eventAddress = props.wordpressData.event_location.split(',').map((item: string) => item.trim());
  } catch (error) {
    eventAddress = [props.wordpressData.event_location];
    console.error('Error parsing event_location:', error);
  }
}

// Next/previous controls
function plusSlides(n: number) {
  showSlides((slideIndex += n));
}

function showSlides(n: number) {
  let slides: HTMLElement[] = Array.from(document.getElementsByClassName('the-slide')) as HTMLElement[];

  if (slides.length === 0) return;

  if (n > slides.length) {
    slideIndex = 1;
  }

  if (n < 1) {
    slideIndex = slides.length;
  }

  for (let i = 0; i < slides.length; i++) {
    slides[i].style.display = 'none';
  }

  slides[slideIndex - 1].style.display = 'block';
}

const isPastEvent = computed(() => {
  if (!props.wordpressData.event_expiry_date) return false;

  const [day, month, year] = props.wordpressData.event_expiry_date.split('/');
  const eventDate = new Date(`${year}-${month}-${day}`);
  const now = new Date();

  // Set the time of eventDate to the end of the day
  eventDate.setHours(23, 59, 59, 999);

  return eventDate < now;
});
</script>

<style>
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.slideshow-container {
  position: relative;
  margin: auto;
  padding-top: 56.25%; /* 9/16 * 100 = 56.25 */
}

.the-slide {
  display: none;
}

/* Next & previous buttons */
.ce-arrow-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -22px;
  transition: 0.6s ease;
}

svg.ce-arrow-icon:hover path {
  fill: white;
}

svg.ce-arrow-icon:hover rect {
  fill: #8016b2;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.ebookCTA:hover span {
  color: #5a0b88;
}
.ebookCTA:hover span .downloadIcon {
  filter: brightness(0.4);
}

.ebookCTA:active span {
  color: white;
}

.ebookCTA:active span {
  color: white;
}

.ebookCTA:active span .downloadIcon {
  filter: brightness(0) invert(1);
}
</style>
