<template>
  <div>
    <swiper
      id="ma-about-swiper"
      :effect="'cards'"
      :grab-cursor="true"
      :modules="modules"
      :class="[
        'mySwiper',
        'sgDesktop:tw-translate-x-[40px] sgTablet2:tw-translate-x-0 sgTablet:tw-translate-x-[25%] tw-translate-x-[20px]',
      ]"
      :initial-slide="1"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in datas"
        :key="index"
        style="height: 300px; padding-left: 10px;"
      >
        <ma-polaroid-card
          height="284px"
          width="300px"
          image-height="200px"
          :image="item.image_url"
        >
          <p
            :class="[
              'tw-text-sg-ma-sh1 tw-text-sg-ma24-purple-b40',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >{{ item.caption }}
            <sup
              id="sup-handler-container"
              class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-sg-ma-bt2 tw-font-akkurat"
              @click="handleOpenImageSourceModal"
            >{{ item.reference }}</sup>
          </p>
        </ma-polaroid-card>
      </swiper-slide>
    </swiper>
    <div class="tw-flex tw-justify-center tw-mt-6 tw-gap-[60px]">
      <div
        :class="[
          'tw-flex',
          'tw-bg-[#7A489D] hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E]',
          'tw-h-14 tw-w-14',
          'tw-text-white',
          'tw-cursor-pointer',
          'tw-rounded-full',
          { 'tw-opacity-[0.25]' : leftButtonDisabled }
        ]"
        @click="swiperInstance?.slidePrev()"
      >
        <img :src="$cdn('icons/ma/ma_chevron_left.svg')">
      </div>

      <div
        :class="[
          'tw-flex',
          'tw-bg-[#7A489D] hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E]',
          'tw-h-14 tw-w-14',
          'tw-text-white',
          'tw-cursor-pointer',
          'tw-rounded-full',
          { 'tw-opacity-[0.25]' : rightButtonDisabled }
        ]"
        @click="swiperInstance?.slideNext()"
      >
        <img :src="$cdn('icons/ma/ma_chevron_right.svg')">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore from 'swiper';
import { ref, computed, Ref } from 'vue';
import 'swiper/swiper-bundle.css';
import { Autoplay, FreeMode } from 'swiper/modules';
import { useI18n } from 'vue-i18n';

import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';
import { checkLang } from '../../support/helper';
const { t } = useI18n();

SwiperCore.use([Autoplay, FreeMode]);
const swiperInstance = ref<SwiperInstance>();

const props = defineProps<{
  imageSourceOpen: boolean,
}>();

const modules = [EffectCards];

const leftButtonDisabled = ref(false);
const rightButtonDisabled = ref(false);

const onSlideChange = () => {
  if (swiperInstance?.value) {
    leftButtonDisabled.value = swiperInstance?.value.isBeginning;
    rightButtonDisabled.value = swiperInstance?.value.isEnd;
  }
};

const emit = defineEmits(['update:imageSourceOpen']);

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const datas: Ref<Array<any>> = ref([
  {
    id: 1,
    caption: t('ma_about_celebrations_subtitle_slider_1'),
    reference: '[A]',
    image_url: '/images/ma2024/about/celebrations/ma_about_celebration_slider_02.v2.jpg',
  },
  {
    id: 2,
    caption: t('ma_about_celebrations_subtitle_slider_2'),
    reference: '[B]',
    image_url: '/images/ma2024/about/celebrations/ma_about_celebration_slider_01.jpg',
  },
  {
    id: 3,
    caption: t('ma_about_celebrations_subtitle_slider_3'),
    reference: '[C]',
    image_url: '/images/ma2024/about/celebrations/ma_about_celebration_slider_03.v2.jpg',
  },
]);

const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

</script>

<style scoped lang="scss">

#ma-about-swiper.swiper:deep(.swiper-wrapper){
  transition-timing-function: linear !important;
}

#ma-about-swiper.swiper .swiper-wrapper .swiper-slide {
  background-color: transparent;
}

#ma-about-swiper.swiper:deep(.swiper-slide-shadow){
  background: none !important;
}

</style>
