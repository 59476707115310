// @ts-expect-error It will have
import { createApp, ref } from 'vue/dist/vue.esm-bundler';
import 'swiper/css/bundle';
import '../styles/main.scss';

import Components from './components';
import Pages from './pages';
import InlineSvg from 'vue-inline-svg';
import { createI18n } from 'vue-i18n';
import messages from './i18n';

import { cdn } from './support/cdn';
import { langRedirect } from './support/langRedirect';
import * as Sentry from '@sentry/vue';


//#region i18n Related setup
// TODO: bruh, don't export anything from main.ts
export type MessageLanguages = keyof typeof messages;
// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = typeof messages['en-US'];

// TODO: can move this to d.ts
// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema { }

  // define the datetime format schema
  export interface DefineDateTimeFormat { }

  // define the number format schema
  export interface DefineNumberFormat { }
}

// Moved to global-functions.d.ts
// declare module '@vue/runtime-core' {
//   interface ComponentCustomProperties {
//     $cdn: (filePath: string, preset?: string, useLocal?: boolean) => string;
//     $langRedirect: (path: string, external?: string) => string;
//   }
// }


const i18n = createI18n({
  warnHtmlMessage: false,
  legacy: false,
  locale: 'en-US', // set locale
  fallbackLocale: 'en-US', // set fallback locale
  messages
  // If you need to specify other options, you can set other options
  // ...
});
//#endregion


// Vue initialization
const vueApp = createApp({
  setup() {
    const wordpressData = ref(window?.['__wordpress_data__']);

    return {
      wordpressData
    };
  }
});

// Component installation
Object.entries(Components).forEach((component) => {
  // console.log('Globally installed component: ', component[0], component[1]);
  vueApp.component(component[0], component[1]);
});

vueApp.component('inline-svg', InlineSvg);

// Page installation
Object.entries(Pages).forEach((component) => {
  // console.log('Globally installed page: ', component[0], component[1]);
  vueApp.component(component[0], component[1]);
});

// CDN Usage
vueApp.config.globalProperties.$cdn = cdn;
// i18n Usage
vueApp.use(i18n);
// lang Redirect Usage
vueApp.config.globalProperties.$langRedirect = langRedirect;
// Can install other components, plugins over here
// ...



Sentry.init({
  enabled: ['prod', 'staging'].includes(process.env.APP_ENV as string),
  app: vueApp,
  dsn: process.env.SENTRY_DSN,
  environment: process.env.APP_ENV,
  integrations: [
    // temporary disable to check if this causes the security issue in security report scan
    // Sentry.replayIntegration({
    //   maskAllText:false,
    // }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event: any, hint: any) => {
    if (process.env.APP_ENV == 'local') {
      console.error(event);
      console.error(hint.originalException || hint.syntheticException);

      return null; // this drops the event and nothing will be send to sentry
    }

    return event;
  },
  ignoreErrors: [
    'Jsloader error (code #1):', // Google api script, not using, suspect from plugin
    'YT is not defined', //videojs-youtube not using
    '{}',
    'HupuBridge',// hupu https://www.hupu.com/ is a site for china to watch nba, suspecting some app or plugin is referencing it.
    'window.Frodo.Webview', //another webview thingy
    'X is not a function', // google bot crawling
    '<unknown>', // google bot crawling
    'getPercent is not defined',  // google bot crawling
    'i.replace is not a function', // google bot crawling
    'a.L', // suspect from wp plugin/webview
    '_AutofillCallbackHandler', // when open through fb or insta on IOS

    'Cannot read properties of undefined (reading \'offsetHeight\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'innerText\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'setScreenSize\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'code\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'fireReadyEvent\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'fireChangeEvent\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'setMaxSize\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'setCurrentPosition\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'setDefaultPosition\')', // Library loading issue on old Android versions
    'Cannot read properties of undefined (reading \'setIsViewable\')', // Library loading issue on old Android versions

    'Cannot read property \'offsetHeight\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'innerText\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'setScreenSize\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'code\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'fireReadyEvent\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'fireChangeEvent\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'setMaxSize\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'setCurrentPosition\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'setDefaultPosition\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'setIsViewable\' of undefined', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'value\' of null', // Library loading issue on old mobile Chrome versions
    'Cannot read property \'offsetTop\' of null', // Library loading issue on old mobile Chrome versions

    '_0x266f24 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0xe32102 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x23e975 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x154720 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x3280af is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x2a905f is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x4e1cd6 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x4501fa is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x1d82d2 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x106a25 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x314195 is not defined', // Issue related to extra parameters added to website url when opened through ads
    '_0x6b9e64[_0x9b65d4(...)] is not a function', // Issue related to extra parameters added to website url when opened through ads
    '_0xb65f1a[_0x5f5dea(...)] is not a function', // Issue related to extra parameters added to website url when opened through ads
    'Cannot read properties of undefined (reading \'getBoundingClientRect\')', // Library loading issue on old Android versions

    'logicReadAdsPage is not defined', // Issue related to extra parameters added to website url when opened through ads
    'ads_readAdPage is not defined', // Issue related to extra parameters added to website url when opened through ads
    'runCustomize is not defined', // Issue related to extra parameters added to website url when opened through ads
    'ads_clickUnAd is not defined', // Issue related to extra parameters added to website url when opened through ads
    'ads_afterScrollCheck is not defined', // Issue related to extra parameters added to website url when opened through ads
    'Invalid or unexpected token', // Issue related to extra parameters added to website url when opened through ads
    'onHide is not defined', // Issue related to extra parameters added to website url when opened through ads
    'doEnterResultPageLogic is not defined', // Issue related to extra parameters added to website url when opened through ads

    'yt is not defined', // Same error as above, accounting for lower case 'yt'
    'Chartboost is not defined', // Issue related to ChartBoost(https://www.chartboost.com/) being used for advertising on mobile devices
    'Can\'t find variable: Chartboost', // Issue related to ChartBoost (https://www.chartboost.com/) being used for advertising on mobile devices

    'Unexpected token \'var\'', // Only reported once, most likely accessed by a site admin, not a user
    'db', // Only reported once, most likely accessed by a site admin, not a user
    'YT.ready is not a function', // Only reported once, most likely accessed by a site admin, not a user
    'Menu caption text is required!', // Only reported once, most likely accessed by a site admin, not a user
    'tgetT is not defined', // Only reported once, most likely accessed by a site admin, not a user

    'Java object is gone', // Error related to Chrome Webview on Android
    'Java bridge method invocation error', // Error related to Chrome Webview on Android

    'undefined', // Additional parameters added when opened through Youtube via ads
    'finish is not defined', // Error coming from ad on https://html5.gamemonetize.co/, likely through external ad boosting agency
    'Java exception was raised during method invocation', // Redirected through ad from https://sottotv.com/
    'undefined is not a function', // Another parameter error through ads
    'Stylesheet append timeout', // Error with google ad analytics
    'Cannot read properties of undefined (reading \'getElementsByTagName\')', // Error with google ad analytics

    'mbrowser.isAllowAutoFillOnDomain is not a function', // Error with autofill browser settings in Chrome
    'mbrowser.onDOMContentLoaded is not a function', // Error with autofill browser settings in Chrome
    'Unexpected end of input', // Error with browser settings in Chrome on mobile

    'Illegal invocation', // Error only reported once, likely due to a site admin accessing the site through MS Edge on Windows 10+
    'Failed to construct \'URL\': Invalid URL', // Error related to usage of Google Tag Manager https://tagmanager.google.com/#/home
    'jb', // Only reported once, most likely accessed by a site admin, not a user
    'Failed to load because no supported source was found.', // Video-js library issue
    'Object captured as promise rejection with keys: code, message', // External crypto service (most likely https://metamask.io/) loaded on the site
    'Invalid call to runtime.sendMessage(). Tab not found.', // Error related to Chrome extensions (source: https://developer.chrome.com/docs/extensions/reference/api)
    'Failed to execute \'querySelectorAll\' on \'Document\': \':is([id*=\'gpt-\'])\' is not a valid selector.', // ACF plugin issue
  ]
});

vueApp.mount('#vue-app');
