/**
 * This function also available globally
 *
 * Usage:
 *
 * <img :src="$cdn('img/image.png')">
 */
export const cdn = (filePath: string, useLocal: boolean = true): string => {
  if (useLocal) {
    return process.env.APP_URL + '/wp-content/themes/ap_vue_base_theme/assets/' + filePath;
  }

  return process.env.APP_URL + '/wp-content/themes/ap_vue_base_theme/assets/' + filePath;
};
