<template>
  <div class="tw-h-0 tw-w-[90vw] sgTablet:tw-w-max tw-z-20 tw-sticky tw-bottom-[10px] sgDesktop:tw-left-0 sgDesktop:tw-translate-x-0 tw-m-auto">
    <div
      id="CENavBar"
      class="tw-rounded-[56px] tw-py-3 tw-px-5 tw-flex  tw-bg-white sgTablet:tw-w-auto tw-overflow-x-auto tw-relative tw-max-h-[64px] tw-overflow-y-hidden"
    >
      <div
        id="CENavBarContainer"
        class=" tw-overflow-x-auto tw-flex tw-gap-x-3 sgDesktop2:tw-gap-x-[60px] tw-items-center tw-overflow-y-hidden"
        @touchstart="hideArrow=true"
        @touchend="hideArrow=false;updateScrollPosition()"
      >
        <div
          class="tw-flex sgTablet:tw-hidden"
          :class="{ '!tw-hidden': isReachStart || tabsScrollPosition <= 0 && !hideArrow }"
        >
          <div class="tw-absolute tw-left-0 tw-top-[18%] tw-h-[45px] tw-bg-white tw-pl-5 tw-rounded-l-[18px]">
            <inline-svg
              class="scroll-arrow tw-left-0 tw-bg-white"
              :src="$cdn('icons/ce/chevron_left.svg')"
              fill="#8016B2"
              @click="scrollTabs('left')"
            />

          </div>
        </div>
        <a
          v-for="(btn,index) in menuItems "
          :key="index"
          class="tw-py-3"
          :href="menuAction(btn.id,btn.url)"
          :target="btn.id==4?'_blank':'_self'"
          rel="noopener"
          @click="menuAction(btn.id,btn.url,true)"
        >
          <span
            :class="['tw-flex tw-flex-wrap tw-content-center tw-h-10 tw-py-3 tw-px-5 sgTablet:tw-px-7',
                     '!tw-text-sg-body-bold-2 tw-rounded-[26px] tw-uppercase tw-whitespace-pre tablet:whitespace-normal',
                     'tw-max-h-10',
                     btn.activeBtn?'tw-bg-[#520E72] tw-text-white':'tw-bg-white tw-text-[#8C12D4]',
                     'hover:tw-bg-[#8C12D4] hover:tw-text-white active:tw-bg-[#520E72] active:tw-text-white ',
            ]"
          >{{ btn.label }}</span>
        </a>
        <div
          class="tw-flex sgTablet:tw-hidden"
          :class="{ '!tw-hidden': isReachEnd || hideArrow }"
        >
          <div class="tw-absolute tw-right-0 tw-top-[18%] tw-h-[45px] tw-bg-white tw-pr-5 tw-rounded-r-[18px]">
            <inline-svg
              class="scroll-arrow tw-right-0 tw-bg-white"
              :src="$cdn('icons/ce/chevron_right.svg')"
              @click="scrollTabs('right')"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../support/helper';
import { Ref, computed, onBeforeMount, onMounted, ref, onUnmounted } from 'vue';
const { t } = useI18n({ useScope: 'global' });

declare let gtag: any;

const hideArrow = ref(false);
const tabsContainer: Ref<any> = ref(null);
const tabsScrollPosition: Ref<number> = ref(0);
const containerWidth = ref(0);
const scrollWidth = ref(0);

let menuItems = [
  {
    id: 1,
    label: t('ce_navigation_about'),
    url: `${
      checkLang() != 'zh-hans'
        ? process.env.WP_SITEURL + '/experience/ce/about'
        : process.env.WP_SITEURL + '/experience/ce/about/?lang=zh-hans'
    }`,
    activeBtn: false,
  },
  {
    id: 2,
    label: t('ce_navigation_events'),
    url: `${
      checkLang() != 'zh-hans'
        ? process.env.WP_SITEURL + '/experience/ce/events'
        : process.env.WP_SITEURL + '/experience/ce/events/?lang=zh-hans'
    }`,
    activeBtn: false,
  },
  // {
  //   id:3,
  //   label:t('ce_navigation_video'),
  //   url:`${checkLang() != 'zh-hans' ? process.env.WP_SITEURL + '/experience/ce/video' : process.env.WP_SITEURL + '/experience/ce/video/?lang=zh-hans'}`,
  //   activeBtn:false
  // },
  {
    id: 4,
    label: t('ce_navigation_past_years'),
    url: '#',
    activeBtn: false,
  },
];

onBeforeMount(() => {
  menuItems.forEach((item) => {
    item.activeBtn = false;
  });

  if (window.location.pathname == '/experience/ce/about/' || window.location.pathname == '/experience/ce/about') {
    menuItems[0].activeBtn = true;
  } else if (window.location.pathname == '/experience/ce/events' || window.location.pathname == '/experience/ce/events/') {
    menuItems[1].activeBtn = true;
  }
  // else if(window.location.pathname == '/experience/ce/video' || window.location.pathname == '/experience/ce/video/'){
  //   menuItems[2].activeBtn = true;
  // }
});

function menuAction(id: number, url: string, isTap: boolean = false) {
  if (id != 4) {
    return url;
  } else {
    let lang = checkLang();
    let eventLabel = 'ce_past_years_click' + (lang === 'en' ? '_en' : '_zhhans');

    if (typeof gtag === 'function' && isTap) {
      gtag('event', 'click', {
        event_category: 'ce_past_years_category',
        event_label: eventLabel,
        value: 1,
      });
    }

    return lang === 'en' ? '/experience/ce/past-years' : '/experience/ce/past-years/?lang=zh-hans';
  }
}

onMounted(() => {
  tabsContainer.value = document.getElementById('CENavBarContainer');
  updateDimensions();
  window.addEventListener('resize', updateDimensions);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateDimensions);
});

const updateDimensions = () => {
  if (tabsContainer.value) {
    containerWidth.value = tabsContainer.value.offsetWidth;
    scrollWidth.value = tabsContainer.value.scrollWidth;
    updateScrollPosition();
  }
};

const scrollTabs = (direction: string) => {
  const container = tabsContainer.value;
  const scrollAmount = direction === 'left' ? -containerWidth.value : containerWidth.value;
  container.scrollLeft += scrollAmount;
  updateScrollPosition();
};

const isReachEnd = computed(() => {
  return tabsScrollPosition.value + containerWidth.value >= scrollWidth.value;
});

const isReachStart = computed(() => {
  return tabsScrollPosition.value <= 0;
});

const updateScrollPosition = () => {
  if (tabsContainer.value) {
    tabsScrollPosition.value = tabsContainer.value.scrollLeft;
  }
};
</script>
<style>
#CENavBar{
  top:-80px;
  box-shadow: -4px 4px 10px 0px #0000004D;
}

#CENavBarContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
#CENavBarContainer::-webkit-scrollbar {
    display: none;
}
</style>
