<script lang="ts" setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';

const { t } = useI18n();
const currentQuestionNo = ref(0);
const totalQuestionNo = ref(4);
const questions = ref({
  1: {
    title_en: 'Which of these statements best describe you?',
    title_cn: '以下哪个句子最符合你的性格？',
  },
  2: {
    title_en: 'Which of these statements best describe you?',
    title_cn: '以下哪个句子最符合你的性格？',
  },
  3: {
    title_en: 'Which of these statements best describe you?',
    title_cn: '以下哪个句子最符合你的性格？',
  },
  4: {
    title_en: 'Which of these statements best describe you?',
    title_cn: '以下哪个句子最符合你的性格？',
  },
});
const options = ref({
  1: {
    'bak-chang': {
      option_en: 'Nominated class monitor at least once',
      option_cn: '曾获提名为班长至少一次',
    },
    'nyonya-chang': {
      option_en: '10/10 Instagram feed aesthetics Instagram',
      option_cn: '页面美感十足',
    },
    'kee-chang': {
      option_en: 'Everyone’s go-to HTHT partner',
      option_cn: '大家倾诉的对象',
    },
    'trendy-chang': {
      option_en: 'Have a unique style because you’re a serial thrifter',
      option_cn: '喜欢二手服饰，而且穿搭风格独特',
    },
  },
  2: {
    'bak-chang': {
      option_en: 'People have called you wayang',
      option_cn: '曾被人说很“假”（未必当着你的面说）',
    },
    'nyonya-chang': {
      option_en: 'Suffers from ‘main character’ syndrome',
      option_cn: '时常自我感觉良好',
    },
    'kee-chang': {
      option_en: 'Literally friends with everyone',
      option_cn: '好友满天下',
    },
    'trendy-chang': {
      option_en: 'You have #nofilter',
      option_cn: '口无遮拦#nofilter',
    },
  },
  3: {
    'bak-chang': {
      option_en: 'Graduated with Straight As',
      option_cn: '曾以特优的成绩毕业',
    },
    'nyonya-chang': {
      option_en: 'Changes hair colour as often as the seasons',
      option_cn: '发型换了又换',
    },
    'kee-chang': {
      option_en: 'People call you basic, and you embrace it',
      option_cn: '常被认为没个性，但你不曾当回事',
    },
    'trendy-chang': {
      option_en: 'Spends hours on TikTok every day',
      option_cn: '天天刷好几小时的抖音',
    },
  },
  4: {
    'bak-chang': {
      option_en: 'Serious on the outside but lowkey full of feelings on the inside',
      option_cn: ' 外冷内热',
    },
    'nyonya-chang': {
      option_en: 'People assume you studied art',
      option_cn: '常被误认为是美术生',
    },
    'kee-chang': {
      option_en: 'Have 5 sets of the same outfit',
      option_cn: '衣橱里有五套款式相同的衣服',
    },
    'trendy-chang': {
      option_en: 'You have a pair of crocs loaded with jiBbItZ',
      option_cn: '拥有一双配了100000个“智必星”的洞洞鞋',
    },
  },
});
const answers = ref<string[]>([]);
const results = ref({
  'bak-chang': {
    title_en: 'Bak Chang',
    title_cn: '肉粽',
    desc_en: 'Classic, no-frills, no-nonsense! You can always be counted on to be ever dependable and reliable.',
    desc_cn: '简单、朴实、不废话！绝对可靠，跟着你准没错。',
    options_en: ['Plain t-shirts for the win', 'Hawker centre enthusiast', 'Old school at heart'],
    options_cn: ['一件T恤闯天下', '小贩中心就是你的菜', '老灵魂'],
    image: cdn('images/dw2024/personality-quiz/dumplings-results/bak-chang.scaled.jpg'),
  },
  'nyonya-chang': {
    title_en: 'Nonya Chang',
    title_cn: '娘惹粽',
    desc_en: 'Just like the nonya chang’s uniquely coloured tip from blue pea flowers, you stand out above the crowd.',
    desc_cn: '就像娘惹粽使用蝶豆花染上一角的特色，你就是超群出众。',
    options_en: ['It’s giving… extra, in the best way', 'Overdressed again', 'Life of the party'],
    options_cn: ['多此一举（是好事）', '穿着浮夸不稀奇', '派对的灵魂人物'],
    image: cdn('images/dw2024/personality-quiz/dumplings-results/nyonya-chang.scaled.jpg'),
  },
  'kee-chang': {
    title_en: 'Kee Chang',
    title_cn: '碱水粽',
    desc_en: 'Introverted extrovert alert!Being at the centre of attention is not your cup of tea. With the right people though… look out!',
    desc_cn: '内向型的外向人！你不喜欢引人注目，除非是跟你合拍的人。',
    options_en: ['Basic and what about it?', 'Too many besties', 'Screaming on the inside'],
    options_cn: ['世俗要你管？', '好友满天下', '内心不停尖叫'],
    image: cdn('images/dw2024/personality-quiz/dumplings-results/kee-chang.scaled.jpg'),
  },
  'trendy-chang': {
    title_en: 'Trendy Chang',
    title_cn: '时髦粽',
    desc_en: 'No trend escapes you, anything and everything is for the ‘gram.',
    desc_cn: '流行趋势由你掌握，凡事都得拍照打卡',
    options_en: ['0.5 selfie pls', 'Forget the classics, it’s all about the latest “it” craze', 'Didn’t post, didn’t happen'],
    options_cn: ['自拍功夫一流', '怀旧？我走在最前线好吗', '做什么一定会po文'],
    image: cdn('images/dw2024/personality-quiz/dumplings-results/trendy-chang.scaled.jpg'),
  },
});
const result = ref<{ title_en: string; title_cn: string; desc_en: string; desc_cn: string; image: string }>({
  title_en: '',
  title_cn: '',
  desc_en: '',
  desc_cn: '',
  image: '',
});
const currentQuestion = ref<{ title_en: string; title_cn: string }>({ title_en: '', title_cn: '' });
const currentOptions = ref<[string, { option_en: string; option_cn: string }][]>([]);
const showQuestion = ref(false);
const showResult = ref(false);
const showCover = ref(true);

const validateAnswer = (answer: string) => {
  answers.value.push(answer);
  if (currentQuestionNo.value == totalQuestionNo.value) {
    const calc = calculateAnswers();
    result.value = results.value[calc[0]];
    showResult.value = true;
    //showQuestion.value = false;
    return;
  } else {
    currentQuestionNo.value++;
    currentQuestion.value = questions.value[currentQuestionNo.value];
    if (currentQuestionNo.value - 1 == totalQuestionNo.value - 1) {
      const calc = calculateAnswers();
      if (calc.length == 2) {
        let tempOptions = [];
        calc.forEach((c) => {
          tempOptions.push([c, options.value[currentQuestionNo.value][c]]);
        });
        currentOptions.value = tempOptions;
      } else {
        currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
      }
    } else {
      currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
    }
    return;
  }
};

const restartQuiz = () => {
  showCover.value = true;
  showQuestion.value = false;
  showResult.value = false;
  answers.value = [];
  result.value = { title_en: '', title_cn: '', desc_en: '', desc_cn: '', image: '' };

  currentQuestionNo.value = 0;
};

const startQuiz = () => {
  restartQuiz();
  showCover.value = false;
  showQuestion.value = true;
  showResult.value = false;
  currentQuestionNo.value = 1;
  currentQuestion.value = questions.value[currentQuestionNo.value];
  currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
};

const calculateAnswers = () => {
  let highest = 1;
  let highestCategory: string[] = [];
  const counts = answers.value.reduce((counts: any, answer) => {
    if (counts[answer]) {
      counts[answer]++;
    } else {
      counts[answer] = 1;
    }

    if (counts[answer] > highest) {
      highest = counts[answer];
      highestCategory = [answer];
    } else if (counts[answer] == highest) {
      highestCategory.push(answer);
    }
    return counts;
  }, {});
  highestCategory = highestCategory.sort();
  return highestCategory;
};
</script>

<template>
  <section
    class="tw-bg-sg-dw24-blue-w60 tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20 tw-py-5 sgTablet:tw-py-10 tw-relative"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
  >
    <div class="tw-w-full tw-absolute tw-bottom-0 tw-left-0">
      <div class="tw-max-w-full sgDesktop:tw-max-w-[960px] tw-w-full tw-mx-auto tw-flex tw-justify-between">
        <div>
          <img
            class="tw-h-[100px] sgTablet:tw-h-[140px] tw-absolute tw-left-0 sgTablet:tw-left-8 sgDesktop:tw-relative sgDesktop:tw-left-0 tw-bottom-5 sgTablet:tw-bottom-8"
            :src="$cdn('images/dw2024/personality-quiz/kee-chang-2.png')"
          >
        </div>
        <div>
          <img
            class="tw-h-[100px] sgTablet:tw-h-[140px] tw-absolute tw-right-0 sgTablet:tw-right-8 sgDesktop:tw-relative sgDesktop:tw-right-0 tw-bottom-5 sgTablet:tw-bottom-10"
            :src="$cdn('images/dw2024/personality-quiz/trendy-chang.png')"
          >
        </div>
      </div>
    </div>

    <img
      class="tw-h-[60px] sgTablet:tw-h-[100px] tw-absolute tw-left-4 sgTablet:tw-left-10 sgDesktop:tw-left-20 tw-top-[26px] sgTablet:tw-top-10"
      :src="$cdn('images/dw2024/personality-quiz/bak-chang.png')"
    >
    <img
      class="tw-h-[60px] sgTablet:tw-h-[100px] tw-absolute tw-right-4 sgTablet:tw-right-10 sgDesktop:tw-right-20 tw-top-[26px] sgTablet:tw-top-10"
      :src="$cdn('images/dw2024/personality-quiz/nyonya-chang.png')"
    >
    <div
      class="tw-h-[72px] sgTablet:tw-h-25 tw-flex tw-items-center tw-justify-center tw-w-[calc(100vw-120px-32px)] sgTablet:tw-w-[calc(100vw-200px-80px)] sgDesktop:tw-w-[640px] tw-mx-auto"
    >
      <h2
        :class="
          checkLang() == 'zh-hans'
            ? ' tw-text-center  tw-text-sg-dw24-blue-b60 tw-px-4 sgTablet:tw-px-5 tw-flex tw-place-self-center tw-text-sg-h3-mobile-dw-title-zh sgTablet:tw-text-sg-h3-tablet-dw-title-zh sgDesktop:tw-text-sg-h3-dw-title-zh tw-font-notosans'
            : 'tw-text-sg-dw24-blue-b60 tw-px-4 sgTablet:tw-px-5 tw-flex tw-place-self-center tw-text-center  tw-text-sg-h3-mobile-dw-title sgTablet:tw-text-sg-h3-tablet-dw-title sgDesktop:tw-text-sg-h3-dw-title tw-font-akkuratbold'
        "
      >
        {{ $t('dw_personality_quiz_dumpling_title') }}
      </h2>
    </div>

    <div class="tw-max-w-[640px] tw-w-full tw-mx-auto">
      <template v-if="showCover">
        <div class="tw-flex tw-justify-center tw-items-center tw-h-[444px] sgTablet:tw-h-[280px]">
          <div class="">
            <button
              class="tw-h-[56px] tw-py-3 tw-px-7 tw-rounded-[56px] tw-relative tw-min-w-[200px] tw-bg-sg-dw24-green-b40 hover:tw-bg-sg-dw24-green active:tw-bg-sg-dw24-green-b60 tw-text-white hover:tw-text-[#1A1A1A] active:tw-text-white tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-cursor-pointer !tw-font-[400] disabled:tw-bg-[#D1D1D1] disabled:tw-text-white disabled:tw-cursor-not-allowed before:tw-content-[''] before:tw-absolute before:tw-top-full before:tw-left-1/2 before:-tw-translate-x-1/2 before:tw-border-[10px] before:tw-border-transparent before:tw-border-t-sg-dw24-green-b40 hover:before:tw-border-t-sg-dw24-green active:before:tw-border-t-sg-dw24-green-b60 before:disabled:tw-border-t-[#D1D1D1] before:tw-border-t-solid"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              @click="startQuiz"
            >
              {{ $t('dw_personality_quiz_lets_start') }}
            </button>

            <img
              class="tw-mt-2 tw-mx-auto tw-w-[140px]"
              :src="$cdn('images/dw2024/personality-quiz/dumpling.png')"
            >
          </div>
        </div>
      </template>
      <template v-else-if="showQuestion">
        <div class="tw-min-h-[84px] tw-mt-5 tw-flex tw-items-center tw-justify-center">
          <div
            class="tw-text-sg-sh2d-mobile sgTablet:tw-text-sg-sh2-tablet-qm sgDesktop:tw-text-sg-sh2-qm tw-text-[#1A1A1A]"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ checkLang() == 'en' ? currentQuestion.title_en : currentQuestion.title_cn }}
          </div>
        </div>
        <div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-4 sgTablet:tw-gap-5 sgDesktop:tw-gap-6 tw-mt-5 sgTablet:tw-mt-7 sgDesktop:tw-mt-9">
            <template
              v-for="([optionKey, optionVal], index) in currentOptions"
              :key="index"
            >
              <div
                class="tw-bg-sg-dw24-green-w20 hover:tw-bg-sg-dw24-green active:tw-bg-sg-dw24-green-b40 tw-rounded-lg tw-h-[76px] tw-text-center tw-py-2 tw-px-3 tw-border-sg-dw24-green hover:tw-border-sg-dw24-green-b20 active:tw-border-sg-dw24-green-b60 tw-border-2 tw-text-sg-sh3 sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-text-[#1A1A1A] active:tw-text-white tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-col-span-2"
                :class="[
                  currentOptions.length == 2 || (index + 1 == currentOptions.length && (index + 1) % 2 != 0)
                    ? 'sgTablet:tw-col-span-2'
                    : 'sgTablet:tw-col-span-1',
                ]"
                :style="{
                  boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.06),0px 0px 4px 0px rgba(0,0,0,0.04)',
                }"
                @click="validateAnswer(optionKey)"
              >
                <span v-html="checkLang() == 'en' ? optionVal.option_en : optionVal.option_cn" />
              </div>
            </template>
          </div>
        </div>
      </template>

      <ol
        class="tw-flex tw-items-center tw-w-[min(calc(100vw-200px-24px),260px)] sgTablet:tw-w-[260px] tw-mx-auto tw-mt-9 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-[68px]"
      >
        <li
          v-for="i in totalQuestionNo - 1"
          :key="i"
          class="tw-flex tw-w-full tw-items-center after:tw-content-[''] after:tw-w-full after:tw-h-[2px] after:tw-border-b after:tw-border-sg-dw24-blue-b20 after:tw-border-2 after:tw-inline-block"
        >
          <span
            class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-sg-dw24-blue-b20"
            :class="[i <= currentQuestionNo ? 'tw-bg-sg-dw24-blue-b20' : 'tw-bg-transparent']"
          />
        </li>

        <li class="tw-flex tw-items-center">
          <span
            class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-sg-dw24-blue-b20"
            :class="[currentQuestionNo === totalQuestionNo ? 'tw-bg-sg-dw24-blue-b20' : 'tw-bg-transparent']"
          />
        </li>
      </ol>

      <div
        class="tw-flex tw-items-center tw-justify-center tw-mt-10 sgTablet:tw-mt-[60px]"
        :class="[showCover ? 'tw-text-[#A3A3A3]' : 'tw-text-[#1A1A1A]']"
      >
        <div
          class="tw-py-2 tw-px-4 tw-flex tw-items-center tw-z-[100]"
          :class="{
            'tw-group/duanwu-personality-quiz-dumpling-restart-btn tw-cursor-pointer': !showCover,
          }"
          @click="!showCover ? startQuiz() : null"
        >
          <span class="group-hover/duanwu-personality-quiz-dumpling-restart-btn:tw-underline"> {{ $t('dw_personality_quiz_restart') }}</span>
          <inline-svg
            :fill="showCover ? '#A3A3A3' : '#1A1A1A'"
            :src="$cdn(`/icons/restart_alt.svg`)"
            class="tw-w-6 tw-h-6 tw-ml-2"
          />
        </div>
      </div>
    </div>
    <dw-personality-quiz-result-modal
      v-model="showResult"
      :image="result?.image"
      @on-hide="restartQuiz"
    />
  </section>
</template>
