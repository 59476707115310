<template>
  <article
    ref="article"
    class="
      tw-max-w-none
      tw-prose
      tw-text-sg-remix-body
      prose-em:tw-italic prose-p:tw-m-1 prose-h4:tw-text-sg-h4-mobile tablet:prose-h4:tw-text-sg-h4-tablet desktop:prose-h4:tw-text-sg-h4
      prose-li:tw-my-0 prose-h2:tw-text-sg-sh1
      prose-h4:tw-mt-10 prose-h4:tw-mb-3 tablet:prose-h4:tw-mb-4 desktop:prose-h4:tw-mb-5"
    v-html="$props.data"
  />
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps<{
  data?: string | null
}>();

const article = ref<HTMLElement | null>(null);

onMounted(() => {
  const iframes = article.value?.querySelectorAll('iframe');
  const imgs = article.value?.querySelectorAll('img');

  iframes?.forEach((iframe: HTMLIFrameElement) => {
    // Create a wrapper div with the necessary styles
    const wrapper = document.createElement('div');
    wrapper.style.position = 'relative';
    wrapper.style.overflow = 'hidden';
    wrapper.style.maxWidth = '935px';
    wrapper.style.maxHeight = '526px';
    wrapper.style.paddingBottom = '56.25%'; // 16:9 Aspect Ratio

    // Apply the necessary styles to the iframe
    iframe.style.position = 'absolute';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.left = '0';
    iframe.style.top = '0';
    iframe.style.borderRadius = '8px';
    iframe.style.overflow = 'hidden';

    // Wrap the iframe in the wrapper div
    if (iframe.parentNode) {
      iframe.parentNode.insertBefore(wrapper, iframe);
    }
    wrapper.appendChild(iframe);
  });

  // console.log('imgs', imgs);

  imgs?.forEach((img: HTMLImageElement) => {
    // Calculate the aspect ratio based on the image's original dimensions
    const wrapper = document.createElement('div');
    wrapper.style.position = 'relative';
    wrapper.style.overflow = 'hidden';
    wrapper.style.maxWidth = '935px';

    // Apply the necessary styles to the image
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.borderRadius = '8px';
    img.style.objectFit = 'cover';

    // Wrap the image in the wrapper div
    if (img.parentNode) {
      img.parentNode.insertBefore(wrapper, img);
    }
    wrapper.appendChild(img);
  });


});
</script>
