<template>
  <section
    :class="[
      'sgTablet:tw-px-[80px] tw-px-4',
      'sgTablet:tw-py-10 tw-py-5',
      'tw-bg-sg-ma24-pink-w20 tw-relative'
    ]"
  >
    <div class="tw-flex tw-flex-col tw-gap-[40px]">
      <!-- Official privilege card -->
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-hidden">
        <div>
          <h4
            :class="[
              'tw-text-center tw-pb-5 tw-text-sg-ma-sh2 tw-uppercase tw-text-[#484848]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ce_partner_title_1') }}
          </h4>
          <div class="tw-flex tw-justify-center">
            <img
              class="tw-w-[100px] tw-h-[53px]"
              :src="$cdn('images/experience/ce/privilege-card.png')"
            >
          </div>
        </div>
      </div>
      <!-- Official Community Partners -->
      <div class="tw-flex tw-flex-wrap tw-justify-center">
        <div>
          <h4
            :class="[
              'tw-text-center tw-pb-5 tw-text-sg-ma-sh2 tw-uppercase  tw-text-[#484848]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ce_partner_title_2') }}
          </h4>
          <div class="tw-flex tw-flex-wrap tw-justify-around tablet:tw-justify-center">
            <div
              v-for="(partner, index) in partnersData"
              :key="index"
              class="tw-w-[100px] tw-h-[53px] tw-mx-5"
            >
              <img :src="$cdn(partner.url)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { checkLang } from '../../support/helper';
const partnersData = [{
  id: 1,
  url: 'images/experience/ce/partner-ntuc.png'
},
{
  id: 2,
  url: 'images/experience/ce/partner-safra.png'
},
{
  id: 3,
  url: 'images/experience/ce/partner-hometeamns.png'
},
{
  id: 4,
  url: 'images/experience/ce/partner-merewards.png'
}];
</script>
