<script lang="ts" setup>
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData: any;
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref();

const menuItems = [
  {
    label: 'qm_about_menu_first_offerings',
    id: 'first-offerings-section',
  },
  {
    label: 'qm_about_menu_food_offerings',
    id: 'food-offerings-section',
  },
  {
    label: 'qm_about_menu_paper_offerings',
    id: 'paper-offerings-section',
  },
  {
    label: 'qm_about_menu_other_practices',
    id: 'other-practices-section',
  },
];
const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onMounted(() => {
  if (props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }

  nextTick(() => {
    // Add <sup> click listener
    const aboutDesc = document.getElementById('qm-about');
    if (aboutDesc) {
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#qm-about sup');
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('qm-about');

  if (burningJossCard) {
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#qm-about sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar :is-transparent="false" :is-hidden="false" :qm-navigation="true" />
    <qm-secondary-navigation :menu-items-data="menuItems" />

    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/qm2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            class="tw-text-sg-h1-mobile-qm sgTablet:tw-text-sg-h1-tablet-qm sgDesktop:tw-text-sg-h1-qm tw-pb-1"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('qm_about_title') }}
          </div>
          <div
            class="tw-text-sg-sh1-mobile-dw sgTablet:tw-text-sg-sh1-tablet-dw sgDesktop:tw-text-sg-sh1-dw tw-mt-1"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('qm_about_subtitle') }}
          </div>
        </div>
      </div>
    </div>
    <section class="tw-px-4 sgTablet:tw-px-10 tw-pt-5 sgTablet:tw-pt-10 tw-pb-[120px]">
      <div
        id="qm-about"
        class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
        v-html="wordpressData.description"
      />
    </section>

    <div class="tw-bg-gradient-to-t tw-from-sg-qm25-light-orange tw-to-sg-qm24-light-green-w60">
      <section id="first-offerings-section" class="tw-relative ">
        <img
          :src="$cdn('images/qm2024/about/first-offerings.png')"
          class="tw-absolute -tw-top-[100px] tw-left-1/2 -tw-translate-x-1/2 tw-w-[200px] tw-h-[200px]"
        >
        <div class="tw-px-4 sgTablet:tw-px-10 tw-pt-[120px] tw-pb-[100px]">
          <div class="tw-max-w-[600px] tw-w-full tw-mx-auto">
            <div
              class="tw-text-sg-h2-mobile-qm sgTablet:tw-text-sg-h2-tablet-qm sgDesktop:tw-text-sg-h2-qm tw-text-center tw-text-sg-qm25-green-b40"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('qm_about_first_offerings_title') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-qm sgTablet:tw-text-sg-sh1-tablet-qm sgDesktop:tw-text-sg-sh1-qm tw-text-center tw-mt-5 tw-text-sg-qm25-green-b40"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              v-html="$t('qm_about_first_offerings_subtitle')"
            />
            <p
              class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider tw-mt-6"
            >
              <!-- Here -->

              <span v-html="$t('qm_about_first_offerings_desc')" />
              <span>
                <sup class="tw-text-[#0366D8] tw-cursor-pointer" @click="handleOpenImageSourceModal">[4]</sup>
              </span>
            </p>
          </div>
        </div>
      </section>

      <section id="food-offerings-section" class="tw-relative">
        <img
          :src="$cdn('images/qm2024/about/food-offerings.v2.png')"
          class="tw-absolute -tw-top-[20px] tw-left-1/2 -tw-translate-x-1/2 tw-w-[200px] tw-h-[200px]"
        >
        <div class="tw-px-4 sgTablet:tw-px-10 tw-pt-[200px] tw-pb-[100px]">
          <div class="tw-max-w-[600px] tw-w-full tw-mx-auto tw-space-y-6">
            <div
              class="tw-text-sg-h2-mobile-qm sgTablet:tw-text-sg-h2-tablet-qm sgDesktop:tw-text-sg-h2-qm tw-text-center tw-text-sg-qm25-green-b40"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('qm_about_food_offerings_title') }}
            </div>
            <p
              class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
              v-html="$t('qm_about_food_offerings_desc_1')"
            />
            <div
              class="tw-text-sg-sh1-mobile-qm sgTablet:tw-text-sg-sh1-tablet-qm sgDesktop:tw-text-sg-sh1-qm tw-text-sg-qm25-green-b40"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('qm_about_food_offerings_subtitle') }}
            </div>
            <p
              class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
            >
              <!-- Here -->

              {{ $t('qm_about_food_offerings_desc_2') }}
            </p>
          </div>
        </div>
      </section>

      <section id="paper-offerings-section" class="tw-relative">
        <img
          :src="$cdn('images/qm2024/about/paper-offerings.png')"
          class="tw-absolute -tw-top-[20px] tw-left-1/2 -tw-translate-x-1/2 tw-w-[200px] tw-h-[200px]"
        >
        <div class="tw-px-4 sgTablet:tw-px-10 tw-pt-[200px] tw-pb-10 sgTablet:tw-pb-[60px]">
          <div class="tw-max-w-[600px] tw-w-full tw-mx-auto tw-space-y-6">
            <div
              class="tw-text-sg-h2-mobile-qm sgTablet:tw-text-sg-h2-tablet-qm sgDesktop:tw-text-sg-h2-qm tw-text-center tw-text-sg-qm25-green-b40"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('qm_about_paper_offerings_title') }}
            </div>
            <p
              class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
            >
              {{ $t('qm_about_paper_offerings_desc') }}
            </p>
            <p
              class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
            >
              {{ $t('qm_about_paper_offerings_desc_2') }}
              <span>
                <sup class="tw-text-[#0366D8] tw-cursor-pointer" @click="handleOpenImageSourceModal">[5]</sup>
              </span>
            </p>
          </div>
        </div>
      </section>

    </div>


    <section id="other-practices-section" class="tw-relative ">
      <div class="tw-px-4 sgTablet:tw-px-10 tw-py-5 sgTablet:tw-py-10">
        <div class="tw-max-w-[600px] tw-w-full tw-mx-auto tw-space-y-6">
          <div
            class="tw-text-sg-h2-mobile-qm sgTablet:tw-text-sg-h2-tablet-qm sgDesktop:tw-text-sg-h2-qm tw-text-center tw-text-sg-qm25-green-b40"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('qm_about_other_practices_title') }}
          </div>
          <div
            class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider tw-text-sg-qm25-green-b40 tw-text-center"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            v-html="$t('qm_about_other_practices_subtitle')"
          />
          <div class="tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-6">
            <div class="tw-flex sgTablet:tw-w-[200px] tw-flex-shrink-0">
              <img
                :src="$cdn('images/qm2024/about/other-practices-1.png')"
                class="tw-w-full tw-object-contain tw-place-self-center tw-h-[200px] sgTablet:tw-h-auto"
              >
            </div>
            <div>
              <div
                class="tw-text-sg-sh1-mobile-qm sgTablet:tw-text-sg-sh1-tablet-qm sgDesktop:tw-text-sg-sh1-qm tw-text-sg-qm25-green-b40 tw-text-center sgTablet:tw-text-left"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('qm_about_other_practices_subtitle_1') }}
              </div>
              <p
                class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider tw-mt-6"
              >
                <span v-html="$t('qm_about_other_practices_desc_1')" />
                <span>
                  <sup class="tw-text-[#0366D8] tw-cursor-pointer" @click="handleOpenImageSourceModal">[5]</sup>
                </span>
              </p>
            </div>
          </div>

          <hr class="tw-border-t-sg-qm24-green tw-border-t-2">

          <div class="tw-flex tw-flex-col sgTablet:tw-flex-row-reverse tw-gap-6">
            <div class="tw-flex sgTablet:tw-w-[200px] tw-flex-shrink-0">
              <img
                :src="$cdn('images/qm2024/about/other-practices-2.v2.png')"
                class="tw-w-full tw-object-contain tw-place-self-center tw-h-[200px] sgTablet:tw-h-auto"
              >
            </div>
            <div>
              <div
                class="tw-text-sg-sh1-mobile-qm sgTablet:tw-text-sg-sh1-tablet-qm sgDesktop:tw-text-sg-sh1-qm tw-text-sg-qm25-green-b40 tw-text-center sgTablet:tw-text-left"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('qm_about_other_practices_subtitle_2') }}
              </div>
              <p
                class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider tw-mt-6"
              >
                {{ $t('qm_about_other_practices_desc_2') }}
                <span>
                  <sup class="tw-text-[#0366D8] tw-cursor-pointer" @click="handleOpenImageSourceModal">[5]</sup>
                </span>
              </p>
              <p
                class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider tw-mt-6"
                v-html="$t('qm_about_other_practices_desc_2_note')"
              />
            </div>
          </div>

          <div />
        </div>
      </div>
    </section>

    <ap-image-sources v-model="imageSourceOpen" :data="imageSourcesData" />

    <ap-footer />
  </div>
</template>

<style lang="scss">
#qm-about sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}
</style>
