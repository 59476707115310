<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <ap-article-template
      :title="title"
      :content="wordpressData.the_content"
    />
    <ap-footer />
  </div>
</template>
  
<script lang="ts">
import { onMounted, toRefs } from 'vue';
  
export default {
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { wordpressData } = toRefs(props);
  
    onMounted(() => {
      console.log(wordpressData.value);
    });
  },
  data() {
    let title = 'Discover ten key public policies that made us who we are';
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get('lang');

    if(langParam == 'zh-hans'){
      title = '阅读塑造新加坡华族文化的十个主要公共政策';
    }
    return {
      title,
    };
  },
};
</script>
  