<template>
  <div
    id="cny-conversations"
    class="tw-bg-[#FFC17D] tw-relative tw-flex tw-flex-col tw-justify-between tw-items-center tw-mt-5 sgTablet:tw-mt-10 tw-py-5 sgTablet:tw-py-10 tw-px-4 sgTablet:tw-px-6"
  >
    <!-- <button @click="expandDrawer()">
      rest
    </button> -->
    <div class="tw-h-[572px] tw-rounded-lg tw-border-2 tw-border-[#E1907D] tw-w-full tw-max-w-[1000px] tw-relative tw-bg-white tw-overflow-y-hidden">
      <div class="tw-bg-[#C52600] tw-p-5 tw-rounded-t-md">
        <h2
          class="tw-text-white tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_conversation_window_title') }}
        </h2>
      </div>

      <div class="tw-flex tw-flex-row tw-relative">
        <div
          id="sidebar"
          class="tw-overflow-y-auto tw-overflow-x-hidden tw-transition-all tw-ease-in-out tw-duration-800 tw-h-[500px] tw-flex tw-flex-row tw-absolute tw-z-10 tw-top-0 tw-left-0 sgDesktop:tw-relative tw-w-20 sgDesktop:tw-w-[35%]"
        >
          <div class="tw-bg-[#E1907D] tw-w-full tw-h-full tw-flex tw-flex-col">
            <div
              v-for="(conversationType, index) in conversationTypes"
              :key="index"
              :class="[
                'tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-py-4 sgDesktop:tw-pl-[12px] tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3 sgDesktop:tw-pr-4 conversationType tw-mb-[2px] sgDesktop:tw-min-w-[221px]',
                selectedTypeStyle(conversationType.id),
                {
                  'tw-min-w-[221px]': convoDrawer === true,
                },
              ]"
              @click="changeType(conversationType.id)"
            >
              <div class="sidebarContent tw-bg-white tw-rounded-full tw-w-8 tw-h-8 sgDesktop:tw-w-9 sgDesktop:tw-h-9 tw-ml-3 sgDesktop:tw-ml-0">
                <img :src="$cdn(conversationType.imgSrc)" />
              </div>
              <span
                class="tw-mx-4 tw-hidden sgDesktop:tw-flex tw-transition-opacity tw-ease-in-out tw-duration-1000 !tw-font-normal"
                :class="{ '!tw-flex': convoDrawer === true, 'tw-font-notosansbold': checkLang() == 'zh-hans', 'tw-font-akkuratbold': checkLang() == 'en' }"
                >{{ $t(conversationType.name) }}</span
              >
            </div>
          </div>
          <div class="tw-flex sgDesktop:tw-hidden tw-relative tw-h-full tw-w-[22px] -tw-z-10" @click="expandDrawer()">
            <div
              class="side-bar-button tw-h-[52px] tw-bg-[#E1907D] tw-w-[22px] tw-pt-[19px] tw-pb-4 tw-cursor-pointer tw-rounded-r-lg tw-absolute tw-top-[240px]"
            >
              <!-- <img
                :src="$cdn('images/cny2024/conversation-starters/chevron_right.png')"
              > -->
              <inline-svg
                class="tw-fill-ap-black tw-h-3 tw-w-3 tw-pl-[3px]"
                :src="$cdn(convoDrawer === true ? 'icons/arrow_chevron_left.svg' : 'icons/arrow_chevron_right.svg')"
                alt="Arrow Right"
              />
            </div>
          </div>
        </div>

        <div
          id="general-content"
          class="tw-w-full tw-h-full tw-max-h-[500px] tw-p-5 tw-pl-8 tw-flex tw-flex-wrap tw-overflow-y-scroll tw-ml-[50px] sgDesktop:tw-pl-5"
          @scroll="closeSideBar"
        >
          <div v-if="selectedType != 5" class="tw-w-full tw-h-full">
            <div v-for="(convoPoints, index) in mainConvo" :key="index" class="tw-flex tw-flex-row tw-pb-2 tw-w-full tw-pl-10 sgTablet:tw-pl-[100px]">
              <div class="tw-w-[90%] tw-min-h-[80px] tw-bg-[#FFC17D] tw-rounded-lg">
                <div class="tw-flex tw-flex-col sgTablet:tw-flex-row">
                  <!-- sgTablet:tw-pl-3 sgTablet:tw-pr-5 -->
                  <div
                    v-if="convoPoints?.conversation_image"
                    class="tw-pt-2 tw-pl-1 sgTablet:tw-pt-0 tw-max-h-[100px] tw-max-w-[175px] tablet:tw-max-w-[100px] tw-self-center"
                  >
                    <!-- tw-p-3  -->
                    <img
                      :src="convoPoints?.conversation_image"
                      class="tw-m-auto sgTablet:tw-m-0 sgTablet:tw-pt-0 tw-max-h-[88px] tw-max-w-[175px] tablet:tw-max-w-[100px]"
                    />
                  </div>
                  <!-- sgTablet:tw-w-[70%] sgDesktop:tw-w-[80%] -->
                  <div
                    class="tw-w-[100%] tw-p-3 sgTablet:tw-py-5 sgTablet:tw-pl-3 sgTablet:tw-pr-5"
                    :class="{ 'tw-flex tw-items-center': convoPoints?.external_link === '' }"
                  >
                    <div
                      class="tw-text-sg-sh3-mobile-cny tablet:tw-text-sg-sh3-tablet-cny desktop:tw-text-sg-sh3-cny"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                      v-html="convoPoints?.conversation_content"
                    />
                    <div v-if="convoPoints?.external_link != ''" class="tw-pt-3 tw-flex tw-justify-center sgTablet:tw-justify-end">
                      <a
                        class="tw-h-[32px] tw-py-[5px] tw-px-0 sg:tablet:tw-px-3 tw-w-fit tw-text-[#8C12D4] tw-cursor-pointer hover:tw-underline"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                        :href="convoPoints?.external_link"
                        target="'blank'"
                      >
                        {{ $t('find_out_more') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-w-[52px] sgTablet:tw-w-[56px] tw-py-5 tw-flex tw-flex-row">
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[16px] tw-mr-2">
                  <img v-show="index == 0" :src="$cdn('images/cny2024/conversation-starters/arrow-orange.svg')" />
                </div>
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[32px]">
                  <img v-show="index == 0" class="tw-min-w-[16px]" :src="$cdn('images/cny2024/conversation-starters/anon-user.png')" />
                </div>
              </div>
            </div>
          </div>

          <div v-else id="counter-content" class="tw-w-full tw-h-full" @scroll="closeSideBar">
            <div
              v-for="(convoPoints, index) in mainConvo"
              :key="index"
              :class="['tw-flex tw-flex-row tw-pb-2 tw-w-full', index % 2 != 0 ? 'tw-pl-10 sgTablet:tw-pl-[100px]' : 'tw-pr-10 sgTablet:tw-pr-[100px]']"
            >
              <div v-if="index % 2 == 0" class="tw-w-[52px] sgTablet:tw-w-[56px] tw-py-5 tw-flex tw-flex-row tw-justify-end">
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[32px]">
                  <img :src="$cdn('images/cny2024/conversation-starters/anon-user.png')" class="tw-min-w-[16px]" />
                </div>
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[16px] tw-ml-2">
                  <img :src="$cdn('images/cny2024/conversation-starters/arrow-yellow.svg')" />
                </div>
              </div>
              <div :class="['tw-w-[90%] tw-min-h-[80px] tw-rounded-lg sgDesktop:tw-p-[10px]', index % 2 != 0 ? 'tw-bg-[#FFC17D]' : 'tw-bg-[#FFF1CD]']">
                <div class="tw-flex tw-flex-col-reverse sgTablet:tw-flex-row">
                  <div class="tw-w-full tw-p-3 sgTablet:tw-py-5 sgTablet:tw-pl-3 sgTablet:tw-pr-5 tw-flex tw-flex-wrap tw-content-center">
                    <div class="tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3" v-html="convoPoints?.conversation_content" />
                  </div>
                  <div
                    v-if="index % 2 == 0 && convoPoints?.conversation_image"
                    class="tw-pt-2 sgTablet:tw-pt-0 tw-max-h-[80px] tw-max-w-[100px] tw-self-center"
                  >
                    <!-- tw-p-3 sgTablet:tw-pl-3 sgTablet:tw-pr-5 -->
                    <img :src="convoPoints?.conversation_image" class="tw-m-auto sgTablet:tw-m-0 xtw-pt-3 sgTablet:tw-pt-0 tw-max-h-[80px] tw-max-w-[100px]" />
                  </div>
                </div>
              </div>
              <div v-if="index % 2 != 0" class="tw-w-[52px] sgTablet:tw-w-[56px] tw-py-5 tw-flex tw-flex-row tw-justify-start">
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[16px] tw-mr-2">
                  <img :src="$cdn('images/cny2024/conversation-starters/arrow-orange.svg')" />
                </div>
                <div class="tw-h-full tw-w-full tw-max-h-[32px] tw-max-w-[32px]">
                  <img :src="$cdn('images/cny2024/conversation-starters/anon-user.png')" class="tw-min-w-[16px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount } from 'vue';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../support/helper';

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    conversationStarters?: any | null;
  }>(),
  {
    conversationStarters: null,
  }
);

const conversationTypes = [
  {
    id: 1,
    name: 'cny_conversation_older_generation',
    imgSrc: 'images/cny2024/conversation-starters/older-gen.png',
  },
  {
    id: 2,
    name: 'cny_conversation_young_adults',
    imgSrc: 'images/cny2024/conversation-starters/young-adult.png',
  },
  {
    id: 3,
    name: 'cny_conversation_youths',
    imgSrc: 'images/cny2024/conversation-starters/youths.png',
  },
  {
    id: 4,
    name: 'cny_conversation_kids',
    imgSrc: 'images/cny2024/conversation-starters/kids.png',
  },
  {
    id: 5,
    name: 'cny_conversation_counters',
    imgSrc: 'images/cny2024/conversation-starters/counters.png',
  },
];

const olderGenConvo = ref([]);
const youngAdultConvo = ref([]);
const youthsConvo = ref([]);
const kidsConvo = ref([]);
const countersConvo = ref([]);
const mainConvo = ref([{}]);

let convoTimeout = ref();

const selectedType = ref(1);

const convoDrawer = ref(false);

const isMobileTabletView = ref(window.innerWidth > 1024 ? false : true);

onMounted(() => {
  window.addEventListener('resize', handleStarterWindowResize);

  populateConvoData();
  // console.log('check', props.conversationStarters,   convoDrawer.value);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleStarterWindowResize);
});

const handleStarterWindowResize = () => {
  if (window.innerWidth < 1025) {
    isMobileTabletView.value = true;
  }
};

function populateConvoData() {
  props.conversationStarters.forEach((convo: any) => {
    if (convo.type == 'Older Generation') {
      olderGenConvo.value = convo.data;
    } else if (convo.type == 'Young Adults') {
      youngAdultConvo.value = convo.data;
    } else if (convo.type == 'Youths') {
      youthsConvo.value = convo.data;
    } else if (convo.type == 'Kids') {
      kidsConvo.value = convo.data;
    } else if (convo.type == 'Counters') {
      countersConvo.value = convo.data;
    }
  });

  changeConvoList();
}

function selectedTypeStyle(id: number) {
  if (id == selectedType.value) {
    return 'tw-bg-[#DA745C] tw-font-bold';
  } else {
    return '';
  }
}

function changeType(id: number) {
  selectedType.value = id;
  changeConvoList();
  const generalContentDiv = document.getElementById('general-content');
  const counterContentDiv = document.getElementById('counter-content');

  if (generalContentDiv) {
    generalContentDiv.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (counterContentDiv) {
    counterContentDiv.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}

function changeConvoList() {
  if (selectedType.value == 1) {
    mainConvo.value = olderGenConvo.value;
  } else if (selectedType.value == 2) {
    mainConvo.value = youngAdultConvo.value;
  } else if (selectedType.value == 3) {
    mainConvo.value = youthsConvo.value;
  } else if (selectedType.value == 4) {
    mainConvo.value = kidsConvo.value;
  } else if (selectedType.value == 5) {
    mainConvo.value = countersConvo.value;
  }
}

function expandDrawer() {
  let convoSidebar = document.getElementById('sidebar');
  let convoSidebarContent = document.getElementsByClassName('sidebarContent');
  if (isMobileTabletView.value && !!convoSidebar) {
    if (convoSidebar.classList.contains('tw-w-20')) {
      convoSidebar.classList.remove('tw-w-20');
      // for(var i = 0; i < convoSidebarContent.length; i++)
      // {
      //   convoSidebarContent[i].classList.remove('tw-m-auto');
      // }
      // slightly timeout to avoid css transition issue
      clearTimeout(convoTimeout.value);
      convoTimeout.value = setTimeout(() => {
        convoSidebar.classList.add('tw-w-58');
      }, 2);
      convoDrawer.value = true;
    } else {
      convoSidebar.classList.remove('tw-w-58');
      // for(var i = 0; i < convoSidebarContent.length; i++)
      // {
      //   convoSidebarContent[i].classList.add('tw-m-auto');
      // }
      convoSidebar.classList.add('tw-w-20');
      convoDrawer.value = false;
    }
  } else {
    convoSidebar.classList.add('sgDesktop:tw-w-[35%]');
  }
}

const closeSideBar = () => {
  if (convoDrawer.value === true) {
    expandDrawer();
  }
};
</script>
<style>
.conversationType:hover {
  background-color: #da745c;
  cursor: pointer;
}

.side-bar-button {
  border-radius: 0px, 4px, 4px, 0px !important;
  box-shadow: -4px 4px 10px 0px rgb(0, 0, 0, 0.3);
}
</style>
