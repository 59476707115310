<template>
  <div class="circle tw-min-w-[40px] tw-text-sg-h4-mobile tablet:tw-text-sg-h4-tablet desktop:tw-text-sg-h4">
    {{ $t(`gallery_${number}`) }}
  </div>
</template>

<script lang="ts">
export default {
  props: {
    number: {
      type: [Number, String],
      required: true
    }
  }
};
</script>

  <style scoped>
  .circle {
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border: 4px solid white;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    background: transparent;
  }
  </style>
