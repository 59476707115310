<template>
  <div
    class="headerCustom tw-z-10"
  >
    <div
      ref="inner"
      class="inner tw-z-[-1]"
    />
    <!-- Content -->
    <div
      class="tw-justify-between
      tw-pb-40
      tablet:tw-pb-0
      tablet:tw-pt-40
      desktop:tw-pb-0
      desktop:tw-pt-40"
    >
      <div
        class="tw-flex tw-w-full tw-h-full tw-flex-col tw-justify-start tw-px-4
      tablet:tw-px-10
      tablet:tw-flex-row
      desktop:tw-px-20
      desktop:tw-flex-row"
      >
        <!-- Remix Clickable Column -->
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-pt-8 hover:tw-cursor-pointer  tablet:tw-w-[50%] desktop:tw-w-[50%] tw-gap-4"
        >
          <div
            v-for="(zoneInfo,index) in remixTypeList"
            :key="index"
            class="tw-flex tw-flex-row tw-w-[360px] tw-h-[60px] tw-justify-center "
            @click="onZoneClicked(index+1)"
            @mouseenter="onMouseEnter(index)"
            @mouseleave="onMouseLeave(index)"
          >
            <div
              class="typeBtn tw-flex tw-flex-row tw-w-[328px] tw-h-[52px] tw-rounded-[30px] tw-justify-center tw-bg-white"
            >
              <div
                :class="['btnName tw-relative tw-flex tw-py-4 hover:tw-text-sg-sh2-mobile',zoneInfo.active?'tw-text-sg-sh2-mobile tablet:tw-text-sg-sh2-tablet desktop:tw-text-sg-sh2':'']"
              >
                {{ zoneInfo.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="tw-px-5" />
        <!-- Text Column -->

        <div
          class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-pt-8
          tablet:tw-w-[50%] tablet:tw-pt-0
          desktop:tw-w-[50%] desktop:tw-pt-0 tw-relative tw-min-h-[150px] tablet:tw-h-auto"
          :style="{
            transition:'height 0.3s ease-in-out'
          }"
        >
          <div
            :class="{
              '!tw-opacity-0': currentTypeIndex !=0,

            }"
            class="tw-absolute tw-top-0 tablet:tw-top-auto tw-left-0 tw-opacity-100"
          >
            <p class="tw-text-white tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1 tw-py-2 tablet:tw-py-5 desktop:tw-py-5">
              {{ $t('default_remix_title') }}
            </p>
            <p
              class="tw-text-white"
              :class="{
                'tw-break-keep':checkLang()=='en'
              }"
            > {{ $t('default_remix_desc') }}</p>
          </div>
          <div
            v-for="(remixObj,index) in remixTypeList"
            :key="remixObj.name"
            :class="{
              '!tw-opacity-0': currentTypeIndex != index+1,

            }"
            class="tw-absolute tw-top-0 tablet:tw-top-auto tw-left-0 tw-opacity-100"
            :style="{
              transition:'opacity 0.3s ease-in-out'
            }"
          >
            <span
              v-for="(item,itemIndex) in remixObj.textArray"
              :key="itemIndex"
            >
              <p
                class="tw-text-white tw-text-sg-sh2-mobile tw-py-2
          tablet:tw-text-sg-sh2-tablet tablet:tw-py-5
          desktop:tw-text-sg-sh2 desktop:tw-py-5"
              >
                {{ item.title }}
              </p>
              <p
                class="tw-text-white"
                :class="{
                  'tw-break-keep':checkLang()=='en'
                }"
                v-html="item.description"
              />
            </span>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, Ref, toRefs } from 'vue';
// import log from 'video.js/dist/types/utils/log'; // TODO: Danial: reason?
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../support/helper';

const { t, locale } = useI18n();


const inner: Ref<HTMLElement | null> = ref(null);


let currentTypeIndex:Ref<number> = ref(0);
interface ZoneInfo {
  name: string,
  image: string,
  title: string,
  description: string,
}

const props = defineProps({
  imageRemix1: {
    type: String,
    default: 'https://picsum.photos/1200?random=1'
  },
  imageRemix2: {
    type: String,
    default: 'https://picsum.photos/1200?random=2'
  },
  imageRemix3: {
    type: String,
    default: 'https://picsum.photos/1200?random=3'
  },
  imageRemix4: {
    type: String,
    default: 'https://picsum.photos/1200?random=4'
  },
  imageRemix5: {
    type: String,
    default: 'https://picsum.photos/1200?random=5'
  },
});

let remixTypeList: ZoneInfo[] = [
  {
    name: t('remix_1_name'),
    image: props.imageRemix1,
    textArray:[
      {
        title: t('remix_1-1_title'),
        description: t('remix_1-1_description'),
      },
      {
        title: t('remix_1-2_title'),
        description: t('remix_1-2_description'),
      }
    ],
    bgColour:'#00AFF3',
    active:false
  },
  {
    name: t('remix_2_name'),
    image: props.imageRemix2,
    textArray:[
      {title: t('remix_2_title'),
        description: t('remix_2_description'),}
    ],
    bgColour:'#8BD15D',
    active:false
  },
  {
    name: t('remix_3_name'),
    image: props.imageRemix3,
    textArray:[
      {title: t('remix_3_title'),
        description: t('remix_3_description'),}
    ],
    bgColour:'#F98CDA',
    active:false
  },
  {
    name: t('remix_4_name'),
    image: props.imageRemix4,
    textArray:[
      {title: t('remix_4_title'),
        description: t('remix_4_description'),}
    ],
    bgColour:'#F99A53',
    active:false

  },
  {
    name: t('remix_5_name'),
    image: props.imageRemix5,
    textArray:[
      {title: t('remix_5-1_title'),
        description: t('remix_5-1_description'),},
      {title: t('remix_5-2_title'),
        description: t('remix_5-2_description'),},
      {title: t('remix_5-3_title'),
        description: t('remix_5-3_description'),}
    ],
    bgColour:'#C19AEF',
    active:false
  },
];

const preloadImages = () => {
  remixTypeList.forEach((zone) => {
    const img = new Image();
    img.src = zone.image;
  });
};

onMounted(() => {
  preloadImages();
});
function onZoneClicked(val: number) {
  if (inner.value) {

    setTimeout(() => {
      if (inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${remixTypeList[val - 1].image})`;

      }
    }, 150);
  }
  switch (val) {
    case 1:
      currentTypeIndex.value = 1;
      break;
    case 2:
      currentTypeIndex.value = 2;
      break;
    case 3:
      currentTypeIndex.value = 3;
      break;
    case 4:
      currentTypeIndex.value = 4;
      break;
    case 5:
      currentTypeIndex.value = 5;
      break;
    default:
      break;
  }

  remixTypeList.forEach((item) => {
    item.active = false;
  });

  remixTypeList[val - 1].active = true;
  changeBtnStyling(val - 1,'change');
}

function onMouseEnter(index:number){
  changeBtnStyling(index,'change');
}

function onMouseLeave(index:number){
  changeBtnStyling(index,'leave');
}

function changeBtnStyling(index:number,type:string){
  const btnElement = document.querySelectorAll('.typeBtn');
  btnElement.forEach((element,elIndex) => {
    if(currentTypeIndex.value != elIndex + 1){
      element.style.backgroundColor = 'white';
      element.style.width = '328px';
      element.style.height = '52px';
    }
  });

  if(type == 'change'){
    btnElement[index].style.backgroundColor = remixTypeList[index].bgColour;
    btnElement[index].style.width = '360px';
    btnElement[index].style.height = '60px';
  }

}
</script>

<style scoped>
.headerCustom {
    position: relative;
    width: 100%;
    @apply tablet:tw-h-[100vh];
    /* overflow: hidden; */
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(https://picsum.photos/1200?random=86); */
    background-size: cover;
    /* background-position: center center; */
    background-repeat: no-repeat;
    transition: background-image 0.3s linear;
}

p {
  text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
}

.typeBtn{
  transition:width 0.3s ease-in-out,height 0.3s ease-in-out ,background-color 0.2s ease-in-out;
}

.typeBtn .btnName{
  transition:0.3s ease-in-out;
}


.typeBtn:hover .btnName{
  @apply tw-text-sg-sh2;
}

@media screen and (max-width: 768px) {
  .typeBtn:hover .btnName{
    @apply tw-text-sg-sh2-tablet;
  }
}

@media screen and (max-width: 500px) {
  .typeBtn:hover .btnName{
    @apply tw-text-sg-sh2-mobile;
  }
}

</style>
