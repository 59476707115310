<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="true"
      :is-hidden="false"
      :page-url="page_url"
    />
    <!-- Header -->
    <div class="headerCustom">
      <div
        ref="inner"
        class="inner"
      />
      <!-- VALUES TEXT -->
      <div
        class="tw-absolute tw-flex tw-flex-col tw-justify-end tw-px-4 tw-pb-5 tw-inset-0 tw-max-h-[100vh] tw-max-w-[100vw]
        md:tw-px-10 md:tw-pr-0 md:tw-pb-0 md:tw-flex-row md:tw-justify-between
        lg:tw-pl-20"
      >
        <div class="tw-flex tw-flex-col tw-justify-end">
          <p
            class="tw-uppercase tw-text-white tw-text-sg-dp2-mobile
        md:tw-text-sg-dp2-tablet md:tw-pb-10
        lg:tw-text-sg-dp2"
          >
            {{ $t('values') }}
          </p>
        </div>
        <!--  <img
          class="landscape-hidden tw-hidden xl:tw-block"
          :src="$cdn('images/ren_vector.svg')"
          alt="ren vector"
        > -->
      </div>
    </div>
    <!-- Section 1 -->
    <ap-section-layout
      :section-text-one="topTextSection1"
      :section-text-two="bottomTextSection1"
      :section-image-url="imageSection1"
      :is-first-image="true"
    />
    <!-- Section 2 -->
    <ap-section-layout
      :section-text-one="topTextSection2"
      :section-text-two="bottomTextSection2"
      :section-image-url="imageSection2"
      :is-build-text-one-left="isMobileView"
      :is-second-image="true"
    />
    <!-- SubSection 1 -->
    <ap-section-layout-2
      :section-text="textSubsection1"
      :image-url="imageSubsection1"
    />

    <!-- JangandFox Section -->
    <values-jangandfox-section
      id="SCCCXJangandFox_c"
      :data="jangandfox"
    />

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      v-if="!!imageSources"
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <!-- Books/Articles/Videos/Games Section -->
    <ap-swiper
      :swiper-type="'articles'"
      :articles-data="articlesData"
      :title="$t('swiper_title_articles')"
      :description="$t('deep_dive_into_values')"
    />
    <ap-swiper
      :swiper-type="'videos'"
      :videos-data="videosData"
      :description="$t('press_play_values')"
      :title="$t('swiper_title_videos')"
    />
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script>
import { ref, toRefs, computed, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import {getScreenSize} from '../support/helper';
import { cdn } from '../support/cdn';
import ScrollTrigger from 'gsap/ScrollTrigger';
export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const inner = ref(null);
    const isMobileView = ref(false);

    const {t} = useI18n();

    const { wordpressData } = toRefs(props);

    const topTextSection1 = computed(() => wordpressData.value.top_text_section_1);
    const bottomTextSection1 = computed(() => wordpressData.value.bottom_text_section_1);
    const imageSection1 = computed(() => wordpressData.value.image_section_1);
    const topTextSection2 = computed(() => wordpressData.value.top_text_section_2);
    const bottomTextSection2 = computed(() => wordpressData.value.bottom_text_section_2);
    const imageSection2 = computed(() => wordpressData.value.image_section_2);
    const textSubsection1 = computed(() => wordpressData.value.text_subsection_1);
    const imageSubsection1 = computed(() => wordpressData.value.image_subsection_1);
    const imageSources = computed(() => wordpressData.value.image_sources);
    const page_url = computed(() => wordpressData.value.page_url);
    const jangandfox = computed(() => wordpressData.value?.jangandfox || {});

    const articlesData = computed(()=>[
      {
        id: 1,
        title: t('loyalty'),
        external_url: process.env.WP_SITEURL + '/values/loyalty',
      },
      {
        id: 2,
        title: t('filial_piety'),
        external_url: process.env.WP_SITEURL + '/values/filial-piety',
      },
      {
        id: 3,
        title: t('kindness'),
        external_url: process.env.WP_SITEURL + '/values/kindness',
      },
      {
        id: 4,
        title: t('love'),
        external_url: process.env.WP_SITEURL + '/values/love',
      },
      {
        id: 5,
        title: t('considerate_behaviour'),
        external_url: process.env.WP_SITEURL + '/values/considerate-behaviour',
      },
      {
        id: 6,
        title: t('righteousness'),
        external_url: process.env.WP_SITEURL + '/values/righteousness',
      },
      {
        id: 7,
        title: t('integrity'),
        external_url: process.env.WP_SITEURL + '/values/integrity',
      },
      {
        id: 8,
        title: t('sense_of_shame'),
        external_url: process.env.WP_SITEURL + '/values/sense-of-shame',
      },
      {
        id: 9,
        title: t('thriftiness'),
        external_url: process.env.WP_SITEURL + '/values/thriftiness',
      },
      {
        id: 10,
        title: t('perserverance'),
        external_url: process.env.WP_SITEURL + '/values/perserverance',
      },
    ]);

    const videosData = computed(()=> [
      {
        id: 1,
        title: t('values_videos_1'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=n8AZB9u-rFs',
        image_url: cdn('videos_thumbnails/Values/EP1-The_Tick_Tock_Family.jpg'),
      },
      {
        id: 2,
        title: t('values_videos_2'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=fbhrIVnHM1Q',
        image_url: cdn('videos_thumbnails/Values/EP2-Righteousness.jpg'),
      },
      {
        id: 3,
        title: t('values_videos_3'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=fJK0TCiCgnQ',
        image_url: cdn('videos_thumbnails/Values/EP3-Considerate_Behaviour.jpg'),
      },
      {
        id: 4,
        title: t('values_videos_4'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=Foa-thlmq3g',
        image_url: cdn('videos_thumbnails/Values/EP4-Filial_Piety.jpg'),
      },
      {
        id: 5,
        title: t('values_videos_5'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=ThxFGuRecAE',
        image_url: cdn('videos_thumbnails/Values/EP5-Love.jpg'),
      },
      {
        id: 6,
        title: t('values_videos_6'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=TovgZaaZ-GI',
        image_url: cdn('videos_thumbnails/Values/EP6-Loyalty.jpg'),
      },
      {
        id: 7,
        title: t('values_videos_7'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=FMpsL52K7YA',
        image_url: cdn('videos_thumbnails/Values/EP7-Integrity.jpg'),
      },
      {
        id: 8,
        title: t('values_videos_8'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=Qz1nR3OYG3c',
        image_url: cdn('videos_thumbnails/Values/EP8-Thrift.jpg'),
      },
      {
        id: 9,
        title: t('values_videos_9'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=Dlhmyz2kDEQ',
        image_url: cdn('videos_thumbnails/Values/EP9-Shame.jpg'),
      },
      {
        id: 10,
        title: t('values_videos_10'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=LU5_6NkUjTQ',
        image_url: cdn('videos_thumbnails/Values/EP10-Kindness.jpg'),
      },
    ]);

    onMounted(() => {
      if (wordpressData.value.header_image && inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(${wordpressData.value.header_image})`;
      }

      if (getScreenSize() == 'mobile') {
        inner.value.style.backgroundPosition = 'center center';
        isMobileView.value = true;
      }
      setTimeout(()=>{
        if(window.location.hash.toLowerCase() == '#scccxjangandfox'.toLowerCase()) {
          ScrollTrigger.clearScrollMemory();
          window.history.scrollRestoration = 'manual';
          const element = document.getElementById('SCCCXJangandFox_c');
          const topNav = document.getElementById('top-nav');
          const topNavHeight = topNav.getBoundingClientRect().height;
          const temp = props.offsetY ?? topNavHeight;
          if (element) {
            const elementRect = element.getBoundingClientRect().top;
            const bodyRect = document.body.getBoundingClientRect().top;
            const offset = elementRect - bodyRect - temp;
            window.scrollTo({
              top: offset,
              behavior: 'instant',
            });
          }

        }

      },500);
    });

    return {
      inner,
      topTextSection1,
      bottomTextSection1,
      imageSection1,
      topTextSection2,
      bottomTextSection2,
      imageSection2,
      textSubsection1,
      imageSubsection1,
      page_url,
      articlesData,
      videosData,
      imageSources,
      isMobileView,
      jangandfox
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100lvh;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .landscape-hidden {
    display: none;
  }
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=9); /*Default image*/
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
