<template>
  <div class="tw-flex tw-flex-row">
    <swiper
      id="originCard"
      :space-between="10"
      :breakpoints="{
        280: {
          slidesPerView: 2.2,
        },
        360: {
          slidesPerView: 2.2,
        },
        680: {
          slidesPerView: 4,
        },
      }"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(card, index) in cards"
        :id="'card-' + card.id"
        :key="index"
        class="sectionCard tw-transition-all tw-w-[180px] tw-h-[252px] tw-flex tw-flex-col tw-rounded-lg tw-bg-[#F4F4F4]"
        @click="() => selectedCard(card.id)"
      >
        <div class="tw-h-[180px]">
          <img :src="$cdn('images/cny2024/storiesCard/' + card.imageUrlPath)">
        </div>
        <div
          class="tw-text-[#C52600] tw-py-4 tw-px-5"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          <p>{{ card.title }}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';

const { t } = useI18n();
onMounted(() => {
  selectedCard(1);
});
const emit = defineEmits(['selectStory']);

const props = defineProps<{
  navSelectId: number;
}>();

watch(
  () => props.navSelectId,
  () => {
    selectedCard(props.navSelectId);
  }
);

const cards = [
  {
    id: 1,
    imageUrlPath: 'story.png',
    title: t('cny_the_legend_nian'),
  },
  {
    id: 2,
    imageUrlPath: 'story2.png',
    title: t('cny_the_story_zodiac_race'),
  },
  {
    id: 3,
    imageUrlPath: 'story3.png',
    title: t('cny_bai_tian_gong_festival'),
  },
  {
    id: 4,
    imageUrlPath: 'story4.png',
    title: t('cny_the_origins_of_ren_ri'),
  },
];

function selectedCard(id: number) {
  for (var i = 0; i < cards.length; i++) {
    let cardElm = document.getElementById('card-' + cards[i].id) as HTMLElement;
    if (id == cards[i].id) {
      cardElm.style.border = '2px solid #c52600';
      cardElm.style.backgroundColor = '#ffffff';
    } else {
      cardElm.style.border = '0px solid transparent';
      cardElm.style.backgroundColor = '#F4F4F4';
    }
  }
  emit('selectStory', id);
}

let swiperElm = ref({});

onMounted(() => {
  getSwiperStyle();
});

function onSwiper(swiper) {
  false && console.log(swiper);
  swiperElm.value = swiper;
}

function getSwiperStyle() {}
</script>
<style>
#originCard {
  padding: 10px;
}
#originCard .sectionCard {
  transition: box-shadow 0.5s ease-in;
  transition: background-color 0.2s ease-in;
}
#originCard .sectionCard:hover {
  background-color: #ffffff !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}

@media (max-width: 1025px) {
  #originCard {
    padding: 0;
  }
}
</style>
