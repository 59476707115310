<template>
  <section
    :class="[
      'tw-pt-[60px]',
      'sgDesktop:tw-pb-[40px]',
    ]"
  >
    <swiper
      :slides-per-view="'auto'"
      :space-between="40"
      :centered-slides="true"
      class="mySwiper"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
        :style="`width: ${cardWidth};`"
      >
        <ws-101-other-food-card
          :data="item"
          @open-sources="notifyParent"
        />
        <div class="swiper-lazy-preloader" />
      </swiper-slide>
    </swiper>
    <div
      :class="[
        'tw-flex tw-justify-center tw-items-center tw-gap-[60px]',
        'tw-pt-10',
      ]"
    >
      <div
        :class="[
          'tw-h-[56px] tw-w-[56px]',
          'tw-rounded-full',
        ]"
        @mouseenter="changeButtonState('hover', 0)"
        @mouseleave="changeButtonState('leave', 0)"
        @mousedown="changeButtonState('active', 0)"
        @mouseup="changeButtonState(isHoverLeft ? 'hover' : 'leave', 0)"
        @touchstart="changeButtonState('active', 0)"
        @touchend="changeButtonState(isHoverLeft ? 'hover' : 'leave', 0)"
        @click="swiperInstance?.slidePrev()"
      >
        <img
          :class="[
            'tw-transition-colors tw-duration-200 tw-cursor-pointer'
          ]"
          :src="$cdn(buttonLeft)"
        >
      </div>
      <div class="tw-text-sg-ws-body">
        {{ currentSlideIndex + 1 }} / {{ items.length }}
      </div>
      <div
        :class="[
          'tw-h-[56px] tw-w-[56px]',
          'tw-rounded-full',
        ]"
        @mouseenter="changeButtonState('hover', 1)"
        @mouseleave="changeButtonState('leave', 1)"
        @mousedown="changeButtonState('active', 1)"
        @mouseup="changeButtonState(isHoverRight ? 'hover' : 'leave', 1)"
        @touchstart="changeButtonState('active', 1)"
        @touchend="changeButtonState(isHoverRight ? 'hover' : 'leave', 1)"
        @click="swiperInstance?.slideNext()"
      >
        <img
          :class="[
            'tw-h-[56px] tw-w-[56px]',
            'tw-transition-colors tw-duration-200 tw-cursor-pointer'
          ]"
          :src="$cdn(buttonRight)"
        >
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInstance } from 'swiper';
import 'swiper/css';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
const { t } = useI18n();

const leftButtonDisabled = ref(true);
const rightButtonDisabled = ref(false);
const currentSlideIndex = ref(0);

const buttonLeft = ref('icons/ws/ws_left_chevron_button_disable.svg');
const buttonRight = ref('icons/ws/ws_right_chevron_button_idle.svg');

const isHoverLeft = ref(false);
const isHoverRight = ref(false);

// Function to change button state
// Function to change button state
function changeButtonState(state: string, button: number) {
  // Handle left button (button === 0)
  if (button === 0) {
    if (leftButtonDisabled.value) {
      isHoverLeft.value = false;
      buttonLeft.value = 'icons/ws/ws_left_chevron_button_disable.svg';
    } else {
      switch (state) {
        case 'hover':
          isHoverLeft.value = true;
          buttonLeft.value = 'icons/ws/ws_left_chevron_button_hover.svg';
          break;
        case 'active':
          buttonLeft.value = 'icons/ws/ws_left_chevron_button_active.svg';
          break;
        case 'leave':
          isHoverLeft.value = false;
          buttonLeft.value = 'icons/ws/ws_left_chevron_button_idle.svg';
          break;
        default:
          if (isHoverLeft.value) break;
          buttonLeft.value = 'icons/ws/ws_left_chevron_button_idle.svg';
      }
    }
  }
  // Handle right button (button === 1)
  else if (button === 1) {
    if (rightButtonDisabled.value) {
      isHoverRight.value = false;
      buttonRight.value = 'icons/ws/ws_right_chevron_button_disable.svg';
    } else {
      switch (state) {
        case 'hover':
          isHoverRight.value = true;
          buttonRight.value = 'icons/ws/ws_right_chevron_button_hover.svg';
          break;
        case 'active':
          buttonRight.value = 'icons/ws/ws_right_chevron_button_active.svg';
          break;
        case 'leave':
          isHoverRight.value = false;
          buttonRight.value = 'icons/ws/ws_right_chevron_button_idle.svg';
          break;
        default:
          if (isHoverRight.value) break;
          buttonRight.value = 'icons/ws/ws_right_chevron_button_idle.svg';
      }
    }
  }
}


const items = [
  {
    image: 'images/ws2024/winter-solstice-101/other-food-eaten/nuts.v2.jpg',
    imageHeight: checkLang() == 'en' ?  432 : 472,
    title: t('ws_winter_solstice_101_other_food_title_1'),
    description: t('ws_winter_solstice_101_other_food_description_1'),
  },
  {
    image: 'images/ws2024/winter-solstice-101/other-food-eaten/red-bean-and-glutinous-rice.v2.jpg',
    imageHeight: checkLang() == 'en' ?  392 : 472,
    title: t('ws_winter_solstice_101_other_food_title_2'),
    description: t('ws_winter_solstice_101_other_food_description_2'),
  },
  {
    image: 'images/ws2024/winter-solstice-101/other-food-eaten/mutton-and-vermicelli-soup.v2.jpg',
    imageHeight: checkLang() == 'en' ?  368 : 472,
    title: t('ws_winter_solstice_101_other_food_title_3'),
    description: t('ws_winter_solstice_101_other_food_description_3'),
  },
  {
    image: 'images/ws2024/winter-solstice-101/other-food-eaten/rice-cakes.v2.jpg',
    imageHeight: checkLang() == 'en' ?  384 : 448,
    title: t('ws_winter_solstice_101_other_food_title_4'),
    description: t('ws_winter_solstice_101_other_food_description_4'),
  },
  {
    image: 'images/ws2024/winter-solstice-101/other-food-eaten/wantons.v2.png',
    imageHeight: checkLang() == 'en' ?  312 : 424,
    title: t('ws_winter_solstice_101_other_food_title_5'),
    description: t('ws_winter_solstice_101_other_food_description_5'),
  },
  {
    image: 'images/ws2024/winter-solstice-101/other-food-eaten/dumplings.v2.jpg',
    imageHeight: checkLang() == 'en' ? 264 : 352,
    title: t('ws_winter_solstice_101_other_food_title_6'),
    description: t('ws_winter_solstice_101_other_food_description_6'),
  },
];

const onSlideChange = () => {
  if (swiperInstance?.value) {
    currentSlideIndex.value = swiperInstance?.value.realIndex;
    leftButtonDisabled.value = swiperInstance?.value.isBeginning;
    rightButtonDisabled.value = swiperInstance?.value.isEnd;

    changeButtonState('idle', 0);
    changeButtonState('idle', 1);
  }
};

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const cardWidth = ref('570px');
function setCardWidth() {
  if (window.innerWidth < 680) {
    cardWidth.value = '90%';
  } else {
    cardWidth.value = '500px';
  }
}

const emit = defineEmits(['notifyParent']);

function notifyParent() {
  emit('notifyParent');
}

onMounted(() => {
  setCardWidth();
  window.addEventListener('resize', setCardWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', setCardWidth);
});

</script>
