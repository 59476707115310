<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="true"
      :is-hidden="false"
      :page-url="page_url"
    />
    <!-- Header -->
    <div class="headerCustom">
      <div
        ref="inner"
        class="inner"
      />
      <!-- PRACTICES TEXT -->
      <div
        class="tw-absolute tw-flex tw-flex-col tw-justify-end tw-px-4 tw-pb-5 tw-inset-0 tw-max-h-[100vh] tw-max-w-[100vw]
        md:tw-px-10 md:tw-pr-0 md:tw-pb-0 md:tw-flex-row md:tw-justify-between
        lg:tw-pl-20"
      >
        <div class="tw-flex tw-flex-col tw-justify-end">
          <p
            class="tw-uppercase tw-text-white tw-text-sg-dp2-mobile
        md:tw-text-sg-dp2-tablet md:tw-pb-10
        lg:tw-text-sg-dp2"
          >
            {{ $t('practices') }}
          </p>
        </div>
        <!-- <img
          class="landscape-hidden tw-hidden xl:tw-block"
          :src="$cdn('images/ren_vector.svg')"
          alt="ren vector"
        > -->
      </div>
    </div>
    <!-- Section 1 -->
    <ap-section-layout
      :section-text-one="topTextSection1"
      :section-text-two="bottomTextSection1"
      :section-image-url="imageSection1"
      :is-first-image="true"
    />
    <!-- Section 2 -->
    <ap-section-layout
      :section-text-one="topTextSection2"
      :section-text-two="bottomTextSection2"
      :section-image-url="imageSection2"
      :is-build-text-one-left="isMobileView"
      :is-build-text-two-left="isMobileView"
      :is-second-image="true"
    />
    <!-- SubSection 1 -->
    <ap-section-layout-2
      :section-text="textSubsection1"
      :image-url="imageSubsection1"
    />

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <!-- Books/Articles/Videos/Games Section -->
    <ap-swiper
      :swiper-type="'videos'"
      :videos-data="videosData"
      :description="$t('press_play_practices')"
      :title="$t('swiper_title_videos')"
    />
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script>
import { ref, toRefs, computed, onMounted } from 'vue';
import { getScreenSize } from '../support/helper';
import {useI18n} from 'vue-i18n';
import { cdn } from '../support/cdn';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const inner = ref(null);
    const isMobileView = ref(false);
    const {t} = useI18n();

    const { wordpressData } = toRefs(props);
    const topTextSection1 = computed(() => wordpressData.value.top_text_section_1);
    const bottomTextSection1 = computed(() => wordpressData.value.bottom_text_section_1);
    const imageSection1 = computed(() => wordpressData.value.image_section_1);
    const topTextSection2 = computed(() => wordpressData.value.top_text_section_2);
    const bottomTextSection2 = computed(() => wordpressData.value.bottom_text_section_2);
    const imageSection2 = computed(() => wordpressData.value.image_section_2);
    const textSubsection1 = computed(() => wordpressData.value.text_subsection_1);
    const imageSubsection1 = computed(() => wordpressData.value.image_subsection_1);
    const imageSources = computed(() => wordpressData.value.image_sources);
    const page_url = computed(() => wordpressData.value.page_url);

    const videosData = computed(()=>[
      {
        id: 17,
        title: t('practices_videos_17'),
        description: '',
        video_url: 'https://youtu.be/0usLAhBMsHg?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/guardian_of_the_land.jpeg'),
      },
      {
        id: 1,
        title: t('practices_videos_1'),
        description: '',
        video_url: 'https://youtu.be/pRZ3SLuv8Zs?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Hokkien.jpg'),
      },
      {
        id: 2,
        title: t('practices_videos_2'),
        description: '',
        video_url: 'https://youtu.be/eI87uJfX3gM?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Cantonese.jpg'),
      },
      {
        id: 3,
        title: t('practices_videos_3'),
        description: '',
        video_url: 'https://youtu.be/s4WbvXgqnCw?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Teochew.jpg'),
      },
      {
        id: 4,
        title: t('practices_videos_4'),
        description: '',
        video_url: 'https://youtu.be/IZqTcr_steQ?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Peranakan.jpg'),
      },
      {
        id: 5,
        title: t('practices_videos_5'),
        description: '',
        video_url: 'https://youtu.be/WKP99SxGpBw?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Sweet_Reunions.jpg'),
      },
      {
        id: 6,
        title: t('practices_videos_6'),
        description: '',
        video_url: 'https://youtu.be/1RMTYnujzQM?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/We_Are_Family.jpg'),
      },
      {
        id: 7,
        title: t('practices_videos_7'),
        description: '',
        video_url: 'https://youtu.be/tix-rditgQI?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Making_Bak_Chang.jpg'),
      },
      {
        id: 8,
        title: t('practices_videos_8'),
        description: '',
        video_url: 'https://youtu.be/6oWB2VtDp9k?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Making_Mobile_Mini_Getai.jpg'),
      },
      {
        id: 9,
        title: t('practices_videos_9'),
        description: '',
        video_url: 'https://youtu.be/MvYDy5D9cUs?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Riddle_Me_This.jpg'),
      },
      {
        id: 10,
        title: t('practices_videos_10'),
        description: '',
        video_url: 'https://youtu.be/yGMySXA4ZhE?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Time_for_Tangyuan.jpg'),
      },
      {
        id: 11,
        title: t('practices_videos_11'),
        description: '',
        video_url: 'https://youtu.be/kha08EX3-k4?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Music_Conductor.jpg'),
      },
      {
        id: 12,
        title: t('practices_videos_12'),
        description: '',
        video_url: 'https://youtu.be/K81Jty8rSBc?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Puppeteer.jpg'),
      },
      {
        id: 13,
        title: t('practices_videos_13'),
        description: '',
        video_url: 'https://youtu.be/sq1XG6Cs2f8?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Getai_Singers.jpg'),
      },
      {
        id: 14,
        title: t('practices_videos_14'),
        description: '',
        video_url: 'https://youtu.be/ciyqOqtuv8U?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Author.jpg'),
      },
      {
        id: 15,
        title: t('practices_videos_15'),
        description: '',
        video_url: 'https://youtu.be/a8Zz_nx7IFI?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Graffiti_Artist.jpg'),
      },
      {
        id: 16,
        title: t('practices_videos_16'),
        description: '',
        video_url: 'https://youtu.be/-eqqjD92_Hk?feature=shared',
        image_url: cdn('videos_thumbnails/Practices/Chef.jpg'),
      },
    ]);

    onMounted(() => {
      if (wordpressData.value.header_image && inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(${wordpressData.value.header_image})`;
      }
      if (getScreenSize() == 'mobile' && inner.value) {
        inner.value.style.backgroundPosition = 'center center';
        isMobileView.value = true;
      }
    });

    return {
      inner,
      topTextSection1,
      bottomTextSection1,
      imageSection1,
      topTextSection2,
      bottomTextSection2,
      imageSection2,
      textSubsection1,
      imageSubsection1,
      page_url,
      videosData,
      imageSources,
      isMobileView,
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100svh;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .landscape-hidden {
    display: none;
  }
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=9); /*Default image*/
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
