<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="remix-modal-bg tw-fixed tw-hidden tw-inset-0 tw-bg-opacity-80 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{ '!tw-flex': modelValue == true }"
    >
      <!-- Modal Content -->
      <div>
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5 sgTablet2:tw-mb-10">
          <div
            class="tw-flex tw-items-center tw-cursor-pointer tw-group"
            @click="handleModalClose"
          >
            <p
              class="tw-uppercase tw-mr-1 group-hover:tw-underline"
              :class="[
                'tw-text-sg-remix-bt1 tw-text-white',
                { 'tw-font-akkuratbold': checkLang() == 'en' },
                { 'tw-font-notosansbold': checkLang() != 'en' }
              ]"
            >
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon_white.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div
          :class="[
            'tw-flex tw-flex-col tw-items-center tw-justify-center',
            'tw-max-h-[80vh] tw-h-full',
            'tw-w-[335px] sgTablet:tw-w-[520px] sgTablet2:tw-w-[640px]',
            'tw-gap-5 sgTablet2:tw-gap-10',
          ]"
        >
          <h2
            :class="[
              'tw-text-white tw-text-center',
              'tw-text-sg-ma-mobile-h3 sgTablet:tw-text-sg-ma-tablet-h3 sgDesktop:tw-text-sg-ma-desktop-h3',
              { 'tw-font-akkuratbold': checkLang() == 'en' },
              { 'tw-font-notosansbold': checkLang() != 'en' },
            ]"
          >
            {{ $t('remix_personality_quiz_your_result') }}
          </h2>

          <!-- Image Box -->
          <img
            class="tw-object-contain tw-w-auto tw-object-center tw-max-h-[50vh] tw-mx-auto"
            :style="{
              boxShadow: '-4px 4px 10px 0px rgba(0, 0, 0, 0.3)',
            }"
            :src="image"
          >
          <div>
            <div
              class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-text-center tw-text-white"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
            >
              {{ $t('remix_personality_quiz_download_and_share') }}
            </div>

            <div class="tw-flex tw-items-center tw-justify-center tw-mt-5 sgTablet2:tw-mt-3">
              <a
                href="javascript:void(0)"
                :class="[
                  'tw-h-[32px] tw-px-6 sgTablet2:tw-px-4 sgDesktop:tw-px-6 tw-text-white',
                  { 'tw-font-akkuratbold': checkLang() == 'en' },
                  { 'tw-font-notosansbold': checkLang() != 'en' },
                ]"
                @click="downloadImage(image)"
              >
                <div class="tw-flex tw-flex-row tw-items-center tw-uppercase">
                  {{ $t('remix_personality_quiz_save_image') }}
                  <inline-svg
                    alt="Download Icon"
                    :src="$cdn(`/icons/download_icon_white.svg`)"
                    class="tw-fill-[#0366D8] tw-w-6 tw-h-6 tw-ml-2"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { computed } from 'vue';
import { checkLang } from '../../../../support/helper';

const props = defineProps<{
  modelValue: boolean;
  image: string;
}>();

const emit = defineEmits(['update:modelValue', 'onShow', 'onHide']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if (!newVal) {
    emit('onHide');
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  } else {
    emit('onShow');
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
  }
});

async function downloadImage(imageUrl:string) {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'personality-quiz-result.jpg';
  link.click();

  // Clean up the URL object after the download
  window.URL.revokeObjectURL(link.href);
}
</script>


<style scoped>
.remix-modal-bg{
  background: rgba(26, 26, 26, 0.80);
  backdrop-filter: blur(10px);
}
</style>
