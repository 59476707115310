<template>
  <div :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <div
      :class="[
        'tw-h-full tw-w-full',
      ]"
    >
      <section
        :class="[
          'tw-flex tw-flex-col tw-items-center',
          'tw-px-4 sgTablet:tw-px-[80px]',
          'tw-pt-5 sgTablet:tw-pt-10',
          'tw-text-center tw-text-sg-sccc1',
          'tw-gap-5 sgTablet:tw-gap-10',
        ]"
      >
        <div
          :class="[
            'tw-max-w-[630px]',
            'tw-text-sg-remix-mobile-h2 sgTablet:tw-text-sg-remix-tablet-h2 sgDesktop:tw-text-sg-remix-desktop-h2',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('remix_personality_quiz_title') }}
        </div>
        <div
          :class="[
            'tw-text-sg-sccc1',
            'tw-text-sg-remix-body',
            'tw-max-w-[630px]',
            'tw-mb-[48.92px] sgTablet:tw-mb-0'
          ]"
          v-html="wordpressData.description"
        />

      </section>
      <remix-personality-quiz />
    </div>
    <remix-navigation-bar :can-view="wordpressData.canView" />
    <remix-partner-section class="tw-hidden" />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { checkLang } from '../../../support/helper';

const props = defineProps({
  wordpressData: {
    type: Object,
    default: null,
  },
});

console.log(props.wordpressData);
</script>
