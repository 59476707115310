<template>
  <div class="tw-pt-5 sgTablet:tw-pb-[72px]">
    <swiper
      id="preserving_qing_ming"
      ref="preservingQingMingSwiper"
      :pagination="{
        clickable:true,
        el:'.preserving-qing-ming-swiper-pagination'
      }"
      :slides-per-view="'auto'"
      :center-insufficient-slides="true"
      :slides-per-group="1"
      :initial-slide="0"
      :space-between="20"
      :breakpoints="{
        0:{
          centeredSlides:false,
          centeredSlidesBounds:false,
          slidesOffsetAfter:16,
          slidesOffsetBefore:16,
        },
        600: {
          centeredSlides:false,
          centeredSlidesBounds:false,
          slidesOffsetAfter:40,
          slidesOffsetBefore:40,
        },
        1025:{
          centeredSlides:true,
          centeredSlidesBounds:true,
          slidesOffsetAfter:0,
          slidesOffsetBefore:0,
        },
      }"
      :slides-offset-after="0"
      :slides-offset-before="0"
      @swiper="onSwiper"
      @resize="checkSwiper"
      @transition-end="checkSwiper"
    >

      <swiper-slide
        v-for="(item, index) in props.data"
        :key="index"
        class="!tw-max-w-[320px] tw-w-full tw-relative"
      >
        <div
          class="tw-bg-ap-white tw-flex sgDesktop:tw-flex-row tw-items-center"
          v-html="item.tiktok_embed_code"
        />
      </swiper-slide>
    </swiper>

    <div class="tw-flex tw-flex-row tw-justify-center tw-align-middle tw-relative sgTablet2:tw-hidden tw-pb-5 tw-pt-8">
      <!-- Left Swiper button -->
      <div
        class="tw-h-14 tw-w-14"
        @mouseenter="changeButtonState('hover', 0)"
        @mouseleave="changeButtonState('leave', 0)"
        @mousedown="changeButtonState('active', 0)"
        @mouseup="changeButtonState(isHoverLeft ? 'hover' : 'leave', 0)"
        @touchstart="changeButtonState('active', 0)"
        @touchend="changeButtonState(isHoverLeft ? 'hover' : 'leave', 0)"
        @click="changeSlide('prev')"
      >
        <img
          :src="$cdn(buttonLeft)"
          class="tw-cursor-pointer"
        >
      </div>
      <!-- Current slide number -->
      <div class="tw-mx-[60px] tw-my-4 !tw-p-0 tw-w-fit tw-text-sg-qm25-green-b20">
        {{ currentIndex + 1 }} / {{ props.data.length }}
      </div>
      <!-- Right Swiper button -->
      <div
        class="tw-h-14 tw-w-14"
        @mouseenter="changeButtonState('hover', 1)"
        @mouseleave="changeButtonState('leave', 1)"
        @mousedown="changeButtonState('active', 1)"
        @mouseup="changeButtonState(isHoverRight ? 'hover' : 'leave', 1)"
        @touchstart="changeButtonState('active', 0)"
        @touchend="changeButtonState(isHoverRight ? 'hover' : 'leave', 1)"
        @click="changeSlide('next')"
      >
        <img
          :src="$cdn(buttonRight)"
          class="tw-cursor-pointer"
        >
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Mousewheel, FreeMode} from 'swiper/modules';
import SwiperCore from 'swiper';
import { onBeforeUnmount, onMounted, ref, Ref } from 'vue';
import { nextTick } from 'vue';

SwiperCore.use([Pagination, Mousewheel, FreeMode]);

const datas: Ref<Array<any>> = ref([]);
const preservingQingMingSwiper = ref(null);
const swiperInstance = ref<SwiperInstance>();

const currentIndex = ref(0);
const leftButtonDisabled = ref(true);
const rightButtonDisabled = ref(false);
const buttonLeft = ref('icons/qm/qm_green_left_nav_disabled.png');
const buttonRight = ref('icons/qm/qm_green_right_nav.png');
const isHoverLeft = ref(false);
const isHoverRight = ref(false);

interface PreserveQingming {
  tiktok_embed_code: string;
}

const props = defineProps<{
  data: PreserveQingming[]
}>();

const onSwiper = (swiper:any) => {
  swiperInstance.value = swiper;
  setButtonState();
};

onMounted(() => {
  datas.value = [
    {
      content: 'When visiting the cemetery to clean a tomb, this could involve removing weeds or debris, wiping down the headstone, and ensuring the area around the grave is clean. It’s also customary to bring food offerings and/or flowers as a way of paying respects to the deceased. The act of visiting a tomb to clean and present offerings is to honour the memory of the deceased and demonstrate a sense of care and respect for their final resting place.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/1-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'Remembrance of loved ones is also observed at home on Qingming day by those who practise ancestor worship.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/2-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'As cremation becomes more widespread due to land-scarcity, visiting the cemetery will increasingly be replaced by visitation to the columbarium.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/3-Burning of Joss.png',
      isSingaporenWay: true,
    },
    {
      content: 'When visiting the cemetery to clean a tomb, this could involve removing weeds or debris, wiping down the headstone, and ensuring the area around the grave is clean. It’s also customary to bring food offerings and/or flowers as a way of paying respects to the deceased. The act of visiting a tomb to clean and present offerings is to honour the memory of the deceased and demonstrate a sense of care and respect for their final resting place.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/1-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'Remembrance of loved ones is also observed at home on Qingming day by those who practise ancestor worship.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/2-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'As cremation becomes more widespread due to land-scarcity, visiting the cemetery will increasingly be replaced by visitation to the columbarium.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/3-Burning of Joss.png',
      isSingaporenWay: true,
    },
  ];

  nextTick(() => {
    setButtonState();
  });

  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

function onResize() {
  swiperInstance.value?.update();
  setButtonState();
}

const checkSwiper = (swiper:any) => {
  currentIndex.value = swiper.realIndex;
  setButtonState();
};

function changeSlide(direction:string) {
  if(direction == 'next'){
    if(currentIndex.value + 1 == props.data.length) return;
    swiperInstance.value?.slideNext();
    currentIndex.value += 1;
  }else{
    if(currentIndex.value === 0) return;
    swiperInstance.value?.slidePrev();
    currentIndex.value -= 1;
  }
  setButtonState();
}

function setButtonState() {
  leftButtonDisabled.value = (swiperInstance.value?.isBeginning ?? true);
  rightButtonDisabled.value = (swiperInstance.value?.isEnd ?? false) && currentIndex.value + 1 == props.data.length;
  changeButtonState('idle', 0);
  changeButtonState('idle', 1);
}

// Function to change button state
function changeButtonState(state: string, button: number) {
  // Handle left button (button === 0)
  if (button === 0) {
    if (leftButtonDisabled.value) {
      isHoverLeft.value = false;
      buttonLeft.value = 'icons/qm/qm_green_left_nav_disabled.png';
    } else {
      switch (state) {
        case 'hover':
          isHoverLeft.value = true;
          buttonLeft.value = 'icons/qm/qm_green_left_nav_hover.png';
          break;
        case 'active':
          buttonLeft.value = 'icons/qm/qm_green_left_nav_active.png';
          break;
        case 'leave':
          isHoverLeft.value = false;
          buttonLeft.value = 'icons/qm/qm_green_left_nav.png';
          break;
        default:
          if (isHoverLeft.value) break;
          buttonLeft.value = 'icons/qm/qm_green_left_nav.png';
      }
    }
  }
  // Handle right button (button === 1)
  else if (button === 1) {
    if (rightButtonDisabled.value) {
      isHoverRight.value = false;
      buttonRight.value = 'icons/qm/qm_green_right_nav_disabled.png';
    } else {
      switch (state) {
        case 'hover':
          isHoverRight.value = true;
          buttonRight.value = 'icons/qm/qm_green_right_nav_hover.png';
          break;
        case 'active':
          buttonRight.value = 'icons/qm/qm_green_right_nav_active.png';
          break;
        case 'leave':
          isHoverRight.value = false;
          buttonRight.value = 'icons/qm/qm_green_right_nav.png';
          break;
        default:
          if (isHoverRight.value) break;
          buttonRight.value = 'icons/qm/qm_green_right_nav.png';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.preserving-qing-ming-swiper-pagination {
  padding-top: 12px;
  justify-content: center;
  display: flex;
}

.preserving-qing-ming-swiper-pagination .swiper-pagination-bullet {
  @apply tw-w-3 tw-h-3;
}

.preserving-qing-ming-swiper-pagination .swiper-pagination-bullet-active {
  @apply tw-bg-ap-dim-grey #{!important};
  @apply tw-w-3 tw-h-3;
}

#preserving_qing_ming .tiktok-embed{
  margin:0 auto!important;
}
</style>
