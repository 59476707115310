<template>
  <div
    :id="divId"
  >
    <!-- Text -->
    <div
      class="tw-w-full tw-h-full tw-overflow-hidden tw-bg-fixed tw-flex tw-flex-row tw-mb-40"
      :class="[isTextLeft ? 'tw-justify-start' : 'tw-justify-end']"
    >
      <div
        ref="textHeight"
        class="textContainer tw-w-full tw-flex tw-px-4 tw-pb-20
        tw-flex-wrap tw-flex-col sgTablet:tw-px-20
        tablet:tw-px-10 tablet:tw-pb-12 sgDesktop:tw-px-30
        desktop:tw-px-20 desktop:tw-pb-14 tw-h-fit sgTablet:tw-gap-6 tw-gap-5"
      >
        <h2
          class="text-shadow tw-text-sg-ce-white tw-text-sg-h4-mobile tw-h-fit
        tablet:tw-text-sg-h4-tablet
        desktop:tw-text-sg-h4"
        >
          {{ title }}
        </h2>
        <div
          class="text-shadow tw-text-sg-ce-white tw-text-sg-body-1-mobile tw-h-fit
        tablet:tw-text-sg-body-1-tablet tw-max-w-[590px]
        desktop:tw-text-sg-body-1"
          :class="{
            'tw-break-keep': checkLang() == 'en'
          }"
          v-html="text"
        />

        <a
          v-if="showButton"
          :href="eventsUrl"
          class="tw-flex tw-text-sg-body-4-mobile tw-items-center tw-justify-center tw-rounded-[20px]
         tw-border-2 tw-border-white tw-text-sg-ce-white tw-w-fit tw-mb-12
        hover:tw-border-[#8016B2] hover:tw-text-sg-sccc1 active:tw-border-none active:tw-bg-white active:tw-text-sg-sccc1
         tw-text-center tw-uppercase tw-px-6 tw-py-3 tw-max-h-[40px]"
          @click="handleClick"
        >
          <span>{{ $t('ce_swiper_main_btn') }}</span>
        </a>
      </div>


    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref } from 'vue';
import { checkLang } from '../../../support/helper';

export default {
  props: {
    title: {
      type: String,
      default: 'Section Text'
    },
    text: {
      type: String,
      default: 'Section Text'
    },
    isTextLeft: {
      type: Boolean,
      default: true
    },
    divId: {
      type: String,
      default: '',
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const eventsUrl = computed<string>(() => {
      const baseUrl: string = import.meta.env.VITE_WP_SITEURL || '';
      return checkLang() !== 'zh-hans'
        ? `${baseUrl}/experience/ce/events`
        : `${baseUrl}/experience/ce/events/?lang=zh-hans`;
    });

    function handleClick(event: Event) {
      event.preventDefault();
      window.location.href = eventsUrl.value;
    }

    return {
      checkLang,
      eventsUrl,
      handleClick
    };
  }
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

p.text-shadow {
  text-shadow: -4px 4px 15px rgba(0, 0, 0, 0.30)
}

span.text-shadow {
  text-shadow: -4px 4px 15px rgba(0, 0, 0, 0.30)
}
</style>
