<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { h, ref} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref();
const section2TextRef = ref<HTMLParagraphElement | null>(null);
const section3TextRef = ref<HTMLParagraphElement | null>(null);
const section2ImgRef = ref<HTMLDivElement | null>(null);
const section3ImgRef = ref<HTMLDivElement | null>(null);
const menuItems = [
  {
    label:'dw_origin_stories_menu_origin_story',
    id:'origin-stories-section'
  }
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onBeforeMount(()=>{
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

onMounted(() => {

  recalculateHeight();
  window.addEventListener('resize', recalculateHeight);
});
onBeforeUnmount(()=>{
  window.removeEventListener('resize', recalculateHeight);
});

function recalculateHeight(){
  requestAnimationFrame(()=>{

    if(section2TextRef.value){
      let height = section2TextRef.value.getBoundingClientRect().height;
      height = height > 300 ? height : 300;
      section2ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }

    if(section3TextRef.value){
      let height = section3TextRef.value.getBoundingClientRect().height;
      height = height > 300 ? height : 300;
      section3ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }
  });
}
</script>

<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :dw-navigation="true"
    />
    <dw-secondary-navigation
      :menu-items-data="menuItems"
    />
    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/dw2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            :class="checkLang() == 'zh-hans'? 'tw-text-sg-h1-mobile-dw-about-title-zh sgTablet:tw-text-sg-h1-tablet-dw-about-title-zh sgDesktop:tw-text-sg-h1-dw-about-title-zh tw-pb-1 tw-font-notosansbold': 'tw-text-sg-h1-mobile-dw-about-title sgTablet:tw-text-sg-h1-tablet-dw-about-title sgDesktop:tw-text-sg-h1-dw-about-title tw-pb-1 tw-font-akkuratbold'"
          >
            {{ $t('dw_origin_stories_title') }}
          </div>
        </div>
      </div>
    </div>

    <div
      id="origin-stories-section"
      class="tw-relative"
    >

      <img
        :src="$cdn('images/dw2024/about/deco_left.png')"
        class="tw-absolute tw-left-0 tw-top-[100px]
        tw-w-[100px] sgDesktop:tw-w-[160px]
        tw-hidden sgTablet:tw-block"
      >
      <img
        :src="$cdn('images/dw2024/about/deco_right.png')"
        class="tw-absolute tw-right-0 tw-top-[200px]
        tw-w-[100px] sgDesktop:tw-w-[160px]
        tw-hidden sgTablet:tw-block"
      >

      <img
        :src="$cdn('images/dw2024/about/deco2_left.png')"
        class="tw-absolute tw-left-0 tw-bottom-[200px]
        tw-w-[100px] sgDesktop:tw-w-[160px]
        tw-hidden sgTablet:tw-block"
      >
      <img
        :src="$cdn('images/dw2024/about/deco2_right.png')"
        class="tw-absolute tw-right-0 tw-bottom-[100px]
        tw-w-[100px] sgDesktop:tw-w-[160px]
        tw-hidden sgTablet:tw-block"
      >
      <div class="tw-max-w-full sgTablet:tw-max-w-[min(800px,100vw-200px)] sgDesktop:tw-max-w-[min(800px,100vw-320px)] tw-w-full tw-mx-auto tw-px-4 sgTablet:tw-px-5 sgDesktop2:tw-px-0 tw-py-5 sgTablet:tw-py-10 ">
        <section
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
        >
          <p v-html="$t('dw_origin_stories_section_1_desc')" />
        </section>

        <section
          class="tw-mt-6
        tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
        "
        >
          <div

            class="tw-grid tw-grid-cols-1 sgTablet:tw-grid-cols-2"
          >
            <div
              ref="section2ImgRef"
              :style="{
                '--text-height':'0px'
              }"
              class="tw-col-span-1 tw-flex"
            >
              <img
                :src="$cdn('images/dw2024/origin-stories/drum.png')"
                class="tw-place-self-center tw-w-full tw-object-contain tw-h-[200px] sgTablet:tw-h-auto  sgTablet:tw-max-h-[--text-height]"
              >
            </div>
            <div class="tw-col-span-1 tw-flex tw-place-self-center">
              <p
                ref="section2TextRef"
                v-html="$t('dw_origin_stories_section_2_desc')"
              />
            </div>
          </div>
        </section>

        <section
          class="tw-mt-6
        tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
        >
          <div class="tw-grid tw-grid-cols-1 sgTablet:tw-grid-cols-2 tw-gap-6">

            <div

              class="tw-col-span-1 tw-order-2 sgTablet:tw-order-1  tw-flex tw-place-self-center"
            >
              <p ref="section3TextRef">
                {{ $t('dw_origin_stories_section_3_desc') }}
                <span><sup
                  class="tw-text-[#0366D8] tw-cursor-pointer"
                  @click="handleOpenImageSourceModal"
                >[1]</sup></span>
                <span><sup
                  class="tw-text-[#0366D8] tw-cursor-pointer"
                  @click="handleOpenImageSourceModal"
                >[2]</sup></span>
              </p>
            </div>

            <div
              ref="section3ImgRef"
              :style="{
                '--text-height':'0px'
              }"
              class="tw-col-span-1 tw-flex tw-order-1 sgTablet:tw-order-2"
            >
              <img
                :src="$cdn('images/dw2024/origin-stories/boat.png')"
                class="tw-place-self-center tw-w-full tw-object-contain tw-h-[200px] sgTablet:tw-h-auto sgTablet:tw-max-h-[--text-height]"
              >
            </div>
          </div>
        </section>



      </div>
    </div>

    <section class="tw-bg-sg-dw24-blue-w60 ">
      <div class="tw-max-w-[800px] tw-mx-auto tw-flex tw-flex-row tw-justify-center">
        <div class="tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-y-10 sgTablet:tw-gap-y-10 tw-gap-x-10 tw-justify-between tw-px-4 tw-py-5 sgTablet:tw-px-10 sgTablet:tw-py-10">
          <div class="tw-w-[200px] tw-flex tw-self-center">
            <img
              class="tw-max-w-[200px]"
              :src="$cdn('images/dw2024/origin-stories/dumpling.png')"
            >
          </div>
          <div class="tw-flex tw-flex-col tw-justify-center">
            <div
              class="tw-text-[#1A1A1A] tw-max-w-[343px] sgTablet:tw-max-w-[343px] tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1 tw-text-center sgTablet:tw-text-left"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
            >
              {{ $t('dw_origin_stories_section_4_desc') }}
            </div>
            <a
              v-if="props.wordpressData.find_out_more_external_link"
              class=" "
              :class="checkLang() == 'zh-hans'? 'tw-bg-sg-dw24-green-b40 hover:tw-opacity-60 tw-rounded-[26px] tw-h-[52px] tw-px-6 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer tw-mt-10 tw-self-center sgTablet:tw-self-start tw-text-sg-dw-button-mobile-1-zh tablet:tw-text-sg-dw-button-1-zh tw-font-notosans': 'tw-bg-sg-dw24-green-b40 hover:tw-opacity-60 tw-rounded-[26px] tw-h-[52px] tw-px-6 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer tw-mt-10 tw-self-center sgTablet:tw-self-start tw-text-sg-dw-button-mobile-1 tablet:tw-text-sg-dw-button-1  tw-font-akkurat'"

              :href="props.wordpressData.find_out_more_external_link"
              rel="noopener"
              target="_blank"
            >
              <span class="tw-text-white tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 tw-uppercase">{{ $t('dw_origin_stories_find_out_more') }}</span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>
