<template>
  <div
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <swiper
      id="CESwiper"
      :modules="[FreeMode,Mousewheel,Autoplay]"
      :slides-per-view="2.5"
      :space-between="10"
      :auto-height="true"
      class="tw-py-10"
      :breakpoints="{
        680:{
          slidesPerView:2.5,
          spaceBetween:20,
        },
        800:{
          slidesPerView:4.5,
          spaceBetween:20,
        },
        1280:{
          slidesPerView:6,
          spaceBetween:20,
        },
        1441:{
          slidesPerView:12,
          spaceBetween:20,
        },
        2640:{
          slidesPerView:22,
          spaceBetween:20,
        },
      }"
      :free-mode="{
        enabled:true,
        momentum:false,
      }"
      :autoplay="{
        disableOnInteraction:false,
        pauseOnMouseEnter:false,
        waitForTransition:true,
        delay:0
      }"
      :speed="5000"
      loop
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(event,index) in events"
        :key="index"
        class="tw-rounded-md tw-bg-white !tw-h-[236px] tablet:!tw-h-auto"
      >
        <div class="tw-rounded-t-md tw-h-[92px] tablet:tw-h-[146px] tw-overflow-hidden tw-relative">
          <img
            class="tw-h-full tw-w-full tw-object-cover"
            :src="event.acf.event_gallery?event.acf.event_gallery[0].url:''"
            :class="(event.acf.event_is_paid_event && event.acf.event_status =='2') || (!event.acf.event_is_paid_event && event.acf.event_status =='1') ? 'tw-brightness-50' : ''"
          >
        </div>

        <div class="swiper-slide-desc tw-h-[144px] tablet:tw-h-[136px] tw-flex tw-flex-col tw-flex-wrap tw-justify-between">
          <div class="tw-pt-3 tw-px-4 tw-h-[92px] tablet:tw-h-[80px] tw-line-clamp-4 tablet:tw-line-clamp-3">
            <p class="tw-text-sg-body-2">
              {{ getEventTypeText(event.acf.event_type) }}
            </p>
            <p class="tw-text-sg-sh3">
              {{ event.title }}
            </p>
          </div>
          <div class="tw-px-4 tw-flex tw-justify-center tablet:tw-justify-end">
            <a
              class="tw-py-2 tw-px-3 !tw-text-sg-label-5 tw-uppercase tw-text-[#20269E] "
              :href="checkLang()=='en'?event.permalink:`${event.permalink}?lang=zh-hans`"
            >
              <span class="swiper-btn-text tw-flex tw-whitespace-pre tw-items-center">
                {{ t('ce_swiper_btn') }}<img
                  class="tw-h-3 tw-w-3 tw-ml-2"
                  :src="$cdn('images/experience/ce/arrow_forward_ios.png')"
                >
              </span>
            </a>
          </div>
        </div>


      </swiper-slide>
    </swiper>
    <div class="tw-flex tw-justify-center">
      <a
        class="catBtn tw-rounded-[32px] tw-bg-[#00D6C6] tw-text-black hover:tw-bg-[#49B194] active:tw-text-white active:tw-bg-[#12654E] !tw-text-sg-body-bold-2  tw-py-[18px] tw-uppercase tw-h-[52px] tw-w-[175px] tw-flex tw-justify-center tw-items-center tw-text-center !tw-leading-[22px]"
        :href="goToEventsPage()"
        target="_self"
      >
        {{ t('ce_swiper_main_btn') }}
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade } from 'swiper/modules';
import {FreeMode,Mousewheel,Autoplay} from 'swiper/modules';
// import { checkLang } from '../../../support/helper';
import { langRedirect } from '../../../support/langRedirect';


import SwiperCore from 'swiper/core';
import { onBeforeMount,ref,computed } from 'vue';
import { cdn } from '../../../support/cdn';
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import {  Event } from '../../../types/types';
import { checkLang } from '../../../support/helper';

let events = ref<Array<Event>>([]);
let swiperObj = ref();
const { t } = useI18n({ useScope: 'global' });
SwiperCore.use([EffectFade]);
SwiperCore.use([Mousewheel]);
SwiperCore.use([FreeMode]);
SwiperCore.use([Autoplay]);

const onSwiper = (swiper:any) => {
  swiperObj.value = swiper;
};
const onMouseEnter = () => {
  swiperObj.value?.autoplay.stop();
};
const onMouseLeave = () => {
  swiperObj.value?.autoplay.start();
};

function getFeaturedEvents() {

  let currentDomain = process.env.WP_SITEURL;

  if (currentDomain === undefined) {
    return;
  }

  let url = currentDomain + '/wp-json/wp/v2/events?per_page=12&page=1&featured=1';

  axios.get(url)
    .then((response) => {
      let data: Array<any> = response.data.data;

      events.value = [];

      for (let event of data) {
        events.value.push({
          id: event.ID,
          title: event.post_title,
          date: event.post_date,
          content: event.content.rendered,
          permalink: event.link,
          acf: {
            event_date: event.acf.event_date,
            event_card_date: event.acf.event_card_date,
            event_featured: event.acf.event_featured,
            event_location: event.acf.event_location,
            event_present_by: event.acf.event_present_by,
            event_ticket_price: event.acf.event_ticket_price,
            event_type: event.acf.event_type,
            event_visit_page_link: event.acf.event_visit_page_link,
            event_gallery: event.acf.event_gallery ?? [],
            event_is_paid_event: event.acf.event_is_paid_event,
            event_status: event.acf.event_status,
          }
        });
      }

      let defaultCount = 12;
      if(window.innerWidth >= 1440){
        defaultCount = 48;
      }
      const neededCount = events.value.length > defaultCount ?  events.value : defaultCount;

      const copyCount = Math.floor(neededCount / events.value.length);

      events.value = Array.from({length:copyCount}).flatMap(()=> events.value);

      swiperObj.value?.autoplay.start();
    })
    .catch((error) => {
      console.error(error);
    });
}

function getEventTypeText(id:string) {
  let text = '';

  switch (id) {
    case '0':
      text = t('event.type_concert');
      break;
    case '1':
      text = t('event.type_theatre');
      break;
    case '2':
      text = t('event.type_exhibition');
      break;
    default:
      text = '';
      break;
  }

  return text;

}

function goToEventsPage() {
  return checkLang() != 'zh-hans' ? process.env.WP_SITEURL + '/experience/ce/events' : process.env.WP_SITEURL + '/experience/ce/events/?lang=zh-hans';
}

onBeforeMount(() => {
  getFeaturedEvents();
});

</script>
<style>
@media (min-width: 1440px) {
  #CESwiper .swiper-slide{
    width:260px!important;
  }
}
#CESwiper .swiper-slide{
  box-shadow: 0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A;
}

#CESwiper .swiper-slide:hover .swiper-slide-desc{
  background-color: rgba(0, 0, 0, 0.06);
}

#CESwiper .swiper-slide:hover  .swiper-btn-text{
  text-decoration: underline;
}
/*
.catBtn:hover{
  background-color: #49B194;
}

.catBtn:active{
  background-color: #12654E;
} */

#CESwiper .swiper-wrapper{
  transition-timing-function: linear !important;
}
</style>
