<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :qm-navigation="true"
    />
    <qm-secondary-navigation
      :menu-items-data="menuItems"
    />

    <section id="qm-explore-about ">
      <div class="tw-relative">
        <picture>
          <img
            :src="$cdn('images/qm2024/banner.scaled.jpg')"
            class="tw-w-full tw-object-cover tw-max-h-40 sgTablet:tw-max-h-60 sgDesktop:tw-max-h-[280px]"
          >
        </picture>

        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-qm sgTablet:tw-text-sg-h1-tablet-qm sgDesktop:tw-text-sg-h1-qm tw-pb-1"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
            >
              {{ $t('qm_qingming_101') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-mt-1"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold tw-text-sg-sh1-mobile-qm sgTablet:tw-text-sg-sh1-tablet-qm sgDesktop:tw-text-sg-sh1-qm'"
            >
              {{ $t('qm_qingming_101_navigating') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="tw-max-w-[800px] tw-m-auto tw-py-5 tw-px-10 sgTablet2:tw-px-0 tw-pt-5 sgTablet:tw-pt-10 tw-pb-25">
      <p class="tw-text-sg-label-3">
        {{ $t('qm_qingming_101_desc') }}
      </p>
    </div> -->

    <div class="tw-px-4 sgTablet:tw-px-10">

      <div
        id="qm-about"
        class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-py-5 tw-pt-5 sgTablet:tw-pt-10 tw-pb-25 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-text-start"

        v-html="wordpressData.description"
      />
    </div>


    <!-- Observation -->
    <section class="tw-bg-[#DFF4EA] tw-flex tw-flex-col tw-items-center">
      <img
        class="-tw-mt-[90px] tw-max-w-[200px]"
        :src="$cdn('images/qm2024/qingming-101/1-Observation.png')"
      >
      <div class=" tw-px-4 sgTablet:tw-px-10">


        <div
          id="observation_section"
          class="tw-max-w-[800px] tw-pt-5 tw-text-sg-qm24-green-b60 tw-text-center tw-items-center"
        >
          <h3
            class="tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw tw-text-center"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('qm_qm101_observation') }}
          </h3>
          <p
            class="tw-text-sg-sh2-mobile-qm tablet:tw-text-sg-sh2-tablet-qm desktop:tw-text-sg-sh2-qm tw-pt-6"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('qm_qm101_observation_desc') }}
            <span>
              <sup
                class="tw-text-[#0366D8] tw-cursor-pointer"
                @click="handleOpenImageSourceModal"
              >[1]
              </sup>
            </span>
            :
          </p>
        </div>
      </div>
      <!-- Observation Slider -->
      <qm-observation-card
        class="tw-w-full"
        :data="observation_cards"
      />

      <div class="tw-pb-25" />
    </section>

    <!-- Beating The Crowd -->
    <section class="tw-bg-[#D7F1E5] tw-flex tw-flex-col tw-items-center tw-px-4 sgTablet:tw-px-10">

      <img
        class="-tw-mt-[90px] tw-max-w-[200px]"
        :src="$cdn('images/qm2024/qingming-101/2-Beating The Crowd.png')"
      >
      <div
        id="beating_crowd_section"
        class="tw-max-w-[800px] tw-pt-5 tw-pb-25 tw-text-sg-qm24-green-b60 tw-items-center"
      >
        <h3
          class="tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw tw-text-center"
          :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
        >
          {{ $t('qm_qm101_beating_crowd') }}
        </h3>
        <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6">
          {{ $t('qm_qm101_beating_crowd_desc') }}
        </p>

        <p
          class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-2 tw-pb-6"
          v-html="$t('qm_qm101_beating_crowd_desc2')"
        />
      </div>
    </section>

    <!-- Burning of Joss & Incense Paper -->
    <section class="tw-bg-[#CDEDDE] tw-flex tw-flex-col tw-items-center">
      <img
        class="-tw-mt-[90px] tw-max-w-[200px] "
        :src="$cdn('images/qm2024/qingming-101/3-Burning_of_Joss/1-Burning Of Joss.png')"
      >
      <div
        id="burning_joss_section"
        class="tw-w-full tw-pt-5 tw-pb-25 tw-text-sg-qm24-green-b60 tw-text-center tw-items-center tw-flex-col tw-flex tw-justify-center"
      >
        <div class="tw-px-4 sgTablet:tw-px-10">
          <h3
            class="tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw tw-text-center"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('qm_qm101_burning_joss') }}
          </h3>
          <p
            class="tw-text-sg-sh2-mobile-qm tablet:tw-text-sg-sh2-tablet-qm desktop:tw-text-sg-sh2-qm tw-pt-6 tw-max-w-[800px]"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('qm_qm101_burning_joss_desc') }}
          </p>

        </div>
        <!-- Burning Joss Paper Slider -->
        <div class="tw-w-full tw-overflow-x-hidden">
          <qm-burning-joss-card
            v-model:image-source-open="imageSourceOpen"
            :data="burning_of_joss_cards"
          />
        </div>

        <div
          class="tw-px-4 sgTablet:tw-px-10"
        >
          <div class="tw-flex tw-flex-col-reverse sgTablet:tw-flex-row tw-gap-x-10 tw-justify-between tw-max-w-[800px]">
            <div class="tw-text-left tw-flex-col tw-flex tw-justify-center">
              <p
                class="tw-text-sg-body-1-mobile-dw tablet:tw-text-sg-body-1-tablet-dw desktop:tw-text-sg-body-1-dw tw-mt-4"
              >
                <!-- {{ $t('qm_qm101_burning_joss_desc2') }} -->
                <span v-html="$t('qm_qm101_burning_joss_desc2')" />

                <span>
                  <sup
                    v-if="checkLang() === 'zh-hans'"
                    class="tw-text-[#0366D8] tw-cursor-pointer"
                    @click="handleOpenImageSourceModal"
                  >[3]
                  </sup>
                </span>
              </p>
              <p
                v-if="checkLang() !== 'zh-hans'"
                class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-mt-4
                "
              >
                <!-- {{ $t('qm_qm101_burning_joss_desc3') }} -->
                <span v-html="$t('qm_qm101_burning_joss_desc3')" />
                <span>
                  <sup
                    class="tw-text-[#0366D8] tw-cursor-pointer"
                    @click="handleOpenImageSourceModal"
                  >[3]
                  </sup>
                </span>
              </p>
            </div>
            <div class="tw-max-w-[325px] tw-self-center">
              <!-- <blockquote
              class="tiktok-embed tw-bg-sg-qm24-light-green-w40"
              cite="https://www.tiktok.com/@singaporeccc/video/7216166589081718018"
              data-video-id="7216166589081718018"
              style="max-width: 605px;min-width: 325px;"
            >
              <section> <a
                target="_blank"
                rel="noopener"
                title="@singaporeccc"
                href="https://www.tiktok.com/@singaporeccc?refer=embed"
              >@singaporeccc</a> <p>One of the Qingming practices is to burn joss money for our ancestors to enjoy in their afterlife. Do you know? Joss paper can be folded into 3D ingots, which is believed to hold greater value than unfolded paper money. 😮 In this video, Kristin from Ban Kah Hiang Trading shares with us the way she folds ingots!Discover more interesting facts about the festival at 👉 https:&#47;&#47;festivefever.singaporeccc.org.sg&#47;qingming.</p> <a
                target="_blank"
                rel="noopener"
                title="♬ original sound  - SCCC"
                href="https://www.tiktok.com/music/original-sound-SCCC-7217668479783062273?refer=embed"
              >♬ original sound  - SCCC</a> </section>
            </blockquote> -->
              <div
                class="ttc tw-bg-[#CDEDDE] tw-max-w-[325px] tw-self-center"
                v-html="wordpressData.single_tiktok_embed_code"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Preparation Of Food Offerings -->
    <section class="tw-bg-[#C1E8D6] tw-flex tw-flex-col tw-items-center">
      <img
        class="-tw-mt-[90px] tw-max-w-[200px]"
        :src="$cdn('images/qm2024/qingming-101/4-Preparation Of Food Offerings.png')"
      >
      <div class="tw-px-4 sgTablet:tw-px-10">
        <div
          id="preparation_food_section"
          class="tw-max-w-[800px] tw-pt-5 tw-pb-10 tw-text-sg-qm24-green-b60 tw-items-center"
        >
          <h3
            class="tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw tw-text-center"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('qm_qm101_preparation_food') }}
          </h3>
          <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6">
            {{ $t('qm_qm101_preparation_food_desc') }}
            <span>
              <sup
                class="tw-text-[#0366D8] tw-cursor-pointer"
                @click="handleOpenImageSourceModal"
              >[2]
              </sup>
            </span>
          </p>
        </div>
      </div>
    </section>


    <!-- Preserving Qingming Festival-->
    <section class="tw-bg-ap-white tw-flex tw-flex-col tw-items-center tw-mx-auto">

      <div class="tw-pt-5 sgTablet:tw-pt-10 sgDesktop:tw-px-20 tw-px-4 sgTablet:tw-px-10 tw-flex">
        <img
          class="sgTablet:tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-flex tw-h-[364px] tw-object-contain"
          :src="$cdn('images/qm2024/qingming-101/5-Shell_Left.png')"
        >
        <div
          id="preserving_qm_section"
          class="tw-max-w-[800px] tw-text-sg-qm24-green-b60 tw-items-center"
        >

          <h3
            class="tw-text-sg-h2-mobile-dw sgTablet:tw-text-sg-h2-tablet-dw sgDesktop:tw-text-sg-h2-dw tw-text-center"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold tw-font-norma'"
          >
            {{ $t('qm_qm101_preserving_qm') }}
          </h3>
          <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-10">
            {{ $t('qm_qm101_preserving_qm_desc') }}
          </p>
          <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6">
            {{ $t('qm_qm101_preserving_qm_desc2') }}
          </p>
          <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6">
            {{ $t('qm_qm101_preserving_qm_desc3') }}
          </p>
        </div>
        <img
          class="sgTablet:tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-flex tw-h-[364px] tw-object-contain"
          :src="$cdn('images/qm2024/qingming-101/5-Shell_Right.png')"
        >
      </div>
      <!-- Tiktok Slider -->
      <qm-preserving-qing-ming
        class="tw-w-full"
        :data="preserve_qing_ming_tiktok"
      />

    </section>

    <section class="tw-bg-ap-white tw-max-w-[800px] tw-mx-auto tw-flex tw-flex-row tw-justify-center">
      <div class="tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-y-10 sgTablet:tw-gap-y-10 tw-gap-x-10 tw-justify-between tw-px-10 tw-py-5 sgTablet:tw-px-10 sgTablet:tw-py-10 sgDesktop:tw-px-20 sgDesktop:tw-py-10">
        <div class="tw-w-[200px] tw-flex tw-self-center">
          <img
            class="tw-max-w-[200px]"
            :src="$cdn(checkLang() != 'zh-hans' ? 'images/qm2024/qingming-101/6-Singaporen_Way_EN.png' : 'images/qm2024/qingming-101/6-Singaporen_Way_CN-1.png')"
          >
        </div>
        <div class="tw-flex tw-flex-col tw-justify-center">
          <div
            class="tw-text-sg-qm24-green-b60 tw-max-w-[343px] sgTablet:tw-max-w-[343px] tw-text-sg-sh1-mobile-qm tablet:tw-text-sg-sh1-tablet-qm desktop:tw-text-sg-sh1-qm tw-text-center sgTablet:tw-text-left"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('qm_qm101_preserving_qm_practices') }}
          </div>
          <a
            v-if="props.wordpressData.find_out_more_external_link"
            class=" tw-bg-sg-qm24-green hover:tw-opacity-60 tw-rounded-[26px] tw-h-[52px] tw-px-6 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer tw-mt-10 tw-self-center sgTablet:tw-self-start"
            :href="props.wordpressData.find_out_more_external_link"
            target="_blank"
            rel="noopener"
          >
            <span
              class="tw-text-white tw-text-sg-dw-button-mobile-1-zh tablet:tw-text-sg-button-1-qm tw-uppercase"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
            >{{ $t('find_out_more') }}</span>
          </a>
        </div>
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { cdn } from '../../support/cdn';
import { useI18n } from 'vue-i18n';
import { onMounted, ref, nextTick, onBeforeUnmount } from 'vue';
import { checkLang } from '../../support/helper';
import { langRedirect } from '../../support/langRedirect';

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref();

const observation_cards = ref<ObservationCard[]>([]);
const burning_of_joss_cards = ref<BurningJossCard[]>([]);
const preserve_qing_ming_tiktok = ref<PreserveQingming[]>([]);

interface ObservationCard {
  description: string;
  is_singaporen_way: boolean;
}

interface BurningJossCard {
  description: string;
  is_singaporen_way: boolean;
  image: any;
}

interface PreserveQingming {
  tiktok_embed_code: string;
}

onMounted(()=>{
  // console.log('check data', props.wordpressData);
  if(props.wordpressData?.observation_cards) {
    observation_cards.value = props.wordpressData.observation_cards;
  }

  if(props.wordpressData?.burning_of_joss_cards) {
    burning_of_joss_cards.value = props.wordpressData.burning_of_joss_cards;
  }

  if(props.wordpressData?.preserve_qing_ming_tiktok) {
    preserve_qing_ming_tiktok.value = props.wordpressData.preserve_qing_ming_tiktok;
  }

  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }

  nextTick(() => {

    // Add <sup> click listener
    const aboutDesc = document.getElementById('qm-qm101');
    if(aboutDesc){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#qm-qm101 sup');
      supElements.forEach((supElement) => {

        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('qm-qm101');

  if(burningJossCard){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#qm-qm101 sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const menuItems = [
  {
    label:'qm_qm101_menu_observation',
    id:'observation_section'
  },
  {
    label:'qm_qm101_menu_beating_crowd',
    id:'beating_crowd_section'
  },
  {
    label:'qm_qm101_menu_burning_joss',
    id:'burning_joss_section'
  },
  {
    label:'qm_qm101_menu_preparation_food',
    id:'preparation_food_section'
  },
  {
    label:'qm_qm101_menu_preserving_qm',
    id:'preserving_qm_section'
  }
];

</script>
<style lang="scss">
.ttc blockquote{
  @apply tw-rounded-lg;
}
.ttc blockquote iframe{
  @apply tw-rounded-lg;

}
</style>
