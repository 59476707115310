<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { ref} from 'vue';
const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');
const menuItems = [
  {
    label: 'zy_about_menu_offerings',
    id   : 'about_offerings'
  },
  {
    label: 'zy_about_menu_ceremonies',
    id   : 'about_ceremonies'
  },
  {
    label: 'zy_about_menu_banquets_auctions',
    id   : 'about_banquets_auctions'
  },
  {
    label: 'zy_about_menu_getai',
    id   : 'about_getai'
  },
];


const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};


onBeforeMount(()=>{
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

onMounted(() => {


  nextTick(() => {

    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };


      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});
</script>

<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :zy-navigation="true"
    />
    <zy-secondary-navigation
      :menu-items-data="menuItems"
    />
    <!-- Banner -->
    <div class="tw-relative tw-tracking-wider">
      <picture>
        <img
          :src="$cdn('images/zy2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            class="tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 tw-pb-1"
          >
            {{ $t('zy_about_title') }}
          </div>
        </div>
      </div>
      <zy-paper-tear />
    </div>
    <!-- description -->
    <section
      id="sup-handler-container"
      class="
          tw-py-10
          sgTablet:tw-py-[80px]
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-relative
          tw-tracking-wider
          tw-flex tw-flex-col
          tw-justify-center
          tw-items-center
        "
    >
      <div
        class="
          about-header-description
          tw-max-w-[600px]
          tw-w-full
          tw-text-sg-body-1-mobile
          sgTablet:tw-text-sg-body-1-tablet
          desktop:tw-text-sg-body-1
          [&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]
        "
        v-html="wordpressData.description"
      />

      <zy-paper-tear />
    </section>

    <section
      class="
        tw-px-4
        sgTablet:tw-px-5
        sgDesktop:tw-px-10
        tw-relative
        tw-flex tw-gap-8
        tw-justify-center
        tw-bg-sg-zy24-orange
        tw-relative tw-py-10
        sgTablet:tw-py-[80px]
        tw-tracking-wider
      "
    >
      <!-- left side -->
      <div
        class="tw-relative tw-w-[140px] tw-h-full tw-hidden sgTablet:tw-block"
      >
        <div>
          <img
            class="tw-pt-[400px]"
            :src="$cdn('images/zy2024/brick.png')"
          >
          <img
            class="tw-pt-[656px]"
            :src="$cdn('images/zy2024/orange.png')"
          >
        </div>
      </div>

      <!-- center -->
      <div
        class="tw-flex tw-w-[600px] tw-flex-col tw-items-start tw-justify-center"
      >
        <div
          class="tw-w-full tw-text-center tw-text-sg-ref-mobile-h2 sgTablet:tw-text-sg-h2 tw-pb-[24px]"
        >
          {{ $t('zy_about_how_it_observed_title') }}
        </div>
        <div
          class="tw-text-sg-body-1 tw-pb-[24px]"
        >
          {{ $t('zy_about_how_it_observed_desc_1') }}
        </div>
        <div
          class="tw-text-sg-body-1"
        >
          {{ $t('zy_about_how_it_observed_desc_2') }}
          <sup
            class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body"
            @click="handleOpenImageSourceModal"
          >[4]</sup>
          <sup
            class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body"
            @click="handleOpenImageSourceModal"
          >[5]</sup>
          <sup
            class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body"
            @click="handleOpenImageSourceModal"
          >[6]</sup>
          <sup
            class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body"
            @click="handleOpenImageSourceModal"
          >[7]</sup>
        </div>
        <!-- Offerings -->
        <div
          id="about_offerings"
          class="
          tw-w-full
          tw-text-center
          tw-text-sg-ref-mobile-h4
          sgTablet:tw-text-sg-ref-desktop-h4
          tw-py-[24px]"
        >
          {{ $t('zy_about_how_it_observed_subtitle_1') }}
        </div>
        <div
          class="
            tw-flex
            tw-flex-col-reverse
            sgDesktop:tw-flex-row
            tw-gap-6
            tw-items-center
          "
        >
          <!-- left side -->
          <div class="tw-flex-col tw-flex-1">
            <div
              class="tw-text-sg-body-1 tw-pb-[24px] "
              v-html="$t('zy_about_how_it_observed_desc_3')"
            />

          </div>
          <!-- right side -->
          <div class="tw-relative tw-w-[300px] tw-shadow-lg tw-bg-[#CF89AE] tw-rounded-sm">
            <!-- Background Image -->
            <img
              class="tw-w-full tw-object-cover tw-h-[300px]"
              :src="$cdn('images/zy2024/home/tile_background.scaled.png')"
            >
            <!-- Content -->
            <div class="tw-absolute tw-inset-0 tw-flex-col">
              <div
                :class="[
                  'tw-text-center tw-text-sg-caption-desktop-1 tw-font-hanzipan',
                  'tw-px-[20px] tw-pt-[40px]'
                ]"
                style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);"
              >
                {{ $t('zy_about_how_it_observed_img_desc_1') }}
              </div>
              <div class="tw-flex tw-h-[220px] tw-items-center">
                <img
                  class="tw-w-full"
                  :src="$cdn('images/zy2024/about/zy24_about_sacrificial_offering.png')"
                >
              </div>

            </div>
          </div>
        </div>

        <!-- Ceremonies -->

        <div
          id="about_ceremonies"
          class="tw-w-full tw-text-center tw-text-sg-ref-mobile-h4 sgTablet:tw-text-sg-ref-desktop-h4 tw-py-[24px]"
        >
          {{ $t('zy_about_how_it_observed_subtitle_2') }}
        </div>

        <div class="tw-flex tw-flex-col tw-gap-6 tw-items-center ">
          <!-- top image -->
          <div
            class="tw-relative tw-w-[352px] tw-shadow-lg tw-bg-[#FFDD63] tw-rounded-sm"
          >
            <!-- Background Image -->
            <img
              class="tw-w-full tw-object-cover tw-h-[316px] tw-rounded-sm"
              :src="$cdn('images/zy2024/home/tile_background.scaled.png')"
            >
            <!-- Content -->
            <div class="tw-absolute tw-inset-0 tw-flex-col tw-p-4">

              <div class="tw-flex tw-items-center tw-pb-[20px]">
                <img
                  class="tw-object-cover tw-h-full"
                  :src="$cdn('images/zy2024/about/zy24_about_ceremonies.jpg')"
                >
              </div>
              <div class="tw-text-sg-caption-desktop-1 tw-font-hanzipan">
                {{ $t('zy_about_how_it_observed_caption') }}
                <sup
                  class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body  tw-font-akkurat"
                  @click="handleOpenImageSourceModal"
                >[A]</sup>
              </div>
            </div>
          </div>
          <!-- description -->
          <div class="tw-flex-col tw-flex tw-gap-6">
            <div
              class="tw-text-sg-body-1"
              v-html="$t('zy_about_how_it_observed_desc_5')"
            />
            <div class="tw-text-sg-body-1">
              {{ $t('zy_about_how_it_observed_desc_6') }}
            </div>
            <div class="tw-text-sg-body-1">
              {{ $t('zy_about_how_it_observed_desc_7') }}
            </div>
            <div class="tw-text-sg-body-1">
              {{ $t('zy_about_how_it_observed_desc_8') }}
            </div>
          </div>
        </div>
      </div>

      <!-- right side -->
      <div class="tw-relative tw-w-[140px] tw-h-full tw-hidden sgTablet:tw-block">
        <div>
          <img
            class="tw-pt-[100px]"
            :src="$cdn('images/zy2024/candle.png')"
          >
          <img
            class="tw-pt-[956px]"
            :src="$cdn('images/zy2024/flag.png')"
          >
        </div>
      </div>
      <!--  -->
      <zy-paper-tear />
    </section>

    <!-- banquet section -->
    <section
      id="about_banquet"
      class="
          tw-flex
          tw-justify-center
          tw-bg-sg-zy24-zy1-w60
          tw-tracking-wider
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-py-[40px]
          sgTablet:tw-py-[60px]
          sgDesktop:tw-pb-[80px]
        "
    >
      <div
        class="tw-flex tw-flex-col tw-items-center tw-max-w-[600px]"
      >
        <!-- top image -->
        <div
          class="tw-relative tw-w-[352px] tw-shadow-lg tw-bg-[#88D8DF] tw-rounded-md"
        >
          <!-- Background Image -->
          <img
            class="tw-w-full tw-object-cover tw-h-[316px] tw-rounded-sm"
            :src="$cdn('images/zy2024/home/tile_background.scaled.png')"
          >
          <!-- Content -->
          <div class="tw-absolute tw-inset-0 tw-flex-col tw-p-4">

            <div class="tw-flex tw-items-center tw-pb-[20px]">
              <img
                class="tw-object-cover tw-h-full"
                :src="$cdn('images/zy2024/about/zy24_about_banquet.jpg')"
              >
            </div>
            <div class="tw-text-sg-caption-desktop-1 tw-font-hanzipan">
              {{ $t('zy_about_banquet_caption_1') }}
              <sup
                class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body  tw-font-akkurat"
                @click="handleOpenImageSourceModal"
              >[B]</sup>
            </div>
          </div>
        </div>

        <!-- Banquet title -->
        <div
          id="about_banquets_auctions"
          class="tw-w-full tw-text-center tw-text-sg-ref-mobile-h4 sgTablet:tw-text-sg-ref-desktop-h4 tw-py-[24px]"
        >
          <div
            id="about_banquet"
            class="tw-pr-1"
            v-html="$t('zy_about_banquet_subtitle_banquet')"
          />
        </div>

        <!-- Banquet decsription -->
        <div class="tw-flex-col tw-flex tw-gap-6">
          <div
            class="tw-text-sg-body-1"
            v-html="$t('zy_about_banquet_description_1')"
          />
        </div>

        <!-- Getai title -->
        <div
          id="about_getai"
          class="tw-w-full tw-text-center tw-text-sg-ref-mobile-h4 sgTablet:tw-text-sg-ref-desktop-h4 tw-pt-[24px]"
        >
          {{ $t('zy_about_banquet_subtitle_2') }}
        </div>

        <!-- Getai image -->
        <div class="tw-flex-col tw-p-4 tw-my-10">

          <div class="tw-flex tw-items-center tw-pb-5">
            <img
              class="tw-object-cover tw-h-full"
              :src="$cdn('images/zy2024/about/zy24_about_getai.png')"
            >
          </div>

        </div>

        <!-- Getai decsription -->
        <div
          id="sup-handler-container"
          class="tw-flex-col tw-flex tw-gap-6"
        >
          <div
            class="tw-text-sg-body-1"
            v-html="$t('zy_about_banquet_description_2')"
          />
          <div
            class="tw-text-sg-body-1"
            v-html="$t('zy_about_banquet_description_3')"
          />
          <div
            class="tw-text-sg-body-1"
            v-html="$t('zy_about_banquet_description_4')"
          />
          <div
            class="tw-text-sg-body-1"
            v-html="$t('zy_about_banquet_description_5')"
          />
          <div
            class="tw-text-sg-body-1"
            v-html="$t('zy_about_banquet_description_6')"
          />
          <div
            class="tw-text-sg-body-1"
            v-html="$t('zy_about_banquet_description_7')"
          />
        </div>
      </div>
    </section>
    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style scoped>
#sup-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}

#about_banquet:deep(em){
  font-weight: 400;
}
</style>
