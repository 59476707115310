<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, onMounted, nextTick } from 'vue';
import { ref} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen  = ref<boolean>(false);
const imageSourcesData = ref();
const findOutMoreLink  = props.wordpressData?.find_out_more_external_link;

const menuItems = [
  {
    label: 'ws_origin_stories_menu_solar_terms',
    id   : 'ws_origin_stories_menu_solar_terms'
  },
  {
    label: 'ws_origin_stories_menu_chinese_believes',
    id   : 'ws_origin_stories_menu_chinese_believes'
  },
  {
    label: 'ws_origin_stories_menu_why_eat_dumplings',
    id   : 'ws_origin_stories_menu_why_eat_dumplings'
  },
  {
    label: 'ws_origin_stories_menu_why_eat_tangyuan',
    id   : 'ws_origin_stories_menu_why_eat_tangyuan'
  }
];



const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onBeforeMount(()=>{
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

const pageBreakHeight1 = ref(0);
const pageBreakHeight2 = ref(0);
const pageBreak1 = ref<HTMLElement | null>(null);
const pageBreak2 = ref<HTMLElement | null>(null);

function updatePageBreakHeight() {
  if (pageBreak1.value) {
    pageBreakHeight1.value = pageBreak1.value.getBoundingClientRect().height;
  }
  if (pageBreak2.value) {
    pageBreakHeight2.value = pageBreak2.value.getBoundingClientRect().height;
  }
}

onMounted(() => {
  window.addEventListener('load', updatePageBreakHeight);
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
  pageBreak1.value = document.getElementById('page-break-1');
  pageBreak2.value = document.getElementById('page-break-2');
  updatePageBreakHeight();
  window.addEventListener('resize', updatePageBreakHeight);
});
onBeforeUnmount(()=>{
  // Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  window.removeEventListener('resize', updatePageBreakHeight);
  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

</script>

<template>
  <section
    class="tw-overflow-x-hidden"
    :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ws-navigation="true"
    />
    <ws-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- Banner -->
    <section class="tw-relative tw-tracking-wider">
      <picture>
        <div
          :style="{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${$cdn('images/ws2024/banner/ws-subpage-hero-banner.jpg')}) lightgray 50% / cover no-repeat`
          }"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        />
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-ws-mobile-h1 sgTablet:tw-text-sg-ws-tablet-h1 sgDesktop:tw-text-sg-ws-desktop-h1 tw-pb-1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_origin_stories_title') }}
          </div>
        </div>
      </div>
      <ws-paper-tear />
    </section>

    <!-- description -->
    <section
      id="ws_about_menu_winter_solstice_meaning"
      :class="[
        'tw-py-10 sgTablet:tw-py-[40px]',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-relative tw-flex tw-flex-col tw-justify-center tw-items-center'
      ]"
    >
      <div
        id="sup-handler-container"
        :class="[
          'tw-max-w-[600px] tw-w-full',
          'tw-text-sg-ws-body tw-text-center',
          '[&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]',
        ]"
        v-html="wordpressData.description"
      />
    </section>


    <!-- Solar terms -->
    <section
      id="ws_origin_stories_menu_solar_terms"
      :class="[
        'tw-relative tw-overflow-hidden sgDesktop:tw-overflow-visible',
        'tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-5',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pt-[169px] sgTablet:tw-pt-[208px] sgTablet2:tw-pt-[311px] sgDesktop:tw-pt-[80px]',
        'tw-pb-5 sgTablet2:tw-pb-[60px]',
      ]"
      :style="{
        background: `
        linear-gradient(180deg, rgba(255, 255, 255, 0.00) 58.54%, #FFF 97.57%),
        linear-gradient(0deg, rgba(242, 222, 207, 0.85) 0%, rgba(242, 222, 207, 0.85) 100%),
        url(${$cdn('images/ws2024/origin-stories/solar-terms/bg.jpg')}) lightgray 50% / cover no-repeat
      `
      }"
    >
      <img
        id="page-break-1"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full tw-mt-[-1px]"
        :src="$cdn('images/ws2024/page-break/ws-origin-stories-page-break-1.png')"
      >
      <!-- mobile -->
      <div
        :class="[
          'sgDesktop:tw-hidden',
          'tw-h-[272px] sgTablet:tw-h-[360px] sgTablet2:tw-h-[560px]',
          'tw-w-[272px] sgTablet:tw-w-[360px] sgTablet2:tw-w-[560px]',
          'tw-absolute tw-overflow-hidden tw-top-0',
          'tw-mt-[-140px] sgTablet:tw-mt-[-185px] sgTablet2:tw-mt-[-300px]',
        ]"
      >
        <!-- Rotating image -->
        <img
          class="let-it-roll"
          :src="$cdn('images/ws2024/origin-stories/solar-terms/mobile-roller.png')"
        >
      </div>
      <div
        :class="[
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-max-w-[800px] tw-text-center',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_origin_stories_title_1') }}
      </div>
      <div
        :class="[
          'tw-relative',
          'tw-flex tw-gap-10',
          'tw-max-w-[800px]',
          'tw-text-center sgDesktop:tw-text-left',
          {'sgDesktop:tw-pr-[200px]' : checkLang() == 'en' },
          {'sgDesktop:tw-pr-[292px]' : checkLang() !== 'en'}
        ]"
      >
        <div
          id="sup-handler-container"
          v-html="$t('ws_origin_stories_description_1')"
        />

        <!-- desktop -->
        <div
          :class="[
            'tw-hidden sgDesktop:tw-block',
            'tw-absolute tw-overflow-hidden tw-right-0',
            {'tw-mr-[-50%] tw-mt-[-32px] tw-h-[600px] tw-w-[600px]' : checkLang() == 'en' },
            {'tw-mr-[-22%] tw-mt-[-10px] tw-h-[460px] tw-w-[460px]' : checkLang() !== 'en'}
          ]"
        >
          <!-- Rotating image -->
          <img
            class="let-it-roll"
            :src="$cdn('images/ws2024/origin-stories/solar-terms/desktop-roller.png')"
          >
        </div>
      </div>
    </section>

    <!-- Chinese believes -->
    <section
      :class="[
        'tw-relative',
        'tw-flex tw-justify-center tw-items-center',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pt-10 sgTablet:tw-pt-[40px]',
        'tw-pb-[100px] sgTablet:tw-pb-[92px] sgDesktop:tw-pb-[80px]'
      ]"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-max-w-[800px]">
        <section id="ws_origin_stories_menu_chinese_believes">
          <div
            :class="[
              'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
              'tw-w-full tw-max-w-[800px] tw-text-center',
              'tw-pb-5',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_origin_stories_title_2') }}
          </div>
          <div
            :class="[
              'tw-max-w-[800px] tw-flex sgTablet:tw-gap-10 tw-gap-5 tw-pb-10',
              'tw-justify-center tw-items-center',
              'tw-flex-col sgTablet2:tw-flex-row'
            ]"
          >
            <img
              :class="['tw-w-[260px]']"
              :src="$cdn('images/ws2024/origin-stories/ying-yang.png')"
            >
            <div
              id="sup-handler-container"
              class="tw-text-sg-ws-body tw-text-center sgTablet2:tw-text-left "
              v-html="$t('ws_origin_stories_description_2_1')"
            />
          </div>
        </section>
        <section
          id="ws_origin_stories_menu_why_eat_dumplings"
          :class="[
            'tw-flex tw-flex-col',
            'tw-pb-[100px]',
            'tw-pt-5 sgTablet:tw-pt-10',
          ]"
        >
          <div
            :class="[
              'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
              'tw-w-full tw-max-w-[800px] tw-text-center',
              'tw-pb-5',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_origin_stories_title_3') }}
          </div>
          <div
            :class="[
              'tw-max-w-[800px] tw-flex sgTablet:tw-gap-10 tw-gap-5',
              'tw-justify-center tw-items-center',
              'tw-flex-col-reverse sgTablet2:tw-flex-row'
            ]"
          >
            <div
              id="sup-handler-container"
              class="tw-text-sg-ws-body tw-text-center sgTablet2:tw-text-left"
              v-html="$t('ws_origin_stories_description_2_2')"
            />
            <img
              :class="['tw-w-[260px]']"
              :src="$cdn('images/ws2024/origin-stories/currypuff.png')"
            >
          </div>
        </section>
        <section
          id="ws_origin_stories_menu_why_eat_tangyuan"
          :class="[
            'tw-flex tw-flex-col',
            'tw-pb-[100px]',
            'tw-pt-5 sgTablet:tw-pt-10',
          ]"
        >
          <div
            :class="[
              'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
              'tw-w-full tw-max-w-[800px] tw-text-center',
              'tw-pb-5',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_origin_stories_title_4') }}
          </div>
          <div
            :class="[
              'tw-max-w-[800px] tw-flex sgTablet:tw-gap-10 tw-gap-5',
              'tw-justify-center tw-items-center',
              'tw-flex-col sgTablet2:tw-flex-row'
            ]"
          >
            <img
              :class="['tw-w-[260px]']"
              :src="$cdn('images/ws2024/about/soup.png')"
            >
            <div
              id="sup-handler-container"
              class="tw-text-sg-ws-body tw-text-center sgTablet2:tw-text-left"
              v-html="$t('ws_origin_stories_description_2_3')"
            />

          </div>
        </section>
      </div>
    </section>

    <section
      :class="[
        'tw-relative tw-hidden',
        'tw-flex tw-justify-center tw-items-center',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pb-10 sgDesktop:tw-pb-[40px]',
        // 'tw-pt-[100px] sgDesktop:tw-pt-[120px]',
        'tw-gap-8 tw-bg-sg-ws24-maroon'
      ]"
      :style="{
        paddingTop: `${pageBreakHeight2}px`
      }"
    >
      <img
        id="page-break-2"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full tw-mt-[-1px]"
        :src="$cdn('images/ws2024/page-break/ws-origin-stories-page-break-2.png')"
      >

      <img
        :class="[
          'tw-absolute tw-top-0 tw-z-10 tw-w-[200px]',
          // 'tw-mt-[-70px] sgTablet:tw-mt-[-60px] sgTablet2:tw-mt-[-50px] sgDesktop:tw-mt-[-30px] sgDesktop2:tw-mt-[-20px]',
        ]"
        :src="checkLang() == 'en' ? $cdn('images/ws2024/stamp/singaporen_stamp_en.png') : $cdn('images/ws2024/stamp/singaporen_stamp_cn.png')"
        :style="{
          filter: 'drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.3))',
          marginTop: `${pageBreakHeight2 - 90}px`
        }"
      >

      <div
        :class="[
          'tw-flex tw-flex-col tw-gap-6 ',
          'tw-max-w-[800px]',
          'tw-pt-[80px] sgTablet:tw-pt-[60px]',
        ]"
      >
        <!-- center side -->
        <div
          class="tw-text-sg-ref-desktop-body tw-text-center tw-text-white"
          v-html="$t('ws_origin_stories_footer')"
        />
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </section>
</template>

<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.let-it-roll{
  animation: rotate 12s linear infinite;
}

#sup-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}

#sup-handler-container:deep(a) {
  cursor: pointer;
  text-decoration: underline;
}
</style>
