<script lang="ts" setup>
// Import Swiper Vue.js components
import type {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import {FreeMode,Mousewheel,Autoplay,EffectCoverflow} from 'swiper/modules';
import { useI18n } from 'vue-i18n';

import { onMounted, toRefs, ref, Ref, computed, nextTick} from 'vue';
import { cdn } from '../../support/cdn';
import SwiperCore from 'swiper';
import { checkLang } from '../../support/helper';
SwiperCore.use([EffectCoverflow]);
const datas = ref([
  {
    'title': 'Love',
    'title_cn': '爱',
    'images': [
      {
        'desc': 'To love, we need not be beautiful or brilliant. We just have to care.',
        'link': 'images/values/jangandfox/love/en/2-.jpg',
        'desc_cn': '把手头上的工作放下，多花点时间听听你爱的人有什么想跟你说的吧！随便聊点什么都好！',
        'link_cn': 'images/values/jangandfox/love/cn/2-.jpg'
      },
      {
        'desc': 'Listening is a way of loving. When we listen, we indicate that you’re important to me.',
        'link': 'images/values/jangandfox/love/en/3-.jpg',
        'desc_cn': '送给心爱的人最好的礼物， 就是陪伴。',
        'link_cn': 'images/values/jangandfox/love/cn/3-.jpg'
      },
      {
        'desc': 'Being present is the greatest gift of love you can give.',
        'link': 'images/values/jangandfox/love/en/1-.jpg',
        'desc_cn': '只要继续加油，总有一天一定会越过那座高山的！',
        'link_cn': 'images/values/jangandfox/love/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Shame',
    'title_cn': '耻',
    'images': [
      {
        'desc': 'We learn more from knowing and admitting our mistakes.',
        'link': 'images/values/jangandfox/shame/en/2-.jpg',
        'desc_cn': '如果能从错误中得到反思，这绝对会起到一种自我提醒的作用，提醒自己别再犯同样的错。',
        'link_cn': 'images/values/jangandfox/shame/cn/2-.jpg'
      },
      {
        'desc': 'Reflecting on our shortcomings makes us better people.',
        'link': 'images/values/jangandfox/shame/en/3-.jpg',
        'desc_cn': '敞开心扉，改过自新，再从错误中好好学习，必能成为更好的自己。',
        'link_cn': 'images/values/jangandfox/shame/cn/3-.jpg'
      },
      {
        'desc': 'We are willing to ask for help when in need.',
        'link': 'images/values/jangandfox/shame/en/1-.jpg',
        'desc_cn': '需要帮助的时候，别为难。因为三个臭皮匠， 胜过一个诸葛亮。',
        'link_cn': 'images/values/jangandfox/shame/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Courtesy',
    'title_cn': '礼',
    'images': [
      {
        'desc': 'Someone else could be wearing the smile you shared today.',
        'link': 'images/values/jangandfox/courtesy/en/2-.jpg',
        'desc_cn': '也许你不经意的一笑会是旁人微笑的理由。所以记得常常笑脸迎人哦！',
        'link_cn': 'images/values/jangandfox/courtesy/cn/2-.jpg'
      },
      {
        'desc': 'See, share and smile - Take these little steps on the path to thoughtfulness!',
        'link': 'images/values/jangandfox/courtesy/en/3-.jpg',
        'desc_cn': '微笑，示意，然后礼貌谦让。这是创造优雅社会的小举动哦！',
        'link_cn': 'images/values/jangandfox/courtesy/cn/3-.jpg'
      },
      {
        'desc': '“I am sorry” may be the toughest words to say, but also the gentlest words that can heal invisible wound.',
        'link': 'images/values/jangandfox/courtesy/en/1-.jpg',
        'desc_cn': '“对不起” 也许是世上最难说出口，但却是可以治愈任何创伤、最温柔 、最有效的话。',
        'link_cn': 'images/values/jangandfox/courtesy/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Integrity',
    'title_cn': '廉',
    'images': [
      {
        'desc': 'The things we do when no one is looking are the things that define us.',
        'link': 'images/values/jangandfox/integrity/en/2-.jpg',
        'desc_cn': '清廉正直的人，懂得自我约束，不会做出偷鸡摸狗的事。',
        'link_cn': 'images/values/jangandfox/integrity/cn/2-.jpg'
      },
      {
        'desc': 'Being honest can be as rewarding as an ice cream treat.',
        'link': 'images/values/jangandfox/integrity/en/3-.jpg',
        'desc_cn': '廉洁做人，诚实做事，就像在炎热天气下吃了一口冰淇淋一样，清爽舒服。',
        'link_cn': 'images/values/jangandfox/integrity/cn/3-.jpg'
      },
      {
        'desc': 'There’s always a better reason to tell the truth.',
        'link': 'images/values/jangandfox/integrity/en/1-.jpg',
        'desc_cn': '做个清正廉洁，胸襟坦荡的孩子。',
        'link_cn': 'images/values/jangandfox/integrity/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Filial Piety',
    'title_cn': '孝',
    'images': [
      {
        'desc': 'When was the last time that you said ‘thank you’ to your superhero?',
        'link': 'images/values/jangandfox/filial-piety/en/2-.jpg',
        'desc_cn': '我们往往在长大后才慢慢察觉到身边有位平凡的超人。你有多久没向这位每天都能见得到的超人说声谢谢了呢？',
        'link_cn': 'images/values/jangandfox/filial-piety/cn/2-.jpg'
      },
      {
        'desc': 'These are the toughest and gentlest hands. Hold them dearly.',
        'link': 'images/values/jangandfox/filial-piety/en/3-.jpg',
        'desc_cn': '小时候，长辈总是小心翼翼地呵护和照顾我们。现在，我们长大了，是时候轮到我们好好地照顾他们了。',
        'link_cn': 'images/values/jangandfox/filial-piety/cn/3-.jpg'
      },
      {
        'desc': 'A simple act of service and an expression of care can brighten a loved one’s day.',
        'link': 'images/values/jangandfox/filial-piety/en/1-.jpg',
        'desc_cn': '要想当父母的好帮手，不如先从简单的家务着手吧！这是关怀父母的方式之一哦！',
        'link_cn': 'images/values/jangandfox/filial-piety/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Perseverance',
    'title_cn': '毅',
    'images': [
      {
        'desc': 'There’s no need to rush. Take one step at a time.',
        'link': 'images/values/jangandfox/perseverance/en/2-.jpg',
        'desc_cn': '只要继续加油，总有一天一定会越过那座高山的！',
        'link_cn': 'images/values/jangandfox/perseverance/cn/2-.jpg'
      },
      {
        'desc': 'Water the seeds of patience and hard work, and delight in the harvest it will bring you.',
        'link': 'images/values/jangandfox/perseverance/en/3-.jpg',
        'desc_cn': '一分耕耘，一分收获。日积月累地努力付出，必定会有所收获。',
        'link_cn': 'images/values/jangandfox/perseverance/cn/3-.jpg'
      },
      {
        'desc': 'Take a leap of faith. You may be one leap away from the star.',
        'link': 'images/values/jangandfox/perseverance/en/1-.jpg',
        'desc_cn': '别因为曾经跌倒而害怕再去尝试，鼓起勇气，挑战自我吧！',
        'link_cn': 'images/values/jangandfox/perseverance/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Thrift',
    'title_cn': '俭',
    'images': [
      {
        'desc': 'What are some other ways that help to conserve this precious treasure for our future?',
        'link': 'images/values/jangandfox/thrift/en/2-.jpg',
        'desc_cn': '我们应当尽己之力，节约资源。',
        'link_cn': 'images/values/jangandfox/thrift/cn/2-.jpg'
      },
      {
        'desc': 'It takes only a little imagination to create a moment to remember.',
        'link': 'images/values/jangandfox/thrift/en/3-.jpg',
        'desc_cn': '只要动动脑筋，就能让旧物拥有新的用途、新的生命。',
        'link_cn': 'images/values/jangandfox/thrift/cn/3-.jpg'
      },
      {
        'desc': 'Sharing is caring for Mother Earth, like the good old days when we practice thrift by sharing what we own with our friends.',
        'link': 'images/values/jangandfox/thrift/en/1-.jpg',
        'desc_cn': '资源共享，不仅是节俭的表现，它还可为环保出一份力。',
        'link_cn': 'images/values/jangandfox/thrift/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Righteousness',
    'title_cn': '义',
    'images': [
      {
        'desc': 'We can be our own superhero in simple ways through this tough period.',
        'link': 'images/values/jangandfox/righteousness/en/2-.jpg',
        'desc_cn': '在冠病疫情持续的期间，做好卫生管理，人人有责。',
        'link_cn': 'images/values/jangandfox/righteousness/cn/2-.jpg'
      },
      {
        'desc': 'When was a time that you stood up for something or someone?',
        'link': 'images/values/jangandfox/righteousness/en/3-.jpg',
        'desc_cn': '你也能像小象一样，当个可靠的后盾吗？',
        'link_cn': 'images/values/jangandfox/righteousness/cn/3-.jpg'
      },
      {
        'desc': 'A small voice is louder still when others are silent.',
        'link': 'images/values/jangandfox/righteousness/en/1-.jpg',
        'desc_cn': '你要相信，你的正义之声能够影响身边的人去做正确的事。',
        'link_cn': 'images/values/jangandfox/righteousness/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Loyalty',
    'title_cn': '忠',
    'images': [
      {
        'desc': 'To all who keep us going through this tough period, thank you for your service!',
        'link': 'images/values/jangandfox/loyalty/en/2-.jpg',
        'desc_cn': '在新冠疫情期间，感谢尽忠职守的前线医务人员。',
        'link_cn': 'images/values/jangandfox/loyalty/cn/2-.jpg'
      },
      {
        'desc': 'A promise kept is a good friend made.',
        'link': 'images/values/jangandfox/loyalty/en/3-.jpg',
        'desc_cn': '忠诚的朋友会信守承诺。如果你身边这么一位忠诚的朋友，请好好珍惜他/她。',
        'link_cn': 'images/values/jangandfox/loyalty/cn/3-.jpg'
      },
      {
        'desc': 'A family is defined by commitment and love.',
        'link': 'images/values/jangandfox/loyalty/en/1-.jpg',
        'desc_cn': '家，是一个值得我们用一辈子承诺和爱去灌溉的地方。',
        'link_cn': 'images/values/jangandfox/loyalty/cn/1-.jpg'
      },
    ]
  },
  {
    'title': 'Kindness',
    'title_cn': '仁',
    'images': [
      {
        'desc': 'Lend a hand - and an umbrella. That’s how a new friend is found!',
        'link': 'images/values/jangandfox/kindness/en/2-.jpg',
        'desc_cn': '伸出援手，把伞借给有需要的人吧！这是结交新朋友的小秘诀哦！',
        'link_cn': 'images/values/jangandfox/kindness/cn/2-.jpg'
      },
      {
        'desc': 'Pass on a little act of kindness on someone’s rainy day.',
        'link': 'images/values/jangandfox/kindness/en/3-.jpg',
        'desc_cn': '在冷冷的雨天里，善良无私的举动足以温暖人心。',
        'link_cn': 'images/values/jangandfox/kindness/cn/3-.jpg'
      },
      {
        'desc': 'When we see the good in others, we also end up finding the good in every day.',
        'link': 'images/values/jangandfox/kindness/en/1-.jpg',
        'desc_cn': '当我们试着去看见别人的好，渐渐地，我们也会察觉到枯燥乏味的日子里，也会有让人惊喜的事情。',
        'link_cn': 'images/values/jangandfox/kindness/cn/1-.jpg'
      },
    ]
  }
]);
const tempIndex = ref(0);
const realIndex = computed(() => {
  return swiperInstance.value?.realIndex;
});

const swiperInstance = ref<SwiperInstance>();
const imageModal = ref({
  show:false,
  data:null,
});
const handleOpenImageModal = (row:any) => {
  // alert('Image Source Not Implemented');
  imageModal.value.show = true;
  imageModal.value.data = row;

};

function onSwiper(swiper) {


  swiperInstance.value = swiper;
  if (datas.value.length >= 5) {
    nextTick(() => {
      let activeIndex = swiper.realIndex;
      swiper.slideNext(0, false);//Hack
      swiper.slidePrev(0, false);
      swiper.slidePrev(0, false);
      swiper.slideNext(0, false);
      swiper.slideNext(0, false);

      swiper.slideNext(0, false);
      swiper.slideNext(0, false);
      swiper.slidePrev(0, false);
      swiper.slidePrev(0, false);
      tempIndex.value = swiper.realIndex;
      onSlideEndChange(swiper);
    });
  }
}


let timeout = null;
function onTouchEnd(swiper) {
  if(datas.value.length < 5)return;
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    let activeIndex = swiper.realIndex;
    swiper.slidePrev(0, false);
    swiper.slidePrev(0, false);
    swiper.slideNext(0, false);
    swiper.slideNext(0, false);

    swiper.slideNext(0, false);
    swiper.slideNext(0, false);
    swiper.slidePrev(0, false);
    swiper.slidePrev(0, false);
    onSlideEndChange(swiper);
  }, 350);

}

function onTouchStart(swiper){
  if (timeout) {
    clearTimeout(timeout);
  }
  onSlideStartChange(swiper);
}
function slideNext() {
  swiperInstance.value.slideNext(0,false);
  swiperInstance.value.slideNext(0,false);
  swiperInstance.value.slidePrev(0,false);
  swiperInstance.value.slidePrev(0,false);
  swiperInstance.value.slideNext();

}

function slidePrev() {
  swiperInstance.value.slidePrev(0,false);
  swiperInstance.value.slidePrev(0,false);
  swiperInstance.value.slideNext(0,false);
  swiperInstance.value.slideNext(0,false);
  swiperInstance.value.slidePrev();
}
const onSlideStartChange = (swiper) => {
  tempIndex.value = swiper.realIndex;
  swiper.slides.forEach((slide)=>{
    slide.classList.remove('swiper-slide-hidden');
  });
};

const onSlideEndChange = (swiper) => {
  if(swiper.realIndex == tempIndex.value) return;
  let activeSlideIndex = swiper.slides.findIndex((slide)=>{
    return slide.classList.contains('swiper-slide-active');
  });
  let prevPrevSlideIndex = (activeSlideIndex - 2 + swiper.slides.length) % swiper.slides.length;
  let prevSlideIndex = (activeSlideIndex - 1 + swiper.slides.length) % swiper.slides.length;
  let nextSlideIndex = (activeSlideIndex + 1) % swiper.slides.length;
  let nextNextSlideIndex = (activeSlideIndex + 2) % swiper.slides.length;

  swiper.slides.forEach((slide,index)=>{
    if(![prevPrevSlideIndex,prevSlideIndex,activeSlideIndex,nextSlideIndex,nextNextSlideIndex].includes(index)){
      slide.classList.add('swiper-slide-hidden');
    }else{
      slide.classList.remove('swiper-slide-hidden');
    }
  });

  if(datas.value.length < 5)return;
  let activeIndex = swiper.realIndex;
  swiper.slidePrev(0, false);
  swiper.slidePrev(0, false);
  swiper.slideToLoop(activeIndex, 0, false);
  swiper.slideNext(0, false);
  swiper.slideNext(0, false);
  swiper.slideToLoop(activeIndex, 0, false);
};

</script>

<template>
  <div>
    <swiper
      class="valuesJangandfoxSwiper"
      :effect="'coverflow'"
      :grab-cursor="true"
      :centered-slides="true"
      :slides-per-view="'auto'"
      :breakpoints="{
        680:{
          coverflowEffect:{
            rotate: 0,
            stretch: 205,
            depth: 300,
            modifier: 1, // 2,3
            slideShadows: false,
          }
        },
      }"
      :coverflow-effect="{
        rotate: 0,
        stretch: 210,
        depth: 200,
        modifier: 1, // 2,3
        slideShadows: false,
      }"
      :initial-slide="0"
      :normalize-slide-index="false"
      :loop="true"
      @swiper="onSwiper"
      @touch-start="onTouchStart"
      @touch-end="onTouchEnd"
      @before-slide-change-start="onSlideStartChange"
      @slide-next-transition-start="onSlideStartChange"
      @slide-next-transition-end="onSlideEndChange"
      @slide-prev-transition-start="onSlideStartChange"
      @slide-prev-transition-end="onSlideEndChange"
    >
      <swiper-slide
        v-for="(item, index) in datas"
        :key="`slide_${index}`"
        class="tw-w-[260px] tw-h-[260px]  sgTablet:tw-w-[300px] sgTablet:tw-h-[300px] tw-border-4 tw-rounded-lg tw-bg-white"
      >
        <div
          class="tw-p-8 tw-flex tw-flex-wrap tw-justify-center tw-content-center tw-h-full tw-w-full tw-bg-cover tw-bg-center tw-group tw-cursor-pointer slideContentWrapper"
          :style="{ backgroundImage: `url(${$cdn(checkLang()=='en'?item.images[0]?.link:item.images[0]?.link_cn)})` }"
          @click="handleOpenImageModal(item)"
        >
          <button
            :style="{
              '--bg-image':'radial-gradient(circle,#A35E00,#A35E00 50%,transparent 50%)'
            }"
            :class="[
              'tw-relative tw-overflow-hidden tw-rounded-full tw-h-[80px] tw-w-[80px] tw-flex tw-flex-row tw-justify-center tw-flex-wrap tw-content-center tw-bg-[#FFC87D] tw-opacity-100',
              'before:tw-content-[\'\'] before:tw-absolute before:tw-top-1/2 before:tw-left-1/2 before:tw-w-[200%] before:tw-h-[200%] before:[background-image:var(--bg-image)] before:tw-bg-center before:tw-bg-no-repeat before:-tw-translate-x-1/2 before:-tw-translate-y-1/2 before:tw-scale-0 before:tw-transition-transform before:tw-ease-linear before:tw-duration-300',
              'group-hover:before:tw-scale-100',
            ]"
          >
            <inline-svg
              :src="$cdn('icons/open_in_full.svg')"
              alt="Video Games"
              class="tw-w-12 tw-h-12 tw-fill-[#A35E00] group-hover:tw-fill-[#FFC87D] tw-z-[10]"
              :style="{ transition: 'fill 0.3s linear'
              }"
            />
          </button>
        </div>
      </swiper-slide>
    </swiper>
    <div class="tw-w-[260px] sgTablet:tw-w-[300px] tw-mx-auto tw-text-center tw-mt-5 tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1  tw-tracking-wider">
      {{ checkLang()=='en'? datas[realIndex]?.title : datas[realIndex]?.title_cn }}
    </div>

    <div class="tw-flex tw-mt-5 tw-justify-center">
      <button
        class="tw-rounded-full tw-h-[40px] tw-min-w-[40px] tw-text-white tw-bg-[#FF9300] tw-text-[20px] tw-text-center disabled:tw-opacity-25"
        @click="slidePrev()"
      >
        &lt;
      </button>
      <div class="tw-px-[40px] tw-items-center tw-flex">
        <p
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-flex-nowrap"
          :style="{minWidth: '26px'}"
        >
          {{ `${realIndex + 1} / ${datas.length}` }}
        </p>
      </div>
      <button
        class="tw-rounded-full tw-h-[40px] tw-min-w-[40px] tw-text-white tw-bg-[#FF9300] tw-text-[20px]  tw-text-center  disabled:tw-opacity-25"
        @click="slideNext()"
      >
        >
      </button>
    </div>



    <values-jangandfox-image-modal
      v-model="imageModal.show"
      :data="imageModal.data"
    />
  </div>
</template>
<style>
.valuesJangandfoxSwiper .swiper-slide{
  border-color: rgb(from #FF9300 r g b /0.6)
}
.valuesJangandfoxSwiper .swiper-slide.swiper-slide-active{
  border-color: rgb(from #FF9300 r g b)
}
.valuesJangandfoxSwiper .swiper-slide .slideContentWrapper{
  opacity: 0.6;
}
.valuesJangandfoxSwiper .swiper-slide.swiper-slide-active .slideContentWrapper{
  opacity: 1;
}
.valuesJangandfoxSwiper .swiper-slide-hidden{
  opacity:0;
}

</style>
