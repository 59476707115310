<template>
  <section
    :class="[
      'tw-w-full tw-flex tw-flex-col tw-items-center',
      {'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}
    ]"
    style="overflow: hidden;"
  >
    <!-- stamp -->
    <img
      v-if="isMobile"
      :class="['tw-w-[208px] tw-h-[140px] tw-mb-10']"
      :src="checkLang() === 'en' ? $cdn('images/ma2024/ma_stamp_EN.svg') : $cdn('images/ma2024/ma_stamp_CN.svg')"
    >
    <section
      :class="[
        'tw-relative tw-flex tw-gap-10 tw-tw-w-full tw-m-auto'
      ]"
    >
      <div
        v-if="!isMobile"
        :class="[
          'tw-absolute tw-flex tw-flex-col tw-items-center tw-z-10',
        ]"
        :style="`left: ${paddingLeft}px`"
      >
        <!-- stamp -->
        <img
          :class="['tw-w-[208px] tw-h-[140px] tw-my-10']"
          :src="checkLang() === 'en' ? $cdn('images/ma2024/ma_stamp_EN.svg') : $cdn('images/ma2024/ma_stamp_CN.svg')"
        >
        <div class="tw-flex tw-justify-center tw-gap-[60px]">
          <div
            :class="[
              'tw-flex',
              'tw-bg-[#7A489D] hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E]',
              'tw-h-14 tw-w-14',
              'tw-text-white',
              'tw-cursor-pointer',
              'tw-rounded-full',
              { 'tw-opacity-[0.25]' : leftButtonDisabled }
            ]"
            @click="onClickPrev"
          >
            <img :src="$cdn('icons/ma/ma_chevron_left.svg')">
          </div>

          <div
            :class="[
              'tw-flex',
              'tw-bg-[#7A489D] hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E]',
              'tw-h-14 tw-w-14',
              'tw-text-white',
              'tw-cursor-pointer',
              'tw-rounded-full',
              { 'tw-opacity-[0.25]' : rightButtonDisabled }
            ]"
            @click="onClickNext"
          >
            <img :src="$cdn('icons/ma/ma_chevron_right.svg')">
          </div>
        </div>
      </div>

      <!-- desktop view -->
      <swiper
        v-if="!isMobile"
        id="ma-celebration-swiper"
        slides-per-view="auto"
        class="mySwiper"
        :slides-offset-after="paddingSliderRight"
        :style="swiperStyle"
        :space-between="20"
        @swiper="onSwiper"
        @slide-change="onSlideChange"
        @reach-end="onReachEnd"
      >
        <swiper-slide
          v-for="(item, index) in datas"
          :key="index"
          :style="{ height: '350px', width: '300px' }"
        >
          <ma-polaroid-card
            height="316px"
            width="300px"
            image-height="200px"
            :image="item.image_url"
            :class="`${ currentSlideIndex }`"
          >
            <p
              :class="[
                'tw-text-sg-ma-sh1 tw-text-sg-ma24-purple-b40',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >{{ item.caption }}
              <sup
                id="sup-handler-container"
                class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-sg-ma-bt2 tw-font-akkurat"
                @click="handleOpenImageSourceModal"
              >{{ item.reference }}</sup>
            </p>
          </ma-polaroid-card>
        </swiper-slide>
      </swiper>

      <!-- mobile view -->
      <swiper
        v-else
        id="ma-celebration-swiper"
        slides-per-view="auto"
        class="mySwiper"
        :centered-slides="true"
        :style="swiperStyleMobile"
        :space-between="20"
        @swiper="onSwiper"
        @slide-change="onSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in datas"
          :key="index"
          :class="[
            'ma-celebration-card',
          ]"
          :style="{ height: '350px', width: '300px' }"
        >
          <ma-polaroid-card
            height="316px"
            width="300px"
            image-height="200px"
            :image="item.image_url"
            :class="`${ currentSlideIndex }`"
          >
            <p
              :class="[
                'tw-text-sg-ma-sh1 tw-text-sg-ma24-purple-b40',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >{{ item.caption }}
              <sup
                id="sup-handler-container"
                class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-sg-ma-bt2 tw-font-akkurat"
                @click="handleOpenImageSourceModal"
              >{{ item.reference }}</sup>
            </p>
          </ma-polaroid-card>
        </swiper-slide>
      </swiper>



    </section>
    <div
      v-if="isMobile"
      class="tw-flex tw-justify-center tw-mb-5 tw-gap-[60px]"
    >
      <div
        :class="[
          'tw-flex',
          'tw-bg-[#7A489D] hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E]',
          'tw-h-14 tw-w-14',
          'tw-text-white',
          'tw-cursor-pointer',
          'tw-rounded-full',
          { 'tw-opacity-[0.25]' : leftButtonDisabled }
        ]"
        @click="onClickPrev"
      >
        <img :src="$cdn('icons/ma/ma_chevron_left.svg')">
      </div>

      <div
        :class="[
          'tw-flex',
          'tw-bg-[#7A489D] hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E]',
          'tw-h-14 tw-w-14',
          'tw-text-white',
          'tw-cursor-pointer',
          'tw-rounded-full',
          { 'tw-opacity-[0.25]' : rightButtonDisabled }
        ]"
        @click="onClickNext"
      >
        <img :src="$cdn('icons/ma/ma_chevron_right.svg')">
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref, computed, Ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';

const { t } = useI18n();

const swiperInstance = ref<SwiperInstance>();

const props = defineProps<{
  imageSourceOpen: boolean,
}>();


const isMobile = ref(window.innerWidth <= 820);
const paddingLeft = ref(0);

const paddingSliderRight = ref(690);

// screen width: 1440px -> suitable padding: 690
// screen width: 1240px -> suitable padding: 670
// screen width: 1024px -> suitable padding: 450

function onReachEnd() {
  rightButtonDisabled.value = true;
}

const swiperStyleMobile = ref({
  height: '350px',
  width: '100vw',
});

const swiperStyle = ref({
  height: '350px',
  width: '100vw',
  marginLeft: '100px',
});

function onResize() {
  swiperStyle.value.marginLeft = calculateDesktopPaddingLeft();

  if (window.innerWidth <= 820) {
    isMobile.value = true;
    paddingLeft.value = 0;
  }  else {
    isMobile.value = false;
    paddingLeft.value = (window.innerWidth - 800) / 2;
  }
}

const calculateDesktopPaddingLeft = () => {
  const maxContentWidth = 800;
  const contentWidth = window.innerWidth;
  const leftPadding = (contentWidth - maxContentWidth) / 2;
  if (contentWidth >= 1440) {
    paddingSliderRight.value = 690;
  }else if (contentWidth >= 1240 && contentWidth <= 1440) {
    paddingSliderRight.value = 0.1 * contentWidth + 546;
  } else {
    paddingSliderRight.value = 1.0185 * contentWidth - 592.94;
  }
  return `${leftPadding + 228}px`;
};


function onClickPrev() {
  if (swiperInstance?.value && !leftButtonDisabled.value) {
    swiperInstance.value.slidePrev();
  }
  rightButtonDisabled.value = false;
}

function onClickNext() {
  if (swiperInstance?.value && !rightButtonDisabled.value) {
    swiperInstance.value.slideNext();
  }
}

onMounted(() => {
  window.addEventListener('resize', onResize);
  onResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

const leftButtonDisabled = ref(true);
const rightButtonDisabled = ref(false);

const currentSlideIndex = ref(0);

const onSlideChange = () => {
  leftButtonDisabled.value = false;
  rightButtonDisabled.value = false;
  if (swiperInstance?.value) {
    leftButtonDisabled.value = swiperInstance?.value.isBeginning;
    if (isMobile.value) {
      rightButtonDisabled.value = swiperInstance?.value.isEnd;
    }
    currentSlideIndex.value = swiperInstance?.value.realIndex;
  }
};


const emit = defineEmits(['update:imageSourceOpen']);

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const datas: Ref<Array<any>> = ref([
  {
    id: 1,
    caption: t('ma_mid_autumn_101_celebrations_slider_1'),
    reference: '[A]',
    image_url: '/images/ma2024/mid-autumn-101/celebrations/ma_101_celebrations_slider_01.jpg',
  },
  {
    id: 2,
    caption: t('ma_mid_autumn_101_celebrations_slider_2'),
    reference: '[B]',
    image_url: '/images/ma2024/mid-autumn-101/celebrations/ma_101_celebrations_slider_02.jpg',
  },
  {
    id: 3,
    caption: t('ma_mid_autumn_101_celebrations_slider_3'),
    reference: '[C]',
    image_url: '/images/ma2024/mid-autumn-101/celebrations/ma_101_celebrations_slider_04.jpg',
  },
  {
    id: 4,
    caption: t('ma_mid_autumn_101_celebrations_slider_4'),
    reference: '[D]',
    image_url: '/images/ma2024/mid-autumn-101/celebrations/ma_101_celebrations_slider_03.jpg',
  }
]);

const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};



</script>

<style scoped lang="scss">

#ma-celebration-swiper.swiper:deep(.swiper-wrapper){
  transition-timing-function: linear !important;
}

#ma-celebration-swiper.swiper .swiper-wrapper .swiper-slide {
  background-color: transparent;
}

#ma-celebration-swiper.swiper:deep(.swiper-slide-shadow){
  background: none !important;
}

.ma-celebration-card {
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  opacity: 1;
  transform: scale(1);
}

.ma-celebration-card.hidden-card {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}

</style>
