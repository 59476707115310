<template>
  <div
    class="tw-relative tw-rounded-[20px] tw-bg-white"
    :class="[
      'tw-rounded-[20px]',
      'tw-bg-white',
      'tw-m-5',
      'tw-h-[470px] sgTablet:tw-h-[620px]',

    ]"
  >
    <div
      :class="[
        'tw-relative tw-h-[206px] sgTablet:tw-h-[360px]',
      ]"
    >
      <img
        :class="[
          'tw-h-full',
          'tw-w-full tw-object-cover',
          'tw-rounded-t-[20px]',
          { 'tw-opacity-25' : !active },
        ]"
        :src="$cdn(data.image)"
        loading="lazy"
      >
      <img
        class="tw-absolute tw-w-full tw-bottom-0 tw-mb-[-2px]"
        :src="$cdn('images/ws2024/page-break/ws-101-page-break-1.png')"
      >
    </div>

    <!-- Content aligned to the bottom -->
    <div
      :class="[
        'tw-w-full tw-h-[300px]',
        'tw-flex tw-flex-col tw-rounded-b-[20px]'
      ]"
    >
      <!-- Description -->
      <div
        id="sup-handler-container"
        :class="[
          'tw-text-black tw-bg-white tw-text-center',
          'tw-pt-[24px] sgTablet:tw-pt-[20px]',
          'tw-pb-1',
          'sgDesktop:tw-px-[28px]',
          'sgTablet:tw-rounded-b-[20px]',
          { 'tw-hidden' : !active },
        ]"
        v-html="$t(data.description)"
      />
      <!-- Title -->
      <div
        :class="[
          'tw-bg-white tw-text-sg-ws-h4-mobile sgTablet:tw-text-sg-ws-h4-tablet sgDesktop:tw-text-sg-ws-h4-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-max-w-[800px] tw-text-center',
          'tw-min-h-[96px]',
          'sgDesktop:tw-px-[20px]',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
          { 'tw-opacity-25 tw-pt-[24px]' : !active },
        ]"
        v-html="$t(data.title)"
      />
      <div
        :class="[
          'tw-flex tw-justify-center',
          { 'tw-hidden' : !active },
        ]"
      >
        <div
          :class="[
            'tw-flex tw-items-center tw-justify-center',
            'tw-text-white tw-bg-sg-ws24-maroon',
            'tw-px-6 tw-py-4 tw-gap-2',
            'tw-mt-[28px] sgTablet:tw-mt-10',
            'tw-text-sg-ws-bt1 tw-cursor-pointer',
            'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
            'hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"

          @click="onclick(data.url)"
        >
          <div>{{ $t('ws_winter_solstice_101_view_recipe') }}</div>
          <img :src="$cdn('icons/ws/ws_arrow_outward.svg')">
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import { checkLang } from '../../support/helper';
interface Item {
  image: string;
  title: string;
  description: string;
  url: string;
}

function onclick(string: string) {
  console.log(string);
  window.open(string, '_blank', 'noopener,noreferrer');
}


defineProps<{
  data: Item;
  active: boolean;
}>();

</script>

<style scoped>
#sup-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}

.chev-btn {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
