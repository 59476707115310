<template>
  <div
    class="w-flex tw-flex-col tw-px-4 tw-pt-5 tw-pb-8
    tablet:tw-px-10 tablet:tw-pt-[60px] tablet:tw-pb-10
    desktop:tw-px-20 desktop:tw-pt-[68px] desktop:tw-pb-10"
  >
    <p
      class="tw-text-sg-sccc1 tw-pb-[28px] tw-text-sg-h1-mobile 
    tablet:tw-text-sg-h1-tablet tablet:tw-pb-10 
    desktop:tw-pb-10 desktop:tw-text-sg-h1 "
    >
      {{ title }}
    </p>
    <hr class="line1">
    <div
      class="tw-pt-4
     tablet:tw-pt-10 md:tw-pl-[194px]
     desktop:tw-pt-10 desktop:tw-pb-0 lg:tw-pl-[280px] xl:tw-pl-[405px] "
    >
      <ap-wysiwyg-viewer :data="content" />
      <div class="tw-pt-8 tablet:tw-pt-10 desktop:tw-pt-10" />
      <hr class="line3">
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
};
</script>
