<template>
  <div class="tw-relative tw-h-[100vh]">
    <!-- Text One, Text Two, Horizontal Text -->
    <div class="tw-absolute tw-top-0 tw-left-0 tw-z-0 tw-w-full tw-overflow-hidden">
      <img
        ref="inner"
        class="tw-w-[100vw] tw-h-[100vh] tw-object-cover"
        :src="sectionImageUrl"
      >
    </div>
    <div
      class="tw-flex tw-flex-col tw-h-full tw-w-full tw-py-5 tw-px-4
        md:tw-py-10 md:tw-px-10
        lg:tw-py-10 lg:tw-px-20 tw-bg-transparent"
    >
      <!-- Text One -->
      <div class="tw-relative tw-flex-1">
        <div
          class="tw-sticky tw-top-[35%]"
        >
          <div
            class="tw-h-full"
          >
            <div
              :class="[
                'tw-z-20 tw-flex tw-flex-row tw-w-full',
                isBuildTextOneLeft
                  ? 'tw-justify tablet:tw-justify-start tablet:tw-text-left desktop:tw-justify-start desktop:tw-text-left'
                  : 'tw-justify tw-text-start tablet:tw-justify-end tablet:tw-text-right desktop:tw-justify-end desktop:tw-text-right',
              ]"
            >
              <p
                :class="
                  [
                    isBuildTextOneLeft ? 'tw-text-left' : 'tw-text-right',
                    {
                      'tw-break-keep':checkLang()=='en'
                    }
                  ]"
                class="sectionOneText tw-line-clamp-[10] tw-text-white tw-text-sg-h3-mobile
              md:tw-w-[630px] md:tw-min-w[464px] md:tw-text-sg-h3-tablet
              lg:tw-w-[630px] lg:tw-text-sg-h3"
              >
                {{ sectionTextOne }}
              </p>
            </div>
          </div>
        </div>
      </div>


      <!-- Text Two -->
      <div
        v-if="!!sectionTextTwo?.trim()"
        :class="[
          'tw-z-20 tw-flex tw-flex-row tw-w-full',
          isBuildTextTwoLeft
            ? 'tw-justify tablet:tw-justify-start tablet:tw-text-left desktop:tw-justify-start desktop:tw-text-left'
            : 'tw-justify tw-text-start tablet:tw-justify-end tablet:tw-text-right desktop:tw-justify-end desktop:tw-text-right',
          isSpecialBuildRight ? 'tw-justify-end tw-text-right' : 'tw-justify-start tw-text-left',
        ]"
      >
        <p
          :class="
            [
              isBuildTextTwoLeft ? 'tw-text-left' : 'tw-text-right',
              {
                'tw-break-keep':checkLang()=='en'
              }
            ]"
          class="tw-line-clamp-10 tw-text-white tw-text-sg-h3-mobile
          md:tw-w-[630px] md:tw-min-w[464px] md:tw-text-sg-h3-tablet
          lg:tw-w-[630px] lg:tw-min-h-[52px] lg:tw-text-sg-h3"
        >
          {{ sectionTextTwo }}
        </p>
      </div>
      <!-- Horizontal Text -->
      <div
        v-if="isShowHorizontalText == true"
        ref="textBottomHeight"
        :class="randomIdTextTwo"
        class="tw-z-20 tw-flex tw-flex-col tw-space-y-3
        tablet:tw-flex-col tablet:tw-space-y-0
        md:tw-flex-col md:tw-items-end
        lg:tw-flex-row lg:tw-justify-between"
      >
        <!-- Text & SubText 1 -->
        <div class="tw-flex tw-flex-row lg:tw-flex-col">
          <p
            class="tw-text-sg-h3-mobile tw-text-white
          md:tw-text-sg-h3-tablet md:tw-text-right
          lg:tw-text-sg-h3 lg:tw-text-left"
          >
            {{ hText1 }}
            <span
              class="tw-text-sg-h3-mobile tw-pl-2 tw-text-white
              md:tw-p-0 md:tw-text-sg-h3-tablet md:tw-text-right
              lg:tw-p-0 lg:tw-text-sg-h3 lg:tw-text-left lg:tw-block"
            >
              {{ hSubText1 }}
            </span>
          </p>
        </div>

        <!-- Text & SubText 2 -->
        <div class="tw-flex tw-flex-row lg:tw-flex-col">
          <p
            class="tw-text-sg-h3-mobile tw-text-white
          md:tw-text-sg-h3-tablet md:tw-text-right
          lg:tw-text-sg-h3 lg:tw-text-center"
          >
            {{ hText2 }}
            <span
              class="tw-text-sg-h3-mobile tw-pl-2 tw-text-white
              md:tw-p-0 md:tw-text-sg-h3-tablet
              lg:tw-p-0 lg:tw-text-sg-h3 lg:tw-text-center lg:tw-block"
            >
              {{ hSubText2 }}
            </span>
          </p>
        </div>

        <!-- Text & SubText 3 -->
        <div class="tw-flex tw-flex-row md:tw-flex-row lg:tw-flex-col">
          <p
            class="tw-text-sg-h3-mobile tw-text-white
          md:tw-text-sg-h3-tablet md:tw-text-right
          lg:tw-text-sg-h3 lg:tw-text-right"
          >
            {{ hText3 }}
            <span
              class="tw-text-sg-h3-mobile tw-pl-2 tw-text-white tablet:tw-p-0
              md:tw-text-sg-h3-tablet
              lg:tw-p-0 lg:tw-text-sg-h3 lg:tw-text-right lg:tw-block"
            >
              {{ hSubText3 }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, onBeforeUnmount, onBeforeMount, toRefs, watch } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { checkLang } from '../../support/helper';
export default {
  props: {
    slugName: {
      type: String,
      default: '',
    },
    sectionTextOne: {
      type: String,
      default: 'Section Text One',
    },
    sectionTextTwo: {
      type: String,
      default: '',
    },
    sectionImageUrl: {
      type: String,
      default: 'https://picsum.photos/1200?random=2',
    },
    isFirstImage: {
      type: Boolean,
      default: false,
    },
    isSecondImage: {
      type: Boolean,
      default: false,
    },
    isThirdImage: {
      type: Boolean,
      default: false,
    },
    isBuildTextOneLeft: {
      type: Boolean,
      default: true,
    },
    isBuildTextTwoLeft: {
      type: Boolean,
      default: true,
    },
    isSpecialBuildRight: {
      type: Boolean,
      default: false,
    },
    isShowHorizontalText: {
      type: Boolean,
      default: false,
    },
    hText1: {
      type: String,
      default: '',
    },
    hSubText1: {
      type: String,
      default: '',
    },
    hText2: {
      type: String,
      default: '',
    },
    hSubText2: {
      type: String,
      default: '',
    },
    hText3: {
      type: String,
      default: '',
    },
    hSubText3: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const inner: Ref<HTMLElement | null> = ref(null);
    let containerDiv : Ref<HTMLElement | null> = ref(null);
    let textBottomHeight : Ref<HTMLElement | null> = ref(null);
    const { isFirstImage, isSecondImage, isThirdImage, sectionImageUrl } = toRefs(props);

    const randomIdTextOne: Ref<string> = ref('');
    const randomIdTextTwo: Ref<string> = ref('');
    const randomIdDiv: Ref<string> = ref('');

    const handleScroll = () => {
      let offsetY = window.pageYOffset;
      let scale;

      if (isFirstImage.value) {
        const delayedOffsetY = Math.max(0, offsetY - 500);
        scale = delayedOffsetY / 8000 + 1;
      } else if (isSecondImage.value) {
        const delayedOffsetY = Math.max(0, offsetY - 1000);
        scale = delayedOffsetY / 9000 + 1;
      } else if (isThirdImage.value) {
        const delayedOffsetY = Math.max(0, offsetY - 1700);
        scale = delayedOffsetY / 11000 + 1;
      }

      if (inner.value) {
        inner.value.style.transform = `scale(${scale})`;
      }
    };

    function generateRandomId() {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      randomIdTextOne.value = 'one' + result;
      randomIdTextTwo.value = 'two' + result;
      randomIdDiv.value = 'div' + result;
    }

    function handleResize() {
      const scrollIdOne = ScrollTrigger.getById(randomIdTextOne.value);
      if(scrollIdOne) {
        scrollIdOne.kill();
      }

      // gsap controller
      gsap.to('.' + randomIdTextOne.value + ' .sectionOneText', {
        scrollTrigger: {
          id: randomIdTextOne.value,
          trigger: '.' + randomIdTextOne.value,
          //endTrigger: '.' + endTrigger,
          start: 'top 45%',
          end:()=>`bottom 45%+=${(document.querySelector('.' + randomIdTextOne.value + ' .sectionOneText')as HTMLDivElement)?.offsetHeight}px`,
          toggleActions: 'restart none none none',
          scrub:true,
          markers: false,
          pinSpacing: false,
          pin: true,
          anticipatePin:1,
          invalidateOnRefresh: true,
          preventOverlaps: true,
          fastScrollEnd: false,
        },
      });
    }

    onBeforeMount(() => {
      generateRandomId();
    });

    onMounted(() => {
      if (containerDiv.value) {
        containerDiv.value.style.backgroundImage =
        'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(' + sectionImageUrl.value + ')';
      }
      window.addEventListener('scroll', handleScroll);

      false && console.log('isSpecialBuildRight', props.isSpecialBuildRight);

      // gsap.registerPlugin(ScrollTrigger);
      // if(getScreenSize() == 'desktop' && detectBrowser() == 'SAFARI')
      // {
      //   ScrollTrigger.normalizeScroll(true);
      // }
      // setTimeout(handleResize,300);
      // window.addEventListener('resize', handleResize);

    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      // window.removeEventListener('resize', handleResize);
    });

    return {
      inner,
      randomIdDiv,
      randomIdTextOne,
      randomIdTextTwo,
      containerDiv,
      textBottomHeight,
      checkLang
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sectionCustom {
  width: 100%;
  height: 1000px;
}

p {
  text-shadow: -4px 4px 15px rgba(0, 0, 0, 0.3);
}
</style>
