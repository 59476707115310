<template>
  <div>
    <!-- Image Border Rounded  -->
    <img
      :src="getImage()"
      alt="Event Image"
      class="tw-h-full tw-w-full tw-max-h-[92px] tw-rounded-tl-lg tw-rounded-tr-lg tw-bg-cover
      md:tw-max-h-[146px] md:tw-max-w-[260px] md:tw-h-[146px] md:tw-w-[260px]"
    >
    <!-- Sold Out / Paid Event / Free Event / Fully Booked / Selling Fast -->
    <div
      :class="[getEventStatusColor()]"
      class="tw-flex tw-justify-center tw-items-center tw-text-sg-body-3 tw-text-white tw-min-w-[164px]
      md:tw-h-7 lg:tw-h-8"
    >
      {{ getEventStatusText() }}
    </div>
    <!-- Event Card Content -->
    <div class="tw-h-full tw-w-full tw-rounded-bl-lg tw-rounded-br-lg tw-shadow-md tw-bg-white hover:tw-inset-0 hover:tw-bg-gray-50">
      <div class="tw-px-2 tw-pt-2 md:tw-px-4 md:tw-pt-3">
        <!-- Event Type -->
        <p class="tw-h-6 tw-text-sg-body-3-mobile md:tw-text-sg-body-3-tablet lg:tw-text-sg-body-3 tw-pb-1 tw-normal-case">
          {{ getEventTypeText() }}
        </p>
        <!-- Event Title -->
        <h3
          :class="[
            'tw-line-clamp-3 tw-h-[60px] tw-max-w-[228px] tw-text-sg-sh3-mobile',
            'md:tw-line-clamp-2 md:tw-h-[40px] md:tw-text-sg-sh3-tablet',
            'lg:tw-text-sg-sh3',
          ]"
        >
          {{ props.event.title }}
        </h3>
        <!-- Event Date -->
        <div class="tw-pt-4 tw-flex tw-flex-col md:tw-flex-row tw-gap-2">
          <p class="tw-text-sg-caption">
            {{ $t('common.dates') }}
          </p>
          <p class="tw-text-sg-caption">
            {{ props.event.acf.event_card_date ?? 'Date not specified' }}
          </p>
        </div>
        <!-- Find Out More Text Button -->
        <div class="tw-py-2 md:tw-py-3">
          <a
            :href="props.event.permalink"
          >
            <div class="tw-flex tw-flex-row tw-align-middle tw-items-center tw-justify-end tw-py-1 tw-pr-2 tw-gap-2">
              <span class="tw-text-sg-button-2 tw-text-[#0366D8] hover:tw-underline">{{ $t('ce_swiper_btn') }}</span>
              <inline-svg
                class="tw-w-3 tw-h-3"
                fill="#0366D8"
                :src="$cdn('icons/ce/arrow_chevron_right_blue.svg')"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import { Event } from '../../../types/types';
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });

let featuredImage = ref('');

function getImage(): string{
  featuredImage.value = props.event.acf?.event_gallery?.[0]?.sizes?.medium_large || props.event?.acf?.event_gallery?.[0]?.url || '';
  return featuredImage.value;
}

function getEventTypeText() {

  let text = '';

  switch (props.event.acf.event_type) {
    case '0':
      text = t('event.type_concert');
      break;
    case '1':
      text = t('event.type_theatre');
      break;
    case '2':
      text = t('event.type_exhibition');
      break;
    default:
      text = '';
      break;
  }

  return text;
}

function getEventStatusColor() {
  let color = '';

  if (props.event.acf.event_is_paid_event) {
    switch (props.event.acf.event_status) {
      case '0':
        color = 'tw-bg-[#0366D8]';
        break;
      case '1':
        color = 'tw-bg-[#F6610C]';
        break;
      case '2':
        color = 'tw-bg-[#E4100E]';
        break;
      default:
        color = 'tw-bg-[#E4100E]';
        break;
    }
  } else {
    switch (props.event.acf.event_status) {
      case '0':
        color = 'tw-bg-[#36A93F]';
        break;
      case '1':
        color = 'tw-bg-[#E4100E]';
        break;
      default:
        color = 'tw-bg-[#E4100E]';
        break;
    }
  }

  return color;
}

function getEventStatusText(): string {
  const eventStatus = props.event.acf.event_status;
  const isPaidEvent = props.event.acf.event_is_paid_event;

  if (isPaidEvent) {
    if (eventStatus === '0') return t('ce_paid_event');
    if (eventStatus === '1') return t('ce_selling_fast');
    if (eventStatus === '2') return t('ce_sold_out');
  } else {
    if (eventStatus === '0') return t('ce_free_event');
    if (eventStatus === '1') return t('ce_fully_booked');
  }

  return '';
}

const props = defineProps({
  event: {
    type: Object as PropType<Event>,
    required: true
  }
});
</script>
