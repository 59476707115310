<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import { ref} from 'vue';
const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen  = ref<boolean>(false);
const imageSourcesData = ref('');

const menuItems = [
  {
    label: 'zy_home_menu_common_sights',
    id   : 'common-sg-sights-section'
  },
  {
    label: 'zy_home_menu_origin_stories',
    id   : 'origin-stories-section'
  }
];

const slides = ref([
  {
    year             : '2024',
    image            : '/images/zy2024/home/slider/slide-1.jpg',
    description      : 'zy_home_slider_description_1',
    reference        : '[A]',
    position_from_top: 51,
    z_index          : 1,
    overlay_color    : '#88D8DF',
  },
  {
    year             : '2024',
    image            : '/images/zy2024/home/slider/slide-2.jpg',
    description      : 'zy_home_slider_description_2',
    reference        : '[B]',
    position_from_top: 0,
    z_index          : 2,
    overlay_color    : '#6BDE99',
  },
  {
    year             : '2024',
    image            : '/images/zy2024/home/slider/slide-4.jpg',
    description      : 'zy_home_slider_description_3',
    reference        : '[C]',
    position_from_top: 101,
    z_index          : 1,
    overlay_color    : '#88D8DF',
  },
  {
    year             : '2024',
    image            : '/images/zy2024/home/slider/slide-3.jpg',
    description      : 'zy_home_slider_description_4',
    reference        : '[D]',
    position_from_top: 51,
    z_index          : 2,
    overlay_color    : '#6BDE99',
  },
  {
    year             : '2024',
    image            : '/images/zy2024/home/slider/slide-5.jpg',
    description      : 'zy_home_slider_description_5',
    reference        : '[E]',
    position_from_top: 0,
    z_index          : 3,
    overlay_color    : '#88D8DF',
  },
]);

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};


const timeline_post = ref([]);

onBeforeMount(()=>{
  if(props.wordpressData?.timeline){
    timeline_post.value = props.wordpressData.timeline;
  }

  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});
onMounted(() => {


  nextTick(() => {

    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };


      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});
</script>

<template>
  <div class="tw-relative tw-overflow-hidden">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :zy-navigation="true"
    />
    <zy-secondary-navigation
      :menu-items-data="menuItems"
    />
    <div />
    <section class="tw-relative">
      <!-- Header section -->
      <div class="tw-relative">
        <picture>
          <img
            :src="$cdn('images/zy2024/banner.scaled.jpg')"
            class="tw-w-full tw-object-cover tw-h-[970px] sgTablet2:tw-h-[1296px] sgDesktop:tw-h-[970px] sgTablet:tw-h-[1124px] "
          >
        </picture>
        <div class="tw-absolute tw-inset-0 tw-top-[120px] sgTablet:tw-top-[160px] sgDesktop:tw-top-[200px]">
          <div class="tw-text-white tw-text-center">
            <img
              class="tw-pb-[10px] tw-h-[40px] sgTablet:tw-h-[60px] tw-mx-auto"
              :src="$cdn('images/zy2024/header.png')"
            >
            <div
              :class="[
                'tw-font-akkuratbold tw-pb-1',
                'tw-text-sg-zy-dp3-mobile sgTablet:tw-text-sg-zy-dp3-tablet sgDesktop:tw-text-sg-zy-dp3-desktop'
              ]"
            >
              {{ $t('zy_home_title') }}
            </div>
          </div>
        </div>
        <zy-paper-tear />
      </div>
    </section>

    <!-- Gallery section -->
    <section
      id="common-sg-sights-section"
      class="
          tw-flex tw-flex-col
          tw-items-center
          tw-bg-sg-zy24-orange
          tw-relative
          tw-overflow-visible
          tw-font-akkurat
          tw-tracking-wider
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
        "
    >
      <div
        class="
          sgDesktop:tw-max-w-[600px]
          tw-w-full
          tw-text-center
          tw-pt-10
          tw-pb-5
          sgTablet:tw-py-[60px]
          sgTablet:tw-px-[80px]
          sgDesktop:tw-px-0
        "
      >
        <p
          class="
              tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1
          "
          v-html="props.wordpressData.description"
        />
        <div
          class="
            tw-text-sg-sh1-mobile
            sgTablet:tw-text-sg-sh1-tablet
            sgDesktop:tw-text-sg-sh1
            tw-mt-[40px]
          "
          v-html="$t('zy_home_section_2_title')"
        />
      </div>
      <zy-home-swiper
        v-model:image-source-open="imageSourceOpen"
        :data="slides"
      />
      <div class="sgTablet:tw-h-[80px] tw-h-[60px]" />
      <zy-paper-tear />
    </section>

    <!-- Origin stories section -->
    <section
      id="origin-stories-section"
      class="
          tw-flex
          tw-justify-center
          tw-items-center
          tw-h-[820px]
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          sgTablet:tw-h-[600px]
          sgDesktop:tw-h-[800px]
          tw-font-akkurat tw-tracking-wider"
    >
      <div
        class="
          tw-flex
          tw-justify-center
          tw-items-center
          tw-h-full
          tw-w-full"
      >
        <div class="tw-flex tw-flex-col sgTablet:tw-flex-row tw-h-full">
          <!-- left side -->
          <div
            class="
              tw-flex
              tw-w-full

              sgDesktop2:tw-w-[400px]

              sgDesktop:tw-w-[392.5px]
              sgDesktop:tw-h-[800px]

              sgTablet2:tw-w-[400px]

              sgTablet:tw-w-[260px]
              sgTablet:tw-h-full
            "
          >
            <img
              :src="cdn('images/zy2024/home/origin_story.png')"
              class="tw-w-full tw-place-self-center tw-object-contain"
            >
          </div>
          <!-- right side -->
          <div
            class="
                tw-flex
                tw-w-full

                sgDesktop2:tw-w-[400px]

                sgDesktop:tw-w-[392.5px]
                sgDesktop:tw-h-[800px]
                sgDesktop:tw-pl-10

                sgTablet2:tw-w-[400px]
                sgTablet2:tw-pl-5
                sgTablet2:tw-py-10

                sgTablet:tw-w-[260px]
                sgTablet:tw-h-full
              "
          >
            <div class="tw-place-self-center">
              <div class="tw-text-sg-h3-mobile sgTablet:tw-text-sg-h3-tablet sgDesktop:tw-text-sg-h3">
                {{ $t('zy_home_section_3_title') }}
              </div>
              <p class="tw-mt-5 sgDesktop:tw-mt-10 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">
                {{ $t('zy_home_section_3_desc_1') }}
              </p>
              <p class="tw-mt-4 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">
                {{ $t('zy_home_section_3_desc_2') }}
              </p>

              <div class="tw-flex tw-w-full tw-justify-center sgTablet:tw-justify-start tw-mt-10 tw-mr-5 ">
                <a
                  :href="$langRedirect('/festivals/zhongyuan/origin-stories')"
                  target="_blank"
                  rel="noopener"
                  class="
                      tw-rounded-[26px]
                      tw-text-white
                      tw-bg-sg-zy24-brown
                      tw-px-6 tw-py-4
                      tw-text-sg-button-mobile-1
                      tablet:tw-text-sg-button-1
                      !tw-leading-[20px]
                      tw-h-[52px] tw-uppercase
                      hover:tw-bg-[#C9705A]
                      active:tw-bg-[#963D27]
                    "
                >
                  {{ $t('zy_home_discover_more') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>
