<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-ap-white-smoke tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{'!tw-flex': modelValue == true}"
    >

      <!-- Modal Content -->
      <div class="tw-mx-4 sgTablet:tw-mx-10 sgDesktop:tw-mx-20">
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div
            class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
            @click="handleModalClose"
          >
            <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div class="tw-w-full tw-shadow-ap-preset-1">



          <!-- Image Box -->
          <div class="">
            <img
              class="tw-object-cover tw-h-full tw-w-full tw-object-center tw-max-h-[80vh]"
              :src="$cdn('images/cny2024/greet-them-right/family-tree.jpeg')"
            >
          </div>

        </div>


      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { onMounted, computed,ref } from 'vue';

const props = defineProps<{
  modelValue: boolean,
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if(!newVal) {
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  }else{
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
  }
});
</script>
