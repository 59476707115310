<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { h, ref, computed} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const description = ref('');

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');

const uniqueMooncakeFlavours = ref([]);

const menuItems = [
  {
    label:'ma_mid_autumn_101_menu_celebrations',
    id:'ma_mid_autumn_101_menu_celebrations'
  },
  {
    label:'ma_mid_autumn_101_menu_lanterns_of_mid_autumn',
    id:'ma_mid_autumn_101_menu_lanterns_of_mid_autumn'
  },
  {
    label:'ma_mid_autumn_101_menu_lantern_riddles',
    id:'ma_mid_autumn_101_menu_lantern_riddles'
  },
  {
    label:'ma_mid_autumn_101_menu_moon_gazing',
    id:'ma_mid_autumn_101_menu_moon_gazing'
  },
  {
    label:'ma_mid_autumn_101_menu_tea_drinking',
    id:'ma_mid_autumn_101_menu_tea_drinking'
  },
  {
    label:'ma_mid_autumn_101_menu_mooncake_feasting',
    id:'ma_mid_autumn_101_menu_mooncake_feasting'
  },
];

const lanternData = ref([
  {
    id: 1,
    title: 'ma_mid_autumn_101_lanterns_slider_title_1',
    image: '/images/ma2024/mid-autumn-101/lantern-of-mid-autumn/ma_lantern_slider_01.jpg',
    description: 'ma_mid_autumn_101_lanterns_slider_description_1',
    rotateAngle: '-4deg'
  },
  {
    id: 2,
    title: 'ma_mid_autumn_101_lanterns_slider_title_2',
    image: '/images/ma2024/mid-autumn-101/lantern-of-mid-autumn/ma_lantern_slider_02.jpg',
    description: 'ma_mid_autumn_101_lanterns_slider_description_2',
    rotateAngle: '4deg'
  },
  {
    id: 3,
    title: 'ma_mid_autumn_101_lanterns_slider_title_3',
    image: '/images/ma2024/mid-autumn-101/lantern-of-mid-autumn/ma_lantern_slider_03.jpg',
    description: 'ma_mid_autumn_101_lanterns_slider_description_3',
    rotateAngle: '4deg'
  },
  {
    id: 4,
    title: 'ma_mid_autumn_101_lanterns_slider_title_4',
    image: '/images/ma2024/mid-autumn-101/lantern-of-mid-autumn/ma_lantern_slider_04.jpg',
    description: 'ma_mid_autumn_101_lanterns_slider_description_4',
    rotateAngle: '-4deg'
  },
]);

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        false && console.log('sup clicked');
        imageSourceOpen.value = !imageSourceOpen.value;
      };
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

const sliderData = ref<any[]>([]);


onBeforeMount(()=>{
  if(props.wordpressData?.image_sources?.length > 0) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }

  if (props.wordpressData?.unique_mooncake_flavours) {
    uniqueMooncakeFlavours.value = props.wordpressData.unique_mooncake_flavours;
  }

  if (props.wordpressData?.description) {
    description.value = props.wordpressData.description;
  }

  sliderData.value = [
    {
      image: cdn('images/ma2024/about/typical-mooncakes/ma_typical_mooncakes_slider_01.jpg'),
      description: 'These soft, no-bake mooncakes have been available in Singapore since the 1960s! It’s said they were invented in Hong Kong in the 1960s as a healthier alternative. When first brought to Singapore, snowskin mooncakes were mainly white and not as exquisite as they are today. One of the first to offer snowskin mooncakes, Poh Guan Cake House developed new flavours such as pandan and chocolate. Since then, these delicate mooncakes have become a must-eat treat for locals! [8]'
    },
    {
      image: cdn('images/ma2024/about/typical-mooncakes/ma_typical_mooncakes_slider_02.jpg'),
      description: 'These soft, no-bake mooncakes have been available in Singapore since the 1960s! It’s said they were invented in Hong Kong in the 1960s as a healthier alternative. When first brought to Singapore, snowskin mooncakes were mainly white and not as exquisite as they are today. One of the first to offer snowskin mooncakes, Poh Guan Cake House developed new flavours such as pandan and chocolate. Since then, these delicate mooncakes have become a must-eat treat for locals! [8]'
    }
  ];
  // if(props.wordpressData?.timeline?.length > 0){
  //   sliderData.value = props.wordpressData.timeline;
  // }
});

const backgroundStyle = computed(() => ({
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${cdn('images/ma2024/mid-autumn-101/unique-mooncakes/ma_mooncake_background.jpg')}) lightgray 50% / cover no-repeat`
}));

</script>

<template>
  <div :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ma-navigation="true"
      :ma2-navigation="wordpressData?.canView?.includes('sidebar.ma2')"
    />
    <ma-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- header -->
    <section class="tw-relative">
      <!-- background -->
      <picture>
        <img
          :src="$cdn('images/ma2024/ma_sub_banner.jpg')"
          :class="[
            'tw-w-full',
            'tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]',
            'tw-object-cover'
          ]"
        >
      </picture>
      <!-- title -->
      <div class="tw-bg-black/20 tw-absolute tw-w-full tw-h-full tw-top-0" />
      <div class="tw-absolute tw-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-ma-mobile-h1 sgTablet:tw-text-sg-ma-tablet-h1 sgDesktop:tw-text-sg-ma-desktop-h1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_mid_autumn_101_title') }}
          </div>
        </div>
      </div>
    </section>

    <!-- description -->
    <section
      id="ma_mid_autumn_101_menu_celebrations"
      :class="[
        'tw-pt-5 sgTablet:tw-pt-10',
        'tw-pb-5 sgDesktop:tw-pb-10',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20',
        'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-5',
      ]"
    >
      <!-- title -->
      <div
        :class="[
          'tw-w-full sgTablet:tw-max-w-[800px]',
          'tw-text-center tw-text-[#3E2551]',
          'sgDesktop:tw-text-sg-ma-desktop-h2 tw-text-sg-ma-tablet-h2',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ma_mid_autumn_101_celebrations') }}
      </div>
      <!-- description -->
      <div
        id="sup-handler-container"
        :class="[
          'tw-tracking-wider',
          'tw-max-w-[800px] tw-text-center',
          'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
        ]"
        v-html="description"
      />
    </section>

    <!-- celebration -->
    <section
      :class="[
        'tw-pt-5',
        'tw-pb-10 sgDesktop:tw-pb-[60px]',
        'tw-flex tw-flex-col',

      ]"
      style="background: linear-gradient(180deg, #FFF 0%, #F2B579 100%);"
    >
      <ma-101-celebration-swiper
        v-model:image-source-open="imageSourceOpen"
      />
      <div
        :class="[
          'tw-flex tw-flex-col',
          'tw-w-full',
          'tw-items-center tw-justify-center',
          'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        ]"
      >

        <div
          :class="[
            'tw-flex tw-flex-col',
            'tw-max-w-[800px]',
            'tw-gap-5',
            'tw-pt-10 sgTablet2:tw-pt-0',
          ]"
        >
          <!-- description -->
          <div
            id="sup-handler-container"
            :class="[
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1',
              'tw-text-center sgTablet2:tw-text-left',
              'tw-text-sg-ma24-purple-b60'
            ]"
          >
            {{ $t('ma_mid_autumn_101_celebrations_description') }}
            <sup
              class="tw-text-sg-zy24-blue tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1"
            >[1]</sup>
            <sup
              class="tw-text-sg-zy24-blue tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1"
            >[2]</sup>
          </div>
        </div>

      </div>
    </section>

    <!-- lanterns of mid-autumn -->
    <section
      id="ma_mid_autumn_101_menu_lanterns_of_mid_autumn"
      :class="[
        'tw-relative tw-w-full tw-h-full',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'tw-bg-[#F5C494]',
        'sgDesktop:tw-px-20 sgTablet:tw-px-10 tw-px-4',
        'sgDesktop2:tw-pt-[84px] sgTablet2:tw-pt-[72px] tw-pt-[62px]',
        'tw-pb-10',
      ]"
    >
      <div
        :class="[
          'tw-w-full tw-h-full',
          'tw-max-w-[800px]',
          'tw-gap-10',
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
        ]"
      >
        <div>
          <!-- title -->
          <div
            :class="[
              'tw-w-full sgTablet:tw-max-w-[800px]',
              'tw-pb-5',
              'tw-text-center tw-text-[#3E2551]',
              'sgDesktop:tw-text-sg-ma-desktop-h2 tw-text-sg-ma-tablet-h2',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_mid_autumn_101_lanterns_title') }}
          </div>
          <!-- description -->
          <div
            id="sup-handler-container"
            :class="[
              'tw-tracking-wider',
              'tw-max-w-[800px] tw-text-center',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
            ]"
          >
            {{ $t('ma_mid_autumn_101_lanterns_description_1') }}
            <sup
              :class="[
                'tw-text-sg-zy24-blue',
                'tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1'
              ]"
            >
              [3]
            </sup>
            <sup
              :class="[
                'tw-text-sg-zy24-blue',
                'tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1'
              ]"
            >
              [4]
            </sup>

          </div>
        </div>
        <!-- stamp -->
        <img
          :class="['tw-w-[204px] tw-h-[140px]']"
          :src="checkLang() === 'en' ? $cdn('images/ma2024/ma_stamp_EN.svg') : $cdn('images/ma2024/ma_stamp_CN.svg')"
        >
        <!-- description -->
        <div
          id="sup-handler-container"
          :class="[
            'tw-tracking-wider',
            'tw-max-w-[800px] tw-text-center',
            'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
          ]"
        >
          {{ $t('ma_mid_autumn_101_lanterns_description_2') }}
          <sup
            :class="[
              'tw-text-sg-zy24-blue',
              'tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1'
            ]"
          >
            [5]
          </sup>
          <sup
            :class="[
              'tw-text-sg-zy24-blue',
              'tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1'
            ]"
          >
            [6]
          </sup>
        </div>
        <div
          :class="[
            'sgTablet2:tw-grid tw-flex tw-flex-col sgTablet2:tw-grid-cols-2',
            'tw-gap-4',
            'sgTablet2:tw-max-w-[800px] tw-max-w-[320px]',
          ]"
        >
          <div
            v-for="(lantern, i) in lanternData"
            :key="lantern.id"
            :class="[
              'tw-flex tw-flex-col tw-items-center',
            ]"
          >
            <ma-lantern-card
              :image="lantern.image"
              :rotate-angle="lantern.rotateAngle"
              width="392px"
            >
              <div
                :class="[
                  'tw-w-full',
                  'tw-pb-3',
                  'tw-text-sg-ma-sh1',
                  'tw-text-[#4E2E65]',
                  { 'tw-font-akkuratbold' : checkLang() == 'en' },
                  { 'tw-font-notosansbold' : checkLang() != 'en' },
                ]"
              >
                {{ $t(lantern.title) }}
              </div>
              <!-- description -->
              <div
                id="sup-handler-container"
                :class="[
                  'lantern-card-description',
                  'tw-text-[#00633D]',
                  'tw-text-sg-ma-sh3',
                  { 'tw-font-akkuratbold' : checkLang() == 'en' },
                  { 'tw-font-notosansbold' : checkLang() != 'en' },
                ]"
                v-html="$t(lantern.description)"
              />
            </ma-lantern-card>
          </div>
        </div>

      </div>
      <div class="tw-absolute tw-top-0 tw-w-full">
        <ma-wave-break-line
          image="images/ma2024/mid-autumn-101/lantern-of-mid-autumn/ma-lantern-line-break.svg"
          direction=""
        />
      </div>
    </section>

    <!-- lantern riddles -->
    <section
      id="ma_mid_autumn_101_menu_lantern_riddles"
      :class="[
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'sgDesktop:tw-px-20 sgTablet:tw-px-10 tw-px-4',
        'sgTablet:tw-pt-10 tw-pt-5',
        'sgTablet:tw-pb-10 tw-pb-5',
      ]"
      style="background: linear-gradient(180deg, #F5C494 0%, #BBA1CD 100%);"
    >
      <div
        :class="[
          'tw-max-w-[800px] tw-gap-10',
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'tw-w-full tw-h-full',
        ]"
      >
        <div
          :class="[
            'tw-w-full sgTablet2:tw-h-[476px]',
            'tw-flex tw-gap-10',
            'tw-flex-col sgTablet2:tw-flex-row tw-items-center tw-justify-center',
          ]"
        >
          <div
            :class="[
              'tw-min-w-[320px]',
              'sgTablet:tw-max-w-[320px]',
            ]"
          >
            <img
              style="transform: rotate(-4deg);"
              :src="$cdn('images/ma2024/mid-autumn-101/lantern-riddle/ma_riddle_feature_img.jpg')"
            >
          </div>
          <div
            :class="[
              'tw-flex tw-flex-col',
            ]"
          >
            <!-- title -->
            <div
              :class="[
                'tw-pb-5',
                'tw-text-[#3E2551]',
                'tw-text-center sgTablet2:tw-text-left',
                'sgDesktop:tw-text-sg-ma-desktop-h2 sgTablet:tw-text-sg-ma-tablet-h2 tw-text-sg-ma-mobile-h2',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ $t('ma_mid_autumn_101_lanterns_riddles_title') }}
            </div>
            <!-- description -->
            <div
              id="sup-handler-container"
              :class="[
                'tw-tracking-wider',
                'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
              ]"
            >
              {{ $t('ma_mid_autumn_101_lanterns_riddles_description_1') }}
              <sup
                :class="[
                  'tw-text-sg-zy24-blue',
                  'tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1'
                ]"
              >
                [7]
              </sup>

            </div>
          </div>

        </div>

        <ma-lantern-riddle-swiper />

        <img
          :class="[
            'tw-h-[140px] tw-w-[208px]'
          ]"
          :src="checkLang() === 'en' ? $cdn('images/ma2024/ma_stamp_EN.svg') : $cdn('images/ma2024/ma_stamp_CN.svg')"
        >
        <!-- description -->
        <div
          id="sup-handler-container"
          :class="[
            'tw-tracking-wider tw-text-center',
            'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
          ]"
        >
          {{ $t('ma_mid_autumn_101_lanterns_riddles_description_2') }}
          <sup
            :class="[
              'tw-text-sg-zy24-blue',
              'tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1'
            ]"
          >
            [8]
          </sup>

        </div>
      </div>
    </section>

    <!-- Moon Gazing -->
    <section
      id="ma_mid_autumn_101_menu_moon_gazing"
      :class="[
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'tw-bg-[#BBA1CD]',
        'sgDesktop:tw-px-20 sgTablet:tw-px-10 tw-px-4',
        'sgTablet:tw-pt-10 tw-pt-5',
        'sgTablet:tw-pb-10 tw-pb-5',
      ]"
    >
      <div
        :class="[
          'tw-max-w-[800px] tw-gap-10',
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'tw-w-full tw-h-full',
        ]"
      >
        <div
          :class="[
            'tw-flex tw-items-center tw-justify-center',
            'tw-gap-5'
          ]"
        >
          <img
            :class="[
              'tw-w-[120px]',
              'tw-hidden sgTablet:tw-block'
            ]"
            :src="$cdn('images/ma2024/mid-autumn-101/moon-gazing/ma_moon_gazing_moon.png')"
          >
          <!-- title -->
          <div
            :class="[
              'tw-pb-5',
              'tw-text-[#3E2551]',
              'tw-text-center sgTablet2:tw-text-left',
              'sgDesktop:tw-text-sg-ma-desktop-h2 sgTablet:tw-text-sg-ma-tablet-h2 tw-text-sg-ma-mobile-h2',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_mid_autumn_101_moon_gazing_title') }}
          </div>
          <img
            :class="[
              'tw-w-[120px]',
              'tw-hidden sgTablet:tw-block',
              'tw-scale-x-[-1]',
            ]"
            :src="$cdn('images/ma2024/mid-autumn-101/moon-gazing/ma_moon_gazing_moon.png')"
          >
        </div>
        <!-- description -->
        <div
          id="sup-handler-container"
          :class="[
            'tw-tracking-wider tw-text-center',
            'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
          ]"
        >
          {{ $t('ma_mid_autumn_101_moon_gazing_description_1') }}
        </div>
        <!-- description -->
        <div
          id="sup-handler-container"
          :class="[
            'tw-tracking-wider tw-text-center',
            'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
          ]"
        >
          {{ $t('ma_mid_autumn_101_moon_gazing_description_2') }}
        </div>
        <div
          :class="[
            'tw-w-full tw-gap-10 sgTablet2:tw-h-[262px]',
            'tw-flex tw-flex-col sgTablet2:tw-flex-row tw-items-center',
          ]"
        >
          <img
            :class="[
              'tw-w-[320px]',
            ]"
            style="transform: rotate(-4deg);"
            :src="checkLang() === 'en' ? $cdn('images/ma2024/mid-autumn-101/moon-gazing/ma_moon_gazing_EN.jpg') : $cdn('images/ma2024/mid-autumn-101/moon-gazing/ma_moon_gazing_CN.jpg')"
          >
          <!-- description -->
          <div
            id="sup-handler-container"
            :class="[
              'tw-tracking-wider tw-text-start',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
            ]"
            v-html="$t('ma_mid_autumn_101_moon_gazing_description_3')"
          />

        </div>
      </div>
    </section>

    <!-- Tea Drinking -->
    <section>
      <div
        id="ma_mid_autumn_101_menu_tea_drinking"
        :class="[
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'tw-bg-[#BBA1CD]',
          'sgDesktop:tw-px-20 sgTablet:tw-px-10 tw-px-4',
          'sgTablet:tw-pt-10 tw-pt-5',
          'sgTablet:tw-pb-10 tw-pb-5',
          'tw-mt-[-1px]'
        ]"
      >
        <div
          :class="[
            'tw-max-w-[800px]',
            'tw-flex tw-flex-col tw-items-center tw-justify-center',
            'tw-w-full tw-h-full',
          ]"
        >
          <div
            :class="[
              'tw-flex tw-items-center tw-justify-center',
              'tw-gap-5'
            ]"
          >
            <img
              :class="[
                'tw-w-[120px]',
                'tw-hidden sgTablet:tw-block'
              ]"
              :src="$cdn('images/ma2024/mid-autumn-101/tea-drinking/ma_tea_drinking_teapot.png')"
            >
            <!-- title -->
            <div
              :class="[
                'tw-text-[#4E2E65]',
                'tw-text-center sgTablet2:tw-text-left',
                'sgDesktop:tw-text-sg-ma-desktop-h2 sgTablet:tw-text-sg-ma-tablet-h2 tw-text-sg-ma-mobile-h2',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ $t('ma_mid_autumn_101_tea_drinking_title') }}
            </div>
            <img
              :class="[
                'tw-w-[120px]',
                'tw-hidden sgTablet:tw-block',
                'tw-scale-x-[-1]',
              ]"
              :src="$cdn('images/ma2024/mid-autumn-101/tea-drinking/ma_tea_drinking_teapot.png')"
            >
          </div>
          <div
            :class="[
              'tw-flex tw-flex-col tw-gap-5',
            ]"
          >
            <!-- description -->
            <div
              id="sup-handler-container"
              :class="[
                'tw-tracking-wider',
                'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
              ]"
              v-html="$t('ma_mid_autumn_101_tea_drinking_description_1')"
            />
            <!-- description with image -->
            <div
              :class="[
                'tw-w-full sgTablet2:tw-gap-10 tw-gap-5 sgTablet2:tw-h-[286px]',
                'tw-flex tw-flex-col sgTablet2:tw-flex-row tw-items-center',
              ]"
            >
              <div
                style="transform: rotate(-4deg);"
              >
                <div :class="['tw-w-full tw-py-1 tw-px-[3px] tw-bg-white']">
                  <img
                    :class="[
                      'tw-max-w-[320px] tw-h-[240px]',
                    ]"
                    :src="$cdn('images/ma2024/mid-autumn-101/tea-drinking/ma_tea_drinking_feature_img.jpg')"
                  >
                </div>
                <div
                  id="sup-handler-container"
                  :class="[
                    'tw-text-sg-ma-bt2',
                  ]"
                  style="line-height: 24px;"
                  v-html="$t('ma_mid_autumn_101_tea_drinking_image_caption')"
                />
              </div>
              <!-- description -->
              <div
                id="sup-handler-container"
                :class="[
                  'tw-tracking-wider tw-text-start',
                  'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
                ]"
                v-html="$t('ma_mid_autumn_101_tea_drinking_description_2')"
              />
            </div>
            <!-- description -->
            <div
              id="sup-handler-container"
              :class="[
                'tw-tracking-wider',
                'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
              ]"
              v-html="$t('ma_mid_autumn_101_tea_drinking_description_3')"
            />
            <!-- description -->
            <div
              id="sup-handler-container"
              :class="[
                'tw-tracking-wider',
                'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
              ]"
              v-html="$t('ma_mid_autumn_101_tea_drinking_description_4')"
            />
          </div>

        </div>
      </div>
    </section>

    <!-- Mooncake Feasting -->
    <section
      id="ma_mid_autumn_101_menu_mooncake_feasting"
      class="tw-relative"
      :style="backgroundStyle"
    >
      <section
        :class="[
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'sgDesktop:tw-px-20 sgTablet:tw-px-10 tw-px-4',
          'sgDesktop2:tw-pt-[84px] sgTablet2:tw-pt-[72px] sgTablet:tw-pt-[62px] tw-pt-[52px]',
          'sgTablet:tw-pb-10 tw-pb-5',
        ]"
      >
        <div
          :class="[
            'tw-flex tw-flex-col tw-max-w-[800px] tw-items-center tw-justify-center',
          ]"
        >
          <!-- title -->
          <div
            :class="[
              'tw-pb-5',
              'tw-text-[#EB8C2E]',
              'tw-text-center sgTablet2:tw-text-left',
              'sgDesktop:tw-text-sg-ma-desktop-h2 sgTablet:tw-text-sg-ma-tablet-h2 tw-text-sg-ma-mobile-h2',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_mid_autumn_101_mooncake_feasting_title') }}
          </div>
          <!-- description -->
          <div
            id="sup-handler-container"
            :class="[
              'tw-pb-10',
              'tw-tracking-wider tw-text-white',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
            ]"
            v-html="$t('ma_mid_autumn_101_mooncake_feasting_description_1')"
          />
          <!-- description with image -->
          <div
            :class="[
              'tw-pb-5',
              'tw-text-white',
              'tw-w-full tw-gap-10',
              'tw-grid tw-grid-cols-1 sgTablet2:tw-grid-cols-2',
              'tw-flex tw-flex-col tw-items-center tw-justify-center',
            ]"
          >
            <div
              :class="[
                'tw-flex tw-flex-col tw-gap-6 tw-items-center',
              ]"
            >
              <img
                :class="[
                  'tw-w-[373.625px]'
                ]"
                style="transform: rotate(-4deg);"
                :src="checkLang()=='en' ? $cdn('images/ma2024/mid-autumn-101/unique-mooncakes/ma_unique_mooncake_feature_en.png') : $cdn('images/ma2024/mid-autumn-101/unique-mooncakes/ma_unique_mooncake_feature_cn.png')"
              >
            </div>
            <div
              :class="[
                'tw-flex tw-flex-col tw-gap-6',
              ]"
            >
              <div
                id="sup-handler-container"
                :class="[
                  'tw-tracking-wider tw-text-start',
                  'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
                ]"
                v-html="$t('ma_mid_autumn_101_mooncake_feasting_description_2')"
              />
              <div
                id="sup-handler-container"
                :class="[
                  'tw-tracking-wider tw-text-start',
                  'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
                ]"
                v-html="$t('ma_mid_autumn_101_mooncake_feasting_description_3')"
              />
            </div>
          </div>

          <!-- description -->
          <div
            id="sup-handler-container"
            :class="[
              'tw-pb-6',
              'tw-text-white',
              'tw-tracking-wider tw-text-start',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
            ]"
            v-html="$t('ma_mid_autumn_101_mooncake_feasting_description_4')"
          />
          <!-- description -->
          <div
            id="sup-handler-container"
            :class="[
              'tw-text-white ma-mooncake-feasting',
              'tw-tracking-wider tw-text-start',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
            ]"
            v-html="$t('ma_mid_autumn_101_mooncake_feasting_description_5')"
          />

        </div>
      </section>
      <section
        :class="[
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'sgTablet:tw-pt-10 tw-pt-5',
          'sgDesktop2:tw-pb-[104px] sgTablet2:tw-pb-[92px] sgTablet:tw-pb-[82px] tw-pb-[72px]',
        ]"
      >
        <!-- title -->
        <div
          :class="[
            'tw-flex tw-flex-col tw-items-center tw-justify-center tw-max-w-[600px]',
            'tw-pb-10',
            'tw-text-white',
            'tw-text-center',
            'sgDesktop:tw-text-sg-ma-desktop-h4 sgTablet:tw-text-sg-ma-tablet-h4 tw-text-sg-ma-mobile-h4',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('ma_mid_autumn_101_mooncake_feasting_highlights_title') }}
        </div>
        <ma-unique-mooncake-flavour-swiper
          :list="uniqueMooncakeFlavours"
          :handle-open-image-source-modal="handleOpenImageSourceModal"
        />
      </section>
      <div class="tw-absolute tw-top-0 tw-w-full tw-mt-[-2px]">
        <ma-wave-break-line
          image="images/ma2024/mid-autumn-101/unique-mooncakes/ma_line_break.svg"
          direction=""
        />
      </div>
      <div class="tw-absolute tw-bottom-0 tw-w-full">
        <ma-wave-break-line image="images/ma2024/ma-cta-line-break.svg" />
      </div>
    </section>

    <ma-bottom-cta-section
      :title="$t('ma_mid_autumn_101_read_more_title')"
      :cta-button="$t('ma_mid_autumn_101_discover_more')"
      :cta-link="wordpressData?.explore_online_url_link"
      :image="$cdn('images/ma2024/mid-autumn-101/ma_101_cta.png')"
    />

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style scoped>

#sup-handler-container:deep(sup){
  cursor: pointer;
  color: #0366D8;
}

#sup-handler-container:deep(a){
  cursor: pointer;
  text-decoration: underline;
}

.ma-mooncake-feasting:deep(sup){
  color: #fff !important;
}

.lantern-card-description:deep(sup){
  color: #00633D !important;
  font-family: 'Akkurat' !important;
  cursor: pointer;
}
</style>
