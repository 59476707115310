<template>
  <section>
    <div
      :class="[
        'tw-w-full',
      ]"
      :style="computedStyle"
    />
  </section>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { cdn } from '../../support/cdn';

const props = defineProps({
  image: {
    type: String,
    required: true
  },
  direction: {
    type: String,
    required: false,
    default: () => 'up'
  }
});

onMounted(() => {
  updateHeight();
  window.addEventListener('resize', updateHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateHeight);
});

// Computed style to set the background size
const computedStyle = computed(() => {
  return {
    height: `${currentHeight.value}`,
    backgroundImage: `url(${ cdn(props.image) })`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: `auto ${currentHeight.value}`,
  };
});

// Reactive property to store the current height
const currentHeight = ref('12px');

// Function to update height based on window width
const updateHeight = () => {
  if (window.innerWidth >= 1024) { // sgDesktop2: '1440px'
    currentHeight.value = '43px';
  } else if (window.innerWidth >= 800) { // sgDesktop: '1025px'
    currentHeight.value = '32px';
  } else if (window.innerWidth >= 600) { // sgTablet2: '800px'
    currentHeight.value = '22px';
  } else {
    currentHeight.value = '12px'; // Default for smaller screens
  }
};
</script>
