<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="true"
      :is-hidden="false"
      :page-url="page_url"
    />
    <!-- Header -->
    <div class="headerCustom">
      <div
        ref="inner"
        class="inner"
      />
      <!-- ROOTS TEXT -->
      <div
        class="tw-absolute tw-flex tw-flex-col tw-justify-end tw-px-4 tw-pb-5 tw-inset-0 tw-max-h-[100vh] tw-max-w-[100vw]
        md:tw-px-10 md:tw-pr-0 md:tw-pb-0 md:tw-flex-row md:tw-justify-between
        lg:tw-pl-20"
      >
        <div class="tw-flex tw-flex-col tw-justify-end">
          <p
            class="tw-uppercase tw-text-white tw-text-sg-dp2-mobile
        md:tw-text-sg-dp2-tablet md:tw-pb-10
        lg:tw-text-sg-dp2"
          >
            {{ $t('roots') }}
          </p>
        </div>
        <!--  <img
          class="landscape-hidden tw-hidden xl:tw-block"
          :src="$cdn('images/ren_vector.svg')"
          alt="ren vector"
        > -->
      </div>
    </div>
    <!-- Section 1 -->
    <ap-section-layout
      :section-text-one="topTextSection1"
      :section-text-two="bottomTextSection1"
      :section-image-url="imageSection1"
      :is-first-image="true"
    />
    <!-- Section 2 -->
    <ap-section-layout
      :section-text-one="topTextSection2"
      :section-text-two="bottomTextSection2"
      :section-image-url="imageSection2"
      :is-build-text-one-left="isMobileView"
      :is-build-text-two-left="isMobileView"
      :is-second-image="true"
    />
    <!-- Section 3 -->
    <ap-section-layout
      :section-text-one="topTextSection3"
      :section-text-two="bottomTextSection3"
      :section-image-url="imageSection3"
      :is-build-text-one-left="isMobileView"
      :is-build-text-two-left="false"
      :is-special-build-right="true"
      :is-third-image="true"
    />
    <!-- SubSection 1 -->
    <ap-section-layout-2
      :section-text="textSubsection1"
      :image-url="imageSubsection1"
    />
    <!-- SubSection 2 -->
    <ap-section-layout-2
      :section-text="textSubsection2"
      :image-url="imageSubsection2"
      :is-image-on-left="false"
    />
    <!-- SubSection 3 -->
    <ap-section-layout-2
      :section-text="textSubsection3"
      :image-url="imageSubsection3"
    />
    <!-- SubSection 4 -->
    <ap-section-layout-2
      :section-text="textSubsection4"
      :image-url="imageSubsection4"
      :is-image-on-left="false"
    />

    <!-- Books/Articles/Videos/Games Section -->
    <roots-read-more-section />

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      v-if="!!imageSources"
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <ap-swiper
      :swiper-type="'books'"
      :books-data="booksData"
      :description="$t('deep_dive_into_roots')"
      :title="$t('swiper_title_articles')"
    />
    <ap-swiper
      :swiper-type="'videos'"
      :videos-data="videosData"
      :description="$t('press_play_roots')"
      :title="$t('swiper_title_videos')"
    />

    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script>
import { ref, toRefs, computed, onMounted } from 'vue';
import { getScreenSize,checkLang } from '../support/helper';
import { useI18n } from 'vue-i18n';
import { cdn } from '../support/cdn';
export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    // const { t, locale } = useI18n({ useScope: 'global' });
    const inner = ref(null);
    const isMobileView = ref(false);

    const {t} = useI18n();

    const { wordpressData } = toRefs(props);

    const topTextSection1 = computed(() => wordpressData.value.top_text_section_1);
    const bottomTextSection1 = computed(() => wordpressData.value.bottom_text_section_1);
    const imageSection1 = computed(() => wordpressData.value.image_section_1);
    const topTextSection2 = computed(() => wordpressData.value.top_text_section_2);
    const bottomTextSection2 = computed(() => wordpressData.value.bottom_text_section_2);
    const imageSection2 = computed(() => wordpressData.value.image_section_2);
    const topTextSection3 = computed(() => wordpressData.value.top_text_section_3);
    const bottomTextSection3 = computed(() => wordpressData.value.bottom_text_section_3);
    const imageSection3 = computed(() => wordpressData.value.image_section_3);
    const textSubsection1 = computed(() => wordpressData.value.text_subsection_1);
    const imageSubsection1 = computed(() => wordpressData.value.image_subsection_1);
    const textSubsection2 = computed(() => wordpressData.value.text_subsection_2);
    const imageSubsection2 = computed(() => wordpressData.value.image_subsection_2);
    const textSubsection3 = computed(() => wordpressData.value.text_subsection_3);
    const imageSubsection3 = computed(() => wordpressData.value.image_subsection_3);
    const textSubsection4 = computed(() => wordpressData.value.text_subsection_4);
    const imageSubsection4 = computed(() => wordpressData.value.image_subsection_4);
    const imageSources = computed(() => wordpressData.value.image_sources);
    const page_url = computed(() => wordpressData.value.page_url);

    // console.log('check t', t('deep_dive_into_roots'));
    const booksData = computed(()=>[
      {
        id: 1,
        title: t('root_articles_1'),
        external_url: process.env.WP_SITEURL + '/roots/discover-ten-key-public-policies-that-made-us-who-we-are',
        external_url: `${checkLang() != 'zh-hans' ? process.env.WP_SITEURL + '/roots/discover-ten-key-public-policies-that-made-us-who-we-are/' : process.env.WP_SITEURL + '/roots/discover-ten-key-public-policies-that-made-us-who-we-are/?lang=zh-hans'}`,
        image_url: '',
      },
      {
        id: 2,
        title: t('root_articles_2'),
        external_url: `${checkLang() != 'zh-hans' ? process.env.WP_SITEURL + '/roots/find-out-more-about-five-aspects-that-shaped-chinese-singaporean-culture/' : process.env.WP_SITEURL + '/roots/find-out-more-about-five-aspects-that-shaped-chinese-singaporean-culture/?lang=zh-hans'}`,
        image_url: '',
      },
      {
        id: 3,
        title: t('root_articles_3'),
        external_url: `${checkLang() != 'zh-hans' ? process.env.WP_SITEURL + '/roots/chinese-names-of-singapore/' : process.env.WP_SITEURL + '/roots/chinese-names-of-singapore/?lang=zh-hans'}`,
        image_url: '',
      },
    ]);
    const videosData = computed(()=> [
      {
        id: 1,
        title: t('one_of_us_episode_10'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=c-QwACQ-Scc',
        image_url: cdn('videos_thumbnails/Roots/The_Conversation.jpg'),
      },
      {
        id: 2,
        title: t('one_of_us_episode_6'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=J9DUYeEy2pA',
        image_url: cdn('videos_thumbnails/Roots/The_Prologue.jpg'),
      },
      {
        id: 3,
        title: t('one_of_us_episode_5'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=2P6y8p0l718',
        image_url: cdn('videos_thumbnails/Roots/The_Hainanese.jpg'),
      },
      {
        id: 4,
        title: t('one_of_us_episode_4'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=VFKeqvNV12E',
        image_url: cdn('videos_thumbnails/Roots/The_Hakkas.jpg'),
      },
      {
        id: 5,
        title: t('one_of_us_episode_3'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=CZTM-8LPDDM',
        image_url: cdn('videos_thumbnails/Roots/The_Cantonese.jpg'),
      },
      {
        id: 6,
        title: t('one_of_us_episode_2'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=QXKcVg14DqE',
        image_url: cdn('videos_thumbnails/Roots/The_Teochews.jpg'),
      },
      {
        id: 7,
        title: t('one_of_us_episode_1'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=fsBkgQeadWE',
        image_url: cdn('videos_thumbnails/Roots/The_Hokkiens.jpg'),
      },
      {
        id: 8,
        title: t('what_is_singlish'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=mlbw7Lj6vkg',
        image_url: cdn('videos_thumbnails/Roots/What_is_Singlish.jpg'),
      },
    ]);

    onMounted(() => {
      if (wordpressData.value.header_image && inner.value) {
        inner.value.style.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(${wordpressData.value.header_image})`;
      }
      if (getScreenSize() == 'mobile') {
        inner.value.style.backgroundPosition = 'center center';
        isMobileView.value = true;
      }
    });

    return {
      inner,
      topTextSection1,
      bottomTextSection1,
      imageSection1,
      topTextSection2,
      bottomTextSection2,
      imageSection2,
      topTextSection3,
      bottomTextSection3,
      imageSection3,
      textSubsection1,
      imageSubsection1,
      textSubsection2,
      imageSubsection2,
      textSubsection3,
      imageSubsection3,
      textSubsection4,
      imageSubsection4,
      page_url,
      booksData,
      videosData,
      imageSources,
      isMobileView,
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100svh;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .landscape-hidden {
    display: none;
  }
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=9);
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
