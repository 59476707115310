<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="true"
      :is-hidden="false"
      :page-url="page_url"
    />
    <!-- Header -->
    <div class="headerCustom">
      <div
        ref="inner"
        class="inner"
      />
      <!-- FOOD TEXT -->
      <div
        class="tw-absolute tw-flex tw-flex-col tw-justify-end tw-px-4 tw-pb-5 tw-inset-0 tw-max-h-[100vh] tw-max-w-[100vw]
        md:tw-px-10 md:tw-pr-0 md:tw-pb-0 md:tw-flex-row md:tw-justify-between
        lg:tw-pl-20"
      >
        <div class="tw-flex tw-flex-col tw-justify-end">
          <p
            class="tw-uppercase tw-text-white tw-text-sg-dp2-mobile
        md:tw-text-sg-dp2-tablet md:tw-pb-10
        lg:tw-text-sg-dp2"
          >
            {{ $t('food') }}
          </p>
        </div>
        <!-- <img
          class="landscape-hidden tw-hidden xl:tw-block"
          :src="$cdn('images/ren_vector.svg')"
          alt="ren vector"
        > -->
      </div>
    </div>
    <!-- Section 1 -->
    <ap-section-layout
      :section-text-one="topTextSection1"
      :section-text-two="bottomTextSection1"
      :section-image-url="imageSection1"
      :is-image-first="true"
    />
    <!-- Section 2 -->
    <ap-section-layout
      :section-text-one="topTextSection2"
      :section-text-two="bottomTextSection2"
      :section-image-url="imageSection2"
      :is-image-second="true"
    />
    <!-- SubSection 1 -->
    <ap-section-layout-2
      :section-text="textSubsection1"
      :image-url="imageSubsection1"
    />
    <!-- SubSection 2 -->
    <ap-section-layout-2
      :section-text="textSubsection2"
      :image-url="imageSubsection2"
      :is-image-on-left="false"
    />

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      v-if="!!imageSources"
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <!-- Books/Articles/Videos/Games Section -->
    <ap-swiper
      :swiper-type="'books'"
      :books-data="booksData"
      :description="$t('deep_dive_into_food')"
      :title="$t('swiper_title_books')"
    />
    <ap-swiper
      :swiper-type="'videos'"
      :videos-data="videosData"
      :description="$t('press_play_food')"
      :title="$t('swiper_title_videos')"
    />
    <ap-swiper
      :swiper-type="'games'"
      :games-data="gamesData"
      :description="$t('food_games_section_description')"
      :title="$t('swiper_title_games')"
    />
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script>
import { ref, toRefs, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { getScreenSize,checkLang } from '../support/helper';
import { cdn } from '../support/cdn';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const inner = ref(null);
    const _t = useI18n().t;

    const { wordpressData } = toRefs(props);
    const topTextSection1 = computed(() => wordpressData.value.top_text_section_1);
    const bottomTextSection1 = computed(() => wordpressData.value.bottom_text_section_1);
    const imageSection1 = computed(() => wordpressData.value.image_section_1);
    const topTextSection2 = computed(() => wordpressData.value.top_text_section_2);
    const bottomTextSection2 = computed(() => wordpressData.value.bottom_text_section_2);
    const imageSection2 = computed(() => wordpressData.value.image_section_2);
    const textSubsection1 = computed(() => wordpressData.value.text_subsection_1);
    const imageSubsection1 = computed(() => wordpressData.value.image_subsection_1);
    const textSubsection2 = computed(() => wordpressData.value.text_subsection_2);
    const imageSubsection2 = computed(() => wordpressData.value.image_subsection_2);
    const imageSources = computed(() => wordpressData.value.image_sources);
    const page_url = computed(() => wordpressData.value.page_url);
    //console.log('check wordpressData', wordpressData.value);
    const booksData = computed(()=>[
      {
        id: 1,
        title: _t('food_books_1_title'),
        year: '2015',
        author: _t('food_books_1_author'),
        publisher: _t('food_books_1_publisher'),
        external_url: `${checkLang() != 'zh-hans' ? 'https://catalogue.nlb.gov.sg/search/card?id=990dd899-78ea-5fae-b72b-8ba80c2f4856&entityType=FormatGroup' : 'https://catalogue.nlb.gov.sg/search/card?id=990dd899-78ea-5fae-b72b-8ba80c2f4856&entityType=FormatGroup'}`,
        image_url: '',
      },
      {
        id: 2,
        title: _t('food_books_2_title'),
        year: '2010',
        author: _t('food_books_2_author'),
        publisher:  _t('food_books_2_publisher'),
        external_url: `${checkLang() != 'zh-hans' ? 'https://catalogue.nlb.gov.sg/search/card?id=9744f552-a388-5aeb-ab75-54684e549a76&entityType=FormatGroup' : 'https://catalogue.nlb.gov.sg/search/card?id=9744f552-a388-5aeb-ab75-54684e549a76&entityType=FormatGroup'}` ,
        image_url: '',
      },
      {
        id: 3,
        title:  _t('food_books_3_title'),
        year: '2018',
        author:   _t('food_books_3_author'),
        publisher:  _t('food_books_3_publisher'),
        external_url: `${checkLang() != 'zh-hans' ? 'https://catalogue.nlb.gov.sg/search/card?id=16ce81da-e1c4-5c42-9066-6b81e0a4007a&entityType=FormatGroup' : 'https://catalogue.nlb.gov.sg/search/card?id=16ce81da-e1c4-5c42-9066-6b81e0a4007a&entityType=FormatGroup'}`,
        image_url: '',
      },
      {
        id: 4,
        title:  _t('food_books_4_title'),
        year: '2013',
        author: '-',
        publisher:  _t('food_books_4_publisher'),
        external_url: `${checkLang() != 'zh-hans' ? 'https://catalogue.nlb.gov.sg/search/card?id=a2c61899-cbf6-5d51-9f2c-3bc047aff33e&entityType=FormatGroup' : 'https://catalogue.nlb.gov.sg/search/card?id=a2c61899-cbf6-5d51-9f2c-3bc047aff33e&entityType=FormatGroup'}`,
        image_url: '',
      },
      {
        id: 5,
        title:  _t('food_books_5_title'),
        year: '2016',
        author:   _t('food_books_5_author'),
        publisher:  _t('food_books_5_publisher'),
        external_url: `${checkLang() != 'zh-hans' ? 'https://catalogue.nlb.gov.sg/search/card?id=c12289f4-519a-5ad3-8432-2341d6b49069&entityType=FormatGroup' : 'https://catalogue.nlb.gov.sg/search/card?id=c12289f4-519a-5ad3-8432-2341d6b49069&entityType=FormatGroup'}`,
        image_url: '',
      },
      // {
      //   id: 6,
      //   title:  _t('food_books_6_title'),
      //   year: '2016',
      //   author:   _t('food_books_6_author'),
      //   publisher:  _t('food_books_6_publisher'),
      //   external_url: 'https://sealion.iseas.edu.sg/client/en_SG/default/search/detailnonmodal/ent:$002f$002fSD_ILS$002f0$002fSD_ILS:298278/one',
      //   image_url: '',
      // },
      {
        id: 7,
        title:  _t('food_books_7_title'),
        year: '1989',
        author:   _t('food_books_7_author'),
        publisher:  _t('food_books_7_publisher'),
        external_url: `${checkLang() != 'zh-hans' ? 'https://catalogue.nlb.gov.sg/search/card?id=17aa11e1-41ab-56be-b633-0648f0e18d9d&entityType=FormatGroup' : 'https://catalogue.nlb.gov.sg/search/card?id=17aa11e1-41ab-56be-b633-0648f0e18d9d&entityType=FormatGroup'}`,
        image_url: '',
      },
      {
        id: 8,
        title:  _t('food_books_8_title'),
        year: '2015',
        author:   _t('food_books_8_author'),
        publisher: _t('food_books_8_publisher'),
        external_url: `${checkLang() != 'zh-hans' ? 'https://catalogue.nlb.gov.sg/search/card?id=2122ec5d-3b37-5271-92c1-0661a4eb4fb7&entityType=FormatGroup' : 'https://catalogue.nlb.gov.sg/search/card?id=2122ec5d-3b37-5271-92c1-0661a4eb4fb7&entityType=FormatGroup'}`,
        image_url: '',
      },
    ]);

    const videosData = computed(()=>[
      {
        id: 9,
        title: _t('food_videos_9'),
        description: '',
        video_url: 'https://youtu.be/XDMf1sLj1QI?feature=shared',
        image_url: cdn('videos_thumbnails/Food/bowl_soul_full_of_love.jpeg'),
      },
      {
        id: 10,
        title: _t('food_videos_10'),
        description: '',
        video_url: 'https://youtu.be/8BLSqOK0DCg?feature=shared',
        image_url: cdn('videos_thumbnails/Food/messy_plate_of_flavours.jpeg'),
      },
      {
        id: 11,
        title: _t('food_videos_11'),
        description: '',
        video_url: 'https://youtu.be/DntomqEsp8Q?feature=shared',
        image_url: cdn('videos_thumbnails/Food/no_tea_inside_this_dish.jpeg'),
      },
      {
        id: 12,
        title: _t('food_videos_12'),
        description: '',
        video_url: 'https://youtu.be/xOQqXNPt_k8?feature=shared',
        image_url: cdn('videos_thumbnails/Food/the_og_yong_tau_foo.jpeg'),
      },
      {
        id: 1,
        title: _t('food_videos_1'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=-eqqjD92_Hk',
        image_url: cdn('videos_thumbnails/Food/Chef.jpg'),
      },
      {
        id: 2,
        title: _t('food_videos_2'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=HkuTr3GZEcg',
        image_url: cdn('videos_thumbnails/Food/Nanyang_Tea.jpg'),
      },
      {
        id: 3,
        title: _t('food_videos_3'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=3yxIgLGF9RM',
        image_url: cdn('videos_thumbnails/Food/Nanyang_Coffee.jpg'),
      },
      {
        id: 4,
        title: _t('food_videos_4'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=6gzGMY1eyD8',
        image_url: cdn('videos_thumbnails/Food/Preserving_Peranakan_Recipes.jpg'),
      },
      {
        id: 5,
        title: _t('food_videos_5'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=yGMySXA4ZhE',
        image_url: cdn('videos_thumbnails/Food/Time_for_Tangyuan.jpg'),
      },
      {
        id: 6,
        title: _t('food_videos_6'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=tix-rditgQI',
        image_url: cdn('videos_thumbnails/Food/Making_Bak_Chang.jpg'),
      },
      {
        id: 7,
        title: _t('food_videos_7'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=BsUB65VLo9A',
        image_url: cdn('videos_thumbnails/Food/Reunion_Dinner_for_Ah_Ma.jpg'),
      },
      {
        id: 8,
        title: _t('food_videos_8'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=WKP99SxGpBw',
        image_url: cdn('videos_thumbnails/Food/Sweet_Reunions.jpg'),
      },

    ]);

    const gamesData = computed(()=>[
      {
        id: 1,
        title: _t('food_games_1'),
        image_url: cdn('games_thumbnails/hawker_chicken_rice.jpg'),
        external_url: `${checkLang() != 'zh-hans' ? `${process.env.APP_URL}/games/chicken-rice` : `${process.env.APP_URL}/games/chicken-rice?lang=zh-hans`}`,
      },
      {
        id: 2,
        title: _t('food_games_2'),
        image_url: cdn('games_thumbnails/hawker_toast_kopi.png'),
        external_url: `${checkLang() != 'zh-hans' ? `${process.env.APP_URL}/games/toast-kopi` : `${process.env.APP_URL}/games/toast-kopi?lang=zh-hans`}`,
      },
      {
        id: 3,
        title: _t('food_games_3'),
        image_url: cdn('games_thumbnails/hawker_bak_kut_teh.jpg'),
        external_url: `${checkLang() != 'zh-hans' ? `${process.env.APP_URL}/games/bah-kut-teh` : `${process.env.APP_URL}/games/bah-kut-teh?lang=zh-hans`}`,

      },
      {
        id: 4,
        title: _t('food_games_1'),
        image_url: cdn('games_thumbnails/hawker_chicken_rice.jpg'),
        external_url: `${checkLang() != 'zh-hans' ? `${process.env.APP_URL}/games/chicken-rice` : `${process.env.APP_URL}/games/chicken-rice?lang=zh-hans`}`,
      },
      {
        id: 5,
        title: _t('food_games_2'),
        image_url: cdn('games_thumbnails/hawker_toast_kopi.png'),
        external_url: `${checkLang() != 'zh-hans' ? `${process.env.APP_URL}/games/toast-kopi` : `${process.env.APP_URL}/games/toast-kopi?lang=zh-hans`}`,
      },
      {
        id: 6,
        title: _t('food_games_3'),
        image_url: cdn('games_thumbnails/hawker_bak_kut_teh.jpg'),
        external_url: `${checkLang() != 'zh-hans' ? `${process.env.APP_URL}/games/bah-kut-teh` : `${process.env.APP_URL}/games/bah-kut-teh?lang=zh-hans`}`,
      },
    ]);

    onMounted(() => {
      if (wordpressData.value.header_image && inner.value) {
        inner.value.style.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(${wordpressData.value.header_image})`;
        inner.value.style.backgroundPosition = 'center center';

      }

      // console.log('wordpressData', wordpressData.value);
      if (getScreenSize() == 'mobile') {
        inner.value.style.backgroundPosition = 'center center';
      }
    });

    return {
      inner,
      topTextSection1,
      bottomTextSection1,
      imageSection1,
      topTextSection2,
      bottomTextSection2,
      imageSection2,
      textSubsection1,
      imageSubsection1,
      textSubsection2,
      imageSubsection2,
      page_url,
      booksData,
      videosData,
      gamesData,
      imageSources,
      _t,
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100svh;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .landscape-hidden {
    display: none;
  }
}
.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=9); /*Default image*/
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
