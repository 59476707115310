<template>
  <div
    :class="[
      'tw-w-[43.5vw] sgTablet:tw-w-[240px] sgDesktop:tw-w-[260px] tw-flex tw-flex-col',
      {'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}
    ]"
  >
    <!-- Image Border Rounded  -->

    <div class="tw-relative">
      <img
        :src="event.image"
        alt="Event Image"
        :class="[
          'tw-h-full tw-w-full',
          'tw-rounded-t-[4px]',
          'tw-max-h-[92px]',
          'tw-object-cover',
          'tw-max-h-[92px] sgTablet:tw-max-h-[134px] sgDesktop:tw-max-h-[146px]',
          'tw-flex-shrink-0'
        ]"
      >
      <div
        v-if="event.status=='fully_booked' || event.status=='sold_out'"
        class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
      >
        <div
          v-if="isPastEvent(event.expired_datetime)"
          class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white"
        >
          {{ $t('ma_family_fun_past_event') }}
        </div>
      </div>
    </div>

    <div
      :class="[
        getEventStatusColor(),
        'tw-text-white tw-text-sg-body-3 tw-tracking-wider',
        'tw-px-4',
        'sgTablet:tw-py-[2px] sgDesktop:tw-py-1',
        'tw-text-center',
        'tw-w-full',
        'tw-h-6 sgTablet:tw-h-7 sgDesktop:tw-h-8',
        'tw-flex-shrink-0'
      ]"
    >
      {{ getEventStatusText() }}
    </div>
    <!-- Event Card Content -->
    <div
      class="tw-h-full tw-w-full tw-rounded-b-[4px] tw-bg-white hover:tw-inset-0 hover:tw-bg-gray-50 tw-flex-1"
      :style="{
        boxShadow:'0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A'
      }"
    >
      <div class="tw-px-2 sgTablet:tw-px-4 tw-pt-2 sgTablet:tw-pt-3">
        <!-- Event Type -->
        <p class="tw-h-6 tw-text-sg-body-3-mobile md:tw-text-sg-body-3-tablet lg:tw-text-sg-body-3 tw-pb-1 tw-normal-case">
          {{ getEventTypeText() }}
        </p>
        <!-- Event Title -->
        <h3
          :class="[
            'tw-line-clamp-3 sgTablet:tw-line-clamp-2',
            'tw-text-sg-ma-sh3',
            'tw-min-h-[60px] sgTablet:tw-min-h-[40px]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ event.title }}
        </h3>
        <!-- Event Date -->
        <div class="tw-pt-4 tw-flex tw-flex-col md:tw-flex-row tw-gap-2">
          <p class="tw-text-sg-caption">
            {{ $t('common.dates') }}
          </p>
          <span class="tw-block sgTablet:tw-inline tw-text-sg-caption">
            {{ event.date_str ?? 'Date not specified' }}
          </span>
        </div>
        <!-- Find Out More Text Button -->
        <div class="tw-py-2 sgTablet:tw-py-3 tw-flex tw-justify-end">
          <button
            :class="[
              'tw-flex tw-flex-row tw-align-middle tw-items-center ',
              'tw-py-1 tw-pr-2 tw-gap-2',
            ]"
            @click="$emit('openModal', event)"
          >
            <span class="tw-text-sg-button-2 tw-text-[#0366D8] hover:tw-underline">{{ $t('ma_family_fun_find_out_more') }}</span>
            <inline-svg
              class="tw-w-3 tw-h-3"
              fill="#0366D8"
              :src="$cdn('icons/ce/arrow_chevron_right_blue.svg')"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
const { t } = useI18n();

type MaFamilyFunProgrammeCategoryType = 'activities' | 'workshops' | 'performances' | 'online';
type MaFamilyFunProgrammeCategoryFilterType = 'all' | MaFamilyFunProgrammeCategoryType;
type MaFamilyFunProgrammeStatusType = 'free_event' | 'paid_event' | 'selling_fast' | 'sold_out' | 'fully_booked';
type MaFamilyFunProgrammePostType = {
  title:string,
  description:string,
  category: MaFamilyFunProgrammeCategoryType,
  dates:string[],
  date_str:string,
  time:string,
  venue:string,
  paid:boolean,
  status: MaFamilyFunProgrammeStatusType,
  image:string,
  external_link?:string,
  is_video_embed:boolean,
  video_link?:string,
  expired_datetime?:string,
}

defineEmits<{
  (e:'openModal',event:MaFamilyFunProgrammePostType):void
}>();

function getEventTypeText() {

  if(!props.event.category)
    return '';

  return t(`ma_family_fun_category_${props.event.category}`);
}

function getEventStatusColor() {
  if(!props.event.status)
    return 'tw-bg-[#E4100E]';

  const statusMap:{[key:string]:string} = {
    'free_event':'tw-bg-[#36A93F]',
    'fully_booked':'tw-bg-[#E4100E]',
    'paid_event':'tw-bg-[#0366D8]',
    'selling_fast':'tw-bg-[#F6610C]',
    'sold_out':'tw-bg-[#E4100E]',
  };

  return statusMap[props.event.status];
}

function getEventStatusText(): string {
  if(!props.event.status)
    return '';
  return t(`ma_family_fun_status_${props.event.status}`);
}

function isPastEvent(isoDate?: string){
  if(!isoDate) return false;
  const date = new Date(isoDate + '+08:00');
  const now = new Date();
  return date < now;
}

const props = defineProps<{
  event:MaFamilyFunProgrammePostType
}>();
</script>
