<template>
  <div
    class="tw-w-[100vw] tw-relative tw-overflow-visible"
    :style="{ height: swiperContainerHeight + 'px' }"
  >
    <swiper
      id="zy-home-swiper"
      :slides-per-view="'auto'"
      :center-insufficient-slides="true"
      :slides-offset-after="64"
      :slides-offset-before="64"
      :loop="true"
      :autoplay="{ delay: 0, disableOnInteraction: false }"
      :prevent-interaction-on-transition="true"
      :speed="5000"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in extendedData"
        :key="index"
        :style="{
          zIndex: item.z_index,
          transform: `translateY(${item.position_from_top}px)`,
          backgroundColor: item.overlay_color
        }"
        class="
            !tw-w-[284px]
            !tw-h-[292px]

            sgTablet:!tw-w-[300px]
            sgTablet:!tw-h-[331px]

            sgDesktop:!tw-w-[352px]
            sgDesktop:!tw-h-[352px]

            tw-relative
            tw-shadow-lg
            tw-rounded-sm
            tw-p-4
            tw-transform
            tw-transition-transform
            tw-duration-300
            tw-mx-[-6px]
          "
      >
        <!-- Background Image -->
        <img
          class="tw-absolute tw-w-full tw-object-cover tw-h-full tw-rounded-sm tw-top-0 tw-left-0"
          :src="$cdn('images/zy2024/home/tile_background.png')"
        >

        <!-- Content -->
        <div class="tw-flex tw-flex-col tw-h-full tw-relative">
          <img
            :src="$cdn(item.image)"
            class="tw-h-[240px] sgTablet:tw-h-[208px] sgDesktop:tw-h-[240px] tw-w-full tw-object-cover tw-object-center tw-rounded-sm"
          >
          <p
            class="
                tw-text-sg-caption-desktop-1
                tw-mt-4
                sgTablet:tw-mt-5
                tw-flex-grow
                tw-font-hanzipan
              "
          >
            {{ $t(item.description) }}
            <span v-if="item.reference">
              <sup
                class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body tw-font-akkurat"
                @click="handleOpenImageSourceModal"
              >{{ item.reference }}</sup>
            </span>
          </p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore from 'swiper';
import { ref, computed } from 'vue';
import 'swiper/swiper-bundle.css';
import { Autoplay, FreeMode } from 'swiper/modules';

SwiperCore.use([Autoplay, FreeMode]);
type Post = {
  year: string,
  image: any,
  description: string,
  reference: string,
  position_from_top: number,
  z_index: number,
  overlay_color: string
};

const props = defineProps<{
  data: Post[],
  imageSourceOpen: boolean,
}>();

const emit = defineEmits(['update:imageSourceOpen']);

const swiperContainerHeight = ref(453);

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

// Duplicate the slides to ensure sufficient number for loop mode
const extendedData = computed(() => {
  const minSlidesForLoop = 10; // Minimum number of slides required for loop
  let data = [...props.data];

  while (data.length < minSlidesForLoop) {
    data = data.concat(props.data);
  }

  return data;
});

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

</script>

<style scoped lang="scss">
.swiper {
  height: 453px !important;
}

.swiper-wrapper {
  height: 453px !important;
}

.swiper:deep(.swiper-wrapper){
  transition-timing-function: linear !important;
}
</style>
