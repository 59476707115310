<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar :is-transparent="false" :is-hidden="false" :cny-navigation="true" />

    <secondary-navigation :menu-items-data="menuItems" />

    <!-- Header -->
    <section>
      <div class="tw-relative">
        <picture>
          <source media="(max-width: 375px)" :srcset="$cdn('images/cny2024/banner_375.png')" />
          <source media="(max-width: 680px)" :srcset="$cdn('images/cny2024/banner_680.png')" />
          <source media="(max-width: 800px)" :srcset="$cdn('images/cny2024/banner_800.png')" />
          <source media="(max-width: 1025px)" :srcset="$cdn('images/cny2024/banner_1025.png')" />
          <img :src="$cdn('images/cny2024/banner_1440.png')" class="tw-w-full tw-object-cover" />
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny-title"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_visiting_101') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-mt-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_conversation_starters') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="cny-conversations-about" class="tw-max-w-[800px] tw-m-auto tw-pt-10 tw-px-4 sgTablet:tw-px-0">
      <!-- <p class="tw-text-sg-label-3">
        {{ $t('cny_conversation_starters_paragraph1') }}
      </p>
      <br>

      <div
        class="tw-text-sg-label-3"
        v-html="$t('cny_conversation_starters_paragraph2')"
      /> -->
      <div class="tw-text-sg-label-3" v-html="props.wordpressData.description" />
    </div>
    <cny-conversation-window :conversation-starters="props.wordpressData.conversation_starters" />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    wordpressData?: any | null;
  }>(),
  {
    wordpressData: null,
  }
);

const menuItems = [
  {
    label: 'cny_conversations_about',
    id: 'cny-conversations-about',
  },
  {
    label: 'cny_conversations',
    id: 'cny-conversations',
  },
];
</script>
