<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="true"
      :is-hidden="false"
      :page-url="page_url"
    />
    <!-- Header -->
    <div class="headerCustom">
      <div
        ref="inner"
        class="inner"
      />
      <!-- ARTS TEXT -->
      <div
        class="tw-absolute tw-flex tw-flex-col tw-justify-end tw-px-4 tw-pb-5 tw-inset-0 tw-max-h-[100vh] tw-max-w-[100vw]
        md:tw-px-10 md:tw-pr-0 md:tw-pb-0 md:tw-flex-row md:tw-justify-between
        lg:tw-pl-20"
      >
        <div class="tw-flex tw-flex-col tw-justify-end">
          <p
            class="tw-uppercase tw-text-white tw-text-sg-dp2-mobile
        md:tw-text-sg-dp2-tablet md:tw-pb-10
        lg:tw-text-sg-dp2"
          >
            {{ $t('arts') }}
          </p>
        </div>
        <!--  <img
          class="landscape-hidden tw-hidden xl:tw-block"
          :src="$cdn('images/ren_vector.svg')"
          alt="ren vector"
        > -->
      </div>
    </div>
    <!-- Section 1 -->
    <ap-section-layout
      :section-text-one="topTextSection1"
      :section-text-two="bottomTextSection1"
      :section-image-url="imageSection1"
      :is-first-image="true"
    />
    <!-- SubSection 1 -->
    <ap-section-layout-2
      :section-text="textSubsection1"
      :image-url="imageSubsection1"
    />

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      v-if="!!imageSources"
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <!-- Books/Articles/Videos/Games Section -->
    <ap-swiper
      :swiper-type="'songsAndPoems'"
      :songs-poems-data="songsPoemsData"
      :title="$t('swiper_title_songs_and_poems')"
      :description="$t('deep_dive_into_arts')"
    />
    <ap-swiper
      :swiper-type="'videos'"
      :videos-data="videosData"
      :title="$t('swiper_title_videos')"
      :description="$t('press_play_arts')"
    />
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script>
import { computed, onMounted, toRefs, ref } from 'vue';
import { getScreenSize } from '../support/helper';
import { useI18n } from 'vue-i18n';
import { cdn } from '../support/cdn';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const { t, locale } = useI18n();

    const inner = ref(null);

    const { wordpressData } = toRefs(props);
    const topTextSection1 = computed(() => wordpressData.value.top_text_section_1);
    const bottomTextSection1 = computed(() => wordpressData.value.bottom_text_section_1);
    const imageSection1 = computed(() => wordpressData.value.image_section_1);
    const textSubsection1 = computed(() => wordpressData.value.text_subsection_1);
    const imageSubsection1 = computed(() => wordpressData.value.image_subsection_1);
    const imageSources = computed(() => wordpressData.value.image_sources);
    const page_url = computed(() => wordpressData.value.page_url);

    const songsPoemsData = computed(()=>[
      {
        id: 1,
        title: t('arts_songs_and_poems_1'),
        external_url: process.env.APP_URL + '/song/liang-wern-fook',
      },
      {
        id: 2,
        title: t('arts_songs_and_poems_2'),
        external_url: process.env.APP_URL + '/poem/khoo-seok-wan',
      },
    ]);
    const videosData = computed(()=> [
      {
        id: 1,
        title: t('arts_videos_1'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=uSo5PT60smA',
        image_url: cdn('videos_thumbnails/Arts/Singapore_Chinese_Cultural_Contribution_Award_2021-The_Theatre_Practice.jpg'),
        // image_url: cdn('videos_thumbnails/Arts/Singapore_Chinese_Cultural_Contribution_Award_2021-The_Theatre_Practice.jpg'),
      },
      {
        id: 2,
        title: t('arts_videos_2'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=h6wRIVa3JRQ',
        image_url: cdn('videos_thumbnails/Arts/Singapore_Chinese_Cultural_ContributionAward_2021-Liang_Wern_Fook.jpg'),
      },
      {
        id: 3,
        title: t('arts_videos_3'),
        description: '',
        video_url: 'https://youtu.be/a8Zz_nx7IFI?feature=shared',
        image_url: cdn('videos_thumbnails/Arts/Graffiti_Artist.jpg'),
      },
      {
        id: 4,
        title: t('arts_videos_4'),
        description: '',
        video_url: 'https://youtu.be/ciyqOqtuv8U?feature=shared',
        image_url: cdn('videos_thumbnails/Arts/Author.jpg'),
      },
      {
        id: 5,
        title: t('arts_videos_5'),
        description: '',
        video_url: 'https://youtu.be/sq1XG6Cs2f8?feature=shared',
        image_url: cdn('videos_thumbnails/Arts/Getai_Singers.jpg'),
      },
      {
        id: 6,
        title: t('arts_videos_6'),
        description: '',
        video_url: 'https://youtu.be/K81Jty8rSBc?feature=shared',
        image_url: cdn('videos_thumbnails/Arts/Puppeteer.jpg'),
      },
      {
        id: 7,
        title: t('arts_videos_7'),
        description: '',
        video_url: 'https://youtu.be/kha08EX3-k4?feature=shared',
        image_url: cdn('videos_thumbnails/Arts/Music_Conductor.jpg'),
      },
      {
        id: 8,
        title: t('arts_videos_8'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=wM0Q5KVmjAM',
        image_url: cdn('videos_thumbnails/Arts/The_New_Wave.jpg'),
      },
      {
        id: 9,
        title: t('arts_videos_9'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=LzHV4OQoky4',
        image_url: cdn('videos_thumbnails/Arts/Game_Changers.jpg'),
      },
      {
        id: 10,
        title: t('arts_videos_10'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=lF1mgUMnvGU',
        image_url: cdn('videos_thumbnails/Arts/The_Keepers.jpg'),
      },
      {
        id: 11,
        title: t('arts_videos_11'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=yOiNsnqbtCk',
        image_url: cdn('videos_thumbnails/Arts/OGS_Last_Teochew_Puppeteers.jpg'),
      },
      {
        id: 12,
        title: t('arts_videos_12'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=MwSNUwZ5g_s',
        image_url: cdn('videos_thumbnails/Arts/Being_a_Full-Time_Comic_Artist_in_Singapore.jpg'),
      },
      {
        id: 13,
        title: t('arts_videos_13'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=vJ3er_oQQkg',
        image_url: cdn('videos_thumbnails/Arts/Bead_by_Bead-The_Art_of_Crafting_Peranakan_Beaded_Shoes.jpg'),
      },
      {
        id: 14,
        title: t('arts_videos_14'),
        description: '',
        video_url: 'https://youtu.be/-eqqjD92_Hk?feature=shared',
        image_url: cdn('videos_thumbnails/Arts/Chef.jpg'),
      },
    ]);

    onMounted(() => {
      if (wordpressData.value.header_image && inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(${wordpressData.value.header_image})`;
      }
      if (getScreenSize() == 'mobile') {
        inner.value.style.backgroundPosition = 'center center';
      }
    });

    return {
      inner,
      topTextSection1,
      bottomTextSection1,
      imageSection1,
      textSubsection1,
      imageSubsection1,
      page_url,
      songsPoemsData,
      videosData,
      imageSources
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100svh;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .landscape-hidden {
    display: none;
  }
}
.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=2); /*Default image*/
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
