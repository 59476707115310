<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-navigation="true"
    />

    <secondary-navigation :menu-items-data="menuItems" />
    <!-- Header -->
    <div class="header">
      <img
        class="header-image"
        :src="$cdn('images/cny2024/landing.svg')"
      >
      <div
        class="header-content tw-w-full tw-h-screen tw-flex tw-flex-wrap tw-flex-col tw-justify-middle tw-content-center tw-pt-[140px] tw-px-4 tablet:tw-pt-40 tablet:tw-px-10 desktop:tw-pt-[180px]"
      >
        <img
          class="tw-pb-[10px] tw-h-[80px]"
          :src="$cdn('images/cny2024/singaporen-festival.svg')"
        >
        <p
          class="tw-text-white tw-text-sg-h1-mobile-qm tablet:tw-text-sg-h1-tablet-qm desktop:tw-text-sg-h1-qm cny-text-shadow"
          :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
        >
          {{ $t('chinese_new_year') }}
        </p>
      </div>
    </div>
    <!-- The content below is hidden because of absolute items above -->
    <!-- Content -->
    <div class="tw-flex tw-flex-col tw-min-h-screen tw-w-full">
      <!-- Calendar -->
      <cny-24-calendar :data="$props.wordpressData?.cny24Calendar" />
      <!-- Red Packet Collection Swiper -->
      <cny-highlights
        v-if="highlights.length>0"
        :data="highlights"
      />
      <!-- CNY Section With Button -->
      <cny-24-section-layout
        :title="section1Title"
        :description="section1Description"
        :image-asset="$cdn('images/cny2024/origin-stories.svg')"
        custom-id="origin-stories"
        external="internal"
        :external-link="$langRedirect('/festivals/chinese-new-year/origin-stories')"
      />
      <cny-24-section-layout
        :is-image-left="true"
        :title="section2Title"
        :description="section2Description"
        :image-asset="$cdn('images/cny2024/section2.svg')"
        custom-id="cny-the-sgren-way"
        external="internal"
        :external-link="$langRedirect('/festivals/chinese-new-year/about')"
      />
      <cny-24-section-layout
        :title="section3Title"
        :description="section3Description"
        :image-asset="$cdn('images/cny2024/landing.svg')"
        custom-id="family-fun"
        external="internal"
        :external-link="$langRedirect('/festivals/chinese-new-year/familyfun')"
      />

      <section
        id="cny-sg-way"
        class=""
      >
        <div class="tw-flex tw-space-x-4 tw-items-center tw-pt-5 tablet:tw-pt-10 tw-px-4 tablet:tw-px-8 desktop:tw-px-20">
          <div>
            <img :src="$cdn('images/cny2024/selfie.svg')">
          </div>
          <div>
            <div
              class="tw-text-sg-sh1-mobile-qm tablet:tw-text-sg-sh1-tablet-qm desktop:tw-text-sg-sh1-qm"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
            >
              {{ $t('cny_#cnysgway') }}
            </div>
            <div class="tw-mt-4 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">
              {{ $t('cny_share_moment') }}
            </div>
          </div>
        </div>

        <!-- <div
          class="tw-mt-8 tablet:tw-mt-10 tw-mb-5 tablet:tw-mb-10 tw-flex tw-overflow-x-scroll tw-w-full tw-gap-x-4 tablet:tw-gap-x-5 scrollbar-hide"
        >
          <div
            v-for="(i, index) in social_media_images"
            :key="index"
            class="tw-flex tw-flex-col first:tw-ml-4 sgTablet:first:tw-ml-10 sgDesktop:first:tw-ml-20 last:tw-mr-4 sgTablet:last:tw-mr-10 sgDesktop:last:tw-mr-20 tablet:tw-min-w-[260px] tw-min-w-[168px] "
          >
            <img
              :key="index+'i'"
              :src="i.image"
              class="tw-h-[168px] tablet:tw-h-[260px] tw-w-[168px] tablet:tw-w-[260px] tw-object-cover"
            >
            <a
              v-if="!!i?.username && i?.username_link"
              :key="index+'a'"
              class="tw-mt-2 tablet:tw-max-w-[260px]  tw-w-fit tw-line-clamp-1 tw-max-w-[168px] tw-text-sg-body-mobile tablet:tw-text-sg-body-tablet desktop:tw-text-sg-body"
              :class="{'tw-cursor-pointer hover:tw-underline': !!i?.username_link}"
              :href="i?.username_link"
              target="_blank"
              rel="noopener"
            >
              {{ `@${i?.username}` }}
            </a>
            <p
              v-else-if="!!i?.username"
              class="tw-mt-2 tablet:tw-max-w-[260px]  tw-w-fit tw-line-clamp-1 tw-max-w-[168px] tw-text-sg-body-mobile tablet:tw-text-sg-body-tablet desktop:tw-text-sg-body"
            >
              {{ `@${i?.username}` }}
            </p>
          </div>
        </div> -->
        <cny-24-ugc-swiper
          :social_media_images="social_media_images"
        />
      </section>

      <cny-sticker-section :data="downloadables" />
      <ap-footer />
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, Ref, ref, toRefs, computed,onBeforeMount} from 'vue';
import { checkLang } from '../../support/helper';
import { useI18n } from 'vue-i18n';
import { cdn } from '../../support/cdn';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { wordpressData } = toRefs(props);
    const inner: Ref<HTMLElement | null> = ref(null);
    const social_media_images = ref([]);
    const { t } = useI18n();
    const UGCSectionRef = ref(null);

    const downloadables = ref({});
    const highlights = ref([]);

    onBeforeMount(()=>{
      if (wordpressData.value.social_media_images) {
        social_media_images.value = wordpressData.value.social_media_images;
      }
      if(wordpressData.value.downloadables){
        downloadables.value = wordpressData.value.downloadables;
      }
      if(wordpressData.value.carousel){
        highlights.value = wordpressData.value.carousel;
      }
    });

    onMounted(() => {
      if (inner.value && wordpressData.value.header_image) {
        inner.value.style.backgroundImage = `url(${wordpressData.value.header_image})`;
      }

    });

    const onScrollUGCSection = (event: WheelEvent) => {
      console.log('im here');
      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        console.log('scroll');
        if(!!UGCSectionRef.value)
        {
          UGCSectionRef.value.scrollContainer.scrollLeft += event.deltaX;
          console.log('scroll event', event.deltaX);
        }
      }
    };

    const section1Title = computed(() => t('cny_origin_stories'));
    const section1Description = computed(() => t('cny_origin_stories_desc'));
    const section2Title = computed(() => t('cny_singaporen_way'));
    const section2Description = computed(() => t('cny_singaporen_way_desc'));
    const section3Title = computed(() => t('cny_fest_family_fun'));
    const section3Description = computed(() => t('cny_fest_family_fun_desc'));

    const menuItems = [
      {
        label: 'cny_calendar',
        id: 'cny24-calendar',
      },
      {
        label: 'cny_highlights',
        id: 'highlights',
      },
      {
        label: 'cny_origin_stories',
        id: 'origin-stories',
      },
      {
        label: 'cny_the_sgren_way',
        id: 'cny-the-sgren-way',
      },
      {
        label: 'cny_family_fun',
        id: 'family-fun',
      },
      {
        label: 'cny_#cnysgway',
        id: 'cny-sg-way',
      },
    ];

    return {
      inner,
      social_media_images,

      section1Title,
      section1Description,
      section2Title,
      section2Description,
      section3Title,
      section3Description,
      menuItems,
      onScrollUGCSection,
      downloadables,
      highlights,
      checkLang
    };
  },
  data() {


    return {

    };
  },
};
</script>

<style scoped>
.header {
  position: relative;
  height: 93vh;
}
.header-image {
  width: 100%;
  height: 93vh;
  object-fit: cover;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.cny-text-shadow {
  text-shadow: -4px 4px 10px rgba(0, 0, 0, 0.30);
}
</style>
