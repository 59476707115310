<template>
  <div
    :id="divId"
    ref="containerDiv"
    class="headerCustom"
  >
    <!-- Text -->
    <div
      class="tw-w-full tw-h-fit tw-overflow-hidden tw-bg-fixed tw-flex tw-flex-row"
      :class="[isTextLeft ? 'tw-justify-start' : 'tw-justify-end', randomIdTextOne]"
    >
      <div
        ref="textHeight"
        class="textContainer tw-flex tw-px-4 tw-pt-5
        tw-flex-wrap
        tw-flex-col
        tablet:tw-px-10 tablet:tw-py-10 tablet:tw-w-[60%]
        desktop:tw-px-20 desktop:tw-py-10 desktop:tw-w-[60%] tw-h-fit"
      >
        <h2
          :class="[
            'text-shadow tw-h-fit tw-pb-5 sgTablet:tw-pb-6',
            'tw-text-white tw-font-akkuratbold',
            'tw-text-sg-remix-tablet-h4 desktop:tw-text-sg-remix-desktop-h4'
          ]"
        >
          {{ title }}
        </h2>
        <p
          :class="[
            'text-shadow tw-text-white tw-text-sg-remix-body tw-h-fit',
            {'tw-break-keep':checkLang()=='en'}
          ]"
        >{{ text }}
        </p>
      </div>


    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, Ref, ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getScreenSize,detectBrowser } from '../../../support/helper';
import { checkLang } from '../../../support/helper';
export default {
  props: {
    title: {
      type: String,
      default: 'Section Text'
    },
    text: {
      type: String,
      default: 'Section Text'
    },
    isTextLeft: {
      type: Boolean,
      default: true
    },
    divId: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    let containerDiv: Ref<HTMLElement | null> = ref(null);
    const randomIdTextOne: Ref<string> = ref('');
    const randomIdDiv: Ref<string> = ref('');

    function generateRandomId() {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      randomIdTextOne.value = 'one' + result;
      // randomIdTextTwo.value = 'two' + result;
      randomIdDiv.value = 'div' + result;
    }

    onBeforeMount(() => {
      generateRandomId();
    });

    onMounted(async () => {
      gsap.registerPlugin(ScrollTrigger);
      setTimeout(handleResize, 300);
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    async function handleResize() {
      const scrollId = ScrollTrigger.getById(randomIdTextOne.value);
      if(scrollId) {
        scrollId.kill();
      }

      const elem = document.querySelector( '#' + props.divId + ' .textContainer') as HTMLDivElement;

      // gsap controller
      gsap.to('.' + randomIdTextOne.value, {
        id: randomIdTextOne.value,
        scrollTrigger: {
          id: randomIdTextOne.value,
          trigger: '#' + props.divId,
          start: 'top 30%',
          end:`bottom 30%+=${elem?.offsetHeight + 40}px`,
          toggleActions: 'restart none none none',
          markers: false,
          pin: true,
          pinSpacing: false,
          invalidateOnRefresh: true,
          preventOverlaps: true,
          fastScrollEnd: true,
        },
      });
    }
    return {
      randomIdTextOne,
      containerDiv,
      randomIdDiv,
      checkLang
    };
  }
};
</script>

  <style scoped>
  .headerCustom {
      position: relative;
      width: 100%;
      height: 100vh;
      overflow: hidden;
  }

  p.text-shadow {
    text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
  }

  span.text-shadow {
    text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
  }
  </style>
