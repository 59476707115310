<template>
  <div
    ref="containerDiv"
    :class="[randomContainerId]"
    class="tw-flex tw-flex-col tablet:tw-flex-row desktop:tw-flex-row tw-justify-between"
  >
    <div
      :class="['tw-max-h-[100%] tablet:tw-w-[60%] desktop:tw-w-[60%]',
               isImageOnLeft ? 'tablet:tw-order-1 desktop:tw-order-1' : 'tablet:tw-order-2 desktop:tw-order-2'
      ]"
    >
      <img
        :class="['tw-w-full tw-h-full tw-object-cover']"
        :src="imageUrl"
        alt="Image URL"
      >
    </div>
    <div
      :class="['tw-relative tablet:tw-max-w-[40%] desktop:tw-max-w-[40%] tw-py-4 tablet:tw-py-5 desktop:tw-py-10',
               isImageOnLeft ? 'tw-px-4 tablet:tw-pl-5 tablet:tw-pr-10 desktop:tw-pl-10 desktop:tw-pr-20'
               : 'tw-px-4 tablet:tw-pr-5 tablet:tw-pl-10 desktop:tw-pr-10 desktop:tw-pl-20',
               isImageOnLeft ? 'tablet:tw-order-2 desktop:tw-order-2' : 'tablet:tw-order-1 desktop:tw-order-1'
      ]"
    >
      <p
        :class="[randomId, 'tw-sticky tw-top-[35%] tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1',{
          'tw-break-keep':checkLang()=='en'
        }]"
        v-html="sectionText"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, onBeforeMount, watch, Ref } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onBeforeUnmount } from 'vue';
import { checkLang } from '../../support/helper';
export default {
  props: {
    sectionText: {
      type: String,
      default: 'Your section text'
    },
    isImageOnLeft: {
      type: Boolean,
      default: true,
    },
    imageUrl: {
      type: String,
      default: 'https://picsum.photos/1200?random=10',
    },
  },
  setup() {
    const randomId = ref('');
    const randomContainerId = ref('');
    // let innerWidth = ref(window.innerWidth);
    let containerDiv: Ref<HTMLElement | null> = ref(null);

    // Function to generate a random ID
    function generateRandomId() {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      const charactersLength = characters.length;

      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      randomId.value = result;
      randomContainerId.value = 'container' + result;
    }

    function  handleResize() {
      const scrollIdOne = ScrollTrigger.getById(randomId.value);
      if(scrollIdOne) {
        scrollIdOne.kill();
      }
      let divWidth = 0;

      if (containerDiv.value) {
        divWidth = containerDiv.value.clientWidth;
      }

      // Mobile View no scrolling
      if (divWidth <= 680) {
        return;
      }

      gsap.to('.' + randomId.value, {
        scrollTrigger: {
          id: randomId.value,
          trigger: '.' + randomId.value,
          endTrigger: '.' + randomContainerId.value,
          start: 'top 30%',
          end: 'bottom 33%+=' + (document.querySelector('.' + randomId.value) as HTMLElement).offsetHeight + 'px',
          toggleActions: 'restart none none none',
          markers: false,
          // {
          //   startColor: 'green',
          //   endColor: 'red',
          //   fontSize: '12px',
          //   indent: 20,
          // },
          pin: true,
          pinSpacing: false,
          invalidateOnRefresh: true,
          preventOverlaps: true,
          fastScrollEnd: false,
        }
      });
    }

    onBeforeMount(() => {
      generateRandomId();
    });

    onMounted(() => {
      // gsap.registerPlugin(ScrollTrigger);
      // setTimeout(handleResize,300);
      // window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      // window.removeEventListener('resize', handleResize);
    });

    return {
      randomId,
      randomContainerId,
      containerDiv,
      checkLang
    };
  }
};
</script>
