<template>
  <div class="tw-flex tw-w-full tw-h-full tw-bg-[#FAE97D] tw-justify-center tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-0">
    <div class="tw-max-w-[800px] tw-flex tw-flex-col tw-w-full tw-items-center">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-max-w-[800px] sgTablet:tw-px-0 sgTablet:tw-items-start"
        :class="[
          isBuildLeft ? 'sgTablet:tw-flex-row' : 'sgTablet:tw-flex-row-reverse',
          isFirst
            ? 'tw-pt-10 tw-pb-5 sgTablet:tw-pb-10 sgDesktop:tw-pb-[60px]'
            : isLast
            ? 'tw-pt-5 tw-pb-10 sgTablet:tw-pt-10 sgDesktop:tw-pt-[60px] sgTablet:tw-pb-10 sgDesktop:tw-pb-[40px]'
            : 'tw-pt-5 tw-pb-5 sgTablet:tw-pt-10 sgDesktop:tw-pt-[60px] sgTablet:tw-pb-10 sgDesktop:tw-pb-[60px]',
        ]"
      >
        <img :src="$cdn(snack.image)" class="tw-w-[200px] tw-h-[200px]" />
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full sgTablet:tw-justify-start sgTablet:tw-items-start"
          :class="[isBuildLeft ? 'sgTablet:tw-pl-10' : 'sgTablet:tw-pr-10']"
        >
          <p
            class="tw-text-[#C52600] tw-text-sg-h4-mobile-cny tw-pt-8 tw-pb-3 sgTablet:tw-text-sg-h4-tablet-cny sgTablet:tw-pt-0 sgDesktop:tw-pb-4 sgDesktop:tw-text-sg-h4-cny"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ snack.title }}
          </p>
          <p
            class="tw-text-[#C52600] tw-text-sg-sh2-mobile-cny tw-pb-6 sgTablet:tw-pb-8 sgTablet:tw-text-sg-sh2-tablet-cny sgDesktop:tw-pb-10 sgDesktop:tw-text-sg-sh2-cny"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ snack.subtitle }}
          </p>
          <div class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1" v-html="formattedText(snack.description)" />
        </div>
      </div>
      <hr v-if="!isLast" class="horizontal-line" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { checkLang } from '../../../support/helper';
interface Snack {
  title: string;
  description: string;
  subtitle: string;
  image: string;
}

export default {
  props: {
    snack: {
      type: Object as PropType<Snack>,
      required: true,
    },
    isBuildLeft: {
      type: Boolean,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    function formattedText(text: string): string {
      const paragraphs = text.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-[#484848] tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-max-w-[560px]">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-[#484848] tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6 tw-max-w-[560px]">${paragraph}</p>`;
          }
        })
        .join('');
    }
    return {
      formattedText,
      checkLang,
    };
  },
};
</script>

<style scoped>
.horizontal-line {
  width: 100%;
  max-width: 800px;
  border-top: 2px solid #c4a900;
}
</style>
