<script lang="ts" setup>
import { onBeforeMount, onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();
type Post={
  title:string,
  description:string,
  image:string,
  external_link:string,
  is_past?: boolean
}

const event_post = ref<Post[]>([]);
const other_programme_post = ref<Post[]>([]);
const event_description = ref('');
const other_programme_description = ref('');
const online_programme_description = ref('');

const modalKey = ref(Date.now());

onBeforeMount(() => {
  if(props.wordpressData?.event_post){
    event_post.value = props.wordpressData.event_post.map((item:any)=>{
      return {
        ...item,
        image:item.image ? item.image : cdn('images/dw2024/whats-open/card_placeholder.png'),
      };
    });
  }
  if(props.wordpressData?.other_programme_post){
    other_programme_post.value = props.wordpressData.other_programme_post.map((item:any)=>{
      return {
        ...item,
        image:item.image ? item.image : cdn('images/dw2024/whats-open/card_placeholder.png'),
      };
    });
  }
  if(props.wordpressData?.event_description){
    event_description.value = props.wordpressData.event_description;
  }
  if(props.wordpressData?.other_programme_description){
    other_programme_description.value = props.wordpressData.other_programme_description;
  }
});

const modal = ref<{show:boolean,data:Post}>({
  show:false,
  data:{} as Post
});
const handleOpenModal = (item:Post)=>{
  modal.value.show = true;
  modal.value.data = item;
  modalKey.value = Date.now();
};

const menuItems = [
  {
    label: 'dw_whats_open_menu_events',
    id: 'events-post-section',
  },
  {
    label: 'dw_whats_open_menu_other_programmes',
    id: 'other-exciting-programmes-post-section',
  },
];


</script>

<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :dw-navigation="true"
    />

    <dw-secondary-navigation
      :menu-items-data="menuItems"
    />


    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/dw2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div :class="checkLang() == 'zh-hans'? 'tw-text-sg-h1-mobile-dw-about-title-zh sgTablet:tw-text-sg-h1-tablet-dw-about-title-zh sgDesktop:tw-text-sg-h1-dw-about-title-zh tw-pb-1 tw-font-notosansbold': 'tw-text-sg-h1-mobile-dw-about-title sgTablet:tw-text-sg-h1-tablet-dw-about-title sgDesktop:tw-text-sg-h1-dw-about-title tw-pb-1  tw-font-akkuratbold'">
            {{ $t('dw_whats_open_title') }}
          </div>
          <div
            class="tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-mt-1"
          >
            {{ $t('dw_whats_open_subtitle') }}
          </div>
        </div>
      </div>
    </div>

    <section class="tw-relative tw-bg-sg-dw24-dw1-w60 tw-px-4">


      <div class="tw-w-full tw-absolute tw-top-0 tw-left-0">
        <div class="tw-max-w-[400px]  sgTablet2:tw-max-w-[1144px] tw-w-full tw-mx-auto  tw-flex tw-justify-between">
          <div>
            <img
              class="tw-w-[40px] sgTablet:tw-w-[120px] sgDesktop:tw-w-[160px] tw-absolute tw-left-4 sgTablet:tw-left-10 sgDesktop:tw-left-20 sgDesktop2:tw-relative sgDesktop2:tw-left-0 "
              :src="$cdn('images/dw2024/whats-open/events_left.png')"
            >
          </div>
          <div>
            <img
              class="tw-w-[40px] sgTablet:tw-w-[120px] sgDesktop:tw-w-[160px] tw-absolute tw-right-4 sgTablet:tw-right-10 sgDesktop:tw-right-20 sgDesktop2:tw-relative sgDesktop2:tw-right-0 "
              :src="$cdn('images/dw2024/whats-open/events_right.png')"
            >
          </div>
        </div>
      </div>
      <div
        id="events-post-section"
        class="tw-w-[calc(100vw-32px-80px)] sgTablet:tw-w-[calc(100vw-80px-240px)] sgDesktop:tw-w-[calc(100vw-160px-320px)] sgDesktop2:tw-w-[800px] tw-mx-auto tw-text-center
      tw-pt-5 sgTablet:tw-pt-10"
      >
        <h1 :class="checkLang() == 'zh-hans'? 'tw-text-sg-h1-mobile-dw-title-zh sgTablet:tw-text-sg-h1-tablet-dw-title-zh sgDesktop:tw-text-sg-h1-dw-title-zh tw-pb-1 tw-font-notosansbold': 'tw-text-sg-h1-mobile-dw-titlemobile sgTablet:tw-text-sg-h1-tablet-dw-title sgDesktop:tw-text-sg-h1-dw-title tw-pb-1 tw-font-akkuratbold'">
          {{ $t('dw_whats_open_events_title') }}
        </h1>
        <p
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1
        tw-mt-4 sgTablet:tw-mt-5 tw-break-words"
          v-html="event_description"
        />
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto
      tw-mt-6 sgTablet:tw-mt-10
      tw-pb-[60px] sgTablet:tw-pb-[100px]
      tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item,index) in event_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-sg-dw24-dw1-w20 tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow:'0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A'
          }"
        >
          <div class="tw-relative">
            <img
              :src="item.image"
              class="tw-w-full tw-h-[90px] sgTablet:tw-h-[141.75px] tw-object-center tw-object-cover"
            >
            <div
              v-if="item.is_past"
              class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
            >
              <div class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white">
                {{ $t('dw_whats_open_past_event') }}
              </div>
            </div>
          </div>
          <div class="tw-my-5 tw-mx-4 tw-flex-1 tw-flex tw-flex-col">

            <div
              class="tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-mt-2 tw-flex-1"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div
              class="tw-flex tw-justify-end tw-text-sg-button-2  tw-mt-4 tw-cursor-pointer tw-group"
              @click="handleOpenModal(item)"
            >
              <div class="tw-flex tw-gap-x-2 tw-items-center">
                <span class="group-hover:tw-underline">{{ $t('dw_whats_open_find_out_more') }}</span><inline-svg :src="$cdn('images/dw2024/whats-open/arrow_forward_ios.svg')" />
              </div>

            </div>
          </div>

        </div>
      </div>


    </section>

    <section class="tw-relative tw-bg-sg-dw24-blue-w60 tw-px-4">
      <img
        class=" tw-absolute tw-left-0 tw-top-[-60px] sgDesktop:tw-top-[-100px] tw-w-[120px] sgDesktop:tw-w-[200px]"
        :src="$cdn('images/dw2024/whats-open/others_left.png')"
      >

      <img
        class="tw-absolute tw-right-0 tw-top-[-60px] sgDesktop:tw-top-[-100px] tw-w-[120px] sgDesktop:tw-w-[200px]"
        :src="$cdn('images/dw2024/whats-open/others_right.png')"
      >

      <div
        id="other-exciting-programmes-post-section"
        class="tw-max-w-[340px] sgTablet2:tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-center
        tw-pt-[60px] sgDesktop:tw-pt-[100px]"
      >
        <h1 :class="checkLang() == 'zh-hans'? 'tw-text-sg-h1-mobile-dw-title-zh sgTablet:tw-text-sg-h1-tablet-dw-title-zh sgDesktop:tw-text-sg-h1-dw-title-zh tw-font-notosansbold': 'tw-text-sg-h1-mobile-dw-titlemobile sgTablet:tw-text-sg-h1-tablet-dw-title sgDesktop:tw-text-sg-h1-dw-title tw-font-akkuratbold'">
          {{ $t('dw_whats_open_other_programmes_title') }}
        </h1>
        <p
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1
  tw-mt-7 tw-break-words"


          v-html="other_programme_description"
        />
      </div>
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto
tw-mt-6 sgTablet:tw-mt-10
tw-pb-10 sgTablet:tw-pb-20
tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item,index) in other_programme_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-sg-dw24-blue-w40 tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow:'0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A'
          }"
        >
          <div class="tw-relative">
            <img
              :src="item.image"
              class="tw-w-full tw-h-[90px] sgTablet:tw-h-[141.75px] tw-object-center tw-object-cover"
            >
            <div
              v-if="item.is_past"
              class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
            >
              <div class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white">
                {{ $t('dw_whats_open_past_event') }}
              </div>
            </div>
          </div>

          <div class="tw-my-5 tw-mx-4 tw-flex-1 tw-flex tw-flex-col">

            <div
              class="tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-mt-2 tw-flex-1"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div
              class="tw-flex tw-justify-end tw-text-sg-button-2  tw-mt-4 tw-cursor-pointer tw-group"
              @click="handleOpenModal(item)"
            >
              <div class="tw-flex tw-gap-x-2 tw-items-center">
                <span class="group-hover:tw-underline">{{ $t('dw_whats_open_find_out_more') }}</span><inline-svg :src="$cdn('images/dw2024/whats-open/arrow_forward_ios.svg')" />
              </div>

            </div>
          </div>

        </div>
      </div>


    </section>


    <dw-whats-open-modal
      :key="modalKey"
      v-model="modal.show"
      :data="modal.data"
    />
    <ap-footer />
  </div>
</template>
