<template>
  <div
    class="tw-align-middle"
    :style="{height: lottieContainerHeight + 'vh',
             width: '100vw',
             transition: 'transform 1.5s ease-out',
             overflow: 'hidden',
             position: 'fixed',
             transform: `translateY(${translateY})`,
    }"
  >
    <Vue3Lottie
      :animation-link="$cdn('lottiefiles/singaporen_loading.json')"
      :loop="1"
      :auto-play="true"
      @on-complete="handleCompleted"
    />
  </div>
</template>

<script lang="ts">
import { Vue3Lottie } from 'vue3-lottie';
import { ref, onMounted, Ref } from 'vue';

export default {
  components: {
    Vue3Lottie
  },
  emits: ['lottieFinishLoad'],
  setup(props, context) {
    const lottieContainerHeight = ref(100);
    const translateY: Ref<string> = ref('0');
    const handleCompleted = () => {
      // Make the lottie, invisible by going downward into a void
      translateY.value = '100%';
      setTimeout(() => {
        lottieContainerHeight.value = 0;
        // Re-enable scrolling when component is unmounted
        context.emit('lottieFinishLoad', true);
      }, 1500);
      document.body.style.overflow = 'visible';
    };

    onMounted(() => {
      document.body.style.overflow = 'hidden';
    });

    return {
      handleCompleted,
      lottieContainerHeight,
      translateY,
    };
  }
};
</script>
