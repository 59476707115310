<template>
  <div
    class="tw-w-full tw-relative tw-pb-[60px]"
  >
    <swiper
      id="qm-home-swiper"
      :slides-per-view="'auto'"
      :space-between="0"
      :slides-offset-before="0"
      :slides-offset-after="0"
      centered-slides
      centered-slides-bounds
      center-insufficient-slides
      :breakpoints="{
        680: {
          slidesOffsetBefore: 28,
          slidesOffsetAfter: 28,
          slidesPerView: 'auto',
          spaceBetween: 0,
          centeredSlides: false,
          centeredSlidesBounds: false,
        },
        1025: {
          slidesOffsetBefore: 64,
          slidesOffsetAfter: 64,
          slidesPerView: 'auto',
          spaceBetween: 0,
          centeredSlides: false,
          centeredSlidesBounds: false,
        },
      }"
      @swiper="onSwiper"
      @resize="checkSlidePosition"
      @slide-change="checkSlidePosition"
      @touch-end="checkSlidePosition"
    >
      <swiper-slide
        v-for="(item, index) in data"
        :key="index"
        class="!tw-w-[320px] sgTablet:!tw-w-[420px] !tw-h-auto sgTablet:!tw-h-[600px] tw-px-[30px] sgTablet:tw-px-[60px] tw-relative"
      >
        <div
          class="tw-text-sg-h3-qm-mobile-timeline sgTablet:tw-text-sg-h3-qm-tablet-timeline sgDesktop:tw-text-sg-h3-qm-timeline tw-flex tw-justify-center tw-text-sg-qm24-green-b20 "
        >
          <div class="tw-font-akkuratbold !tw-font-normal">
            {{ item.year }}
          </div>
        </div>
        <div class="tw-py-3 tw-my-2 tw-flex tw-justify-center tw-relative">
          <div
            class="tw-absolute tw-border-[1px] tw-border-sg-qm24-green-w40 tw-w-[320px] sgTablet:tw-w-[420px] tw-rounded-[1px] tw-top-[50%] tw-left-[50%] tw-z-0"
          />
          <span class="tw-w-5 tw-h-5 tw-rounded-full tw-bg-sg-qm24-green-b20 tw-z-10" />
        </div>
        <img
          :src="item.image"
          class="tw-h-[260px] sgDesktop:tw-h-[300px] tw-w-full tw-object-cover tw-object-center tw-rounded-lg"
        >
        <p
          class="tw-text-sg-h3-qm-desc-timeline tw-mt-2"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosans ' : 'tw-font-akkurat'"
        >
          {{ item.description }}
          <span>
            <sup
              class="tw-cursor-pointer tw-text-[#0366D8]"
              @click="handleOpenImageSourceModal"
            >[{{ item.reference }}]</sup>
          </span>
        </p>
      </swiper-slide>
    </swiper>

    <!-- <div class="tw-hidden sgDesktop:tw-flex tw-py-10 tw-px-20">
      <button
        class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-text-white tw-bg-sg-qm24-green tw-text-[35px] tw-pb-2 tw-pr-1 disabled:tw-opacity-25"
        :disabled="disabledPrev"
        @click="slidePrev()"
      >
        &lt;
      </button>
      <button
        class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-ml-5 tw-text-white tw-bg-sg-qm24-green tw-text-[35px] tw-pb-2 tw-pl-1 disabled:tw-opacity-25"
        :disabled="disabledNext"
        @click="slideNext()"
      >
        >
      </button>
    </div> -->
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { checkLang } from '../../support/helper';
import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';
type Post = {
  year: string;
  image: any;
  description: string;
  reference: string;
};
const props = defineProps<{
  data: Post[];
  imageSourceOpen: boolean;
}>();
const emit = defineEmits(['update:imageSourceOpen']);

const { t } = useI18n();

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
  checkSlidePosition();
};
const handleOpenImageSourceModal = () => {
  // alert('Image Source Not Implemented');
  imageSourceOpen.value = !imageSourceOpen.value;
};
const disabledPrev = ref(false);
const disabledNext = ref(false);
// function slideNext() {
//   swiperInstance.value?.slideNext();
//   checkSlidePosition();
// }

// function slidePrev() {
//   swiperInstance.value?.slidePrev();
//   checkSlidePosition();
// }

function checkSlidePosition() {
  if ((swiperInstance.value?.isBeginning && swiperInstance.value?.isEnd) || (!swiperInstance.value?.allowSlideNext && !swiperInstance.value?.allowSlidePrev)) {
    disabledPrev.value = true;
    disabledNext.value = true;
  } else if (swiperInstance.value?.snapIndex == props.data.length - 1 || swiperInstance.value?.isEnd) {
    disabledPrev.value = false;
    disabledNext.value = true;
  } else if (swiperInstance.value?.snapIndex == 0 || swiperInstance.value?.isBeginning) {
    disabledPrev.value = true;
    disabledNext.value = false;
  } else {
    disabledPrev.value = false;
    disabledNext.value = false;
  }
}

// Reminder
// onMounted(handleOpenImageSourceModal);
</script>
<style lang="scss"></style>
