<template>
  <div
    :id="customId"
    :class="sectionBgColour"
    class="tw-max-w-[800px] tw-mx-auto tw-pb-8 tw-px-4 sgTablet:tw-px-10 sgTablet:tw-pb-10 sgDesktop:tw-pb-10 sgDesktop:tw-px-20 tw-relative"
  >
    <!-- <span v-if="swiperType == 'do-these'">
      <div class="tw-absolute tw-top-[-50px] tw-left-0">
        <img :src="$cdn('images/cny2024/floatingMountain/floatingMountain.svg')">
      </div>
      <div class="tw-absolute tw-top-[-50px] tw-right-0">
        <img :src="$cdn('images/cny2024/floatingMountain/floatingMountain2.svg')">
      </div>
    </span> -->
    <div
      v-if="swiperType == 'basic'"
      class="tw-py-4"
    />

    <div class="tw-text-center">
      <div
        class="tw-font-bold tw-text-sg-h3-mobile-cny-title sgTablet:tw-text-sg-h3-tablet-cny-title sgDesktop:tw-text-sg-h3-cny-title tw-m-auto tw-text-[#C52600]"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
      >
        {{ sectionTitle }}
      </div>
    </div>

    <div class="tw-py-8 sgTablet:tw-py-10 sgDesktop:tw-py-[60px] tw-h-[500px]">
      <swiper
        id="cny_greeting_Swiper"
        :effect="'coverflow'"
        :grab-cursor="true"
        :centered-slides="true"
        :slides-per-view="'auto'"
        class="tw-max-w-[343px] sgTablet:tw-max-w-[480px] sgDesktop:tw-max-w-[554px] tw-w-full tw-mx-auto"
        :breakpoints="{
          680: {
            slidesPerView: 'auto',
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 200,
              modifier: 1, // 2,3
              scale: 1,
              slideShadows: false,
            },
          },
          1025: {
            slidesPerView: 'auto',
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 200,
              modifier: 1, // 2,3
              scale: 1,
              slideShadows: false,
            },
          },
        }"
        :coverflow-effect="{
          rotate: 0,
          stretch: 65,
          depth: 350,
          modifier: 1, // 2,3
          scale: 1,
          slideShadows: false,
        }"
        :auto-height="true"
        :class="swiperClassName"
        :loop="true"
        @swiper="onSwiper"
        @touch-start="onTouchStart"
        @touch-end="onTouchEnd"
        @slide-change="onSlideChange"
        @before-slide-change-start="onSlideStartChange"
        @slide-next-transition-start="onSlideStartChange"
        @slide-next-transition-end="onSlideEndChange"
        @slide-prev-transition-start="onSlideStartChange"
        @slide-prev-transition-end="onSlideEndChange"
      >
        <!-- :space-between="10" -->
        <!-- spaceBetween: 100, -->

        <!-- class="tw-w-[152px] sgTablet:tw-w-[192px] sgDesktop:tw-w-[212px] !tw-h-[280px] sgTablet:!tw-h-[320px]" -->
        <swiper-slide
          v-for="(item, index) in datas"
          :key="index"
          v-slot="{ isActive }"
          class="tw-w-[152px] sgTablet:tw-w-[192px] sgDesktop:tw-w-[212px] !tw-h-[280px] sgTablet:!tw-h-[320px]"
        >
          <!-- tw-w-[140px] tablet:tw-w-[180px] -->
          <div class="tw-flex tw-items-center tw-h-full">
            <div :class="swiperStyle">
              <div class="slideContentWrapper tw-relative">
                <!-- <div
                  class="tw-h-[200px] tw-rounded-t-md tw-bg-center tw-bg-contain tw-bg-no-repeat"
                  :style="{ backgroundImage: `url(${$cdn(item.image_url)})` }"
                /> -->
                <div
                  class="tw-bg-ap-white tw-px-4 tw-py-5 sgTablet:tw-px-5 tw-shadow-md green-shadow tw-border!-2 tw-relative tw-w-[140px] sgTablet:tw-w-[180px] sgDesktop:tw-w-[200px]"
                  :style="{ borderColor: '#7DBCA4' }"
                >
                  <div class="tw-flex tw-justify-center tw-items-center">
                    <!-- class="tw-text-ap-navy-blue tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 !tw-leading-[52px] sgTablet:!tw-leading-[72px]  sgDesktop:!tw-leading-[84px] tw-text-center tw-tracking-[0.48px]" -->
                    <p
                      class="tw-text-ap-navy-blue tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 !tw-leading-[52px] sgTablet:!tw-leading-[72px] sgDesktop:!tw-leading-[84px] tw-text-center tw-tracking-[0.48px]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                      v-html="item.title"
                    />
                  </div>
                  <p
                    v-if="isActive"
                    class="tw-text-sg-cny24-red tw-text-sg-sh2-mobile-cny sgTablet:tw-text-sg-sh2-tablet-cny sgDesktop:tw-text-sg-sh2-cny tw-w-full tw-text-center tw-mt-5 tw-min-h-[84px] sgTablet:tw-min-h-[56px]"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    {{ item.pinyin }}
                  </p>
                </div>

                <!--  <div class="slideDesc tw-flex tw-flex-col -tw-bottom-8">
                  <div class="tw-flex tw-justify-center tw-cursor-pointer">
                    <div class="tw-my-[24px] tw-rounded-full tw-border-2 tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-items-center tw-border-sg-cny24-red tw-bg-ap-white hover:tw-bg-sg-cny24-red-w60 tw-flex tw-justify-center tw-flex-row active:tw-bg-sg-cny24-red-w40 tw-cursor-pointer">
                      <inline-svg
                        :src="$cdn('images/cny2024/icon/volume_up.svg')"
                        @click="onSpeakerClick(index)"
                      />
                    </div>
                  </div>
                  <div class="tw-text-ap-body tw-text-sm tw-leading-6 tw-text-sg-cny24-red tw-text-center">
                    {{ item.description }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="tw-w-[152px] sgTablet:tw-w-[212px] tw-h-[180px] tw-mx-auto">
        <div class="tw-flex tw-items-center tw-h-full">
          <div class="slideContentWrapper tw-relative">
            <div class="slideDesc tw-flex tw-flex-col tw-w-[140px] sgTablet:tw-w-[200px]">
              <div class="tw-flex tw-justify-center tw-cursor-pointer">
                <div
                  class="tw-my-[24px] tw-rounded-full tw-border-2 tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-items-center tw-border-sg-cny24-red tw-bg-ap-white hover:tw-bg-sg-cny24-red-w60 tw-flex tw-justify-center tw-flex-row active:tw-bg-sg-cny24-red-w40 tw-cursor-pointer"
                >
                  <inline-svg
                    :src="$cdn('images/cny2024/icon/volume_up.svg')"
                    @click="onSpeakerClick(descSpeakerIndex)"
                  />
                </div>
              </div>
              <div
                class="tw-text-ap-body tw-text-sm tw-leading-6 tw-text-sg-cny24-red tw-text-center tw-min-h-[72px]"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
              >
                {{ descContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-wrap sgTablet:tw-pt-[60px] tw-justify-center">
      <div class="tw-flex sgTablet:tw-flex-wrap">
        <button
          class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[35px] tw-pb-2 tw-pr-1"
          @click="slidePrev()"
        >
          &lt;
        </button>
        <div class="tw-px-[60px] tw-items-center tw-flex">
          <p
            class="tw-text-sg-cny24-red tw-text-sg-caption tw-flex-nowrap"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
            :style="{ minWidth: '26px' }"
          >
            {{ `${realIndex} / ${swiperLength}` }}
          </p>
        </div>
        <button
          class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[35px] tw-pb-2 tw-pl-1 disabled:tw-bg-sg-cny24-red-w60"
          @click="slideNext()"
        >
          >
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { EffectCoverflow } from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import SwiperCore from 'swiper';

SwiperCore.use([EffectCoverflow]);
import { onMounted, toRefs, ref, Ref } from 'vue';
import { cdn } from '../../../support/cdn';
import { nextTick } from 'vue';
import { onBeforeMount } from 'vue';
import { checkLang } from '../../../support/helper';

// let disabledNext: Ref<boolean> = ref(false);
// let disabledPrev: Ref<boolean> = ref(true);

const { t } = useI18n();
const props = defineProps({
  swiperType: {
    type: String,
    default: 'basic',
  },
  customId: {
    type: String,
    default: '',
  },
});

const { swiperType } = toRefs(props);
let swiperRefElm: Ref<HTMLElement | undefined> = ref();
let sectionTitle: Ref<string> = ref('');
let sectionDesc: Ref<string> = ref('');
let swiperStyle: Ref<string> = ref('');
const modules = [EffectCoverflow];
let swiperClassName: Ref<string> = ref(swiperType.value + 'Swiper');
let sectionBgColour: Ref<string> = ref('');

let swiperElm = ref();
const tempIndex = ref(0);

const descSpeakerIndex = ref();
const descContent = ref('');

const datas: Ref<Array<any>> = ref([]);

const audioPlayer = new Audio();

onBeforeMount(() => {
  getSwiperStyle();
  if (props.swiperType === 'basic') {
    datas.value = basicDatas.value;
  } else if (props.swiperType === 'intermediate') {
    datas.value = intermediateDatas.value;
  } else if (props.swiperType === 'advanced') {
    datas.value = advancedDatas.value;
  } else if (props.swiperType === 'zodiac') {
    datas.value = zodiacDatas.value;
  }
});

// const onSlideChanged = () => {
//   const swiperCurr = swiperElm.value;
//   false && console.log(swiperCurr, swiperCurr.isBeginning, swiperCurr.isEnd,'check');
//   if (swiperCurr.isBeginning) {
//     disabledPrev.value = true;
//   }
//   if (swiperCurr.isEnd) {
//     disabledNext.value = true;
//   }
// };

const onSlideChange = (swiper) => {
  const index = swiper.realIndex;
  const slideItem = datas.value?.[index];

  descContent.value = slideItem.description;
  descSpeakerIndex.value = index;
};

function onSwiper(swiper) {
  swiperElm.value = swiper;
  if (datas.value.length >= 5) {
    nextTick(() => {
      let activeIndex = swiper.realIndex;
      swiper.slidePrev(0, false);
      swiper.slidePrev(0, false);
      swiper.slideToLoop(activeIndex, 0, false);
      swiper.slideNext(0, false);
      swiper.slideNext(0, false);
      swiper.slideToLoop(activeIndex, 0, false);
      onSlideEndChange(swiper);
    });
  }
}

let timeout = null;
function onTouchEnd(swiper) {
  if (datas.value.length < 5) return;
  if (timeout) {
    clearTimeout(timeout);
  }
  //if(swiper.realIndex == tempIndex.value) return;
  timeout = setTimeout(() => {
    let activeIndex = swiper.realIndex;
    swiper.slidePrev(0, false);
    swiper.slidePrev(0, false);
    swiper.slideToLoop(activeIndex, 0, false);
    swiper.slideNext(0, false);
    swiper.slideNext(0, false);
    swiper.slideToLoop(activeIndex, 0, false);

    // const index = swiper.realIndex;
    // const slideItem = datas.value?.[activeIndex];

    // descContent.value = slideItem.description;
    // descSpeakerIndex.value = activeIndex;
    onSlideEndChange(swiper);
  }, 350);
}

function onTouchStart(swiper) {
  if (timeout) {
    clearTimeout(timeout);
  }
  tempIndex.value = swiper.realIndex;
  onSlideStartChange(swiper);
}

function getSwiperStyle() {
  if (swiperType.value == 'basic') {
    sectionTitle.value = t('cny_greetings_basic');
  } else if (swiperType.value == 'intermediate') {
    sectionTitle.value = t('cny_greetings_intermediate');
  } else if (swiperType.value == 'advanced') {
    sectionTitle.value = t('cny_greetings_advanced');
  } else if (swiperType.value == 'zodiac') {
    sectionTitle.value = t('cny_greetings_zodiac');
  }
}

function slideNext() {
  swiperElm.value.slideNext();

  // false && console.log(swiperElm.value, 'check swiper next');
}

function slidePrev() {
  swiperElm.value.slidePrev();
  // false && console.log(swiperElm.value, 'check swiper prev');
}

const onSpeakerClick = (index: number) => {
  // false && console.log('clicked', index);
  audioPlayer.pause();
  const soundUrl = datas.value?.[index]?.sound_url;
  audioPlayer.src = cdn(soundUrl);
  if (props.swiperType === 'zodiac') {
    audioPlayer.play();
    audioPlayer.volume = 0.18;
  } else {
    audioPlayer.play();
    audioPlayer.volume = 1;
  }
};

const realIndex = computed(() => {
  return swiperElm.value?.realIndex + 1;
});

const swiperLength = computed(() => {
  return swiperElm.value?.slides?.length ?? 0;
});

const basicDatas = computed(() => [
  {
    id: 1,
    title: '年年<br/>有余',
    pinyin: 'nián nián yǒu yú',
    description: t('cny_greetings_basic_1'),
    sound_url: 'sound/cny/greetings/audioBasic2.mp3',
  },
  {
    id: 2,
    title: '大吉<br/>大利',
    pinyin: 'dà jí dà lì',
    description: t('cny_greetings_basic_2'),
    sound_url: 'sound/cny/greetings/audioBasic4.mp3',
  },
  {
    id: 3,
    title: '心想<br/>事成',
    pinyin: 'xīn xiǎng shì chéng',
    description: t('cny_greetings_basic_3'),
    sound_url: 'sound/cny/greetings/audioBasic3.mp3',
  },
  {
    id: 4,
    title: '身体<br/>健康',
    pinyin: 'shēn tǐ jiàn kāng',
    description: t('cny_greetings_basic_4'),
    sound_url: 'sound/cny/greetings/audioBasic1.mp3',
  },
]);

const intermediateDatas = computed(() => [
  {
    id: 1,
    title: '万事<br/>如意',
    pinyin: 'wàn shì rú yì',
    description: t('cny_greetings_intermediate_1'),
    sound_url: 'sound/cny/greetings/audioIntermediate1.mp3',
  },
  {
    id: 2,
    title: '步步<br/>高升',
    pinyin: 'bù bù gāo shēng',
    description: t('cny_greetings_intermediate_2'),
    sound_url: 'sound/cny/greetings/audioIntermediate6.mp3',
  },
  {
    id: 3,
    title: '一帆<br/>风顺',
    pinyin: 'yī fān fēng shùn',
    sound_url: 'sound/cny/greetings/audioIntermediate2.mp3',
    description: t('cny_greetings_intermediate_3'),
  },
  {
    id: 4,
    title: '阖家<br/>幸福',
    pinyin: 'hé jiā xìng fú',
    description: t('cny_greetings_intermediate_4'),
    sound_url: 'sound/cny/greetings/audioIntermediate7.mp3',
  },
  {
    id: 5,
    title: '马到<br/>成功',
    pinyin: 'mǎ dào chéng gōng',
    description: t('cny_greetings_intermediate_5'),
    sound_url: 'sound/cny/greetings/audioIntermediate8.mp3',
  },
  {
    id: 6,
    title: '招财<br/>进宝',
    pinyin: 'zhāo cái jìn bǎo',
    description: t('cny_greetings_intermediate_6'),
    sound_url: 'sound/cny/greetings/audioIntermediate4.mp3',
  },
  {
    id: 7,
    title: '财源<br/>广进',
    pinyin: 'cái yuán guǎng jìn',
    description: t('cny_greetings_intermediate_7'),
    sound_url: 'sound/cny/greetings/audioIntermediate9.mp3',
  },
  {
    id: 8,
    title: '吉祥<br/>如意',
    pinyin: 'jí xiáng rú yì',
    description: t('cny_greetings_intermediate_8'),
    sound_url: 'sound/cny/greetings/audioIntermediate5.mp3',
  },
]);

const advancedDatas = computed(() => [
  {
    id: 1,
    title: '吉星<br/>高照',
    pinyin: 'jí xīng gāo zhào',
    description: t('cny_greetings_advanced_1'),
    sound_url: 'sound/cny/greetings/audioAdvanced5.mp3',
  },
  {
    id: 2,
    title: '飞黄<br/>腾达',
    pinyin: 'fēi huáng téng dá',
    // description: 'Wishing you rapid success in your career.',
    description: t('cny_greetings_advanced_2'),
    sound_url: 'sound/cny/greetings/audioAdvanced2.mp3',
  },
  /* {
    id: 3,
    title: '大展<br/>宏图',
    pinyin: 'dà zhǎn hóng tú',
    description: t('cny_greetings_advanced_3'),
    sound_url: 'sound/cny/greetings/audioAdvanced6.mp3',
  }, */
  {
    id: 4,
    title: '升官<br/>发财',
    pinyin: 'shēng guān fā cái',
    description: t('cny_greetings_advanced_4'),
    sound_url: 'sound/cny/greetings/audioAdvanced3.mp3',
  },
  {
    id: 5,
    title: '财源<br/>滚滚',
    pinyin: 'cái yuán gǔn gǔn',
    description: t('cny_greetings_advanced_5'),
    sound_url: 'sound/cny/greetings/audioAdvanced7.mp3',
  },
]);

const zodiacDatas = computed(() => [
  {
    id: 1,
    title: '龙马<br/>精神',
    pinyin: 'lóng mǎ jīng shén',
    description: t('cny_greetings_zodiac_1'),
    sound_url: 'sound/cny/greetings/audioZodiac1.mp3',
  },
  {
    id: 2,
    title: '龙年<br/>吉祥',
    pinyin: 'lóng nián jí xiáng',
    description: t('cny_greetings_zodiac_2'),
    sound_url: 'sound/cny/greetings/audioZodiac2.mp3',
  },
  {
    id: 3,
    title: '龙年<br/>大吉',
    pinyin: 'lóng nián dà jí',
    description: t('cny_greetings_zodiac_3'),
    sound_url: 'sound/cny/greetings/audioZodiac3.mp3',
  },
  {
    id: 4,
    title: '龙腾<br/>虎跃',
    pinyin: 'lóng téng hǔ yuè',
    description: t('cny_greetings_zodiac_4'),
    sound_url: 'sound/cny/greetings/audioZodiac4.mp3',
  },
  {
    id: 5,
    title: '龙年<br/>如意',
    pinyin: 'lóng nián rú yì',
    description: t('cny_greetings_zodiac_5'),
    sound_url: 'sound/cny/greetings/audioZodiac5.mp3',
  },
]);
const onSlideStartChange = (swiper) => {
  swiper.slides.forEach((slide) => {
    slide.classList.remove('swiper-slide-hidden');
  });
};

const onSlideEndChange = (swiper) => {
  let activeSlideIndex = swiper.slides.findIndex((slide) => {
    return slide.classList.contains('swiper-slide-active');
  });

  let prevPrevSlideIndex = (activeSlideIndex - 2 + swiper.slides.length) % swiper.slides.length;
  let prevSlideIndex = (activeSlideIndex - 1 + swiper.slides.length) % swiper.slides.length;
  let nextSlideIndex = (activeSlideIndex + 1) % swiper.slides.length;
  let nextNextSlideIndex = (activeSlideIndex + 2) % swiper.slides.length;

  swiper.slides.forEach((slide, index) => {
    if (![prevPrevSlideIndex, prevSlideIndex, activeSlideIndex, nextSlideIndex, nextNextSlideIndex].includes(index)) {
      slide.classList.add('swiper-slide-hidden');
    } else {
      slide.classList.remove('swiper-slide-hidden');
    }
  });

  if (datas.value.length < 5) return;
  let activeIndex = swiper.realIndex;
  swiper.slidePrev(0, false);
  swiper.slidePrev(0, false);
  swiper.slideToLoop(activeIndex, 0, false);
  swiper.slideNext(0, false);
  swiper.slideNext(0, false);
  swiper.slideToLoop(activeIndex, 0, false);
};
</script>
<style>
div#cny_greeting_Swiper .slideDesc {
  opacity: 0;
  height: 0;
}
div#cny_greeting_Swiper .swiper-slide-active .slideDesc {
  opacity: 1;
  /* min-height:96px; */
  height: fit-content;
  /* transition: opacity 0.5s ease-in-out; */
  /*  padding-bottom: 20px; */
}

div#cny_greeting_Swiper .swiper-slide {
  /*  min-height: 292px; */
}

div#cny_greeting_Swiper.swiper-autoheight .swiper-wrapper {
  align-items: center !important;
}

div#cny_greeting_Swiper .swiper-slide .slideContentWrapper {
  /* opacity: 0.5; */
}

div#cny_greeting_Swiper .swiper-slide.swiper-slide-active .slideContentWrapper {
  /* opacity: 1; */
  /* transition: opacity 0.5s ease-in-out; */
}

@media (max-width: 680px) {
  div#cny_greeting_Swiper.swiper-3d {
    /* perspective: 80px; */
  }
}
.swiper-slide-hidden {
  opacity: 0;
}
.green-shadow {
  box-shadow: 12px 12px 0px 0px #007c4c;
  border-radius: 8px;
}
</style>
