<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <ap-article-template
      :title="title"
      :content="wordpressData.the_content"
    />
    <ap-footer />
  </div>
</template>

<script lang="ts">
import { onMounted, toRefs } from 'vue';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { wordpressData } = toRefs(props);

    onMounted(() => {
      false && console.log(wordpressData.value);
    });
  },
  data() {
    const title = '耻 chi (Sense of shame)';

    return {
      title,
    };
  },
};
</script>
