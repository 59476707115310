<template>
  <div class="">
    <!-- Top Nav Bar -->
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />

    <div class="tw-bg-sg-sccc1-w60 tw-bg-opacity-20 tw-pl-4 tablet:tw-pl-8 desktop:tw-pl-16 tw-pt-12 tablet:tw-pt-14 desktop:tw-pt-16 tw-text-sg-h4-mobile tablet:tw-text-sg-h4-tablet desktop:tw-text-sg-h4 tw-text-sg-sccc1 tw-w-full">
      {{ gameTitle }}
    </div>

    <div class="tw-bg-sg-sccc1-w60 tw-bg-opacity-20 tw-pt-6 tablet:tw-pt-8 desktop:tw-pt-12">
      <iframe
        class="tw-mx-auto tw-w-[90vw] tw-aspect-square
      tablet:tw-w-[80vw]
      desktop:tw-w-[50vw]"
        :class="{'!tw-aspect-video': squareOrHorizontal === 'horizontal'}"
        :src="gameUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
        allowfullscreen
      />
    </div>

    <div class=" tw-bg-sg-sccc1-w60 tw-bg-opacity-20 tw-pt-6 tw-pb-10 tw-inline-flex tw-mx-auto tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3 tw-w-full tw-justify-center tw-flex-row">
      Co-Developed with
      <a
        href="https://solstice.sg/"
        target="_blank"
        rel="noopener"
        class="tw-text-ap-dodger-blue tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3 tw-ml-1"
      >
        We are Solstice
      </a>
    </div>

    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script setup lang='ts'>
import { onMounted, ref } from 'vue';

const gameRef = ref<string>('');
const gameUrl = ref<string>('');
const gameTitle = ref<string>('');
const squareOrHorizontal = ref<string>('');

const props = defineProps<{
  wordpressData: any,
}>();

onMounted(() => {
  const slug = props.wordpressData.wp_query.post_name;

  switch (slug) {
    case 'huat':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/cnywithsccc/';
      gameTitle.value = '新年快乐: HUAT do I say?';
      squareOrHorizontal.value = 'square';
      break;

    case 'chicken-rice':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/chickenrice/';
      gameTitle.value = 'Hawker Adventure – Chicken Rice';
      squareOrHorizontal.value = 'square';
      break;

    case 'toast-kopi':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/kopitoast/';
      gameTitle.value = 'Hawker Adventure – Toast & Kopi';
      squareOrHorizontal.value = 'square';
      break;

    case 'bah-kut-teh':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/bakkutteh/';
      gameTitle.value = 'Hawker Adventure – Bak Kut Teh';
      squareOrHorizontal.value = 'square';
      break;

    case 'tangyuan-pop':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/tangyuanpop/';
      gameTitle.value = 'Tangyuan Pop!';
      squareOrHorizontal.value = 'horizontal';
      break;

    case 'zhong-yuan-festival-game':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/sccczhongyuan/';
      gameTitle.value = 'Zhongyuan Festival Game';
      squareOrHorizontal.value = 'horizontal';
      break;

    case 'singaporen-personality-quiz':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/scccquiz/';
      gameTitle.value = 'SINGAPO人 Personality Quiz';
      squareOrHorizontal.value = 'horizontal';
      break;

    case 'duanwu-festival-bak-chang-hunt':
      gameRef.value = slug;
      gameUrl.value = 'https://play.solstice.sg/bakchanghunt/?fbclid=IwAR1F4qaujn2WxY3atG7ZuZazrTYpPAlvgdViZ2hu5EcZ8XAB1yIpYaejuW4';
      gameTitle.value = 'Duanwu Festival Bak Chang Hunt';
      squareOrHorizontal.value = 'horizontal';
      break;
  }
});

</script>
