<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-[#F4F2E3] tw-bg-opacity-80 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{ '!tw-flex': modelValue == true, 'tw-font-notosans': checkLang() == 'zh-hans', 'tw-font-akkuratbold': checkLang() == 'en' }"
    >
      <!-- Modal Content -->
      <div class="tw-max-w-[343px] tw-w-full sgTablet:tw-max-w-[560px] tw-mx-4">
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div
            class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
            @click="handleModalClose"
          >
            <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div
          class="tw-bg-white tw-rounded-lg tw-w-full tw-shadow-ap-preset-1 tw-flex tw-flex-col tw-relative tw-max-h-[80vh] tw-h-full before:tw-content-[''] before:tw-absolute before:tw-top-full before:tw-left-1/2 before:-tw-translate-x-1/2 before:tw-border-[20px] before:tw-border-transparent before:tw-border-t-solid"
          :class="[
            showFallback || showResult ? 'before:tw-border-t-[#F4F2E3]' : 'before:tw-border-t-white',

          ]"
        >
          <!-- question -->
          <div v-if="showQuestion">
            <div
              :class="[
                'tw-flex tw-flex-col tw-justify-center',
                'tw-bg-[#F4F2E3] tw-rounded-t-lg',
                'tw-px-5 sgTablet:tw-px-[32px] sgDesktop:tw-px-[40px]',
                'tw-pt-5 sgTablet:tw-pt-[32px]',
                'tw-pb-4',
                'tw-min-h-[132px] sgTablet:tw-min-h-[128px] sgDesktop:tw-min-h-[144px]',
              ]"
            >
              <h4
                class="tw-text-[#9E1E00] tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t(`cny_greet_them_right_quiz_${currentQuestion}_question`) }}
              </h4>
            </div>
            <div class="tw-bg-white tw-px-4 sgTablet:tw-px-20 tw-pt-4 sgTablet:tw-pt-6 tw-pb-5 sgTablet:tw-pb-10 tw-rounded-b-lg">
              <div
                class="tw-grid tw-grid-cols-2 tw-gap-4 sgTablet:tw-gap-5 sgDesktop:tw-gap-6"
              >
                <template
                  v-for="(i, index) in options[currentQuestion]"
                  :key="i"
                >
                  <div
                    :class="[
                      'tw-bg-[#FF9F33] hover:tw-bg-[#FF8700] active:tw-bg-[#A35600] tw-rounded-lg tw-h-[52px] tw-text-center tw-py-4 tw-border-[#FF8700] hover:tw-border-[#CC6C00] active:tw-border-[#824500] tw-border-2 tw-text-sg-sh3-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-text-[#9E1E00] active:tw-text-white tw-cursor-pointer',
                      {
                        'tw-font-notosansbold': checkLang() == 'zh-hans',
                        'tw-font-akkuratbold': checkLang() == 'en',
                        'tw-col-span-1': isColumnSpan1(options[currentQuestion], index),
                        'tw-col-span-2': !isColumnSpan1(options[currentQuestion], index)
                      }
                    ]"
                    :style="{
                      boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.06),0px 0px 4px 0px rgba(0,0,0,0.04)',
                    }"
                    @click="validateAnswer(i)"
                  >
                    {{ $t(`cny_greet_them_right_quiz_${currentQuestion}_answer_${index + 1}`) }}
                  </div>
                </template>
              </div>


              <div class="tw-hidden tw-flex tw-justify-center tw-text-sg-caption tw-mt-4 sgTablet:tw-mt-6 tw-text-[#767676]">
                <div class="tw-w-4 tw-h-4 tw-text-center">
                  {{ currentQuestionNo }}
                </div>
                <div class="tw-w-4 tw-h-4 tw-text-center">
                  /
                </div>
                <div class="tw-w-4 tw-h-4 tw-text-center">
                  {{ totalQuestionNo }}
                </div>
              </div>
            </div>
          </div>

          <!-- fallback -->
          <div
            v-else-if="showFallback"
            :class="[
              'tw-h-full',
              'tw-max-h-[304px] sgTablet:tw-max-h-[328px] sgDesktop:tw-max-h-[352px]',
              'tw-bg-[#F4F2E3] tw-flex tw-items-center tw-justify-center tw-rounded-lg',
              'tw-p-5 sgTablet:tw-p-8 sgDesktop:tw-p-10',
            ]"
          >
            <div>
              <div class="tw-text-[#9E1E00] tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-text-center">
                {{ $t('cny_greet_them_right_quiz_fallback_title') }}
              </div>
              <img
                :src="$cdn('images/cny2024/greet-them-right/quiz/fallback.svg')"
                class="tw-mx-auto tw-w-[100px] tw-h-[100px] sgDesktop:tw-w-[100px] sgDesktop:tw-h-[100px] tw-mt-4 sgTablet:tw-mt-6 sgDesktop:tw-mt-8"
              >
              <div
                class="tw-text-[#9E1E00] tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-mt-4 sgTablet:tw-mt-6 sgDesktop:tw-mt-8 tw-text-center"
              >
                {{ $t('cny_greet_them_right_quiz_fallback_subtitle') }}
              </div>
              <div
                class="tw-text-[#9E1E00] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-mt-2 sgTablet:tw-mt-3 tw-text-center"
              >
                {{ $t('cny_greet_them_right_quiz_fallback_desc') }}
              </div>
            </div>
          </div>

          <!-- answer -->
          <div
            v-else-if="showResult"
            :class="[
              'tw-h-full',
              'tw-max-h-[304px] sgTablet:tw-max-h-[328px] sgDesktop:tw-max-h-[352px]',
              'tw-bg-[#F4F2E3] tw-flex tw-items-center tw-justify-center tw-rounded-lg',
              'tw-p-5 sgTablet:tw-p-8 sgDesktop:tw-p-10',
            ]"
          >
            <div>
              <div
                class="tw-text-[#9E1E00] tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-text-center"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny_greet_them_right_quiz_result_title') }}
              </div>
              <img
                :src="$cdn(result?.image)"
                class="tw-mx-auto tw-w-[100px] tw-h-[100px] sgDesktop:tw-w-[100px] sgDesktop:tw-h-[100px] tw-mt-4 sgTablet:tw-mt-6 sgDesktop:tw-mt-8"
              >
              <div class="tw-mt-4 sgTablet:tw-mt-6 sgDesktop:tw-mt-8">
                <div
                  v-if="checkLang() == 'en'"
                  class="tw-text-[#9E1E00] tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-text-center"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  {{ result?.en }}
                </div>
                <div
                  class="tw-text-[#9E1E00] tw-text-sg-sh2-mobile-cny sgTablet:tw-text-sg-sh2-tablet-cny sgDesktop:tw-text-sg-sh2-cny tw-mt-2 sgDesktop:tw-mt-3 tw-text-center"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  {{ result?.cn }}
                </div>
                <div
                  v-if="checkLang() == 'en'"
                  class="tw-text-[#9E1E00] tw-text-sg-body-1-mobile-cny sgTablet:tw-text-sg-body-1-tablet-cny sgDesktop:tw-text-sg-body-1-cny tw-mt-2 sgDesktop:tw-mt-3 tw-text-center"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  {{ result?.cnpy }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          :src="$cdn('images/cny2024/greet-them-right/quiz_lion.png')"
          class="tw-w-20 tw-flex tw-mx-auto tw-mt-7"
        >

        <div class="tw-flex tw-items-center tw-justify-center tw-text-[#1A1A1A] tw-mt-3 sgTablet:tw-mt-5">
          <div
            class="tw-py-2 tw-px-4 tw-cursor-pointer tw-flex tw-items-center tw-group/cny-greet-them-right-restart-btn"
            @click="restartQuiz"
          >
            <span class="group-hover/cny-greet-them-right-restart-btn:tw-underline"> {{ $t('cny_greet_them_right_restart') }}</span>
            <inline-svg
              fill="#1A1A1A"
              :src="$cdn(`/icons/restart_alt.svg`)"
              class="tw-w-6 tw-h-6 tw-ml-2"
            />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { onMounted, computed, ref } from 'vue';
import { checkLang } from '../../../support/helper';
const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if (!newVal) {
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
    onHide();
  } else {
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
    onShow();
  }
});

function isColumnSpan1(list: any, index: any) {
  const length = list.length;

  if (length === 1 || length === 4) {
    return true;
  } else if (length === 2){
    return false;
  } else if (length === 3) {
    // 3 items: first two in the first row
    return index < 2;
  }

  // Default case
  return false;
}



const options = {
  1: ['mother', 'father', 'my-sibling', 'my-parent'],
  2: ['same', 'older-by-1-gen', 'older-by-2-gen', 'dont-know'],
  3: ['older', 'younger'],
  4: ['male', 'female'],
  5: ['yes', 'no'],
  6: ['yes', 'no', 'dont-know'],
  7: ['same', 'different'],
  8: ['senior', 'junior'],
  9: ['grandfather', 'grandmother', 'dont-know'],
  10: ['older', 'younger', 'dont-know'],
} as const;

const questions = {
  q: 1,
  father: {
    next_no: 2,
    q: 2,
    same: {
      next_no: 3,
      q: 3,
      younger: {
        next_no: 4,
        q: 7,
        same: {
          next_no: 6,
          q: 4,
          male: {
            a: 'tangdi',
          },
          female: {
            a: 'tangmei',
          },
        },
        different: {
          next_no: 6,
          q: 4,
          male: {
            a: 'biaodi2',
          },
          female: {
            a: 'biaomei2_f',
          },
        },
      },
      older: {
        next_no: 4,
        q: 7,
        same: {
          next_no: 6,
          q: 4,
          male: {
            a: 'tangge',
          },
          female: {
            a: 'tangjie',
          },
        },
        different: {
          next_no: 6,
          q: 4,
          male: {
            a: 'biaoge',
          },
          female: {
            a: 'biaojie2_f',
          },
        },
      },
    },
    'older-by-1-gen': {
      next_no: 3,
      q: 5,
      yes: {
        next_no: 4,
        q: 8,
        senior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'bofu',
          },
          female: {
            a: 'gugu',
          },
        },
        junior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'shushu',
          },
          female: {
            a: 'gugu',
          },
        },
      },
      no: {
        next_no: 4,
        q: 8,
        senior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'guzhang',
          },
          female: {
            a: 'bomu',
          },
        },
        junior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'guzhang',
          },
          female: {
            a: 'shenshen',
          },
        },
      },
    },
    'older-by-2-gen': {
      next_no: 3,
      q: 6,
      yes: {
        next_no: 6,
        q: 4,
        male: {
          a: 'gonggong',
        },
        female: {
          a: 'popo',
        },
      },
      no: {
        next_no: 4,
        q: 9,
        grandfather: {
          next_no: 5,
          q: 10,
          older: {
            next_no: 6,
            q: 4,
            male: {
              a: 'bogong',
            },
            female: {
              a: 'gupo',
            },
          },
          younger: {
            next_no: 6,
            q: 4,
            male: {
              a: 'shugong',
            },
            female: {
              a: 'gupo',
            },
          },
          'dont-know': {
            a: 'fallback',
          },
        },
        grandmother: {
          next_no: 5,
          q: 10,
          older: {
            next_no: 6,
            q: 4,
            male: {
              a: 'jiugong',
            },
            female: {
              a: 'yipo',
            },
          },
          younger: {
            next_no: 6,
            q: 4,
            male: {
              a: 'jiugong',
            },
            female: {
              a: 'yipo',
            },
          },
          'dont-know': {
            a: 'fallback',
          },
        },
        'dont-know': {
          a: 'fallback',
        },
      },
      'dont-know': {
        a: 'fallback',
      },
    },
    'dont-know': {
      a: 'fallback',
    },
  },
  mother: {
    next_no: 2,
    q: 2,
    same: {
      next_no: 3,
      q: 3,
      younger: {
        next_no: 4,
        q: 7,
        same: {
          next_no: 6,
          q: 4,
          male: {
            a: 'biaodi',
          },
          female: {
            a: 'biaomei_m',
          },
        },
        different: {
          next_no: 6,
          q: 4,
          male: {
            a: 'biaodi',
          },
          female: {
            a: 'biaomei2_m',
          },
        },
      },
      older: {
        next_no: 4,
        q: 7,
        same: {
          next_no: 6,
          q: 4,
          male: {
            a: 'biaoge2',
          },
          female: {
            a: 'biaojie2_m',
          },
        },
        different: {
          next_no: 6,
          q: 4,
          male: {
            a: 'biaoge2',
          },
          female: {
            a: 'biaojie_m',
          },
        },
      },
    },
    'older-by-1-gen': {
      next_no: 3,
      q: 5,
      yes: {
        next_no: 4,
        q: 8,
        senior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'jiujiu',
          },
          female: {
            a: 'yima',
          },
        },
        junior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'jiujiu',
          },
          female: {
            a: 'yima',
          },
        },
      },
      no: {
        next_no: 4,
        q: 8,
        senior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'yizhang',
          },
          female: {
            a: 'jiumu',
          },
        },
        junior: {
          next_no: 6,
          q: 4,
          male: {
            a: 'yizhang',
          },
          female: {
            a: 'jiumu',
          },
        },
      },
    },
    'older-by-2-gen': {
      next_no: 3,
      q: 6,
      yes: {
        next_no: 6,
        q: 4,
        male: {
          a: 'waigong2',
        },
        female: {
          a: 'waipo2',
        },
      },
      no: {
        next_no: 4,
        q: 9,
        grandfather: {
          next_no: 5,
          q: 10,
          older: {
            next_no: 6,
            q: 4,
            male: {
              a: 'bogong2',
            },
            female: {
              a: 'gupo2',
            },
          },
          younger: {
            next_no: 6,
            q: 4,
            male: {
              a: 'shugong2',
            },
            female: {
              a: 'gupo2',
            },
          },
          'dont-know': {
            a: 'fallback',
          },
        },
        grandmother: {
          next_no: 5,
          q: 10,
          older: {
            next_no: 6,
            q: 4,
            male: {
              a: 'jiugong2',
            },
            female: {
              a: 'yipo2',
            },
          },
          younger: {
            next_no: 6,
            q: 4,
            male: {
              a: 'jiugong2',
            },
            female: {
              a: 'yipo2',
            },
          },
          'dont-know': {
            a: 'fallback',
          },
        },
        'dont-know': {
          a: 'fallback',
        },
      },
      'dont-know': {
        a: 'fallback',
      },
    },
    'dont-know': {
      a: 'fallback',
    },
  },
  'my-sibling': {
    next_no: 2,
    q: 3,
    younger: {
      next_no: 6,
      q: 4,
      male: {
        a: 'didi',
      },
      female: {
        a: 'meimei',
      },
    },
    older: {
      next_no: 6,
      q: 4,
      male: {
        a: 'gege',
      },
      female: {
        a: 'jiejie',
      },
    },
  },
  'my-parent': {
    next_no: 6,
    q: 4,
    male: {
      a: 'baba',
    },
    female: {
      a: 'mama',
    },
  },
};

const results = {
  gupo: {
    en: "Grandaunt (Grandfather's sister)",
    cn: '姑婆',
    cnpy: 'gū pó',
    image: 'images/cny2024/greet-them-right/quiz/gupo.png',
  },
  shugong: {
    en: "Granduncle (Grandfather's younger brother)",
    cn: '叔公',
    cnpy: 'shū gōng',
    image: 'images/cny2024/greet-them-right/quiz/shugong.png',
  },
  bogong: {
    en: "Granduncle (Grandfather's older brother) ",
    cn: '伯公',
    cnpy: 'bó gōng',
    image: 'images/cny2024/greet-them-right/quiz/bogong.png',
  },
  gonggong: {
    en: 'Grandfather',
    cn: '公公/爷爷',
    cnpy: 'gōng gōng/yé yé',
    image: 'images/cny2024/greet-them-right/quiz/gonggong.png',
  },
  popo: {
    en: 'Grandmother',
    cn: '婆婆/奶奶',
    cnpy: 'pó pó/nǎi nai',
    image: 'images/cny2024/greet-them-right/quiz/popo.png',
  },
  jiugong: {
    en: "Granduncle (Grandmother's brother)",
    cn: '舅公',
    cnpy: 'jiù gōng',
    image: 'images/cny2024/greet-them-right/quiz/jiugong.png',
  },
  yipo: {
    en: "Grandaunt (Grandmother's sister)",
    cn: '姨婆',
    cnpy: 'yí pó',
    image: 'images/cny2024/greet-them-right/quiz/yipo.png',
  },
  gugu: {
    en: "Auntie (Father's sister)",
    cn: '姑母/姑姑',
    cnpy: 'gū mǔ/gū gū',
    image: 'images/cny2024/greet-them-right/quiz/gugu.png',
  },
  shushu: {
    en: "Uncle (Father's younger brother)",
    cn: '叔父/叔叔',
    cnpy: 'shú fù/shū shu',
    image: 'images/cny2024/greet-them-right/quiz/shushu.png',
  },
  guzhang: {
    en: "Uncle (Father's Sister's Husband)",
    cn: '姑丈',
    cnpy: 'gū zhàng',
    image: 'images/cny2024/greet-them-right/quiz/guzhang.png',
  },
  shenshen: {
    en: "Auntie (Father's Younger Brother's Wife)",
    cn: '婶婶',
    cnpy: 'shěn shen',
    image: 'images/cny2024/greet-them-right/quiz/shenshen.png',
  },
  bomu: {
    en: "Auntie (Father's Older Brother's Wife)",
    cn: '伯母',
    cnpy: 'bó mǔ',
    image: 'images/cny2024/greet-them-right/quiz/bomu.png',
  },
  bofu: {
    en: "Uncle (Father's older brother)",
    cn: '伯父/伯伯',
    cnpy: 'bó fù/bó bo',
    image: 'images/cny2024/greet-them-right/quiz/bofu.png',
  },
  baba: {
    en: 'Father',
    cn: '爸爸',
    cnpy: 'bà bà',
    image: 'images/cny2024/greet-them-right/quiz/baba.png',
  },
  biaoge: {
    en: 'Cousin (of the different surname as self)',
    cn: '表哥',
    cnpy: 'biǎo gē',
    image: 'images/cny2024/greet-them-right/quiz/biaoge.png',
  },
  biaodi2: {
    en: 'Cousin (of the different surname as self)',
    cn: '表弟',
    cnpy: 'biǎo dì',
    image: 'images/cny2024/greet-them-right/quiz/biaodi2.png',
  },
  biaojie2_f: {
    en: 'Cousin (of the different surname as self)',
    cn: '表姐',
    cnpy: '',
    image: 'images/cny2024/greet-them-right/quiz/biaojie2.png',
  },
  biaomei2_f: {
    en: 'Cousin (of the different surname as self)',
    cn: '表妹',
    cnpy: 'biǎo mèi',
    image: 'images/cny2024/greet-them-right/quiz/biaomei2.png',
  },
  tangge: {
    en: 'Cousins (of the same surname as self)',
    cn: '堂哥',
    cnpy: 'táng gē',
    image: 'images/cny2024/greet-them-right/quiz/tangge.png',
  },
  tangdi: {
    en: 'Cousins (of the same surname as self)',
    cn: '堂弟',
    cnpy: 'táng dì',
    image: 'images/cny2024/greet-them-right/quiz/tangdi.png',
  },
  tangjie: {
    en: 'Cousins (of the same surname as self)',
    cn: '堂姐',
    cnpy: 'táng jiě',
    image: 'images/cny2024/greet-them-right/quiz/tangjie.png',
  },
  tangmei: {
    en: 'Cousins',
    cn: '堂妹',
    cnpy: 'táng mèi',
    image: 'images/cny2024/greet-them-right/quiz/tangmei.png',
  },
  gupo2: {
    en: "Grandaunt (Grandfather's sister)",
    cn: '姑婆（外祖父姐妹）',
    cnpy: 'gū pó',
    image: 'images/cny2024/greet-them-right/quiz/gupo2.png',
  },
  shugong2: {
    en: "Granduncle (Grandfather's younger brother)",
    cn: '叔公',
    cnpy: 'shū gōng',
    image: 'images/cny2024/greet-them-right/quiz/shugong2.png',
  },
  bogong2: {
    en: "Granduncle (Grandfather's older brother)",
    cn: '伯公',
    cnpy: 'bó gōng',
    image: 'images/cny2024/greet-them-right/quiz/bogong2.png',
  },
  waigong2: {
    en: 'Grandfather',
    cn: '外公',
    cnpy: 'wài gōng',
    image: 'images/cny2024/greet-them-right/quiz/waigong2.png',
  },
  waipo2: {
    en: 'Grandmother',
    cn: '外婆',
    cnpy: 'wài pó',
    image: 'images/cny2024/greet-them-right/quiz/waipo2.png',
  },
  jiugong2: {
    en: "Granduncle (Grandmother's brother)",
    cn: '舅公',
    cnpy: 'jiù gōng',
    image: 'images/cny2024/greet-them-right/quiz/jiugong2.png',
  },
  yipo2: {
    en: "Grandaunt (Grandmother's sister)",
    cn: '姨婆',
    cnpy: 'yí pó',
    image: 'images/cny2024/greet-them-right/quiz/yipo2.png',
  },
  mama: {
    en: 'Mother',
    cn: '妈妈',
    cnpy: 'mā mā',
    image: 'images/cny2024/greet-them-right/quiz/mama.png',
  },
  jiujiu: {
    en: "Uncle  (Mother's Brother)",
    cn: '舅舅',
    cnpy: 'jiù jiu',
    image: 'images/cny2024/greet-them-right/quiz/jiujiu.png',
  },
  yima: {
    en: "Auntie (Mother's Sister)",
    cn: '姨妈',
    cnpy: 'yí mā',
    image: 'images/cny2024/greet-them-right/quiz/yima.png',
  },
  jiumu: {
    en: "Auntie (Mother's Brother's Wife)",
    cn: '舅母',
    cnpy: 'jiù mu',
    image: 'images/cny2024/greet-them-right/quiz/jiumu.png',
  },
  yizhang: {
    en: "Uncle (Mother's Sister's Husband)",
    cn: '姨丈',
    cnpy: 'yí zhàng',
    image: 'images/cny2024/greet-them-right/quiz/yizhang.png',
  },
  biaoge2: {
    en: 'Cousin',
    cn: '表哥',
    cnpy: 'biǎo gē',
    image: 'images/cny2024/greet-them-right/quiz/biaoge2.png',
  },
  biaodi: {
    en: 'Cousin',
    cn: '表弟',
    cnpy: 'biǎo dì',
    image: 'images/cny2024/greet-them-right/quiz/biaodi.png',
  },
  biaojie_m: {
    en: 'Cousin',
    cn: '表姐',
    cnpy: 'biǎo jiě',
    image: 'images/cny2024/greet-them-right/quiz/biaojie.png',
  },
  biaojie2_m: {
    en: 'Cousin',
    cn: '表姐',
    cnpy: 'biǎo jiě',
    image: 'images/cny2024/greet-them-right/quiz/biaojie2.png',
  },
  biaomei2_m: {
    en: 'Cousin',
    cn: '表妹',
    cnpy: 'biǎo mèi',
    image: 'images/cny2024/greet-them-right/quiz/biaomei2.png',
  },
  biaomei_m: {
    en: 'Cousin',
    cn: '表妹',
    cnpy: 'biǎo mèi',
    image: 'images/cny2024/greet-them-right/quiz/biaomei.png',
  },
  jiejie: {
    en: 'Sister',
    cn: '姐姐',
    cnpy: 'jiě jiě',
    image: 'images/cny2024/greet-them-right/quiz/jiejie.png',
  },
  meimei: {
    en: 'Sister',
    cn: '妹妹',
    cnpy: '"mèi mei',
    image: 'images/cny2024/greet-them-right/quiz/meimei.png',
  },
  gege: {
    en: 'Brother',
    cn: '哥哥',
    cnpy: 'gē gē',
    image: 'images/cny2024/greet-them-right/quiz/gege.png',
  },
  didi: {
    en: 'Brother',
    cn: '弟弟',
    cnpy: 'dì dì',
    image: 'images/cny2024/greet-them-right/quiz/didi.png',
  },
};

const currentQuestionNo = ref(1);
const currentQuestion = ref(1);
const totalQuestionNo = ref(6);
const answers = ref<string[]>([]);
const result = ref<{ en: string; cn: string; cnpy: string; image: string }>();
const showQuestion = ref(true);
const showResult = ref(false);
const showFallback = ref(false);

const onShow = () => {
  restartQuiz();
};

const onHide = () => {};

const validateAnswer = (answer: string) => {
  answers.value.push(answer);
  let nextQuestion: any = questions;
  answers.value.forEach((a) => {
    nextQuestion = nextQuestion[a];
  });
  if (nextQuestion?.a) {
    false && console.log(nextQuestion);
    if (nextQuestion.a == 'fallback') {
      showFallback.value = true;
      showQuestion.value = false;
      return;
    }
    result.value = results[nextQuestion.a];
    showResult.value = true;
    showQuestion.value = false;
    return;
  } else {
    currentQuestionNo.value = nextQuestion.next_no;
    currentQuestion.value = nextQuestion.q;
  }
};

const restartQuiz = () => {
  showQuestion.value = true;
  showResult.value = false;
  showFallback.value = false;
  answers.value = [];
  result.value = undefined;

  currentQuestionNo.value = 1;
  currentQuestion.value = questions.q;
};
</script>
