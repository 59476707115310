<script lang="ts" setup>
import { ref, onMounted, nextTick, onBeforeUnmount} from 'vue';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref();
const videos_content = ref<Video[]>([]);

const menuItems = ref<any>([]);

interface Video {
  title: string;
  description: string;
  external_link: string;
  youtube_facebook_embed_link: string;
  secondary_nav_menu_title: string;
}

onMounted(()=>{

  if(props.wordpressData?.videos_content) {
    videos_content.value = props.wordpressData.videos_content;
    false && console.log('videos_content', videos_content.value);
    menuItems.value = videos_content.value.map((x, index) => {
      return {
        label: x?.secondary_nav_menu_title ?? '',
        id: 'video_section_' + index,
      };
    });
  }
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }

  nextTick(() => {

    // Add <sup> click listener
    const aboutDesc = document.getElementById('qm-explore-online');
    if(aboutDesc){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#qm-explore-online sup');
      supElements.forEach((supElement) => {

        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('qm-explore-online');

  if(burningJossCard){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#qm-explore-online sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});

</script>

<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :qm-navigation="true"
    />
    <qm-secondary-navigation
      :menu-items-data="menuItems"
      :not-translate="true"
    />

    <section id="qm-explore-about">
      <div class="tw-relative">
        <picture>
          <img
            :src="$cdn('images/qm2024/banner.scaled.jpg')"
            class="tw-w-full tw-object-cover tw-max-h-40 sgTablet:tw-max-h-60 sgDesktop:tw-max-h-[280px]"
          >
        </picture>

        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-qm sgTablet:tw-text-sg-h1-tablet-qm sgDesktop:tw-text-sg-h1-qm tw-pb-1"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
            >
              {{ $t('qm_explore_online') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-mt-1"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold tw-text-sg-sh1-mobile-qm sgTablet:tw-text-sg-sh1-tablet-qm sgDesktop:tw-text-sg-sh1-qm'"
            >
              {{ $t('qm_discover_qingming') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      id="qm-explore-online"
      class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-py-5 sgTablet:tw-py-10 sgTablet:tw-px-10 tw-px-4 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 "

      v-html="wordpressData.description"
    />

    <div class="tw-bg-gradient-to-t tw-from-sg-qm25-light-orange tw-to-sg-qm24-light-green-w60">
      <div
        v-for="(row, index) of videos_content"
        :id="'video_section_'+index"
        :key="index+'video'"
        class="tw-flex tw-flex-col sgTablet2:tw-flex-row tw-px-4 sgTablet:tw-px-0 sgDesktop2:tw-px-20 tw-justify-between "
        :class="{'sgTablet2:tw-flex-row-reverse': index%2 == 1
        }"
      >
        <!-- Video -->
        <div
          class="tw-flex tw-flex-col tw-justify-center sgTablet2:tw-w-[60%] sgDesktop:tw-pl-20 sgDesktop:tw-pr-10 sgTablet2:tw-pl-10 sgTablet2:tw-pr-5 sgTablet2:tw-pb-[20px] tw-pt-[40px] sgTablet:tw-px-[58px] sgDesktop:tw-py-[139px]"
          :class="{'sgDesktop:!tw-pl-10 sgDesktop:!tw-pr-20 sgTablet2:!tw-pl-5 sgTablet2:!tw-pr-10 ': index%2 == 1}"
        >
          <!-- tw-aspect-video -->

          <iframe
            class="tw-mx-auto
          sgDesktop:tw-w-[50vw] sgDesktop2:tw-w-[45vw]
          sgTablet2:tw-w-[50vw] sgTablet:tw-w-[80vw]
          tw-w-[88vw]
          tw-rounded-lg
          tw-aspect-video
          "
            :src="row.youtube_facebook_embed_link"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>


        <!-- Context -->
        <div
          class="tw-flex tw-flex-col sgTablet2:tw-w-[40%] tw-py-5 sgTablet:tw-px-10 sgTablet2:tw-pl-5 sgTablet2:tw-pr-10 sgDesktop:tw-pl-10 sgDesktop:tw-pr-20  tw-justify-center"
          :class="{'sgDesktop:!tw-pl-20 sgDesktop:!tw-pr-10 sgTablet2:!tw-pl-10 sgTablet2:!tw-pr-5': index%2 == 1,
                   'tw-pb-10 sgTablet2:tw-pb-5': index+1 == videos_content.length
          }"
        >
          <h3
            class="tw-text-sg-qm25-green-b60 tw-text-sg-h3-mobile-qm-title sgTablet:tw-text-sg-h3-tablet-qm-title sgDesktop2:tw-text-sg-h3-qm-title tw-text-center sgTablet2:tw-text-left"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ row.title }}
          </h3>
          <div
            class="tw-mt-5 sgDesktop:tw-mt-8 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 underline-anchor-tag explore_online_video_desc"

            v-html="row.description"
          />

          <div class="tw-flex">
            <a
              v-if="!!row.external_link"
              class=" tw-bg-sg-qm24-green hover:tw-bg-sg-qm24-green-w20 active:tw-bg-sg-qm24-green-b20 tw-rounded-[26px] tw-h-[52px] tw-px-6 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer tw-mt-10"
              :href="row.external_link"
              target="_blank"
              rel="noopener"
            >
              <span class="tw-text-ap-white tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 tw-uppercase">{{ $t('qm_watch_here') }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <ap-image-sources
      v-if="!!imageSourcesData && imageSourcesData?.length > 0"
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style lang="scss">
.underline-anchor-tag a {
  text-decoration: underline;
}

#qm-explore-online sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}

.explore_online_video_desc p:not(:first-of-type) {
  margin-top: 24px;
}
</style>

