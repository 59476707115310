<template>
  <div class="tw-px-4 tw-py-5 tablet:tw-px-[40px] tablet:tw-py-[40px] desktop:tw-py-[60px] desktop:tw-px-20">
    <a
      class="tw-flex tw-flex-row tw-w-full tw-group"
      href="https://www.thinkchina.sg/trees-forest-becoming-chinese-singaporean-multicultural-singapore"
      target="_blank"
      rel="noopener"
    >
      <p
        v-if="canShow(query_simulate_date ?? undefined)"
        class="tw-w-[65%] tw-pr-10 tw-text-sg-h1-mobile tw-leading-[48px] tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1 tw-text-sg-sccc4-w40 tw-cursor-pointer group-hover:tw-text-sg-sccc4-w20"
      >
        {{ $t('read_more_from_former_ceo') }}
      </p>
      <p
        v-else
        class="tw-w-[65%] tw-pr-10 tw-text-sg-h1-mobile tw-leading-[48px] tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1 tw-text-sg-sccc4-w40 tw-cursor-pointer group-hover:tw-text-sg-sccc4-w20"
      >
        {{ $t('read_more_from_ceo') }}
      </p>
      <div class="tw-flex tw-flex-col tw-justify-end group-hover:!tw-translate-x-12 group-hover:-tw-translate-y-10 tw-duration-300">
        <img
          class="tw-cursor-pointer"
          :src="$cdn('icons/cta_top_right_pink_arrow.svg')"
          alt="CTA Top Right Arrow"
        >
      </div>
    </a>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    ctaUrl: {
      type: String,
      default: () => null,
    }
  },
  setup() {

    const urlParams = new URLSearchParams(window.location.search);
    const query_simulate_date = urlParams.get('simulate_date');

    // https://singaporen.sg/roots/?simulate_date=2024-06-03T00:00:00%2B08:00
    // https://singaporen.sg/roots/?simulate_date=2024-06-02T23:59:59%2B08:00
    const canShow = (stimulatedCurrentDate?: string) : boolean => {
      // https://app.clickup.com/t/3638597/AT-7957
      const targetDate = new Date('2024-06-03T00:00:00+08:00'); // is not equal (new Date('2024-06-02T16:00:00Z'))
      const currentDate = stimulatedCurrentDate ? new Date(stimulatedCurrentDate) : new Date();

      if (isNaN(currentDate.getTime())) {
        throw new Error('Invalid current date been set');
      }

      if (currentDate >= targetDate) {
        return true;
      }

      return false;
    };

    return {
      canShow,
      query_simulate_date
    };
  }
};


// console.log('');
// console.log('Machine Time');
// console.log(canShow() ? 'true' : 'false');

// console.log('');
// console.log('UTC/Zulu');
// console.log(canShow('2024-06-02T15:59:59Z') ? 'true' : 'false');
// console.log(canShow('2024-06-02T16:00:00Z') ? 'true' : 'false');

// console.log('');
// console.log('UTC+0000');
// console.log(canShow('2024-06-03T00:00:00+0000') ? 'true' : 'false');
// console.log(canShow('2024-06-02T23:59:59+0000') ? 'true' : 'false');

// console.log('');
// console.log('UTC+0800');
// console.log(canShow('2024-06-03T00:00:00+0800') ? 'true' : 'false');
// console.log(canShow('2024-06-02T23:59:59+0800') ? 'true' : 'false');

// console.log('');
// console.log('UTC+0700 Thai');
// console.log(canShow('2024-06-03T00:00:00+0700') ? 'true' : 'false');
// console.log(canShow('2024-06-02T23:59:59+0700') ? 'true' : 'false');

// console.log('');
// console.log('UTC+0900 Japs');
// console.log(canShow('2024-06-03T00:00:00+0900') ? 'true' : 'false');
// console.log(canShow('2024-06-02T23:59:59+0900') ? 'true' : 'false');
</script>
