<template>
  <div class="tw-flex tw-flex-col tw-content-center tw-align-middle tw-justify-center tw-flex-wrap">
    <div
      :class="['tw-rounded-lg tw-w-[342px] tw-h-[192px] tablet:tw-h-[361px] tablet:tw-w-[643px] sgTablet2:tw-h-[446px] sgTablet2:tw-w-[794px]', type=='song' ? 'tw-border-4 tw-border-[#00D6C6] tw-border-solid' : '']"
    >
      <video
        :id="videoId"
        class="videoPlayer video-js tw-p-3 tablet:tw-p-4 desktop:tw-p-8"
        true
        fluid
        fill
      />
    </div>
    <div
      v-if="type=='song'"
      class="songDiv tw-w-[342px] tablet:tw-w-[643px] desktop:tw-w-[794px]"
    >
      <h3>{{ songTitle }}</h3>
      <p>{{ $t('lyrics') }}: {{ lyricsBy }}</p>
      <p>{{ $t('music') }}: {{ musicBy }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import videojs from 'video.js';
import 'videojs-youtube';
import 'video.js/dist/video-js.css';
import { onMounted, ref, Ref, onBeforeMount } from 'vue';
import { cdn } from '../../support/cdn';

const videoId: Ref<any> = ref();

const props = defineProps({
  videoUrl: {
    type: String,
    default: 'https://www.youtube.com/watch?v=rjkj-gabB48',
  },
  posterUrl: {
    type: String,
    default: 'videos_thumbnails/Songs/The_Sparrow_with_a_Bamboo_Twig.jpg',
  },
  lyricsBy: {
    type: String,
    default: 'Liang Wern Fook',
  },
  musicBy: {
    type: String,
    default: 'Liang Wern Fook',
  },
  songTitle: {
    type: String,
    default: 'The Sparrow with a Bamboo Twig',
  },
  type: {
    type: String,
    default: 'song',
  }
});

onBeforeMount(() => {
  generateRandomId();
});

onMounted(() => {
  initVideoPlayer();
});

function generateRandomId() {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  videoId.value = result;
}

async function initVideoPlayer() {
  var options = {
    autoplay: false,
    preload: true,
    controls: true,
    techOrder: ['youtube'],
    sources: [
      {
        type: 'video/youtube',
        src: props.videoUrl,
      },
    ],
    poster: cdn(props.posterUrl),
  };

  const player = videojs(videoId.value, options);
  // false && console.log(player, 'check init player');

  let getPlayer = videojs.getPlayer(videoId.value);


  const videoElem = document.getElementById(videoId.value);
  videoElem?.classList.add(props.type);

  false && console.log('getPlayer', getPlayer);
  if (!getPlayer) {
    false && console.log('videoPlayer error');
    return;
  }
}
</script>

<style>
.videoPlayer {
  height: 100% !important;
}

.videoPlayer .vjs-poster {
  background-size: cover !important;
  overflow: hidden;
}

.videoPlayer .vjs-poster img {
  object-fit: cover !important;
}

.videoPlayer.song , .video-js.song .vjs-tech,
.videoPlayer.song .vjs-poster
{
  border-radius: 5px !important;
}

.videoPlayer .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  border-radius: 100% !important;
  border: none !important;
  height: 80px !important;
  width: 80px !important;
}

@media (max-width:500px){
  .videoPlayer .vjs-big-play-button {
    top: 40% !important;
  }
}

.videoPlayer.song .vjs-big-play-button {
  background-color: #00d6c6 !important;
}

.videoPlayer .vjs-big-play-button .vjs-icon-placeholder:before {
  font-size: 48px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.songDiv {
  margin: auto;
  padding: 20px 24px;
}

.songDiv h3 {
  font-size: 24px;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 12px;
}
.songDiv p {
  font-size: 12px;
  font-weight: 400;
}

.songDiv p:first-child {
  padding-bottom: 10px;
}

.songDiv p:last-child {
  padding-bottom: 20px;
}
.videoPlayer .vjs-big-play-button {
  display: flex !important;
}

.videoPlayer.vjs-has-started .vjs-big-play-button {
  display: none !important;
}
</style>
