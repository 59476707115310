<template>
  <div
    class="tw-w-full sgDesktop:tw-h-[588px]"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <swiper
      id="ma-home-swiper"
      :slides-per-view="'auto'"
      :center-insufficient-slides="true"
      :space-between="12"
      :breakpoints="{
        '640': {
          spaceBetween: 16,
        }
      }"
      style="height: 588px;"
      :slides-offset-before="16"
      :slides-offset-after="16"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in data"
        :key="index"
        :class="[
          'tw-max-w-max',
        ]"
      >
        <!-- Content -->
        <div
          class="tw-relative tw-w-[340px] tw-h-[588px] tw-flex tw-flex-col tw-justify-end tw-items-center tw-rounded-md"
          :style="{
            backgroundImage: `url(${ item.image })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }"
        >
          <div
            :id="`panel-${index}`"
            :class="[
              'tw-w-full tw-h-0',
              'tw-absolute tw-top-0',
              'tw-bg-white tw-bg-opacity-90 tw-rounded-md',
              'tw-p-3'
            ]"
          >
            <p
              id="sup-handler-container"
              class="
                tw-font-akkurat
                tw-tracking-wider
              "
              v-html="checkLang()==='en' ? item.description_en : item.description_cn"
            />
          </div>

          <div
            :class="[
              'tw-cursor-pointer',
              'tw-w-full tw-h-[44px]',
              'tw-absolute tw-bottom-0',
              'tw-gap-1',
              'tw-flex tw-flex-column tw-justify-center tw-items-center',
              'tw-bg-white tw-bg-opacity-90 tw-rounded-b-md',
            ]"
            @click="handlePanelClick('panel-'+index)"
          >
            <img
              v-if="openPanel.includes('panel-'+index)"
              :src="$cdn('icons/ma/ma_arrow_drop_down.svg')"
            >
            <img
              v-else
              :src="$cdn('icons/ma/ma_arrow_drop_up.svg')"
            >
            <p
              :class="[
                'tw-text-sg-ma-bt1 tw-text-center tw-tracking-wider tw-uppercase',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ openPanel.includes('panel-'+index) ? $t('ma_about_typical_mooncakes_read_less') : $t('ma_about_typical_mooncakes_read_more') }}
            </p>
          </div>
        </div>

      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

import gsap from 'gsap';
import { nextTick } from 'process';
import { checkLang } from '../../support/helper';


const openPanel = ref<string[]>([]);

type Post = {
  image: any,
  description_en: string,
  description_cn: string,
};

const props = defineProps<{
  data: Post[],
  imageSourceOpen: boolean,
}>();

const emit = defineEmits(['update:imageSourceOpen']);

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

onMounted(() => {
  const panels = document.querySelectorAll('[id^="panel"]');
  panels.forEach(panel => {
    gsap.set(panel, { height: 0, y: 588 });
  });
});

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const handlePanelClick = (id:string) => {
  const panelElement = document.getElementById(id);
  if (openPanel.value.includes(id)) {
    openPanel.value = openPanel.value.filter((item) => item !== id);
    gsap.to(panelElement, { height: 0, y: 588, duration: 0.3 });
  } else {
    openPanel.value.push(id);
    gsap.fromTo(panelElement, { height: 0, y: 588 }, { height: 588, y: 0, duration: 0.3 });
  }
};

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

const mouseState = ref<'leave' | 'enter'>('leave');
const onMouseEnter = () => {
  mouseState.value = 'enter';
};
const onMouseLeave = () => {
  mouseState.value = 'leave';
};

const handleSupClick = () => {
  imageSourceOpen.value = true;
};

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){

      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

</script>

<style scoped lang="scss">
#ma-home-swiper.swiper {
  @apply tw-h-[512px] sgDesktop:tw-h-[554px];
}

#ma-home-swiper .swiper-wrapper {
  @apply tw-h-[512px] sgDesktop:tw-h-[554px];
}

#ma-home-swiper.swiper:deep(.swiper-wrapper){
  transition-timing-function: linear !important;
}

#sup-handler-container:deep(sup){
  color: #0366D8;
  cursor: pointer;
}

#sup-handler-container:deep(a){
  cursor: pointer;
  text-decoration: underline;
}

</style>
