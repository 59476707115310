<template>
  <div id="exhibition-nav-bar">
    <div
      class=" tw-flex  tw-flex-row tw-justify-evenly tw-bg-gradient-to-t tw-h-[72px] tablet:tw-h-[80px] tw-w-full tw-from-black tw-bg-opacity-15"
    >
      <div class="tabs tw-w-full tw-relative tw-py-4 tablet:tw-py-5">
        <div
          class="scroll-arrow tw-text-ap-white tw-left-0 tw-px-2 hover:tw-bg-ap-nero/60 hover:tw-bg-opacity-10 tw-z-10 tw-pb-2 tw-hidden tablet:tw-hidden"
          :class="{ '!tw-flex tablet:!tw-hidden': tabsScrollPosition > 0 && !hideArrow}"
          @click="scrollTabs('left')"
        >
          &lt;
        </div>
        <div
          class="tabs-container scrollbar-hide tw-text-ap-white tw-overflow-x-auto tw-flex tw-flex-row tw-justify-start tablet:tw-justify-evenly tw-w-full tw-px-4 tw-h-[40px]"
          @touchstart="hideArrow=true"
          @touchend="hideArrow=false;updateScrollPosition()"
        >
          <a
            v-for="tab in tabs"
            :key="tab"
            class="tw-min-w-[170px] tw-uppercase tw-text-center"
            :class="{ active: modelValue === tab }"
            :href="handleClickNav(tab)"
            target="_self"
          >
            {{ $t(`${tab}`) }}
          </a>
        </div>

        <div
          class="scroll-arrow tw-text-ap-white tw-right-0 tw-px-2 hover:tw-bg-ap-nero/60 hover:tw-bg-opacity-10 tw-z-10 tw-flex tablet:tw-hidden"
          :class="{ '!tw-hidden tablet:!tw-hidden': isReachEnd || hideArrow}"
          @click="scrollTabs('right')"
        >
          &gt;
        </div>
      </div>
    </div>
  </div>
</template>
<!-- :class="{'tw-hidden': isFixed}" -->

<script lang="ts" setup>
import { ref, Ref, onMounted, onBeforeUnmount, toRefs, computed, nextTick, } from 'vue';
import { useI18n } from 'vue-i18n';
import { langRedirect } from '../../support/langRedirect';

const hideArrow = ref(false);
const tabsContainer:Ref<any> = ref(null);
const tabsScrollPosition:Ref<number> = ref(0);

const isFixed:Ref<boolean> = ref(false);
const isFooterInView = ref(false);

const { t, locale } = useI18n();

const props = defineProps<
    { modelValue: string }
  >();

const emit = defineEmits(['update:modelValue']);


const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

onMounted( async() => {
  const getTabsContainer = document.querySelector('.tabs-container');
  tabsContainer.value = getTabsContainer;
  window.addEventListener('scroll', handleScroll);

  // tabsContainer.value.addEventListener('scroll', updateScrollPosition);

  // const footerSection = document.getElementById('exhibition-footer');
  // if (footerSection) {
  //   observer.observe(footerSection);
  // }

  // gsap.registerPlugin(ScrollTrigger);


});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  const footerSection = document.getElementById('exhibition-footer');


  if (footerSection) {
    observer.unobserve(footerSection);
  }
});

const handleScroll = () => {
  const footer = document.getElementById('exhibition-footer');
  const navBar = document.getElementById('exhibition-nav-bar');

  if (footer && navBar) {
    const rect = footer.getBoundingClientRect();
    const rect1 = navBar.getBoundingClientRect();

    const isAbove = rect1.bottom <= rect1.top;

    // isFixed.value = rect.top > 0;
  }
};
const footerSection = document.getElementById('exhibition-footer');

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    isFooterInView.value = entry.isIntersecting;
  });
}, {
  root: footerSection,
  rootMargin: '10px',
  threshold: 0.01, // Adjust the threshold as needed
});



const isReachEnd = computed(() => {
  let offsetWidth;
  let scrollWidth;
  if (!!tabsContainer.value){
    offsetWidth = tabsContainer.value?.offsetWidth;
    scrollWidth = tabsContainer.value?.scrollWidth;
  }
  return tabsScrollPosition.value + offsetWidth >= scrollWidth;
});


const scrollTabs = (direction: string) => {
  const container = tabsContainer.value;
  const containerWidth = tabsContainer.value.offsetWidth;
  const scrollWidth = tabsContainer.value.scrollWidth;


  const scrollAmount = direction === 'left' ? -(scrollWidth - containerWidth) : containerWidth;
  container.scrollLeft += scrollAmount;
  updateScrollPosition();
};

const updateScrollPosition = () => {

  if (tabsContainer.value) {
    tabsScrollPosition.value = tabsContainer.value.scrollLeft;
  }

};

const handleClickNav = (tab: string) => {
  let value;
  if (tab === 'general_information') {
    value = 'general-information';
  } else if (tab === 'singaporen_m') {
    value = 'singaporen';
  } else if (tab === 'gallery_etiquette') {
    value = 'gallery-etiquette';
  } else {
    value = 'about';
  }
  return langRedirect(`/exhibition/${value}/`);
};

const tabs = ['about', 'general_information', 'singaporen_m', 'gallery_etiquette'];

</script>

<style scoped>

.tabs {
  width: 100%;
  /* background: linear-gradient(to to, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));  */
}
.tabs a {
  background: none;
  border: none;
  color: white;
  padding: 12px 16px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.35s, color 0.35s, border-radius 0.35s;
}
.tabs a.active {
  background-color: #8c12d4;
  color: white;
  border-radius: 26px;
}

.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}
.scroll-arrow.left {
  left: 0;
}

.scroll-arrow.right {
  right: 0;
}


.tabs-container {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.tabs-container::-webkit-scrollbar {
  width: 0; /* WebKit */
}

.tabs-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* WebKit */
}

</style>
