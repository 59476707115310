<template>
  <!-- Modal Overlay -->
  <div
    id="modal"
    class="tw-fixed tw-hidden tw-inset-0 tw-bg-ap-white-smoke tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[999] tw-overflow-hidden"
    :class="{'!tw-flex': modelValue == true}"
  >

    <!-- Modal Content -->
    <div class="tw-w-5/6 tablet:tw-w-4/6 desktop:tw-w-2/6 sgDesktop2:tw-max-w-[480px]">
      <!-- Cancel  -->
      <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-2">
        <div
          class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
          @click="handleModalClose"
        >
          <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
            {{ $t('modal_close') }}
          </p>
          <img
            :src="$cdn('icons/cancel_icon.svg')"
            alt="cancel_icon"
          >
        </div>
      </div>

      <!-- LightBox -->
      <div class="tw-bg-white tw-rounded-xl tw-w-full light-box-shadow tw-flex tw-flex-col tw-max-h-[512px] sgTablet2:tw-max-h-[512px] sgDesktop:tw-max-h-[512px] sgDesktop2:tw-max-h-[580px] tw-min-h-[40vh] tw-overflow-y-auto tw-overscroll-none">
        <!-- Header -->
        <div class="tw-flex tw-flex-row tw-h-20 tw-p-4">
          <!-- Avatar -->
          <!-- <div class="tw-mr-4"> -->
          <!-- Mock Avatar, change to prof pic later -->
          <!-- <img
              :src="$cdn('icons/avatar_icon.svg')"
              alt="avatar_icon"
              class="tw-w-12 tw-h-12"
            > -->
          <!-- </div> -->
          <div class="tw-flex tw-flex-col tw-text-sg-label-3">
            <div class="tw-text-ap-night-rider tw-font-light">
              {{ lightBoxSrc?.social_platform ?? '' }}
            </div>
            <a
              class="tw-text-ap-nero tw-text-sg-label-bold-3 hover:tw-underline"
              :href="lightBoxSrc?.social_link"
              target="_blank"
              rel="noopener"
            >
              {{ `@${lightBoxSrc?.username ?? ''}` }}
            </a>
          </div>
        </div>

        <!-- Image Box -->
        <div class="tw-flex tw-flex-center tw-justify-center">
          <a
            :href="lightBoxSrc?.social_link"
            target="_blank"
            rel="noopener"
          >
            <img
              :src="lightBoxSrc?.image ?? 'https://picsum.photos/1920/1080'"
              alt="Lightbox image"
              class="tw-self-center tw-w-full tw-min-h-[200px] tw-object-cover"
            >
          </a>
          <!-- tw-max-h-[42.5vh]  -->
        </div>


        <!-- Description -->
        <div class="tw-p-4">
          <div class="tw-text-ap-night-rider tw-text-sg-label-3 tw-break-words">
            {{
              lightBoxSrc?.description ?? '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { onMounted, computed, onBeforeUnmount } from 'vue';

onMounted(() => {
  // console.log('check onMounted');
  // console.log('check image', props.lightBoxSrc);
});

onBeforeUnmount(() => {
});



const props = defineProps<{
  modelValue: boolean,
  lightBoxSrc: any
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

watch(() => modelValue.value, () => {
  if(modelValue.value === true)
  {
    document.body.style.overflow = 'hidden'; // Disable body scrolling when modal is open
  } else {
    document.body.style.overflow = ''; // Enable body scrolling when modal is closed
  }
});

const handleModalClose = () => {
  modelValue.value = false;
};


</script>

<style>
.light-box-shadow {

/* Hard Shadow */
    box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.50);}
</style>
