<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-white tw-bg-opacity-95 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{'!tw-flex': modelValue == true}"
    >

      <!-- Modal Content -->
      <div class="tw-mx-4 sgTablet:tw-mx-10 sgDesktop:tw-mx-20 tw-my-5">
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div
            class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
            @click="handleModalClose"
          >
            <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div class="tw-w-full sgDesktop:tw-w-[680px] tw-h-full   tw-max-w-[600px] sgDesktop:tw-max-w-[680px] tw-mx-auto tw-relative">
          <button
            class="sgDesktop:tw-block tw-hidden tw-rounded-full tw-h-[40px] tw-min-w-[40px] tw-text-white tw-bg-[#FF9300] tw-text-[20px] tw-text-center disabled:tw-opacity-25 -tw-translate-y-1/2 tw-absolute tw-top-1/2 -tw-left-[60px]"
            :disabled="index == 0"
            @click="index--"
          >
            &lt;
          </button>

          <button
            class="sgDesktop:tw-block tw-hidden tw-rounded-full tw-h-[40px] tw-min-w-[40px] tw-text-white tw-bg-[#FF9300] tw-text-[20px]  tw-text-center  disabled:tw-opacity-25 -tw-translate-y-1/2 tw-absolute tw-top-1/2 -tw-right-[60px]"
            :disabled="index == data?.images?.length - 1"
            @click="index++"
          >
            &gt;
          </button>

          <h2
            class="tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-text-center tw-tracking-wider"
          >
            {{ checkLang()=='en'? data?.title : data?.title_cn }}
          </h2>

          <!-- Image Box -->
          <div class="tw-mt-5">

            <img
              ref="imageRef"
              class="tw-object-contain tw-w-auto tw-object-center tw-max-h-[min(600px,100vh-300px)] sgDesktop:tw-max-h-[min(680px,100vh-300px)] tw-rounded-lg tw-mx-auto"
              :style="{
                boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A'
              }"
              :src="$cdn(checkLang()=='en'?data?.images[index]?.link:data?.images[index]?.link_cn)"
            >
          </div>
          <div
            ref="descRef"
            :style="{
              '--text-width': '600px'
            }"
            class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-text-center tw-text-[#1A1A1A] tw-mt-5  tw-tracking-wider tw-min-h-[48px] tw-w-w-full tw-mx-auto"
          >
            {{ checkLang()=='en'? data?.images?.[index]?.desc : data?.images?.[index]?.desc_cn }}
          </div>

          <div class="tw-flex tw-mt-5 tw-justify-center">
            <button
              class="tw-block sgDesktop:tw-hidden tw-rounded-full tw-h-[40px] tw-min-w-[40px] tw-text-white tw-bg-[#FF9300] tw-text-[20px] tw-text-center disabled:tw-opacity-25"
              :disabled="index == 0"
              @click="index--"
            >
              &lt;
            </button>
            <div class="tw-px-[40px] tw-items-center tw-flex">
              <p
                class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-flex-nowrap"
                :style="{minWidth: '26px'}"
              >
                {{ `${index+1} / ${data?.images?.length}` }}
              </p>
            </div>
            <button
              class="tw-block sgDesktop:tw-hidden tw-rounded-full tw-h-[40px] tw-min-w-[40px] tw-text-white tw-bg-[#FF9300] tw-text-[20px]  tw-text-center  disabled:tw-opacity-25"
              :disabled="index == data?.images?.length - 1"
              @click="index++"
            >
              &gt;
            </button>
          </div>


        </div>


      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { onMounted, computed,ref,onBeforeUnmount } from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  modelValue: boolean,
  data:any,
}>();
const index = ref(0);
const emit = defineEmits(['update:modelValue','onShow','onHide']);
const imageRef = ref<HTMLElement | null>(null);
const descRef = ref<HTMLElement | null>(null);
const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if(!newVal) {
    emit('onHide');
    index.value = 0;
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  }else{
    emit('onShow');
    recalculateWidth();
    index.value = 0;
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');

  }
});

onMounted(() => {


  window.addEventListener('resize', recalculateWidth);
});
onBeforeUnmount(()=>{
  window.removeEventListener('resize', recalculateWidth);
});

function recalculateWidth(){
  requestAnimationFrame(()=>{
    if(imageRef.value){
      let width = imageRef.value.getBoundingClientRect().width;
      descRef.value?.style.setProperty('--text-width', `${width}px`);
    }
  });
}
</script>
