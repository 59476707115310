<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { ref} from 'vue';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');
const menuItems = [
  {
    label: 'ws_about_menu_winter_solstice_meaning',
    id   : 'ws_about_menu_winter_solstice_meaning'
  },
  {
    label: 'ws_about_menu_winter_solstice_in_the_past',
    id   : 'ws_about_menu_winter_solstice_in_the_past'
  },
  {
    label: 'ws_about_menu_winter_solstice_in_singapore',
    id   : 'ws_about_menu_winter_solstice_in_singapore'
  },
];


const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};


onBeforeMount(()=>{
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

const pageBreakHeight1 = ref(0);
const pageBreakHeight2 = ref(0);
const pageBreak1 = ref<HTMLElement | null>(null);
const pageBreak2 = ref<HTMLElement | null>(null);
const highlightContainer = ref(0);

function updatePageBreakHeight() {
  if (pageBreak1.value) {
    pageBreakHeight1.value = pageBreak1.value.getBoundingClientRect().height;
  }
  if (pageBreak2.value) {
    pageBreakHeight2.value = pageBreak2.value.getBoundingClientRect().height;
  }

  const container = document.getElementById('sg-highlight-container');
  if (container) {
    if (window.innerWidth < 1440) {
      highlightContainer.value = container.getBoundingClientRect().height;
    }else{
      highlightContainer.value = 0;
    }
  }
}

onMounted(() => {
  window.addEventListener('load', updatePageBreakHeight);
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
  pageBreak1.value = document.getElementById('page-break-1');
  pageBreak2.value = document.getElementById('page-break-2');
  updatePageBreakHeight();
  window.addEventListener('resize', updatePageBreakHeight);
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  window.removeEventListener('resize', updatePageBreakHeight);
  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = true;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});
</script>

<template>
  <div
    class="tw-overflow-hidden"
    :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ws-navigation="true"
    />
    <ws-secondary-navigation
      :menu-items-data="menuItems"
    />
    <!-- Banner -->
    <div class="tw-relative tw-tracking-wider">
      <picture>
        <div
          :style="{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${$cdn('images/ws2024/banner/ws-subpage-hero-banner.jpg')}) lightgray 50% / cover no-repeat`
          }"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        />
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-ws-mobile-h1 sgTablet:tw-text-sg-ws-tablet-h1 sgDesktop:tw-text-sg-ws-desktop-h1 tw-pb-1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_about_title') }}
          </div>
        </div>
      </div>
    </div>
    <!-- description -->
    <section
      id="ws_about_menu_winter_solstice_meaning"
      :class="[
        'tw-relative',
        'tw-pb-5 tw-pt-10',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
      ]"
    >
      <div
        :class="[
          'tw-w-full tw-max-w-[600px]',
          'tw-pb-4 tw-text-sg-ws24-maroon',
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-center',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_about_title_1') }}
      </div>
      <div
        id="sup-handler-container"
        class="
          about-header-description
          tw-max-w-[600px]
          tw-w-full
          tw-text-sg-ws-body
          tw-text-center
          [&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]
        "
        v-html="wordpressData.description"
      />
    </section>

    <!-- Winter Solstice in the Past -->
    <section
      id="ws_about_menu_winter_solstice_in_the_past"
      :class="[
        'tw-relative tw-flex tw-flex-col',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pb-10',
        // 'tw-pt-[56px] sgTablet:tw-pt-[80px] sgDesktop:tw-pt-[100px]',
        'tw-gap-5 sgTablet2:tw-gap-8 tw-justify-center tw-items-center'
      ]"
      :style="{
        background: `linear-gradient(0deg, #FFF 0%, rgba(242, 222, 207, 0.90) 50%), url(${$cdn('images/ws2024/about/winter-solstice-in-the-past/bg.jpg')}) lightgray 50% / cover no-repeat`,
        'padding-top': pageBreakHeight1 + 'px'
      }"
    >
      <img
        id="page-break-1"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-about-page-break-1.png')"
      >
      <div
        :class="[
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-text-center',
          'tw-pt-10',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_about_title_2') }}
      </div>
      <div
        :class="[
          'tw-max-w-[800px] tw-flex sgTablet:tw-gap-10 tw-gap-5',
          'tw-flex-col sgTablet2:tw-flex-row',
          'tw-justify-center tw-items-center'
        ]"
      >
        <img
          :class="[
            'tw-w-[280px]'
          ]"
          :src="$cdn('images/ws2024/about/winter-solstice-in-the-past/girl-dancing.png')"
        >
        <div
          id="sup-handler-container"
          class="tw-text-sg-ws-body tw-text-center sgTablet:tw-text-left"
          v-html="$t('ws_about_desc_2_1')"
        />
      </div>
      <div
        :class="[
          'tw-hidden',
          'tw-max-w-[800px] tw-flex sgTablet:tw-gap-10 tw-gap-5',
          'tw-justify-center tw-items-center',
          'tw-flex-col-reverse sgTablet2:tw-flex-row',
          'sgTablet2:tw-pt-2',
        ]"
      >
        <div
          id="sup-handler-container"
          class="tw-text-sg-ws-body tw-text-center sgTablet:tw-text-left"
          v-html="$t('ws_about_desc_2_2')"
        />
        <img
          :class="['tw-w-[260px]']"
          :src="$cdn('images/ws2024/about/winter-solstice-in-the-past/soup.png')"
        >
      </div>
    </section>

    <!-- Stamp Highlight -->
    <section
      :class="[
        'tw-hidden',
        'tw-relative tw-w-full tw-pt-[60px] sgDesktop2:tw-pt-0',
        'tw-flex tw-items-center tw-justify-center',
        'tw-px-4 sgTablet:tw-px-0'
      ]"
    >
      <img
        :class="[
          'tw-absolute tw-w-[182px]',
          'sgDesktop2:tw-mr-[750px] tw-mr-0',
          // 'sgDesktop2:tw-mt-0 sgTablet:tw-mt-[-180px] tw-mt-[-220px]'
        ]"
        :src="checkLang() == 'en' ? $cdn('images/ws2024/stamp/singaporen_stamp_en.png') : $cdn('images/ws2024/stamp/singaporen_stamp_cn.png')"
        :style="{
          filter: 'drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.3))',
          marginTop: `${-highlightContainer*0.95}px`
        }"
      >
      <div
        id="sg-highlight-container"
        :class="[
          'tw-text-white tw-bg-sg-ws24-maroon',
          'tw-rounded-md',
          'sgDesktop2:tw-pt-0 tw-pt-[60px]',
          { 'sgDesktop2:tw-pl-[120px]' : checkLang() == 'en' },
          { 'sgDesktop2:tw-pl-[140px] sgDesktop2:tw-pr-[80px]' : checkLang() != 'en' },
        ]"
      >
        <div
          class="tw-text-sg-ws-body"
          :class="[
            'tw-max-w-[660px]',
            'tw-py-4 tw-px-5',
            'tw-text-sg-ws-body',
            'tw-text-center sgTablet:tw-text-left',
          ]"
        >
          {{ $t('ws_about_highlight') }}
        </div>
      </div>
    </section>


    <!-- Winter Solstice in Singapore -->
    <section
      id="ws_about_menu_winter_solstice_in_singapore"
      :class="[
        'tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-5 sgDesktop:tw-gap-10',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-pt-[80px] tw-pb-[60px]',
      ]"
    >
      <div
        :class="[
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-text-center',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_about_title_3') }}
      </div>
      <div
        :class="[
          'tw-max-w-[800px] tw-flex sgTablet:tw-gap-10 tw-gap-5',
          'tw-justify-center tw-items-center',
          'tw-flex-col-reverse sgTablet2:tw-flex-row',
        ]"
      >
        <div
          id="sup-handler-container"
          v-html="$t('ws_about_desc_3_1')"
        />
        <img
          :class="[
            'tw-max-w-[260px] tw-min-w-[260px] tw-rounded-full tw-overflow-hidden tw-border-8',
            'tw-border-[#9B2F3A]'
          ]"
          :src="$cdn('images/ws2024/about/animal-dough.jpg')"
        >
      </div>
      <div
        :class="[
          'tw-max-w-[800px] tw-flex sgTablet:tw-gap-10 tw-gap-5',
          'tw-justify-center tw-items-center',
          'tw-flex-col sgTablet2:tw-flex-row',
        ]"
      >
        <img
          :class="[
            'tw-max-w-[260px] tw-min-w-[260px] tw-overflow-hidden',
          ]"
          :src="$cdn('images/ws2024/about/soup.png')"
        >
        <div
          id="sup-handler-container"
          v-html="$t('ws_about_desc_3_2')"
        />
      </div>
    </section>

    <!-- CTA redirection -->
    <section
      :class="[
        'tw-px-10 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-relative tw-flex tw-justify-center tw-items-center',
        // 'tw-pt-[36px] sgTablet:tw-pt-[60px] sgDesktop:tw-pt-[80px]',
        'tw-pb-10 sgTablet:tw-pb-[60px]',
      ]"
      :style="{
        background: `linear-gradient(90deg, rgba(79, 24, 30, 0.65) 50%, rgba(255, 255, 255, 0.20) 100%), url(${$cdn('images/ws2024/about/redirection.jpg')}) lightgray 50% / cover no-repeat`,
        paddingTop: pageBreakHeight2 + 'px'
      }"
    >
      <img
        id="page-break-2"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full tw-object-cover"
        :src="$cdn('images/ws2024/page-break/ws-about-page-break-2.png')"
      >
      <div
        :class="[
          'tw-w-full tw-max-w-[800px] tw-h-full',
          'tw-flex tw-flex-col tw-justify-end sgTablet:tw-justify-center',
          'tw-text-white',
          'tw-items-center sgTablet2:tw-items-start',
          'tw-pt-5'
        ]"
      >
        <!-- title -->
        <h3
          :class="[
            'tw-text-center sgTablet2:tw-text-left',
            'tw-text-sg-ws-h2-mobile sgTablet:tw-text-sg-ws-h2-tablet sgDesktop:tw-text-sg-ws-h2-desktop',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('ws_about_cta_title') }}
        </h3>
        <!-- description -->
        <p class="tw-text-sg-ws-body tw-mt-1 tw-text-center sgTablet2:tw-text-left">
          {{ $t('ws_about_cta_description') }}
        </p>
        <!-- button -->
        <div class="tw-flex tw-mt-8">
          <a
            :href="$langRedirect('/festivals/winter-solstice/winter-solstice-101', checkLang())"
            target="_blank"
            :class="[
              'tw-text-white tw-bg-sg-ws24-maroon',
              'tw-px-6 tw-py-4',
              'tw-text-sg-ws-bt1',
              'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
              'hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_about_find_out_more') }}
          </a>
        </div>
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style scoped>
#sup-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}

#about_banquet:deep(em){
  font-weight: 400;
}
</style>
