<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />

    <!-- Article Title -->
    <div
      class="tw-flex tw-flex-col tw-px-4 tw-pt-8 tw-pb-5
    tablet:tw-px-10 tablet:tw-pt-[60px] tablet:tw-pb-10
    desktop:tw-px-20 desktop:tw-pt-[68px] desktop:tw-pb-10"
    >
      <p
        class="tw-text-sg-h1 - tw-text-sg-sccc1 tw-pb-[28px]
      tablet:tw-pb-10
      desktop:tw-pb-10"
      >
        {{ wordpressData.the_title }}
      </p>
      <hr class="line1">
    </div>
    <!-- Article Content -->
    <div
      class="tw-flex tw-justify-stretch tw-px-4 tw-py-5
    tablet:tw-pr-10 tablet:tw-pt-10 md:tw-pl-[20vh]
    desktop:tw-pr-20 desktop:tw-pt-10 lg:tw-pl-[40vh]"
    >
      <article
        class="tw-prose tw-w-full tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 prose-em:tw-italic tw-max-w-none"
        v-html="wordpressData.the_content"
      />
    </div>
    <ap-footer />
  </div>
</template>

<script lang="ts">
import { onMounted, ref, toRefs } from 'vue';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {

    const { wordpressData } = toRefs(props);

    onMounted(() => {
      console.log(wordpressData.value);
    });
  },
};
</script>
