<template>
  <Transition name="drawer-fade">
    <div
      v-if="!modelValue"
      class="tw-fixed tw-right-0 tw-top-[calc((100vh-142px)/2)] tw-z-[9999]"
      @click="toggleDrawer"
    >
      <div class="tw-relative tw-inline-block tw-w-9 tw-transition-opacity tw-duration-150">
        <img
          :src="$cdn('icons/qm/primary_navigation_handle_green.png')"
          class="tw-w-9 tw-flex tw-hidden tw-cursor-pointer tw-absolute tw-top-0 tw-left-0"
          style="filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));"
          alt="Navigation Handle Green"
        >
        <img
          :src="$cdn('icons/qm/primary_navigation_handle_light_green.png')"
          class="tw-w-9 tw-flex tw-cursor-pointer tw-absolute tw-top-0 tw-left-0"
          style="filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));"
          alt="Navigation Handle Light Green"
        >
      </div>
    </div>
    <div
      v-else
      class="tw-fixed tw-right-0 tw-top-0 tw-w-[60vw] tablet:tw-w-[45vw] desktop:tw-w-[30vw] tw-h-[100vh] tw-flex tw-flex-row"
    >
      <div
        class="tw-w-9 tw-relative"
        @click="toggleDrawer"
      >
        <img
          :src="$cdn('icons/qm/primary_navigation_handle_white.png')"
          class="tw-w-9 tw-fixed tw-top-[calc((100vh-142px)/2)] tw-cursor-pointer tw-transition-opacity tw-duration-700"
          alt="Navigation Handle"
        >
      </div>
      <div class="tw-bg-white tw-w-full tw-h-full tw-p-10 tw-py-20 tw-gap-y-8 tw-flex tw-flex-col tw-overflow-y-auto">
        <div
          v-for="(item, index) in menuItems"
          :key="index"
          class="tw-text-sg-qm24-green tw-text-sg-button-1 tw-uppercase"
          :class="{'!tw-text-underline': currentRoute === item.link}"
        >
          <span
            v-if="item.isAccordian === false"
          >
            <a
              :href="$langRedirect(item.link, item.isExternal ? 'external': '')"
              :target="item.isExternal ? '_blank' : '_self'"
              rel="noopener"
              :style="{textDecoration: currentRoute === item.link ? 'underline' : 'inherit'}"
              :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold tw-text-sg-yearcount'"
            >{{ `${$t(item.label)}` }}
            </a>
          </span>

          <!-- Accordian Menu -->
          <div
            v-else
            class=""
          >
            <div
              class="tw-flex tw-items-center tw-flex-row tw-group tw-cursor-pointer tw-pb-1"
              :class="{'tw-underline tw-text-sg-qm24-green': accordianActive.includes(item.label)}"
              @click="handleAccordianClick(`${item.label}`)"
            >
              <span
                :class="{'tw-underline': !!item.accordian && item.accordian.map(v => v?.link).includes(currentRoute),'tw-font-notosansbold':checkLang() == 'zh-hans','tw-font-akkuratbold tw-text-sg-yearcount':checkLang() == 'en'}"
              >
                {{ `${$t(item.label)}` }}
              </span>
              <div
                v-if="item.isAccordian === true"
                class="tw-ml-2 tw-align-middle tw-flex tw-items-center"
                @click="handleAccordianClick(`${item.label}`)"
              >
                <inline-svg
                  :src="$cdn('icons/arrow_drop_down.svg')"
                  class="tw-fill-sg-qm24-green tw-h-6 tw-w-6 tw-cursor-pointer"
                  alt="Arrow Right"
                  :class="{'-tw-rotate-180 tw-transition-transform': accordianActive.includes(item.label)}"
                  @click="handleAccordianClick(`${item.label}`)"
                />
              </div>
            </div>
            <div
              v-if="accordianActive.includes(item.label)"
              class="tw-flex tw-flex-col tw-pt-4 tw-text-sg-dw-bt2 tw-text-sg-button-2 tw-uppercase tw-transform tw-transition-transform tw-text-sg-qm24-green tw-gap-y-4 tw-cursor-pointer"
            >
              <a
                v-for="(subItem, subItem_index) of item.accordian"
                :key="subItem.label+subItem_index"
                class="hover:tw-underline"
                :class="[
                  {'hover:tw-no-underline tw-cursor-not-allowed tw-pointer-events-none tw-text-sg-qm24-green-w60 tw-text-opacity-50': subItem.isDisabled, 'tw-underline': currentRoute === subItem.link},
                  { 'tw-font-akkurat' : checkLang() == 'en' },
                  { 'tw-font-notosans' : checkLang() != 'en' },
                ]"
                :href="$langRedirect(subItem.link, subItem.isExternal ? 'external': '')"
                :target="subItem.isExternal ? '_blank' : '_self'"
                rel="noopener"
              >
                {{ `${$t(subItem.label)}` }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted} from 'vue';
import { langRedirect } from '../../support/langRedirect';
import { checkLang } from '../../support/helper';

// const isDrawerOpen = ref<boolean>(false);
const accordianActive = ref<string>('');

const props = defineProps<{
  modelValue: boolean,
}>();

onMounted(() => {
  getCurrentRoute();
  console.log('check currentRoute', currentRoute.value);
});

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const currentRoute = ref<string>('');

const toggleDrawer = () => {
  modelValue.value = !modelValue.value;
};

const handleAccordianClick = (content: string) => {
  if (accordianActive.value === content) {
    accordianActive.value = '';
  } else {
    accordianActive.value = content;
  }
};

const getCurrentRoute = () => {
  const url = window.location.href;
  const parts = url.replace(/^https?:\/\//, '').split('/');

  const extractedPath = '/' + parts.filter(item => item !== '' && item !== '?lang=zh-hans').slice(1).join('/');
  currentRoute.value = extractedPath;
  const accordianFound = menuItems.filter(item => item.isAccordian).filter(item => item.accordian?.map(v => v?.link).includes(extractedPath));
  if(accordianFound.length > 0){
    accordianActive.value = accordianFound[0].label;
  }
};

const menuItems = [
  {
    label: 'qm_home',
    link: '/festivals/qingming',
    isAccordian: false,
    isExternal: false,

  },
  {
    label: 'qm_about',
    link: '/festivals/qingming/about',
    isAccordian: false,
    isExternal: false,

  },
  {
    label: 'qm_origin_stories',
    link: '/festivals/qingming/origin-stories',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'qm_explore_online',
    link: '/festivals/qingming/explore-online',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'qm_qingming_101',
    link: '/festivals/qingming/qingming-101',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'qm_past_year',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: '2023',
        link: checkLang() !== 'zh-hans' ? 'https://festivefever.singaporeccc.org.sg/qingming/archival/2023/' : 'https://festivefever.singaporeccc.org.sg/qingming/archival/2023/zh-hans/',
        isDisabled: false,
        isExternal: true,
      },
    ]
  },
];
</script>
