<template>
  <!-- CNY 24 Calendar -->
  <div
    id="cny24-calendar"
    class="tw-flex-col tw-flex tw-w-[calc(100vw-32px)] sgTablet:tw-w-[calc(100vw-80px)] sgDesktop:tw-w-[calc(min(900px,100vw-160px))] tw-mx-auto tw-py-20 tw-gap-y-2"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
  >
    <!-- Head -->
    <div>
      <div class="tw-bg-sg-cny24-yellow-w20 tw-rounded-t-[40px] tw-h-[24px] tw-w-[40px] tw-mx-auto" />
      <div class="tw-rounded-lg tw-bg-sg-cny24-yellow-w20 tw-p-1.5">
        <div class="tw-bg-sg-cny24-red tw-flex tw-flex-row tw-justify-between tw-p-2.5">
          <div>
            <inline-svg
              :src="$cdn('icons/cny/cny_diamond_icon.svg')"
              class="tw-h-8 tw-w-8"
              alt="CNY Diamond Square"
            />
          </div>
          <div
            class="tw-bg-sg-cny24-white tw-rounded-lg"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            <h4
              class="tw-text-sg-sh3-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-text-sg-cny24-red tw-px-2 sgTablet:tw-px-4 tw-py-1.5"
            >
              {{ $t('cny_calendar_24_guide') }}
            </h4>
          </div>

          <div>
            <inline-svg
              :src="$cdn('icons/cny/cny_diamond_icon.svg')"
              class="tw-h-8 tw-w-8"
              alt="CNY Diamond Square"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="tw-rounded-lg tw-border-sg-cny24-green tw-grid tw-border-2 tw-grid-cols-12">
      <!-- Content 1 -->
      <div class="tw-min-h-[18vh] sgTablet:tw-min-h-[40vh] tw-col-span-12 sgTablet:tw-col-span-4">
        <div class="tw-h-full tw-flex tw-flex-col tw-justify-between">
          <div class="tw-px-4 tw-py-5 tw-hidden sgTablet:tw-flex">
            <ap-wysiwyg-viewer
              :data="activeState?.content_left"
              class="tw-h-[300px] tw-overflow-y-auto"
              :class="{}"
            />
          </div>
          <div class="tw-px-4 tw-py-5 tw-flex sgTablet:tw-hidden tw-justify-center">
            <div
              class="tw-text-sg-cny24-green tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-font-bold tw-text-center"
            >
              {{ $t('cny_calendar_whats_happening') }}
            </div>
          </div>
          <!-- :style="{'max-height': '300px', 'overflow-y': 'auto'}" -->

          <div class="tw-w-full tw-pb-5 tw-flex tw-flex-row tw-justify-center tw-bg-white tw-rounded-lg">
            <a
              class="tw-bg-sg-cny24-red tw-rounded-[26px] tw-h-[40px] tw-uppercase !tw-py-6 tw-flex tw-flex-row tw-items-center !tw-px-6 tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-text-ap-white-smoke tw-cursor-pointer"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              :href="activeExternalLinkLeft"
              rel="noopener"
              target="_blank"
            >
              {{ $t('find_out_more') }}
            </a>
          </div>
        </div>
      </div>

      <!-- Content 2 -->
      <div
        class="tw-min-h-[18vh] sgTablet:tw-min-h-[40vh] tw-col-span-12 sgTablet:tw-col-span-4 tw-flex tw-flex-col tw-justify-between tw-p-5 tw-pt-12 sgTablet:tw-pt-5"
      >
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full">
          <p
            class="tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny tw-text-sg-cny24-green tw-text-center"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ checkLang() == 'en' ? (activeState?.show_day !== 0 ? `DAY` : `EVE`) : `${activeState?.chinese_title}` }}
          </p>
          <p
            class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny tw-text-sg-cny24-green tw-text-center !tw-text-[200px] sgTablet:!tw-text-[140px] sgDesktop:!tw-text-[200px] tw-min-h-[200px] tw-pt-20 tw-leading-[-16px]"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ activeState?.show_day !== 0 ? `${activeState?.show_day}` : `` }}
          </p>
        </div>

        <div class="tw-hidden sgTablet:tw-flex tw-w-full tw-text-center tw-justify-center tw-items-center">
          <inline-svg
            :src="$cdn('images/cny2024/lion-dance.svg')"
            class="tw-w-[120px] tw-h-[120px]"
          />
        </div>
      </div>

      <!-- Content 3 -->
      <div class="tw-min-h-[40vh] tw-col-span-12 sgTablet:tw-col-span-4 tw-jus">
        <div class="tw-h-full tw-flex tw-flex-col tw-justify-between">
          <div class="tw-px-4 tw-py-5">
            <ap-wysiwyg-viewer
              :data="activeState?.content_right"
              class="tw-h-[300px] tw-overflow-y-auto !tw-text-[#1A1A1A]"
              :class="{
                'tw-break-keep': checkLang() == 'en',
              }"
            />
            <!-- :style="{'max-height': '300px', 'overflow-y': 'auto'}" -->
          </div>

          <div class="tw-w-full tw-pb-5 tw-pt-5 tw-flex tw-flex-row tw-justify-center tw-bg-white tw-rounded-lg">
            <a
              rel="noopener"
              target="_blank"
              :href="activeExternalLinkRight"
              class="tw-bg-sg-cny24-red tw-rounded-[26px] tw-h-[40px] tw-uppercase tw-w-fit tw-flex tw-flex-row tw-items-center !tw-py-6 !tw-px-6 tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-text-ap-white-smoke tw-cursor-pointer"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('find_out_more') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Date -->
    <div
      class="tw-rounded-lg tw-border-sg-cny24-green tw-border-2 tw-flex tw-flex-col tw-text-sg-sh2-mobile sgTablet:tw-text-sg-sh2-tablet sgDesktop:tw-text-sg-sh2"
    >
      <table class="tw-text-center">
        <tr
          class="tw-text-sg-cny24-green tw-text-sg"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          <td
            class="tw-px-4 tw-py-1 tw-border-r-2 tw-border-sg-cny24-green tw-w-1/2 tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny"
          >
            {{ activeState?.date }}
          </td>
          <td
            class="tw-px-4 tw-py-1 tw-w-1/2 !tw-font-normal tw-text-[16px]"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ activeState?.day }}
          </td>
        </tr>
        <tr class="tw-bg-sg-cny24-green tw-text-ap-white-smoke">
          <td
            class="tw-border-r-2 tw-border-sg-cny24-green tw-px-4 tw-py-1 !tw-font-normal tw-leading-6"
            :colspan="isMobile ? 2 : 1"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-notosansbold'"
          >
            {{ activeState?.chinese_date }}
          </td>
          <td
            v-if="!isMobile"
            class="tw-px-4 tw-py-1"
            :colspan="isMobile ? 2 : 1"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-notosansbold'"
          >
            {{ activeState?.chinese_day }}
          </td>
        </tr>
        <tr
          v-if="isMobile"
          class="tw-bg-sg-cny24-green tw-text-ap-white-smoke"
        >
          <td
            class="tw-px-4 tw-py-1 tw-border-r-2 tw-border-sg-cny24-green"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            :colspan="isMobile ? 2 : 1"
          >
            {{ activeState?.chinese_day }}
          </td>
        </tr>
      </table>
    </div>

    <!-- Swiper -->
    <div class="tw-flex tw-flex-row">
      <div
        class="tw-hidden sgTablet:tw-flex tw-bg-sg-cny24-green tw-py-8 tw-px-3"
        :class="{ '!tw-bg-sg-cny24-green-w40': disabledPrev }"
      >
        <button
          :disabled="disabledPrev"
          @click="slidePrev"
        >
          <inline-svg
            class="tw-fill-ap-white tw-h-6 tw-w-6"
            :src="$cdn('icons/arrow_chevron_left.svg')"
            alt="Arrow Left"
          />
        </button>
      </div>

      <swiper
        :space-between="6"
        :slides-per-view="'auto'"
        class="calendarSwiper tw-h-[144px]"
        slider-per-group="3"
        @slide-change="checkSlidePosition"
      >
        <swiper-slide
          v-for="(item, index) in datas"
          :key="`calendar_${index}`"
          class="tw-border-2 tw-border-sg-cny24-green tw-cursor-pointer !tw-min-h-0 tw-w-[120px] sgTablet:tw-w-[160px] tw-h-[144px]"
          :class="{ '!tw-bg-sg-cny24-red !tw-border-sg-cny24-red': activeState?.id - 1 === index }"
          @click="onClickCalendarDate(index)"
        >
          <div class="tw-px-4 tw-pt-1 tw-pb-2 tw-flex tw-flex-row tw-h-[144px]">
            <div
              class="tw-bg-sg-cny24-green tw-pl-1 tw-pr-5 !tw-font-bold tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-rounded-sm tw-text-ap-white-smoke tw-w-5 tw-flex tw-flex-wrap !tw-items-center !tw-font-notosansbold !tw-text-center"
              :class="{
                '!tw-bg-sg-cny24-yellow-w20 !tw-text-sg-cny24-red': activeState?.id - 1 === index,
              }"
            >
              {{ item.chinese_title }}
            </div>
            <div
              class="tw-w-full tw-text-sg-cny24-green tw-uppercase tw-text-sg-h4-mobile-cny sgTablet:tw-text-sg-h4-tablet-cny sgDesktop:tw-text-sg-h4-cny tw-text-center tw-flex tw-flex-wrap tw-items-center tw-gap-1 tw-font-notosansbold"
              :class="{
                '!tw-text-sg-cny24-yellow-w20': activeState?.id - 1 === index,
              }"
            >
              <p
                class="tw-w-full tw-font-notosansbold"
                v-html="item.title"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div
        class="tw-hidden sgTablet:tw-flex tw-bg-sg-cny24-green tw-py-4 tw-px-3"
        :class="{ '!tw-bg-sg-cny24-green-w40': disabledNext }"
      >
        <button
          :disabled="disabledNext"
          @click="slideNext"
        >
          <inline-svg
            class="tw-fill-ap-white tw-h-6 tw-w-6"
            :src="$cdn('icons/arrow_chevron_right.svg')"
            alt="Arrow Right"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { checkLang } from '../../support/helper';
import { onBeforeUnmount } from 'vue';

const currentLanguage = ref<'zh-Hans' | 'en'>('en');

// Computed property to determine the font class
const fontClass = computed(() => {
  return currentLanguage.value === 'zh-Hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold';
});

const props = defineProps<{
  data: any;
}>();

let swiperCollection = {};

let disabledNext = ref<boolean>(false);
let disabledPrev = ref<boolean>(true);

const datas = ref<Array<any>>([]);

type CalendarType = {
  id: number;
  title: string;
  chinese_title: string;
  date: string;
  chinese_date: string;
  show_day: number;
  day: string;
  chinese_day: string;
  content_left: string;
  content_middle: string;
  content_right: string;
  external_link_left: string;
  external_link_right: string;
};
const activeState = ref<CalendarType | null>(null);
const isMobile = ref(window.innerWidth <= 680);

const calendar = ref<CalendarType[]>([]);
onMounted(() => {
  false && console.log('check calendar', props.data);
  calendar.value = initCalendar();
  datas.value = calendar.value;
  swiperCollection = document.querySelector('.calendarSwiper').swiper;
  const curr_calendar = calendar.value[0];

  window.addEventListener('resize', updateWindowSize);

  activeState.value = {
    id: curr_calendar?.id,
    date: curr_calendar?.date,
    title: curr_calendar?.title,
    chinese_title: curr_calendar?.chinese_title,
    chinese_date: curr_calendar?.chinese_date,
    show_day: curr_calendar?.show_day,
    day: curr_calendar?.day,
    chinese_day: curr_calendar?.chinese_day,
    content_middle: curr_calendar?.content_middle,
    content_left: curr_calendar?.content_left,
    content_right: curr_calendar?.content_right,
    external_link_left: curr_calendar?.external_link_left,
    external_link_right: curr_calendar?.external_link_right,
  };
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize);
});

const updateWindowSize = () => {
  isMobile.value = window.innerWidth <= 680;
};

const slideNext = () => {
  // false && console.log('slide next', swiperCollection);
  swiperCollection.slideNext();
};

const slidePrev = () => {
  // false && console.log('slide Prev', swiperCollection);

  swiperCollection.slidePrev();
};

const checkSlidePosition = () => {
  // false && console.log(swiperCollection, 'check swiper Collection');
  if (swiperCollection.isBeginning) {
    disabledPrev.value = true;
    disabledNext.value = false;
    // false && console.log('swiperCollection is Begining', swiperCollection);
  } else if (swiperCollection.isEnd) {
    disabledPrev.value = false;
    disabledNext.value = true;
    // false && console.log('swiperCollection is End', swiperCollection);
  } else if (!swiperCollection.isBeginning && !swiperCollection.isEnd) {
    disabledPrev.value = false;
    disabledNext.value = false;
    // false && console.log('swiperCollection is Not begining and end', swiperCollection);
  }
};

const onClickCalendarDate = (index: number) => {
  // false && console.log('check index', index);
  const curr_calendar = calendar.value[index];
  false && console.log('check index', index, curr_calendar);

  activeState.value = {
    id: curr_calendar?.id,
    date: curr_calendar?.date,
    show_day: curr_calendar?.show_day,
    chinese_title: curr_calendar?.chinese_title,
    chinese_date: curr_calendar?.chinese_date,
    day: curr_calendar?.day,
    chinese_day: curr_calendar?.chinese_day,
    content_left: curr_calendar?.content_left,
    content_right: curr_calendar?.content_right,
    external_link_left: curr_calendar?.external_link_left,
    external_link_right: curr_calendar?.external_link_right,
  };
};

const activeExternalLinkLeft = computed(() => (activeState.value ? activeState.value?.external_link_left : ''));

const activeExternalLinkRight = computed(() => (activeState.value ? activeState.value?.external_link_right : ''));

const initCalendar = () => {
  return [
    {
      id: 1,
      title: 'Eve',
      chinese_title: '除夕',
      date: '9 February 2024',
      chinese_date: '2024 年 2 月 9 日',
      show_day: 0,
      day: 'Friday',
      chinese_day: '星期五',
      content_middle: 'EVE',
      content_left: props?.data['eve']?.['content_left'] || '',
      content_right: props?.data['eve']?.['content_right'] || '',
      external_link_left: props?.data['eve']?.['external_link_left'] || '',
      external_link_right: props?.data['eve']?.['external_link_right'] || '',
    },
    {
      id: 2,
      title: 'Day <br/> 1',
      show_day: 1,
      chinese_title: '正月初一',
      date: '10 February 2024',
      chinese_date: '2024 年 2 月 10 日',
      day: 'Saturday',
      chinese_day: '星期六',
      content_middle: 'EVE',
      content_left: props?.data['day_1']?.['content_left'] || '',
      content_right: props?.data['day_1']?.['content_right'] || '',
      external_link_left: props?.data['day_1']?.['external_link_left'] || '',
      external_link_right: props?.data['day_1']?.['external_link_right'] || '',
    },
    {
      id: 3,
      title: 'Day <br/>  2',
      chinese_title: '正月初二',
      show_day: 2,
      date: '11 February 2024',
      chinese_date: '2024年2月11日',
      day: 'Sunday',
      chinese_day: '星期日',
      content_middle: 'EVE',
      content_left: props?.data['day_2']?.['content_left'] || '',
      content_right: props?.data['day_2']?.['content_right'] || '',
      external_link_left: props?.data['day_2']?.['external_link_left'] || '',
      external_link_right: props?.data['day_2']?.['external_link_right'] || '',
    },
    {
      id: 4,
      title: 'Day <br/>  3',
      chinese_title: '正月初三',
      show_day: 3,
      date: '12 February 2024',
      chinese_date: '2024 年 2 月 12 日',
      day: 'Monday',
      chinese_day: '星期一',
      content_middle: 'EVE',
      content_left: props?.data['day_3']?.['content_left'] || '',
      content_right: props?.data['day_3']?.['content_right'] || '',
      external_link_left: props?.data['day_3']?.['external_link_left'] || '',
      external_link_right: props?.data['day_3']?.['external_link_right'] || '',
    },
    {
      id: 5,
      title: 'Day <br/>  4',
      show_day: 4,
      chinese_title: '正月初四',
      date: '13 February 2024',
      chinese_date: '2024 年 2 月 13 日',
      day: 'Tuesday',
      chinese_day: '星期二',
      content_middle: 'EVE',
      content_left: props?.data['day_4']?.['content_left'] || '',
      content_right: props?.data['day_4']?.['content_right'] || '',
      external_link_left: props?.data['day_4']?.['external_link_left'] || '',
      external_link_right: props?.data['day_4']?.['external_link_right'] || '',
    },
    {
      id: 6,
      title: 'Day <br/>  5',
      show_day: 5,
      chinese_title: '正月初五',
      date: '14 February 2024',
      chinese_date: '2024年2月14日',
      day: 'Wednesday',
      chinese_day: '星期三',
      content_middle: 'EVE',
      content_left: props?.data['day_5']?.['content_left'] || '',
      content_right: props?.data['day_5']?.['content_right'] || '',
      external_link_left: props?.data['day_5']?.['external_link_left'] || '',
      external_link_right: props?.data['day_5']?.['external_link_right'] || '',
    },
    {
      id: 7,
      title: 'Day <br/>  6',
      chinese_title: '正月初六',
      show_day: 6,
      date: '15 February 2024',
      chinese_date: '2024年2月15日',
      day: 'Thursday',
      chinese_day: '星期四',
      content_middle: 'EVE',
      content_left: props?.data['day_6']?.['content_left'] || '',
      content_right: props?.data['day_6']?.['content_right'] || '',
      external_link_left: props?.data['day_6']?.['external_link_left'] || '',
      external_link_right: props?.data['day_6']?.['external_link_right'] || '',
    },
    {
      id: 8,
      title: 'Day <br/>  7',
      chinese_title: '正月初七',
      show_day: 7,
      date: '16 February 2024',
      chinese_date: '2024 年 2 月 16 日',
      day: 'Friday',
      chinese_day: '星期五',
      content_middle: 'EVE',
      content_left: props?.data['day_7']?.['content_left'] || '',
      content_right: props?.data['day_7']?.['content_right'] || '',
      external_link_left: props?.data['day_7']?.['external_link_left'] || '',
      external_link_right: props?.data['day_7']?.['external_link_right'] || '',
    },
    {
      id: 9,
      title: 'Day <br/>  8',
      chinese_title: '正月初八',
      show_day: 8,
      date: '17 February 2024',
      chinese_date: '2024 年 2 月 17 日',
      day: 'Saturday',
      chinese_day: '星期六',
      content_middle: 'EVE',
      content_left: props?.data['day_8']?.['content_left'] || '',
      content_right: props?.data['day_8']?.['content_right'] || '',
      external_link_left: props?.data['day_8']?.['external_link_left'] || '',
      external_link_right: props?.data['day_8']?.['external_link_right'] || '',
    },
    {
      id: 10,
      title: 'Day <br/>  9',
      chinese_title: '正月初九',
      show_day: 9,
      date: '18 February 2024',
      chinese_date: '2024 年 2 月 18 日',
      day: 'Sunday',
      chinese_day: '星期日',
      content_middle: 'EVE',
      content_left: props?.data['day_9']?.['content_left'] || '',
      content_right: props?.data['day_9']?.['content_right'] || '',
      external_link_left: props?.data['day_9']?.['external_link_left'] || '',
      external_link_right: props?.data['day_9']?.['external_link_right'] || '',
    },
    {
      id: 11,
      title: 'Day <br/>  10',
      chinese_title: '正月初十',
      date: '19 February 2024',
      show_day: 10,
      chinese_date: '2024 年 2 月 19 日',
      day: 'Monday',
      chinese_day: '星期一',
      content_middle: 'EVE',
      content_left: props?.data['day_10']?.['content_left'] || '',
      content_right: props?.data['day_10']?.['content_right'] || '',
      external_link_left: props?.data['day_10']?.['external_link_left'] || '',
      external_link_right: props?.data['day_10']?.['external_link_right'] || '',
    },
    {
      id: 12,
      title: 'Day <br/>  11',
      chinese_title: '正月十一',
      show_day: 11,
      date: '20 February 2024',
      chinese_date: '2024 年 2 月 20 日',
      day: 'Tuesday',
      chinese_day: '星期二',
      content_middle: 'EVE',
      content_left: props?.data['day_11']?.['content_left'] || '',
      content_right: props?.data['day_11']?.['content_right'] || '',
      external_link_left: props?.data['day_11']?.['external_link_left'] || '',
      external_link_right: props?.data['day_11']?.['external_link_right'] || '',
    },
    {
      id: 13,
      title: 'Day <br/>  12',
      chinese_title: '正月十二',
      show_day: 12,
      date: '21 February 2024',
      chinese_date: '2024 年 2 月 21 日',
      day: 'Wednesday',
      chinese_day: '星期三',
      content_middle: 'EVE',
      content_left: props?.data['day_12']?.['content_left'] || '',
      content_right: props?.data['day_12']?.['content_right'] || '',
      external_link_left: props?.data['day_12']?.['external_link_left'] || '',
      external_link_right: props?.data['day_12']?.['external_link_right'] || '',
    },
    {
      id: 14,
      title: 'Day <br/>  13',
      chinese_title: '正月十三',
      show_day: 13,
      date: '22 February 2024',
      chinese_date: '2024 年 2 月 22 日',
      day: 'Thursday',
      chinese_day: '星期四',
      content_middle: 'EVE',
      content_left: props?.data['day_13']?.['content_left'] || '',
      content_right: props?.data['day_13']?.['content_right'] || '',
      external_link_left: props?.data['day_13']?.['external_link_left'] || '',
      external_link_right: props?.data['day_13']?.['external_link_right'] || '',
    },
    {
      id: 15,
      title: 'Day <br/>  14',
      chinese_title: '正月十四',
      date: '23 February 2024',
      show_day: 14,
      chinese_date: '2024 年 2 月 23 日',
      day: 'Friday',
      chinese_day: '星期五',
      content_middle: 'EVE',
      content_left: props?.data['day_14']?.['content_left'] || '',
      content_right: props?.data['day_14']?.['content_right'] || '',
      external_link_left: props?.data['day_14']?.['external_link_left'] || '',
      external_link_right: props?.data['day_14']?.['external_link_right'] || '',
    },
    {
      id: 16,
      title: 'Day <br/>  15',
      chinese_title: '正月十五',
      date: '24 February 2024',
      show_day: 15,
      chinese_date: '2024 年 2 月 24 日',
      day: 'Saturday',
      chinese_day: '星期六',
      content_middle: 'EVE',
      content_left: props?.data['day_15']?.['content_left'] || '',
      content_right: props?.data['day_15']?.['content_right'] || '',
      external_link_left: props?.data['day_15']?.['external_link_left'] || '',
      external_link_right: props?.data['day_15']?.['external_link_right'] || '',
    },
  ];
};
</script>
<style scoped>
.font-zh {
  @apply tw-font-notosansbold;
}
.font-en {
  @apply tw-font-akkuratbold;
}
</style>
