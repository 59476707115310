<template>
  <div
    class="tw-relative tw-shadow-lg tw-rounded-[20px] tw-bg-white"
    :class="[
      'tw-w-full tw-h-[604px]',
      'tw-rounded-[20px]'
    ]"
    :style="{
      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)',
    }"
  >
    <img
      :class="[
        'tw-w-full tw-object-cover',
        'tw-rounded-t-[20px]'
      ]"
      :style="{ height: isMobile && !isExpanded ?`460px`: `${data.imageHeight}px` }"
      :src="$cdn(data.image)"
      loading="lazy"
    >

    <!-- Content aligned to the bottom -->
    <div
      :class="[
        'box-anim',
        'tw-absolute tw-bottom-0 tw-w-full',
        'tw-flex tw-flex-col tw-rounded-b-[20px]'
      ]"
    >
      <!-- Spacer div to push the content to the bottom -->
      <div class="tw-flex-grow" />
      <img
        class="tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-home-page-break-1.png')"
      >
      <!-- Title -->
      <div
        :class="[
          'tw-bg-white tw-text-sg-ws-h4-mobile sgTablet:tw-text-sg-ws-h4-tablet sgDesktop:tw-text-sg-ws-h4-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-max-w-[800px] tw-pb-5 tw-text-center',
          'sgDesktop:tw-px-[28px]',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
        v-html="data.title"
      />

      <!-- Description -->
      <div
        id="card-handler-container"
        :class="[
          'tw-text-black tw-bg-white tw-text-center',
          'tw-pb-3 sgTablet:tw-pb-[32px]',
          'tw-px-4 sgTablet:tw-px-[28px]',
          'sgTablet:tw-rounded-b-[20px]',
        ]"
        v-html="description"
      />
      <div
        :class="[
          'chev-btn tw-rounded-b-[20px] sgTablet:!tw-hidden tw-pb-4',
        ]"
        @click="onClickMore"
      >
        <img
          :src="$cdn(isExpanded ? 'icons/ws/ws_chevron_down.svg' : 'icons/ws/ws_chevron_up.svg')"
        >
        <div
          :class="[
            'tw-text-sg-ws24-maroon sgDesktop:tw-pb-[22px]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ isExpanded ? $t('ws_winter_solstice_101_less') : $t('ws_winter_solstice_101_more') }}
        </div>
      </div>

    </div>
  </div>

</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import { checkLang } from '../../support/helper';
import { nextTick } from 'process';
interface Item {
  image: string;
  imageHeight: number;
  title: string;
  description: string;
}

const isExpanded = ref(false);
const isMobile = ref(window.innerWidth <= 680);
const textIsTruncated = ref(false);

const props = defineProps<{
  data: Item;
}>();

const emit = defineEmits(['openSources']);

const description = ref(props.data.description);

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeMount(() => {
  removeSupClickHandler();
  window.removeEventListener('resize', onResize);
});


function onResize() {
  // check if mobile
  isMobile.value = window.innerWidth <= 680;
  attachSupClickHandler();
  if (isMobile.value) {
    description.value = truncateHtmlText(props.data.description, 93);
  }else{
    description.value = props.data.description;
  }
}

function emitClickHandler() {
  emit('openSources');
  false && console.log('emitClickHandler');
}

function attachSupClickHandler() {
  nextTick(() => {
    const supElements = document.querySelectorAll('#card-handler-container sup');
    if(supElements?.length > 0){
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', emitClickHandler);
      });
    }
  });
}

function removeSupClickHandler() {
  const supElements = document.querySelectorAll('#card-handler-container sup');
  if(supElements?.length > 0){
    false && console.log('removeSupClickHandler: ', supElements.length);
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', emitClickHandler);
    });
  }
}

/**
 * Truncates an HTML string to a specified maxLength, removing any <p> tags,
 * and re-wrapping the text with <p> tags and adding an ellipsis.
 *
 * @param {string} htmlString - The HTML string to truncate
 * @param {number} maxLength - The maximum length of the truncated string
 * @return {string} The truncated HTML string
 */
function truncateHtmlText(htmlString: string, maxLength = 300) {
  // Remove <p> tags from the HTML string
  const strippedText = htmlString.replace(/<\/?p>/g, '');
  if (strippedText.length <= maxLength) {
    textIsTruncated.value = false;
    return htmlString;
  }
  textIsTruncated.value = true;
  // Limit the text to the specified maxLength
  const limitedText = strippedText.substring(0, maxLength);
  // Re-wrap with <p> tags and add ellipsis
  return `<p>${limitedText}...</p>`;
}


function onClickMore() {
  if (textIsTruncated.value) {
    isExpanded.value = !isExpanded.value;
    if (isExpanded.value) {
      attachSupClickHandler();
      description.value = props.data.description;
    } else {
      description.value = truncateHtmlText(props.data.description, 93);
    }
  }
}
</script>

<style scoped>

.anim {
  transition: height 0.3s ease-in-out;
}

#card-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}

.chev-btn {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
