<template>
  <div
    class="tw-w-[100vw] tw-relative tw-overflow-hidden tw-h-[330px]"
  >
    <swiper
      id="zy-home-swiper"
      :slides-per-view="'auto'"
      :center-insufficient-slides="true"
      :loop="true"
      :autoplay="{ delay: 0, disableOnInteraction: false }"
      :prevent-interaction-on-transition="true"
      :speed="5000"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in extendedData"
        :key="index"
        class="tw-w-[260px] tw-h-[330px] tw-relative"
      >
        <!-- Content -->
        <div class="tw-flex tw-flex-col tw-h-full tw-relative tw-items-center">
          <img
            :src="item.image"
            class="tw-w-full tw-object-contain tw-object-center tw-rounded-sm tw-h-[260px] tw-w-[260px]"
            :style="{filter: 'drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.3))'}"
          >
          <p class="tw-text-sg-caption-desktop-1 tw-mt-5 tw-flex-grow tw-font-hanzipan tw-text-center">
            {{ item.description }}
            <span v-if="item.reference">
              <sup
                class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body  tw-font-akkurat"
                @click="handleOpenImageSourceModal"
              >{{ item.reference }}</sup>
            </span>
          </p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore from 'swiper';
import { ref, computed } from 'vue';
import type { Swiper as SwiperInstance } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Autoplay, FreeMode } from 'swiper/modules';

SwiperCore.use([Autoplay, FreeMode]);

type Post = {
  year: string,
  image: any,
  description: string,
  reference: string
}

const props = defineProps<{
  data: Post[],
  imageSourceOpen: boolean,
}>();

const emit = defineEmits(['update:imageSourceOpen']);

// Duplicate the slides to ensure sufficient number for loop mode
const extendedData = computed(() => {
  const minSlidesForLoop = 20; // Minimum number of slides required for loop
  let data = [...props.data];

  while (data.length < minSlidesForLoop) {
    data = data.concat(props.data);
  }

  return data;
});

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};
</script>

<style scoped lang="scss">
.swiper-wrapper {
  height: 324px !important;
}

.swiper:deep(.swiper-wrapper){
  transition-timing-function: linear !important;
}
</style>
