<template>
  <div
    class="tw-relative"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
  >
    <div class="tw-absolute tw-top-[-50px] tw-left-0 tw-z-10">
      <img
        :src="$cdn('images/cny2024/floatingMountain/floatingMountain.png')"
        class="tw-w-[160px]"
      >
    </div>
    <div class="tw-absolute tw-top-[-50px] tw-right-0 tw-z-10">
      <img
        :src="$cdn('images/cny2024/floatingMountain/floatingMountain2.png')"
        class="tw-w-[160px]"
      >
    </div>
    <span :class="['tw-flex tw-transition-opacity tw-duration-[1500ms]', loadingAnimation]">
      <div class="tw-w-full tw-relative tw-flex tw-flex-col tw-justify-center">
        <div class="tw-relative tw-flex tw-justify-center">
          <img
            class="tw-w-[258px] tw-h-[258px]"
            :src="$cdn('images/loading-animation.gif')"
          >
          <div class="tw-absolute tw-bottom-[60px]">
            <div class="loadingBarContainer">
              <div :class="['loadingBar', loadingBar]" />
            </div>
          </div>
        </div>
        <p class="tw-text-sg-body-1 tw-text-sg-cny24-red tw-text-center tw-relative tw-bottom-[20px]">Loading...</p>
      </div>
    </span>
    <span :class="['tw-block  tw-transition-opacity tw-duration-[1000ms]', mainStorySection]">
      <div class="tw-bg-[#FFE39E] tw-relative">
        <div class="tw-absolute tw-top-[500px] tw-left-0 tw-hidden tablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain3.png')"
            class="tw-w-[160px]"
          >
        </div>
        <div class="tw-absolute tw-bottom-[500px] tw-right-0 tw-hidden tablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain4.png')"
            class="tw-w-[160px]"
          >
        </div>
        <div class="tw-flex tw-flex-row tw-flex-wrap tw-max-w-[600px] tw-m-auto tw-py-10 tw-px-4 tablet:tw-px-7">
          <div class="tw-pb-3 tablet:tw-pb-6 desktop:tw-pb-6 tw-mx-auto">
            <div
              class="tw-font-normal tw-text-center tw-pb-4 tablet:tw-pb-5 tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny tw-h-fit tw-w-fit tw-m-auto tw-text-[#C52600]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ story.title }}
            </div>
            <div
              v-if="story.subtitle"
              class="tw-text-sg-label-2 tw-pb-2 tablet:tw-pb-3 tw-h-fit tw-w-fit tw-m-auto tw-text-[#C52600]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ story.subtitle }}
            </div>
            <div class="tw-text-sg-caption-italic tw-italic tw-w-fit tw-m-auto">
              {{ story.desc }}
            </div>
          </div>

          <div
            v-show="story.id == 1"
            class="tw-flex tw-flex-wrap tw-text-sg-body-1"
          >
            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_nian_paragraph1') }}
            </p>

            <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath1)"
                  class="tw-object-contain"
                >
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%]">
                <p>
                  {{ $t('cny_origin_stories_nian_paragraph2') }}
                </p>
              </div>
            </div>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_nian_paragraph3') }}
            </p>

            <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6">
                <p>
                  {{ $t('cny_origin_stories_nian_paragraph4') }}
                </p>
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0">
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath2)"
                  class="tw-object-contain"
                >
              </div>
            </div>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_nian_paragraph5') }}
            </p>

            <p class="tw-text-sg-caption-italic tw-italic">
              {{ $t('cny_origin_stories_nian_bottom_text') }}
              <br>
              <a
                rel="noopener"
                href="https://blog.nus.edu.sg/linus/2009/01/21/"
                target="_blank"
                class="tw-not-italic tw-underline"
              >
                {{ $t('cny_origin_stories_nian_bottom_text1') }}
              </a>
            </p>
          </div>

          <div
            v-show="story.id == 2"
            class="tw-flex tw-flex-wrap tw-text-sg-body-1"
          >
            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph1') }}
            </p>

            <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img :src="$cdn('images/cny2024/' + story.imagePath1)">
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center">
                <p>
                  {{ $t('cny_origin_stories_zodiac_race_paragraph2') }}
                </p>
              </div>
            </div>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph3') }}
            </p>

            <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6">
                <div v-html="$t('cny_origin_stories_zodiac_race_paragraph4')" />
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0">
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath2)"
                  class="tw-object-contain"
                >
              </div>
            </div>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph5') }}
            </p>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph6') }}
            </p>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph7') }}
            </p>

            <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath3)"
                  class="tw-object-contain"
                >
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%]">
                <p>
                  {{ $t('cny_origin_stories_zodiac_race_paragraph8') }}
                </p>
              </div>
            </div>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph9') }}
            </p>
          </div>

          <div
            v-show="story.id == 3"
            class="tw-flex tw-flex-wrap tw-text-sg-body-1"
          >
            <!-- <div class="tw-text-ap-body-bold tw-w-fit tw-m-auto tw-pb-6">{{ $t('cny_origin_stories_bai_tian_gong_version_1') }}</div> -->
            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_bai_tian_gong_paragraph1') }}
            </p>

            <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath1)"
                  class="tw-object-contain"
                >
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%]">
                <p class="tw-pb-6">
                  {{ $t('cny_origin_stories_bai_tian_gong_paragraph2') }}
                </p>
                <p>
                  {{ $t('cny_origin_stories_bai_tian_gong_paragraph3') }}
                </p>
              </div>
            </div>
            <!-- Version 2 Remove request by client -->
            <!-- <div class="tw-text-ap-body-bold tw-w-fit tw-m-auto tw-pb-6">
              {{ $t('cny_origin_stories_bai_tian_gong_version_2') }}
            </div>

            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_bai_tian_gong_paragraph4') }}
            </p> -->

            <div class="tw-flex tw-flex-wrap tw-pb-6 tw-w-full tw-m-auto tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0">
              <img
                :src="$cdn('images/cny2024/' + story.imagePath2)"
                class="tw-object-contain"
              >
            </div>

            <p class="tw-text-sg-caption-italic tw-italic">
              {{ $t('cny_info_source') }} <br>
              <a
                rel="noopener"
                href="https://sfcca.sg/en/festivals/"
                target="_blank"
                class="tw-not-italic tw-underline"
              > SFCCA - Festivals</a>
            </p>
          </div>

          <div
            v-show="story.id == 4"
            class="tw-flex tw-flex-wrap tw-text-sg-body-1"
          >
            <!-- Version 2 Remove request by client -->
            <!-- <div class="tw-text-ap-body-bold tw-w-fit tw-m-auto tw-pb-6">{{ $t('cny_origin_stories_ren_ri_version_1') }}</div> -->
            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_ren_ri_paragraph1') }}
            </p>

            <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div
                class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6 tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0"
              >
                <img
                  :src="$cdn('images/cny2024/' + story.imagePath1)"
                  class="tw-object-contain"
                >
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%]">
                <p>
                  {{ $t('cny_origin_stories_ren_ri_paragraph2') }}
                </p>
              </div>
            </div>

            <!-- <p class="tw-pb-6">
              {{ $t('cny_origin_stories_ren_ri_paragraph3') }}
            </p> -->

            <!-- <div class="tw-flex tw-flex-wrap tw-pb-6">
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-pr-0 tablet:tw-pr-6">
                <p>
                  {{ $t('cny_origin_stories_ren_ri_paragraph4') }}
                </p>
              </div>
              <div class="tw-w-[100%] tablet:tw-w-[50%] tw-flex tw-justify-center tw-max-h-[300px] tablet:tw-max-h-fit tw-py-6 tablet:tw-py-0">
                <img :src="$cdn('images/cny2024/' + story.imagePath2)">
              </div>
            </div> -->

            <p class="tw-text-sg-caption-italic tw-italic">
              {{ $t('cny_info_source') }}<br>
              <a
                rel="noopener"
                href="https://www.scmp.com/yp/discover/lifestyle/features/article/3071571/happy-birthday-everyone-take-moment-celebrate"
                target="_blank"
                class="tw-not-italic tw-underline"
              >
                South China Morning Post - Happy birthday, everyone! Take a moment to celebrate 'People's Day'</a>
            </p>
          </div>
        </div>
      </div>
      <div
        v-show="story.id == 2"
        class="tw-relative"
      >
        <div class="tw-absolute tw-top-[-50px] tw-right-0">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain2.png')"
            class="tw-w-[160px]"
          >
        </div>
        <div class="tw-absolute tw-top-[500px] tw-left-0 tw-hidden tablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain3.png')"
            class="tw-w-[160px]"
          >
        </div>
        <div class="tw-absolute tw-bottom-[500px] tw-right-0 tw-hidden tablet:tw-flex">
          <img
            :src="$cdn('images/cny2024/floatingMountain/floatingMountain4.png')"
            class="tw-w-[160px]"
          >
        </div>
        <div class="tw-max-w-[600px] tw-m-auto tw-pt-[90px] tw-pb-10 tw-px-4 tablet:tw-px-7">
          <div class="tw-pb-3 tablet:tw-pb-6 desktop:tw-pb-6 tw-mx-auto">
            <div
              class="tw-font-normal tw-text-center tw-pb-4 tablet:tw-pb-5 tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny tw-h-fit tw-text-[#C52600]"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_origin_stories_zodiac_race_paragraph10') }}
            </div>
            <div class="tw-flex tw-flex-wrap tw-pb-6 tw-w-full tw-m-auto tw-justify-center">
              <img :src="$cdn('images/cny2024/zodiacRace/zodiacRace4.png')">
            </div>
            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph11') }}
            </p>
            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph12') }}
            </p>
            <p class="tw-pb-6">
              {{ $t('cny_origin_stories_zodiac_race_paragraph13') }}
            </p>
            <p class="tw-text-sg-caption-italic tw-italic">
              {{ $t('cny_info_source') }}<br>
              <a
                rel="noopener"
                href="http://www.gov.cn/ztzl/08cjtbch/content_861356.htm"
                target="_blank"
                class="tw-not-italic tw-underline"
              >
                THE STATE COUNCIL THE PEOPLE'S REPUBLIC OF CHINA - 十二生肖故事传说</a>
            </p>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>
<script setup lang="ts">
import { onMounted, /* defineExpose, */ ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';

const { t } = useI18n();
// let sectionTitle = ref('');
// let sectionSubtitle = ref('');
// let sectionDesc = ref('');
// let storySection = ref(1);
let loadingTimeout = ref();
const loadingAnimation = ref('tw-opacity-1 tw-h-auto');
const loadingBar = ref('');
const mainStorySection = ref('tw-opacity-0 tw-h-0');

let story = ref({
  id: 1,
  title: '',
  subtitle: '',
  desc: '',
  imagePath1: '',
  imagePath2: '',
  imagePath3: '',
});

defineExpose({ getStoryData });

const storyData = computed(() => [
  {
    id: 1,
    title: t('cny_the_legend_nian'),
    subtitle: t('cny_origin_stories_nian_subtitle'),
    description: t('cny_origin_stories_nian_desc'),
    imagePath1: 'legendNian/legendNian.png',
    imagePath2: 'legendNian/legendNian2.png',
    imagePath3: '',
  },
  {
    id: 2,
    title: t('cny_origin_stories_zodiac_race'),
    subtitle: t('cny_origin_stories_zodiac_race_subtitle'),
    description: t('cny_origin_stories_zodiac_race_desc'),
    imagePath1: 'zodiacRace/zodiacRace.png',
    imagePath2: 'zodiacRace/zodiacRace2.png',
    imagePath3: 'zodiacRace/zodiacRace3.png',
  },
  {
    id: 3,
    title: t('cny_bai_tian_gong_festival'),
    subtitle: '',
    description: t('cny_origin_stories_bai_tian_gong_desc'),
    imagePath1: 'baitianGong/baitianGong.png',
    imagePath2: 'baitianGong/baitianGong2.png',
    imagePath3: '',
  },
  {
    id: 4,
    title: t('cny_the_origins_of_ren_ri'),
    subtitle: '',
    description: t('cny_origin_stories_ren_ri_desc'),
    imagePath1: 'originRenri/originRenri.png',
    imagePath2: 'originRenri/originRenri2.png',
    imagePath3: '',
  },
]);

onMounted(() => {
  getStoryData(1);
});

function getStoryData(id: number) {
  loadingAnimation.value = 'tw-opacity-1 tw-visible tw-min-h-screen';
  mainStorySection.value = 'tw-opacity-0 tw-invisible tw-h-0';

  for (var i = 0; i < storyData.value.length; i++) {
    if (id == storyData.value[i].id) {
      story.value.id = storyData.value[i].id;
      story.value.title = storyData.value[i].title;
      story.value.subtitle = storyData.value[i].subtitle;
      story.value.desc = storyData.value[i].description;
      story.value.imagePath1 = storyData.value[i].imagePath1;
      story.value.imagePath2 = storyData.value[i].imagePath2;
      story.value.imagePath3 = storyData.value[i].imagePath3;
    }
  }

  loadingBar.value = 'active';

  clearTimeout(loadingTimeout.value);
  loadingTimeout.value = setTimeout(() => {
    loadingAnimation.value = 'tw-opacity-0 tw-invisible tw-h-0';
    mainStorySection.value = 'tw-opacity-1 tw-visible tw-h-full';
    loadingBar.value = '';
  }, 500);
}
</script>
<style>
.loadingBarContainer {
  width: 98px;
  height: 4px;
  /* background-color: #8c12d4;
  opacity: 0.6; */
  overflow: hidden;
}

.loadingBar {
  background-color: #8c12d4;
  height: 100%;
  opacity: 1;
  width: 0;
}

.loadingBar.active {
  animation: loadBar 1.5s ease-in-out forwards;
}

@keyframes loadBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
