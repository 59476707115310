<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <!-- Header Title -->
    <div
      class="tw-flex tw-flex-col tw-px-4 tw-pt-8 tw-pb-5 tablet:tw-px-10 tablet:tw-pt-[60px] tablet:tw-pb-10 desktop:tw-px-20 desktop:tw-pb-10 desktop:tw-pt-[60px]"
    >
      <p class="tw-text-sg-sccc1 tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">
        {{ $t('arts_songs_and_poems_2') }}
      </p>
      <div class="tw-pb-[28px]" />
      <hr class="line1">
    </div>
    <!-- Poem Swiper -->
    <ap-swiper
      swiper-type="poems"
      :poems-data="poemsData"
      name-page="poems"
      :description="$t('poems_section_desc')"
      :title="$t('swiper_title_poems')"
    />
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { cdn } from '../../support/cdn';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    const poemsData = computed(()=>[
      {
        id: 1,
        title: t('khoo_seok_wan_poems_1'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=ivskbWSh_Rw',
        image_url:  cdn('videos_thumbnails/Poems/Transliteration_of_Malay_Language_Into_Minnan.jpg'),
      },
      {
        id: 2,
        title: t('khoo_seok_wan_poems_2'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=R-Ezo5hv78E',
        image_url: cdn('videos_thumbnails/Poems/Bridge.jpg'),
      },
      {
        id: 3,
        title: t('khoo_seok_wan_poems_3'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=21HEDRzCjtY',
        image_url: cdn('videos_thumbnails/Poems/Coconut_Tree.jpg'),
      },
      {
        id: 4,
        title: t('khoo_seok_wan_poems_4'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=epF9b10B6-E',
        image_url: cdn('videos_thumbnails/Poems/Local_Talk.jpg'),
      },
      {
        id: 5,
        title: t('khoo_seok_wan_poems_5'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=d5e1pUzN8mQ',
        image_url: cdn('videos_thumbnails/Poems/Singapore_Map.jpg'),
      },
      {
        id: 6,
        title: t('khoo_seok_wan_poems_6'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=AGibSIyKURY',
        image_url: cdn('videos_thumbnails/Poems/Sin_Chew.jpg'),
      },
    ]);
    return {
      poemsData,
    };
  },
};
</script>
