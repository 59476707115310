<template>
  <section
    :class="[
      'tw-pt-10 sgTablet2:tw-pt-[60px]',
      'sgDesktop:tw-pb-[40px]',
      { 'tw-hidden' : !items.length }
    ]"
  >
    <swiper
      :slides-per-view="'auto'"
      :space-between="0"
      :centered-slides="true"
      :normalize-slide-index="false"
      :effect="'creative'"
      :creative-effect="{
        shadowPerProgress: true,
      }"
      :breakpoints="{
        0: {
          creativeEffect: {
            limitProgress: 1,
            prev: {
              shadow: false,
              translate: [-140, -90, -400],
            },
            next: {
              shadow: false,
              translate: [140, -90, -400],
            },
          }
        },
        680: {
          creativeEffect: {
            limitProgress: 3,
            prev: {
              shadow: false,
              translate: [-280, -120, -400],
            },
            next: {
              shadow: false,
              translate: [280, -120, -400],
            },
          }
        },
      }"
      :loop="true"
      class="mySwiper"
      style="width: 100vw;"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'swiper-slide-custom',
          { 'swiper-hidden': !item.visible },
          { 'swiper-shown': item.visible },
        ]"
        :style="{
          width: cardWidth,
          filter: item.visible ? 'drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.3))' : null
        }"
      >
        <ws-101-tangyuan-recipe-card
          :data="item"
          :active="index === currentSlideIndex"
        />
        <div class="swiper-lazy-preloader" />
      </swiper-slide>
    </swiper>
    <div
      :class="[
        'tw-flex tw-justify-center tw-items-center tw-gap-[60px]',
        'tw-pt-10 sgTablet2:tw-pt-[60px]',
      ]"
    >
      <div
        :class="[
          'tw-flex',
          'tw-bg-[#9B2F3A] hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
          'tw-h-[56px] tw-w-[56px]',
          'tw-text-white',
          'tw-rounded-full',
          { 'tw-bg-[#CC959A] hover:tw-bg-[#CC959A] active:tw-bg-[#CC959A] tw-opacity-[0.25]' : leftButtonDisabled },
          { 'tw-cursor-pointer' : !leftButtonDisabled}
        ]"
        @click="swiperInstance?.slidePrev()"
      >
        <img :src="$cdn('icons/ma/ma_chevron_left.svg')">
      </div>
      <div class="tw-text-sg-ws-body">
        {{ ((currentSlideIndex % 5) + 1) }} / 5
      </div>
      <div
        :class="[
          'tw-flex',
          'tw-bg-[#9B2F3A] hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
          'tw-h-[56px] tw-w-[56px]',
          'tw-text-white',
          'tw-rounded-full',
          { 'tw-bg-[#CC959A] hover:tw-bg-[#CC959A] active:tw-bg-[#CC959A] tw-opacity-[0.25]' : rightButtonDisabled },
          { 'tw-cursor-pointer' : !rightButtonDisabled}
        ]"
        @click="swiperInstance?.slideNext()"
      >
        <img :src="$cdn('icons/ma/ma_chevron_right.svg')">
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInstance } from 'swiper';
import 'swiper/css';
import SwiperCore from 'swiper';
import 'swiper/css/effect-creative';
import { EffectCreative } from 'swiper/modules';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { cdn } from '../../support/cdn';

SwiperCore.use([EffectCreative]);

const leftButtonDisabled = ref(true);
const rightButtonDisabled = ref(false);
const currentSlideIndex = ref(0);

const cardWidth = ref('540px');
function changeCardWidth() {
  onLoad();
  if (window.innerWidth < 680) {
    cardWidth.value = '280px';
  } else {
    cardWidth.value = '540px';
  }
}

onMounted(() => {
  fetchRecipes();
  changeCardWidth();
  window.addEventListener('resize', changeCardWidth);
  window.addEventListener('load', onLoad);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', changeCardWidth);
});

interface TangyuanRecipe {
  title: string;
  description: string;
  image: string;
  url: string;
  visible: boolean;
}

const items = ref<TangyuanRecipe[]>([]);

function fetchRecipes() {
  fetch(cdn('json/ws/tangyuan_recipe.json'))
    .then((response) => response.json()).then((data) => {
      items.value = data;
    });
}

const onSlideChange = () => {
  if (items.value.length === 0)  return;
  if (swiperInstance?.value) {
    const totalSlides = items.value.length;

    currentSlideIndex.value = swiperInstance?.value.realIndex;
    leftButtonDisabled.value = swiperInstance?.value.isBeginning;
    rightButtonDisabled.value = swiperInstance?.value.isEnd;

    // Reset all slides to not visible
    items.value.forEach((item, _) => {
      item.visible = false;
    });

    // Make the current slide and its neighbors visible
    items.value[currentSlideIndex.value].visible = true;
    items.value[(currentSlideIndex.value - 1 + totalSlides) % totalSlides].visible = true; // Previous slide
    items.value[(currentSlideIndex.value + 1) % totalSlides].visible = true; // Next slide
    if (window.innerWidth >= 680) {
      items.value[(currentSlideIndex.value - 2 + totalSlides) % totalSlides].visible = true; // Two slides before
      items.value[(currentSlideIndex.value + 2) % totalSlides].visible = true; // Two slides after
    }
  }
};

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

function onLoad() {
  const swiperWrapper = window.document.querySelector('.swiper-3d .swiper-wrapper');
  if (swiperWrapper) {
    swiperWrapper.style.pointerEvents = 'none';
  }
}

</script>

<style lang="scss" scoped>
/* General styles for desktop and tablet */
.swiper-hidden {
  opacity: 0 !important;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.swiper-shown {
  opacity: 1 !important;
  pointer-events: auto;
}

.swiper {
  min-width: 1620px; /* Ensure the Swiper won't shrink below this width */
  overflow: hidden; /* Hide content that overflows the container */
  margin: 0 auto; /* Center the Swiper within its parent container */
}


</style>
