<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-25-navigation="true"
      :cny25-p2-navigation="wordpressData?.unlock_sidebar"
    />

    <section class="tw-relative">
      <img
        :src="$cdn('images/cny2025/page_breaks/cny25-home-page-break-1.png')"
        :class="[
          'tw-w-full tw-absolute -tw-bottom-[1px]',
          'tw-h-[28px] sgTablet:tw-h-[104px]',
          'tw-z-[1]'
        ]"
      >
      <picture>
        <img
          :src="$cdn('images/cny2025/banner/cny25-hero-banner.jpg')"
          :class="[
            'tw-w-full tw-object-cover',
            'tw-h-[661px] sgTablet:tw-h-[1072px] sgTablet2:tw-h-[1244px] sgDesktop:tw-h-[902px]',
          ]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-top-[80px] sgTablet:tw-top-[100px] sgDesktop:tw-top-[120px] tw-z-[1]">
        <div class="tw-text-white tw-text-center">
          <img
            class="tw-pb-[10px] tw-h-[40px] sgTablet:tw-h-[60px] tw-mx-auto"
            :src="$cdn('images/zy2024/header.png')"
            :style="{
              filter: 'drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.30))'
            }"
          >
          <div
            :class="[
              'tw-text-sg-ma-dp3-mobile sgTablet:tw-text-sg-ma-dp3-tablet sgDesktop:tw-text-sg-ma-dp3-desktop',
              'tw-pb-1 tw-tracking-[0.01em]',
              { 'tw-font-akkuratbold': checkLang() == 'en' },
              { 'tw-font-notosansbold': checkLang() != 'en' },
            ]"
            :style="{
              textShadow: '-4px 4px 10px rgba(0, 0, 0, 0.30)'
            }"
          >
            {{ $t('chinese_new_year') }}
          </div>
        </div>
      </div>
    </section>

    <!-- CNY CALENDAR -->
    <cny-25-calendar
      :data="wordpressData?.cny24Calendar"
      @open-sources="handleSupClick"
    />

    <cny-25-highlights
      v-if="highlights.length > 0"
      :data="highlights"
    />
    <!-- CNY FAMILY FUN -->
    <cny-25-image-block
      v-for="imageBlock in imageBlocks"
      :id="imageBlock.id"
      :key="imageBlock.id"
      :data="imageBlock"
    />

    <section
      id="cny_ff_25"
      class=""
    >
      <div
        class="tw-flex tw-space-x-4 tw-items-center tw-pt-5 tablet:tw-pt-10 tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]"
      >
        <div>
          <img :src="$cdn('images/cny2024/selfie.svg')">
        </div>
        <div>
          <div
            class="tw-text-sg-sh1-mobile-qm tablet:tw-text-sg-sh1-tablet-qm desktop:tw-text-sg-sh1-qm"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('cny_ff_25') }}
          </div>
          <div class="tw-mt-4 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">
            {{ $t('cny_share_moment') }}
          </div>
        </div>
      </div>

      <cny-25-ugc-swiper :social_media_images="wordpressData.social_media_images" />
    </section>

    <cny-25-sticker-section
      id="cny_free_stickers_menu"
      :data="wordpressData.downloadables"
    />

    <secondary-navigation :menu-items-data="menuItems" />
    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
      :title-style="`${checkLang() == 'zh-hans' ?
        'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-notosansbold' :
        'tw-text-sg-cny25-h2-mobile sgTablet:tw-text-sg-cny25-h2-tablet sgDesktop:tw-text-sg-cny25-h2-desktop tw-font-akkuratbold'
      }`"
    />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { checkLang } from '../../support/helper';

interface ImageBlock {
  id: string
  isMobile: boolean
  img: string
  imgMobile: string
  title: string
  description: string
  buttonTitle: string
  buttonLink: string
  rtl: boolean
}

const isMobile = ref(window.innerWidth < 800);

const props = defineProps<{
  wordpressData: any
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref(props.wordpressData.sources);
const menuItems = [
  {
    label: 'cny_calendar_menu',
    id: 'cny_calendar_menu'
  },
  {
    label: 'cny_highlights_menu',
    id: 'cny_highlights_menu'
  },
  {
    label: 'cny_origin_stories_menu',
    id: 'cny_origin_stories_menu'
  },
  {
    label: 'cny_the_sgren_way_menu',
    id: 'cny_the_sgren_way_menu'
  },
  {
    label: 'cny_family_fun_menu',
    id: 'cny_family_fun_menu'
  },
  {
    label: 'cny_ff_25',
    id: 'cny_ff_25'
  },
  {
    label: 'cny_free_stickers_menu',
    id: 'cny_free_stickers_menu'
  },
];

const highlights = ref([]);

function onResize() {
  isMobile.value = window.innerWidth < 800;
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
  nextTick(() => {
    addSourcesPanelHandler();
  });
  if (props.wordpressData.carousel) {
    highlights.value = props.wordpressData.carousel;
  }
});

const handleSupClick = () => {
  imageSourceOpen.value = true;
};

function addSourcesPanelHandler() {
  removeSourcesPanelHandler();
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if (supElements?.length > 0) {
    supElements.forEach((supElement) => {
      supElement.addEventListener('click', handleSupClick);
    });
  }
}

function removeSourcesPanelHandler() {
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if (supElements?.length > 0) {
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
}

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
  removeSourcesPanelHandler();
});

const imageBlocks = ref<ImageBlock[]>([
  {
    id: 'cny_origin_stories_menu',
    isMobile: true,
    img: 'images/cny2025/homepage/origin_stories/cny25-home-origin-stories-desktop.jpg',
    imgMobile: 'images/cny2025/homepage/origin_stories/cny25-home-origin-stories-mobile.jpg',
    title: 'cny25_origin_stories_title',
    description: 'cny25_origin_stories_description',
    buttonTitle: 'cny_discover_more',
    buttonLink: '/festivals/chinese-new-year/origin-stories/',
    rtl: false
  },
  {
    id: 'cny_the_sgren_way_menu',
    isMobile: true,
    img: 'images/cny2025/homepage/singaporen_way/cny25-home-singaporen-way-desktop.jpg',
    imgMobile: 'images/cny2025/homepage/singaporen_way/cny25-home-singaporen-way-mobile.jpg',
    title: 'cny25_singaporen_way_title',
    description: 'cny25_singaporen_way_description',
    buttonTitle: 'cny_discover_more',
    buttonLink: '/festivals/chinese-new-year/about/',
    rtl: true
  },
  {
    id: 'cny_family_fun_menu',
    isMobile: true,
    img: 'images/cny2025/homepage/cny_family_fun/cny25-home-family-fun-desktop.jpg',
    imgMobile: 'images/cny2025/homepage/cny_family_fun/cny25-home-family-fun-mobile.jpg',
    title: 'cny25_family_fun_title',
    description: 'cny25_family_fun_description',
    buttonTitle: 'cny_discover_more',
    buttonLink: '/festivals/chinese-new-year/familyfun/',
    rtl: false
  }
]);

</script>

<style scoped></style>
