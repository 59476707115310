<template>
  <div class="headerCustom">
    <div
      ref="inner"
      class="inner"
    />
    <div
      class="tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-left-0 tw-w-full tw-h-full tw-overflow-hidden tw-bg-fixed tw-flex tw-flex-row"
    >
      <div
        class="tw-flex tw-h-full tw-w-full tw-px-4 tw-pt-5
        tablet:tw-px-10 tablet:tw-py-10
        desktop:tw-px-20 desktop:tw-py-10"
        :class="[isBuiltLeft ? 'tw-justify-start' : 'tw-justify-end']"
      >
        <div
          class="tw-flex tw-flex-row tw-min-w-[290px]
        tablet:tw-w-[504px]
        desktop:tw-w-[792px]"
        >
          <!-- Title, Content, Note -->
          <div>
            <p
              class="tw-text-white tw-text-sg-h3-mobile tw-pb-5
              tablet:tw-text-sg-h3-tablet tablet:tw-pb-10
              desktop:tw-text-sg-h3 desktop:tw-pb-[60px]"
            >
              {{ instructionTitle }}
            </p>
            <p
              class="tw-text-white tw-text-sg-sh2-mobile
              tablet:tw-text-sg-sh2-tablet
              desktop:tw-text-sg-sh2"
            >
              {{ instructionContent }}
            </p>
            <!-- Button -->
            <div class="tw-pb-20" />
            <a
              class="button hover:!tw-bg-sg-sccc5-w20"
              :href="redirectUrl()"
              target="_blank"
              rel="noopener"
            >
              {{ $t('find_out_more') }}
              <i class="tw-pl-2"><img :src="$cdn('icons/arrow_outward.svg')"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, toRefs } from 'vue';

export default {
  props: {
    imageUrl: {
      type: String,
      default: 'https://picsum.photos/1200?random=12'
    },
    instructionTitle: {
      type: String,
      default: 'RESPECT THE EXHIBITION',
    },
    instructionContent: {
      type: String,
      default: 'To ensure the safety of visitors and the exhibition, the following behaviours are prohibited:',
    },
    isBuiltLeft: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const inner: Ref<HTMLElement | null> = ref(null);
    const {imageUrl} = toRefs(props);
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get('lang');
    function getImageUrl(imageUrl: string) {
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(' + imageUrl + ')';
    }

    function redirectUrl() {
      // const baseUrl = process.env.MAIN_SITE_URL;
      if (langParam === 'zh-hans') {
        // window.location.href = baseUrl + 'zh-hans/group-visits/';
        return 'https://singaporeccc.org.sg/zh-hans/group-visits/';
      } else {
        // window.location.href = baseUrl + 'group-visits/';
        return 'https://singaporeccc.org.sg/group-visits/';
      }
    }

    onMounted(() => {
      if (inner.value) {
        inner.value.style.backgroundImage = getImageUrl(imageUrl.value);
      }
    });

    return {
      inner,
      redirectUrl,
    };
  }
};
</script>

<style scoped>
.headerCustom {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.sectionCustom {
    width: 100%;
    height: 1000px;
}
.button {
  background-color: #E32726;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 8px 24px;
  cursor: pointer;
  border-radius: 26px;
  height: 52px;
}

p {
  text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
}
</style>
