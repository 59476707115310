<template>
  <div
    :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
    class="tw-flex tw-flex-col tw-m-auto tw-max-w-[500px] sgTablet2:tw-max-w-[800px] tw-bg-[#FFFFFF80]"
  >
    <div class="tw-flex tw-flex-col sgTablet2:tw-flex-row tw-px-4 tw-py-5 sgTablet:tw-px-5 sgTablet:tw-py-10 tw-justify-around tw-min-h-[350px] tw-gap-5">

      <swiper
        id="burning_joss_card"
        :modules="[Controller]"
        :controller="{
          control:
            'burningJossDescSwiper'
        }"
        :effect="'cards'"
        :rotate="true"
        :loop="isLoop"
        :centered-slides="isCentre"
        :slides-per-view="1"
        :grab-cursor="false"
        :pagination="{
          clickable:true,
          el:'.burning-joss-swiper-pagination'
        }"
        class="burning_joss_card_swiper tw-w-[280px] sgTablet:tw-w-[340px] sgTablet2:tw-ml-5 "
        @transition-start="onSlideChange"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(item, index) in props.data"
          :key="index"
          class="tw-relative tw-m-0 tw-drop-shadow-lg"
        >
          <img
            v-if="item.is_singaporen_way"
            class="tw-absolute -tw-bottom-5 -tw-right-5 tw-w-[100px] tw-h-[66px]  tw-z-20"
            :src="$cdn(checkLang() !== 'zh-hans' ? 'icons/singaporen_way_stamp-4.png' : 'icons/singaporen_way_stamp_cn-4.png')"
          >
          <!-- Image -->
          <div
            :class="[
              'tw-relative tw-w-[280px] sgTablet:tw-w-[340px] tw-h-[216px] sgTablet:tw-h-[260px] tw-flex tw-items-center tw-justify-center',
              'before:tw-absolute before:tw-inset-0 before:tw-bg-gradient-to-t before:tw-from-[#FFDD63] before:tw-to-[#F79F1C] before:tw-opacity-75',
            ]"
            :style="{
              background: `url(${$cdn('images/qm2024/qingming-101/paper_texture.png')})`
            }"
          >
            <img
              class="tw-w-full tw-h-full tw-object-cover tw-max-w-[248px] sgTablet:tw-max-w-[308px] tw-max-h-[184px] sgTablet:tw-max-h-[228px] tw-z-10"
              :src="item.image"
            >
          </div>
        </swiper-slide>
      </swiper>

      <swiper
        id="burning_joss_descp"
        :modules="[Controller]"
        :controller="{
          control:
            'burningJossCardSwiper'
        }"
        :rotate="true"
        :loop="isLoop"
        :centered-slides="isCentre"
        :slides-per-view="1"
        :grab-cursor="false"
        class=" tw-w-[280px] sgTablet:tw-w-[340px] sgTablet2:tw-ml-5 "
        @transition-start="onSlideChange"
        @swiper="onSwiperDesc"
      >
        <swiper-slide
          v-for="(item, index) in props.data"
          :key="index"
          class="tw-relative tw-m-0 !tw-h-[initial]"
        >
          <div
            id="burning_joss_card_text"
            class="tw-flex tw-flex-row tw-items-center tw-mt-5 sgTablet2:tw-mt-0 tw-mx-auto sgTablet2:tw-mx-0"
          >
            <div
              class="tw-w-full tw-text-sg-body-1 tw-text-sg-qm24-green-b60 tw-text-left"
              v-html="item.description"
            />
          </div>
        </swiper-slide>
      </swiper>

    </div>
    <div class="burning-joss-swiper-pagination tw-flex tw-mx-auto" />
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Mousewheel,Controller} from 'swiper/modules';
import SwiperCore from 'swiper';
import SwiperCoreSecond from 'swiper';
import { onMounted, ref, computed, nextTick , watch} from 'vue';
import { checkLang } from '../../support/helper';
import { onBeforeUnmount } from 'vue';
import 'swiper/css/effect-cards';

// import required modules
import { EffectCards } from 'swiper/modules';

SwiperCore.use([Pagination, Mousewheel,EffectCards,Controller]);
SwiperCoreSecond.use([Pagination, Mousewheel,Controller]);

const burningJossCardSwiper = ref<SwiperCore | null>(null);
const burningJossDescSwiper = ref<SwiperCoreSecond | null>(null);
let isLoop = ref(false);
let isCentre = ref(false);
let firstLoop = ref(true);

interface BurningJossCard {
  description: string;
  is_singaporen_way: boolean;
  image: any;
}

const props = defineProps<{
  data: BurningJossCard[],
  imageSourceOpen: boolean,

}>();

const emit = defineEmits(['update:imageSourceOpen']);

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});


onMounted(() => {
  nextTick(() => {
    onSlideChange( burningJossCardSwiper.value);
  });

});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('burning_joss_card_text');
  if(burningJossCard){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#burning_joss_card_text sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});

function onSwiper(swiper: SwiperCore) {
  burningJossCardSwiper.value = swiper;
}

function onSwiperDesc(swiper: SwiperCoreSecond) {
  burningJossDescSwiper.value = swiper;
}

watch([burningJossCardSwiper,burningJossDescSwiper], ([item,item2]) => {
  if (item && item2) {
    item.controller.control = item2;
    item2.controller.control = item;

  }
});

function onSlideChange(swiper: any) {
  if(props.data.length === 0) return;
  isLoop.value = true;
  isCentre.value = true;
  if(firstLoop.value){
    firstLoop.value = false;
    swiper.slideTo(1,1000,false);
    swiper.slideTo(2,1000,false);
    swiper.slideTo(0,1000,false);
  }
  nextTick(() => {
    setBurningJossSourceElem();
  });
}

function truncateHtmlText(htmlString: string, maxLength = 390) {
  // Remove <p> tags from the HTML string
  const strippedText = htmlString.replace(/<\/?p>/g, '');
  if (strippedText.length <= maxLength) {
    return htmlString;
  }
  // Limit the text to the specified maxLength
  const limitedText = strippedText.substring(0, maxLength);
  // Re-wrap with <p> tags and add ellipsis
  return limitedText + '...';
}

function setBurningJossSourceElem(){
  // add <sup> click listener
  const handleSupClick = () => {
    imageSourceOpen.value = true;
  };

  const supElements = document.querySelectorAll('#burning_joss_card_text sup');
  supElements.forEach((supElement) => {
    console.log(supElement);
    supElement.addEventListener('click', handleSupClick);
  });
}
</script>

<style lang="scss">
.burning-joss-swiper-pagination {
  padding-bottom: 32px;
  justify-content: center;
  display: flex;
}

.burning-joss-swiper-pagination .swiper-pagination-bullet {
  @apply tw-w-3 tw-h-3;
}

.burning-joss-swiper-pagination .swiper-pagination-bullet-active {
  @apply tw-bg-ap-dim-grey #{!important};
  @apply tw-w-3 tw-h-3;
}

#burning_joss_card .swiper-wrapper .swiper-slide {
  overflow: visible;
}

#burning_joss_card .swiper-wrapper .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
}

#burning_joss_card_text sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}

#burning_joss_card .swiper-slide .swiper-slide-shadow{
  background:none;
}
</style>
