<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { ref } from 'vue';
import {useI18n} from 'vue-i18n';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  wordpressData:any
}>();

const {t} = useI18n();
const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');

const galorePadding = ref(0);

const menuItems = [
  {
    label: 'ws_winter_solstice_101_menu_past_present',
    id   : 'ws_winter_solstice_101_menu_past_present'
  },
  {
    label: 'ws_winter_solstice_101_menu_tangyuan_galore',
    id   : 'ws_winter_solstice_101_menu_tangyuan_galore'
  },
  {
    label: 'ws_winter_solstice_101_menu_other_food_eaten',
    id   : 'ws_winter_solstice_101_menu_other_food_eaten'
  },
  {
    label: 'ws_winter_solstice_101_menu_tangyuan_recipes',
    id   : 'ws_winter_solstice_101_menu_tangyuan_recipes'
  },
];

onBeforeMount(()=>{
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

const pageBreakHeight1 = ref(0);
const pageBreakHeight2 = ref(0);
const pageBreak1 = ref<HTMLElement | null>(null);
const pageBreak2 = ref<HTMLElement | null>(null);

function updatePageBreakHeight() {
  if (pageBreak1.value) {
    pageBreakHeight1.value = pageBreak1.value.getBoundingClientRect().height;
  }
  if (pageBreak2.value) {
    pageBreakHeight2.value = pageBreak2.value.getBoundingClientRect().height;
  }
  attachSupClickHandler();
}

onMounted(() => {
  window.addEventListener('load', updatePageBreakHeight);
  pageBreak1.value = document.getElementById('page-break-1');
  pageBreak2.value = document.getElementById('page-break-2');
  updatePageBreakHeight();
  window.addEventListener('resize', updatePageBreakHeight);
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  window.removeEventListener('resize', updatePageBreakHeight);
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

function attachSupClickHandler() {
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    console.log('attachSupClickHandler: ', supElements.length);
    supElements.forEach((supElement) => {
      supElement.addEventListener('click', handleSupClick);
    });
  }
}

const handleSupClick = () => {
  imageSourceOpen.value = true;
};

const tangyuanGalore = [
  {
    title: 'ws_winter_solstice_101_title_3',
    description   : 'ws_winter_solstice_101_description_3_1',
    image: 'images/ws2024/winter-solstice-101/tangyuan-galore/cantonese.png',
    type: 1,
  },
  {
    title: 'ws_winter_solstice_101_title_4',
    description   : 'ws_winter_solstice_101_description_4_1',
    image: 'images/ws2024/winter-solstice-101/tangyuan-galore/teochew.png',
    type: 2,
  },
  {
    title: 'ws_winter_solstice_101_title_5',
    description   : 'ws_winter_solstice_101_description_5_1',
    image: 'images/ws2024/winter-solstice-101/tangyuan-galore/hakka.png',
    type: 1,
  },
  {
    title: 'ws_winter_solstice_101_title_6',
    description   : 'ws_winter_solstice_101_description_6_1',
    image: 'images/ws2024/winter-solstice-101/tangyuan-galore/peranakan.png',
    type: 2,
  },
];

</script>

<template>
  <div
    class="tw-overflow-hidden"
    :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ws-navigation="true"
    />
    <ws-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- header -->
    <section class="tw-relative tw-tracking-wider">
      <picture>
        <div
          :style="{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${$cdn('images/ws2024/banner/ws-subpage-hero-banner.jpg')}) lightgray 50% / cover no-repeat`
          }"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        />
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-ws-mobile-h1 sgTablet:tw-text-sg-ws-tablet-h1 sgDesktop:tw-text-sg-ws-desktop-h1 tw-pb-1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_winter_solstice_101_main_title') }}
          </div>
        </div>
      </div>
    </section>

    <!-- Celebrating Winter Solstice -->
    <section
      id="ws_winter_solstice_101_menu_past_present"
      :class="[
        'tw-pt-10 tw-pb-10',
        'tw-relative tw-tracking-wider',
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
      ]"
    >
      <div
        :class="[
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-max-w-[800px] tw-text-center',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_winter_solstice_101_title_1') }}
      </div>
      <ws-101-celebrating-winter-swiper />
    </section>

    <!-- Tangyuan Galore -->
    <section
      id="ws_winter_solstice_101_menu_tangyuan_galore"
      :class="[
        'tw-relative',
        'tw-pb-10',
        'tw-relative tw-tracking-wider',
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
        'tw-bg-sg-ws24-maroon tw-text-white',
      ]"
      :style="{
        paddingTop: pageBreakHeight1 + 'px'
      }"
    >
      <img
        id="page-break-1"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-home-page-break-2.png')"
      >
      <img
        class="tw-absolute tw-top-0 tw-z-10 tw-w-[200px]"
        :src="checkLang() == 'en' ? $cdn('images/ws2024/stamp/singaporen_stamp_en.png') : $cdn('images/ws2024/stamp/singaporen_stamp_cn.png')"
        :style="{
          filter: `drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.30))`,
          marginTop: `${pageBreakHeight1 - 90}px`
        }"
      >
      <div
        :class="[
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-pb-10 tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-pt-[100px] sgTablet:tw-pt-[80px]',
          'tw-w-full',
        ]"
      >
        <div
          :class="[
            'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
            'tw-w-full tw-max-w-[800px] tw-text-center tw-pb-2',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('ws_winter_solstice_101_title_2') }}
        </div>
        <div
          id="sup-handler-container"
          :class="[
            'tw-w-full tw-text-sg-ws-body tw-text-center sgTablet2:tw-text-left',
            'tw-max-w-[800px]'
          ]"
          v-html="props.wordpressData?.tangyuan_galore_description"
        />
      </div>

      <div
        :class="[
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-gap-10 sgTablet2:tw-gap-0',
        ]"
      >
        <div
          v-for="item in tangyuanGalore"
          :key="item.title"
          :class="[
            'tw-flex tw-justify-center tw-items-center',
            'sgTablet2:tw-flex-row',
            'tw-gap-10 tw-max-w-[800px]',
            { 'sgTablet2:tw-ml-[250px] tw-flex-col custom-padding-1': item.type == 1 },
            { 'sgTablet2:tw-mr-[250px] tw-flex-col-reverse custom-padding-2' : item.type == 2 },
          ]"
        >
          <img
            v-if="item.type == 2"
            :class="[
              'tw-mr-4 sgTablet:tw-mr-0 tw-w-[400px] sgTablet2:tw-w-[554px]'
            ]"
            :src="$cdn(item.image)"
          >
          <div
            :class="[
              'tw-flex tw-flex-col tw-justify-center tw-items-center sgTablet2:tw-items-start',
              'sgTablet:tw-min-w-[460px]',
              'tw-px-4 sgTablet:tw-px-[80px] sgTablet2:tw-px-0',
            ]"
          >
            <h4
              :class="[
                'tw-pb-1',
                'tw-text-sg-ws-h4-mobile sgTablet:tw-text-sg-ws-h4-tablet sgDesktop:tw-text-sg-ws-h4-desktop',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ $t(item.title) }}
            </h4>
            <div
              id="sup-handler-container"
              class="tw-text-sg-ws-body"
              :class="[
                'tw-text-center sgTablet2:tw-text-left',
              ]"
              v-html="$t(item.description)"
            />
          </div>
          <img
            v-if="item.type == 1"
            :class="[
              'tw-ml-4 sgTablet:tw-ml-0 tw-w-[400px] sgTablet2:tw-w-[554px]'
            ]"
            :src="$cdn(item.image)"
          >
        </div>
      </div>
    </section>

    <!-- Other food -->
    <section
      id="ws_winter_solstice_101_menu_other_food_eaten"
      :class="[
        'tw-relative',
        'tw-relative tw-tracking-wider',
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
        'tw-bg-sg-ws24-maroon tw-text-white',
        'tw-pb-10 tw-pt-10',
      ]"
    >
      <section
        :class="[
          'tw-flex tw-flex-col tw-justify-center tw-items-center',
          'tw-w-full tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        ]"
      >
        <div
          :class="[
            'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
            'tw-w-full tw-max-w-[800px] tw-text-center tw-pb-2',
            // 'tw-px-4 sgTablet2:tw-px-[80px]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
          v-html="$t('ws_winter_solstice_101_title_7')"
        />
        <div
          id="sup-handler-container"
          class="tw-text-sg-ws-body"
          :class="[
            'tw-w-full tw-text-sg-ws-body tw-text-center sgTablet2:tw-text-left',
            'tw-max-w-[800px]',
          // 'tw-px-4 sgTablet2:tw-px-[80px]',
          ]"
          v-html="props.wordpressData?.other_food_description"
        />
      </section>
      <ws-101-other-food-swiper @notify-parent="handleSupClick" />
    </section>

    <!-- Tangyuan Recipes -->
    <section
      id="ws_winter_solstice_101_menu_tangyuan_recipes"
      :class="[
        'tw-relative tw-w-full',
        'tw-pb-[60px]',
        // 'tw-pt-[60px] sgTablet:tw-pt-[80px] sgDesktop:tw-pt-[100px]',
        'tw-relative tw-tracking-wider',
        'tw-flex tw-flex-col tw-justify-center tw-items-center',
      ]"
      :style="{
        paddingTop: `${pageBreakHeight2}px`
      }"
    >
      <img
        id="page-break-2"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full tw-mt-[-1px]"
        :src="$cdn('images/ws2024/page-break/ws-101-page-break-2.png')"
      >
      <div
        :class="[
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
          'tw-w-full tw-max-w-[800px] tw-text-center tw-pb-2 tw-pt-10',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-text-sg-ws24-maroon',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_winter_solstice_101_title_8') }}
      </div>
      <div
        id="sup-handler-container"
        class="tw-text-sg-ws-body"
        :class="[
          'tw-w-full tw-text-sg-ws-body tw-text-center sgTablet2:tw-text-left',
          'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
          'tw-max-w-[800px]',
        ]"
        v-html="props.wordpressData?.tangyuan_recipe_description"
      />
      <ws-101-tangyuan-recipe-swiper />
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />
    <ap-footer />
  </div>
</template>

<style scoped>
#sup-handler-container:deep(sup) {
  cursor: pointer;
}

#sup-handler-container:deep(a) {
  cursor: pointer;
  text-underline-offset: 2px;
  text-decoration: underline;
}

@media screen and (min-width: 800px) and (max-width: 900px) {
  .custom-padding-1 {
    padding-left: 80px !important;
  }
  .custom-padding-2 {
    padding-right: 80px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .custom-padding-1 {
    padding-left: 40px !important;
  }
  .custom-padding-2 {
    padding-right: 40px !important;
  }
}

</style>
