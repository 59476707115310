<template>
  <div
    :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
  >
    <swiper
      id="observation_card"
      ref="observationCardSwiper"
      :space-between="20"
      :slides-per-view="'auto'"
      :centered-slides="true"
      class="tw-w-[90%] sgTablet:tw-w-[100%] tw-m-auto"
      @swiper="onSwiper"
      @transition-end="checkSwiper"
    >
      <swiper-slide
        v-for="(item, index) in props.data"
        :key="index"
        class="tw-max-w-[360px] sgTablet:tw-max-w-[500px] tw-drop-shadow-md"
      >
        <qm-observation-content-card
          :data="item"
          :index="index"
          @open-sources="imageSourceOpen = true"
        />
      </swiper-slide>
    </swiper>

    <div class="tw-flex tw-flex-row tw-justify-center tw-align-middle tw-relative">
      <!-- Left Swiper button -->
      <div
        class="tw-h-14 tw-w-14"
        @mouseenter="changeButtonState('hover', 0)"
        @mouseleave="changeButtonState('leave', 0)"
        @mousedown="changeButtonState('active', 0)"
        @mouseup="changeButtonState(isHoverLeft ? 'hover' : 'leave', 0)"
        @touchstart="changeButtonState('active', 0)"
        @touchend="changeButtonState(isHoverLeft ? 'hover' : 'leave', 0)"
        @click="changeSlide('prev')"
      >
        <img
          :src="$cdn(buttonLeft)"
          class="tw-cursor-pointer"
        >
      </div>
      <!-- Current slide number -->
      <div class="tw-mx-[60px] tw-my-4 !tw-p-0 tw-w-fit tw-text-sg-qm25-green-b20">
        {{ currentIndex + 1 }} / {{ props.data.length }}
      </div>
      <!-- Right Swiper button -->
      <div
        class="tw-h-14 tw-w-14"
        @mouseenter="changeButtonState('hover', 1)"
        @mouseleave="changeButtonState('leave', 1)"
        @mousedown="changeButtonState('active', 1)"
        @mouseup="changeButtonState(isHoverRight ? 'hover' : 'leave', 1)"
        @touchstart="changeButtonState('active', 0)"
        @touchend="changeButtonState(isHoverRight ? 'hover' : 'leave', 1)"
        @click="changeSlide('next')"
      >
        <img
          :src="$cdn(buttonRight)"
          class="tw-cursor-pointer"
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Mousewheel} from 'swiper/modules';
import SwiperCore from 'swiper';
import { ref, computed } from 'vue';
import { checkLang } from '../../support/helper';

SwiperCore.use([Pagination, Mousewheel]);

const swiperObj = ref<SwiperCore | null>(null);
interface ObservationCard {
  description: string;
  is_singaporen_way: boolean;
  image: string;
}

const props = defineProps<{
  data: ObservationCard[],
  imageSourceOpen: boolean,
}>();

const observationCardSwiper = ref(SwiperCore);
const currentIndex = ref(0);
const leftButtonDisabled = ref(true);
const rightButtonDisabled = ref(false);

const buttonLeft = ref('icons/qm/qm_green_left_nav_disabled.png');
const buttonRight = ref('icons/qm/qm_green_right_nav.png');

const isHoverLeft = ref(false);
const isHoverRight = ref(false);
const emit = defineEmits(['update:imageSourceOpen']);

const onSwiper = (swiper:any) => {
  swiperObj.value = swiper;
};

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const checkSwiper = (swiper:any) => {
  currentIndex.value = swiper.realIndex;
  setButtonState();
};

function changeSlide(direction:string) {
  if(direction == 'next'){
    if(currentIndex.value + 1 == props.data.length) return;
    swiperObj.value?.slideNext();
    // swiperObj.value?.slideTo(currentIndex.value + 1);
    currentIndex.value += 1;
  }else{
    if(currentIndex.value === 0) return;
    swiperObj.value?.slidePrev();
    // swiperObj.value?.slideTo(currentIndex.value - 1);
    currentIndex.value -= 1;
  }
  setButtonState();
}

function setButtonState() {
  leftButtonDisabled.value = (swiperObj.value?.isBeginning ?? true);
  rightButtonDisabled.value = (swiperObj.value?.isEnd ?? false) && currentIndex.value + 1 == props.data.length;
  changeButtonState('idle', 0);
  changeButtonState('idle', 1);
}

// Function to change button state
function changeButtonState(state: string, button: number) {
  // Handle left button (button === 0)
  if (button === 0) {
    if (leftButtonDisabled.value) {
      isHoverLeft.value = false;
      buttonLeft.value = 'icons/qm/qm_green_left_nav_disabled.png';
    } else {
      switch (state) {
        case 'hover':
          isHoverLeft.value = true;
          buttonLeft.value = 'icons/qm/qm_green_left_nav_hover.png';
          break;
        case 'active':
          buttonLeft.value = 'icons/qm/qm_green_left_nav_active.png';
          break;
        case 'leave':
          isHoverLeft.value = false;
          buttonLeft.value = 'icons/qm/qm_green_left_nav.png';
          break;
        default:
          if (isHoverLeft.value) break;
          buttonLeft.value = 'icons/qm/qm_green_left_nav.png';
      }
    }
  }
  // Handle right button (button === 1)
  else if (button === 1) {
    if (rightButtonDisabled.value) {
      isHoverRight.value = false;
      buttonRight.value = 'icons/qm/qm_green_right_nav_disabled.png';
    } else {
      switch (state) {
        case 'hover':
          isHoverRight.value = true;
          buttonRight.value = 'icons/qm/qm_green_right_nav_hover.png';
          break;
        case 'active':
          buttonRight.value = 'icons/qm/qm_green_right_nav_active.png';
          break;
        case 'leave':
          isHoverRight.value = false;
          buttonRight.value = 'icons/qm/qm_green_right_nav.png';
          break;
        default:
          if (isHoverRight.value) break;
          buttonRight.value = 'icons/qm/qm_green_right_nav.png';
      }
    }
  }
}

</script>

<style lang="scss">

#observation_card .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.observation-swiper-pagination {
  padding-top: 32px;
  justify-content: center;
  display: flex;
}

.observation-swiper-pagination .swiper-pagination-bullet {
  @apply tw-w-3 tw-h-3;
}

.observation-swiper-pagination .swiper-pagination-bullet-active {
  @apply tw-bg-ap-dim-grey #{!important};
  @apply tw-w-3 tw-h-3;
}

#observation_card .swiper-wrapper {
  padding-top: 40px;
  padding-bottom: 40px ;

  @media screen and (max-width: 680px) {
    padding-top: 20px;
    padding-bottom: 20px;

  }
}

</style>
