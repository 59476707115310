<script lang="ts" setup>
import { ComputedRef, computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, unref } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { h, ref} from 'vue';
import { checkLang } from '../../support/helper';
import { reactive } from 'vue';

type MaFamilyFunProgrammeCategoryType = 'activities' | 'workshops' | 'performances' | 'online';
type MaFamilyFunProgrammeCategoryFilterType = 'all' | MaFamilyFunProgrammeCategoryType;
type MaFamilyFunProgrammeStatusType = 'free_event' | 'paid_event' | 'selling_fast' | 'sold_out' | 'fully_booked';
type MaFamilyFunProgrammePostType = {
  title:string,
  description:string,
  category: MaFamilyFunProgrammeCategoryType,
  dates:string[],
  date_str:string,
  time:string,
  venue:string,
  paid:boolean,
  status: MaFamilyFunProgrammeStatusType,
  image:string,
  external_link?:string,
  is_video_embed:boolean,
  video_link?:string,
  expired_datetime?:string,
}

const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const modalKey = ref(Date.now());

const menuItems = [
  {
    label:'ma_family_fun_menu_about',
    id:'ma_family_fun_menu_about'
  },
  {
    label:'ma_family_fun_menu_programmes',
    id:'ma_family_fun_menu_programmes'
  },
];

if (props.wordpressData.canView?.includes('sidebar.ma2')) {
  menuItems.push({
    label:'ma_family_fun_menu_personality_quiz',
    id:'ma_family_fun_menu_personality_quiz'
  });
}


const showSocial = ref(false);
const social_media_list = ref<{id:number,name:string,icon:string,href:string}[]>([]);

const freebies_list = ref<{title:string,datetime:string,venue:string,image:string}[]>([]);

const programmes_list = ref<MaFamilyFunProgrammePostType[]>([]);
const post_filter = ref<MaFamilyFunProgrammeCategoryFilterType>('all');

const isProgramStart = ref(false);
const isProgramEnd = ref(true);

const programTabListRef = ref<HTMLDivElement | null>(null);

const selectedFilterDate = ref<string[]>([]);

const headerTitle = ref('');
const headerDescriptions = ref([]);

const exhibitionNoticeModalOpen = ref(false);

const dates = {
  '1': {
    index:1,
    filter_en:'FRIDAY',
    filter_cn:'星期五',
    aag_handle_en: '06 Sept, Friday',
    aag_handle_cn: '06 Sept, 星期五',
    dayVal: '6',
  },
  '2':{
    index:2,
    filter_en:'SATURDAY',
    filter_cn:'星期六',
    aag_handle_en: '07 Sept, Saturday',
    aag_handle_cn: '07 Sept, 星期六',
    dayVal: '7',
  },
  '3':{
    index:3,
    filter_en:'SUNDAY',
    filter_cn:'星期天',
    aag_handle_en: '08 Sept, Sunday',
    aag_handle_cn: '08 Sept, 星期天',
    dayVal: '8',
  }
};
const programmesByDates = ref<{[key:string]:MaFamilyFunProgrammePostType[]}>({
  '1':[],
  '2':[],
  '3':[]
});

const checkScrollPosition = () => {
  const element = programTabListRef.value;
  if (element) {
    isProgramStart.value = element.scrollLeft > 0;
    isProgramEnd.value = element.scrollLeft + element.clientWidth < (element.scrollWidth - 50);
  }
};

const scrollRight = () => {
  const element = programTabListRef.value;
  if (element) {
    element.scrollLeft += 50;
  }
};

const scrollLeft = () => {
  const element = programTabListRef.value;
  if (element) {
    element.scrollLeft -= 50;
  }
};

const pagination = reactive({
  currentPage:1,
  maxPostPerPage:12,
});
const filtered_prorgammes_list = computed(()=>{
  let tempList = programmes_list.value;
  if(post_filter.value !== 'all'){
    tempList = tempList.filter((item)=>item.category === post_filter.value);
  }

  if(selectedFilterDate.value.length !== 0){
    if(selectedFilterDate.value.length < Object.entries(dates).length){
      tempList = tempList.filter((item)=>selectedFilterDate.value.some(date=>item.dates.includes(date)));
    }
  }
  return tempList;
});

const paginated_filtered_prorgammes_list = computed(()=>{
  let tempList = filtered_prorgammes_list.value;

  let start = (pagination.currentPage - 1) * pagination.maxPostPerPage;
  let end = pagination.currentPage * pagination.maxPostPerPage;
  tempList = tempList.slice(start,end);

  return tempList;

});
const lastPage = computed(()=>Math.ceil(filtered_prorgammes_list.value.length / pagination.maxPostPerPage));

onBeforeMount(()=>{

  if(props.wordpressData?.header_content.title){
    headerTitle.value = props.wordpressData.header_content.title;
  }

  if(props.wordpressData?.header_content.description){
    headerDescriptions.value = props.wordpressData.header_content.description.map((item: any)=>item.text);
  }

  if(props.wordpressData?.social_link){
    const links = Object.values(props.wordpressData.social_link);
    showSocial.value = links.filter((item)=>!item).length != links.length;
    social_media_list.value = [
      {
        id:1,
        name:'Facebook',
        icon: '/images/ma2024/family-fun/social_facebook.svg',
        href: props.wordpressData.social_link?.facebook_link ?? ''
      },
      {
        id:2,
        name:'Instagram',
        icon: '/images/ma2024/family-fun/social_instagram.svg',
        href:props.wordpressData.social_link?.instagram_link ?? '',
      },
      {
        id:3,
        name:'Youtube',
        icon: '/images/ma2024/family-fun/social_youtube.svg',
        href:props.wordpressData.social_link?.youtube_link ?? '',
      },
      {
        id:4,
        name:'Wechat',
        icon: '/images/ma2024/family-fun/social_wechat.svg',
        href:props.wordpressData.social_link?.wechat_link ?? '',
      },
      {
        id:5,
        name:'Tiktok',
        icon: '/images/ma2024/family-fun/social_tiktok.svg',
        href:props.wordpressData.social_link?.tiktok_link ?? '',
      },
      {
        id:6,
        name:'XiaoHongShu',
        icon: '/images/ma2024/family-fun/social_xhs.svg',
        href:props.wordpressData.social_link?.xiaohongshu_link ?? '',
      },
      {
        id:7,
        name:'Linkedin',
        icon: '/images/ma2024/family-fun/social_linkedin.svg',
        href:props.wordpressData.social_link?.linkedin_link ?? '',
      }
    ].filter((item)=>!!item.href);
  }

  if(props.wordpressData?.freebies){
    freebies_list.value = props.wordpressData.freebies;
  }

  if (props.wordpressData?.programme_post) {
    const suffixEN = 'Sep 2024';
    const suffixCN = '年9月';
    const language = checkLang(); // Set to 'en' for English or any other value for Chinese

    for (const item of props.wordpressData.programme_post) {
      const totalDatesLength = item.dates.length;

      if (!item.image) {
        item.image = cdn('images/ma2024/family-fun/card_placeholder.png');
      }

      if (!item.date) {
        if (totalDatesLength > 0) {
          let tempDateArr = item.dates.map((dateIndex) => dates[dateIndex].dayVal).sort((a, b) => a - b);

          if (language !== 'en') {
            if (totalDatesLength === 1) {
              item.date_str = `2024年9月${tempDateArr[0]}日`;
            } else {
              let firstDate = tempDateArr[0];
              let lastDate = tempDateArr[totalDatesLength - 1];

              if (lastDate - firstDate === totalDatesLength - 1) {
              // Consecutive dates
                item.date_str = `2024年9月${firstDate}至${lastDate}日`;
              } else if (totalDatesLength === 2) {
              // Two non-consecutive dates
                item.date_str = `2024年9月${tempDateArr.join('与')}日`;
              } else {
              // More than two dates with gaps
                item.date_str = `2024年9月${firstDate}至${lastDate}日`;
              }
            }
          } else {
            if (totalDatesLength === 1) {
              item.date_str = `${tempDateArr[0]} ${suffixEN}`;
            } else {
              let lastDate = tempDateArr.pop();
              item.date_str = `${tempDateArr.join(', ')} & ${lastDate} ${suffixEN}`;
            }
          }
        }
      } else {
        item.date_str = item.date;
      }

      programmes_list.value.push(item);

      for (const dateIndex of item.dates) {
        if (!programmesByDates.value[dateIndex]) programmesByDates.value[dateIndex] = [];
        programmesByDates.value[dateIndex].push(item);
      }
    }
  }



});


onMounted(() => {

  document.documentElement.addEventListener('click', (event) => {
    const isShowSelection = showSelection.value;
    //console.log(event,isShowSelection);
    var target = event.target || event.srcElement || event.currentTarget;
    if(document.getElementById('selectioncontainer')?.contains(target)){
      toggleSelection();
    }
    else if (!document.getElementById('selectionbox')?.contains(target)){
      //console.log('not in selectionbox');
      showSelection.value = false;
    }else{
      //console.log('in selectionbox');
    }
  });

  handleOnResize();
  window.addEventListener('resize', handleOnResize);

  const element = programTabListRef.value;
  if (element) {
    element.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition(); // Initial check on mount
  }

  const canView = !props.wordpressData?.canView?.includes('dialog.notice.exhibition');
  exhibitionNoticeModalOpen.value = canView;
});

onBeforeUnmount(() => {
  const element = programTabListRef.value;
  if (element) {
    element.removeEventListener('scroll', checkScrollPosition);
  }
  window.removeEventListener('resize', handleOnResize);
});

const aagActiveIndex = ref(1);

const programme_filter_list: ComputedRef<{key:MaFamilyFunProgrammeCategoryFilterType,label:string}[]> = computed(()=>[
  {
    key:'all',
    label:t('ma_family_fun_category_all'),
  },
  {
    key:'activities',
    label:t('ma_family_fun_category_activities'),
  },
  {
    key:'workshops',
    label:t('ma_family_fun_category_workshops'),
  },
  {
    key:'performances',
    label:t('ma_family_fun_category_performances'),
  },
  {
    key:'online',
    label:t('ma_family_fun_category_online'),
  }
]);



const showSelection = ref(false);
const toggleSelection = ()=>{
  showSelection.value = !showSelection.value;
};

const toggleSelected = (item:string)=>{
  if(selectedFilterDate.value.includes(item)){
    selectedFilterDate.value.splice(selectedFilterDate.value.indexOf(item),1);
  }else{
    selectedFilterDate.value.push(item);
  }
};

const isMobile = ref(window.innerWidth <= 1024);

function handleOnResize() {
  isMobile.value = window.innerWidth <= 1024;
}


const programmeModal = ref<{show:boolean,data?:MaFamilyFunProgrammePostType}>({
  show:false,
});
const openProgrammeModal = (evt:MaFamilyFunProgrammePostType)=>{
  programmeModal.value.data = evt;
  programmeModal.value.show = true;
  modalKey.value = Date.now();
};


const resetFilter = (config:{
  pagination?:boolean,
  filter_date?:boolean,
  filter_category?:boolean
}) =>{
  setTimeout(() => {
    if(config.pagination)
      pagination.currentPage = 1;
    if(config.filter_date){
      selectedFilterDate.value = [];
    }
    if(config.filter_category)
      post_filter.value = 'all';
  },300);
};

function handleClickPagination(index:number, event: string){
  if (event === 'prev') {
    pagination.currentPage -= 1;
  } else if (event === 'next') {
    pagination.currentPage += 1;
  } else {
    pagination.currentPage = index;
  }
  handleProgrammeScroll();
}

const handleProgrammeScroll = () => {
  const element = document.getElementById('ma_family_fun_menu_programmes');
  const topNav = document.getElementById('top-nav');
  const topNavHeight = topNav!.getBoundingClientRect().height!;
  const temp = props.offsetY ?? topNavHeight;
  if (element) {
    const elementRect = element.getBoundingClientRect().top;
    const bodyRect = document.body.getBoundingClientRect().top;
    const offset = elementRect - bodyRect - temp;

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

};

</script>

<template>
  <div :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ma-navigation="true"
      :ma2-navigation="wordpressData?.canView?.includes('sidebar.ma2')"
      :disable-top-padding="true"
    />
    <ma-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- header -->
    <section class="tw-bg-sg-ma24-yellow tw-relative">
      <div
        :class="[
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
          'tw-text-sg-ma-h2-mobile sgTablet:tw-text-sg-ma-h2-tablet sgDesktop:tw-text-sg-ma-h2-desktop',
          'tw-text-center',
          'tw-absolute tw-top-0',
          'tw-w-full',
          'tw-top-[52px] sgDesktop:tw-top-[68px]',
          'tw-mt-[60px]',
          'tw-flex tw-items-center tw-justify-center tw-flex-col',
        ]"
      >
        <div
          id="ma-family-fun-title"
          :class="[
            'tw-relative tw-w-full tw-text-sg-ma24-purple-b40 tw-text-center',
            { 'tw-pl-[60px]': checkLang() != 'en' },
            { 'tw-max-w-full sgTablet:tw-max-w-full sgDesktop:tw-max-w-full': checkLang() != 'en' }
          ]"
          v-html="isMobile ? $t('ma_family_fun_title_2') : $t('ma_family_fun_title')"
        />

        <div
          v-if="checkLang() != 'en'"
          id="ma-family-fun-title"
          class="tw-relative tw-w-full tw-text-sg-ma24-purple-b40 tw-text-center"
          v-html="$t('ma_family_fun_title2')"
        />
      </div>

      <div
        :class="[
          'tw-max-w-[1440px] tw-mx-auto',
          'tw-h-[765px] sgTablet:tw-h-[1228px] sgTablet2:tw-h-[1400px] sgDesktop:tw-h-[1100px]',
        ]"
      >
        <img
          :src="$cdn('/images/ma2024/family-fun/banner_2.scaled.png')"
          class="tw-w-full tw-object-contain tw-h-full"
        >
      </div>
    </section>

    <!-- description -->
    <section
      id="ma_family_fun_menu_about"
      :class="[
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        'tw-py-5 sgTablet:tw-py-10 sgDesktop:tw-py-[60px]',
        'tw-bg-gradient-to-b tw-from-sg-ma24-yellow tw-to-sg-ma24-pink-w20',
        'tw-shadow-t-bg-bug tw-shadow-sg-ma24-yellow',
      ]"
    >
      <div
        :class="[
          'tw-text-center',
          'tw-text-sg-ma24-purple-b40',
          'tw-max-w-[1000px] tw-w-full tw-mx-auto',
        ]"
      >
        <div
          :class="[
            'tw-text-sg-ma-h3-mobile sgTablet:tw-text-sg-ma-h3-tablet sgDesktop:tw-text-sg-ma-h3-desktop',
            { 'tw-hidden' : headerTitle === '' },
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
          :style="{
            letterSpacing:'0.015em'
          }"
        >
          {{ headerTitle }}
        </div>
        <div
          v-for="(desc, index) in headerDescriptions"
          :key="'desc-' + index"
          :class="[
            'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1',
            'tw-tracking-wider',
            'tw-mt-8 sgDesktop:tw-mt-10'
          ]"
          v-html="desc"
        />
      </div>
    </section>

    <!-- freebies -->
    <section
      :class="[
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        'tw-py-5 sgTablet:tw-py-10 sgDesktop:tw-py-[60px]',
      ]"
    >

      <div class="tw-max-w-[1000px] tw-mx-auto">
        <div>
          <div
            :class="[
              'tw-text-sg-ma-h3-mobile sgTablet:tw-text-sg-ma-h3-tablet sgDesktop:tw-text-sg-ma-h3-desktop',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
              'tw-text-sg-ma24-purple-b40',
              'tw-text-center'
            ]"
            :style="{
              letterSpacing:'0.015em'
            }"
          >
            {{ $t('ma_family_fun_section_2_title') }}
          </div>
          <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-10 tw-mt-8 sgDesktop:tw-mt-10">

            <div
              v-for="row in freebies_list"
              :key="row.title"
              class="tw-w-[240px] sgDesktop:tw-w-[280px]"
            >
              <div
                :class="[
                  'tw-bg-sg-ma24-pink-w20 tw-rounded-[200px]',
                  'tw-flex tw-items-center tw-justify-center',
                  'tw-w-[180px] sgTablet:tw-w-[220px] sgDesktop:tw-w-[280px]',
                  'tw-h-[180px] sgTablet:tw-h-[220px] sgDesktop:tw-h-[280px]',
                  'tw-mx-auto'
                ]"
              >
                <img
                  :src="row.image"
                  class="tw-w-full tw-h-full tw-object-contain"
                >
              </div>

              <div
                :class="[
                  { 'tw-font-akkuratbold' : checkLang() == 'en' },
                  { 'tw-font-notosansbold' : checkLang() != 'en' },
                  'tw-mt-6',
                  'tw-text-sg-ma-sh1',
                  'tw-text-sg-ma24-purple-b40',
                  'tw-text-center'
                ]"
              >
                {{ row.title }}
              </div>

              <div
                :class="[
                  'tw-mt-6',
                  { 'tw-font-akkuratbold' : checkLang() == 'en' },
                  { 'tw-font-notosansbold' : checkLang() != 'en' },
                  'tw-text-sg-ma-sh3',
                  'tw-text-[#1A1A1A]',
                  'tw-tracking-[0.03em]',
                  'tw-text-center'
                ]"
              >
                {{ row.datetime }}
              </div>
              <div
                :class="[
                  'tw-mt-1',
                  'tw-text-sg-body-3-mobile sgTablet:tw-text-sg-body-3-tablet sgDesktop:tw-text-sg-body-3',
                  'tw-text-sg-ma24-purple-b40',
                  'tw-tracking-wider',
                  'tw-text-center'
                ]"
              >
                {{ row.venue }}
              </div>
            </div>

          </div>
          <div
            :class="[
              'tw-mt-8 sgTablet:tw-mt-10',
              'tw-text-center',
              'tw-text-sg-caption',
              'tw-tracking-wider',
              'tw-text-sg-ma24-purple-b40'
            ]"
          >
            <div>
              {{ $t('ma_family_fun_section_2_caption_1') }}
            </div>
            <div class="mt-1">
              {{ $t('ma_family_fun_section_2_caption_2') }}
            </div>
          </div>

        </div>

        <div
          v-if="showSocial"
          class="tw-text-center tw-mt-[60px]"
        >
          <div
            :class="[
              'tw-text-sg-ma-h4-mobile sgTablet:tw-text-sg-ma-h4-tablet sgDesktop:tw-text-sg-ma-h4-desktop',
              'tw-text-sg-ma24-purple-b40',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            <div>
              {{ $t('ma_family_fun_section_3_title') }}
            </div>
          </div>

          <div
            :class="[
              'tw-flex tw-flex-wrap tw-justify-center sgTablet:tw-justify-between',
              'tw-gap-y-5 tw-gap-x-4 sgTablet:tw-gap-0',
              'tw-mt-8 sgDesktop:tw-mt-10'
            ]"
          >
            <div
              v-for="item in social_media_list"
              :key="item.id"
            >
              <a
                :href="item.href"
                target="_blank"
                rel="noopener"
                class="tw-cursor-pointer tw-fill-sg-ma24-yellow-w20 hover:tw-fill-sg-ma24-yellow-w60"
              >
                <inline-svg
                  :src="$cdn(item.icon)"
                  class="tw-h-14 tw-w-14"
                />
              </a>

            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- calendar -->
    <section
      id="ma_family_fun_menu_festival_calendar"
      :class="[
        'tw-hidden',
        'tw-bg-sg-ma24-pink-w20',
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        'tw-pt-5 sgTablet:tw-pt-10 sgDesktop:tw-pt-[60px]',
        'tw-pb-5 sgDesktop:tw-pb-10',
      ]"
    >

      <div class="tw-relative tw-max-w-[1000px] tw-mx-auto">
        <div
          :class="[
            'tw-text-sg-h3-mobile sgTablet:tw-text-sg-h3-tablet sgDesktop:tw-text-sg-h3',
            'tw-text-sg-ma24-purple-b40',
            'tw-text-center'
          ]"
          :style="{
            letterSpacing:'0.015em'
          }"
          v-html="$t('ma_family_fun_section_4_title')"
        />

        <div
          :class="[
            'tw-mt-5 sgTablet:tw-mt-8 sgDesktop:tw-mt-10',
            'tw-flex',
          ]"
          :style="{
            'boxShadow':'-4px 4px 10px 0px #0000004D'
          }"
        >
          <div
            v-for="[rowkey,row] in Object.entries(dates)"
            :key="row.index"
            class=" tw-flex tw-transition-all tw-duration-300"
            :class="{
              'tw-flex-grow':row.index==aagActiveIndex,
              'tw-bg-sg-ma24-yellow-w60':row.index==1,
              'tw-bg-sg-ma24-yellow-w40':row.index==2,
              'tw-bg-sg-ma24-yellow-w20':row.index==3,
            }"
          >
            <div
              :class="[
                'tw-py-4 sgTablet:tw-py-6 sgDesktop:tw-py-9',
                'tw-px-3 sgTablet:tw-px-4 sgDesktop:tw-px-5',
                'tw-w-[56px] sgTablet:tw-w-[64px] sgDesktop:tw-w-[72px]',
                'tw-overflow-hidden',
                'tw-flex-shrink-0',
                {
                  'tw-cursor-pointer tw-group/group-handle':row.index!=aagActiveIndex
                }
              ]"
              @click="aagActiveIndex=row.index"
            >
              <inline-svg
                :src="$cdn('/images/ma2024/family-fun/unfold_more.svg')"
                class="tw-m-[2px] tw-h-7 tw-w-7 tw-fill-sg-ma24-yellow-b20"
                :class="{
                  'group-hover/group-handle:tw-fill-sg-ma24-purple-b40':row.index!=aagActiveIndex
                }"
              />
              <div class="tw-mt-5">
                <div
                  :class="[
                    '-tw-rotate-90',
                    'tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1',
                    'tw-text-sg-ma24-purple-b40',
                    'tw-w-[250px]',
                    'tw-h-[250px]',
                    'tw-text-right',
                    'tw-align-bottom',
                  ]"
                  :style="{
                    letterSpacing:'0.02em'
                  }"
                >
                  {{ checkLang()=='en'? row.aag_handle_en : row.aag_handle_cn }}
                </div>
              </div>
            </div>
            <div
              v-show="row.index==aagActiveIndex"
              :class="[
                'tw-px-3 sgTablet:tw-px-4 sgDesktop::tw-px-5',
                'tw-py-5 sgTablet:tw-py-7 sgDesktop:tw-py-10',
              ]"
            >
              <div

                class="tw-max-w-[600px] tw-space-y-5"
              >
                <div
                  v-for="programmeRow in programmesByDates[row.index]"
                  :key="programmeRow.title"
                  :class="[
                    'tw-text-sg-ma24-purple-b40'
                  ]"
                >
                  <div
                    :class="[
                      'tw-text-xs sgTablet:tw-text-[16px] sgDesktop:tw-leading-[20px] sgDesktop:tw-text-xl'
                    ]"
                    :style="{
                      letterSpacing:'0.03em'
                    }"
                  >
                    {{ $t(`ma_family_fun_category_${programmeRow.category}`) }}
                  </div>
                  <div
                    :class="[
                      'tw-text-sg-sh2-mobile sgTablet:tw-text-sg-sh2-tablet sgDesktop:tw-text-sg-sh2',
                      'tw-line-clamp-6 sgTablet:tw-line-clamp-4 sgDesktop:tw-line-clamp-3'
                    ]"
                    :style="{
                      letterSpacing:'0.02em'
                    }"
                  >
                    {{ programmeRow.title }}
                  </div>
                  <div
                    :class="[
                      'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1',
                      'tw-tracking-wider',
                      'tw-mt-2'
                    ]"
                  >
                    {{ programmeRow.time }}
                  </div>
                  <div
                    :class="[
                      'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1',
                      'tw-tracking-wider',
                    ]"
                  >
                    {{ programmeRow.venue }}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


        <img
          :src="$cdn('/images/ma2024/family-fun/event.png')"
          :class="[
            'tw-absolute tw-right-0',
            'tw-h-[80px] sgTablet:tw-h-[120px] sgDesktop:tw-h-[200px]',
            '-tw-bottom-[60px] sgTablet:-tw-bottom-[80px] sgDesktop:-tw-bottom-[140px]'
          ]"
        >
      </div>
    </section>

    <!-- programmes -->
    <section
      id="ma_family_fun_menu_programmes"
      :class="[
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        'tw-py-5 sgTablet:tw-py-10 sgDesktop:tw-py-[60px]',
        'tw-bg-gradient-to-b tw-from-sg-ma24-pink-w20 tw-to-sg-ma24-yellow',
        'tw-shadow-t-bg-bug tw-shadow-sg-ma24-pink-w20',
      ]"
    >
      <div class="tw-max-w-[1000px] tw-mx-auto">
        <!-- title -->
        <div
          :class="[
            'tw-text-sg-ma-h3-mobile sgTablet:tw-text-sg-ma-h3-tablet sgDesktop:tw-text-sg-ma-h3-desktop',
            'tw-text-sg-ma24-purple-b40',
            'tw-text-center',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
          :style="{
            letterSpacing:'0.015em'
          }"
        >
          {{ $t('ma_family_fun_section_5_title') }}
        </div>

        <!-- main tab -->
        <div
          :class="[
            'tw-mt-8 sgDesktop:tw-mt-10',
            'tw-flex sgTablet:tw-justify-center',
          ]"
        >

          <!-- right arrow button -->
          <img
            v-if="isProgramStart"
            id="ma-program-tab-left-button"
            :class="[
              'tw-cursor-pointer',
              'sgTablet:tw-hidden',
            ]"
            style="transform: rotate(180deg);"
            :src="$cdn('/icons/ma/program_more_button.svg')"
            @click="scrollLeft()"
          >

          <!-- tabs -->
          <div
            ref="programTabListRef"
            :class="[
              'tw-flex',
              'tw-gap-x-3 sgDesktop:tw-gap-x-5',
              'tw-overflow-x-auto',
              'scrollbar-hide',
            ]"
          >
            <button
              v-for="item in programme_filter_list"
              :key="item.key"
              :class="[
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
                'tw-flex tw-items-center tw-justify-center tw-flex-shrink-0',
                'tw-text-sg-ma-bt1 tw-leading-[16px]',
                'tw-bg-sg-ma24-purple-w60/25 tw-text-sg-ma24-purple-b40',
                'tw-rounded-full tw-text-center tw-cursor-pointer ',
                'tw-py-3 tw-px-6',
                'tw-uppercase',
                {
                  '!tw-bg-sg-ma24-purple-b40 tw-text-white':item.key==post_filter,
                  'hover:tw-bg-sg-ma24-purple-w60 active:tw-bg-sg-ma24-purple-b40 active:tw-text-white':item.key!=post_filter,
                }
              ]"
              @click="resetFilter({pagination:true});post_filter=item.key"
            >
              {{ item.label }}
            </button>

          </div>
          <!-- right arrow button -->
          <img
            v-if="isProgramEnd"
            id="ma-program-tab-right-button"
            :class="[
              'tw-cursor-pointer',
              'sgTablet:tw-hidden',
            ]"
            :src="$cdn('/icons/ma/program_more_button.svg')"
            @click="scrollRight()"
          >
        </div>

        <!-- day filter -->
        <div

          :class="[
            'tw-flex tw-justify-center tw-relative',
            'tw-mt-3 sgTablet2:tw-mt-4 sgDesktop:tw-mt-5'
          ]"
        >
          <div
            id="selectionbox"
            :class="[
              'tw-flex tw-gap-x-5 tw-gap-y-3 tw-flex-wrap tw-justify-center',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            <div
              v-for="[rowkey,row] of Object.entries(dates)"
              :key="rowkey"
              :class="[
                'tw-text-center tw-text-sg-ma24-orange-b60 tw-text-sg-button-1',
                'tw-pl-4 tw-pr-6 tw-py-[10px]',
                'tw-bg-sg-ma24-orange-w60 tw-bg-opacity-[0.25]',
                'tw-rounded-[22px]',
                'hover: tw-cursor-pointer',
                'tw-border-2 tw-border-sg-ma24-orange-b60',
                {
                  '!tw-bg-sg-ma24-orange-b60 !tw-text-white': selectedFilterDate.includes(rowkey),
                }
              ]"
              @click="resetFilter({pagination:true}),toggleSelected(rowkey);"
            >
              <div
                :class="[
                  'tw-flex tw-gap-2 tw-items-center',
                ]"
              >
                <img
                  v-if="selectedFilterDate.includes(rowkey)"
                  class="tw-h-5 tw-w-5 tw-self-center"
                  :src="$cdn('/icons/ma/program_filter_check.svg')"
                >
                <img
                  v-else
                  class="tw-h-5 tw-w-5 tw-self-center"
                  :src="$cdn('/icons/ma/program_filter_add.svg')"
                >
                <div>
                  {{ checkLang()=='en'? row.filter_en : row.filter_cn }}
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- cards -->
        <div class="tw-mt-5 sgTablet:tw-mt-8 sgDesktop:tw-mt-10">
          <div
            :class="[
              'tw-flex tw-flex-wrap tw-justify-center tw-mx-auto',
              'tw-gap-3 sgTablet:tw-gap-4 sgDesktop:tw-gap-5',
            ]"
          >
            <ma-family-fun-programme-card
              v-for="row in paginated_filtered_prorgammes_list"
              :key="row.title"
              :event="row"
              @open-modal="openProgrammeModal"
            />

          </div>


        </div>

        <!-- Pagination Part -->
        <div
          v-show="filtered_prorgammes_list.length > 0"
          class="tw-mt-8 sgDesktop:tw-mt-10 tw-flex tw-justify-center"
        >
          <div
            :class="[
              'tw-relative tw-flex tw-gap-3'
            ]"
          >
            <div>
              <button
                class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full"
                :disabled="pagination.currentPage === 1"
                @click="handleClickPagination(1,'prev')"
              >
                <inline-svg
                  alt="Previous Page"

                  :class="{
                    ' [&>path]:tw-fill-sg-ma24-purple-b40 [&>path]:hover:tw-fill-white [&>rect]:tw-stroke-sg-ma24-purple-b40 [&>rect]:hover:tw-fill-sg-ma24-purple-b40': pagination.currentPage !== 1,

                  } "
                  :src="pagination.currentPage === 1 ?
                    $cdn('icons/pagination/keyboard_arrow_left_disable.svg') :
                    $cdn('icons/pagination/keyboard_arrow_left.svg')"
                />
              </button>
            </div>

            <button
              v-for="number in lastPage"
              :key="number"
              :class="{
                'tw-bg-sg-ma24-purple-b40 tw-text-white': number === pagination.currentPage,
                'tw-text-sg-ma24-purple-b40 hover:tw-border-2 tw-[border-style:inset] tw-border-sg-ma24-purple-b40': number !== pagination.currentPage,
              }"
              class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full"
              @click="handleClickPagination(number, 'page')"
            >
              <span>
                {{ number }}
              </span>
            </button>

            <button
              class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full"
              :disabled="pagination.currentPage === lastPage"
              @click="handleClickPagination(lastPage,'next')"
            >
              <inline-svg
                alt="Next Page"
                :src="pagination.currentPage === lastPage ?
                  $cdn('icons/pagination/keyboard_arrow_right_disable.svg') :
                  $cdn('icons/pagination/keyboard_arrow_right.svg')"
                :class="{
                  ' [&>path]:tw-fill-sg-ma24-purple-b40 [&>path]:hover:tw-fill-white [&>rect]:tw-stroke-sg-ma24-purple-b40 [&>rect]:hover:tw-fill-sg-ma24-purple-b40': pagination.currentPage !== lastPage,
                } "
              />
            </button>
          </div>
        </div>
      </div>



      <ma-family-fun-programme-modal
        :key="modalKey"
        v-model="programmeModal.show"
        :data="programmeModal.data"
      />

    </section>

    <section
      v-if="false"
      id="ma_family_fun_menu_personality_quiz"
      :class="[
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        'tw-py-5 sgTablet:tw-py-10 sgDesktop:tw-pb-[60px]',
        'tw-bg-gradient-to-b tw-from-sg-ma24-yellow tw-to-sg-ma24-pink'
      ]"
    >
      <div
        :class="[
          'tw-max-w-[800px] tw-mx-auto',
          'tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-8 sgTablet:tw-gap-5'
        ]"
      >
        <div class="tw-flex tw-flex-shrink-0 tw-self-center tw-h-[200px] sgTablet2:tw-h-auto sgTablet2:tw-w-[390px]">
          <img
            :src="$cdn('images/ma2024/family-fun/personality-quiz.png')"
            class="tw-w-full tw-object-contain"
          >
        </div>
        <div>
          <div
            :class="[
              'tw-text-sg-h3-mobile sgTablet:tw-text-sg-h3-tablet sgDesktop:tw-text-sg-h3',
              'tw-text-sg-ma24-purple-b40',
              'tw-text-center',
            ]"
            :style="{
              letterSpacing:'0.015em'
            }"
          >
            {{ $t('ma_family_fun_section_6_title') }}
          </div>
          <div
            :class="[
              'tw-mt-5',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1',
              'tw-tracking-wider',
              'tw-text-center',
              'tw-text-sg-ma24-purple-b40'
            ]"
          >
            {{ $t('ma_family_fun_section_6_desc') }}
          </div>
          <div class="tw-flex tw-justify-center tw-mt-8 sgDesktop:tw-mt-10">
            <a
              :href="$langRedirect('/festivals/mid-autumn/family-fun/personality-quiz')"
              target="_blank"
              rel="noopener"
              class="tw-rounded-[26px] tw-bg-white tw-text-[#484848] tw-px-6 tw-py-4 tw-text-sg-button-mobile-1 sgTablet:tw-text-sg-button-1 !tw-leading-[20px] tw-h-[52px] tw-uppercase tw-flex tw-items-center"
            >
              <span class="tw-font-notosansbold">{{ $t('ma_family_fun_take_the_quiz') }}</span>
              <span class="tw-ml-2">
                <inline-svg
                  :src="$cdn('/images/ma2024/family-fun/arrow_forward_ios.svg')"
                  fill="#484848"
                  class="tw-h-5 tw-w-5"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section
      id="ma_family_fun_menu_personality_quiz"
      :class="[
        'sgDesktop:tw-px-[120px] sgTablet:tw-px-[80px] tw-px-4',
        'sgDesktop:tw-pb-[60px] sgTablet:tw-py-10 tw-py-4',
        'tw-relative tw-w-full tw-h-full',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        {'tw-hidden':wordpressData.canView?.includes('sidebar.ma2') === false}
      ]"
      :style="{
        background: 'linear-gradient(180deg, #FDC404 0%, #F8D5E5 100%)'
      }"
    >
      <div class="tw-max-w-[800px] tw-w-full tw-flex tw-flex-col sgTablet:tw-flex-row tw-justify-center tw-items-center tw-gap-5">
        <img
          class="tw-w-auto tw-h-[240px]"
          :src="$cdn('images/ma2024/family-fun/personality-quiz.png')"
        >
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-max-w-[400px]">
          <!-- title -->
          <div
            :class="[
              'tw-text-sg-ma24-purple-b40',
              'tw-text-sg-ma-mobile-h3 sgDesktop:tw-text-sg-ma-desktop-h3',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_family_fun_read_more_title') }}
          </div>
          <!-- description -->
          <div
            :class="[
              'tw-mt-5',
              'tw-text-sg-ma-body',
              'tw-text-sg-ma24-purple-b40'
            ]"
          >
            {{ $t('ma_family_fun_read_more_description') }}
          </div>
          <!-- button -->
          <a
            class="tw-mt-10"
            target="_blank"
            rel="noopener"
            :href="$langRedirect('/festivals/mid-autumn/personality-quiz', checkLang())"
          >
            <div
              :class="[
                'tw-pl-6 tw-pr-4',
                'tw-py-[18px]',
                'tw-rounded-[26px]',
                'tw-cursor-pointer',
                'tw-bg-white',
                'hover:tw-underline',
                'active:tw-underline',
                'active:tw-bg-sg-ma24-pink-w60',
                'tw-text-[#484848] tw-text-sg-ma-bt1',
                'tw-flex tw-items-center tw-justify-center tw-gap-2',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
              style="text-transform: uppercase;"
            >
              <div>
                {{ $t('ma_family_fun_discover_more') }}
              </div>
              <img
                class="tw-h-5 tw-w-5"
                :src="$cdn('/images/ma2024/family-fun/arrow_forward_ios.svg')"
              >
            </div>

          </a>

        </div>

      </div>
    </section>
    <ma-partner-section />
    <ap-exhibition-notice-modal
      v-model="exhibitionNoticeModalOpen"
      :show-primary-button="false"
    />
    <ap-footer />
  </div>
</template>
