<script lang="ts" setup>
import { onMounted, watch } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../../../support/cdn';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../../support/helper';

const { t } = useI18n();
const currentQuestionNo = ref(0);
const totalQuestionNo = ref(4);
const questions = ref({
  1: {
    title_en: 'It’s morning assembly. What are you up to?',
    title_cn: '早上升旗礼时，你在干嘛？',
  },
  2: {
    title_en: 'It’s maths period. Are you paying attention?',
    title_cn: '上数学课时，你有专心听课吗？',
  },
  3: {
    title_en: 'It’s recess time! Where are you?',
    title_cn: '下课休息时间到了，你会在哪里？',
  },
  4: {
    title_en: 'It’s time for CCA. What kind of CCA are you in?',
    title_cn: '课外活动时间到了，你参加什么活动？',
  },
});
const options = ref({
  1: {
    'class-clown': {
      option_en: 'Talking, talking, talking',
      option_cn: '说话说个不停',
    },
    'model-student': {
      option_en: 'Taking my position in front, all ready to raise the flag',
      option_cn: '在队伍前立正就位，专注升旗礼',
    },
    'recess-foodie': {
      option_en: 'Trying my best not to fidget or fall asleep',
      option_cn: '尽力不动或睡着',
    },
    'pai-kia': {
      option_en: 'Standing outside the school gates ‘cause I’m late',
      option_cn: '迟到了，被拒校门外',
    },
  },
  2: {
    'class-clown': {
      option_en: 'Oops! I was talking to my friends',
      option_cn: '嗯，我在和朋友说话',
    },
    'model-student': {
      option_en: 'Duh, exams are coming',
      option_cn: '当然！要考试了',
    },
    'recess-foodie': {
      option_en: 'Calculating if I can buy ice cream later',
      option_cn: '盘算着待会去买冰淇淋',
    },
    'pai-kia': {
      option_en: 'Wait, it’s maths now???',
      option_cn: '等一下，现在是上数学课吗？',
    },
  },
  3: {
    'class-clown': {
      option_en: 'At the noisiest table in the canteen',
      option_cn: '食堂里最吵闹的桌子',
    },
    'model-student': {
      option_en: 'In class, doing my homework',
      option_cn: '在课室里做功课',
    },
    'recess-foodie': {
      option_en: 'Queuing up for my favourite food',
      option_cn: '排队买我最爱吃的东西',
    },
    'pai-kia': {
      option_en: 'Snuck out to buy food elsewhere',
      option_cn: '偷溜出去买吃的',
    },
  },
  4: {
    'class-clown': {
      option_en: 'Performing arts! I have a flair for performing on stage',
      option_cn: '表演艺术！我最喜欢在台上表演了',
    },
    'model-student': {
      option_en: 'Uniformed groups! Order and precision are my thing',
      option_cn: '制服团体！我超讲究纪律和精确度',
    },
    'recess-foodie': {
      option_en: 'Clubs and societies! I love hobbies and making friends',
      option_cn: '学会和社团！我喜欢培养新的爱好、结交新朋友',
    },
    'pai-kia': {
      option_en: 'Sports! I’m here to win',
      option_cn: '体育活动！我就是要赢！',
    },
  },
});
const answers = ref<string[]>([]);
const results = ref({
  'class-clown': {
    title_en: 'Class Clown',
    title_cn: '班上小丑',
    desc_en: 'You are well-liked by all your peers and always lighten the mood in class. However, you can be disruptive at times and should learn to read the room.',
    desc_cn: '你蛮受其他同学欢迎，也常让班上气氛变轻松。你有时候会扰乱秩序，可以学会察言观色。',
    image: cdn('images/experience/remix/remix_personality_quiz_result_01.v2.jpg'),
  },
  'model-student': {
    title_en: 'Model Student',
    title_cn: '模范生',
    desc_en: 'You have an exemplary attitude and are a pleasure to have in class. You may wish to consider offering help to your classmates who need it.',
    desc_cn: '你的态度值得效仿，班上有你真好。你可以考虑帮其他需要帮助的同学。',
    image: cdn('images/experience/remix/remix_personality_quiz_result_02.v2.jpg'),
  },
  'recess-foodie': {
    title_en: 'Recess Foodie',
    title_cn: '休息时间吃货',
    desc_en:
      'You have potential to do well, but can benefit from paying closer attention in class especially when recess time is drawing near.',
    desc_cn: '你很有潜力，不过如果上课时更专心，尤其是在休息时间逼近的时候，表现会更好。',
    image: cdn('images/experience/remix/remix_personality_quiz_result_03.v2.jpg'),
  },
  'pai-kia': {
    title_en: 'Pai Kia',
    title_cn: '问题学生',
    desc_en: 'You should avoid getting into trouble and try to focus your energy on your studies and your CCAs.',
    desc_cn: '你应该停止闯祸，把精力集中在学习和课外活动上。',
    image: cdn('images/experience/remix/remix_personality_quiz_result_04.v2.jpg'),
  },
});
const result = ref<{ title_en: string; title_cn: string; desc_en: string; desc_cn: string; image: string }>({
  title_en: '',
  title_cn: '',
  desc_en: '',
  desc_cn: '',
  image: '',
});
const currentQuestion = ref<{ title_en: string; title_cn: string }>({ title_en: '', title_cn: '' });
const currentOptions = ref<[string, { option_en: string; option_cn: string }][]>([]);
const showQuestion = ref(false);
const showResult = ref(false);
const showCover = ref(true);

const validateAnswer = (answer: string) => {
  answers.value.push(answer);
  if (currentQuestionNo.value == totalQuestionNo.value) {
    const calc = calculateAnswers();
    result.value = results.value[calc[0]];
    showResult.value = true;

    //showQuestion.value = false;
    return;
  } else {
    currentQuestionNo.value++;
    currentQuestion.value = questions.value[currentQuestionNo.value];
    if (currentQuestionNo.value - 1 == totalQuestionNo.value - 1) {
      const calc = calculateAnswers();
      if (calc.length == 2) {
        let tempOptions = [];
        calc.forEach((c) => {
          tempOptions.push([c, options.value[currentQuestionNo.value][c]]);
        });
        currentOptions.value = tempOptions;
      } else {
        currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
      }
    } else {
      currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
    }
    return;
  }
};

let scrollPosition = 0;

const showModal = () => {
  // scrollPosition = window.pageYOffset;
  // document.body.classList.add('no-scroll');
  // document.body.style.top = `-${scrollPosition}px`;
  // document.body.style.position = 'fixed';
};

const hideModal = () => {
  // document.body.classList.remove('no-scroll');
  // document.body.style.top = '';
  // document.body.style.position = '';
  // window.scrollTo(0, scrollPosition);
};

watch(showResult, (newValue) => {
  if (newValue) {
    showModal();
  } else {
    hideModal();
  }
});

const restartQuiz = () => {
  showCover.value = true;
  showQuestion.value = false;
  showResult.value = false;
  answers.value = [];
  result.value = { title_en: '', title_cn: '', desc_en: '', desc_cn: '', image: '' };
  currentQuestionNo.value = 0;
};

const startQuiz = () => {
  restartQuiz();
  showCover.value = false;
  showQuestion.value = true;
  showResult.value = false;
  currentQuestionNo.value = 1;
  currentQuestion.value = questions.value[currentQuestionNo.value];
  currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
};

const calculateAnswers = () => {
  let highest = 1;
  let highestCategory: string[] = [];
  const counts = answers.value.reduce((counts: any, answer) => {
    if (counts[answer]) {
      counts[answer]++;
    } else {
      counts[answer] = 1;
    }

    if (counts[answer] > highest) {
      highest = counts[answer];
      highestCategory = [answer];
    } else if (counts[answer] == highest) {
      highestCategory.push(answer);
    }
    return counts;
  }, {});
  highestCategory = highestCategory.sort();
  return highestCategory;
};
</script>

<template>
  <section
    :class="[
      'personality-quiz tw-relative',
      'tw-pt-[40px] sgTablet:tw-pt-[62px] sgTablet2:tw-pt-[74px] sgDesktop:tw-pt-[72px] sgDesktop2:tw-pt-[84px]',
      'tw-pb-[104px] sgTablet:tw-pb-[144px]',
      'tw-px-4 sgTablet:tw-px-10 sgTablet2:tw-px-20',
      'tw-flex tw-flex-col tw-justify-center tw-items-center',
    ]"
  >
    <div :class="['tw-flex tw-justify-center tw-relative tw-items-end', 'tw-max-w-[880px] tw-w-full']">
      <div class="tw-flex tw-flex-col tw-items-center tw-w-full">
        <template v-if="showCover">
          <div class="tw-flex tw-flex-col tw-items-center">
            <img
              class="tw-w-[240px] tw-mb-[30px] tw-drop-shadow-[0px_4px_8px_rgba(0,0,0,0.06)]"
              :src="$cdn('images/experience/remix/remix-personality-quiz-hero.v2.png')"
            >
            <button
              :class="[
                'ap-medium-shadow',
                // Base styles
                'tw-h-[56px] tw-py-3 tw-px-7 tw-rounded-[56px] tw-relative tw-bg-[#FF9300] tw-text-[#5A0B88] tw-text-sg-remix-sh1 tw-cursor-pointer',
                // Hover styles
                'hover:tw-bg-[#FFA933]',
                // Active styles
                'active:tw-bg-[#CC7600] active:tw-text-white before:active:tw-border-b-[#CC7600]', // Updated to border-b
                // Disabled styles
                'disabled:tw-bg-[#D1D1D1] disabled:tw-text-white disabled:tw-cursor-not-allowed',
                // Hover pseudo-elements
                'hover:before:tw-border-b-[#FFA933]', // Updated to border-b
                // Disabled pseudo-elements
                'before:disabled:tw-border-b-[#D1D1D1] before:tw-border-b-solid', // Updated to border-b
                { 'tw-font-akkuratbold': checkLang() == 'en' },
                { 'tw-font-notosansbold': checkLang() != 'en' },
              ]"
              class="before:tw-content-[''] before:tw-absolute before:tw-bottom-full before:tw-left-1/2 before:-tw-translate-x-1/2 before:tw-border-[10px] before:tw-border-transparent before:tw-border-b-[#FF9300]"
              @click="startQuiz"
            >
              {{ $t('cny_personality_quiz_lets_start') }}
            </button>
          </div>
        </template>
        <template v-else-if="showQuestion">
          <section
            :class="[
              'tw-flex tw-flex-col sgTablet:tw-flex-row tw-items-center',
              'tw-gap-5 sgTablet:tw-gap-10'
            ]"
          >
            <!-- Question -->
            <div class="tw-flex tw-max-w-[640px] tw-w-full tw-min-h-[96px]">
              <div
                :class="[
                  'tw-text-center tw-text-sg-sccc1',
                  'tw-text-sg-remix-mobile-h4 sgTablet:tw-text-sg-remix-tablet-h4 sgDesktop:tw-text-sg-remix-desktop-h4',
                  { 'tw-font-akkuratbold': checkLang() == 'en' },
                  { 'tw-font-notosansbold': checkLang() != 'en' },
                ]"
              >
                {{ checkLang() == 'en' ? currentQuestion.title_en : currentQuestion.title_cn }}
              </div>
            </div>
            <!-- Answer Options -->
            <div
              :class="[
                'tw-flex tw-flex-col tw-w-full',
                'tw-mt-5 sgTablet:tw-mt-7 sgDesktop:tw-mt-9',
                'tw-gap-4 sgTablet:tw-gap-5 sgDesktop:tw-gap-6',
                'tw-max-w-[640px]', // This makes the container grow to full width
              ]"
            >
              <template
                v-for="([optionKey, optionVal], index) in currentOptions"
                :key="index"
              >
                <div
                  :class="[
                    'personality-quiz-option',
                    'tw-w-full',
                    'tw-flex tw-items-center tw-justify-center tw-col-span-2',
                    'tw-bg-white disabled:tw-bg-[#D1D1D1]',
                    'tw-text-sg-remix-sh3',
                    'tw-text-[#1A1A1A] disabled:tw-text-white',
                    'tw-rounded-[76px] tw-h-[68px] tw-text-center tw-py-2 tw-px-5 sgTablet:tw-px-10 tw-cursor-pointer',
                    { 'tw-font-akkuratbold': checkLang() == 'en' },
                    { 'tw-font-notosansbold': checkLang() != 'en' },
                  ]"
                  @click="validateAnswer(optionKey)"
                >
                  <span v-html="checkLang() == 'en' ? optionVal.option_en : optionVal.option_cn" />
                </div>
              </template>
            </div>
          </section>

        </template>

        <div class="tw-h-[109px] sgTablet:tw-h-[215px] sgDesktop:tw-h-[111px] tw-w-full" />
        <!-- Stepper pagination -->
        <ol
          class="tw-flex tw-items-center tw-w-[min(calc(100vw-200px-24px),260px)] sgTablet:tw-w-[260px] tw-mx-auto"
        >
          <li
            v-for="i in totalQuestionNo - 1"
            :key="i"
            class="tw-flex tw-w-full tw-items-center after:tw-content-[''] after:tw-w-full after:tw-h-[2px] after:tw-border-b after:tw-border-[#192896] after:tw-border-2 after:tw-inline-block"
          >
            <span
              class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-[#192896]"
              :class="[i <= currentQuestionNo ? 'tw-bg-[#192896]' : 'tw-bg-transparent']"
            />
          </li>

          <li class="tw-flex tw-items-center">
            <span
              class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-[#192896]"
              :class="[currentQuestionNo === totalQuestionNo ? 'tw-bg-[#192896]' : 'tw-bg-transparent']"
            />
          </li>
        </ol>

        <div
          class="tw-flex tw-items-center tw-justify-center tw-mt-10 sgTablet:tw-mt-[60px]"
          :class="[showCover ? 'tw-text-[#A3A3A3]' : 'tw-text-[#192896]']"
        >
          <div
            class="tw-py-2 tw-px-4 tw-flex tw-items-center tw-z-[100] tw-text-sg-ma-bt1"
            :class="[
              { 'hover:tw-underline tw-underline-offset-4 tw-decoration-2': !showCover },
              { 'tw-font-akkuratbold': checkLang() == 'en' },
              { 'tw-font-notosansbold': checkLang() != 'en' },
              { 'tw-group/cny-personality-quiz-snack-restart-btn tw-cursor-pointer': !showCover },
            ]"
            @click="!showCover ? startQuiz() : null"
          >
            <span :class="['group-hover/cny-personality-quiz-snack-restart-btn:tw-underline']"> {{ $t('cny_personality_quiz_restart') }}</span>
            <inline-svg
              :fill="showCover ? '#A3A3A3' : '#192896'"
              :src="$cdn(`/icons/restart_alt.svg`)"
              class="tw-w-6 tw-h-6 tw-ml-2"
            />
          </div>
        </div>
      </div>
    </div>

    <remix-personality-quiz-result-modal
      v-model="showResult"
      :image="result?.image"
      @on-hide="restartQuiz"
    />
  </section>
</template>

<style scoped>
body.no-scroll {
  overflow: hidden;
}

.ap-medium-shadow{
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

.personality-quiz{
  background: linear-gradient(180deg, #FFF 0%, #EFE2C8 50%);
  backdrop-filter: blur(8px);
}

.personality-quiz-option {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

.personality-quiz-option:active {
  box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.30);
}

@media screen and (min-width: 1025px) {
  .personality-quiz-option:hover {
    box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.30);
  }
}
</style>
