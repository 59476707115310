<script lang="ts" setup>
import { ref, onMounted, nextTick, onBeforeUnmount, onBeforeMount} from 'vue';

const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen  = ref<boolean>(false);
const imageSourcesData = ref();
const videos_content   = ref<Video[]>([]);
const menuItems        = ref<any>([]);

interface Video {
  title: string;
  description: string;
  external_link: string;
  youtube_facebook_embed_link: string;
  secondary_nav_menu_title: string;
}

onBeforeMount(()=>{
  if(props.wordpressData?.videos_content) {
    videos_content.value = props.wordpressData.videos_content;
    menuItems.value = videos_content.value.map((x, index) => {
      return {
        label: x?.secondary_nav_menu_title ?? '',
        id: 'video-section-' + index,
      };
    });
  }
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

onMounted(()=>{
  nextTick(() => {
    // Add <sup> click listener
    const aboutDesc = document.getElementById('zy-explore-online');
    if(aboutDesc){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#zy-explore-online sup');
      supElements.forEach((supElement) => {

        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('zy-explore-online');

  if(burningJossCard){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#zy-explore-online sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});
</script>

<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :zy-navigation="true"
    />
    <zy-secondary-navigation
      :menu-items-data="menuItems"
      :not-translate="true"
    />

    <!-- Banner -->
    <section id="zy-explore-about">
      <div class="tw-relative">
        <picture>
          <img
            :src="$cdn('images/zy2024/banner.scaled.jpg')"
            class="tw-w-full tw-object-cover tw-max-h-40 sgTablet:tw-max-h-60 sgDesktop:tw-max-h-[280px]"
          >
        </picture>

        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center tw-px-4 ">
            <div
              class="tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 tw-pb-1"
            >
              {{ $t('zy_explore_online_title') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-mt-1"
            >
              {{ $t('zy_explore_online_subtitle') }}
            </div>
          </div>
        </div>
        <zy-paper-tear />
      </div>
    </section>

    <!-- Description -->
    <section
      class="
          tw-relative tw-flex
          tw-flex-col
          tw-items-center
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
        "
    >
      <div
        id="zy-explore-online"
        class="
          tw-max-w-[800px]
          tw-w-full
          tw-py-10
          sgTablet:tw-min-w-[520px]
          sgTablet:tw-py-20
          sgDesktop:tw-max-w-[800px]
          sgDesktop:tw-px-0
          sgDesktop:tw-text-sg-body-1
        "
        v-html="wordpressData.description"
      />
      <zy-paper-tear />
    </section>

    <section
      class="
        tw-relative
        tw-flex tw-flex-col
        tw-items-center
        tw-bg-sg-zy24-peach
        tw-py-10
        tw-px-4
        sgTablet:tw-px-5
        sgDesktop:tw-px-10
      "
    >
      <div
        v-for="(row, index) of videos_content"
        :id="'video-section-'+index"
        :key="index+'video'"
      >
        <!-- First, fifth video - left, Fourth video - right -->
        <div
          v-if="index % 5 === 0 || index % 5 === 4"
          class="
              tw-flex tw-flex-col
              sgTablet2:tw-flex-row
              tw-gap-[89px]
              tw-justify-center
              tw-items-center
              sgDesktop:tw-gap-[80px]
              sgTablet:tw-gap-10
              sgDesktop2:tw-h-[600px]
              sgDesktop:tw-h-[680px]
              sgTablet2:tw-h-[600px]
              sgTablet:tw-h-[676px]
              tw-h-[628px]
            "
        >
          <!-- video -->
          <div
            class="
            zy-explore-online-video
            tw-flex
            tw-flex-col
            tw-justify-center
            tw-w-full
            sgTablet2:tw-w-[52vw]
            sgDesktop2:tw-max-w-[873px]
            sgDesktop2:tw-min-w-[753px]

            sgDesktop:tw-min-w-[493px]
            sgTablet2:tw-max-w-[624px]
            sgTablet2:tw-min-w-[44vw]
            sgTablet:tw-max-w-full
        "
          >
            <iframe
              class="tw-w-full tw-h-full tw-rounded-md tw-max-w-[753px]"
              style="aspect-ratio: 16 / 9;"
              :src="row.youtube_facebook_embed_link"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <!-- description -->
          <div
            class="
            tw-flex
            tw-flex-col
            tw-justify-center
            tw-gap-6
            tw-w-full
            sgDesktop2:tw-max-w-[448px]
            sgDesktop:tw-max-w-[412px]
            sgTablet2:tw-max-w-[400px]
            sgTablet:tw-max-w-full
            "
          >
            <h3
              class="
            tw-text-[#1A1A1A]
            tw-text-sg-ref-mobile-h4
            sgTablet:tw-text-sg-ref-tablet-h4
            sgDesktop:tw-text-sg-ref-desktop-h4"
            >
              {{ row.title }}
            </h3>
            <div
              id="video-section-description"
              class="sg-ref-desktop-body"
              v-html="row.description"
            />
          </div>
        </div>

        <!-- Second and fourth video - right -->
        <div
          v-else-if="index % 5 === 1 || index % 5 === 3"
          class="
              tw-flex tw-flex-col
              sgTablet2:tw-flex-row-reverse
              tw-justify-center
              tw-gap-[89px]
              sgDesktop:tw-gap-[80px]
              sgTablet:tw-gap-10

              sgDesktop2:tw-h-[600px]
              sgDesktop:tw-h-[680px]
              sgTablet2:tw-h-[600px]
              sgTablet:tw-h-[676px]
              tw-h-[628px]
            "
        >
          <!-- video -->
          <div
            class="
            zy-explore-online-video
            tw-flex
            tw-flex-col
            tw-justify-center
            sgTablet2:tw-w-[52vw]
            sgDesktop2:tw-max-w-[873px]
            sgDesktop2:tw-min-w-[753px]

            sgDesktop:tw-min-w-[493px]
            sgTablet2:tw-max-w-[624px]
            sgTablet2:tw-min-w-[44vw]
            sgTablet:tw-max-w-full
            "
          >
            <iframe
              class="tw-mx-auto tw-w-full tw-rounded-md tw-max-w-[753px]"
              style="aspect-ratio: 16 / 9;"
              :src="row.youtube_facebook_embed_link"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <!-- description -->
          <div
            class="
            tw-flex
            tw-flex-col
            tw-justify-center

            sgDesktop2:tw-max-w-[567px]
            sgDesktop:tw-max-w-[412px]

            sgTablet2:tw-max-w-[400px]
            "
          >
            <div class="tw-flex tw-flex-col sgDesktop:tw-max-w-[448px] tw-gap-6">
              <h3
                class="
            tw-text-[#1A1A1A]
            tw-text-sg-ref-mobile-h4
            sgTablet:tw-text-sg-ref-tablet-h4
            sgDesktop:tw-text-sg-ref-desktop-h4"
              >
                {{ row.title }}
              </h3>
              <div
                id="video-section-description"
                class="sg-ref-desktop-body"
                v-html="row.description"
              />
            </div>
          </div>
        </div>

        <!-- Third video - center -->
        <div
          v-else
          class="

            tw-flex
            tw-flex-col
            tw-gap-[89px]
            sgDesktop:tw-flex-col
            sgDesktop2:tw-flex-col
            sgTablet:tw-flex-col
            sgTablet2:tw-flex-row
            tw-justify-center
            tw-items-center

            sgTablet:tw-gap-10

            sgDesktop:tw-h-[828px]
            sgTablet2:tw-h-[600px]
            sgTablet:tw-h-[676px]
            tw-h-[628px]
          "
        >
          <!-- video -->
          <div
            class="
            zy-explore-online-video
            tw-flex
            tw-w-full

            sgDesktop:tw-max-w-[800px]
            sgDesktop:tw-w-[800px]

            sgTablet2:tw-min-w-[410px]
            sgTablet2:tw-min-w-[44vw]
            sgTablet2:tw-w-[52vw]
          "
          >
            <iframe
              class="tw-mx-auto tw-w-full tw-rounded-md  tw-max-w-[800px]"
              style="aspect-ratio: 16 / 9;"
              :src="row.youtube_facebook_embed_link"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <!-- description -->
          <div
            class="
            sgDesktop2:tw-max-w-[800px]
            sgDesktop:tw-max-w-[800px]

            tw-flex
            tw-flex-col
            sgTablet2:tw-justify-center
            tw-gap-6

            sgTablet2:tw-max-w-[400px]

            sgTablet:tw-max-w-full
            "
          >
            <h3
              class="
            tw-text-[#1A1A1A]
            tw-text-sg-ref-mobile-h4
            sgTablet:tw-text-sg-ref-tablet-h4
            sgDesktop:tw-text-sg-ref-desktop-h4
            sgDesktop:tw-text-center
            "
            >
              {{ row.title }}
            </h3>
            <div
              id="video-section-description"
              class="sg-ref-desktop-body sgDesktop:tw-text-center"
              v-html="row.description"
            />
          </div>
        </div>
      </div>

      <zy-paper-tear />
    </section>


    <!-- explore online adventure section -->
    <section
      class="
        tw-relative
        tw-flex tw-flex-col
        tw-items-center
        tw-pt-10
        tw-px-4
        sgTablet:tw-px-5
        sgDesktop:tw-px-10
      "
    >
      <div
        class="
            tw-flex
            tw-flex-col
            tw-items-center
            tw-max-w-[800px]
            tw-pt-10
            tw-pb-5
          "
      >
        <h3
          class="
            tw-text-[#1A1A1A]
            tw-text-sg-ref-mobile-h4
            sgTablet:tw-text-sg-ref-desktop-h4
            tw-text-center
            sgTablet:tw-text-start"
        >
          {{ $t('zy_explore_online_adventure_title') }}
        </h3>
        <div
          class="sg-ref-desktop-body tw-pt-10 tw-text-center"
        >
          {{ $t('zy_explore_online_adventure_description_1') }}
        </div>
        <div
          class="sg-ref-desktop-body tw-pt-6 tw-text-center"
        >
          {{ $t('zy_explore_online_adventure_description_2') }}
        </div>
      </div>

      <div class="tw-pt-10 tw-pb-[60px]">
        <iframe
          class="
            tw-w-[343px]
            tw-h-[193px]
            sgTablet:tw-w-[600px]
            sgTablet:tw-h-[337px]
            sgDesktop:tw-w-[800px]
            sgDesktop:tw-h-[450px]
          "
          src="https://play.solstice.sg/sccczhongyuan/?fbclid=IwAR1crlLCTyA6ef4R928YY4OQR1ooOcr5o1KVoMgDOfUlst7yiwQujuGDskk"
        />
      </div>

    </section>

    <ap-image-sources
      v-if="!!imageSourcesData && imageSourcesData?.length > 0"
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style scoped lang="scss">
.underline-anchor-tag a {
  text-decoration: underline;
}

#zy-explore-online sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}

#video-section-description:deep(a){
  text-decoration: underline !important;
  color: #1a1a1a !important;
}


</style>

