<template>
  <div
    :class="{
      'tw-font-akkurat': checkLang() === 'en',
      'tw-font-notosans': checkLang() !== 'en'
    }"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <div class="tw-flex tw-justify-center tw-align-middle tw-px-4 tw-pt-5 tw-pb-[128px] md:tw-px-10 md:tw-pt-[60px] md:tw-pb-[144px] lg:tw-px-30 tw-relative">
      <div
        ref="inner_ce"
        class="inner_ce"
      />
      <div class="tw-flex tw-flex-col tw-items-center">
        <p
          class="tw-text-ap-h4-ce tw-text-sg-ce-white"
        >
          {{ $t('cultural_extravaganza_events_title') }}
        </p>

        <h1
          class="tw-text-sg-ce-white tw-text-sg-ce-h1-mobile md:tw-text-sg-ce-h1-tablet lg:tw-text-sg-ce-h1 tw-pb-5 tablet:tw-pb-10 desktop:tw-pb-[60px]"
          :class="checkLang() === 'en' ? '' : 'lg:!tw-leading-[84px]'"
        >
          {{ $t('ce_video_on_demand_title') }}
        </h1>

        <!-- Loading -->
        <div
          v-show="isLoading"
          class="tw-w-full tw-h-full tw-relative tw-flex tw-flex-col tw-justify-center tw-max-h-[541px] tw-min-h-[421px] md:tw-max-h-[872px] md:tw-min-h-[700px] lg:tw-max-h-[478px] lg:tw-min-h-[478px]"
        >
          <div class="tw-relative tw-flex tw-justify-center">
            <div class="tw-h-[100px] tw-w-[100px]">
              <Vue3Lottie
                :animation-link="$cdn('lottiefiles/ce_loading_ren_walk.json')"
                loop
                :auto-play="true"
              />
            </div>
          </div>
          <p class="tw-text-sg-caption tw-text-sg-ce-white tw-text-center tw-relative tw-bottom-[20px] tw-pt-5">
            {{ $t('ce_please_wait') }}
          </p>
        </div>

        <!-- Skeleton for VOD -->
        <div
          v-show="!isLoading && videos.length < 1 && archivedVideosCount >= 0"
          class="tw-flex tw-flex-col tw-items-center tw-justify-center"
        >
          <div class="tw-absolute tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-5 tw-text-[#A3A3A3] tw-max-w-[260px] sgTablet:tw-max-w-none">
            <p class="tw-text-center tw-text-sg-h3-mobile md:tw-text-sg-h3-tablet lg:tw-text-sg-h3">
              {{ videos.length < 1 && archivedVideosCount === 0 ? $t('ce_video_on_demand_initial_title') : $t('ce_video_on_demand_archived_title') }}
            </p>
            <p class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3">
              {{ videos.length < 1 && archivedVideosCount === 0 ? $t('ce_video_on_demand_initial_subtitle') : $t('ce_video_on_demand_archived_subtitle') }}
            </p>
          </div>
          <div class="skeleton-grid">
            <div
              v-for="index in skeletonItemCount"
              :key="index"
              class="skeleton-card"
            />
          </div>
        </div>

        <!-- Videos List -->
        <div
          v-show="!isLoading && videos.length > 0"
          :class="{
            'tw-flex tw-flex-wrap tw-gap-3 md:tw-gap-5 tw-pb-5 md:tw-pb-10 lg:tw-pb-[60px] tw-justify-center tw-max-w-[820px] tw-w-full tw-mx-auto':
              size === 'desktop',
            grid: size === 'mobile' || size === 'tablet',
          }"
        >
          <div
            v-for="video in videos"
            :key="video.id"
          >
            <ce-video-card :video="video" />
          </div>
        </div>

        <!-- Pagination Part -->
        <div
          v-show="!isLoading && videos.length !== 0 && lastPage !== 1"
          class="tw-relative tw-flex tw-flex-row tw-mt-5 md:tw-mt-10 lg:tw-mt-[60px]"
        >
          <div class="tw-pr-[6px]">
            <inline-svg
              alt="Previous Page"
              class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8"
              :class="{
                'nextIcon tw-cursor-pointer tw-rounded-full': currentPage !== 1,
              }"
              :src="currentPage === 1 ? $cdn('icons/pagination/keyboard_arrow_left_disable.svg') : $cdn('icons/pagination/keyboard_arrow_left.svg')"
              @click="onPreviousPageClick"
            />
          </div>

          <div
            v-for="number in lastPage"
            :key="number"
            :class="{
              'tw-bg-white tw-text-sg-sccc1 tw-rounded-full': number === currentPage,
              'tw-text-[#8016B2]': number !== currentPage,
              'hover:tw-text-sg-ce-white': number !== currentPage,
            }"
            class="tw-flex tw-items-center tw-justify-center tw-mx-[6px] tw-w-8 tw-h-8 tw-cursor-pointer hover:tw-border-[#ffff] hover:tw-border-2 hover:tw-rounded-full"
          >
            <span @click="getVideos(number)">{{ number }} </span>
          </div>

          <div class="tw-pl-[6px]">
            <inline-svg
              alt="Next Page"
              :src="currentPage === lastPage ? $cdn('icons/pagination/keyboard_arrow_right_disable.svg') : $cdn('icons/pagination/keyboard_arrow_right.svg')"
              :class="{
                'nextIcon tw-cursor-pointer tw-rounded-full': currentPage !== lastPage,
              }"
              class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8"
              @click="onNextPageClick"
            />
          </div>
        </div>
      </div>
    </div>
    <ce-navigation-bar />
    <ce-partner-section />
    <ap-footer class=" tw-bg-white tw-relative" />
  </div>
</template>

<script setup lang="ts">
import { Ref, onBeforeMount, onBeforeUnmount, onMounted, ref, nextTick, } from 'vue';
import axios from 'axios';
import { PaginatedResponse, Video } from '../../../types/types';
import { checkLang, getSgScreenSize } from '../../../support/helper';
import { Vue3Lottie } from 'vue3-lottie';

let isLoading = ref(false);

let currentPage = ref(1);
let lastPage = ref(1);

let videos = ref<Array<Video>>([]);
let size: Ref<string | null> = ref(null);
let archivedVideosCount = ref(0);
let skeletonItemCount = ref(15);
const inner_ce: Ref<HTMLElement | null> = ref(null);


const props = defineProps({
  wordpressData: {
    type: Object,
    default: null,
  },
});

function onNextPageClick() {
  if (currentPage.value < lastPage.value) {
    currentPage.value += 1;
    getVideos(currentPage.value);
  }
}

function onPreviousPageClick() {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
    getVideos(currentPage.value);
  }
}
const handleScroll = () => {
  if (inner_ce.value) {
    const delayedOffsetY = Math.max(0, window.pageYOffset);
    let value = delayedOffsetY / 8000 + 1;
    inner_ce.value.style.transform = `scale(${value})`;
  }
};

function getVideos(page: number) {
  isLoading.value = true;

  let currentDomain = process.env.WP_SITEURL;

  if (currentDomain === undefined) {
    return;
  }

  const lang = checkLang();

  let url = currentDomain + '/wp-json/wp/v2/video?per_page=12&page=' + page + '&lang=' + lang;

  axios
    .get(url)
    .then((response) => {
      let paginatedResponse: PaginatedResponse = response.data;
      let data: Array<any> = paginatedResponse.data;

      videos.value = [];
      currentPage.value = 1;
      lastPage.value = 1;
      archivedVideosCount.value = paginatedResponse.archived ?? 0;

      if (data) {
        for (let video of data) {
          videos.value.push({
            id: video.id,
            title: video.post_title,
            date: video.date,
            content: video.content.rendered,
            permalink: video.link,
            acf: {
              video_gallery: video.acf.video_gallery ?? [],
              video_present_by: video.acf.video_present_by,
              video_produce_by: video.acf.video_produce_by,
              video_is_archived: video.acf.video_is_archived,
            },
          });
        }
        currentPage.value = paginatedResponse.current_page;
        lastPage.value = paginatedResponse.last_page;
      }

      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
      console.error(error);
    });
}

onBeforeMount(() => {
  size.value = getSgScreenSize();

  if (size.value === 'mobile' || size.value === 'tablet') {
    skeletonItemCount.value = 8;
  } else {
    skeletonItemCount.value = 9;
  }

  getVideos(currentPage.value);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', () => {
    size.value = getSgScreenSize();
  });
});

onMounted(async () => {
  const screenWidth = window.innerWidth;

  if (inner_ce.value) {
    const headerImage =
    screenWidth < 1440
      ? props.wordpressData.ce_video_header_image_mobile
      : props.wordpressData.ce_video_header_image;
    inner_ce.value.style.backgroundImage =
      `linear-gradient(0deg, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(${headerImage})`;
    await nextTick();
  }
  window.addEventListener('scroll', handleScroll);


  window.addEventListener('resize', () => {
    size.value = getSgScreenSize();
    if (window.innerWidth < 800) {
      skeletonItemCount.value = 8;
    } else {
      skeletonItemCount.value = 9;
    }
  });
});

</script>

<style>
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.inner_ce {
  position: fixed; /* Stays fixed on scroll */
  top: 0;
  left: 0;
  width: 100%;
  height: auto; /* Adjust dynamically */
  min-height: 100vh; /* Ensures full screen height */
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.75)), url(https://picsum.photos/1200?random=2);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: -1;
}

@media (min-width: 680px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (min-width: 800px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

.skeleton-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

@media (min-width: 680px) {
  .skeleton-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (min-width: 862px) {
  .skeleton-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

.skeleton-card {
  background: #F4F4F4;
  border-radius: 4px;
  opacity: 0.25;
  height: 210px;
  width: 260px;

  /* Medium Shadow */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
              0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

@media (min-width: 680px) and (max-width: 1024px) {
  .skeleton-card {
    width: 240px;
    height: 210px; 
  }
}

@media (max-width: 580px) {
  .skeleton-card {
    height: 168px;
    width: 164px;
  }
}

svg.nextIcon:hover rect {
  fill: #8c12d4;
}

svg.nextIcon:hover path {
  fill: white;
}

svg.nextIcon:active rect {
  fill: #520e72;
}

svg.nextIcon:active path {
  fill: white;
}
</style>
