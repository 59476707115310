<script lang="ts" setup>
// Import Swiper Vue.js components
import type {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCoverflow } from 'swiper/modules';

import { onMounted, ref, onBeforeUnmount} from 'vue';
import SwiperCore from 'swiper';
import { checkLang } from '../../support/helper';

SwiperCore.use([EffectCoverflow]);
const datas = ref([
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_intro.v2.jpg',
        'link_back_EN': '',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_intro.v2.jpg',
        'link_back_CN': '',
      },
    ],
    'is_visible': true,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_01_Q.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_01_A.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_01_Q.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_01_A.jpg',
      },

    ],
    'is_visible': true,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_02_Q.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_02_A.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_02_Q.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_02_A.jpg',
      },

    ],
    'is_visible': true,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_03_Q.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_03_A.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_03_Q.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_03_A.jpg',
      },

    ],
    'is_visible': false,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_04_Q.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_04_A.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_04_Q.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_04_A.jpg',
      },

    ],
    'is_visible': false,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_05_Q.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_05_A.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_05_Q.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_05_A.jpg',
      },
    ],
    'is_visible': false,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_06_Q.v2.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_06_A.v2.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_06_Q.v2.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_06_A.v2.jpg',
      },
    ],
    'is_visible': false,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_07_Q.v2.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_07_A.v2.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_07_Q.v2.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_07_A.v2.jpg',
      },
    ],
    'is_visible': false,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_08_Q.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_08_A.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_08_Q.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_08_A.jpg',
      },
    ],
    'is_visible': true,
  },
  {
    'images': [
      {
        'link_front_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_09_Q.jpg',
        'link_back_EN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_09_A.v2.jpg',
        'link_front_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_09_Q.jpg',
        'link_back_CN': 'images/ma2024/mid-autumn-101/lantern-riddle/riddles/CN/ma_riddle_09_A.v2.jpg',
      },
    ],
    'is_visible': true,
  },
]);

const currentAnswerReveal = ref(-1);
const isInitialIndex = ref(true);
const isLastIndex = ref(false);

const isMobile = ref(true);

const currentIndex = ref(0);

function onResize() {
  if (window.innerWidth <= 430) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

const swiperInstance = ref<SwiperInstance>();
const imageModal = ref({
  show:false,
  data:null,
});

function revealAnswer() {
  if (isInitialIndex.value) { return; }
  if (currentAnswerReveal.value == swiperInstance.value.realIndex) {
    currentAnswerReveal.value = -1;
  }else {
    currentAnswerReveal.value = swiperInstance.value.realIndex;
  }
}

function onSwiper(swiper: any) {
  swiperInstance.value = swiper;
}

function slideNext() {
  if (swiperInstance.value) {
    if (isLastIndex.value) { return; }
    swiperInstance.value.slideNext();
  }
}

function slidePrev() {
  if (swiperInstance.value) {
    if (isInitialIndex.value) { return; }
    swiperInstance.value.slidePrev();
  }
}

function onSlideChange() {
  if (swiperInstance.value) {
    const totalSlides = datas.value.length;
    let currentIndex = swiperInstance.value.realIndex;

    // Reset all slides to not visible
    datas.value.forEach((item, index) => {
      item.is_visible = false;
    });

    // Make the current slide and its neighbors visible
    datas.value[currentIndex].is_visible = true;
    datas.value[(currentIndex - 1 + totalSlides) % totalSlides].is_visible = true; // Previous slide
    datas.value[(currentIndex - 2 + totalSlides) % totalSlides].is_visible = true; // Two slides before
    datas.value[(currentIndex + 1) % totalSlides].is_visible = true; // Next slide
    datas.value[(currentIndex + 2) % totalSlides].is_visible = true; // Two slides after

    // Update other properties
    isInitialIndex.value = swiperInstance.value.realIndex === 0;
    isLastIndex.value = swiperInstance.value.realIndex === totalSlides - 1;
    currentAnswerReveal.value = -1;
    currentIndex = swiperInstance.value.realIndex;
  }
}

</script>

<template>
  <section
    :class="[
      'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10',
    ]"
  >
    <swiper
      class="lanternRiddleSwiper"
      :effect="'coverflow'"
      :grab-cursor="true"
      :centered-slides="true"
      :slides-per-view="'auto'"
      :coverflow-effect="{
        rotate: 0,
        stretch: 360,
        depth: 320,
        modifier: 1, // 2,3
        slideShadows: false,
      }"
      :normalize-slide-index="false"
      :loop="true"
      style="width: 95vw;"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in datas"
        :key="`slide_${index}`"
        :class="[
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'sgTablet:tw-w-[440px] tw-w-[300px]',
          { 'swiper-hidden': !item.is_visible },
          { 'swiper-shown': item.is_visible },
        ]"
      >
        <ma-flip-card
          :flipped="currentAnswerReveal === index"
          :image-front="checkLang() === 'en' ? item.images[0].link_front_EN : item.images[0].link_front_CN"
          :image-back="checkLang() === 'en' ? item.images[0].link_back_EN : item.images[0].link_back_CN"
          :height="`${isMobile ? 300 : 440}px`"
          :width="`${isMobile ? 300 : 440}px`"
        />
      </swiper-slide>

    </swiper>

    <div
      :class="[
        'tw-bg-[#7A489D] hover:tw-bg-[#956DB1] tw-cursor-pointer active:tw-bg-[#623A7E]',
        'tw-rounded-full tw-w-[200px] tw-h-[52px]',
        { 'tw-bg-[#D1D1D1] hover:tw-bg-[#D1D1D1] active:tw-bg-[#D1D1D1]': isInitialIndex },
      ]"
      @click="revealAnswer"
    >
      <div
        :class="[
          'tw-h-full',
          'tw-flex tw-gap-2',
          'tw-justify-center tw-items-center',
        ]"
      >
        <img :src="currentAnswerReveal >= 0 ? $cdn('icons/ma/ma_visibility_off.svg') : $cdn('icons/ma/ma_visibility.svg')">
        <div
          :class="[
            'tw-text-white tw-uppercase tw-text-sg-ma-bt1',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ currentAnswerReveal >= 0 ?
            $t('ma_mid_autumn_101_lanterns_riddles_hide_answer'):
            $t('ma_mid_autumn_101_lanterns_riddles_reveal_answer')
          }}
        </div>
      </div>

    </div>

    <div class="tw-flex tw-justify-center tw-gap-[60px]">
      <button
        :class="[
          'tw-rounded-full disabled:tw-opacity-25',
          'tw-h-[56px] tw-min-w-[56px]',
          'tw-text-[20px] tw-text-center tw-text-white tw-bg-[#7A489D]',
          'tw-flex tw-items-center tw-justify-center',
          'hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E] tw-transition tw-duration-300 tw-ease-in-out',
          { 'tw-opacity-[0.25] hover:tw-bg-[#D1D1D1] active:tw-bg-[#D1D1D1]': isInitialIndex },
        ]"
        @click="slidePrev()"
      >
        <img
          class="tw-w-12 tw-h-12"
          :src="$cdn('icons/ma/ma_chevron_left.svg')"
        >
      </button>
      <button
        :class="[
          'tw-text-white tw-bg-[#7A489D]',
          'tw-rounded-full tw-text-center  disabled:tw-opacity-25',
          'tw-h-[56px] tw-min-w-[56px]',
          'tw-flex tw-items-center tw-justify-center',
          'hover:tw-bg-[#956DB1] active:tw-bg-[#623A7E] tw-transition tw-duration-300 tw-ease-in-out',
          { 'tw-opacity-[0.25] hover:tw-bg-[#D1D1D1] active:tw-bg-[#D1D1D1]': isLastIndex },
        ]"
        @click="slideNext()"
      >
        <img
          class="tw-w-12 tw-h-12"
          :src="$cdn('icons/ma/ma_chevron_right.svg')"
        >
      </button>
    </div>

    <values-jangandfox-image-modal
      v-model="imageModal.show"
      :data="imageModal.data"
    />
  </section>
</template>

<style scoped>
.lanternRiddleSwiper .swiper-slide{
  border-color: rgb(from #FF9300 r g b /0.6)
}
.lanternRiddleSwiper .swiper-slide.swiper-slide-active{
  border-color: rgb(from #FF9300 r g b)
}
.lanternRiddleSwiper .swiper-slide .slideContentWrapper{
  opacity: 0.6;
}
.lanternRiddleSwiper .swiper-slide.swiper-slide-active .slideContentWrapper{
  opacity: 1;
}
.lanternRiddleSwiper .swiper-slide-hidden{
  opacity:0;
}

/* General styles for desktop and tablet */
.swiper-hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.swiper-shown {
  opacity: 1;
  pointer-events: auto;
}

/* Additional styles for mobile view */
@media only screen and (max-width: 768px) {
  .swiper-slide {
    opacity: 0;
    pointer-events: none;
  }

  .swiper-slide.swiper-slide-prev,
  .swiper-slide.swiper-slide-active,
  .swiper-slide.swiper-slide-next {
    opacity: 1;
    pointer-events: auto;
  }
}

</style>
