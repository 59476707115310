<script lang="ts" setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData: any;
}>();
const { t } = useI18n();

const menuItems = [
  {
    label: 'cny_music_menu_spotify',
    id: 'cny-music-spotify',
  },
  {
    label: 'cny_music_menu_youtube',
    id: 'cny-music-youtube',
  },
];
type Post = {
  title: string;
  description: string;
  image: string;
  external_link: string;
};

const spotify_post = ref<Post[]>([]);
const youtube_post = ref<Post[]>([]);

onMounted(() => {
  if (props.wordpressData?.spotify_post) {
    spotify_post.value = props.wordpressData.spotify_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/music/card_placeholder.png'),
      };
    });
  }
  if (props.wordpressData?.youtube_post) {
    youtube_post.value = props.wordpressData.youtube_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/music/card_placeholder.png'),
      };
    });
  }
});
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-navigation="true"
    />
    <secondary-navigation :menu-items-data="menuItems" />
    <section>
      <div class="tw-relative">
        <picture>
          <source
            media="(max-width: 375px)"
            :srcset="$cdn('images/cny2024/banner_375.png')"
          >
          <source
            media="(max-width: 680px)"
            :srcset="$cdn('images/cny2024/banner_680.png')"
          >
          <source
            media="(max-width: 800px)"
            :srcset="$cdn('images/cny2024/banner_800.png')"
          >
          <source
            media="(max-width: 1025px)"
            :srcset="$cdn('images/cny2024/banner_1025.png')"
          >
          <img
            :src="$cdn('images/cny2024/banner_1440.png')"
            class="tw-w-full tw-object-cover"
          >
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny-title tw-pb-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_music_title') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-mt-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_music_subtitle') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tw-relative tw-bg-white tw-px-4 tw-pb-[140px]">
      <div class="tw-w-full tw-absolute tw-top-0 tw-left-0">
        <div class="tw-max-w-full sgDesktop2:tw-max-w-[960px] tw-w-full tw-mx-auto tw-flex tw-justify-between">
          <div>
            <img
              class="tw-hidden sgTablet:tw-block tw-h-[80px] tw-absolute tw-left-4 sgTablet:tw-left-10 sgDesktop:tw-left-20 sgDesktop2:tw-relative sgDesktop2:tw-left-0 sgTablet:tw-h-[100px]"
              :src="$cdn('images/cny2024/music/lantern.png')"
            >
          </div>
          <div>
            <img
              class="tw-hidden sgTablet:tw-block -tw-scale-x-100 tw-h-[80px] tw-absolute tw-right-4 sgTablet:tw-right-10 sgDesktop:tw-right-20 sgDesktop2:tw-relative sgDesktop2:tw-right-0 sgTablet:tw-h-[100px]"
              :src="$cdn('images/cny2024/music/lantern.png')"
            >
          </div>
        </div>
      </div>
      <div
        class="tw-w-[calc(100vw-32px)] sgTablet:tw-w-[calc(100vw-80px-160px)] sgDesktop:tw-w-[calc(100vw-160px-160px)] sgDesktop2:tw-w-[800px] tw-mx-auto tw-text-center tw-pt-5 sgTablet:tw-pt-10"
      >
        <h1
          class="tw-text-[#484848] tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny-title"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_music_section_1_title') }}
        </h1>
        <!-- <p
          class="tw-text-[#484848] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1
  tw-mt-4 sgTablet:tw-mt-5"
          v-html="$t('cny_music_section_1_desc_1')"
        />
        <p
          class="tw-text-[#484848] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1
  tw-mt-4 sgTablet:tw-mt-5"
          v-html="$t('cny_music_section_1_desc_2')"
        /> -->
        <p
          class="tw-text-[#484848] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-mt-4 sgTablet:tw-mt-5"
          v-html="wordpressData.description"
        />
      </div>
    </section>

    <section
      id="cny-music-spotify"
      class="tw-relative tw-bg-[#EDF5F9] tw-px-4 tw-pb-[100px]"
    >
      <div class="tw-absolute -tw-top-[100px] tw-left-[calc((100vw-200px)/2)] tw-w-[200px] tw-h-[200px]">
        <img :src="$cdn('images/cny2024/music/spotify.png')">
      </div>

      <h2
        class="tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny tw-text-center tw-pt-[120px] tw-text-[#827551]"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
      >
        {{ $t('cny_music_spotify_title') }}
      </h2>
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto tw-mt-6 sgTablet:tw-mt-10 tw-pb-10 sgTablet:tw-pb-20 tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item, index) in spotify_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-[#7DBCA4] tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
          }"
        >
          <img
            :src="item.image"
            class="tw-w-full tw-h-[160px] sgTablet:tw-h-[252px] tw-object-center tw-object-cover"
          >
          <div
            class="tw-flex-1 tw-flex tw-flex-col tw-mt-3 tw-mx-2 tw-mb-3 sgTablet:tw-mt-4 sgTablet:tw-mx-3 sgTablet:tw-mb-3 sgDesktop:tw-mt-5 sgDesktop:tw-mx-4"
          >
            <div
              class="tw-min-h-[40px] tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-mb-2"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div class="tw-flex-1 tw-min-h-[120px] sgTablet:tw-min-h-[72px]">
              {{ item.description }}
            </div>
            <div class="tw-flex tw-justify-end tw-text-sg-button-2 tw-pt-2 tw-pr-2 tw-cursor-pointer tw-group tw-min-h-[52px]">
              <a
                :href="item.external_link"
                target="_blank"
                rel="noopener"
                class="tw-flex tw-gap-x-2 tw-items-center"
              >
                <span class="group-hover:tw-underline">{{ $t('cny_music_find_out_more') }}</span>
                <inline-svg :src="$cdn('images/cny2024/music/arrow_outward.svg')" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="cny-music-youtube"
      class="tw-relative tw-bg-[#DCEBF3] tw-px-4 tw-pb-[100px]"
    >
      <div class="tw-absolute -tw-top-[100px] tw-left-[calc((100vw-200px)/2)] tw-w-[200px] tw-h-[200px]">
        <img :src="$cdn('images/cny2024/music/youtube.png')">
      </div>
      <h2
        class="tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny tw-text-center tw-pt-[120px] tw-text-[#827551]"
        :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
      >
        {{ $t('cny_music_youtube_title') }}
      </h2>
      <div
        class="tw-flex tw-flex-wrap tw-justify-center tw-max-w-[800px] tw-w-full tw-mx-auto tw-mt-6 sgTablet:tw-mt-10 tw-pb-10 sgTablet:tw-pb-20 tw-gap-4 sgDesktop:tw-gap-5"
      >
        <div
          v-for="(item, index) in youtube_post"
          :key="index"
          class="tw-w-[160px] sgTablet:tw-w-[252px] tw-bg-[#E1907D] tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col"
          :style="{
            boxShadow: '0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
          }"
        >
          <img
            :src="item.image"
            class="tw-w-full tw-h-[90px] sgTablet:tw-h-[142px] tw-object-center tw-object-cover"
          >
          <div class="tw-my-3 tw-mx-2 tw-flex-1 tw-flex tw-flex-col sgTablet:tw-mt-4 sgTablet:tw-mx-3 sgTablet:tw-mb-3 sgDesktop:tw-mt-5 sgDesktop:tw-mx-4">
            <div
              class="tw-min-h-[40px] tw-mb-2 tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div class="tw-flex-1 tw-min-h-[120px] sgTablet:tw-min-h-[72px]">
              {{ item.description }}
            </div>
            <div class="tw-min-h-[32px] tw-flex tw-justify-end tw-text-sg-button-2 tw-pt-2 tw-pr-2 tw-cursor-pointer tw-group">
              <a
                :href="item.external_link"
                target="_blank"
                rel="noopener"
                class="tw-flex tw-gap-x-2 tw-items-center"
              >
                <span class="group-hover:tw-underline">{{ $t('cny_music_find_out_more') }}</span>
                <inline-svg :src="$cdn('images/cny2024/music/arrow_outward.svg')" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ap-footer />
  </div>
</template>
