<template>
  <img
    class="tw-absolute tw-w-full tw-object-cover tw-z-10 tw-h-[80px]"
    :style="{ bottom: '0', transform: 'translateY(40px)', filter: 'drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.3))' }"
    :src="$cdn('images/zy2024/paper-tear.png')"
  >
</template>

<script setup lang="ts">
</script>
