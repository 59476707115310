<script lang="ts" setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../../support/cdn';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../../support/helper';

const { t } = useI18n();
const currentQuestionNo = ref(0);
const totalQuestionNo = ref(4);
const questions = ref({
  1: {
    title_en: 'You find yourself face to face with a smiling relative. What’s the first CNY greeting you say?',
    title_cn: '你面前站着了个笑容满面的亲戚，你脑子里第一个想到的祝贺词是那个？',
  },
  2: {
    title_en: 'There are so many things to look forward to during CNY — what’s your favourite?',
    title_cn: '你最期待过年做什么？',
  },
  3: {
    title_en: 'What’s on your TV during CNY?	',
    title_cn: '新年收看的节目？',
  },
  4: {
    title_en: 'If you’re at a house visit, one would find you…	',
    title_cn: '上门拜访时，你通常会在哪里？	',
  },
});
const options = ref({
  1: {
    'love-letters': {
      option_en: 'The only one I know:<br/>Happy New Year<br/>新年快乐 (xīn nián kuài lè)',
      option_cn: '新年快乐 (xīn nián kuài lè)',
    },
    niangao: {
      option_en: 'Wishing you happiness and prosperity<br/>恭喜发财(gōng xǐ fā cái)',
      option_cn: '恭喜发财(gōng xǐ fā cái)',
    },
    'pineapple-tarts': {
      option_en: 'Wishing you good health<br/>身体健康 (shēng tî jiàn kāng)',
      option_cn: '身体健康 (shēng tî jiàn kāng)',
    },
    'mandarin-oranges': {
      option_en: 'I use many different CNY greetings, not just one.',
      option_cn: '我会轮流用，不会只用一个。',
    },
  },
  2: {
    'mandarin-oranges': {
      option_en: 'Food lah, abuden?',
      option_cn: '当然是吃各种好吃的！',
    },
    niangao: {
      option_en: 'Time with my family and relatives',
      option_cn: '与家人亲戚相聚',
    },
    'love-letters': {
      option_en: 'Finally, some rest',
      option_cn: '休息',
    },
    'pineapple-tarts': {
      option_en: 'The ‘fit (for the ‘gram).',
      option_cn: '穿新衣',
    },
  },
  3: {
    niangao: {
      option_en: 'An old Stephen Chow movie',
      option_cn: '周星驰的电影',
    },
    'love-letters': {
      option_en: 'The good ol’ Channel 8/Channel U/TVB 8',
      option_cn: '8頻道/U頻道/TVB',
    },
    'pineapple-tarts': {
      option_en: 'Something from Netflix, Disney+, or Viu',
      option_cn: 'Netflix、Disney+ 或 Viu 的节目 ',
    },
    'mandarin-oranges': {
      option_en: 'No TV for us, we’re bonding as a family',
      option_cn: '忙着跟家人聊天所以不会看电视',
    },
  },
  4: {
    'love-letters': {
      option_en: 'Hiding in a corner and trying to avoid eye contact',
      option_cn: '躲在角落里，尽量避免眼神接触',
    },
    niangao: {
      option_en: 'Talking about current affairs, or about children or family',
      option_cn: '谈论社会或时事新闻，或者关于孩子或家庭',
    },
    'pineapple-tarts': {
      option_en: 'Playing games with your family',
      option_cn: '与家人们玩游戏如手机游戏、卡牌',
    },
    'mandarin-oranges': {
      option_en: 'Hanging out with whoever’s at the sofa and watching TV',
      option_cn: '坐在沙发上看电视与亲戚朋友交谈',
    },
  },
});
const answers = ref<string[]>([]);
const results = ref({
  'love-letters': {
    title_en: 'Love Letter',
    title_cn: '鸡蛋卷',
    desc_en: "Light and simple, you take a while to show your flavour similar to a love letter. Don't be shy, soak in the festivities!",
    desc_cn: '你就像鸡蛋卷充满古早味的简单好味道，越嚼越香，尽情放开自己，沉浸在节庆的欢乐之中吧！',
    image: cdn('images/cny2024/personality-quiz/snacks-results/love-letters.scaled.jpg'),
  },
  niangao: {
    title_en: 'Niangao',
    title_cn: '年糕',
    desc_en: 'Loud and flavourful, you like to be the soul of the party wherever you are. You are basically the embodiment of the CNY spirit.',
    desc_cn: '无论身在何处，你就是焦点，绝对是任何场合和聚会的新年灵魂！',
    image: cdn('images/cny2024/personality-quiz/snacks-results/niangao.scaled.jpg'),
  },
  'pineapple-tarts': {
    title_en: 'Pineapple Tart',
    title_cn: '黄梨塔',
    desc_en: 'Like how there are many variations of pineapple tarts, you are able to fit into any situation and go with the flow.',
    desc_cn: '你就好比丰富多彩的黄梨塔，可以任何造型得到大家的喜爱，但又不失自我风格！',
    image: cdn('images/cny2024/personality-quiz/snacks-results/pineapple-tarts.scaled.jpg'),
  },
  'mandarin-oranges': {
    title_en: 'Mandarin Orange',
    title_cn: '柑橘',
    desc_en:
      'Sociable and sweet — you can’t wait to catch up with everybody. Just like mandarin oranges making their rounds during Chinese New Year, you can be found everywhere.',
    desc_cn: '善于交际、性格甜美，你迫不及待想与大家叙旧。就像应节食品柑橘一样，你无所不在。',
    image: cdn('images/cny2024/personality-quiz/snacks-results/mandarin-oranges.scaled.jpg'),
  },
});
const result = ref<{ title_en: string; title_cn: string; desc_en: string; desc_cn: string; image: string }>({
  title_en: '',
  title_cn: '',
  desc_en: '',
  desc_cn: '',
  image: '',
});
const currentQuestion = ref<{ title_en: string; title_cn: string }>({ title_en: '', title_cn: '' });
const currentOptions = ref<[string, { option_en: string; option_cn: string }][]>([]);
const showQuestion = ref(false);
const showResult = ref(false);
const showCover = ref(true);

const validateAnswer = (answer: string) => {
  answers.value.push(answer);
  if (currentQuestionNo.value == totalQuestionNo.value) {
    const calc = calculateAnswers();
    result.value = results.value[calc[0]];
    showResult.value = true;
    //showQuestion.value = false;
    return;
  } else {
    currentQuestionNo.value++;
    currentQuestion.value = questions.value[currentQuestionNo.value];
    if (currentQuestionNo.value - 1 == totalQuestionNo.value - 1) {
      const calc = calculateAnswers();
      if (calc.length == 2) {
        let tempOptions = [];
        calc.forEach((c) => {
          tempOptions.push([c, options.value[currentQuestionNo.value][c]]);
        });
        currentOptions.value = tempOptions;
      } else {
        currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
      }
    } else {
      currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
    }
    return;
  }
};

const restartQuiz = () => {
  showCover.value = true;
  showQuestion.value = false;
  showResult.value = false;
  answers.value = [];
  result.value = { title_en: '', title_cn: '', desc_en: '', desc_cn: '', image: '' };

  currentQuestionNo.value = 0;
};

const startQuiz = () => {
  restartQuiz();
  showCover.value = false;
  showQuestion.value = true;
  showResult.value = false;
  currentQuestionNo.value = 1;
  currentQuestion.value = questions.value[currentQuestionNo.value];
  currentOptions.value = Object.entries(options.value[currentQuestionNo.value]);
};

const calculateAnswers = () => {
  let highest = 1;
  let highestCategory: string[] = [];
  const counts = answers.value.reduce((counts: any, answer) => {
    if (counts[answer]) {
      counts[answer]++;
    } else {
      counts[answer] = 1;
    }

    if (counts[answer] > highest) {
      highest = counts[answer];
      highestCategory = [answer];
    } else if (counts[answer] == highest) {
      highestCategory.push(answer);
    }
    return counts;
  }, {});
  highestCategory = highestCategory.sort();
  return highestCategory;
};
</script>

<template>
  <section
    class="tw-bg-[#FFF1CD] tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20 tw-py-5 sgTablet:tw-py-10 tw-relative"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
  >
    <div class="tw-w-full tw-absolute tw-bottom-0 tw-left-0">
      <div class="tw-max-w-full sgDesktop:tw-max-w-[880px] tw-w-full tw-mx-auto tw-flex tw-justify-between">
        <div>
          <img
            class="tw-h-[100px] sgTablet:tw-h-[140px] tw-absolute tw-left-0 sgTablet:tw-left-10 sgDesktop:tw-relative sgDesktop:tw-left-0 tw-bottom-5 sgTablet:tw-bottom-10"
            :src="$cdn('images/cny2024/personality-quiz/niangao.png')"
          />
        </div>
        <div>
          <img
            class="tw-h-[100px] sgTablet:tw-h-[140px] tw-absolute tw-right-0 sgTablet:tw-right-10 sgDesktop:tw-relative sgDesktop:tw-right-0 tw-bottom-5 sgTablet:tw-bottom-10"
            :src="$cdn('images/cny2024/personality-quiz/love-letters.png')"
          />
        </div>
      </div>
    </div>

    <img
      class="tw-h-[60px] sgTablet:tw-h-[100px] tw-absolute tw-left-4 sgTablet:tw-left-10 sgDesktop:tw-left-20 tw-top-[26px] sgTablet:tw-top-10"
      :src="$cdn('images/cny2024/personality-quiz/mandarin-oranges.png')"
    />
    <img
      class="tw-h-[60px] sgTablet:tw-h-[100px] tw-absolute tw-right-4 sgTablet:tw-right-10 sgDesktop:tw-right-20 tw-top-[26px] sgTablet:tw-top-10"
      :src="$cdn('images/cny2024/personality-quiz/pineapple-tarts.png')"
    />
    <div
      class="tw-h-[72px] sgTablet:tw-h-25 tw-flex tw-items-center tw-justify-center tw-w-[calc(100vw-120px-32px)] sgTablet:tw-w-[calc(100vw-200px-80px)] sgDesktop:tw-w-[640px] tw-mx-auto"
    >
      <h2
        class="tw-font-hobeaux tw-font-[900] tw-text-[#C52600] tw-text-center tw-text-[28px] tw-leading-[36px] sgTablet:tw-text-[36px] sgTablet:tw-leading-[44px] sgDesktop:tw-text-[44px] sgDesktop:tw-leading-[56px]"
      >
        {{ $t('cny_personality_quiz_snack_title') }}
      </h2>
    </div>

    <div class="tw-max-w-[640px] tw-w-full tw-mx-auto">
      <template v-if="showCover">
        <div class="tw-flex tw-justify-center tw-items-center tw-h-[444px] sgTablet:tw-h-[280px]">
          <div class="">
            <button
              class="tw-h-[56px] tw-py-3 tw-px-7 tw-rounded-[56px] tw-relative tw-min-w-[200px] tw-bg-[#F5D300] hover:tw-bg-[#F9E35C] tw-text-[#9E1E00] tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-cursor-pointer !tw-font-[400] disabled:tw-bg-[#D1D1D1] disabled:tw-text-white disabled:tw-cursor-not-allowed before:tw-content-[''] before:tw-absolute before:tw-top-full before:tw-left-1/2 before:-tw-translate-x-1/2 before:tw-border-[10px] before:tw-border-transparent before:tw-border-t-[#F5D300] hover:before:tw-border-t-[#F9E35C] before:disabled:tw-border-t-[#D1D1D1] before:tw-border-t-solid"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              @click="startQuiz"
            >
              {{ $t('cny_personality_quiz_lets_start') }}
            </button>

            <img class="tw-mt-2 tw-mx-auto" :src="$cdn('images/cny2024/greet-them-right/lion.png')" />
          </div>
        </div>
      </template>
      <template v-else-if="showQuestion">
        <div class="tw-min-h-[84px] tw-mt-5 tw-flex tw-items-center tw-justify-center">
          <div
            class="tw-text-sg-sh2-mobile sgTablet:tw-text-sg-sh2-tablet sgDesktop:tw-text-sg-sh2 tw-text-[#9E1E00]"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
          >
            {{ checkLang() == 'en' ? currentQuestion.title_en : currentQuestion.title_cn }}
          </div>
        </div>
        <div>
          <div
            class="tw-grid tw-grid-cols-2 tw-gap-4 sgTablet:tw-gap-5 sgDesktop:tw-gap-6 tw-mt-5 sgTablet:tw-mt-7 sgDesktop:tw-mt-9"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
          >
            <template v-for="([optionKey, optionVal], index) in currentOptions" :key="index">
              <div
                class="tw-bg-[#FF9F33] hover:tw-bg-[#FF8700] active:tw-bg-[#A35600] tw-rounded-lg tw-h-[76px] tw-text-center tw-py-2 tw-px-3 tw-border-[#FF8700] hover:tw-border-[#CC6C00] active:tw-border-[#824500] tw-border-2 tw-text-sg-sh3 sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-text-[#9E1E00] active:tw-text-white tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-col-span-2"
                :class="[
                  currentOptions.length == 2 || (index + 1 == currentOptions.length && (index + 1) % 2 != 0)
                    ? 'sgTablet:tw-col-span-2'
                    : 'sgTablet:tw-col-span-1',
                ]"
                :style="{
                  boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.06),0px 0px 4px 0px rgba(0,0,0,0.04)',
                }"
                @click="validateAnswer(optionKey)"
              >
                <span v-html="checkLang() == 'en' ? optionVal.option_en : optionVal.option_cn" />
              </div>
            </template>
          </div>
        </div>
      </template>

      <ol
        class="tw-flex tw-items-center tw-w-[min(calc(100vw-200px-24px),260px)] sgTablet:tw-w-[260px] tw-mx-auto tw-mt-9 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-[68px]"
      >
        <li
          v-for="i in totalQuestionNo - 1"
          :key="i"
          class="tw-flex tw-w-full tw-items-center after:tw-content-[''] after:tw-w-full after:tw-h-[2px] after:tw-border-b after:tw-border-[#FFC17D] after:tw-border-2 after:tw-inline-block"
        >
          <span
            class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-[#FFC17D]"
            :class="[i <= currentQuestionNo ? 'tw-bg-[#FFC17D]' : 'tw-bg-transparent']"
          />
        </li>

        <li class="tw-flex tw-items-center">
          <span
            class="tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-rounded-full tw-shrink-0 tw-border-2 tw-border-[#FFC17D]"
            :class="[currentQuestionNo === totalQuestionNo ? 'tw-bg-[#FFC17D]' : 'tw-bg-transparent']"
          />
        </li>
      </ol>

      <div class="tw-flex tw-items-center tw-justify-center tw-mt-10 sgTablet:tw-mt-[60px]" :class="[showCover ? 'tw-text-[#D1D1D1]' : 'tw-text-[#1A1A1A']">
        <div
          class="tw-py-2 tw-px-4 tw-flex tw-items-center tw-z-[100]"
          :class="{
            'tw-group/cny-personality-quiz-snack-restart-btn tw-cursor-pointer': !showCover,
          }"
          @click="!showCover ? startQuiz() : null"
        >
          <span class="group-hover/cny-personality-quiz-snack-restart-btn:tw-underline"> {{ $t('cny_personality_quiz_restart') }}</span>
          <inline-svg :fill="showCover ? '#D1D1D1' : '#1A1A1A'" :src="$cdn(`/icons/restart_alt.svg`)" class="tw-w-6 tw-h-6 tw-ml-2" />
        </div>
      </div>
    </div>
    <cny-personality-quiz-result-modal v-model="showResult" :image="result?.image" @on-hide="restartQuiz" />
  </section>
</template>
