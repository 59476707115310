<template>
  <section class="tw-relative tw-py-10 tw-w-full">
    <div
      :class="[

        'tw-absolute tw-right-0 tw-top-[117px] tw-z-10',
        'tw-h-[2px] tw-w-[50%] tw-bg-sg-ws24-maroon',
      ]"
    />
    <swiper
      :slides-per-view="'auto'"
      :space-between="0"
      :center-insufficient-slides="true"
      class="mySwiper"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
        :style="`width: ${cardWidth};`"
      >
        <ws-101-celebrating-winter-card :data="item" />
        <div class="swiper-lazy-preloader" />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script lang="ts" setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInstance } from 'swiper';
import 'swiper/css';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const items = [
  {
    title: t('ws_winter_solstice_101_past'),
    description: t('ws_winter_solstice_101_slider_description_1'),
    image_url: 'images/ws2024/winter-solstice-101/past_present/1.png',
    showLeft: false,
    showRight: true,
  },
  {
    title: null,
    description: t('ws_winter_solstice_101_slider_description_2'),
    image_url: 'images/ws2024/winter-solstice-101/past_present/2.png',
    showLeft: true,
    showRight: true,
  },
  {
    title: t('ws_winter_solstice_101_present'),
    description: t('ws_winter_solstice_101_slider_description_3'),
    image_url: 'images/ws2024/winter-solstice-101/past_present/3.png',
    showLeft: true,
    showRight: true,
  },
  {
    title: null,
    description: t('ws_winter_solstice_101_slider_description_4'),
    image_url: 'images/ws2024/winter-solstice-101/past_present/4.png',
    showLeft: true,
    showRight: true,
  },
];

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const cardWidth = ref('420px');
function setCardWidth() {
  if (window.innerWidth < 680) {
    cardWidth.value = '100%';
  } else {
    cardWidth.value = '420px';
  }
}

onMounted(() => {
  setCardWidth();
  window.addEventListener('resize', setCardWidth);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', setCardWidth);
});

</script>
