<template>
  <div
    id="mainLayout"
    class="tw-w-full tw-h-full tw-relative"
  >
    <div
      class="first-div tw-h-[100vh] tw-px-4
      tablet:tw-pl-10 tablet:tw-pr-0
      desktop:tw-pl-20 desktop:tw-pr-0"
    >
      <div
        class="firstText tw-text-sg-sh1-mobile tw-text-white tablet:tw-w-[55%] desktop:tw-w-[55%]
       tablet:tw-text-sg-sh1-tablet tw-sticky tw-top-[35%]
       desktop:tw-text-sg-sh1 tw-pt-10"
        :class="{
          'tw-break-keep':checkLang()=='en'
        }"
      >
        {{ componentTextFirst }}
      </div>
    </div>
    <div
      class="second-div tw-h-[100vh] tw-px-4
      tablet:tw-pl-10 tablet:tw-pr-0
      desktop:tw-pl-20 desktop:tw-pr-0"
    >
      <div
        class="secondText tw-text-white tw-text-sg-sh1-mobile tablet:tw-w-[55%] desktop:tw-w-[55%]
      tablet:tw-text-sg-sh1-tablet tw-sticky tw-top-[35%]
      desktop:tw-text-sg-sh1  tw-py-10"
        :class="{
          'tw-break-keep':checkLang()=='en'
        }"
      >
        {{ componentTextSecond }}
      </div>

    </div>
    <div class="tw-text-ap-white  tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 tw-flex tw-items-center tw-justify-center tw-pb-8 tw-relative">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-content-center hover:tw-underline tw-cursor-pointer tw-pl-3 tw-pr-4 tw-py-[7px]"
        @click="scrollDown"
      >
        <div class="tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 tw-uppercase">
          {{ $t('more') }}
        </div>
        <inline-svg
          :src="$cdn('icons/expand_circle_down.svg')"
          class="tw-h-7 tw-w-7 tw-mt-1"
          alt="Expand Circle Down"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { checkLang } from '../../support/helper';

export default defineComponent({
  props: {
    componentTextFirst:{
      type:String,
      default:'Ever wondered why our local Chinese culture is the way it is? Spurred by our rich multi-racialism, unique geography, economic position as a port city and policies, our local Chinese culture has developed into a culture worth celebrating and championing.'
    },
    componentTextSecond:{
      type:String,
      default:'Here, we dive deep into everything that makes us SINGAPO人. Explore the many facets of our food, practices, festivals, values, language, arts and music. Look around: we are much more SINGAPO人 than we think!'
    },
  },
  setup() {
    return {
      checkLang
    };
  },
  methods:{
    scrollDown(){
      document.getElementById('highlights-section')?.scrollIntoView({behavior:'smooth',block:'start'});
    },
  }
});
</script>

<style>
#mainLayout .pin-spacer{
  width: 100%!important;
}
#mainLayout .pin-spacer .first-div, .pin-spacer .second-div{
  width: 100%!important;
}
</style>
