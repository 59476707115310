<template>
  <div class="tw-w-[600px] tw-mx-auto">
    <!-- <h1 class="tw-text-ap-h1 tw-sg-h1">
      page.vue
    </h1>

    <ap-awesome />
    <ap-slider />

    <pre>
      {{ wordpressData }}
    </pre>

    <p v-html="wordpressData.the_content" /> -->
    
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

onMounted(() => {
  console.log(process.env.APP_ENV);
  console.log(props.wordpressData, 'check wordpressData');
  if (process.env.WP_HOME) {
    window.location.href = process.env.WP_HOME;
  }
});

</script>
