<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar :is-transparent="false" :is-hidden="false" :cny-navigation="true" />

    <secondary-navigation :menu-items-data="menuItems" :offset-y="90" />

    <!-- Header -->
    <section>
      <div class="tw-relative">
        <picture>
          <source media="(max-width: 375px)" :srcset="$cdn('images/cny2024/banner_375.png')" />
          <source media="(max-width: 680px)" :srcset="$cdn('images/cny2024/banner_680.png')" />
          <source media="(max-width: 800px)" :srcset="$cdn('images/cny2024/banner_800.png')" />
          <source media="(max-width: 1025px)" :srcset="$cdn('images/cny2024/banner_1025.png')" />
          <img :src="$cdn('images/cny2024/banner_1440.png')" class="tw-w-full tw-object-cover" />
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-dw-title sgTablet:tw-text-sg-h1-tablet-dw-title sgDesktop:tw-text-sg-h1-dw-title tw-pb-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_visiting_101') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-dw sgTablet:tw-text-sg-sh1-tablet-dw sgDesktop:tw-text-sg-sh1-dw tw-mt-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_greetings_nav') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Content -->
    <section class="tw-px-4 tw-pt-4 tw-pb-8 sgTablet:tw-px-10 sgTablet:tw-pt-10 sgTablet:tw-pb-8 sgDesktop:tw-px-20 sgDesktop:tw-pt-10 sgDesktop:tw-pb-12">
      <div class="tw-max-w-[800px] tw-mx-auto tw-pt-10 tw-text-[#484848] tw-flex tw-flex-col">
        <div>
          <h3
            class="tw-text-center tw-text-sg-h3-mobile-dw-title tablet:tw-text-sg-h3-tablet-dw-title desktop:tw-text-sg-h3-dw-title"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('cny_greetings_maximum_huat') }}
          </h3>
        </div>
        <div class="tw-relative tw-flex tw-flex-row tw-justify-center -tw-mt-[24px] tw-py-11">
          <!-- Huat Ah Floating Text -->
          <p class="tw-text-sg-cny24-red tw-mr-4 tw-rotate-12 tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">"</p>
          <p
            class="tw-text-sg-cny24-red tw-mr-4 -tw-rotate-[16deg] tw-text-sg-h1-mobile-qm tablet:tw-text-sg-h1-tablet-qm desktop:tw-text-sg-h1-qm tw-uppercase"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('cny_greetings_huat_huat') }}
          </p>
          <p class="tw-text-sg-cny24-red -tw-rotate-[8deg] tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">"</p>
        </div>
        <div class="tw-text-center">
          <!-- <div
            class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1"
            v-html="
              $t('cny_greetings_paragraph_1')
            "
          />
          <br>
          <p
            class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1"
            v-html="$t('cny_greetings_paragraph_2')"
          /> -->
          <div class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1" v-html="props.wordpressData.description" />
        </div>
      </div>
    </section>

    <!-- Greetings Swipe-->
    <section class="greetings-green-bg tw-relative">
      <div class="tw-w-full tw-absolute tw-top-[-21px] sgTablet:tw-top-[-32px] sgDesktop:tw-top-[-57px] tw-left-0">
        <div
          class="tw-max-w-[343px] tablet:tw-max-w-[460px] sgTablet:tw-max-w-[600px] sgTablet2:tw-max-w-[944px] sgDesktop:tw-max-w-7xl tw-mx-auto tw-flex tw-flex-row tw-justify-between"
        >
          <div>
            <img class="tw-w-[46px] sgTablet:tw-w-[64px] sgDesktop:tw-w-[100px]" :src="$cdn('images/cny2024/greetings/cny_greetings_orange_left.png')" />
          </div>
          <div>
            <img class="tw-w-[46px] sgTablet:tw-w-[64px] sgDesktop:tw-w-[100px]" :src="$cdn('images/cny2024/greetings/cny_greetings_orange_right.png')" />
          </div>
        </div>
      </div>

      <cny-24-greetings-swiper swiper-type="basic" custom-id="greetings_basic" />
      <cny-24-greetings-swiper swiper-type="intermediate" custom-id="greetings_intermediate" />
      <cny-24-greetings-swiper swiper-type="advanced" custom-id="greetings_advanced" />
      <cny-24-greetings-swiper swiper-type="zodiac" custom-id="greetings_zodiac" />
    </section>

    <!-- Greetings Swipe-->
    <section class="tw-bg-[#EDF5F9]">
      <cny-24-greetings-dialect />
    </section>

    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData: any;
}>();

const menuItems = computed(() => [
  {
    label: 'cny_greetings_basic',
    id: 'greetings_basic',
  },
  {
    label: 'cny_greetings_intermediate',
    id: 'greetings_intermediate',
  },
  {
    label: 'cny_greetings_advanced',
    id: 'greetings_advanced',
  },
  {
    label: 'cny_greetings_zodiac',
    id: 'greetings_zodiac',
  },
  {
    label: 'cny_greetings_dialects',
    id: 'greetings_dialect',
  },
]);
</script>

<style>
.greetings-green-bg {
  background: linear-gradient(180deg, #d6f5e9 0%, #b0dbca 100%);
}
</style>
