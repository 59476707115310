<template>
  <section
    :class="[
      'tw-relative',
      'tw-w-full tw-flex tw-flex-col tw-items-center tw-my-10 tw-min-h-[450px] sgTablet:tw-min-h-[430px]',
      {'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}
    ]"
  >
    <section
      class="desktop-timeline tw-absolute"
    >
      <!-- desktop view -->
      <div
        class="tw-flex"
        @mousedown="mouseDown"
        @mousemove="mouseMove"
        @mouseup="mouseUp"
        @mouseleave="mouseLeave"
        @touchmove="mouseMove"
        @touchstart="mouseDown"
        @touchend="mouseUp"
      >
        <ws-home-timeline-card
          v-for="(item, index) in timeline"
          :id="item.year"
          :key="item.year"
          :image-source-open="imageSourceOpen"
          :img="item.image"
          :year="item.year"
          :description="item.description"
          :reference="item.reference"
          :active="index === 1"
          :show-description="index === showDescriptionIndex"
          :slider-move="sliderMove"
          :is-click="isClicked"
          @click="handleOnclickTimeline(index)"
        />
      </div>
    </section>

  </section>
</template>


<script setup lang="ts">

import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  imageSourceOpen: boolean,
  datas: any
}>();

const timeline = ref<any>([]);

const isMobile = ref(window.innerWidth < 680);
const sliderMove = ref(false);
const isClicked = ref(false);

const showDescriptionIndex = ref(1);
const description = ref(null);

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

function assignData() {
  timeline.value = props.datas;
}

function handleResize(){
  isMobile.value = window.innerWidth < 680;
  assignData();
}

function handleOnclickTimeline(index: number, event?: any) {
  // prevent click when when slider moving
  if (isClicked.value) { return; }

  if (index == 0) {
    isClicked.value = true;
    sliderMove.value = true;
    const leftItem = document.getElementById(timeline.value[0].year);
    leftItem?.classList.add('sg-move-center-from-left');
    const middleItem = document.getElementById(timeline.value[1].year);
    middleItem?.classList.add('sg-move-right-from-center');
    const rightItem = document.getElementById(timeline.value[2].year);
    rightItem?.classList.add('sg-move-left-from-right');

    setTimeout(() => {
      isClicked.value = false;
      sliderMove.value = false;
      timeline.value.forEach((item: any) => {
        const itemElement = document.getElementById(item.year);
        itemElement?.classList.remove('sg-move-center-from-left');
        itemElement?.classList.remove('sg-move-right-from-center');
        itemElement?.classList.remove('sg-move-left-from-right');
      });
      timeline.value = [
        timeline.value[2],
        timeline.value[0],
        timeline.value[1],
      ];
    }, 250);
  } else if (index == 2) {
    isClicked.value = true;
    sliderMove.value = true;
    const leftItem = document.getElementById(timeline.value[0].year);
    leftItem?.classList.add('sg-move-right-from-left');
    const middleItem = document.getElementById(timeline.value[1].year);
    middleItem?.classList.add('sg-move-left-from-center');
    const rightItem = document.getElementById(timeline.value[2].year);
    rightItem?.classList.add('sg-move-center-from-right');


    setTimeout(() => {
      isClicked.value = false;
      sliderMove.value = false;
      timeline.value.forEach((item: any) => {
        const itemElement = document.getElementById(item.year);
        itemElement?.classList.remove('sg-move-right-from-left');
        itemElement?.classList.remove('sg-move-left-from-center');
        itemElement?.classList.remove('sg-move-center-from-right');
      });
      timeline.value = [
        timeline.value[1],
        timeline.value[2],
        timeline.value[0],
      ];
    }, 250);
  }
}

const isDragging = ref(false);
const startX = ref(0);
const walk = ref(0);

function mouseDown(e: any) {
  touchStart();
  isDragging.value = true;
  startX.value = getEventPageX(e);
  e.currentTarget.style.cursor = 'grabbing';
  document.body.style.userSelect = 'none';
}

function mouseMove(e: any) {
  if (!isDragging.value) return;
  const x = getEventPageX(e);
  walk.value = x - startX.value;

  if (Math.abs(walk.value) < 70) return;
  e.preventDefault();
  // Check if threshold is exceeded
  if (walk.value > 0) {
    handleOnclickTimeline(0); // Function to handle dragging to the right
  } else {
    handleOnclickTimeline(2); // Function to handle dragging to the left
  }
}

function mouseUp(e: any) {
  isDragging.value = false;
  if (Math.abs(walk.value) < 70) {
    touchEnd();
  }
  e.currentTarget.style.cursor = 'grab';
  document.body.style.userSelect = 'auto';
  walk.value = 0;
}

function mouseLeave(e: any) {
  isDragging.value = false;
  e.currentTarget.style.cursor = 'pointer';
  document.body.style.userSelect = 'auto';
}

function getEventPageX(e: any): number {
  if (e.touches && e.touches.length > 0) {
    return e.touches[0].pageX; // Handle touch events
  }
  if (e.pageX !== undefined) {
    return e.pageX; // Handle mouse events
  }
  return 0; // Default fallback
}

const emit = defineEmits(['update:imageSourceOpen']);

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};
const currentSlideIndex = ref(0);

const onSlideChange = () => {
  if (swiperInstance?.value) {
    currentSlideIndex.value = swiperInstance?.value.realIndex;
  }
};

function touchStart() {
  sliderMove.value = true;
}

function touchEnd() {
  sliderMove.value = false;
}

</script>

<style scoped lang="scss">


.sg-move-center-from-left {
  transform: translateX(240px) translateY(-48px);
  animation: sg-animation-1 0.3s ease; /* Apply the bounce effect */
  opacity: 1; /* Fully visible when at center */
}

@keyframes sg-animation-1 {
  0% {
    transform: translateX(0) ;
    opacity: 0.8; /* Partially visible */
  }
  100% {
    transform: translateX(240px)  translateY(-48px);
    opacity: 1; /* Fully visible */
  }
}

.sg-move-right-from-center {
  transform: translateX(240px) translateY(48px);
  animation: sg-animation-2 0.3s ease;
  opacity: 0.5; /* Partially visible */
}

@keyframes sg-animation-2 {
  0% {
    transform: translateX(0) ;
    opacity: 1; /* Fully visible at the center */
  }
  100% {
    transform: translateX(240px) translateY(48px);
    opacity: 0.5; /* Partially visible */
  }
}

.sg-move-left-from-right {
  transform: translateX(-480px);
  animation: sg-animation-3 0.3s ease;
  opacity: 0.5; /* Partially visible */
}

@keyframes sg-animation-3 {
  0% {
    transform: translateX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(-480px);
    opacity: 0.5; /* Partially visible */
  }
}

.sg-move-right-from-left {
  transform: translateX(480px) ;
  animation: sg-animation-reverse-1 0.3s ease;
  opacity: 0.5;
}

@keyframes sg-animation-reverse-1 {
  0% {
    transform: translateX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(480px);
    opacity: 0.5; /* Partially visible */
  }
}

.sg-move-left-from-center {
  transform: translateX(-240px) translateY(48px);
  animation: sg-animation-reverse-2 0.3s ease;
  opacity: 0.5;
}

@keyframes sg-animation-reverse-2 {
  0% {
    transform: translateX(0) ;
    opacity: 1; /* Fully visible at the center */
  }
  100% {
    transform: translateX(-240px) translateY(48px);
    opacity: 0.5;
  }
}

.sg-move-center-from-right {
  transform: translateX(-240px) translateY(-48px);
  animation: sg-animation-reverse-3 0.3s ease;
  opacity: 1;
}

@keyframes sg-animation-reverse-3 {
  0% {
    transform: translateX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(-240px) translateY(-48px);
    opacity: 1;
  }
}

@media screen and (min-width: 680px) {
  .sg-move-center-from-left {
    transform: translateX(300px) translateY(-48px);
    animation: sg-animation-1-desktop 0.3s ease; /* Apply the bounce effect */
    opacity: 1; /* Fully visible when at center */
  }

  @keyframes sg-animation-1-desktop {
    0% {
      transform: translateX(0) ;
      opacity: 0.5; /* Partially visible */
    }
    100% {
      transform: translateX(300px)  translateY(-48px);
      opacity: 1; /* Fully visible */
    }
  }

  .sg-move-right-from-center {
    transform: translateX(300px) translateY(48px);
    animation: sg-animation-2-desktop 0.3s ease;
    opacity: 0.5; /* Partially visible */
  }

  @keyframes sg-animation-2-desktop {
    0% {
      transform: translateX(0) ;
      opacity: 1; /* Fully visible at the center */
    }
    100% {
      transform: translateX(300px) translateY(48px);
      opacity: 0.5; /* Partially visible */
    }
  }

  .sg-move-left-from-right {
    transform: translateX(-600px);
    animation: sg-animation-3-desktop 0.3s ease;
    opacity: 0.5; /* Partially visible */
  }

  @keyframes sg-animation-3-desktop {
    0% {
      transform: translateX(0);
      opacity: 0.5;
    }
    100% {
      transform: translateX(-600px);
      opacity: 0.5; /* Partially visible */
    }
  }

  .sg-move-right-from-left {
    transform: translateX(300px) ;
    animation: sg-animation-reverse-1-desktop 0.3s ease;
    opacity: 0.5;
  }

  @keyframes sg-animation-reverse-1-desktop {
    0% {
      transform: translateX(0);
      opacity: 0.5;
    }
    100% {
      transform: translateX(600px);
      opacity: 0.5; /* Partially visible */
    }
  }

  .sg-move-left-from-center {
    transform: translateX(-300px) translateY(48px);
    animation: sg-animation-reverse-2-desktop 0.3s ease;
    opacity: 0.5;
  }

  @keyframes sg-animation-reverse-2-desktop {
    0% {
      transform: translateX(0) ;
      opacity: 1; /* Fully visible at the center */
    }
    100% {
      transform: translateX(-300px) translateY(48px);
      opacity: 0.5;
    }
  }

  .sg-move-center-from-right {
    transform: translateX(-300px) translateY(-48px);
    animation: sg-animation-reverse-3-desktop 0.3s ease;
    opacity: 1;
  }

  @keyframes sg-animation-reverse-3-desktop {
    0% {
      transform: translateX(0);
      opacity: 0.5;
    }
    100% {
      transform: translateX(-300px) translateY(-48px);
      opacity: 1;
    }
  }

}
</style>

