<template>
  <div
    class="tw-w-full tw-relative tw-overflow-visible sgTablet:tw-h-[600px]"
  >
    <swiper
      id="zy-taboos-swiper"
      :slides-per-view="'auto'"
      :space-between="20"
      :center-insufficient-slides="true"
      :centered-slides="true"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in data"
        :key="index"
        class="sgTablet:tw-w-[600px] sgTablet:tw-h-[600px] tw-relative"
      >
        <!-- Content -->
        <img
          :src="$cdn(item.image)"
          class="
            tw-object-center
            tw-rounded-sm
            sgTablet:tw-h-[600px]
            sgTablet:tw-w-[600px]
            sgTablet:tw-p-0"
        >
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import type {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
type Post={
  image:any,
}
defineProps<{
  data: Post[],
}>();

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper:any) => {
  swiperInstance.value = swiper;
};
</script>
<style scoped lang="scss">

.swiper-wrapper {
    height: 350px !important;
  }

@media screen and (min-width: 768px) {
  .swiper-wrapper {
    height: 600px !important;
  }
}

</style>
