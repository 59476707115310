<template>
  <div
    id="section-pin"
    class="w-px-4 tablet:tw-px-8 sgTablet:tw-px-[40px] sgDesktop:tw-px-[80px] tw-pt-4"
  >
    <div class="tw-uppercase tw-text-sg-sccc1 tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1 tw-pb-7 tablet:tw-pb-10 desktop:tw-pb-[60px]">
      <div class="tw-flex tw-flex-wrap">
        <p>
          {{ $t('exhibition_singaporen_tag') }}
          <span
            class="tw-underline tw-ml-1 tw-break-keep"
          >
            {{ $t('exhibition_singaporen_singaporen') }}
          </span>
          {{ $t('exhibition_singaporen_tag_c') }}
          <br v-if="checkLang() != 'zh-hans'">
          {{ $t('exhibition_singaporen_experience') }}
        </p>
      </div>
    </div>

    <div
      ref="photoGridContainer"
      class="tw-grid tw-grid-cols-12 tw-gap-5 tw-mt-4 tw-rounded-md tw-relative"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
        :class="{ 'tw-hidden': !isShowMore && index >= 12 }"
        class="tw-col-span-4 tablet:tw-col-span-3 photo-grid-item"
      >
        <div
          class="tw-object-cover tw-w-full tw-h-full tw-relative tw-aspect-square tw-rounded-lg tw-bg-cover"
          :style="{ backgroundImage: `url(${item.image})` }"
        >
          <!-- Expand Icon -->
          <div
            class="tw-absolute tw-top-2 tw-right-2 tablet:tw-top-4 tablet:tw-right-4 desktop:tw-top-5 desktop:tw-right-5 tw-cursor-pointer"
            @click="handleExpandClick(index)"
          >
            <img
              :src="$cdn('icons/expand_icon.svg')"
              alt="Expand icon"
              class="tw-w-5 tw-h-5 desktop:tw-w-7 desktop:tw-h-7"
            >
          </div>
        </div>
      </div>

      <!-- Show More Gradient -->
      <div
        v-if="!isShowMore"
        class="tw-bg-gradient-to-t tw-from-white tw-from-10% tw-to-transparent tw-absolute tw-bottom-0 tw-left-0 tw-h-20 tw-w-full"
      />
    </div>

    <!-- More Button -->
    <div
      class="tw-text-sg-sccc1 tw-flex tw-w-full tw-flex-col tw-gap-y-2 tw-justify-center tw-relative tw-mt-6 tw-group tw-cursor-pointer"
      @click="handleClickShowMore"
    >
      <div class="tw-text-sg-label-4 tw-flex tw-items-center tw-justify-center group-hover:tw-underline tw-uppercase">
        {{ !isShowMore ? $t('more') : $t('less') }}
      </div>
      <div class="tw-flex tw-justify-center">
        <img
          :src="$cdn('icons/add_circle_icon.svg')"
          alt="Add Circle Icon"
          class="tw-w-4 tw-h-4 tw-items-center"
        >
      </div>
    </div>

    <!-- Spacing -->
    <div class="tw-py-8">
      <br>
    </div>

  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { checkLang } from '../../support/helper';

interface LightBoxData {
  image: string;
  username: string;
  description: string;
  social_platform: string;
  social_link: string;
}

const props = withDefaults(
  defineProps<{
    socialMediaPost: any;
  }>(),
  {
    socialMediaPost: '',
  }
);

const isShowMore = ref<boolean>(false);
const data = ref<LightBoxData[]>([]);

const emit = defineEmits(['lightBoxSrc', 'isLightBoxOpen']);

onMounted(() => {
  if (!!props.socialMediaPost) {
    data.value = props.socialMediaPost as unknown as LightBoxData[];
  }
});

const handleExpandClick = (index: number) => {
  const currentData = data.value[index];
  emit('lightBoxSrc', currentData);
  emit('isLightBoxOpen', true);
};

const handleClickShowMore = () => {
  isShowMore.value = !isShowMore.value;
};
</script>
