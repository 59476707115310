<template>
  <div
    :class="[
      'tw-w-full tw-h-[512px] sgDesktop:tw-h-[554px]',
      {'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}
    ]"
  >
    <div
      id="ma-home-swiper"
      ref="emblaRef"
      class="embla"
    >
      <div class="embla__container">
        <div
          v-for="(item, index) in extendedData"
          :key="index"
          :class="['embla__slide','tw-h-[512px] sgDesktop:tw-h-[554px]']"
        >
          <div
            :class="[
              'tw-max-w-max',
              'tw-py-3 sgTablet:tw-py-4 sgDesktop:tw-py-5',
              'tw-px-2 sgTablet:tw-px-3 sgDesktop:tw-px-4',
              'tw-relative',
              'tw-h-min',
              item.positionFromTop,
              item.rotation,
            ]"
          >
            <!-- Content -->
            <div class="tw-w-[240px] sgTablet:tw-w-[300px] sgDesktop:tw-w-[352px]">
              <img
                :src="item.image"
                :class="[
                  {
                    '!tw-h-[169px] sgTablet:!tw-h-[208px] sgDesktop:!tw-h-[240px]': item.image_orientation == 'landscape',
                    '!tw-h-[224px] sgTablet:!tw-h-[276px] sgDesktop:!tw-h-[320px] !tw-w-[169px] sgTablet:!tw-w-[208px] sgDesktop:!tw-w-[240px]':
                      item.image_orientation == 'portrait',
                  },
                  'tw-w-auto tw-h-auto tw-mx-auto',
                  'tw-object-contain tw-object-center',
                  'tw-outline-4 tw-outline-white tw-outline -tw-outline-offset-4',
                ]"
              >
              <div
                :class="[
                  'tw-text-sg-ma-h4-mobile sgTablet:tw-text-sg-ma-h4-tablet sgDesktop:tw-text-sg-ma-h4-desktop',
                  'tw-flex tw-justify-center',
                  'tw-text-sg-ma24-purple-b60',
                  'tw-my-5',
                  { 'tw-font-akkuratbold' : checkLang() == 'en' },
                  { 'tw-font-notosansbold' : checkLang() != 'en' },
                ]"
              >
                <div class="tw-border-b-4 tw-border-b-sg-ma24-purple-b60">
                  {{ item.year }}
                </div>
              </div>
              <div
                :class="[
                  'tw-text-sg-ma-sh3 tw-text-sg-ma24-purple-b60 tw-text-center',
                  { 'tw-font-akkuratbold' : checkLang() == 'en' },
                  { 'tw-font-notosansbold' : checkLang() != 'en' },
                ]"
              >
                {{ item.description }}
                <span v-if="item.reference">
                  <sup
                    class="tw-cursor-pointer tw-font-akkurat tw-leading-[26px] tw-font-[400]"
                    @click="handleOpenImageSourceModal"
                  >[{{ item.reference }}]</sup>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect, onMounted } from 'vue';
import emblaCarouselVue from 'embla-carousel-vue';
import AutoScroll from 'embla-carousel-auto-scroll';
import { checkLang } from '../../support/helper';
const [emblaRef] = emblaCarouselVue(
  {
    loop: true,
    align: 'start',
    watchDrag:false,
  } ,[AutoScroll({
  //startDelay:0,
    speed:1,
  })]
);

type Post = {
  year: string;
  image: any;
  description: string;
  reference: string;
  rotation: number;
  positionFromTop: number;
  image_orientation: 'landscape' | 'portrait';
};

const props = defineProps<{
  data: Post[];
  imageSourceOpen: boolean;
}>();

const emit = defineEmits(['update:imageSourceOpen']);

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};



const extendedData = computed(() => {
  // Loop to 20 slides
  if (!props.data) return [];
  if (!(props.data.length > 0)) return [];

  const neededCount = props.data.length > 20 ? props.data.length : 20;

  const copyCount = Math.floor(neededCount / props.data.length);
  return Array.from({ length: copyCount }).flatMap(() => props.data);
});
// https://github.com/nolimits4web/swiper/issues/6481#issuecomment-2175013508

</script>

<style scoped lang="scss">
.embla {
  overflow: hidden;
  //@apply tw-h-[512px] sgDesktop:tw-h-[554px];
}
.embla__container {
  display: flex;
  //@apply tw-h-[512px] sgDesktop:tw-h-[554px];
  margin-right: 4px;
}
.embla__slide {
  flex: 0 0 auto; /* Adapt slide size to its content */
  min-width: 0;
  padding-left:4px;
  // max-width: 100%; /* Prevent from growing larger than viewport */
}
</style>
