<template>
  <div
    class="tw-w-full tw-relative before:tw-content-[''] before:tw-absolute before:tw-top-[6px] before:tw-left-0 before:tw-w-full before:tw-border-t-2 before:tw-border-t-sg-dw24-blue-b60"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
  >
    <swiper
      id="dw-home-swiper"
      :slides-per-view="'auto'"
      :space-between="28"
      :slides-offset-before="0"
      :slides-offset-after="0"
      centered-slides
      centered-slides-bounds
      center-insufficient-slides
      :breakpoints="{
        680: {
          slidesOffsetBefore: 28,
          slidesOffsetAfter: 28,
          slidesPerView: 'auto',
          spaceBetween: 48,
          centeredSlides: false,
          centeredSlidesBounds: false,
        },
        1025: {
          slidesOffsetBefore: 64,
          slidesOffsetAfter: 64,
          slidesPerView: 'auto',
          spaceBetween: 48,
          centeredSlides: false,
          centeredSlidesBounds: false,
        },
      }"
      @swiper="onSwiper"
      @resize="checkSlidePosition"
      @slide-change="checkSlidePosition"
      @touch-end="checkSlidePosition"
    >
      <swiper-slide
        v-for="(item, index) in data"
        :key="index"
        class="tw-w-[240px] sgTablet:tw-w-[300px] sgDesktop:tw-w-[352px] tw-h-[572px] tw-px-4 sgTablet:tw-px-2 sgDesktop:tw-px-4 tw-relative"
      >
        <span class="tw-absolute tw-w-3 tw-h-3 tw-rounded-full tw-bg-sg-dw24-blue-b60 tw-left-1/2 -tw-translate-x-1/2" />
        <div
          class="tw-text-sg-h3-mobile sgTablet:tw-text-sg-h3-tablet sgDesktop:tw-text-sg-h3-dw tw-flex tw-justify-center tw-text-sg-dw24-blue-b60 tw-mt-[36px] sgTablet:tw-mt-[40px] sgDesktop:tw-mt-[44px]"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkurat'"
        >
          <div class="tw-border-b-4 tw-border-b-sg-dw24-blue-b60">
            {{ item.year }}
          </div>
        </div>
        <img
          :src="item.image"
          class="tw-h-[169px] sgTablet:tw-h-[208px] sgDesktop:tw-h-[240px] tw-w-full tw-object-cover tw-object-center tw-mt-5"
        >
        <p class="tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-text-sg-dw24-blue-b60 tw-mt-5">
          {{ item.description }}
          <span v-if="item.reference">
            <sup
              class="tw-cursor-pointer tw-text-[#0366D8]"
              @click="handleOpenImageSourceModal"
            >[{{ item.reference }}]</sup>
          </span>
        </p>
      </swiper-slide>
    </swiper>

    <div class="tw-hidden sgDesktop:tw-flex tw-py-10 tw-px-20">
      <button
        class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-text-white tw-bg-sg-dw24-blue-b20 tw-text-[35px] tw-pb-2 tw-pr-1 disabled:tw-opacity-25"
        :disabled="disabledPrev"
        @click="slidePrev()"
      >
        &lt;
      </button>
      <button
        class="tw-rounded-full tw-h-[56px] tw-w-[56px] tw-ml-5 tw-text-white tw-bg-sg-dw24-blue-b20 tw-text-[35px] tw-pb-2 tw-pl-1 disabled:tw-opacity-25"
        :disabled="disabledNext"
        @click="slideNext()"
      >
        >
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { FreeMode, Mousewheel, Autoplay } from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
import { onMounted, toRefs, ref, Ref, computed } from 'vue';
import { cdn } from '../../support/cdn';
type Post = {
  year: string;
  image: any;
  description: string;
  reference: string;
};
const props = defineProps<{
  data: Post[];
  imageSourceOpen: boolean;
}>();
const emit = defineEmits(['update:imageSourceOpen']);

const { t } = useI18n();

const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
  checkSlidePosition();
};
const handleOpenImageSourceModal = () => {
  // alert('Image Source Not Implemented');
  imageSourceOpen.value = !imageSourceOpen.value;
};
const disabledPrev = ref(false);
const disabledNext = ref(false);
function slideNext() {
  swiperInstance.value?.slideNext();
  checkSlidePosition();
}

function slidePrev() {
  swiperInstance.value?.slidePrev();
  checkSlidePosition();
}

function checkSlidePosition() {
  if ((swiperInstance.value?.isBeginning && swiperInstance.value?.isEnd) || (!swiperInstance.value?.allowSlideNext && !swiperInstance.value?.allowSlidePrev)) {
    disabledPrev.value = true;
    disabledNext.value = true;
  } else if (swiperInstance.value?.snapIndex == props.data.length - 1 || swiperInstance.value?.isEnd) {
    disabledPrev.value = false;
    disabledNext.value = true;
  } else if (swiperInstance.value?.snapIndex == 0 || swiperInstance.value?.isBeginning) {
    disabledPrev.value = true;
    disabledNext.value = false;
  } else {
    disabledPrev.value = false;
    disabledNext.value = false;
  }
}

// Reminder
// onMounted(handleOpenImageSourceModal);
</script>
<style lang="scss"></style>
