<template>
  <div
    class="flip-card"
    :class="{ flipped: isFlipped }"
  >
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img
          :src="$cdn(imageFront)"
          alt="Question"
          :style="`width:${width};`"
        >
      </div>
      <div class="flip-card-back">
        <img
          :src="$cdn(imageBack)"
          alt="Answer"
          :style="`width:${width};`"
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  imageFront: {
    type: String,
    required: true
  },
  imageBack: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '440px'
  },
  height: {
    type: String,
    default: '440px'
  },
  flipped: {
    type: Boolean,
    default: false
  }
});

const isFlipped = ref(props.flipped);

watch(() => props.flipped, (newVal) => {
  isFlipped.value = newVal;
});
</script>

<style scoped>
.flip-card {
  background-color: transparent;
  width: 440px;
  height: 440px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

@media screen and (max-width: 680px) {
  .flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
}
}
</style>
