/**
 * This function also available globally
 *
 * Usage:
 *
 *  <a
    :href="$langRedirect(item.link)"
    >{{ item.label }}</a>
 */

export const langRedirect = (path: string, external?: string) => {
  if (external === 'external') {
    return path;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const currentLang = urlParams.get('lang') || 'en';
  const langParam = `?lang=${currentLang}`;

  return `${path}${langParam}`;
};
