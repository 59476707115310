<template>
  <div>
    <swiper
      :slides-per-view="'auto'"
      :space-between="0"
      :center-insufficient-slides="true"
      :slides-offset-before="16"
      :free-mode="true"
      @swiper="onSwiper"
    >
      <slot />
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { Swiper as SwiperInstance } from 'swiper';
import { Swiper } from 'swiper/vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps<{
  width?: string;
  height?: string;
  mobileHeight?: string;
}>();

const swiperInstance = ref<SwiperInstance | null>(null);

const onSwiper = (swiper: SwiperInstance) => {
  swiperInstance.value = swiper;
};

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
  updateIsMobile();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateIsMobile);
});
</script>


<style scoped>


@media (min-width: 768px) {
  .swiper-container {
    display: flex;
    justify-content: center;
  }
}
</style>

