<template>
  <div>
    <img
      v-if="data.is_singaporen_way"
      class="tw-absolute tw-top-4 tw-right-4 sgTablet:tw-top-5 sgTablet:tw-right-5 tw-w-[100px] tw-h-[66px]  tw-z-20"
      :src="$cdn(checkLang() !== 'zh-hans' ? 'icons/singaporen_way_stamp-4.png' : 'icons/singaporen_way_stamp_cn-4.png')"
    >

    <div
      class="tw-bg-ap-white  tw-h-[560px] sgTablet:tw-h-[580px] tw-rounded-[20px] tw-flex tw-flex-col tw-overflow-hidden"
    >
      <div
        class="tw-flex tw-flex-col tw-items-center tw-relative tw-w-full tw-h-full tw-transition-all tw-ease-in tw-duration-200"
        :class="[
          {'tw-max-h-[332px] sgTablet:tw-max-h-[324px]':!isExpanded },
          { 'tw-max-h-[92px] sgTablet:tw-max-h-[232px]': isExpanded}
        ]"
      >
        <img
          :src="data.image"
          class="tw-object-cover tw-w-full tw-h-full"
        >
        <div class="tw-absolute tw-bottom-[-30px]">
          <!-- Numbering -->
          <div
            class="tw-w-[52px] tw-h-[52px] sgTablet:tw-w-[60px] sgTablet:tw-h-[60px] tw-rounded-full tw-bg-sg-qm24-green-b60 tw-text-ap-white  tw-text-sg-h4-mobile tablet:tw-text-sg-h4-tablet desktop:tw-text-sg-h4 tw-flex tw-justify-col tw-justify-center tw-items-center tw-text-center"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="tw-pt-[44px] tw-px-7 tw-pb-5 sgTablet:tw-pt-10 tw-grow tw-flex tw-flex-col tw-justify-between tw-gap-3">
        <p
          class="tw-text-sg-body-1 tw-text-sg-qm24-green-b60 tw-text-left tw-text-pretty tw-transition-all tw-ease-in-out tw-duration-1000 sgTablet:tw-duration-200"
          style="font-size: 16px !important; line-height: 24px !important; letter-spacing: 0px !important; "
          v-html="description"
        />
        <!-- Click More/Less button (description expander) -->
        <div
          :class="[
            'chev-btn tw-rounded-b-[20px] tw-flex tw-flex-col tw-justify-center tw-items-center',
          ]"
          @click="onClickMore()"
        >
          <img
            class="tw-h-auto tw-w-3 tw-m-[6px] tw-cursor-pointer"
            :src="$cdn(getIconSrcPath(index))"
          >
          <div
            :class="[
              { 'tw-text-sg-qm25-green-b20 hover:tw-underline tw-cursor-pointer' :textIsTruncated},
              { 'tw-text-[#D1D1D1]' : !textIsTruncated},
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ isExpanded ? $t('qm_qm101_less') : $t('qm_qm101_more') }}
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { Pagination, Mousewheel} from 'swiper/modules';
import SwiperCore from 'swiper';
import { onMounted, ref, Ref,computed,nextTick ,onBeforeMount} from 'vue';
import { checkLang } from '../../support/helper';

SwiperCore.use([Pagination, Mousewheel]);
interface ObservationCard {
  description: string;
  is_singaporen_way: boolean;
  image: string;
}

const props = defineProps<{
  data: ObservationCard,
  index: number
}>();

const isExpanded = ref(false);
const textIsTruncated = ref(false);
const isMobile = ref(window.innerWidth <= 680);
const description = ref('');
const maxDescriptionLength = ref(280);

const emit = defineEmits(['openSources']);



const checkSwiper = (swiper:any) => {
  //console.log('check swiper', swiper);
};

const internationaliseNumber = (number: number) => {
  let res;
  res = number;
  if (checkLang() === 'zh-hans') {
    switch (number) {
      case 1:
        res = '一';
        break;
      case 2:
        res = '二';
        break;
      case 3:
        res = '三';
        break;
      case 4:
        res = '四';
        break;
      case 5:
        res = '五';
        break;
      case 6:
        res = '六';
        break;
      case 7:
        res = '七';
        break;
      case 8:
        res = '八';
        break;
      case 9:
        res = '九';
        break;
      case 10:
        res = '十';
        break;

    }
  }
  return res;
};

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeMount(() => {
  removeSupClickHandler();
  window.removeEventListener('resize', onResize);
});


function onResize() {
  // check if mobile
  isMobile.value = window.innerWidth <= 680;
  attachSupClickHandler();
  if (isMobile.value) {
    maxDescriptionLength.value = 160;
    description.value = truncateHtmlText(props.data.description, maxDescriptionLength.value);
  }else{
    maxDescriptionLength.value = 280;
    description.value = truncateHtmlText(props.data.description);
  }
}

function emitClickHandler() {
  emit('openSources');
  // console.log('emitClickHandler');
}

function attachSupClickHandler() {
  nextTick(() => {
    const supElements = document.querySelectorAll('#observation_card sup');
    if(supElements?.length > 0){
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', emitClickHandler);
      });
    }
  });
}

function removeSupClickHandler() {
  const supElements = document.querySelectorAll('#observation_card sup');
  if(supElements?.length > 0){
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', emitClickHandler);
    });
  }
}

/**
 * Truncates an HTML string to a specified maxLength, removing any <p> tags,
 * and re-wrapping the text with <p> tags and adding an ellipsis.
 *
 * @param {string} htmlString - The HTML string to truncate
 * @param {number} maxLength - The maximum length of the truncated string
 * @return {string} The truncated HTML string
 */
function truncateHtmlText(htmlString: string, maxLength = maxDescriptionLength.value) {
  // Remove <p> tags from the HTML string
  const strippedText = htmlString.replace(/<\/?p>/g, '');
  if (strippedText.length <= maxLength) {
    textIsTruncated.value = false;
    return htmlString;
  }
  textIsTruncated.value = true;
  // Limit the text to the specified maxLength
  const limitedText = strippedText.substring(0, maxLength);
  // Re-wrap with <p> tags and add ellipsis
  return `<p>${limitedText}...</p>`;
}


function onClickMore() {
  if (textIsTruncated.value) {
    isExpanded.value = !isExpanded.value;
    if (isExpanded.value) {
      attachSupClickHandler();
      description.value = props.data.description;
    } else {
      description.value = truncateHtmlText(props.data.description, maxDescriptionLength.value);
    }
  }
}

function getIconSrcPath(index:number){
  //return icon based on if cards have long desc or not, if less than max characters default to gray up icon
  if(textIsTruncated.value){
    if(isExpanded.value)
      return 'icons/qm/down_vector.png';
    else
      return 'icons/qm/up_vector.png';

  }

  else{
    return 'icons/qm/up_vector_gray.png';
  }
}

</script>

<style lang="scss">

#observation_card sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}

</style>
