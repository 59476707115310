<template>
  <div
    id="cny_highlights_menu"
    class="tw-w-full tw-h-[700px] sgTablet:tw-h-[500px] sgTablet2:tw-h-[640px] sgDesktop:tw-h-[900px]"
  >
    <swiper
      ref="swiperRef"
      :pagination="pagination"
      :effect="'slide'"
      :modules="modules"
      class="highlightsSwiper tw-h-full"
      loop
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="item in data"
        :key="item.id"
        class="tw-relative"
      >
        <div class="tw-h-full">
          <div
            v-if="!item.isVideo"
            :class="['tw-p-3 sgTablet:tw-p-4 sgDesktop:tw-p-8 tw-h-full tw-w-full tw-bg-cover']"
            :style="{ backgroundImage: `url(${item.image})` }"
          />
          <video
            v-else
            :id="`video-${item.id}`"
            playsinline
            class="video-js tw-p-3 sgTablet:tw-p-4 sgDesktop:tw-p-8 tw-h-full"
            preload="auto"
            autoplay
            muted
            width="640"
            height="360"
          />
        </div>

        <div
          class="tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-z-2 tw-bg-gradient-to-b tw-from-transparent tw-from-[30%] tw-via-ap-white/20 tw-via[94%] tw-to-ap-white"
        />

        <div
          v-if="screenWidth < 800"
          class="tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-z-10 tw-text-sg-cny24-red tw-flex tw-flex-col-reverse"
        >
          <div class="tw-flex tw-flex-wrap tw-mr-5 tw-px-4 tw-w-[100%] tw-flex-col">
            <div
              v-if="!!item.title"
              class="tw-text-left tw-pb-5 tw-text-sg-cny24-red tw-text-sg-cny25-sh1 tw-uppercase"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.title }}
            </div>
            <div
              v-if="!!item.description"
              class="tw-text-left tw-pb-5 tw-text-sg-cny24-red tw-text-sg-body-1-mobile-cny sgTablet:tw-text-sg-body-1-tablet-cny- sgDesktop:tw-text-sg-body-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ item.description }}
            </div>
            <div class="tw-pb-[76px] tw-flex">
              <button
                v-if="!!item.cta"
                class="tw-rounded-[26px] tw-mr-5 tw-text-white tw-bg-sg-cny24-red tw-text-sg-button-mobile-1-cny tablet:tw-text-sg-button-1-cny !tw-leading-[20px] tw-px-6 tw-py-4"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                :href="item.external_link"
                rel="noopener"
                target="_blank"
              >
                {{ item.cta }}
              </button>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
import SwiperCore from 'swiper/core';
import { onMounted, toRefs, defineComponent, ref, nextTick, watch, onBeforeUnmount, computed, PropType } from 'vue';
import videojs from 'video.js';
import { getScreenSize } from '../../support/helper';
import { cdn } from '../../support/cdn';
SwiperCore.use([Navigation, Pagination, EffectFade]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Array as PropType<
        Array<{
          id: number;
          title?: string;
          description?: string;
          isVideo?: boolean;
          image?: string;
          poster?: string;
          video?: string;
          cta?: string;
          external_link?: string;
        }>
      >,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });
    const swiperInstance = ref();
    const modules = [Navigation, Pagination];
    const descText = ref('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.');
    let pagination = ref({
      clickable: true,
      renderBullet: function (index, className) {
        const slide = props.data.find((v) => v?.id === index + 1)!;
        const fontRender = checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold';
        false && console.log('checkLang()', checkLang());

        let descriptionSection = '<div class="bullet-description tw-h-[280px] tw-flex tw-flex-col tw-justify-end tw-hidden sgTablet2:tw-flex">';

        if (!!slide.title) {
          descriptionSection +=
            '<div class="tw-text-left tw-text-sg-cny24-red ' +
            (checkLang() == 'zh-hans' ? 'tw-font-notosansbold ' : 'tw-font-akkuratbold ') +
            'tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny">' +
            slide.title +
            '</div>';
        }

        if (!!slide.description) {
          descriptionSection +=
            '<div class="tw-text-left tw-pb-10 tw-pt-5 tw-text-sg-cny24-red ' +
            (checkLang() == 'zh-hans' ? 'tw-font-notosansbold ' : 'tw-font-akkuratbold ') +
            'tw-text-sg-body-1-mobile-cny sgTablet:tw-text-sg-body-1-tablet-cny sgDesktop:tw-text-sg-body-1-cny">' +
            slide.description +
            '</div>';
        }

        descriptionSection += '</div>';
        let buttonSection = '';

        if (!!slide.cta) {
          buttonSection += `<button
          class=".hl-btn-click-${index} tw-rounded-[26px] tw-mr-2 tw-text-white tw-bg-sg-cny24-red tw-px-6 tw-py-4 tw-text-sg-cny-button-mobile-1-zh tablet:tw-text-sg-cny-button-1-zh !tw-leading-[20px]  tw-uppercase tw-tracking-[0.7px] ${fontRender}"
          onclick="window.open('${slide.external_link}', '_blank','rel= noopener noreferrer')"
        ><a
          href="${slide.external_link}"
          target="_blank"
          rel="noopener"
        >${slide.cta}</a></button>`;
        }
        let indicatorSection =
          '<div class="tw-rounded tw-h-[4px] tw-w-full progress-bar tw-bg-sg-cny24-red-w60"><div class="progress-bar-fill tw-h-full tw-bg-sg-cny24-red-b20" style="width:0;"></div> </div>';

        let bullet =
          '<div class="' +
          className +
          '">' +
          '<div class="tw-flex tw-flex-col sgTablet:tw-mr-5 tw-w-[100%]">' +
          '<div class="bullet-container">' +
          descriptionSection +
          '<div class="tw-pb-10 catButton">' +
          buttonSection +
          '</div>' +
          '</div>' +
          indicatorSection +
          ' </div>' +
          '</div>';

        return bullet;
      },
    });

    const swiperRef = ref(null);
    const screenWidth = ref(0);

    onMounted(() => {
      getSwiperStyle();
      videoPlayerInit();
      window.addEventListener('resize', handleResize);
      screenWidth.value = window.innerWidth;

      window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          swiperInstance.value.slideTo(swiperInstance.value.activeIndex);

          const elmBullet = document.querySelector('.swiper-pagination-bullet-active .progress-bar .progress-bar-fill');

          if (elmBullet && typeof elmBullet.getAnimations === 'function') {
            elmBullet.getAnimations().forEach((anim) => {
              anim.cancel();
              anim.play();
            });
          }

          changeProgressBarWidth();
        }
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    const handleResize = () => {
      screenWidth.value = window.innerWidth;
    };

    const onSwiper = (swiper) => {
      // false && console.log(swiper);
      swiperInstance.value = swiper;
    };

    function getSwiperStyle() {
      // checkSlidePosition();
    }

    const getCurrentSlideIndex = () => {
      if (swiperRef.value) {
        return swiperRef.value.$el.swiper.realIndex;
      }
      return -1;
    };

    const previousTimeout = ref();
    async function onSlideChange() {
      try {
        for (let index = 0; index < props.data.length; index++) {
          const slide = props.data?.[index];
          if (slide.isVideo) {
            if (index !== getCurrentSlideIndex()) {
              let getPlayer = videojs.getPlayer(`video-${slide.id}`);
              if (!getPlayer) return;
              if (getPlayer.played().length > 0 && !getPlayer.paused()) {
                getPlayer.pause();
                getPlayer.reset();
              }
            } else {
              let getPlayer = videojs.getPlayer(`video-${slide.id}`);
              if (!getPlayer) return;

              const newSource = {
                src: slide.video,
                type: 'video/mp4',
              };

              getPlayer.src(newSource);
              getPlayer.poster(slide.poster);
              getPlayer.load();
              // await getPlayer.play();
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
      nextTick(() => {
        changeProgressBarWidth();
      });
    }

    function changeProgressBarWidth() {
      //in ms
      clearTimeout(previousTimeout.value);
      let slideChangeTime = 5000;
      const inactiveSlides = document.querySelectorAll('.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) .progress-bar .progress-bar-fill');
      inactiveSlides.forEach((element) => {
        element.style.transition = '';
        element.style.width = '0';
      });

      document.querySelector('.swiper-pagination-bullet-active .progress-bar .progress-bar-fill').style.transition = `width ${slideChangeTime}ms linear`;
      document.querySelector('.swiper-pagination-bullet-active .progress-bar .progress-bar-fill').style.width = '100%';

      previousTimeout.value = setTimeout(() => {
        swiperRef.value?.$el?.swiper?.slideNext();
      }, slideChangeTime);
    }
    function getImageUrl(index) {
      return "tw-bg-[url('https://picsum.photos/1200?random=" + index + "')]";
    }

    const videoPlayerInit = async () => {
      await nextTick();
      // false && console.log('im here check');
      for (let index = 0; index < props.data.length; index++) {
        const slide = props.data?.[index];
        if (slide.isVideo) {
          var options = {
            autoplay: false,
            poster: slide?.poster,
            preload: 'auto',
            muted: true,
            controls: false,
            disablePictureInPicture: true,
          };

          const player = await videojs(`video-${slide.id}`, options);
          // false && console.log(player, 'check init player');

          let getPlayer = videojs.getPlayer(`video-${slide.id}`);

          if (!getPlayer) {
            false && console.log(`video-${slide.id} error`);
            return;
          }

          // false && console.log('check swiper ref', swiperRef.value?.$el?.swiper);

          getPlayer.on('ended', function () {
            //swiperRef.value?.$el?.swiper?.slideNext();
            this.pause();
            this.currentTime(0);
            this.play();
          });
        }
      }
    };

    return {
      modules,
      pagination,
      onSwiper,
      onSlideChange,
      getImageUrl,
      swiperRef,
      // renderMobileTabletBullet,
      screenWidth,
      checkLang,
    };
  },
});
</script>
<style>
.highlightsSwiper .swiper-pagination {
  display: flex;
  @apply tw-py-2 tw-px-4 sgDesktop:tw-py-10 sgDesktop:tw-px-20;
}
.highlightsSwiper .swiper-pagination-bullet {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  margin: 0;
  align-self: flex-end;
  transition: all 1s linear;
}

.highlightsSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  /* align-self: flex-start; */
}
.highlightsSwiper .swiper-pagination-bullet {
  opacity: 1;
}

.highlightsSwiper .swiper-pagination-bullet .bullet-container {
  transition: transform 0.3s linear;
  padding-bottom: 20px;
  transform: translateY(20px);
  opacity: 0.4;
}
.highlightsSwiper .swiper-pagination-bullet-active .bullet-container {
  padding-bottom: 0px;
  transform: translateY(0);
  opacity: 1;
}

.highlightsSwiper .swiper-pagination-bullet .catButton {
  display: none;
}

.highlightsSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active .catButton {
  @media screen and (min-width: 800px) {
    display: flex;
  }
}

.highlightsSwiper {
  height: 100%;
}

.highlightsSwiper .swiper-slide {
  height: 100%;
}

.video-js {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.video-js .vjs-tech {
  object-fit: cover;
}
</style>
