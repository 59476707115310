<template>
  <h1
    v-if="true"
    class="tw-text-4xl tw-bg-ap-dodger-blue"
  >
    This is a AP SLIDER
  </h1>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(() => {
  false && console.log('slider mounted');
});
</script>
