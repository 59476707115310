<template>
  <div
    class="headerCustom tw-z-10"
  >
    <div
      ref="inner"
      class="inner tw-z-[-1]"
    />
    <!-- Content -->
    <div
      class="tw-justify-between
      tw-pb-40
      tablet:tw-pb-0
      tablet:tw-pt-40
      desktop:tw-pb-0
      desktop:tw-pt-40"
    >
      <div
        class="tw-flex tw-w-full tw-h-full tw-flex-col tw-justify-start tw-px-4
      tablet:tw-px-10
      tablet:tw-flex-row
      desktop:tw-px-20
      desktop:tw-flex-row"
      >
        <!-- SCCC Clickable Column -->
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-pt-8 hover:tw-cursor-pointer  tablet:tw-w-[50%] desktop:tw-w-[50%]"
        >
          <!-- Row Zone 4, Zone 5 -->
          <div class="tw-flex tw-flex-row ">
            <!-- Zone 4 -->
            <div class="tw-relative tw-flex">
              <img
                class="tw-w-full tw-h-full"
                :src="$cdn('images/sccc/zone4-inactive.svg')"
                alt="zone 4"
              >
              <img
                :src="$cdn('images/sccc/zone4.svg')"
                alt="zone 4"
                class="tw-opacity-0 tw-absolute tw-top-0 tw-left-0"
                :class="{
                  '!tw-opacity-100': currentZoneIndex == 4
                }"
                @click="onZoneClicked(4)"
              >
            </div>
            <!-- Zone 5 -->
            <div class="tw-relative tw-flex">
              <img
                class="tw-w-full tw-h-full"
                :src="$cdn('images/sccc/zone5-inactive.svg')"
                alt="zone 5"
              >
              <img
                :src="$cdn('images/sccc/zone5.svg')"
                class="tw-opacity-0 tw-absolute tw-top-0 tw-left-0"
                :class="{
                  '!tw-opacity-100': currentZoneIndex == 5
                }"
                alt="zone 5"
                @click="onZoneClicked(5)"
              >
            </div>
          </div>
          <!-- Row Zone 3, Zone T, Zone -, Zone 1, Zone 2 -->
          <div class="tw-flex tw-flex-row">
            <!-- Column for Zone 3 -->
            <div class="tw-flex tw-flex-col">
              <div class="tw-relative tw-flex">
                <img
                  class="tw-w-full tw-h-full"
                  :src="$cdn('images/sccc/zone3-inactive.svg')"
                  alt="zone 3"
                >
                <img
                  :src="$cdn('images/sccc/zone3.svg')"
                  alt="zone 3"
                  class="tw-opacity-0 tw-absolute tw-top-0 tw-left-0"
                  :class="{
                    '!tw-opacity-100': currentZoneIndex == 3
                  }"
                  @click="onZoneClicked(3)"
                >
              </div>
            </div>
            <!-- Column for Zone T, Zone - -->
            <div class="tw-flex tw-flex-col">
              <!-- Zone T/6 -->
              <div class="tw-relative tw-flex">
                <img
                  class="tw-w-full tw-h-full"
                  :src="$cdn('images/sccc/zoneT-inactive.svg')"
                  alt="zone T"
                >
                <img
                  :src="$cdn('images/sccc/zoneT.svg')"
                  alt="zone T"
                  class="tw-opacity-0 tw-absolute tw-top-0 tw-left-0"
                  :class="{
                    '!tw-opacity-100': currentZoneIndex == 6
                  }"
                  @click="onZoneClicked(6)"
                >
              </div>
              <!-- Zone S -->
              <img
                :src="$cdn('images/sccc/zoneS-inactive.svg')"
                class="tw-relative"
                alt="zone -"
              >
            </div>
            <!-- Column for Zone 1, Zone 2 -->
            <div class="tw-flex tw-flex-col">
              <!-- Zone 1 -->
              <div class="tw-relative tw-flex">
                <img
                  class="tw-w-full tw-h-full"
                  :src="$cdn('images/sccc/zone1-inactive.svg')"
                  alt="zone 1"
                >
                <img
                  :src="$cdn('images/sccc/zone1.svg')"
                  alt="zone 1"
                  class="tw-opacity-0 tw-absolute tw-top-0 tw-left-0"
                  :class="{
                    '!tw-opacity-100': currentZoneIndex == 1
                  }"
                  @click="onZoneClicked(1)"
                >
              </div>
              <!-- Zone 2 -->
              <div class="tw-relative tw-flex">
                <img
                  class="tw-w-full tw-h-full"
                  :src="$cdn('images/sccc/zone2-inactive.svg')"
                  alt="zone 2"
                >
                <img
                  :src="$cdn('images/sccc/zone2.svg')"
                  alt="zone 2"
                  class="tw-opacity-0 tw-absolute tw-top-0 tw-left-0"
                  :class="{
                    '!tw-opacity-100': currentZoneIndex == 2
                  }"
                  @click="onZoneClicked(2)"
                >
              </div>
            </div>
          </div>
          <p
            class="tw-text-white tw-italic tw-text-sg-body-italic-mobile-2 tw-py-[28px]
          tablet:tw-text-sg-body-italic-tablet-2
          desktop:tw-text-sg-body-italic"
          >
            {{ $t('click_tiles') }}
          </p>
        </div>
        <div class="tw-px-5" />
        <!-- Text Column -->

        <div
          class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-pt-8
          tablet:tw-w-[50%] tablet:tw-pt-0
          desktop:tw-w-[50%] desktop:tw-pt-0 tw-relative tw-min-h-[150px] tablet:tw-h-auto"
          :style="{
            transition:'height 0.3s ease-in-out'
          }"
        >
          <div
            :class="{
              '!tw-opacity-0': currentZoneIndex !=0,

            }"
            class="tw-absolute tw-top-0 tablet:tw-top-auto tw-left-0 tw-opacity-100"
          >
            <p class="tw-text-white tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1">
              {{ $t('default_desc') }}
            </p>
          </div>
          <div
            v-for="(item,index) in zoneInfoList"
            :key="item.name"
            :class="{
              '!tw-opacity-0': currentZoneIndex != index+1,

            }"
            class="tw-absolute tw-top-0 tablet:tw-top-auto tw-left-0 tw-opacity-100"
            :style="{
              transition:'opacity 0.3s ease-in-out'
            }"
          >
            <p
              class="tw-text-white tw-text-sg-sh3-mobile
          tablet:tw-text-sg-sh3-tablet
          desktop:tw-text-sg-sh3"
            >
              {{ item.name }}
            </p>
            <p
              class="tw-text-white tw-text-sg-h2-mobile tw-py-2
          tablet:tw-text-sg-h2-tablet tablet:tw-py-5
          desktop:tw-text-sg-h2 desktop:tw-py-5"
            >
              {{ item.title }}
            </p>
            <p
              class="tw-text-white tw-text-sg-sh2-mobile
          tablet:tw-text-sg-sh2-tablet
          desktop:tw-text-sg-sh2"
              :class="{
                'tw-break-keep':checkLang()=='en'
              }"
              v-html="item.description"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
interface ZoneInfo {
  name: string,
  image: string,
  title: string,
  description: string,
}

export default {
  props: {
    imageZone1: {
      type: String,
      default: 'https://picsum.photos/1200?random=1'
    },
    imageZone2: {
      type: String,
      default: 'https://picsum.photos/1200?random=2'
    },
    imageZone3: {
      type: String,
      default: 'https://picsum.photos/1200?random=3'
    },
    imageZone4: {
      type: String,
      default: 'https://picsum.photos/1200?random=4'
    },
    imageZone5: {
      type: String,
      default: 'https://picsum.photos/1200?random=5'
    },
    imageZoneT: {
      type: String,
      default: 'https://picsum.photos/1200?random=6'
    },
    reinitGsap: {
      type: Function,
      default: null,
    }
  },
  setup(props) {
    const { t, locale } = useI18n();

    const { imageZone1, imageZone2, imageZone3, imageZone4, imageZone5, imageZoneT } = toRefs(props);
    const inner: Ref<HTMLElement | null> = ref(null);


    let currentZoneIndex:Ref<number> = ref(0);

    let zoneInfoList: ZoneInfo[] = [
      {
        name: t('zone_1_name'),
        image: imageZone1.value,
        title: t('zone_1_title'),
        description: t('zone_1_description')
      },
      {
        name: t('zone_2_name'),
        image: imageZone2.value,
        title: t('zone_2_title'),
        description: t('zone_2_description')
      },
      {
        name: t('zone_3_name'),
        image: imageZone3.value,
        title: t('zone_3_title'),
        description: t('zone_3_description')
      },
      {
        name: t('zone_4_name'),
        image: imageZone4.value,
        title: t('zone_4_title'),
        description: t('zone_4_description')
      },
      {
        name: t('zone_5_name'),
        image: imageZone5.value,
        title: t('zone_5_title'),
        description: t('zone_5_description')
      },
      {
        name: t('zone_t_name'),
        image: imageZoneT.value,
        title: t('zone_t_title'),
        description: t('zone_t_description')
      },
    ];

    const preloadImages = () => {
      zoneInfoList.forEach((zone) => {
        const img = new Image();
        img.src = zone.image;
      });
    };

    onMounted(() => {
      preloadImages();
    });
    function onZoneClicked(val: number) {
      if (inner.value) {

        setTimeout(() => {
          if (inner.value) {
            inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${zoneInfoList[val - 1].image})`;

          }
        }, 150);
      }
      switch (val) {
        case 1:
          currentZoneIndex.value = 1;
          break;
        case 2:
          currentZoneIndex.value = 2;
          break;
        case 3:
          currentZoneIndex.value = 3;
          break;
        case 4:
          currentZoneIndex.value = 4;
          break;
        case 5:
          currentZoneIndex.value = 5;
          break;
        case 6:
          currentZoneIndex.value = 6;
          break;
        default:
          break;
      }
      props.reinitGsap();
    }

    return {
      inner,
      onZoneClicked,
      currentZoneIndex,
      zoneInfoList,
      checkLang
    };
  }
};
</script>

<style scoped>
.headerCustom {
    position: relative;
    width: 100%;
    @apply tablet:tw-h-[100vh];
    /* overflow: hidden; */
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url(https://picsum.photos/1200?random=86); */
    background-size: cover;
    /* background-position: center center; */
    background-repeat: no-repeat;
    transition: background-image 0.3s linear;
}

p {
  text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
}


</style>
