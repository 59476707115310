<template>
  <div
    ref="snackBarBody"
    :class="['tw-fixed tw-top-11 tablet:tw-top-16 desktop:tw-top-16 tw-left-0 tw-z-50 tw-w-full tw-block tw-transition-all tw-duration-1000', snackBarStyle]"
  >
    <div class="tw-py-[12px] tw-px-[40px] tw-flex tw-items-center">
      <img
        v-show="snackBarIcon"
        :src="$cdn('icons/' + snackBarIcon)"
      >
      <p class="tw-text-white tw-text-sg-body-2 tw-pl-3">
        {{ snackBarText }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';

export default defineComponent({
  setup(props) {
    const snackBarText: Ref<string> = ref('');
    const snackBarStyle: Ref<string> = ref('');
    const snackBarIcon: Ref<string> = ref('');
    const snackBarTimeout: Ref<any> = ref('');
    const snackBarBody: Ref<HTMLElement | null> = ref(null);

    function getSnackbarStyle(obj: { text: string; colour: any; type: string }) {
      if (obj) {
        snackBarText.value = obj.text;
        snackBarStyle.value = 'tw-opacity-100';

        if (snackBarBody.value) {
          snackBarBody.value.style.backgroundColor = obj.colour;
        }

        if (obj.type == 'success') {
          snackBarIcon.value = 'check_circle.svg';
          document.getElementById('mc-embedded-subscribe')?.click();
        } else if (obj.type == 'error') snackBarIcon.value = 'error.svg';
      } else {
        snackBarStyle.value = 'tw-bg-transparent tw-opacity-0';
      }
      clearTimeout(snackBarTimeout.value);
      snackBarTimeout.value = setTimeout(() => {
        snackBarStyle.value = 'tw-bg-transparent tw-opacity-0';
      }, 4000);
    }
    return {
      getSnackbarStyle,
      snackBarText,
      snackBarStyle,
      snackBarIcon,
      snackBarBody,
    };
  },
});
</script>
<style scoped>
#snackBarBody {
  background-color: transparent;
}
</style>
