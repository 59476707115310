<template>
  <div>
    <ap-top-nav-bar
      :is-hidden="false"
      :is-transparent="true"
      :page-url="page_url"
    />
    <!-- Header -->
    <div class="headerCustom">
      <div
        ref="inner"
        class="inner"
      />
      <!-- LANGUAGE TEXT -->
      <div
        class="tw-absolute tw-flex tw-flex-col tw-justify-end tw-px-4 tw-pb-5 tw-inset-0 tw-max-h-[100vh] tw-max-w-[100vw]
        md:tw-px-10 md:tw-pr-0 md:tw-pb-0 md:tw-flex-row md:tw-justify-between
        lg:tw-pl-20"
      >
        <div class="tw-flex tw-flex-col tw-justify-end">
          <p
            class="tw-uppercase tw-text-white tw-text-sg-dp2-mobile
        md:tw-text-sg-dp2-tablet md:tw-pb-10
        lg:tw-text-sg-dp2"
          >
            {{ $t('language') }}
          </p>
        </div>
        <!--  <img
          class="landscape-hidden tw-hidden xl:tw-block"
          :src="$cdn('images/ren_vector.svg')"
          alt="ren vector"
        > -->
      </div>
    </div>
    <!-- Section 1 -->
    <ap-section-layout
      :section-text-one="topTextSection1"
      :section-text-two="bottomTextSection1"
      :section-image-url="imageSection1"
      slug-name="language"
      :is-first-image="true"
    />
    <!-- Section 2 - Static, wouldn't change -->
    <ap-section-layout
      :section-text-one="$t('language_kopi_peng_siudai')"
      :section-image-url="imageSection2"
      :is-first-image="false"
      :is-show-horizontal-text="true"
      :h-text1="$t('language_malay')"
      :h-sub-text1="$t('language_malay_desc')"
      :h-text2="$t('language_hokkien')"
      :h-sub-text2="$t('language_hokkien_desc')"
      :h-text3="$t('language_cantonese')"
      :h-sub-text3="$t('language_cantonese_desc')"
      slug-name="language"
      :is-second-image="true"
    />
    <!-- SubSection 1 -->
    <ap-section-layout-2
      :section-text="textSubsection1"
      :image-url="imageSubsection1"
      :is-image-on-left="false"
    />

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      v-if="!!imageSources"
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <!-- Books/Articles/Videos/Games Section -->
    <ap-swiper
      :swiper-type="'videos'"
      :videos-data="videosData"
      :description="$t('press_play_language')"
      :title="$t('swiper_title_videos')"
    />
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script>
import { computed, onMounted, toRefs, ref } from 'vue';
import { getScreenSize } from '../support/helper';
import { useI18n } from 'vue-i18n';
import { cdn } from '../support/cdn';

export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const inner = ref(null);
    const {t} = useI18n();
    const { wordpressData } = toRefs(props);
    const topTextSection1 = computed(() => wordpressData.value.top_text_section_1);
    const bottomTextSection1 = computed(() => wordpressData.value.bottom_text_section_1);
    const imageSection1 = computed(() => wordpressData.value.image_section_1);
    const topTextSection2 = computed(() => wordpressData.value.top_text_section_2);
    const bottomTextSection2 = computed(() => wordpressData.value.bottom_text_section_2);
    const imageSection2 = computed(() => wordpressData.value.image_section_2);
    const textSubsection1 = computed(() => wordpressData.value.text_subsection_1);
    const imageSubsection1 = computed(() => wordpressData.value.image_subsection_1);
    const imageSources = computed(() => wordpressData.value.image_sources);
    const page_url = computed(() => wordpressData.value.page_url);

    const videosData = computed(()=>[
      {
        id: 1,
        title: t('what_is_singlish'),
        description: '',
        video_url: 'https://www.youtube.com/watch?v=mlbw7Lj6vkg',
        image_url: cdn('videos_thumbnails/Language/What_is_Singlish.jpg'),
      },
    ]);

    onMounted(() => {
      if (wordpressData.value.header_image && inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url(${wordpressData.value.header_image})`;
      }
      //console.log('wordpressData', wordpressData.value);
      if (getScreenSize() == 'mobile') {
        inner.value.style.backgroundPosition = 'center center';
      }
    });

    return {
      inner,
      topTextSection1,
      bottomTextSection1,
      imageSection1,
      topTextSection2,
      bottomTextSection2,
      imageSection2,
      textSubsection1,
      imageSubsection1,
      page_url,
      videosData,
      imageSources
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100svh;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .landscape-hidden {
    display: none;
  }
}
.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=2); /*Default image*/
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
