<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { h, ref} from 'vue';
import { langRedirect } from '../../support/langRedirect';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');
const ctaButtonLink  = ref(langRedirect('/festivals/mid-autumn/mid-autumn-101', checkLang()));

const menuItems = [
  {
    label:'ma_origin_stories_menu_hou_yi_and_chang_e',
    id:'ma_origin_stories_menu_hou_yi_and_chang_e'
  },
  {
    label:'ma_origin_stories_menu_rabbit_on_the_moon',
    id:'ma_origin_stories_menu_rabbit_on_the_moon'
  },
  {
    label:'ma_origin_stories_menu_wu_gang_the_wood_cutter',
    id:'ma_origin_stories_menu_wu_gang_the_wood_cutter'
  },
  {
    label:'ma_origin_stories_menu_overthrowing_the_mongols',
    id:'ma_origin_stories_menu_overthrowing_the_mongols'
  },

];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

const sliderData = ref<any[]>([]);


onBeforeMount(()=>{
  if(props.wordpressData?.image_sources?.length > 0) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

const section2TextRef = ref();
const section2ImgRef = ref();
onMounted(() => {
  recalculateHeight();
  window.addEventListener('resize', recalculateHeight);
  sliderData.value = [
    {
      id: 'ma_origin_stories_menu_hou_yi_and_chang_e',
      image: cdn('images/ma2024/origin-stories/ma_origin_stories_01.jpg'),
      title: t('ma_origin_stories_subtitle_1'),
      description: t('ma_origin_stories_description_1'),
      tilte_angle: '-4deg',
      text_padding_style: 'sgDesktop:tw-py-10 sgDesktop:tw-pl-10',
    },
    {
      id: 'ma_origin_stories_menu_rabbit_on_the_moon',
      image: cdn('images/ma2024/origin-stories/ma_origin_stories_02.jpg'),
      title: t('ma_origin_stories_subtitle_2'),
      description: t('ma_origin_stories_description_2'),
      tilte_angle: '4deg',
    },
    {
      id: 'ma_origin_stories_menu_wu_gang_the_wood_cutter',
      image: cdn('images/ma2024/origin-stories/ma_origin_stories_03.jpg'),
      title: t('ma_origin_stories_subtitle_3'),
      description: t('ma_origin_stories_description_3'),
      tilte_angle: '-4deg',
    },
    {
      id: 'ma_origin_stories_menu_overthrowing_the_mongols',
      image: cdn('images/ma2024/origin-stories/ma_origin_stories_04.jpg'),
      title: t('ma_origin_stories_subtitle_4'),
      description: t('ma_origin_stories_description_4'),
      tilte_angle: '4deg',
    }
  ];
});
onBeforeUnmount(()=>{
  window.removeEventListener('resize', recalculateHeight);
});

function recalculateHeight(){
  requestAnimationFrame(()=>{
    if(section2TextRef.value){
      let height = section2TextRef.value.getBoundingClientRect().height;
      height = height > 300 ? height : 300;
      section2ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }
  });
}
</script>

<template>
  <div
    :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ma-navigation="true"
      :ma2-navigation="wordpressData?.canView?.includes('sidebar.ma2')"
    />
    <ma-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- header -->
    <section class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/ma2024/ma_sub_banner.jpg')"
          :class="[
            'tw-w-full',
            'tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]',
            'tw-object-cover'
          ]"
        >
      </picture>
      <div class="tw-bg-black/20 tw-absolute tw-w-full tw-h-full tw-top-0" />
      <div class="tw-absolute tw-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-ma-mobile-h1 sgTablet:tw-text-sg-ma-tablet-h1 sgDesktop:tw-text-sg-ma-desktop-h1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_origin_stories_title') }}
          </div>
        </div>
      </div>
    </section>

    <!-- description -->
    <section
      :class="[
        'tw-pt-5 sgTablet:tw-pt-10',
        'tw-pb-5',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'tw-bg-[#FAE4EE]'
      ]"
    >
      <div
        id="sup-handler-container"
        :class="[
          'tw-tracking-wider',
          'tw-max-w-[800px] tw-text-center',
          'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
        ]"
      >
        {{ $t('ma_origin_stories_description') }}
      </div>
    </section>

    <section
      :class="[
        'tw-relative',
        'tw-pt-5 sgTablet:tw-pt-10',
        'tw-pb-5 sgDesktop:tw-pb-[117.3px] sgTablet:tw-pb-[72px]',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'tw-gap-[60px]',
        'tw-bg-[#FAE4EE]'
      ]"
    >
      <div
        v-for="(item, index) in sliderData"
        :id="item.id"
        :key="item.id"
        :class="[
          'tw-flex tw-flex-col sgTablet2:tw-flex-row tw-items-center tw-justify-center',
          'tw-max-w-[800px]',
          'tw-gap-5',
          'sgTablet2:tw-px-10 sgDesktop:tw-px-0 tw-px-0',
          { 'sgTablet2:tw-flex-row-reverse': index % 2 !== 0 }
        ]"
      >
        <!-- image -->
        <div
          :class="[
            'sgTablet2:tw-w-[400px] tw-w-auto',
            'sgTablet2:tw-h-auto sgTablet:tw-h-[300px] tw-h-full',
          ]"
        >
          <img
            :class="[
              'sgTablet:tw-w-[40vw] tw-w-[85vw] tw-h-full',
              'tw-mb-4 sgTablet:tw-mb-0',
            ]"
            :style="`transform: rotate(${ item.tilte_angle });`"
            :src="item.image"
            :alt="item.title"
          >
        </div>

        <div
          :class="[
            'tw-flex tw-flex-col',
            'tw-py-5 sgTablet2:tw-py-0',
            'sgTablet2:tw-w-[400px] tw-w-full',
            'tw-gap-5',
            { 'sgTablet2:tw-pl-10': index % 2 === 0, 'sgTablet2:tw-pr-10': index % 2 !== 0 }
          ]"
        >
          <!-- title -->
          <div
            :class="[
              'tw-text-center',
              'tw-text-sg-ma-mobile-h3 sgTablet:tw-text-sg-ma-tablet-h3 desktop:tw-text-sg-ma-desktop-h3',
              'tw-text-sg-ma24-purple-b40',
              { 'sgTablet2:tw-text-left': index % 2 === 0, 'sgTablet2:tw-text-right': index % 2 !== 0 },
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
            v-html="item.title"
          />
          <!-- description -->
          <div
            :class="[
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1',
              { 'sgTablet2:tw-text-left': index % 2 === 0, 'sgTablet2:tw-text-right': index % 2 !== 0 }
            ]"
          >
            {{ item.description }}
          </div>
        </div>

      </div>
      <div class="tw-absolute tw-bottom-0 tw-w-full">
        <ma-wave-break-line image="images/ma2024/ma-cta-line-break.svg" />
      </div>
    </section>


    <ma-bottom-cta-section
      :title="$t('ma_origin_stories_read_more')"
      :cta-button="$t('ma_origin_stories_discover_more')"
      :cta-link="ctaButtonLink"
      :image="$cdn('images/ma2024/origin-stories/ma_origin_stories_cta.png')"
      description-style="tw-text-sg-ma-sh1"
    />
    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />
    <ap-footer />
  </div>
</template>

