<template>
  <div class="tw-flex tw-flex-col tw-content-center tw-align-middle tw-justify-center tw-flex-wrap">
    <div
      class="tw-aspect-video tw-rounded-lg tw-h-max tw-w-[90vw] tablet:tw-h-[340px] tablet:tw-w-[600px] sgTablet2:tw-h-max sgTablet2:tw-w-[90vw] sgDesktop:tw-h-[538px] sgDesktop:tw-w-[955px]"
    >
      <video
        :id="videoId"
        class="videoPlayer video-js tw-p-3 tablet:tw-p-4 desktop:tw-p-8"
        true
        fluid
        fill
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import videojs from 'video.js';
import 'videojs-youtube';
import 'video.js/dist/video-js.css';
import { onMounted, ref, Ref, onBeforeMount } from 'vue';
import { cdn } from '../../../support/cdn';

const videoId: Ref<any> = ref();

const props = defineProps({
  videoUrl: {
    type: String,
    nullable: true,
    default: null,
  },
  posterUrl: {
    type: String,
    nullable: true,
    default: null,
  },
});

onBeforeMount(() => {
  generateRandomId();
});

onMounted(() => {
  if (props.videoUrl !== '') {
    initVideoPlayer();
  }
});

function generateRandomId() {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  videoId.value = result;
}

async function initVideoPlayer() {
  var options = {
    autoplay: false,
    preload: true,
    controls: true,
    techOrder: ['youtube'],
    sources: [
      {
        type: 'video/youtube',
        src: props.videoUrl,
      },
    ],
    poster: props.posterUrl,
  };

  const player = videojs(videoId.value, options);
  // false && console.log(player, 'check init player');

  let getPlayer = videojs.getPlayer(videoId.value);


  const videoElem = document.getElementById(videoId.value);
  videoElem?.classList.add('about');

  // false && console.log('getPlayer', getPlayer);
  if (!getPlayer) {
    false && console.log('videoPlayer error');
    return;
  }
}
</script>

  <style>
  .videoPlayer {
    height: 100% !important;
  }

  .videoPlayer .vjs-poster {
    background-size: cover !important;
    overflow: hidden;
  }

  .videoPlayer .vjs-poster img {
    object-fit: cover !important;
  }

  .videoPlayer.about , .video-js.about .vjs-tech,
  .videoPlayer.about .vjs-poster
  {
    border-radius: 8px !important;
  }

  .videoPlayer .vjs-big-play-button {
    top: 45% !important;
    left: 50% !important;
    border-radius: 100% !important;
    border: none !important;
    height: 120px !important;
    width: 120px !important;
  }

  @media (max-width:500px){
    .videoPlayer .vjs-big-play-button {
      top: 30% !important;
      left: 45% !important;
    }
  }

  .videoPlayer.about .vjs-big-play-button {
    background-color: white !important;
    color: #484848!important;

  }

  .videoPlayer .vjs-big-play-button .vjs-icon-placeholder:before {
    font-size: 72px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .videoPlayer .vjs-big-play-button {
    display: flex !important;
  }

  .videoPlayer.vjs-has-started .vjs-big-play-button {
    display: none !important;
   }
  </style>
