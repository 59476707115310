<template>
  <div
    id="greetings_dialect"
    class="tw-px-4 tw-py-8 sgTablet:tw-p-10 sgDesktop:tw-px-20 sgDesktop:tw-py-10 tw-flex tw-flex-col tw-justify-center"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
  >
    <div
      class="tw-text-sg-cny24-red tw-text-sg-h3-mobile-cny-title tablet:tw-text-sg-h3-tablet-cny-title desktop:tw-text-sg-h3-cny-title"
      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
    >
      <h3 class="tw-text-center">
        {{ $t('cny_greetings_dialects') }}
      </h3>
    </div>
    <!-- Dialects bar -->
    <div class="tw-my-[32px] sgTablet:tw-my-[40px] sgDesktop:tw-my-[60px] tw-self-center tw-flex tw-flex-row tw-relative">
      <div class="scroll-arrow tw-left-0 tw-bg-[#EDF5F9] tw-flex tw-flex-col tw-justify-center">
        <button
          class="tw-rounded-full tw-justify-center tw-pr-[-20rem] tw-z-30 tw-items-center tw-h-[40px] tw-min-w-[40px] tw-ml-4 tw-mr-3 tw-text-white tw-bg-[#C52600] hover:tw-bg-sg-cny24-red-w20 tw-text-[28px] tw-hidden tw-cursor-pointer"
          :class="{ '!tw-flex tablet:!tw-hidden': tabsScrollPosition > 0 && !hideArrow }"
          @click="scrollTabs('left')"
        >
          &lt;
        </button>
      </div>
      <div
        ref="tabsContainer"
        class="scrollbar-hide tw-max-w-[343px] tablet:tw-max-w-[420px] sgTablet:tw-max-w-[600px] sgTablet2:tw-max-w-[800px] tw-flex tw-flex-row tw-overflow-x-auto"
      >
        <div class="sgDesktop:tw-gap-x-5 sgTablet:tw-justify-center tw-flex tw-gap-x-4">
          <div
            v-for="item in dialects_filter_list"
            :id="item.key"
            :key="item.key"
            class="tw-rounded-lg tw-w-36 tw-h-11 tw-text-center !tw-bg-[#DCEBF3] tw-text-sm tw-text-sg-cny24-red tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-cursor-pointer"
            :class="{
              '!tw-bg-ap-white tw-border-2 tw-text-sg-cny24-red tw-border-sg-cny24-red': item.key == dialects_selected,
              'hover:tw-border-2 hover:tw-border-sg-cny24-red': item.key != dialects_selected,
              'tw-font-notosansbold': checkLang() == 'zh-hans',
              'tw-font-akkuratbold': checkLang() == 'en',
            }"
            @touchstart="hideArrow = true"
            @touchend="
              hideArrow = false;
              updateScrollPosition();
            "
            @click="dialects_selected = item.key"
          >
            <span> {{ item.label }}</span>
          </div>
        </div>
      </div>
      <div class="scroll-arrow tw-right-0 tw-bg-[#EDF5F9] tw-flex tw-flex-col tw-justify-center">
        <button
          class="tw-rounded-full tw-justify-center tw-pr-[-20rem] tw-z-30 tw-items-center tw-h-[40px] tw-min-w-[40px] tw-ml-3 tw-mr-4 tw-text-white tw-bg-[#C52600] hover:tw-bg-sg-cny24-red-w20 tw-text-[28px] tw-flex tw-cursor-pointer"
          :class="{ '!tw-hidden tablet:!tw-hidden': isReachEnd || hideArrow }"
          @click="scrollTabs('right')"
        >
          &gt;
        </button>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-self-center tw-max-w-[1024px]">
      <button
        :disabled="disabledPrev"
        class="tw-hidden sgTablet:tw-block tw-rounded-full tw-h-[48px] tw-min-w-[48px] hover:tw-bg-sg-cny24-red-w20 tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[30px] tw-pb-2 tw-pr-1 tw-self-center disabled:tw-bg-sg-cny24-red-w60"
        @click="slidePrev()"
      >
        &lt;
      </button>
      <swiper
        id="dialectSwiper"
        slides-per-view="auto"
        class="tw-max-w-[343px] sgTablet:tw-max-w-[448px] sgTablet2:tw-max-w-[793px] sgDesktop:tw-max-w-[720px] sgDesktop2:tw-max-w-[808px]"
        @swiper="onSwiper"
        @slide-change="onSlideChange"
      >
        <swiper-slide v-for="(item, index) in dialects_filter_list" :key="index">
          <div class="tw-flex tw-flex-row tw-flex-wrap tw-px-0 sgTablet:tw-px-5 sgDesktop:xtw-px-10 tw-gap-6 sgTablet:tw-gap-5 tw-justify-center tw-pb-[10px]">
            <div
              v-for="(block, blockIndex) in item.data"
              :key="`block_${blockIndex}`"
              class="tw-w-[120px] sgTablet:tw-w-[173px] sgDesktop:tw-w-[200px] tw-bg-ap-white red-greetings-shadow tw-border-2 tw-border-sg-cny24-red tw-px-4 tw-py-5"
            >
              <!-- tw-h-[424px] sgTablet:tw-h-[412px] sgDesktop:tw-h-[436px] -->
              <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
                <div class="tw-text-sg-cny24-red tw-text-center tw-flex tw-flex-col tw-justify-center">
                  <h3
                    class="tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1 tw-mt-4 sgTablet:tw-mt-5 tw-max-w-[110px] tablet:tw-max-w-none sgTablet:tw-max-w-[140px] tw-self-center"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    {{ block.title }}
                  </h3>
                  <p
                    class="tw-text-sg-sh2-mobile-cny sgTablet:tw-text-sg-sh2-tablet-cny desktop:tw-text-sg-sh2-cny tw-mt-4 sgTablet:tw-mt-5 tw-h-[112]px sgTablet:tw-h-[56px]"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    {{ block.pinyin }}
                  </p>
                  <p
                    class="tw-text-sg-body-mobile sgTablet:tw-text-sg-body-tablet desktop:tw-text-sg-body tw-mt-4 sgTablet:tw-mt-5 tw-h-[120px] sgTablet:tw-h-[96px]"
                  >
                    {{ block.description }}
                  </p>
                </div>

                <div
                  class="tw-my-[24px] tw-rounded-full tw-border-2 tw-w-[40px] tw-h-[40px] tw-min-w-[40px] tw-min-h-[40px] tw-items-center tw-border-sg-cny24-red tw-self-center tw-bg-ap-white hover:tw-bg-sg-cny24-red-w60 tw-flex tw-justify-center tw-flex-row active:tw-bg-sg-cny24-red-w40 tw-cursor-pointer"
                >
                  <inline-svg :src="$cdn('images/cny2024/icon/volume_up.svg')" @click.prevent="onSpeakerClick(blockIndex)" />
                </div>
              </div>
              <div />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <button
        class="tw-hidden sgTablet:tw-block tw-rounded-full tw-h-[48px] tw-min-w-[48px] tw-mx-5 tw-text-white tw-bg-[#C52600] hover:tw-bg-sg-cny24-red-w20 tw-text-[30px] tw-pb-2 tw-pl-1 tw-self-center disabled:tw-bg-sg-cny24-red-w60"
        :disabled="disabledNext"
        @click="slideNext()"
      >
        >
      </button>
    </div>

    <div class="tw-flex sgTablet:tw-hidden tw-pt-8 sgTablet:tw-py-10 tw-max-w-[275px] tw-justify-between tw-self-center">
      <button
        :disabled="disabledPrev"
        class="tw-rounded-full tw-h-[40px] tw-w-[40px] tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[26px] tw-pb-2 tw-pr-1 disabled:tw-bg-sg-cny24-red-w60"
        @click="slidePrev()"
      >
        &lt;
      </button>
      <div class="tw-items-center tw-flex">
        <p class="tw-text-sg-cny24-red tw-text-sg-caption">
          {{ `${realIndex}&nbsp;/&nbsp;${swiperLength}` }}
        </p>
      </div>
      <button
        :disabled="disabledNext"
        class="tw-rounded-full tw-h-[40px] tw-w-[40px] tw-mx-5 tw-text-white tw-bg-[#C52600] tw-text-[26px] tw-pb-2 tw-pl-1 disabled:tw-bg-sg-cny24-red-w60"
        @click="slideNext()"
      >
        >
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, Ref, nextTick, watch } from 'vue';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { useI18n } from 'vue-i18n';
import { cdn } from '../../../support/cdn';
import { checkLang } from '../../../support/helper';

const { t } = useI18n();
const dialects_selected = ref('hokkien');

const hideArrow = ref(false);
const tabsContainer: Ref<any> = ref(null);
const tabsScrollPosition: Ref<number> = ref(0);

let disabledNext: Ref<boolean> = ref(false);
let disabledPrev: Ref<boolean> = ref(true);

const dialects_filter_list = computed(() => [
  {
    key: 'hokkien',
    label: t('cny_greetings_hokkien'),
    data: [
      {
        title: '新年快乐',
        pinyin: 'sin ni kuai lok',
        description: t('cny_greetings_dialect_desc1'),
        sound_url: 'sound/cny/greetings/audioHokkien1.mp3',
      },
      {
        title: '恭喜发财',
        pinyin: 'gheong hee huat cai',
        description: t('cny_greetings_dialect_desc2'),
        sound_url: 'sound/cny/greetings/audioHokkien2.mp3',
      },
      {
        title: '心想事成',
        pinyin: 'sim siu su xing',
        description: t('cny_greetings_dialect_desc3'),
        sound_url: 'sound/cny/greetings/audioHokkien3.mp3',
      },
      {
        title: '身体健康',
        pinyin: 'sin teh kian hong',
        description: t('cny_greetings_dialect_desc4'),
        sound_url: 'sound/cny/greetings/audioHokkien4.mp3',
      },
      {
        title: '大吉大利',
        pinyin: 'dai kiat dai li',
        description: t('cny_greetings_dialect_desc5'),
        sound_url: 'sound/cny/greetings/audioHokkien5.mp3',
      },
      {
        title: '万事如意',
        pinyin: 'ban su ru yi',
        description: t('cny_greetings_dialect_desc6_1'),
        sound_url: 'sound/cny/greetings/audioHokkien6.mp3',
      },
    ],
  },
  {
    key: 'teochew',
    label: t('cny_greetings_teochew'),
    data: [
      {
        title: '新年快乐',
        pinyin: 'sing jia ju yi',
        description: t('cny_greetings_dialect_desc1'),
        sound_url: 'sound/cny/greetings/audioTeochew1.mp3',
      },
      {
        title: '恭喜发财',
        pinyin: 'giong hi huag cai',
        description: t('cny_greetings_dialect_desc2'),
        sound_url: 'sound/cny/greetings/audioTeochew2.mp3',
      },
      {
        title: '心想事成',
        pinyin: 'sing xio si seng',
        description: t('cny_greetings_dialect_desc3'),
        sound_url: 'sound/cny/greetings/audioTeochew3.mp3',
      },
      {
        title: '身体健康',
        pinyin: 'sing ti gieng kang',
        description: t('cny_greetings_dialect_desc4'),
        sound_url: 'sound/cny/greetings/audioTeochew4.mp3',
      },
      {
        title: '大吉大利',
        pinyin: 'dai gig dai li',
        description: t('cny_greetings_dialect_desc5'),
        sound_url: 'sound/cny/greetings/audioTeochew5.mp3',
      },
      {
        title: '万事如意',
        pinyin: 'bhuang se ru yi',
        description: t('cny_greetings_dialect_desc6'),
        sound_url: 'sound/cny/greetings/audioTeochew6.mp3',
      },
    ],
  },
  {
    key: 'cantonese',
    label: t('cny_greetings_cantonese'),
    data: [
      {
        title: '新年快乐',
        pinyin: 'san nin fai lok',
        description: t('cny_greetings_dialect_desc1'),
        sound_url: 'sound/cny/greetings/audioCantonese1.mp3',
      },
      {
        title: '恭喜发财',
        pinyin: 'gong hei fatt choy',
        description: t('cny_greetings_dialect_desc2'),
        sound_url: 'sound/cny/greetings/audioCantonese2.mp3',
      },
      {
        title: '心想事成',
        pinyin: 'sum siong see seng',
        description: t('cny_greetings_dialect_desc3'),
        sound_url: 'sound/cny/greetings/audioCantonese3.mp3',
      },
      {
        title: '身体健康',
        pinyin: 'san tai gin hong',
        description: t('cny_greetings_dialect_desc4'),
        sound_url: 'sound/cny/greetings/audioCantonese4.mp3',
      },
      {
        title: '大吉大利',
        pinyin: 'dai khat dai lei',
        description: t('cny_greetings_dialect_desc5_123'),
        sound_url: 'sound/cny/greetings/audioCantonese5.mp3',
      },
      {
        title: '万事如意',
        pinyin: 'man si yu yee',
        description: t('cny_greetings_dialect_desc6'),
        sound_url: 'sound/cny/greetings/audioCantonese6.mp3',
      },
    ],
  },
  {
    key: 'hainanese',
    label: t('cny_greetings_hainanese'),
    data: [
      {
        title: '新年快乐',
        pinyin: 'dian ni kuai lok',
        description: t('cny_greetings_dialect_desc1'),
        sound_url: 'sound/cny/greetings/audioHainanse1.mp3',
      },
      {
        title: '恭喜发财',
        pinyin: 'kong yee wat sai',
        description: t('cny_greetings_dialect_desc2'),
        sound_url: 'sound/cny/greetings/audioHainanse2.mp3',
      },
      {
        title: '心想事成',
        pinyin: 'dim tio xie jia',
        description: t('cny_greetings_dialect_desc3'),
        sound_url: 'sound/cny/greetings/audioHainanse3.mp3',
      },
      {
        title: '身体健康',
        pinyin: 'din hi gin hang',
        description: t('cny_greetings_dialect_desc4'),
        sound_url: 'sound/cny/greetings/audioHainanse4.mp3',
      },
      {
        title: '大吉大利',
        pinyin: 'dai kiat dai li',
        description: t('cny_greetings_dialect_desc5_123'),
        sound_url: 'sound/cny/greetings/audioHainanse5.mp3',
      },
      {
        title: '万事如意',
        pinyin: 'ban sey lu yi',
        description: t('cny_greetings_dialect_desc6'),
        sound_url: 'sound/cny/greetings/audioHainanse6.mp3',
      },
    ],
  },
  {
    key: 'hakka',
    label: t('cny_greetings_hakka'),
    data: [
      {
        title: '新年快乐',
        pinyin: 'si nian kuai lok',
        description: t('cny_greetings_dialect_desc1'),
        sound_url: 'sound/cny/greetings/audioHakka1.mp3',
      },
      {
        title: '恭喜发财',
        pinyin: 'giong hi fatt choy',
        description: t('cny_greetings_dialect_desc2'),
        sound_url: 'sound/cny/greetings/audioHakka2.mp3',
      },
      {
        title: '心想事成',
        pinyin: 'sim xiong si sin',
        description: t('cny_greetings_dialect_desc3'),
        sound_url: 'sound/cny/greetings/audioHakka6.mp3',
      },
      {
        title: '身体健康',
        pinyin: 'xin ti gin hong',
        description: t('cny_greetings_dialect_desc4'),
        sound_url: 'sound/cny/greetings/audioHakka4.mp3',
      },
      {
        title: '大吉大利',
        pinyin: 'tai kit tai li',
        description: t('cny_greetings_dialect_desc5_123'),
        sound_url: 'sound/cny/greetings/audioHakka5.mp3',
      },
      {
        title: '万事如意',
        pinyin: 'wan shi yi yi',
        description: t('cny_greetings_dialect_desc6'),
        sound_url: 'sound/cny/greetings/audioHakka3.mp3',
      },
    ],
  },
]);

let swiperElm = ref({});

onMounted(async () => {
  // console.log('swiper swipee', swiperElm.value);

  //const getTabsContainer = document.querySelector('.greetings-dialect-tab-container');
  //tabsContainer.value = getTabsContainer;
  await nextTick();

  // swiperElm.value.on('slideChange', () => {
  //   const activeSlide = swiperElm.value.slides[swiperElm.value.activeIndex];
  //   dialects_selected.value = activeSlide.$el.dataset.key;
  //   console.log('active')
  // });
});

watch(
  () => dialects_selected.value,
  () => {
    const selectedDialectIndex = dialects_filter_list.value.findIndex((item) => item.key === dialects_selected.value);
    if (selectedDialectIndex !== -1) {
      swiperElm.value.slideTo(selectedDialectIndex);
    }
  }
);

const audioPlayer = new Audio();

function onSwiper(swiper) {
  //   console.log(swiper);
  swiperElm.value = swiper;
}

const onSlideChanged = (swiper) => {
  const swiperCurr = swiperElm.value;
  disabledPrev.value = false;
  disabledNext.value = false;

  if (swiperCurr.isBeginning) {
    disabledPrev.value = true;
  }
  if (swiperCurr.isEnd) {
    disabledNext.value = true;
  }
};

function onTouchEnd(swiper) {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    let activeIndex = swiper.realIndex;
    swiper.slidePrev(0, false);
    swiper.slidePrev(0, false);
    swiper.slideToLoop(activeIndex, 0, false);
    swiper.slideNext(0, false);
    swiper.slideNext(0, false);
    swiper.slideToLoop(activeIndex, 0, false);
  }, 350);
}

function slideNext() {
  swiperElm.value.slideNext();
}

function slidePrev() {
  swiperElm.value.slidePrev();
}

const onSlideChange = (swiper) => {
  const selectedDialect = dialects_filter_list.value?.[swiperElm.value.realIndex]?.key;
  dialects_selected.value = selectedDialect;
  onSlideChanged(swiper);

  let tab = document.getElementById(selectedDialect)!;
  tabsContainer.value?.scrollTo({ behavior: 'smooth', left: tab.offsetLeft });
};

const realIndex = computed(() => {
  return swiperElm.value.realIndex + 1;
});

const swiperLength = computed(() => {
  return swiperElm.value?.slides?.length ?? 0;
});

const isReachEnd = computed(() => {
  let offsetWidth = 0;
  let scrollWidth = 0;
  if (!!tabsContainer.value) {
    offsetWidth = tabsContainer.value?.offsetWidth;
    scrollWidth = tabsContainer.value?.scrollWidth;
  }
  return tabsScrollPosition.value + offsetWidth >= scrollWidth;
});

const scrollTabs = (direction: string) => {
  const container = tabsContainer.value;
  const containerWidth = tabsContainer.value.offsetWidth;
  const scrollWidth = tabsContainer.value.scrollWidth;

  const scrollAmount = direction === 'left' ? -containerWidth : containerWidth;
  // console.log('check scroll amount', scrollAmount, scrollWidth, containerWidth);
  container.scrollLeft += scrollAmount;
  updateScrollPosition();
};

const updateScrollPosition = () => {
  if (tabsContainer.value) {
    tabsScrollPosition.value = tabsContainer.value.scrollLeft;
  }
};

const onSpeakerClick = (index: number) => {
  audioPlayer.pause();
  const soundUrl = dialects_filter_list.value.find((x) => x?.key === dialects_selected?.value)?.data?.[index]?.sound_url;
  if (!!soundUrl) {
    audioPlayer.src = cdn(soundUrl);
    audioPlayer.play();
  }
};
</script>

<style scoped>
div#dialectSwiper .swiper-slide {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.red-greetings-shadow {
  box-shadow: 12px 12px 0px 0px #c52600;
  border-radius: 8px;
}
.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  height: 70px;
}
.scroll-arrow.left {
  left: 0;
}

.scroll-arrow.right {
  right: 0;
}

.greetings-dialect-tab-container::-webkit-scrollbar {
  width: 0 !important; /* WebKit */
}

.greetings-dialect-tab-container::-webkit-scrollbar-thumb {
  background-color: transparent !important; /* WebKit */
}

.greetings-dialect-tab-container::-webkit-scrollbar-track {
  background-color: transparent !important; /* WebKit */
}
</style>
