<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="true"
      :is-hidden="false"
    />

    <!-- Header -->
    <div class="headerCustom">
      <div
        ref="inner"
        class="inner"
      />
      <!-- FESTIVALS TEXT -->
      <div
        class="tw-absolute tw-flex tw-flex-col tw-justify-end tw-px-4 tw-pb-5 tw-inset-0 tw-max-h-[100vh] tw-max-w-[100vw]
        md:tw-px-10 md:tw-pr-0 md:tw-pb-0 md:tw-flex-row md:tw-justify-between
        lg:tw-pl-20"
      >
        <div class="tw-flex tw-flex-col tw-justify-end">
          <p
            class="tw-uppercase tw-text-white tw-text-sg-dp2-mobile
        md:tw-text-sg-dp2-tablet md:tw-pb-10
        lg:tw-text-sg-dp2"
          >
            {{ $t('festivals') }}
          </p>
        </div>
        <!-- <img
          class="landscape-hidden tw-hidden xl:tw-block"
          :src="$cdn('images/ren_vector.svg')"
          alt="ren vector"
        > -->
      </div>
    </div>

    <!-- Content -->
    <div class="tw-flex tw-flex-col tw-items-center tw-w-full">
      <div class=" tw-max-w-[800px] tw-px-4 tw-py-5 tablet:tw-p-10 desktop:tw-px-20 desktop:tw-py-10">
        <!-- <p class="tw-text-center tw-pb-5 tablet:tw-pb-10 desktop:tw-pb-10 tw-text-sg-h3-mobile tablet:tw-text-sg-h3-tablet desktop:tw-text-sg-h3">
          {{ $t('festivals_content') }}
        </p> -->
        <div
          class=" tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 prose-h3:tw-text-sg-h3-mobile tablet:prose-h3:tw-text-sg-h3-tablet desktop:prose-h3:tw-text-sg-h3"
          v-html="wordpressData.description"
        />
      </div>
    </div>

    <!-- Festivals Section -->
    <div
      v-for="(festival, index) in festivalsData"
      :key="index"
    >
      <ap-festivals-container :festivals-data="festivalsData[index]" />
    </div>

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      v-if="!!imageSources"
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <ap-swiper
      :swiper-type="'festivals'"
      :festivals-data="festivalsItemsData"
      description=""
      title="FESTIVALS 2023"
    />
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script lang="ts">
import { onMounted, toRefs, ref, Ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../support/helper';

interface Festivals {
  title: string;
  description: string;
  image: string;
  url: string;
  isLocked: boolean;
}

export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const inner: Ref<HTMLElement | null> = ref(null);
    const {t} = useI18n();
    const { wordpressData } = toRefs(props);
    const festivals: Ref<Festivals[]> = computed(() => wordpressData.value.festivals);
    const festivalsData: Festivals[] = festivals.value;
    const imageSources = computed(() => wordpressData.value.image_sources);

    onMounted(() => {
      if (wordpressData.value.header_image && inner.value) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${wordpressData.value.header_image})`;
      }
    });

    const festivalsDescription = computed(()=>t('festivals_descriptions'));

    // TODO: Edit this accordingly
    const festivalsItemsData = computed(()=>[
      {
        id: 1,
        title: t('festivals_chinese_new_year'),
        external_url: checkLang() == 'en'
          ? 'https://festivefever.singaporeccc.org.sg/chinese-new-year/archival/2023'
          : 'https://festivefever.singaporeccc.org.sg/chinese-new-year/archival/2023/zh-hans/',
        // image_url: cdn('exhibition_sheets/exhibition_eng.png'),
      },
      {
        id: 2,
        title: t('festivals_qing_ming'),
        external_url: checkLang() == 'en'
          ? 'https://festivefever.singaporeccc.org.sg/qingming/archival/2023'
          : 'https://festivefever.singaporeccc.org.sg/qingming/archival/2023/zh-hans/',
        // image_url: cdn('exhibition_sheets/exhibition_chinese.png'),
      },
      {
        id: 3,
        title: t('festivals_duanwu'),
        external_url: checkLang() == 'en'
          ? 'https://festivefever.singaporeccc.org.sg/duanwu/archival/2023'
          : 'https://festivefever.singaporeccc.org.sg/duanwu/archival/2023/zh-hans/',
        // image_url: cdn('exhibition_sheets/exhibition_tamil.png'),
      },
      {
        id: 4,
        title: t('festivals_zhong_yuan'),
        external_url: checkLang() == 'en'
          ? 'https://festivefever.singaporeccc.org.sg/zhongyuan/archival/2023'
          : 'https://festivefever.singaporeccc.org.sg/zhongyuan/archival/2023/zh-hans/',
        // image_url: cdn('exhibition_sheets/exhibition_malay.png'),
      },
      {
        id: 5,
        title: t('festivals_mid_autumn'),
        external_url: checkLang() == 'en'
          ? 'https://festivefever.singaporeccc.org.sg/mid-autumn/archival/2023'
          : 'https://festivefever.singaporeccc.org.sg/mid-autumn/archival/2023/zh-hans/',
        // image_url: cdn('exhibition_sheets/exhibition_malay.png'),
      },
      {
        id: 6,
        title: t('festivals_winter_solstice'),
        external_url: checkLang() == 'en'
          ? 'https://festivefever.singaporeccc.org.sg/winter-solstice/archival/2023'
          : 'https://festivefever.singaporeccc.org.sg/winter-solstice/archival/2023/zh-hans/',
        // image_url: cdn('exhibition_sheets/exhibition_malay.png'),
      },
    ]);

    return {
      inner,
      festivalsData,
      festivalsDescription,
      festivalsItemsData,
      imageSources
    };
  },
  computed: {
    formattedFestivalsDescription(): string {
      const paragraph = this.festivalsDescription.split('\n\n');
      return paragraph.map((paragraph, index) => {
        if (index === 0) {
          return `<p class="tw-text-center tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">${paragraph}</p>`;
        } else {
          return `<p class="tw-text-center tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6">${paragraph}</p>`;
        }
      }).join('');
    }
  }
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100svh;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .landscape-hidden {
    display: none;
  }
}
.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=2); /*Default image*/
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
