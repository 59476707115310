<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import { useI18n } from 'vue-i18n';
import { h, ref } from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData: any;
}>();
const { t } = useI18n();
const uniqueDumplingsPosts = ref([]);
const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');
const menuItems = [
  {
    label: 'dw_duanwu_101_menu_in_singapore',
    id: 'in-singapore-section',
  },
  {
    label: 'dw_duanwu_101_menu_unique_dumplings',
    id: 'unique-dumplings-section',
  },
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onBeforeMount(() => {
  if (props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
  if (props.wordpressData?.unique_dumplings?.length > 0) {
    uniqueDumplingsPosts.value = props.wordpressData.unique_dumplings;
  }
});

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if (supElements?.length > 0) {
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if (supElements?.length > 0) {
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :dw-navigation="true"
    />
    <dw-secondary-navigation :menu-items-data="menuItems" />
    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/dw2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            :class="
              checkLang() == 'zh-hans'
                ? 'tw-text-sg-h1-mobile-dw-about-title-zh sgTablet:tw-text-sg-h1-tablet-dw-about-title-zh sgDesktop:tw-text-sg-h1-dw-about-title-zh tw-pb-1 tw-font-notosansbold'
                : 'tw-text-sg-h1-mobile-dw-about-title sgTablet:tw-text-sg-h1-tablet-dw-about-title sgDesktop:tw-text-sg-h1-dw-about-title tw-pb-1  tw-font-akkuratbold'
            "
          >
            {{ $t('dw_duanwu_101_title') }}
          </div>
        </div>
      </div>
    </div>

    <section class="tw-px-4 sgTablet:tw-px-10 tw-pt-5 sgTablet:tw-pt-10 tw-pb-[60px] sgDesktop:tw-pb-[100px]">
      <div
        id="sup-handler-container"
        class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider [&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]"
        v-html="wordpressData.description"
      />
    </section>

    <section class="tw-pt-[60px] sgDesktop:tw-pt-[100px] tw-pb-10 tw-bg-sg-dw24-blue-w60 tw-relative">
      <img
        :src="$cdn('images/dw2024/duanwu-101/dragon_left.png')"
        class="tw-absolute tw-left-0 tw-h-[120px] tw-w-[120px] tw-top-[-60px] sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >
      <img
        :src="$cdn('images/dw2024/duanwu-101/dragon_right.png')"
        class="tw-absolute tw-right-0 tw-h-[120px] tw-w-[120px] tw-top-[-60px] sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >

      <div
        id="in-singapore-section"
        class="tw-relative tw-pt-5 tw-pb-10 tw-px-4 sgTablet:tw-px-10"
      >
        <img
          :src="$cdn('images/dw2024/duanwu-101/deco_left.png')"
          class="tw-absolute tw-left-0 tw-top-1/2 -tw-translate-y-1/2 tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
        >
        <img
          :src="$cdn('images/dw2024/duanwu-101/deco_right.png')"
          class="tw-absolute tw-right-0 tw-top-1/2 -tw-translate-y-1/2 tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
        >

        <div
          class="sgTablet:tw-max-w-[min(100vw-200px-40px,800px)] sgDesktop:tw-max-w-[min(100vw-320px-40px,800px)] tw-w-full tw-mx-auto tw-bg-sg-dw24-dw1 tw-p-5 tw-flex tw-flex-col sgDesktop:tw-flex-row tw-rounded-lg tw-gap-5"
        >
          <div class="tw-w-[200px] tw-flex tw-self-center">
            <img
              class="tw-max-w-[200px]"
              :src="$cdn(checkLang() != 'zh-hans' ? 'images/dw2024/duanwu-101/Singaporen_Way_EN.png' : 'images/dw2024/duanwu-101/Singaporen_Way_CN.png')"
            >
          </div>
          <div class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider">
            {{ $t('dw_duanwu_101_singaporen_way_desc') }}
          </div>
        </div>
      </div>

      <div
        id="unique-dumplings-section"
        class="tw-max-w-[600px] sgTablet2:tw-max-w-[800px] sgDesktop:tw-max-w-[840px] tw-w-full tw-mx-auto tw-flex tw-gap-5 tw-mt-5"
      >
        <img
          :src="$cdn('images/dw2024/duanwu-101/unique_dumplings_left.png')"
          class="tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
        >

        <div
          class=" "
          :class="
            checkLang() == 'zh-hans'
              ? ' tw-text-sg-dw24-blue-b60 tw-px-4 sgTablet:tw-px-5 tw-flex tw-place-self-center tw-text-center tw-text-sg-h3-mobile-dw-title-zh sgTablet:tw-text-sg-h3-tablet-dw-title-zh sgDesktop:tw-text-sg-h3-dw-title-zh tw-font-notosansbold'
              : 'tw-text-sg-dw24-blue-b60 tw-px-4 sgTablet:tw-px-5 tw-flex tw-place-self-center tw-text-center  tw-text-sg-h3-mobile-dw-title sgTablet:tw-text-sg-h3-tablet-dw-title sgDesktop:tw-sg-dw-title tw-font-akkuratbold'
          "
        >
          {{ $t('dw_duanwu_101_unique_dumplings_title') }}
        </div>
        <img
          :src="$cdn('images/dw2024/duanwu-101/unique_dumplings_right.png')"
          class="tw-w-[100px] sgDesktop:tw-w-[160px] tw-hidden sgTablet:tw-block"
        >
      </div>
    </section>
    <section class="tw-pb-10 tw-bg-sg-dw24-blue-w60">
      <dw-unique-dumplings-swiper
        :list="uniqueDumplingsPosts"
        :handle-open-image-source-modal="handleOpenImageSourceModal"
      />
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>
