<template>
  <div
    class="tw-w-[100vw] tw-relative tw-overflow-visible"
    :style="{ height: swiperContainerHeight + 'px' }"
  >
    <swiper
      id="activity-swiper"
      :slides-per-view="'auto'"
      :slides-offset-before="16"
      :center-insufficient-slides="true"
      :space-between="isMobile?12:20"
      @swiper="onSwiper"
    >
      <swiper-slide>
        <!-- auction item 1 -->
        <div class="tw-flex tw-flex-col tw-items-center tw-w-[240px]">
          <!-- auction item 1 image -->
          <img
            class="tw-max-w-[240px] tw-h-[240px]"
            :src="$cdn('images/zy2024/zhongyuan-101/activities/popular-auction-items/item-1.png')"
            :style="{filter: 'drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.3))'}"
          >
          <!-- auction item 1 description -->
          <div
            class="tw-text-sg-caption-desktop-body tw-font-hanzipan tw-text-center tw-pt-5"
          >
            {{ $t('zy_zhongyuan_101_activities_auction_image_caption_1') }}
            <sup
              class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-tw-text-sg-ref-desktop-body tw-font-akkurat"
              @click="handleOpenImageSourceModal"
            >[C]</sup>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <!-- auction item 2 -->
        <div class="tw-flex tw-flex-col tw-items-center tw-w-[240px]">
          <!-- auction item 2 image -->
          <img
            class="tw-max-w-[240px] tw-h-[240px]"
            :src="$cdn('images/zy2024/zhongyuan-101/activities/popular-auction-items/item-2.png')"
            :style="{filter: 'drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.3))'}"
          >
          <!-- auction item 2 description -->
          <div
            class="tw-text-sg-caption-desktop-body tw-font-hanzipan tw-text-center tw-pt-5"
          >
            {{ $t('zy_zhongyuan_101_activities_auction_image_caption_2') }}
            <sup
              class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-tw-text-sg-ref-desktop-body tw-font-akkurat"
              @click="handleOpenImageSourceModal"
            >[D]</sup>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <!-- auction item 3 -->
        <div class="tw-flex tw-flex-col tw-items-center tw-w-[240px]">
          <!-- auction item 3 image -->
          <img
            class="tw-max-w-[240px] tw-h-[240px]"
            :src="$cdn('images/zy2024/zhongyuan-101/activities/popular-auction-items/item-3.png')"
            :style="{filter: 'drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.3))'}"
          >
          <!-- auction item 3 description -->
          <div
            class="tw-text-sg-caption-desktop-body tw-font-hanzipan tw-text-center tw-pt-5"
          >
            {{ $t('zy_zhongyuan_101_activities_auction_image_caption_3') }}
            <sup
              class="tw-text-sg-zy24-blue tw-cursor-pointer tw-text-tw-text-sg-ref-desktop-body tw-font-akkurat"
              @click="handleOpenImageSourceModal"
            >[E]</sup>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref, computed } from 'vue';

const emit = defineEmits(['update:imageSourceOpen']);

const swiperContainerHeight = ref(350);

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const props = defineProps<{
  imageSourceOpen: boolean,
}>();

const isMobile = ref(window.innerWidth <= 1024);


const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

</script>

<style scoped lang="scss">
.swiper-wrapper {
  height: 360px !important;
}

.swiper-slide {
  width: 240px;
  height: 360px;
}
</style>
