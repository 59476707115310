<template>
  <div>
    <!-- Image Border Rounded  -->
    <div class="tw-relative">
      <img
        :src="getImage()"
        alt="Event Image"
        :class="[
          'tw-h-[92px] sgTablet:tw-h-[146px]',
          'tw-w-[164px] sgTablet:tw-w-[260px]',
          'tw-cover tw-rounded-t-md'
        ]"
      >
      <!-- past event overlay -->
      <div
        v-if="isPastEvent"
        class="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-[164px] sgTablet:tw-w-[260px] tw-bg-black tw-opacity-40 tw-rounded-t-md"
      />
      <div
        v-if="isPastEvent"
        :class="[
          'tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-[164px] sgTablet:tw-w-[260px] tw-flex tw-justify-center tw-items-center',
          'tw-text-sg-remix-sh3 tw-text-white ',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        <p>{{ $t('remix_past_event') }}</p>
      </div>
    </div>
    <!-- Sold Out / Paid Event / Free Event / Fully Booked / Selling Fast -->
    <div
      :class="[
        getEventStatusColor(),
        'tw-flex tw-justify-center tw-items-center tw-text-sg-remix-b3 tw-text-white',
      ]"
    >
      {{ getEventStatusText() }}
    </div>
    <!-- Event Card Content -->
    <div class="tw-h-full tw-w-[164px] sgTablet:tw-w-[260px] tw-rounded-bl-lg tw-rounded-br-lg tw-shadow-md tw-bg-white hover:tw-inset-0 hover:tw-bg-gray-50">
      <div class="tw-px-2 tw-pt-2 md:tw-px-4 md:tw-pt-3">
        <!-- Event Type -->
        <p class="tw-h-6 tw-text-sg-remix-b3 tw-pb-1 tw-normal-case">
          {{ getEventTypeText() }}
        </p>
        <!-- Event Title -->
        <h3
          :class="[
            'tw-line-clamp-3 tw-h-[60px] tw-max-w-[228px] tw-text-sg-remix-sh3',
            'sgTablet:tw-line-clamp-2 md:tw-h-[40px]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ props.event.title }}
        </h3>
        <!-- Event Date -->
        <div
          :class="[
            'tw-pt-4 tw-flex tw-flex-col md:tw-flex-row tw-gap-2',
            { 'tw-hidden': hideDate },
          ]"
        >
          <p class="tw-text-sg-remix-caption">
            {{ $t('common.dates') }}
          </p>
          <p class="tw-text-sg-remix-caption">
            {{ props.event.acf.event_card_date ?? 'Date not specified' }}
          </p>
        </div>
        <!-- Find Out More Text Button -->
        <div class="tw-py-2 md:tw-py-3">
          <a
            :href="props.event.permalink"
          >
            <div class="tw-flex tw-flex-row tw-align-middle tw-items-center tw-justify-end tw-py-1 tw-pr-2 tw-gap-2">
              <span class="tw-text-sg-remix-bt2 tw-text-[#0366D8] hover:tw-underline">{{ $t('ce_swiper_btn') }}</span>
              <inline-svg
                class="tw-w-3 tw-h-3"
                fill="#0366D8"
                :src="$cdn('icons/ce/arrow_chevron_right_blue.svg')"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { Event, RemixEvent } from '../../../types/types';
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });
import { checkLang } from '../../../support/helper';

let featuredImage = ref('');

function getImage(): string{
  featuredImage.value = props.event.acf?.event_gallery?.[0]?.sizes?.medium_large || props.event?.acf?.event_gallery?.[0]?.url || '';
  return featuredImage.value;
}

function getEventTypeText() {

  let text = '';

  switch (props.event.acf.event_type) {
    case '0':
      text = t('remix.event.type_activities');
      break;
    case '1':
      text = t('remix.event.type_exhibition');
      break;
    case '2':
      text = t('remix.event.type_workshops');
      break;
    default:
      text = '';
      break;
  }

  return text;
}

function getEventStatusColor() {
  let color = '';

  if (props.event.acf.event_is_paid_event) {
    switch (props.event.acf.event_status_paid) {
      case '0':
        color = 'tw-bg-[#0366D8]';
        break;
      case '1':
        color = 'tw-bg-[#F6610C]';
        break;
      case '2':
        color = 'tw-bg-[#E4100E]';
        break;
      default:
        color = 'tw-bg-[#E4100E]';
        break;
    }
  } else {
    switch (props.event.acf.event_status_free) {
      case '0':
        color = 'tw-bg-[#36A93F]';
        break;
      case '1':
        color = 'tw-bg-[#E4100E]';
        break;
      default:
        color = 'tw-bg-[#E4100E]';
        break;
    }
  }

  return color;
}

function getEventStatusText(): string {
  const eventStatusPaid = props.event.acf.event_status_paid;
  const eventStatusFree = props.event.acf.event_status_free;
  const isPaidEvent = props.event.acf.event_is_paid_event;
  if (isPaidEvent) {
    if (eventStatusPaid === '0' || eventStatusPaid === null) return t('remix_paid_event');
    if (eventStatusPaid === '1') return t('remix_selling_fast');
    if (eventStatusPaid === '2') return t('remix_sold_out');
  } else {
    if (eventStatusFree === '0' || eventStatusFree === null) return t('remix_free_event');
    if (eventStatusFree === '1') return t('remix_fully_booked');
  }

  return '';
}

const props = defineProps({
  event: {
    type: Object as PropType<RemixEvent>,
    required: true
  },
  hideDate: {
    type: Boolean,
    default: true
  }
});

const isPastEvent = computed(() => {
  if (!props.event.acf.event_expire_date) return false;

  const [day, month, year] = props.event.acf.event_expire_date.split('/');
  const eventDate = new Date(`${year}-${month}-${day}`);
  const now = new Date();

  // Set the time of eventDate to the end of the day
  eventDate.setHours(23, 59, 59, 999);

  return eventDate < now;
});
</script>
