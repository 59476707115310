<template>
  <div
    :class="[
      'tw-relative',
      'tw-flex tw-flex-col tw-items-center',
      'tw-pt-0 tw-opacity-100 tw-min-h-[460px]',
      'sgTablet:tw-w-[300px] tw-w-[240px] tw-mt-[10px]',
      { 'tw-opacity-50 tw-mt-[42px] sgTablet:tw-mt-[50px]': !active },
    ]"
  >
    <div class="timeline-image tw-relative tw-flex tw-items-center tw-justify-center tw-w-full">
      <!-- Left dotted line -->
      <img
        v-if="active"
        :class="[
          'timeline-dot-line left-side',
          'tw-absolute tw-left-0 tw-w-[70px] sgTablet:tw-w-[100px]',
          'tw-z-0 tw-ml-[-50px] sgTablet:tw-ml-[-75px]',
        ]"
        draggable="false"
        :src="$cdn('images/ws2024/homepage/dot-line-left.png')"
      >

      <!-- Center Image -->
      <div
        :class="[
          'timeline-card',
          'tw-rounded-full tw-overflow-hidden',
          'tw-flex tw-justify-center tw-items-center',
          'tw-border-3 tw-border-[#a3333a] sgTablet:tw-cursor-pointer tw-cursor-grab',
          'tw-w-[240px] tw-h-[240px]',
          'tw-flex-shrink-0',
          'tw-z-10', // Ensure the image is above the lines
          { 'inactive tw-mt-[15px] sgTablet:tw-mt-2': !active },
          { 'active': active },
        ]"
      >
        <img
          :src="img"
          alt="Timeline Image"
          class="tw-object-cover tw-w-full tw-h-full tw-flex-shrink-0"
          draggable="false"
          loading="lazy"
        >
      </div>

      <!-- Right dotted line -->
      <img
        v-if="active"
        :class="[
          'timeline-dot-line right-side',
          'tw-absolute tw-right-0 tw-w-[70px] sgTablet:tw-w-[100px]',
          'tw-z-0 tw-mr-[-45px] sgTablet:tw-mr-[-75px]',
        ]"
        draggable="false"
        :src="$cdn('images/ws2024/homepage/dot-line-right.png')"
      >
    </div>

    <div
      :class="[
        'timeline-connector',
        'tw-bg-sg-ws24-maroon tw-rounded-b',
        {'tw-h-[40px] tw-w-[8px]': active },
        {'tw-h-[20px] tw-w-[6px]': !active},
      ]"
    />

    <!-- Year -->
    <div
      :class="[
        'timeline-year',
        'tw-mt-2 tw-text-[16px] tw-text-[#a3333a] tw-font-bold',
        'tw-text-sg-ws-sh1',
        {'tw-text-sg-ws-h2 sgTablet:tw-text-sg-ws-h2-tablet sgDesktop:tw-text-sg-ws-h2-desktop' : active},
        { 'tw-font-akkuratbold' : checkLang() == 'en' },
        { 'tw-font-notosansbold' : checkLang() != 'en' },
      ]"
    >
      {{ year }}
    </div>

    <!-- Description -->
    <div
      v-show="showDescription"
      id="sup-handler-container"
      ref="descriptionRef"
      :class="[
        'timeline-description',
        'tw-text-center tw-mt-2 tw-absolute tw-top-[320px] sgTablet:tw-top-[345px]',
        'tw-max-w-[300px]',
        { 'tw-opacity-0' : !active || !showDotLine },
        // { 'tw-opacity-100' : active },
      ]"
    >
      {{ description }} <sup>[{{ reference }}]</sup>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { checkLang } from '../../support/helper';

const showDotLine = ref(false);
const isMobile = ref(window.innerWidth < 680);

const descriptionRef = ref(null);

function onResize() {
  isMobile.value = window.innerWidth < 680;
}

onMounted(() => {
  // Watcher for active prop
  onResize();
  window.addEventListener('resize', onResize);
  watch(
    () => props.active,
    (newVal) => {
      showDotLine.value = newVal;
    },
    { immediate: true } // Trigger the watcher on component mount
  );
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

// Define props for the component
const props = defineProps<{
  imageSourceOpen: boolean,
  img: string,
  year: string,
  description: string,
  reference: string,
  active: boolean,
  sliderMove: boolean,
  isClick: boolean,
  showDescription: boolean,
}>();

// Define emits for updating parent state
const emit = defineEmits(['update:imageSourceOpen', 'onClick']);

// watch(() => props.isClick, (value) => {
//   if (props.active && descriptionRef.value) {
//     gsap.to(descriptionRef.value, {
//       duration: 0.3,
//       x: 100,
//       y: -50,
//       ease: 'power2.out',
//       opacity: 0,
//       onComplete: () => {
//         emit('sliderStop');
//       },
//     });
//   }
// });

// watch(() => props.sliderMove, (value) => {
//   if (value) {
//     showDotLine.value = false;
//   }else{
//     showDotLine.value = true;
//   }
// });

// Toggle the image source modal visibility
const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = true;
};

// Computed property to manage image source modal visibility
const imageSourceOpen = computed({
  get() {
    return props.imageSourceOpen;
  },
  set(value) {
    emit('update:imageSourceOpen', value);
  },
});
</script>

<style scoped>

.timeline-description {
  transition: opacity 0.25s ease;
}

.show-dot-line {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.hide-dot-line {
  opacity: 0;
  transition: opacity 0.25s ease;
}

.timeline-card{
  box-shadow: 0 0 0 7px #a3333a;
}

.timeline-card.inactive {
  height:140px;
  width: 140px;
}

#sup-handler-container:deep(sup){
  color: #0366D8;
  cursor: pointer;
}

/* animation */
.sg-move-center-from-left .timeline-card {
  width: 240px !important;
  height: 240px !important;
  transition: all 0.25s ease;
}

.sg-move-right-from-center .timeline-card{
  width: 140px !important;
  height: 140px !important;
  transition: all 0.25s ease;
}

.sg-move-left-from-center .timeline-card{
  width: 140px !important;
  height: 140px !important;
  transition: all 0.25s ease;
}

.sg-move-center-from-right .timeline-card{
  width: 240px !important;
  height: 240px !important;
  transition: all 0.25s ease;
}

/* connector */
.sg-move-center-from-left .timeline-connector {
  width: 8px !important;
  height: 40px !important;
  transition: all 0.25s ease;
}

.sg-move-right-from-center .timeline-connector{
  width: 6px !important;
  height: 20px !important;
  transition: all 0.25s ease;
}

.sg-move-left-from-center .timeline-connector{
  width: 6px !important;
  height: 20px !important;
  transition: all 0.25s ease;
}

.sg-move-center-from-right .timeline-connector{
  width: 8px !important;
  height: 40px !important;
  transition: all 0.25s ease;
}

/* year */
/* Transition for all cases */
.timeline-year {
  transition: all 0.25s ease;
}

.sg-move-center-from-left .timeline-year,
.sg-move-center-from-right .timeline-year {
  font-size: 1.5rem; /* 32px */
  line-height: 2rem; /* 40px */
  letter-spacing: 0.48px;
  font-weight: 700;
}

/* Tablet styles for sg-ws-h2 */
@media (min-width: 680px) {
  .sg-move-center-from-left .timeline-year,
  .sg-move-center-from-right .timeline-year {
    font-size: 2.5rem; /* 40px */
    line-height: 3.25rem; /* 52px */
    letter-spacing: 0.4px;
    font-weight: 400;
  }
}

/* Mobile styles for sg-ws-h2 */
@media (min-width: 1025px) {
  .sg-move-center-from-left .timeline-year,
.sg-move-center-from-right .timeline-year {
  font-size: 3rem; /* 48px */
  line-height: 3.75rem; /* 60px */
  letter-spacing: 0.48px;
  font-weight: 400;
}
}

/* Styles for sg-ws-sh1 */
.sg-move-right-from-center .timeline-year,
.sg-move-left-from-center .timeline-year {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  letter-spacing: 0.48px;
}

.sg-move-right-from-center .timeline-dot-line {
  opacity: 0.5;
  transform: translateX(-300px);
  width: 110px;
  transition: all 0.25s ease;
}

.sg-move-left-from-center .timeline-dot-line {
  opacity: 0.5;
  transform: translateX(300px);
  width: 110px;
  transition: all 0.25s ease;
}

.sg-move-right-from-center .timeline-description,
.sg-move-left-from-center .timeline-description {
  opacity: 0;
  transition: opacity 0.25s ease;
}
</style>
