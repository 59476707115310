<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import { ref} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen  = ref<boolean>(false);
const imageSourcesData = ref('');

const menuItems = [
  {
    label: 'ws_home_menu_through_the_years',
    id   : 'through-the-years-section'
  },
  {
    label: 'ws_home_menu_origin_stories',
    id   : 'origin-stories-section'
  }
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};


const timeline_post = ref([]);

const pageBreakHeight1 = ref(0);
const pageBreakHeight2 = ref(0);

const isMobile = ref(window.innerWidth <= 799);

onBeforeMount(()=>{
  if(props.wordpressData?.timeline){
    timeline_post.value = props.wordpressData.timeline;
  }

  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

const pageBreak1 = ref<HTMLElement | null>(null);
const pageBreak2 = ref<HTMLElement | null>(null);

onMounted(() => {
  window.addEventListener('load', updatePageBreakHeight);
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }

    pageBreak1.value = document.getElementById('page-break-1');
    pageBreak2.value = document.getElementById('page-break-2');
    updatePageBreakHeight();
    window.addEventListener('resize', updatePageBreakHeight);
  });
});

// Function to get the element's height after images have loaded
function updatePageBreakHeight() {
  if (pageBreak1.value) {
    pageBreakHeight1.value = pageBreak1.value.getBoundingClientRect().height;
  }
  if (pageBreak2.value) {
    pageBreakHeight2.value = pageBreak2.value.getBoundingClientRect().height;
  }

  isMobile.value = window.innerWidth <= 799;
}

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  window.removeEventListener('resize', updatePageBreakHeight);

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});
</script>

<template>
  <div
    class="tw-relative tw-overflow-hidden"
    :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}"
    style="touch-action: pan-y;"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ws-navigation="true"
    />
    <ws-secondary-navigation
      :menu-items-data="menuItems"
    />
    <div />
    <!-- Header section -->
    <section class="tw-relative">
      <picture>
        <div
          :style="{
            background:`linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${$cdn('images/ws2024/banner/ws-home-banner.jpg')}) lightgray 50% / cover no-repeat`
          }"
          class="tw-w-full tw-object-cover tw-h-[661px] sgTablet2:tw-h-[1257px] sgDesktop:tw-h-[903px] sgTablet:tw-h-[1072px] "
        />
      </picture>
      <div class="tw-absolute tw-inset-0 tw-top-[80px] sgTablet:tw-top-[100px] sgDesktop:tw-top-[120px]">
        <div class="tw-text-white tw-text-center">
          <img
            class="tw-pb-[10px] tw-h-[40px] sgTablet:tw-h-[60px] tw-mx-auto"
            :src="$cdn('images/ws2024/banner/ws-home-header-ribbon.png')"
            :style="{
              filter: 'drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.30))'
            }"
          >
          <div
            :class="[
              'tw-pb-1',
              'tw-text-sg-ws-dp3-mobile sgTablet:tw-text-sg-ws-dp3-tablet sgDesktop:tw-text-sg-ws-dp3-desktop',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
            :style="{
              textShadow: `-4px 4px 10px rgba(0, 0, 0, 0.30)`
            }"
          >
            {{ $t('ws_home_title') }}
          </div>
        </div>
      </div>
      <img
        id="page-break-1"
        class="tw-absolute tw-bottom-0 tw-left-0 tw-z-10 tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-home-page-break-1.png')"
      >
    </section>

    <!-- description -->
    <section
      :class="[
        'tw-pt-2',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center'
      ]"
    >
      <!-- Timeline section -->
      <div
        id="sup-handler-container"
        :class="[
          'tw-w-full tw-text-sg-ws-body tw-text-center',
          'tw-max-w-[800px]'
        ]"
        v-html="props.wordpressData?.description"
      />
    </section>

    <!-- Winter Solstice Through the Years section -->
    <section
      id="through-the-years-section"
      :class="[
        'tw-pt-2',
        'tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center'
      ]"
    >
      <div
        :class="[
          'tw-w-full tw-max-w-[800px]',
          'tw-pt-10 tw-text-center',
          'tw-text-sg-ws24-maroon',
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
        v-html="$t('ws_home_section_2_title')"
      />
      <ws-home-timeline-swiper
        :datas="timeline_post"
        :image-source-open="imageSourceOpen"
      />
    </section>


    <!-- Origin stories section -->
    <section
      id="origin-stories-section"
      :class="[
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        'tw-relative tw-flex tw-justify-center tw-items-center',
        // 'tw-pt-[100px] sgDesktop:tw-pt-[102px] sgDesktop2:tw-pt-[120px]',
        'tw-pb-10 sgTablet:tw-pb-[80px]',
        'tw-mt-[2px]'
      ]"
      :style="[
        `background: url(${$cdn(isMobile?'images/ws2024/homepage/ws-home-origin-stories-bg-mobile.jpg':'images/ws2024/homepage/ws-home-origin-stories-bg.jpg')}) lightgray 50% / cover no-repeat;`,
        `padding-top: ${pageBreakHeight2}px;`,
      ]"
    >
      <img
        id="page-break-2"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-home-page-break-2.png')"
      >
      <div
        :class="[
          'tw-w-full tw-max-w-[800px] tw-h-[500px] sgTablet2:tw-h-full',
          'tw-flex tw-flex-col tw-justify-end sgTablet2:tw-justify-center',
          'tw-text-white',
          'tw-items-center sgTablet2:tw-items-start'
        ]"
      >
        <h3
          :class="[
            'tw-text-center sgTablet:tw-text-left',
            'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop',
            'tw-pt-5 sgTablet:tw-pt-[60px]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('ws_home_section_3_title') }}
        </h3>
        <p class="tw-text-sg-ws-body sgTablet2:tw-max-w-[390px] tw-mt-1 tw-text-center sgTablet2:tw-text-left">
          {{ $t('ws_home_section_3_desc') }}
        </p>
        <div class="tw-flex tw-mt-8">
          <a
            :href="$langRedirect('/festivals/winter-solstice/origin-stories', checkLang())"
            target="_blank"
            :class="[
              'tw-text-white tw-bg-sg-ws24-maroon',
              'tw-px-6 tw-py-4',
              'tw-text-sg-ma-bt1',
              'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
              'hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ws_home_discover_more') }}
          </a>
        </div>
      </div>
    </section>


    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style lang="scss" scoped>
#sup-handler-container:deep(sup){
  cursor: pointer;
  color: #0366D8;
}
</style>
