<template>
  <div class="tw-bg-[#F4F4F4] tw-py-10 tw-relative">

    <div class="tw-flex tw-flex-col tw-gap-[40px]">
      <!-- Official privilege card (Hidden for CE2025, commented out as per client request)-->
      <div class="tw-hidden tw-flex-wrap tw-justify-center">
        <div>
          <h4 class="tw-text-center tw-pb-5 tw-text-sg-sh2 tw-uppercase">
            {{ $t('ce_partner_title_1') }}
          </h4>
          <div class="tw-flex tw-justify-center">
            <img
              class="tw-w-[100px] tw-h-[53px]"
              :src="$cdn('images/experience/ce/privilege-card.png')"
            >
          </div>
        </div>
      </div>
      <!-- Official Community Partners -->
      <div class="tw-flex tw-flex-wrap tw-justify-center">
        <div>
          <h4 class="tw-text-center tw-pb-5 tw-text-sg-sh2 tw-uppercase">
            {{ $t('ce_partner_title_2') }}
          </h4>
          <div class="tw-flex tw-flex-wrap tw-gap-x-10 tw-gap-y-4 tw-justify-center sgTablet:tw-justify-center">
            <div
              v-for="(partner, index) in partnersData"
              :key="index"
              class="tw-w-[100px] tw-h-[53px] tw-mx-4"
            >
              <img :src="$cdn(partner.url)">
            </div>
          </div>
        </div>
      </div>
      <!-- Special Thanks (Hidden for CE2025, commented out as per client request)-->
      <div class=" tw-flex-wrap tw-justify-center tw-hidden">
        <div>
          <h4 class="tw-text-center tw-pb-5 tw-text-sg-sh2 tw-uppercase">
            {{ $t('ce_partner_title_3') }}
          </h4>
          <div class="tw-flex tw-flex-wrap tw-justify-around tablet:tw-justify-center">
            <div
              v-for="(specialThanks, index) in specialThanksData"
              :key="index"
            >
              <img
                class="tw-w-[143px] tw-h-20"
                :src="$cdn(specialThanks.url)"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const partnersData = [
  /* Commented out for now, may reuse in future */
  // {
  //   id: 1,
  //   url: 'images/experience/ce/partner-ntuc.png'
  // },
  {
    id: 2,
    url: 'images/experience/ce/partner-safra.png'
  },
  {
    id: 3,
    url: 'images/experience/ce/partner-hometeamns.png'
  },
  {
    id: 4,
    url: 'images/experience/ce/partner-merewards.png'
  }];

const specialThanksData = [{
  id: 1,
  url: 'images/experience/ce/special-thanks-dao.png'
}, {
  id: 2,
  url: 'images/experience/ce/special-thanks-metro.png'
}];
</script>
