<template>
  <div>
    <template v-if="part==1">
      <!-- Section 1 -->
      <ap-exhibition-text-layout
        :text="aboutTextSection1"
        div-id="div-section-1"
      />
      <!-- Section 2 -->
      <ap-exhibition-text-layout
        :italic-text="aboutTextSection2Italic"
        :text="aboutTextSection2"
        :is-text-left="false"
        div-id="div-section-2"
      />

      <!-- Section 1 -->
      <!-- <ap-section-layout
      :section-text-one="aboutTextSection1"
      :section-text-two="aboutTextSection2"
      :section-image-url="wordpressData.exhibition_header_about"
      :is-first-image="true"
    /> -->

      <!-- Section SCCC Clickable -->
      <ap-sccc-clickable
        id="section-pin"
        :default-image="wordpressData.exhibition_header_image === '' ? 'https://picsum.photos/1200?random=7' : wordpressData.exhibition_header_about"
        :image-zone1="wordpressData.image_zone_1 === '' ? 'https://picsum.photos/1200?random=1' : wordpressData.image_zone_1"
        :image-zone2="wordpressData.image_zone_2 === '' ? 'https://picsum.photos/1200?random=2' : wordpressData.image_zone_2"
        :image-zone3="wordpressData.image_zone_3 === '' ? 'https://picsum.photos/1200?random=3' : wordpressData.image_zone_3"
        :image-zone4="wordpressData.image_zone_4 === '' ? 'https://picsum.photos/1200?random=4' : wordpressData.image_zone_4"
        :image-zone5="wordpressData.image_zone_5 === '' ? 'https://picsum.photos/1200?random=5' : wordpressData.image_zone_5"
        :image-zone-t="wordpressData.image_zone_t === '' ? 'https://picsum.photos/1200?random=6' : wordpressData.image_zone_t"
        :reinit-gsap="reinitGsap"
      />
    </template>
    <!-- Exhibition Swiper -->
    <template v-if="part==2">
      <ap-swiper
        class=" tw-bg-white tw-relative"
        swiper-type="exhibition"
        :exhibition-data="exhibitionData"
        page-name="exhibition"
        description=""
        :title="$t('exhibition_sheet')"
      />
    </template>

    <!-- part == 1 is for the modal appear once only -->
    <ap-exhibition-notice-modal
      v-if="part == 1"
      :show-primary-button="false"
    />
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { cdn } from '../../support/cdn';

export default {
  props: {
    part:{
      type:Number,
      required:true,
    },
    aboutTextSection1: {
      type: String,
      default: '',
    },
    aboutImageSection1: {
      type: String,
      default: '',
    },
    aboutTextSection2: {
      type: String,
      default: '',
    },
    aboutTextSection2Italic: {
      type: String,
      default: '',
    },
    aboutImageSection2: {
      type: String,
      default: '',
    },
    wordpressData: {
      type: Object,
      default: null,
    },
    reinitGsap: {
      type: Function,
      default: undefined,
    }
  },

  setup() {
    const { t } = useI18n();

    const exhibitionData = [
      {
        id: 1,
        title: t('exhibition_sheet_eng'),
        external_url: cdn('exhibition_sheets/Exhibition_Text_EN_v_12_04062021_f0d1db8243_166a83e7e6.pdf'),
        image_url: cdn('exhibition_sheets/exhibition_eng.png'),
      },
      {
        id: 2,
        title: t('exhibition_sheet_cn'),
        external_url: cdn('exhibition_sheets/Exhibition_Text_CN_v_9_04062021_0d17b4a2fb_2f887bbef0.pdf'),
        image_url: cdn('exhibition_sheets/exhibition_chinese.png'),
      },
      {
        id: 3,
        title: t('exhibition_sheet_tamil'),
        external_url: cdn('exhibition_sheets/Exhibition_Text_TML_v_8_02062021_480cca56ab_860d1271d1.pdf'),
        image_url: cdn('exhibition_sheets/exhibition_tamil.png'),
      },
      {
        id: 4,
        title: t('exhibition_sheet_malay'),
        external_url: cdn('exhibition_sheets/Exhibition_Text_ML_v_13_04062021_f342caacf9_b4e9da6f3c.pdf'),
        image_url: cdn('exhibition_sheets/exhibition_malay.png'),
      },
    ];

    return {
      exhibitionData,
    };
  },
};
</script>
