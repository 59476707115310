<script lang="ts" setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const menuItems = [
  {
    label:'dw_personality_quiz_menu_personality_quiz',
    id:'personality-quiz-section'
  },
  {
    label:'dw_personality_quiz_menu_game',
    id:'game-section'
  }
];

</script>

<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :dw-navigation="true"
    />
    <dw-secondary-navigation
      :menu-items-data="menuItems"
    />
    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/dw2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            :class="checkLang() == 'zh-hans'? 'tw-text-sg-h1-mobile-dw-about-title-zh sgTablet:tw-text-sg-h1-tablet-dw-about-title-zh sgDesktop:tw-text-sg-h1-dw-about-title-zh tw-pb-1 tw-font-notosansbold': 'tw-text-sg-h1-mobile-dw-about-title sgTablet:tw-text-sg-h1-tablet-dw-about-title sgDesktop:tw-text-sg-h1-dw-about-title tw-pb-1  tw-font-akkuratbold'"
          >
            {{ $t('dw_personality_quiz_title') }}
          </div>
          <div
            :class="checkLang() == 'zh-hans'? 'tw-text-sg-sh1-mobile-dw-zh sgTablet:tw-text-sg-sh1-tablet-dw-zh sgDesktop:tw-text-sg-sh1-dw-zh tw-pb-1 tw-font-notosansbold': 'tw-text-sg-sh1-mobile-dw sgTablet:tw-text-sg-sh1-tablet-dw sgDesktop:tw-text-sg-sh1-dw tw-mt-1 tw-font-akkuratbold'"
          >
            {{ $t('dw_personality_quiz_subtitle') }}
          </div>
        </div>
      </div>
    </div>

    <section class="tw-px-4 sgTablet:tw-px-10 tw-pt-5 sgTablet:tw-pt-10 tw-pb-[60px] sgDesktop:tw-pb-[100px]">
      <div
        id="sup-handler-container"
        class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
        [&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]
        "
        

        v-html="wordpressData.description"
      />

    </section>
    <section class="tw-h-[60px] sgDesktop:tw-h-[100px] tw-bg-sg-dw24-blue-w60 tw-relative">

      <img
        :src="$cdn('images/dw2024/personality-quiz/left.png')"
        class="tw-absolute tw-left-0
  tw-h-[120px] tw-w-[120px] tw-top-[-60px]
  sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >
      <img
        :src="$cdn('images/dw2024/personality-quiz/right.png')"
        class="tw-absolute tw-right-0
  tw-h-[120px] tw-w-[120px] tw-top-[-60px]
  sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >
    </section>
    <dw-personality-quiz-dumpling id="personality-quiz-section" />
    <section
      id="game-section"
      class="tw-bg-sg-dw24-blue-w20 tw-py-5 sgTablet:tw-py-10"
    >
      <div class="tw-px-4 sgTablet:tw-px-0 tw-max-w-[520px] sgTablet2:tw-max-w-[680px] tw-mx-auto">
        <div
          class="tw-text-sg-sh2-mobile sgTablet:tw-text-sg-sh2-tablet sgDesktop:tw-text-sg-sh2 tw-text-center tw-text-sg-dw24-blue-b60"
          :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
        >
          <div>{{ $t('dw_personality_quiz_minigame_title_1') }}</div>
          <div>{{ $t('dw_personality_quiz_minigame_title_2') }}</div>
        </div>

        <div
          class="tw-mt-5 tw-text-[12px] tw-leading-[18px] tw-text-sg-dw24-blue-b60 tw-text-center"
        >
          {{ $t('dw_personality_quiz_minigame_caption') }}
        </div>
      </div>
      <div class="tw-px-4 sgTablet:tw-px-10 tw-max-w-[800px] tw-mx-auto tw-mt-6 sgTablet:tw-mt-10">
        <div class=" tw-relative tw-pt-[59%]">
          <iframe
            class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-bottom-0 tw-left-0"
            src="https://play.solstice.sg/bakchanghunt/?fbclid=IwAR1F4qaujn2WxY3atG7ZuZazrTYpPAlvgdViZ2hu5EcZ8XAB1yIpYaejuW4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            allowfullscreen
          />
        </div>
      </div>
    </section>
    <ap-footer />
  </div>
</template>
