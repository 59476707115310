<template>


  <div
    class="tw-w-full"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <swiper
      id="family-fun-swiper"
      :slides-per-view="'auto'"
      :space-between="20"
      :modules="[FreeMode,Mousewheel,Autoplay]"
      :free-mode="{
        enabled:true,
        momentum:false,

      }"
      :autoplay="{
        disableOnInteraction:false,
        pauseOnMouseEnter:false,
        delay:1,
        waitForTransition:true,
      }"
      :speed="5000"
      loop
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in list"
        :key="index"
        class="tw-w-[300px]"
      >
        <img
          :src="$cdn(item.image)"
          class="tw-h-[300px] tw-w[300px] tw-object-cover tw-object-center"
        >
        <div class="tw-py-5 tw-px-4 tw-cursor-default">
          <div
            class="tw-font-hobeaux tw-font-[900] tw-text-[#007C4C] tw-line-clamp-2 tw-h-[48px]"
            :title="item.name"
          >
            {{ item.name }}
          </div>
          <div class="tw-mt-2 tw-text-[#007C4C] tw-text-xs">
            <div class="tw-italic">
              {{ $t('cny_family_fun_image_source') }}:
            </div>
            <a
              :href="item.href"
              class="tw-line-clamp-1"
              target="_blank"
              rel="noopener"
            >{{ item.href_label }}</a>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import type {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import {FreeMode,Mousewheel,Autoplay} from 'swiper/modules';
import { useI18n } from 'vue-i18n';

import { onMounted, toRefs, ref, Ref } from 'vue';
const {t} = useI18n();
const list = ref([
  {
    id:1,
    name: t('cny_family_fun_dragons_section_1_title'),
    image:'/images/cny2024/family-fun/swiper_img_1.png',
    href_label:'Roots - Leong San See Temple',
    href:'https://www.roots.gov.sg/places/places-landing/Places/landmarks/little-india-heritage-trail-walk-of-faiths/leong-san-see-temple'
  },
  {
    id:2,
    name: t('cny_family_fun_dragons_section_2_title'),
    image:'/images/cny2024/family-fun/swiper_img_2.png',
    href_label:'Roots - Thow Kwang Pottery Jungle: Stoking the Fires of Singapore’s Dragon Kiln',
    href:'https://www.roots.gov.sg/en/stories-landing/stories/Thow-Kwang-Pottery-Jungle-Stoking-the-fires-of-Singapores-dragon-kiln/Thow-Kwang-Pottery-Jungle-Stoking-the-fires-of-Singapores-dragon-kiln'

  },
  {
    id:3,
    name: t('cny_family_fun_dragons_section_3_title'),
    image:'/images/cny2024/family-fun/swiper_img_3.png',
    href_label:'veryrealandrew - New Photographs from Toa Payoh, Singapore\'s First Fully-HDB Built Satellite Town',
    href:'https://veryrealandrew.com/site-visits/2019/9/20'
  },
  {
    id:4,
    name: t('cny_family_fun_dragons_section_4_title'),
    image:'/images/cny2024/family-fun/swiper_img_4.png',
    href_label:'Roots - The Singapore Chinese Chamber of Commerce Building at Hill Street',
    href:'https://www.roots.gov.sg/Collection-Landing/listing/1213771'
  },
  {
    id:5,
    name: t('cny_family_fun_dragons_section_5_title'),
    image:'/images/cny2024/family-fun/swiper_img_5.png',
    href_label:'Remember Singapore - Whampoa Dragon Fountain',
    href:'https://remembersingapore.org/whampoa-dragon-fountain/'
  },
  {
    id:6,
    name: t('cny_family_fun_dragons_section_6_title'),
    image:'/images/cny2024/family-fun/swiper_img_6.png',
    href_label:'The Beat Singapore - Vintage Dragon Playgrounds in Singapore From the ‘70s Where Your Parents Used to Play At',
    href:'https://thebeat.asia/singapore/ohana/family/vintage-dragon-playgrounds-in-singapore-from-the-70s-where-your-parents-used-to-play-at'
  },
  {
    id:7,
    name: t('cny_family_fun_dragons_section_7_title'),
    image:'/images/cny2024/family-fun/swiper_img_7.png',
    href_label:'Remember Singapore - haw par villa dragon head',
    href:'https://remembersingapore.org/haw-par-villa-dragon-head/#'
  },
  {
    id:8,
    name: t('cny_family_fun_dragons_section_8_title'),
    image:'/images/cny2024/family-fun/swiper_img_8.png',
    href_label:'Trivia.sg - Dragon’s Teeth Gate was a Hideout for Pirates at Keppel Harbour (3/7)',
    href:'https://trivia.sg/snax_item/dragons-teeth-gate-was-a-hideout-for-pirates-at-keppel-harbour/'
  },
  {
    id:9,
    name: t('cny_family_fun_dragons_section_9_title'),
    image:'/images/cny2024/family-fun/swiper_img_9.png',
    href_label:'All About City Singapore - THE CANYON AT GARDENS BY THE BAY',
    href:'https://allabout.city/singapore/the-canyon-at-gardens-by-the-bay/'
  },
  {
    id:10,
    name: t('cny_family_fun_dragons_section_10_title'),
    image:'/images/cny2024/family-fun/swiper_img_10.png',
    href_label:'NParks - 2 Angsana',
    href:'https://www.nparks.gov.sg/activities/family-time-with-nature/recommended-activities/know-10-trees/2-angsana'
  },
  {
    id:11,
    name: t('cny_family_fun_dragons_section_11_title'),
    image:'/images/cny2024/family-fun/swiper_img_11.png',
    href_label:'Singaporeodonate - Dragonflies & Damselflies of Singapore',
    href:'https://singaporeodonata.wordpress.com/'
  },
  {
    id:12,
    name: t('cny_family_fun_dragons_section_12_title'),
    image:'/images/cny2024/family-fun/swiper_img_12.png',
    href_label:'Singapore Dragon Boat Team (Mens)',
    href:'https://www.facebook.com/SingaporeNationalDragonboatTeamMens/'
  },
]);
const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper:any) => {
  swiperInstance.value = swiper;
};
const onMouseEnter = () => {
  swiperInstance.value?.autoplay.stop();
};
const onMouseLeave = () => {
  swiperInstance.value?.autoplay.start();
};
</script>
<style lang="scss">
 #family-fun-swiper .swiper-wrapper .swiper-slide{
  @apply first:tw-ml-4 last:tw-mr-4 sgTablet:first:tw-ml-8 sgTablet:last:tw-mr-8 sgDesktop:first:tw-ml-20 sgDesktop:last:tw-mr-20;
}

#family-fun-swiper .swiper-wrapper{
    @apply tw-ease-linear;
  }
</style>
