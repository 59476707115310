<script lang="ts" setup>
import {useI18n} from 'vue-i18n';
import { ref, onMounted, nextTick, onBeforeUnmount, onBeforeMount} from 'vue';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref();
const videos_content = ref<Video[]>([]);

const menuItems = ref<any>([]);

interface Video {
  title: string;
  description: string;
  external_link: string;
  youtube_facebook_embed_link: string;
  secondary_nav_menu_title: string;
}

onBeforeMount(()=>{
  if(props.wordpressData?.videos_content) {
    videos_content.value = props.wordpressData.videos_content;
    console.log('videos_content', videos_content.value);
    menuItems.value = videos_content.value.map((x, index) => {
      return {
        label: x?.secondary_nav_menu_title ?? '',
        id: 'video_section_' + index,
      };
    });
  }
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});
onMounted(()=>{

  nextTick(() => {

    // Add <sup> click listener
    const aboutDesc = document.getElementById('dw-explore-online');
    if(aboutDesc){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#dw-explore-online sup');
      supElements.forEach((supElement) => {

        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('dw-explore-online');

  if(burningJossCard){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#dw-explore-online sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});

</script>

<template>
  <div :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ma-navigation="true"
      :ma2-navigation="wordpressData?.canView?.includes('sidebar.ma2')"
    />
    <ma-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- header -->
    <section class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/ma2024/ma_sub_banner.jpg')"
          :class="[
            'tw-w-full',
            'tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]',
            'tw-object-cover'
          ]"
        >
      </picture>
      <div class="tw-bg-black/20 tw-absolute tw-w-full tw-h-full tw-top-0" />
      <div class="tw-absolute tw-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-ma-mobile-h1 sgTablet:tw-text-sg-ma-tablet-h1 sgDesktop:tw-text-sg-ma-desktop-h1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_explore_online_title') }}
          </div>
          <div
            :class="[
              'tw-text-sg-ma-sh1 tw-mt-1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_explore_online_subtitle') }}
          </div>
        </div>
      </div>
    </section>

    <section
      :class="[
        'tw-relative',
        'tw-flex tw-flex-col tw-items-center',
        'sgDesktop:tw-gap-[60px] tw-gap-10',
        'tw-bg-[#FAE4EE]',
        'sgDesktop:tw-px-[80px] sgTablet:tw-px-10 tw-px-4',
        'tw-pt-10 sgDesktop:tw-pt-[60px]',
        'tw-pb-[52px] sgTablet:tw-pb-[82px] sgTablet2:tw-pb-[92px] sgDesktop2:tw-pb-[104px]',
      ]"
    >
      <div
        v-for="(row, index) of videos_content"
        :id="'video_section_'+index"
        :key="index+'video'"
        :class="[
          'tw-max-w-[1000px]',
          'sgTablet:tw-gap-10 tw-gap-5',
          'tw-flex tw-flex-col sgTablet2:tw-flex-row tw-justify-between ',
          {'sgTablet2:tw-flex-row-reverse': index%2 == 1 }
        ]"
      >
        <!-- Video -->
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-items-center"
          :class="[

          ]"
        >
          <!-- tw-aspect-video -->

          <iframe
            :class="[
              'tw-rounded-lg tw-aspect-video',
              'sgTablet2:tw-w-[40vw] tw-w-full',
              'sgDesktop:tw-min-w-[372px]',
              'sgDesktop:tw-max-w-[480px] sgTablet2:tw-max-w-[412px] sgTablet:tw-max-w-[520px]',
            ]"
            :src="row.youtube_facebook_embed_link"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>


        <!-- Context -->
        <div
          :class="[
            'tw-flex tw-flex-col tw-justify-center',
            'tw-gap-5'
          ]"
        >
          <!-- title -->
          <div
            :class="[
              'tw-text-[#4E2E65]',
              'tw-text-sg-ma-mobile-h4 sgTablet:tw-text-sg-ma-tablet-h4 sgDesktop:tw-text-sg-ma-desktop-h4',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ row.title }}
          </div>
          <!-- description -->
          <div
            :class="[
              'tw-text-[#4E2E65] underline-anchor-tag',
              'tw-text-sg-ma-body'
            ]"
            v-html="row.description"
          />

        </div>
      </div>
      <div class="tw-absolute tw-bottom-0 tw-w-full">
        <ma-wave-break-line image="images/ma2024/ma-cta-line-break.svg" />
      </div>
    </section>

    <ma-bottom-cta-section
      :title="$t('ma_explore_online_read_more_title')"
      :description="$t('ma_explore_online_read_more_description')"
      :cta-button="$t('ma_explore_online_discover_more')"
      :cta-link="$langRedirect('/festivals/mid-autumn/familyfun', checkLang())"
      :image="$cdn('images/ma2024/explore-online/ma_explore_online_cta.png')"
    />
    <ap-footer />
  </div>
</template>
