<template>
  <div class="tw-flex tw-justify-around tw-gap-5 tablet:tw-gap-0">
    <a
      v-for="(btn,index) in socialBtn "
      :key="index"
      class="tw-py-5 tablet:tw-py-3 tablet:tw-px-10"
      :href="btn.url"
      target="_blank"
      rel="noopener"
    >
      <span
        class="socialBtn tw-py-4 tablet:tw-py-3 tw-px-[25px] tablet:tw-px-7 !tw-text-sg-body-bold-2 tw-rounded-[26px] tw-uppercase tw-whitespace-pre tablet:whitespace-normal
      tw-bg-[#8016B2] tw-text-white tw-flex
      hover:tw-bg-[#9945C1] hover:tw-text-white
      active:tw-bg-[#520E72] active:tw-text-white"
      >
        {{ btn.label }}
        <div class="tw-ml-2 tw-w-5 tw-h-5">
          <img :src="$cdn(btn.iconUrl)">
        </div>
      </span>
    </a>
  </div>
</template>
<script setup lang="ts">
// import { useI18n } from 'vue-i18n';
// const { t } = useI18n({ useScope: 'global' });

const socialBtn = [
  {
    id:1,
    label:'Facebook',
    iconUrl:'images/experience/ce/facebook_icon.png',
    url:'https://www.facebook.com/singaporechineseculturalcentre/posts/pfbid0uxZf6xNAG6qmX2dgmwoaaV82wy7LP7UqathC4tJuu3LgBFhS1MDFnjn4KowSogHxl',
  },
  {
    id:2,
    label:'Instagram',
    iconUrl:'images/experience/ce/instagram_icon.png',
    url:'https://www.instagram.com/singaporechineseculturalcentre/p/C5sNlj6qvr6/?img_index=1',
  }
];

</script>
