<template>
  <div>
    <!-- Section -->
    <template v-if="part==1">
      <div
        id="section-pin"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-4 tw-py-5 tw-bg-gradient-to-t tw-from-black tw-to-white
      tablet:tw-pt-10
      desktop:tw-pt-10"
      >
        <ap-video-player
          class="tw-z-20 tw-relative"
          :video-url="getLangVideo()"
        />
      </div>

      <div>
        <!-- Instruction 1 -->
        <ap-instruction-layout
          :instruction-no="1"
          :instruction-title="$t('gallery_respect_exhibition')"
          :instruction-content="$t('gallery_respect_content')"
          :instruction-content-list="instructionContentList"
          :instruction-bottom-note="$t('gallery_respect_note')"
          :image-url="galleryImage1"
        />
        <!-- Instruction 2 -->
        <ap-instruction-layout
          :instruction-no="2"
          :is-built-left="false"
          :instruction-title="$t('gallery_photography')"
          :instruction-content="$t('gallery_photography_content')"
          :instruction-bottom-note="$t('gallery_photography_note')"
          :image-url="galleryImage2"
        />
        <!-- Instruction 3 -->
        <ap-instruction-layout
          :instruction-no="3"
          :instruction-title="$t('gallery_accessibility')"
          :instruction-content="$t('gallery_accessibility_content')"
          instruction-bottom-note=""
          :image-url="galleryImage3"
        />
      </div>
    </template>
    <template v-if="part==2">
      <!-- Award Section -->
      <ap-award-layout class="tw-z-20 tw-bg-white tw-relative" />
      <div class="tw-z-20 tw-bg-white tw-relative tw-px-4 tw-py-5 tablet:tw-px-[40px] tablet:tw-py-[40px] desktop:tw-py-[60px] desktop:tw-px-20">
        <a
          class="tw-flex tw-flex-row tw-w-full tw-group tw-max-w-[1440px] tw-mr-auto"
          :href="redirectUrl()"
          target="_blank"
          rel="noopener"
        >
          <p
            class="tw-w-[65%] tw-pr-10 tw-text-sg-h1 tw-text-sg-sccc5-w20 tw-break-words group-hover:tw-text-sg-sccc5 tw-cursor-pointer tw-flex tw-items-center"
            :class="{'!tw-w-[40%]': checkLang() === 'zh-hans'}"
          >
            {{ $t('gallery_check_online_exhibition') }}
          </p>
          <div class="tw-flex tw-flex-col tw-justify-end  group-hover:!tw-translate-x-12 tw-duration-300 group-hover:-tw-translate-y-10 tw-cursor-pointer">
            <img
              :src="$cdn('icons/cta_top_right_red_arrow.svg')"
              alt="CTA Top Right Arrow"
            >
          </div>
        </a>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
import { ref } from 'vue';
export default {
  props: {
    part:{
      type:Number,
      required:true,
    },
    galleryImage1: {
      type: String,
      default: () => null,
    },
    galleryImage2: {
      type: String,
      default: () => null,
    },
    galleryImage3: {
      type: String,
      default: () => null,
    },
  },
  setup() {
    const { t, locale } = useI18n();

    function redirectUrl() {
      const url = 'https://singaporen.singaporeccc.org.sg/homepage?lang=en';
      const cnUrl = 'https://singaporen.singaporeccc.org.sg/homepage?lang=cn';
      if (checkLang() == 'en') {
        return url;
      } else {
        return cnUrl;
      }
    }

    const instructionContentList = [
      t('gallery_eat_drink'),
      t('gallery_smoking'),
      t('gallery_running_making_noise')
    ];

    const getLangVideo = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const langParam = urlParams.get('lang');

      const enVideoUrl = 'https://www.youtube.com/embed/mVs-mxNeSnA?si=Tba6yP1zHUsnvjhe';
      const cnVideoUrl = 'https://www.youtube.com/embed/S69_yqYVD-o?si=atal6q0J-PpW1_pl';

      return langParam === 'zh-hans' ? cnVideoUrl : enVideoUrl;
    };

    return {
      redirectUrl,
      instructionContentList,
      getLangVideo,
      checkLang,
    };
  },
  data() {

    return {
    };
  }
};
</script>
