<script lang="ts" setup>
import { ref, onMounted, nextTick, onBeforeUnmount, onBeforeMount} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen  = ref<boolean>(false);
const imageSourcesData = ref();
const videos_content   = ref<Video[]>([]);
const menuItems = [
  {
    label: 'ws_explore_online_menu_podcast',
    id   : 'ws_explore_online_menu_podcast'
  },
  {
    label: 'ws_explore_online_menu_video',
    id   : 'ws_explore_online_menu_video'
  },
  {
    label: 'ws_explore_online_menu_performance_by_reverberance',
    id   : 'ws_explore_online_menu_performance_by_reverberance'
  }
];

interface Video {
  title: string;
  description: string;
  external_link: string;
  youtube_facebook_embed_link: string;
  secondary_nav_menu_title: string;
}

onBeforeMount(()=>{
  if(props.wordpressData?.videos_content) {
    videos_content.value = props.wordpressData.videos_content;
  }
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

const pageBreakHeight1 = ref(0);
const pageBreakHeight2 = ref(0);
const pageBreakHeight3 = ref(0);
const pageBreak1 = ref<HTMLElement | null>(null);
const pageBreak2 = ref<HTMLElement | null>(null);
const pageBreak3 = ref<HTMLElement | null>(null);

function updatePageBreakHeight() {
  if (pageBreak1.value) {
    pageBreakHeight1.value = pageBreak1.value.getBoundingClientRect().height;
  }
  if (pageBreak2.value) {
    pageBreakHeight2.value = pageBreak2.value.getBoundingClientRect().height;
  }
  if (pageBreak3.value) {
    pageBreakHeight3.value = pageBreak3.value.getBoundingClientRect().height;
  }
}

onMounted(()=>{
  window.addEventListener('load', updatePageBreakHeight);
  nextTick(() => {
    // Add <sup> click listener
    const aboutDesc = document.getElementById('ws-explore-online');
    if(aboutDesc){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#ws-explore-online sup');
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
  pageBreak1.value = document.getElementById('page-break-1');
  pageBreak2.value = document.getElementById('page-break-2');
  pageBreak3.value = document.getElementById('page-break-3');
  updatePageBreakHeight();
  window.addEventListener('resize', updatePageBreakHeight);
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('ws-explore-online');
  window.removeEventListener('resize', updatePageBreakHeight);
  if(burningJossCard){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#ws-explore-online sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});
</script>

<template>
  <div
    class="tw-overflow-hidden"
    :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ws-navigation="true"
    />
    <ws-secondary-navigation
      :menu-items-data="menuItems"
      :not-translate="false"
    />

    <!-- Banner -->
    <section>
      <div class="tw-relative tw-tracking-wider">
        <picture>
          <div
            :style="{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${$cdn('images/ws2024/banner/ws-subpage-hero-banner.jpg')}) lightgray 50% / cover no-repeat`
            }"
            class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
          />
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div
            :class="[
              'tw-text-white tw-text-center',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            <div
              class="tw-text-sg-ws-mobile-h1 sgTablet:tw-text-sg-ws-tablet-h1 sgDesktop:tw-text-sg-ws-desktop-h1 tw-pb-1"
            >
              {{ $t('ws_explore_online_title') }}
            </div>
            <div
              class="tw-text-sg-ws-sh1 tw-mt-1"
            >
              {{ $t('ws_explore_online_subtitle') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Description -->
    <section
      class="
          tw-flex
          tw-flex-col
          tw-items-center
          tw-px-4
          tw-py-5
          sgTablet:tw-px-[80px]
          sgDesktop:tw-px-[120px]
          sgTablet:tw-py-10
        "
    >
      <div
        id="ws-explore-online"
        :class="[
          'tw-relative',
          'tw-text-sg-ws-body',
          'tw-max-w-[600px] tw-w-full tw-text-center',
        ]"
        v-html="wordpressData.description"
      />
    </section>

    <!-- Podcast Section -->
    <section
      id="ws_explore_online_menu_podcast"
      :class="[
        'tw-relative',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'tw-gap-10',
        // 'tw-pt-10 sgTablet:tw-pt-[60px] sgDesktop:tw-pt-[80px]',
        'tw-pb-5 sgTablet:tw-pb-[60px]',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]'
      ]"
      :style="{
        background: `linear-gradient(0deg, #FFF 0%, rgba(242, 222, 207, 0.90) 50%), url(${$cdn('images/ws2024/explore-online/bg.jpg')}) lightgray 50% / cover no-repeat`,
        paddingTop: `${pageBreakHeight1}px`
      }"
    >
      <img
        id="page-break-1"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-explore-online-page-break-1.png')"
      >
      <div
        :class="[
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-max-w-[800px] tw-text-center tw-pt-5',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_explore_online_title_1') }}
      </div>
      <div
        :class="[
          'tw-flex tw-flex-col sgTablet:tw-flex-row',
          'tw-max-w-[800px] tw-flex tw-gap-10',
          'tw-justify-center tw-items-center'
        ]"
      >
        <img
          class="tw-w-[268px]"
          style="box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.30)"
          :src="$cdn('images/ws2024/explore-online/intermission.jpg')"
        >
        <div
          :class="[
            'tw-flex tw-flex-col tw-items-center',
            'tw-text-sg-ws-body tw-gap-[60px]'
          ]"
        >
          <div
            id="sup-handler-container"
            class="tw-text-center sgTablet2:tw-text-left"
            v-html="$t('ws_explore_online_description_1_1')"
          />
          <div class="tw-flex tw-gap-10">
            <a
              href="https://spoti.fi/3pxmyvf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="tw-w-[52px] tw-h-[52px]"
                :src="$cdn('icons/ws/ws-podcast-button-green.svg')"
              >
            </a>
            <a
              href="https://apple.co/3JfGlac"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="tw-w-[52px] tw-h-[52px]"
                :src="$cdn('icons/ws/ws-apple-podcast-button.svg')"
              >
            </a>
            <a
              href="https://bit.ly/IntermissionEP8_AnchorFM"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="tw-w-[52px] tw-h-[52px]"
                :src="$cdn('icons/ws/ws-podcast-button-purple.svg')"
              >
            </a>
          </div>
        </div>
      </div>
    </section>

    <ws-explore-online-swiper
      id="ws_explore_online_menu_video"
      :items="wordpressData?.videos_content"
    />

    <!-- Performance by Reverberance -->
    <section
      id="ws_explore_online_menu_performance_by_reverberance"
      :class="[
        'tw-relative',
        'tw-flex tw-flex-col tw-items-center',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        // 'tw-pt-10 sgTablet:tw-pt-[80px] sgDesktop:tw-pt-[100px]',
        'tw-pb-10'
      ]"
      :style="{
        background: `linear-gradient(0deg, #F5E5D9 0%, rgba(255, 255, 255, 0.70) 49.5%, #F2DECF 100%)`,
        paddingTop: `${pageBreakHeight2}px`
      }"
    >

      <img
        id="page-break-2"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-home-page-break-2.png')"
      >
      <div
        :class="[
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
          'tw-w-full tw-max-w-[800px] tw-text-center tw-pb-2',
          'tw-pt-5 sgTablet:tw-pt-10',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_explore_online_title_2') }}
      </div>
      <div
        id="ws-explore-online"
        :class="[
          'tw-relative',
          'tw-text-sg-ws-body',
          'tw-max-w-[800px] tw-w-full tw-text-center',
          'tw-pb-10'
        ]"
      >
        {{ $t('ws_explore_online_description_2_1') }}
      </div>
      <iframe
        class="tw-max-w-[800px] tw-w-full tw-pb-4 tw-rounded-[8px]"
        style="aspect-ratio: 16 / 9;"
        src="https://www.youtube.com/embed/vG4Z538T6Yc?si=yzUVGR2yxhXhkGwW"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      <div
        :class="[
          'tw-text-sg-ws-sh1 tw-text-center tw-text-sg-ws24-maroon',
          'tw-pb-10',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_explore_online_video_title_1') }}
      </div>
      <iframe
        class="tw-max-w-[800px] tw-w-full tw-pb-4 tw-rounded-[8px]"
        style="aspect-ratio: 16 / 9;"
        src="https://www.youtube.com/embed/MGo9csbxJ_c?si=i_22J924zTnyaR8j"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      <div
        :class="[
          'tw-text-sg-ws-sh1 tw-text-center tw-text-sg-ws24-maroon',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        {{ $t('ws_explore_online_video_title_2') }}
      </div>
    </section>

    <section
      :class="[
        'tw-relative',
        'tw-flex tw-flex-col tw-items-center',
        'tw-bg-white',
        'tw-px-4 sgTablet:tw-px-[80px] sgDesktop:tw-px-[120px]',
        // 'tw-pt-10 sgTablet:tw-pt-[80px] sgDesktop:tw-pt-[100px]',
        'tw-pb-10'
      ]"
      :style="{
        paddingTop: `${pageBreakHeight3}px`
      }"
    >
      <img
        id="page-break-3"
        class="tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full"
        :src="$cdn('images/ws2024/page-break/ws-explore-online-page-break-2.png')"
      >
      <div
        :class="[
          'tw-max-w-[560px] tw-text-center',
          'tw-text-sg-ws-h3-mobile sgTablet:tw-text-sg-ws-h3-tablet sgDesktop:tw-text-sg-ws-h3-desktop tw-text-sg-ws24-maroon',
          'tw-pt-5 sgTablet:tw-pt-10',
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
        v-html="$t('ws_explore_online_cta_title')"
      />
      <div class="tw-flex tw-mt-8">
        <a
          :href="checkLang() === 'zh-hans'
            ? 'https://singaporeccc.org.sg/zh-hans/events/'
            : 'https://singaporeccc.org.sg/events/'"
          target="_blank"
          rel="noopener noreferrer"
          :class="[
            'tw-text-white tw-bg-sg-ws24-maroon',
            'tw-px-6 tw-py-4',
            'tw-text-sg-ma-bt1',
            'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
            'hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('ws_explore_online_discover_more') }}
        </a>
      </div>
    </section>
    <div class="tw-h-[25px]" />

    <ap-image-sources
      v-if="!!imageSourcesData && imageSourcesData?.length > 0"
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style scoped lang="scss">
.underline-anchor-tag a {
  text-decoration: underline;
}

#ws-explore-online sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}

#video-section-description:deep(a){
  text-decoration: underline !important;
  color: #1a1a1a !important;
}


</style>

