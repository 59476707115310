<template>
  <div
    ref="containerDiv"
    :class="[randomContainerId]"
    class="tw-flex tw-flex-col sgTablet2:tw-flex-row tw-justify-between"
  >

    <div
      :class="['tw-relative sgTablet2:tw-max-w-[40%] tw-py-4 sgTablet2:tw-py-5 sgDesktop:tw-py-10',
               'tw-px-4 sgTablet2:tw-pr-5 sgTablet2:tw-pl-10 sgDesktop:tw-pr-10 sgDesktop:tw-pl-20',
      ]"
    >
      <div :class="[randomId,'tw-space-y-5 sgTablet:tw-space-y-10 tw-sticky tw-top-[35%]']">
        <div
          class=" tw-max-w-none
    tw-prose
    !tw-font-akkurat
    tw-text-[#1A1A1A]
    prose-a:tw-underline prose-a:tw-text-[#0366D8]
    tw-text-sg-h3-mobile sgTablet:tw-text-sg-h3-tablet sgDesktop:tw-text-sg-h3 tw-tracking-wider
    "
          v-html="data?.title"
        />
        <div
          class="tw-max-w-none
    tw-prose
    !tw-font-akkurat
    tw-text-[#1A1A1A]
    prose-a:tw-underline prose-a:tw-text-[#0366D8]
    tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-tracking-wider"
          v-html="data?.subtitle"
        />

        <div
          class="tw-max-w-none
    tw-prose
    !tw-font-akkurat
    tw-text-[#1A1A1A]
    prose-a:tw-underline prose-a:tw-text-[#0366D8]
    tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-tracking-wider"
          v-html="data?.content"
        />

        <div
          class="
          tw-max-w-none
    tw-prose
    !tw-font-akkurat
    tw-text-[#1A1A1A]
    prose-a:tw-underline prose-a:tw-text-[#0366D8] tw-text-xs tw-tracking-wider"
          v-html="data?.caption"
        />
      </div>
    </div>

    <div
      :class="['tw-max-h-[100%] sgTablet2:tw-w-[60%]',
               'tw-bg-sg-sccc3-w60'
      ]"
    >

      <div class=" tw-my-5 sgTablet2:tw-my-10">
        <div class="tw-max-w-[500px] tw-mx-auto  tw-w-full tw-px-6">
          <div
            v-if="data?.video_url"
            class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40"
          >
            <iframe
              class="tw-rounded-sm tw-aspect-video tw-w-full"
              :src="data?.video_url"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div
            class="tw-italic tw-text-xs tw-tracking-wider tw-text-center tw-mt-5 sgTablet2:tw-mt-10"
            v-html="$t('values_jangandfox_video_caption')"
          />
        </div>

        <div class="tw-mt-10 sgTablet:tw-mt-20">
          <values-jangandfox-swiper class="tw-max-w-[376px] sgTablet:tw-max-w-[485px] tw-mx-auto tw-px-4" />
        </div>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeMount, Ref } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onBeforeUnmount } from 'vue';
defineProps({
  data: {
    type: Object,
    default: ()=>({})
  },
});

const randomId = ref('');
const randomContainerId = ref('');
// let innerWidth = ref(window.innerWidth);
let containerDiv: Ref<HTMLElement | null> = ref(null);

// Function to generate a random ID
function generateRandomId() {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  randomId.value = result;
  randomContainerId.value = 'container' + result;
}

function  handleResize() {
  const scrollIdOne = ScrollTrigger.getById(randomId.value);
  if(scrollIdOne) {
    scrollIdOne.kill();
  }
  let divWidth = 0;

  if (containerDiv.value) {
    divWidth = containerDiv.value.clientWidth;
  }

  // Mobile View no scrolling
  if (divWidth <= 800) {
    return;
  }

  gsap.to('.' + randomId.value, {
    scrollTrigger: {
      id: randomId.value,
      trigger: '.' + randomId.value,
      endTrigger: '.' + randomContainerId.value,
      start: 'top 30%',
      end: 'bottom 33%+=' + (document.querySelector('.' + randomId.value) as HTMLElement).offsetHeight + 'px',
      toggleActions: 'restart none none none',
      markers: false,
      // {
      //   startColor: 'green',
      //   endColor: 'red',
      //   fontSize: '12px',
      //   indent: 20,
      // },
      pin: true,
      pinSpacing: false,
      invalidateOnRefresh: true,
      preventOverlaps: true,
      fastScrollEnd: false,
    }
  });
}

onBeforeMount(() => {
  generateRandomId();
});

onMounted(() => {
  // gsap.registerPlugin(ScrollTrigger);
  // setTimeout(handleResize,300);
  // window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  // window.removeEventListener('resize', handleResize);
});

</script>
