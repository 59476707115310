<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, onMounted, nextTick } from 'vue';
import { ref} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();

const imageSourceOpen  = ref<boolean>(false);
const imageSourcesData = ref();
const section2TextRef  = ref<HTMLParagraphElement | null>(null);
const section3TextRef  = ref<HTMLParagraphElement | null>(null);
const section2ImgRef   = ref<HTMLDivElement | null>(null);
const section3ImgRef   = ref<HTMLDivElement | null>(null);
const findOutMoreLink  = props.wordpressData?.find_out_more_external_link;

const menuItems = [
  {
    label: 'zy_origin_stories_menu_taoism',
    id   : 'origin-stories-taoism'
  },
  {
    label: 'zy_origin_stories_menu_buddhism',
    id   : 'origin-stories-buddhism'
  }
];



const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onBeforeMount(()=>{
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

onMounted(() => {

  recalculateHeight();
  window.addEventListener('resize', recalculateHeight);
  nextTick(() => {

    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };


      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});
onBeforeUnmount(()=>{
  window.removeEventListener('resize', recalculateHeight);
  // Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

function recalculateHeight(){
  requestAnimationFrame(()=>{

    if(section2TextRef.value){
      let height = section2TextRef.value.getBoundingClientRect().height;
      height = height > 300 ? height : 300;
      section2ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }

    if(section3TextRef.value){
      let height = section3TextRef.value.getBoundingClientRect().height;
      height = height > 300 ? height : 300;
      section3ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }
  });
}
</script>

<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :zy-navigation="true"
    />
    <zy-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- Banner -->
    <section class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/zy2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            class="tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 tw-pb-1"
          >
            {{ $t('zy_origin_stories_title') }}
          </div>
        </div>
      </div>
      <zy-paper-tear />
    </section>

    <!-- description -->
    <section
      id="origin-stories-section"
      class="
          tw-relative
          tw-flex
          tw-justify-center
          tw-tracking-wider
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          sgDesktop:tw-py-[80px]
          sgTablet:tw-py-[60px]
          tw-py-10
        "
    >
      <div class="tw-flex tw-flex-col tw-gap-6 tw-max-w-[600px]">
        <!-- section 1 -->
        <div
          class="tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-6"
          style="align-items: center;"
        >
          <!-- left side -->
          <img
            class="tw-max-w-[288px] tw-h-[388px]"
            :style="{ filter: 'drop-shadow(-4px 4px 2px rgba(0, 0, 0, 0.3))' }"
            :src="$cdn('images/zy2024/origin-stories/origin-stories.scaled.png')"
          >
          <!-- right side -->
          <div
            class="tw-flex tw-flex-col tw-gap-10"
            style="align-items: center;"
          >
            <!-- text -->
            <div
              id="sup-handler-container"
              class="tw-text-sg-ref-desktop-body"
              v-html="$t('zy_origin_stories_section_1_desc_1')"
            />

            <!-- button -->
            <div class="tw-flex">
              <a
                :href="findOutMoreLink"
                target="_blank"
                rel="noopener"
                class="
                    tw-rounded-[26px]
                    tw-text-white
                    tw-bg-sg-zy24-brown
                    tw-px-6 tw-py-4
                    tw-text-sg-button-mobile-1
                    tablet:tw-text-sg-button-1
                    !tw-leading-[20px]
                    tw-h-[52px] tw-uppercase
                    hover:tw-bg-[#C9705A]
                    active:tw-bg-[#963D27]
                  "
              >
                {{ $t('zy_origin_stories_find_out_more') }}
              </a>
            </div>
          </div>
        </div>
        <div
          class="tw-text-sg-ref-desktop-body"
        >
          <div
            id="sup-handler-container"
            class="tw-text-center"
            v-html="$t('zy_origin_stories_section_1_desc_2')"
          />
        </div>
      </div>
      <zy-paper-tear />
    </section>

    <!-- taoism section -->
    <section
      id="origin-stories-taoism"
      class="
          tw-flex
          tw-justify-center
          tw-bg-sg-zy24-pink
          tw-tracking-wider
          tw-relative
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-py-10
          sgTablet:tw-py-[60px]
          sgDesktop:tw-pb-[80px]
        "
    >
      <div class="tw-flex tw-flex-col tw-gap-6 tw-max-w-[600px]">
        <!-- title -->
        <div
          :class="
            [
              'tw-w-full tw-text-center tw-text-sg-zy-h2-mobile sgTablet:tw-text-sg-zy-h2-tablet sgDesktop:tw-text-sg-zy-h2-desktop',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]
          "
        >
          {{ $t('zy_origin_stories_taoism_title') }}
        </div>
        <!-- description -->
        <div
          class="tw-text-sg-ref-desktop-body"
          v-html="$t('zy_origin_stories_taoism_description_1')"
        />
        <!-- image -->
        <div
          class="tw-flex tw-flex-row tw-justify-center tw-gap-7 sgTablet:tw-gap-[60px]"
        >
          <!-- person 1 -->
          <div class="tw-flex tw-flex-col tw-items-center">
            <!-- person 1 image -->
            <img
              class="sgTablet:tw-max-w-[140px] sgTablet:tw-h-[186px] tw-pb-3"
              :src="$cdn('images/zy2024/origin-stories/tian-guan-da-di.scaled.png')"
            >
            <!-- person 1 name -->
            <div
              id="sup-handler-container"
              class="tw-text-sg-ref-desktop-bold tw-max-w-[100px] tw-text-center"
              v-html="$t('zy_origin_stories_taoism_person_name_1')"
            />

          </div>

          <!-- person 2 -->
          <div class="tw-flex tw-flex-col tw-items-center">
            <!-- person 2 image -->
            <img
              class="sgTablet:tw-max-w-[140px] sgTablet:tw-h-[186px] tw-pb-3"
              :src="$cdn('images/zy2024/origin-stories/di-guan-da-di.scaled.png')"
            >
            <!-- person 2 name -->
            <div
              id="sup-handler-container"
              class="tw-text-sg-ref-desktop-bold tw-max-w-[100px] tw-text-center"
              v-html="$t('zy_origin_stories_taoism_person_name_2')"
            />
          </div>

          <!-- person 3 -->
          <div class="tw-flex tw-flex-col tw-items-center">
            <!-- person 3 image -->
            <img
              class="sgTablet:tw-max-w-[140px] sgTablet:tw-h-[186px] tw-pb-3"
              :src="$cdn('images/zy2024/origin-stories/shui-guan-da-di.scaled.png')"
            >
            <!-- person 3 name -->
            <div
              id="sup-handler-container"
              class="tw-text-sg-ref-desktop-bold  tw-max-w-[100px] tw-text-center"
              v-html="$t('zy_origin_stories_taoism_person_name_3')"
            />
          </div>
        </div>
        <!-- description -->
        <div
          class="tw-text-sg-ref-desktop-body"
        >
          {{ $t('zy_origin_stories_taoism_description_2') }}
        </div>

      </div>
      <zy-paper-tear />
    </section>

    <!-- buddhism section -->
    <section
      id="origin-stories-buddhism"
      class="
          tw-relative
          tw-flex
          tw-justify-center
          tw-items-center
          tw-font-akkurat
          tw-tracking-wider
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-py-10
          sgTablet:tw-py-[60px]
          sgDesktop:tw-pb-[80px]
        "
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-6 tw-max-w-[600px]">
        <!-- title -->
        <div
          :class="[
            'tw-w-full tw-text-center tw-text-sg-zy-h2-mobile sgTablet:tw-text-sg-zy-h2-tablet sgDesktop:tw-text-sg-zy-h2-desktop',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('zy_origin_stories_buddhism_title') }}
        </div>
        <!-- description -->
        <div
          class="tw-text-sg-ref-desktop-body"
        >
          {{ $t('zy_origin_stories_buddhism_description_1') }}
        </div>
        <div
          class="tw-text-sg-ref-desktop-body"
        >
          {{ $t('zy_origin_stories_buddhism_description_2') }}
        </div>

        <div
          class="tw-relative tw-w-[352px] tw-shadow-lg tw-bg-[#FFDD63] tw-rounded-md"
        >
          <!-- Background Image -->
          <img
            class="tw-w-full tw-object-cover tw-h-[316px] tw-rounded-sm"
            :src="$cdn('images/zy2024/home/tile_background.scaled.png')"
          >
          <!-- Content -->
          <div class="tw-absolute tw-inset-0 tw-flex-col tw-p-4">

            <div class="tw-flex tw-items-center tw-pb-[20px]">
              <img
                class="tw-object-cover tw-h-full"
                :src="$cdn('images/zy2024/origin-stories/monk-smile.scaled.png')"
              >
            </div>
            <div class="tw-text-sg-caption-desktop-1 tw-font-hanzipan">
              {{ $t('zy_origin_stories_buddhism_caption_1') }}
              <sup
                class="tw-cursor-pointer tw-text-[#0366D8] tw-text-tw-text-sg-ref-desktop-body  tw-font-akkurat"
                @click="handleOpenImageSourceModal"
              >[A]</sup>
            </div>
          </div>
        </div>
      </div>
      <zy-paper-tear />
    </section>

    <section
      class="
        tw-flex
        tw-justify-center
        tw-bg-sg-zy24-pink
        tw-items-center
        tw-font-akkurat
        tw-tracking-wider
        tw-px-4
        sgTablet:tw-px-5
        sgDesktop:tw-px-10
        tw-gap-8
        sgDesktop:tw-py-[80px]
        tw-pt-[60px] tw-pb-10
      "
    >
      <div class="tw-hidden sgTablet:tw-block">
        <!-- left side -->
        <img
          class="tw-max-w-[140px] tw-h-[140px]"
          :src="$cdn('images/zy2024/orange.png')"
        >
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6 tw-max-w-[600px] tw-px-4 sgTablet:tw-px-0">
        <!-- center side -->
        <div
          class="tw-text-sg-ref-desktop-body"
        >
          {{ $t('zy_origin_stories_outro_description_1') }}
        </div>
        <div
          class="tw-text-sg-ref-desktop-body"
        >
          {{ $t('zy_origin_stories_outro_description_2') }}
        </div>
      </div>
      <div class="tw-hidden sgTablet:tw-block">
        <!-- right side -->
        <img
          class="tw-max-w-[140px] tw-h-[140px]"
          :src="$cdn('images/zy2024/flag.png')"
        >
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style scoped>
#sup-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}

#sup-handler-container:deep(a) {
  cursor: pointer;
  text-decoration: underline;
}
</style>
