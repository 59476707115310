<template>
  <div class="tw-h-[100vh] tw-flex tw-flex-col tw-justify-center">
    <iframe
      class="tw-border-4 tw-border-[#00D6C6] tw-border-solid tw-rounded-lg tw-w-[342px] tw-h-[192px]
      tablet:tw-h-[361px] tablet:tw-w-[643px]
      desktop:tw-h-[300px] desktop:tw-w-[534px]"
      :src="videoUrls"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</template>

<script lang="ts">
export default {
  props: {
    videoUrl: {
      type: String,
      default:  'https://www.youtube.com/embed/mVs-mxNeSnA?si=o20Sx7ywyfJXzR2a',
    }
  },
  data() {
    return {
      videoUrls: this.videoUrl
    };
  }
};
</script>
