<template>
  <div
    class="videoCard tw-w-[164px] sgTablet:tw-w-[240px] sgDesktop:tw-w-[260px] tw-h-auto tw-rounded"
    @click="goToVideoDetailPage(props.video.permalink)"
  >
    <div class="tw-relative">
      <div
        class="videoThumbnail tw-flex tw-relative tw-h-[92px] tablet:tw-h-[146px] tw-overflow-hidden tw-rounded-t tw-bg-cover hover:tw-cursor-pointer"
        :style="{ backgroundImage: `url(${props.video.acf.video_gallery[0].url})` }"
      >
        <div
          class="playIcon tw-z-[10] tw-absolute tw-w-[60px] tw-h-[60px] tw-bg-white tw-rounded-full tw-top-[20%] tw-left-[35%] tablet:tw-top-[30%] tablet:tw-left-[40%]"
        >
          <inline-svg
            :src="$cdn('icons/play_icon.svg')"
            alt="Play Icon"
            class="tw-pl-[6px] tw-w-auto tw-h-[21px] tw-top-[30%] tw-left-[30%] tw-fill-[#484848] group-hover:tw-fill-black tw-absolute"
            :style="{ transition: 'fill 0.3s linear' }"
          />
        </div>
      </div>
      <div class="findoutText tw-w-full tw-justify-center tw-py-3 tw-px-4 tw-hidden tw-absolute tw-top-[20%] tablet:tw-top-[30%]">
        <button class="tw-py-2 tw-pl-3 !tw-text-sg-label-5 tw-uppercase tw-text-white">
          <span class="swiper-btn-text tw-flex tw-whitespace-pre tw-items-center tw-underline">
            {{ t('ce_swiper_btn') }}<img
              class="tw-h-3 tw-w-3 tw-ml-2 tw-invert tw-brightness-0"
              :src="$cdn('images/experience/ce/arrow_forward_ios.png')"
            >
          </span>
        </button>
      </div>
    </div>

    <div class="tw-py-2 tablet:tw-py-3 tw-px-2 tablet:tw-px-4 tw-bg-white tw-rounded-b">
      <p class="tw-text-sg-sh3-mobile-ce tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3 tw-h-[60px] tablet:tw-h-[40px] md:tw-line-clamp-2">
        {{ props.video.title }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue';
import { Video } from '../../../types/types';
import { langRedirect } from '../../../support/langRedirect';
import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });

const props = defineProps({
  video: {
    type: Object as PropType<Video>,
    required: true,
  },
});

function goToVideoDetailPage(url: string) {
  let newUrl = url.split('?')[0];
  window.location.href = langRedirect(newUrl);
}
</script>
<style>
.videoCard {
  box-shadow: 0px 4px 8px 0px #0000000f, 0px 0px 4px 0px #0000000a;
}

.videoCard:hover .videoThumbnail {
  filter: brightness(80%);
}

.videoCard:hover .playIcon {
  display: none;
}

.videoCard:hover .findoutText {
  display: flex;
}
</style>
