<template>
  <div
    class="tw-overflow-x-hidden"
    :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
  >
    <swiper
      id="observation_card"
      ref="observationCardSwiper"
      :space-between="20"
      :pagination="{
        el:'.observation-swiper-pagination',
        clickable:true,
      }"
      :slides-per-view="'auto'"
      :slides-per-group="1"
      class="observation_card_swiper tw-w-full tw-max-w-[100%] tw-overflow-hidden"
      centered-slides

      centered-slides-bounds
      :breakpoints="{
        1025:{
          centeredSlides:false,
          centeredSlidesBounds:false,
        }
      }"
      @slide-change-transition-end="checkSwiper"
    >
      <swiper-slide
        v-for="(item, index) in props.data"
        :key="index"
        class="tw-max-w-[280px] sgDesktop:tw-max-w-[320px] tw-relative sgDesktop:tw-pb-10"
      >
        <img
          v-if="item.is_singaporen_way"
          class="tw-absolute -tw-top-5 -tw-left-5 tw-w-[100px] tw-h-[72px]  tw-z-20"
          :src="$cdn(checkLang() !== 'zh-hans' ? 'icons/singaporen_way_stamp-4.png' : 'icons/singaporen_way_stamp_cn-4.png')"
        >

        <div
          class="tw-bg-ap-white tw-flex tw-flex-col tw-items-center tw-pb-20 tw-h-[488px] sgTablet:tw-h-[516px] sgTablet:tw-py-10 sgTablet:tw-px-5 tw-px-4 tw-py-4"
          :class="{'!tw-h-[320px] sgTablet:!tw-h-[320px]': checkLang() == 'zh-hans'}"
        >
          <div>
            <!-- Numbering -->
            <div
              class="tw-w-[52px] tw-h-[52px] sgTablet:tw-w-[52px] sgTablet:tw-h-[52px] sgDesktop:tw-w-[60px] sgDesktop:tw-h-[60px] tw-rounded-full tw-bg-sg-qm24-green-b60 tw-text-ap-white  tw-text-sg-h4-mobile tablet:tw-text-sg-h4-tablet desktop:tw-text-sg-h4 tw-flex tw-justify-col tw-justify-center tw-items-center tw-text-center"
            >
              {{ internationaliseNumber(index + 1) }}
            </div>
          </div>
          <div class="tw-pt-5">
            <p
              class="tw-text-sg-body-1 tw-text-sg-qm24-green-b60 tw-text-left"
              

              style="font-size: 16px !important; line-height: 24px !important; letter-spacing: 0px !important; "
            >
              {{ item.description }}
            </p>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="observation-swiper-pagination tw-flex tw-h-20 tw-mx-auto sgDesktop:!tw-hidden" />

  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { Pagination, Mousewheel} from 'swiper/modules';
import SwiperCore from 'swiper';
import { onMounted, ref, Ref } from 'vue';
import { checkLang } from '../../support/helper';

SwiperCore.use([Pagination, Mousewheel]);

const datas: Ref<Array<any>> = ref([]);
const observationCardSwiper = ref(null);

interface ObservationCard {
  description: string;
  is_singaporen_way: boolean;
}

const props = defineProps<{
  data: ObservationCard[]
}>();

onMounted(() => {
  // datas.value = [
  //   {
  //     content: 'When visiting the cemetery to clean a tomb, this could involve removing weeds or debris, wiping down the headstone, and ensuring the area around the grave is clean. It’s also customary to bring food offerings and/or flowers as a way of paying respects to the deceased. The act of visiting a tomb to clean and present offerings is to honour the memory of the deceased and demonstrate a sense of care and respect for their final resting place.',
  //     isSingaporenWay: false,
  //   },
  //   {
  //     content: 'Remembrance of loved ones is also observed at home on Qingming day by those who practise ancestor worship.',
  //     isSingaporenWay: false,
  //   },
  //   {
  //     content: 'As cremation becomes more widespread due to land-scarcity, visiting the cemetery will increasingly be replaced by visitation to the columbarium.',
  //     isSingaporenWay: true,
  //   },
  //   {
  //     content: 'When visiting the cemetery to clean a tomb, this could involve removing weeds or debris, wiping down the headstone, and ensuring the area around the grave is clean. It’s also customary to bring food offerings and/or flowers as a way of paying respects to the deceased. The act of visiting a tomb to clean and present offerings is to honour the memory of the deceased and demonstrate a sense of care and respect for their final resting place.',
  //     isSingaporenWay: false,
  //   },
  //   {
  //     content: 'Remembrance of loved ones is also observed at home on Qingming day by those who practise ancestor worship.',
  //     isSingaporenWay: false,
  //   },
  //   {
  //     content: 'As cremation becomes more widespread due to land-scarcity, visiting the cemetery will increasingly be replaced by visitation to the columbarium.',
  //     isSingaporenWay: true,
  //   }
  // ];
});

const checkSwiper = (swiper:any) => {
  //console.log('check swiper', swiper);
};

const internationaliseNumber = (number: number) => {
  let res;
  res = number;
  if (checkLang() === 'zh-hans') {
    switch (number) {
      case 1:
        res = '一';
        break;
      case 2:
        res = '二';
        break;
      case 3:
        res = '三';
        break;
      case 4:
        res = '四';
        break;
      case 5:
        res = '五';
        break;
      case 6:
        res = '六';
        break;
      case 7:
        res = '七';
        break;
      case 8:
        res = '八';
        break;
      case 9:
        res = '九';
        break;
      case 10:
        res = '十';
        break;

    }
  }
  return res;
};

</script>

<style lang="scss">
.observation-swiper-pagination {
  padding-top: 32px;
  justify-content: center;
  display: flex;
}

.observation-swiper-pagination .swiper-pagination-bullet {
  @apply tw-w-3 tw-h-3;
}

.observation-swiper-pagination .swiper-pagination-bullet-active {
  @apply tw-bg-ap-dim-grey #{!important};
  @apply tw-w-3 tw-h-3;
}

#observation_card .swiper-wrapper .swiper-slide {
  position: relative;
  @apply first:tw-ml-4 last:tw-mr-4 sgTablet:first:tw-ml-10 sgTablet:last:tw-mr-10 sgDesktop:first:tw-ml-[calc((100vw-800px)/2)] sgDesktop:last:tw-mr-[calc((100vw-800px)/2)];
}

#observation_card .swiper-wrapper {
  padding-top: 36px;
}



</style>
