<template>
  <div class="header-container">
    <!-- Inner -->
    <div
      ref="inner"
      class="inner"
    />
    <img
      v-if="$props.festivalsData.is_locked"
      :src="$cdn('icons/calendar-locked.svg')"
      class="locked-overlay tw-text-[120px]"
    >
    <!-- Content -->
    <div
      class="tw-absolute tw-w-full tw-max-h-[600px] tw-flex tw-flex-col tw-bottom-0 tw-left-0 tw-px-4 tw-py-5
      tablet:tw-p-10
      desktop:tw-px-20 desktop:tw-py-10"
    >
      <p class="text-shadow tw-text-white tw-pb-5 tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">
        {{ $props.festivalsData.title }}
      </p>
      <p class="text-shadow tw-text-white tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1">
        {{ $props.festivalsData.description }}
      </p>
      <div class="tw-pb-10" />
      <!-- Button -->
      <a
        v-if="!$props.festivalsData.is_locked"
        :href="$props.festivalsData.explore_url"
        target="_blank"
        rel="noopener"
        class="button-explore tw-text-sg-button-1 tw-text-sg-sccc1"
      >
        {{ $t('button.explore') }}
        <i class="tw-pl-2">
          <img
            :src="$cdn('icons/arrow_forward_ios.svg')"
          >
        </i>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref } from 'vue';

interface Festival {
  title: string;
  description: string;
  image: string;
  explore_url: string;
  is_locked: boolean;
}

export default {
  props: {
    festivalsData: {
      type: Object as () => Festival,
      required: true
    }
  },
  setup(props) {
    const inner: Ref<HTMLElement | null> = ref(null);

    onMounted(() => {
      if (inner.value) {
        inner.value.style.backgroundImage = props.festivalsData.is_locked ?
          `linear-gradient(0deg, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(${props.festivalsData.image})` :
          `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${props.festivalsData.image})`;
      }
    });

    return {
      inner
    };
  },
};
</script>

<style scoped>
.header-container {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(https://picsum.photos/1200?random=2); /*Default image*/
  background-position: center center;
  background-repeat: no-repeat;
}

.locked-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.text-shadow {
  text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
}

.button-explore {
  background-color: white;
  border: none;
  color: #8C12D4;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 8px 24px;
  cursor: pointer;
  border-radius: 26px;
  max-width: 134px;
  height: 52px;
}
</style>
