<template>
  <section class="tw-py-10">
    <swiper
      :slides-per-view="'auto'"
      :space-between="40"
      :centered-slides="true"
      class="mySwiper"
      @swiper="onSwiper"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
        :style="`width: ${cardWidth};`"
      >
        <ws-explore-online-card
          :data="item"
          :card-width="cardWidth"
        />
        <div class="swiper-lazy-preloader" />
      </swiper-slide>
    </swiper>
    <div
      :class="[
        'tw-flex tw-justify-center tw-items-center tw-gap-[60px]',
        'tw-pt-10',
      ]"
    >
      <div
        :class="[
          'tw-flex',
          'tw-bg-[#9B2F3A] hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
          'tw-h-[56px] tw-w-[56px]',
          'tw-text-white',
          'tw-rounded-full',
          { 'tw-bg-[#CC959A] hover:tw-bg-[#CC959A] active:tw-bg-[#CC959A] tw-opacity-[0.25]' : leftButtonDisabled },
          { 'tw-cursor-pointer' : !leftButtonDisabled}
        ]"
        @click="swiperInstance?.slidePrev()"
      >
        <img :src="$cdn('icons/ma/ma_chevron_left.svg')">
      </div>
      <div class="tw-text-sg-ws-body">
        {{ currentSlideIndex + 1 }} / {{ items.length }}
      </div>
      <div
        :class="[
          'tw-flex',
          'tw-bg-[#9B2F3A] hover:tw-bg-[#AF5961] active:tw-bg-[#7C262E]',
          'tw-h-[56px] tw-w-[56px]',
          'tw-text-white',
          'tw-rounded-full',
          { 'tw-bg-[#CC959A] hover:tw-bg-[#CC959A] active:tw-bg-[#CC959A] tw-opacity-[0.25]' : rightButtonDisabled },
          { 'tw-cursor-pointer' : !rightButtonDisabled}
        ]"
        @click="swiperInstance?.slideNext()"
      >
        <img :src="$cdn('icons/ma/ma_chevron_right.svg')">
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInstance } from 'swiper';
import 'swiper/css';
import { onBeforeUnmount, onMounted, ref } from 'vue';

interface Item {
  youtube_facebook_embed_link: string;
  title: string;
  description: string;
}

const leftButtonDisabled = ref(true);
const rightButtonDisabled = ref(false);
const currentSlideIndex = ref(0);

const props = defineProps<{
  items: Item[];
}>();

const onSlideChange = () => {
  if (swiperInstance?.value) {
    currentSlideIndex.value = swiperInstance?.value.realIndex;
    leftButtonDisabled.value = swiperInstance?.value.isBeginning;
    rightButtonDisabled.value = swiperInstance?.value.isEnd;
  }
};

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};

const cardWidth = ref('570px');

function resize() {
  if (window.innerWidth < 680) {
    cardWidth.value = '90%';
  } else {
    cardWidth.value = '570px';
  }
}

onMounted(() => {
  resize();
  window.addEventListener('resize', resize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});

</script>
