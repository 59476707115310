<script lang="ts" setup>
import { cdn } from '../../support/cdn';
import { useI18n } from 'vue-i18n';
import { ref, onMounted, nextTick, onBeforeUnmount, onBeforeMount } from 'vue';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  wordpressData: any;
}>();
const { t } = useI18n();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref();
const videos_content = ref<Video[]>([]);

const menuItems = ref<any>([]);

interface Video {
  title: string;
  description: string;
  external_link: string;
  youtube_facebook_embed_link: string;
  secondary_nav_menu_title: string;
}

onBeforeMount(() => {
  if (props.wordpressData?.videos_content) {
    videos_content.value = props.wordpressData.videos_content;
    console.log('videos_content', videos_content.value);
    menuItems.value = videos_content.value.map((x, index) => {
      return {
        label: x?.secondary_nav_menu_title ?? '',
        id: 'video_section_' + index,
      };
    });
  }
  if (props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});
onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const aboutDesc = document.getElementById('dw-explore-online');
    if (aboutDesc) {
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#dw-explore-online sup');
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('dw-explore-online');

  if (burningJossCard) {
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#dw-explore-online sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :dw-navigation="true"
    />
    <dw-secondary-navigation
      :menu-items-data="menuItems"
      :not-translate="true"
    />

    <section id="dw-explore-about">
      <div class="tw-relative">
        <picture>
          <img
            :src="$cdn('images/dw2024/banner.scaled.jpg')"
            class="tw-w-full tw-object-cover tw-max-h-40 sgTablet:tw-max-h-60 sgDesktop:tw-max-h-[280px]"
          >
        </picture>

        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              :class="
                checkLang() == 'zh-hans'
                  ? 'tw-text-sg-h1-mobile-dw-about-title-zh sgTablet:tw-text-sg-h1-tablet-dw-about-title-zh sgDesktop:tw-text-sg-h1-dw-about-title-zh tw-pb-1 tw-font-notosansbold'
                  : 'tw-text-sg-h1-mobile-dw-about-title sgTablet:tw-text-sg-h1-tablet-dw-about-title sgDesktop:tw-text-sg-h1-dw-about-title tw-pb-1  tw-font-akkuratbold'
              "
            >
              {{ $t('dw_explore_online_title') }}
            </div>
            <div
              :class="
                checkLang() == 'zh-hans'
                  ? 'tw-text-sg-sh1-mobile-dw-zh sgTablet:tw-text-sg-sh1-tablet-dw-zh sgDesktop:tw-text-sg-sh1-dw-zh tw-pb-1 tw-font-notosansbold'
                  : 'tw-text-sg-sh1-mobile-dw sgTablet:tw-text-sg-sh1-tablet-dw sgDesktop:tw-text-sg-sh1-dw tw-mt-1 tw-font-akkuratbold'
              "
            >
              {{ $t('dw_explore_online_subtitle') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      id="dw-explore-online"
      class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-py-5 sgTablet:tw-py-10 sgTablet:tw-px-10 sgDesktop:tw-px-0 tw-px-4 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
      v-html="wordpressData.description"
    />

    <div
      v-for="(row, index) of videos_content"
      :id="'video_section_' + index"
      :key="index + 'video'"
      class="tw-flex tw-flex-col sgTablet2:tw-flex-row tw-bg-sg-dw24-blue-w60 tw-px-4 sgTablet:tw-px-0 sgDesktop2:tw-px-20 tw-justify-between"
      :class="{
        'sgTablet2:tw-flex-row-reverse': index % 2 == 1,
        '!tw-bg-sg-dw24-blue-w40': index == 1,
        '!tw-bg-sg-dw24-blue-w20': index == 2,
        '!tw-bg-sg-dw24-blue': index == 3,
      }"
    >
      <!-- Video -->
      <div
        class="tw-flex tw-flex-col tw-justify-center sgTablet2:tw-w-[60%] sgDesktop:tw-pl-20 sgDesktop:tw-pr-10 sgTablet2:tw-pl-10 sgTablet2:tw-pr-5 tw-py-[69px] sgTablet:tw-py-[61px] sgTablet:tw-px-[58px] sgDesktop2:tw-py-[128px] sgDesktop:tw-py-[201px]"
        :class="{ 'sgDesktop:!tw-pl-10 sgDesktop:!tw-pr-20 sgTablet2:!tw-pl-5 sgTablet2:!tw-pr-10 ': index % 2 == 1 }"
      >
        <!-- tw-aspect-video -->

        <iframe
          class="tw-mx-auto sgDesktop:tw-w-[50vw] sgDesktop2:tw-w-[45vw] sgTablet2:tw-w-[50vw] sgTablet:tw-w-[80vw] tw-w-[88vw] tw-rounded-lg tw-aspect-video"
          :src="row.youtube_facebook_embed_link"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>

      <!-- Context -->
      <div
        class="tw-flex tw-flex-col sgTablet2:tw-w-[40%] sgDesktop:tw-pl-10 sgDesktop:tw-pr-20 sgTablet2:tw-pl-5 sgTablet2:tw-pr-10 tw-py-5 sgTablet:tw-py-10 sgTablet:tw-px-10 tw-justify-center"
        :class="{ 'sgDesktop:!tw-pl-20 sgDesktop:!tw-pr-10 sgTablet2:!tw-pl-10 sgTablet2:!tw-pr-5': index % 2 == 1 }"
      >
        <h3
          :class="
            checkLang() == 'zh-hans'
              ? ' tw-text-[#1A1A1A] tw-text-sg-h3-mobile-dw-title-zh sgTablet:tw-text-sg-h3-tablet-dw-title-zh sgDesktop:tw-text-sg-h3-dw-title-zh tw-font-notosansbold'
              : 'tw-text-[#1A1A1A] tw-text-sg-h3-mobile-dw-title sgTablet:tw-text-sg-h3-tablet-dw-title sgDesktop:tw-sg-dw-title tw-font-akkuratbold'
          "
        >
          {{ row.title }}
        </h3>
        <div
          :class="
            checkLang() == 'zh-hans'
              ? 'sgDesktop:tw-mt-10 tw-mt-5 tw-text-[#1A1A1A] tw-text-sg-body-1-mobile-dw-zh sgTablet:tw-text-sg-body-1-tablet-dw-zh sgDesktop:tw-text-sg-body-1-tablet-dw-zh tw-font-notosans underline-anchor-tag'
              : 'sgDesktop:tw-mt-10 tw-mt-5 tw-text-[#1A1A1A] tw-text-sg-body-1-mobile-dw sgTablet:tw-text-sg-body-1-tablet-dw  sgDesktop:tw-text-sg-body-1-tablet-dw tw-font-akkurat underline-anchor-tag'
          "
          v-html="row.description"
        />
      </div>
    </div>

    <ap-image-sources
      v-if="!!imageSourcesData && imageSourcesData?.length > 0"
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style lang="scss">
.underline-anchor-tag a {
  text-decoration: underline;
}

#dw-explore-online sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}
</style>
