<template>
  <div
    class="tw-fixed tw-right-10 tw-bottom-[5vh] tw-z-[88] second-nav-container"
  >
    <TransitionGroup
      tag="div"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @leave="onLeave"
    >
      <div
        v-for="(item, index) in menuItems"
        :key="'item_' + index"
        :data-index="index"
        class="tw-text-sg-body-2 tw-cursor-pointer tw-align-middle tw-px-4 tw-flex tw-justify-center tw-flex-col tw-relative tw-group tw-rounded-[20px] tw-border-2 tw-border-sg-qm25-green-b60 tw-text-sg-qm25-green-b60 tw-bg-ap-white tw-text-center tw-mb-2 last-of-type:tw-mb-4 !tw-h-[34px] tw-uppercase"
        :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
        :style="{ minWidth: `${maxItemWidth}px` }"
        @click="handleClick(item?.id)"
      >
        {{ notTranslate == true ? item.label: $t(item.label) }}
        <div
          class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-sg-qm25-green-b60 tw-bg-opacity-50
        tw-hidden group-hover:tw-flex tw-rounded-xl"
        />
      </div>
    </TransitionGroup>

    <div class="tw-flex tw-flex-row tw-justify-end tw-p-[10px]">
      <div
        v-show="!isButtonOpen"
        class=""
        @click="handleToggleButton"
      >
        <img
          :src="$cdn('icons/qm/secondary_navigation_handle_entry.png')"
          class="tw-w-[60px] tw-flex tw-cursor-pointer"
          alt="Navigation Handle Entry"
        >
      </div>

      <div
        v-show="isButtonOpen"
        @click="handleToggleButton"
      >
        <img
          :src="$cdn('icons/qm/secondary_navigation_handle_exit.png')"
          class="tw-w-[60px] tw-flex tw-cursor-pointer"
          alt="Navigation Handle Exit"
        >
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import { ref } from 'vue';
import gsap from 'gsap';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  menuItemsData: Array<any>,
  offsetY?: number,
  handleClick?: () => void,
  doEmit?: boolean,
  notTranslate?: boolean,
}>();

const isButtonOpen = ref<boolean>(false);

const menuItems = ref <Array<any>>([]);
const maxItemWidth = ref<number>(0); // Default width

const emit = defineEmits(['selectStory']);



const onBeforeEnter = (el: any) => {
  el.style.opacity = 0;
  el.style.height = 0;
};

const onEnter = (el: any, done: any) => {
  gsap.fromTo(el, {
    opacity: 0,
    height: 0,
    duration: 0.2,
    y: '100%',
    delay: el.dataset.index * 0.1,
  }, {
    opacity: 1,
    height: '24px',
    duration: 0.2,
    y: 0,
    delay: el.dataset.index * 0.1,
    onComplete: done
  });

  const secondNavContainer = document.querySelector('.second-nav-container');

  if(secondNavContainer){
    // set item min width base on longest item
    const containerWidth = secondNavContainer.getBoundingClientRect().width;
    maxItemWidth.value = containerWidth;
  }


};

const onLeave = (el: any, done: any) => {
  gsap.to(el, {
    opacity: 0,
    height: 0,
    duration: 0.2,
    delay: el.dataset.index * 0.1,
    onComplete: done
  });
};

const handleClick = (id: string) => {
  if (props.doEmit === true) {
    emit('selectStory', id);
  } else {
    handleScroll(id);
  }
};

const handleScroll = (id: string) => {
  // document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  // if(!!props.offsetY) {
  //   window.scrollBy(0, -props.offsetY);

  // }

  const element = document.getElementById(id);
  const topNav = document.getElementById('top-nav');
  const topNavHeight = topNav!.getBoundingClientRect().height!;
  const temp = props.offsetY ?? topNavHeight;
  if (element) {
    const elementRect = element.getBoundingClientRect().top;
    const bodyRect = document.body.getBoundingClientRect().top;
    const offset = elementRect - bodyRect - temp;

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

};


const handleToggleButton = () => {
  isButtonOpen.value = !isButtonOpen.value;


  if(menuItems.value.length == 0) {
    menuItems.value = props.menuItemsData;
  } else {
    menuItems.value = [];

  }

};
</script>
