<template>
  <form
    class="tw-flex tw-flex-col tw-p-8 tw-w-full tw-max-w-[600px] tw-gap-5"
    @submit.prevent="submitForm"
  >
    <!-- Name Field -->
    <section>
      <label
        class="tw-block tw-text-[#C52600] tw-mb-2 tw-flex tw-items-center tw-justify-start"
        :class="dynamicFontClass"
        for="name"
      >
        <span>{{ $t('cny_family_fun_name') }}</span>
        <img
          v-if="errorRefs.name"
          :src="$cdn('icons/alert-red.svg')"
          class="tw-w-5 tw-h-5 tw-ml-2"
          alt="Warning"
        >
      </label>
      <input
        id="name"
        v-model="application.name"
        class="tw-w-full tw-p-3 tw-bg-white tw-rounded-[8px]"
        type="text"
        :placeholder="$t('cny_family_fun_name')"
        @blur="validateApplication"
      >
    </section>

    <!-- Contact Field -->
    <section>
      <label
        class="tw-block tw-text-[#C52600] tw-mb-2 tw-flex tw-items-center tw-justify-start"
        :class="dynamicFontClass"
        for="contact"
      >
        <span>{{ $t('cny_family_fun_contact') }}</span>
        <img
          v-if="errorRefs.contact"
          :src="$cdn('icons/alert-red.svg')"
          class="tw-w-5 tw-h-5 tw-ml-2"
          alt="Warning"
        >
      </label>
      <input
        id="contact"
        v-model="application.contact"
        class="tw-w-full tw-p-3 tw-bg-white tw-rounded-[8px]"
        type="tel"
        :maxlength="maxContactLength"
        :placeholder="$t('cny_family_fun_contact_hint')"
        @input="handleContactInput"
        @blur="validateApplication"
      >
    </section>

    <!-- Email Field -->
    <section>
      <label
        class="tw-block tw-text-[#C52600] tw-mb-2 tw-flex tw-items-center tw-justify-start"
        :class="dynamicFontClass"
        for="email"
      >
        <span>{{ $t('cny_family_fun_email') }}</span>
        <img
          v-if="errorRefs.email"
          :src="$cdn('icons/alert-red.svg')"
          class="tw-w-5 tw-h-5 tw-ml-2"
          alt="Warning"
        >
      </label>
      <input
        id="email"
        v-model="application.email"
        class="tw-w-full tw-p-3 tw-bg-white tw-rounded-[8px]"
        type="email"
        :placeholder="$t('cny_family_fun_email')"
        @blur="validateApplication"
      >
    </section>

    <!-- Upload File Field -->
    <section>
      <label
        class="tw-block tw-text-[#C52600] tw-mb-2 tw-flex tw-items-center tw-justify-start"
        :class="dynamicFontClass"
      >
        <span>{{ $t('cny_family_fun_upload_file') }}</span>
        <img
          v-if="errorRefs.files"
          :src="$cdn('icons/alert-red.svg')"
          class="tw-w-5 tw-h-5 tw-ml-2"
          alt="Warning"
        >
      </label>
      <div
        :class="[
          'tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-text-center tw-gap-2',
          'tw-rounded-[8px] tw-py-[28px] tw-px-3',
          isDragging ? 'tw-border-2 tw-border-dashed tw-border-blue-500' : ''
        ]"
        @dragover.prevent="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDrop"
      >
        <button
          :class="[
            'tw-text-white',
            'tw-bg-[#0366D8] hover:tw-bg-[#66ACFD] active:tw-bg-[#024695]',
            'tw-py-2 tw-px-6',
            'tw-rounded-[26px]',
            dynamicFontClass
          ]"
          type="button"
          @click="onClickBrowseFiles"
        >
          {{ $t('cny_family_fun_browse_files') }}
        </button>
        <p class="tw-text-[#A3A3A3] tw-text-sg-cny25-c1">
          {{ $t('cny_family_fun_drop_them_here') }}
        </p>
        <p class="tw-text-[#A3A3A3] tw-text-sg-cny25-c1">
          {{ $t('cny_family_fun_file_meta') }}
        </p>
      </div>
      <div
        v-if="application.files.length > 0"
        :class="[
          'tw-flex tw-flex-col'
        ]"
      >
        <div
          v-for="(file, index) in application.files"
          :key="index"
          :class="[
            'tw-flex tw-justify-between tw-items-center tw-mt-2',
            'tw-bg-[#FFF9EE] tw-pl-2 tw-py-1 tw-pr-1 tw-rounded-[8px]'
          ]"
        >
          <p class="tw-text-[#1A1A1A] tw-text-sg-cny25-c1">
            {{ truncateFilename(file.name, 40) }}
          </p>
          <div
            v-if="isFileSizeValid(file)"
            :class="['tw-flex tw-flex-row tw-items-center tw-gap-3']"
          >
            <p class="tw-text-[#1A1A1A] tw-text-sg-cny25-c1">
              {{ formatFileSize(file.size) }}
            </p>
            <button
              class="tw-text-[#C52600] tw-text-sg-cny25-c1"
              type="button"
              :title="$t('cny_family_fun_remove_file')"
              @click="onClickRemoveFile(index)"
            >
              <img
                :src="$cdn('icons/x_icon_grey.svg')"
                class="tw-w-5 tw-h-5"
              >
            </button>
          </div>
          <div
            v-else
            :class="['tw-flex tw-flex-row tw-items-center tw-gap-3']"
          >
            <p class="tw-text-[#E4100E] tw-text-sg-cny25-c1">
              {{ $t('cny_family_fun_size_limit') }}
            </p>
            <button
              class="tw-text-[#C52600] tw-text-sg-cny25-c1"
              type="button"
              :title="$t('cny_family_fun_remove_file')"
              @click="onClickRemoveFile(index)"
            >
              <img
                :src="$cdn('icons/alert-red.svg')"
                class="tw-w-5 tw-h-5"
              >
            </button>
          </div>

        </div>
      </div>
      <p
        v-else
        class="tw-text-center tw-text-[#767676] tw-text-sg-cny25-c1 tw-mt-2"
      >
        {{ $t('cny_family_fun_no_file_upload') }}
      </p>
    </section>


    <!-- Text Area -->
    <section>
      <label
        class="tw-block tw-text-[#C52600] tw-mb-2 tw-flex tw-items-center tw-justify-start"
        :class="dynamicFontClass"
        for="about-work"
      >
        <span>{{ $t('cny_family_fun_tell_us') }}</span>
        <img
          v-if="errorRefs.aboutWork"
          :src="$cdn('icons/alert-red.svg')"
          class="tw-w-5 tw-h-5 tw-ml-2"
          alt="Warning"
        >
      </label>
      <textarea
        id="about-work"
        v-model="application.aboutWork"
        :class="[
          'tw-h-[200px]',
          'tw-w-full tw-px-3 tw-py-2 tw-rounded-[8px] tw-bg-white'
        ]"
        rows="5"
        style="resize: none;"
        :placeholder="$t('cny_family_fun_tell_us')"
        @blur="validateApplication"
      />
    </section>

    <!-- Checkbox -->
    <div class="tw-flex tw-items-start">
      <!-- Checkbox Input -->
      <picture
        class="tw-cursor-pointer tw-w-[18px] tw-min-w-[18px] tw-h-[18px] tw-box-border tw-mt-1 tw-flex tw-justify-center tw-items-center tw-overflow-visible"
        @click="onClickAcceptTerms"
      >
        <img
          v-if="acceptTermsError"
          class="tw-w-full tw-h-full tw-object-cover"
          :src="$cdn('icons/check_box_outline_blank_error.svg')"
        >
        <img
          v-else-if="application.acceptTerms"
          class="tw-w-full tw-h-full tw-object-cover"
          :src="$cdn('icons/check_box_green.svg')"
        >
        <img
          v-else
          class="tw-w-full tw-h-full tw-object-cover"
          :src="$cdn('icons/check_box_outline_blank.svg')"
        >
      </picture>
      <!-- Label -->
      <label
        for="terms"
        :class="[
          'term-condition tw-ml-3 tw-cursor-pointer',
          acceptTermsError ? 'tw-text-[#C52600]' : 'tw-text-[#1A1A1A]'
        ]"
        v-html="$t('cny_family_fun_accept_acknowledge')"
      />
    </div>


    <!-- Error Message -->
    <div
      v-if="errorMessage"
      class="tw-flex tw-items-center tw-text-[#C52600] tw-mb-4 tw-gap-3"
    >
      <img :src="$cdn('icons/alert-red.svg')">
      <p>{{ errorMessage }}</p>
    </div>
  </form>

</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { checkLang, truncateFilename, formatFileSize, isFileSizeValid } from '../../../support/helper';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
const { t } = useI18n();

interface ApplicationModel {
  name: string;
  contact: string;
  email: string;
  files: File[];
  aboutWork: string;
  acceptTerms: boolean;
}

const errorMessage = ref('');
const dynamicFontClass = computed(() => {
  return checkLang() === 'en' ?
    'tw-text-sg-cny25-sh3 tw-font-akkuratbold' :
    'tw-text-sg-cny25-sh3 tw-font-notosansbold';
});

defineExpose({ submitForm });
const emit = defineEmits(['success', 'error', 'sending', 'validate']);

const application = ref<ApplicationModel>({
  name: '',
  contact: '',
  email: '',
  files: [],
  aboutWork: '',
  acceptTerms: false,
});

const errorRefs = ref({
  name: false,
  contact: false,
  email: false,
  files: false,
  aboutWork: false,
  acceptTerms: false
});

async function submitFormWithFiles() {
  const form = new FormData();

  try {
    // Add files to the request with renamed filenames
    application.value.files.forEach((file: any, index: number) => {
      try {
        // Generate unique epoch-based filename
        const timestamp = Date.now();

        // Extract file extension (take only the part after the last dot)
        const fileParts = file.name.split('.');
        const fileExtension = fileParts.length > 1 ? fileParts.pop() : ''; // Get extension or empty if no dot
        const uniqueFileName = `${timestamp}-${index}.${fileExtension}`;

        // Create a new file object with the renamed filename
        const renamedFile = new File([file], uniqueFileName, {
          type: file.type,
        });

        // Append renamed file to FormData
        form.append(`files[${index}]`, renamedFile);
      } catch (fileError) {
        console.error(`Error processing file: ${file.name}`, fileError);
        throw fileError;
      }
    });

    // Add other form fields
    form.append('name', application.value.name);
    form.append('contact', application.value.contact);
    form.append('email', application.value.email);
    form.append('tell_us_about_your_work', application.value.aboutWork);

    emit('sending');

    // Check form key
    let formKey = document.cookie
      .split('; ')
      .find((row) => row.startsWith('cny25_form_key='))
      ?.split('=')[1];

    if (!formKey) {
      await axios.post(process.env.APP_URL + '/wp-json/cny25/v1/verify');
    }

    // Submit the form
    const response = await axios.post(
      process.env.APP_URL + '/wp-json/cny25/v1/submit-form',
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    false && console.log('Form submitted successfully:', response.data);
    emit('success');
  } catch (error: any) {
    emit('error', error);
    console.error(
      'Form submission error:',
      error.response?.data || error.message
    );
  }
}

// Reactive state to track dragging
const isDragging = ref(false);

const acceptTermsError = ref(false);

function handleFiles(docs: FileList) {
  if (application.value.files.length > 4) {
    alert(t('cny_family_fun_upload_limit'));
    return;
  }
  const newFiles = Array.from(docs); // Convert FileList to an array
  const uniqueFiles = newFiles.filter(
    (file) => !application.value.files.some((existingFile) => existingFile.name === file.name)
  ); // Avoid duplicate files
  application.value.files.push(...uniqueFiles); // Add unique files to the array
  validateApplication();
}

function onClickAcceptTerms() {
  application.value.acceptTerms = !application.value.acceptTerms;
  acceptTermsError.value = false;
  validateApplication();
}

function onClickBrowseFiles() {
  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = true;

  // Limit to images and PDFs
  input.accept = 'image/*,application/pdf';

  input.click();
  input.addEventListener('change', (event: any) => {
    if (event.target?.files) {
      handleFiles(event.target.files);
    }
  });
}

function onClickRemoveFile(index:any) {
  application.value.files.splice(index, 1);
  validateApplication();
}

function onDragOver(event:any) {
  isDragging.value = true;
}

function onDragLeave() {
  isDragging.value = false;
}

function onDrop(event:any) {
  event.preventDefault();
  isDragging.value = false;

  const files = event.dataTransfer.files;
  handleFiles(files);
}

// Validation Function
function validateApplication(): boolean {

  // Clear previous error
  errorMessage.value = '';

  errorRefs.value = {
    name: false,
    contact: false,
    email: false,
    files: false,
    aboutWork: false,
    acceptTerms: false
  };

  if (application.value.contact.trim() == '+65') {
    application.value.contact = '';
  }

  // Validate name
  if (!application.value.name.trim()) {
    errorMessage.value = t('cny_family_fun_information_incomplete');
    errorRefs.value.name = true;
    emit('validate', false);
    return false;
  }

  if (!application.value.contact.trim()) {
    errorMessage.value = t('cny_family_fun_information_incomplete');
    errorRefs.value.contact = true;
    emit('validate', false);
    return false;
  }

  if (application.value.contact.length < 10) {
    errorMessage.value = t('cny_family_fun_information_incomplete');
    errorRefs.value.contact = true;
    emit('validate', false);
    return false;
  }

  if (!application.value.email.trim()) {
    errorMessage.value = t('cny_family_fun_information_incomplete');
    errorRefs.value.email = true;
    emit('validate', false);
    return false;
  }

  // Validate email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!application.value.email.trim() || !emailRegex.test(application.value.email)) {
    errorMessage.value = t('cny_family_fun_invalid_email');
    errorRefs.value.email = true;
    emit('validate', false);
    return false;
  }

  // Validate files
  if (application.value.files.length === 0) {
    errorMessage.value = t('cny_family_fun_at_least_one');
    errorRefs.value.files = true;
    emit('validate', false);
    return false;
  } else {
    const invalidFiles = application.value.files.filter((file) => file.size > 10 * 1024 * 1024);
    if (invalidFiles.length > 0) {
      errorMessage.value = `${t('cny_family_fun_some_exceed')} ${invalidFiles
        .map((f) => f.name)
        .join(', ')}.`;
      errorRefs.value.files = true;
      emit('validate', false);
      return false;
    }
  }

  // Validate aboutWork
  if (!application.value.aboutWork.trim()) {
    errorMessage.value = t('cny_family_fun_please_tell');
    errorRefs.value.aboutWork = true;
    emit('validate', false);
    return false;
  }

  // Validate acceptTerms
  if (!application.value.acceptTerms) {
    errorMessage.value = t('cny_family_fun_must_accept');
    acceptTermsError.value = true;
    emit('validate', false);
    return false;
  }

  emit('validate', true);
  return true; // No errors found
}

const maxContactLength = 15;
// Validate input: only allow numbers and enforce max length
function handleContactInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value;

  // Handle the edge case where only "+65" remains
  if (value === '+6') {
    application.value.contact = '+65';
    return;
  }

  // Ensure the value always starts with "+65"
  if (!value.startsWith('+65')) {
    value = '+65' + value.replace(/[^0-9]/g, ''); // Remove any non-numeric characters and add prefix
  }

  // Allow only 8 digits after "+65"
  const numberPart = value.slice(3).replace(/[^0-9]/g, ''); // Remove non-numeric characters
  value = '+65' + numberPart.slice(0, 8); // Limit to 8 digits after prefix

  // Update the input value
  application.value.contact = value;
}

// Form Submission
function submitForm() {
  if (validateApplication()) {
    submitFormWithFiles();
  } else {
    emit('error');
  }
}
</script>

<style scoped>
.term-condition :deep(a) {
  color: #0366D8;
  cursor: pointer;
  text-decoration: underline;
}
</style>
