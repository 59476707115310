<script lang="ts" setup>
import { cdn } from '../../support/cdn';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
import { onBeforeMount, ref } from 'vue';
import { onMounted, nextTick, onBeforeUnmount } from 'vue';
const props = defineProps<{
  wordpressData: any;
}>();
const { t } = useI18n();
const imageSourceOpen = ref<boolean>(false);

const menuItems = [
  {
    label: 'qm_home_menu_through_the_years',
    id: 'through-the-years-section',
  },
  {
    label: 'qm_home_menu_origin_stories',
    id: 'origin-stories-section',
  },
];

const timeline_post = ref([]);
const imageSourcesData = ref();
onBeforeMount(() => {
  if (props.wordpressData?.timeline) {
    timeline_post.value = props.wordpressData.timeline;
  }

  if (props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const aboutDesc = document.getElementById('qm-home');
    if (aboutDesc) {
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      const supElements = aboutDesc.querySelectorAll('#qm-home sup');
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
  // Remove <sup> click listener
  const burningJossCard = document.getElementById('qm-home');

  if (burningJossCard) {
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    const supElements = burningJossCard.querySelectorAll('#qm-home sup');
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :qm-navigation="true"
    />
    <qm-secondary-navigation :menu-items-data="menuItems" />
    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/qm2024/banner.scaled.jpg')"
          class="tw-w-full tw-h-[93vh] tw-object-cover"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-top-[120px] sgTablet:tw-top-[160px] sgDesktop:tw-top-[200px]">
        <div class="tw-text-white tw-text-center">
          <img
            class="tw-pb-[10px] tw-h-[40px] sgTablet:tw-h-[60px] tw-mx-auto"
            :src="$cdn('images/qm2024/header.png')"
          >
          <div
            class="tw-text-sg-h1-mobile-qm sgTablet:tw-text-sg-h1-tablet-qm sgDesktop:tw-text-sg-h1-qm tw-pb-1 tw-drop-shadow-xl"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('qm_home_title') }}
          </div>
        </div>
      </div>
    </div>
    <section class="tw-px-4 tw-py-5 sgTablet:tw-p-10">
      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-center">
        <div
          id="qm-home"
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider"
          v-html="wordpressData.description"
        />
      </div>
    </section>
    <section
      id="through-the-years-section"
      class="tw-bg-gradient-to-t tw-from-sg-qm25-light-orange tw-to-sg-qm24-light-green-w60"
    >
      <div class="tw-px-4 tw-pt-10 tw-pb-8 sgTablet:tw-px-20 sgTablet:tw-pb-[60px] tw-text-sg-qm25-green-b60">
        <div
          class=" tw-text-center !tw-text-sg-h2-mobile-qm sgTablet:!tw-text-sg-h2-tablet-qm sgDesktop:!tw-text-sg-h2-qm"
          :class="
            checkLang() == 'zh-hans'
              ? 'tw-font-notosansbold'
              : 'tw-font-akkuratbold tw-text-sg-sh1-mobile-qm sgTablet:tw-text-sg-sh1-tablet-qm sgDesktop:tw-text-sg-sh1-qm'
          "
          v-html="$t('qm_home_section_2_title')"
        />
        <p class="!tw-text-sg-body-1-qm tw-tracking-wider tw-text-center tw-text-sg-qm24-green-b20">
          {{ $t('qm_home_section_2_desc') }}
        </p>
      </div>
      <qm-home-swiper
        v-model:image-source-open="imageSourceOpen"
        :data="timeline_post"
      />
    </section>
    <section id="origin-stories-section">
      <div>
        <div class="tw-flex tw-flex-col sgTablet2:tw-flex-row tw-justify-center">
          <div class="tw-flex">
            <img
              :src="cdn('images/qm2024/home/mountain.png')"
              class="tw-w-full tw-h-[332px] sgTablet:tw-h-[440px] sgTablet2:tw-h-[800px] sgTablet2:tw-w-[512px] sgDesktop:tw-w-[min(720px,60vw)] tw-max-w-none tw-place-self-center tw-object-contain"
            >
          </div>
          <div class="tw-px-4 sgTablet:tw-px-20 sgTablet2:tw-pl-5 sgDesktop:tw-pl-10 tw-flex tw-py-5 sgTablet:tw-py-10">
            <div class="tw-place-self-center sgDesktop:tw-max-w-[560px]">
              <div
                class="tw-text-sg-h3-mobile-qm-title sgTablet:tw-text-sg-h3-tablet-qm-title sgDesktop:tw-text-sg-h3-qm-title"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('qm_home_section_3_title') }}
              </div>

              <p
                class="tw-mt-5 sgDesktop:tw-mt-10 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider "
                v-html="t('qm_home_section_3_desc_1')"
              />
              <p class="tw-mt-4 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider">
                {{ $t('qm_home_section_3_desc_2') }}
              </p>
              <div class="tw-flex tw-mt-10 tw-mr-5">
                <a
                  :href="$langRedirect('/festivals/qingming/origin-stories')"
                  target="_blank"
                  rel="noopener"
                  class="tw-rounded-[26px] tw-text-white tw-bg-sg-qm24-green tw-px-6 tw-py-4 tw-text-sg-button-1-qm tablet:tw-text-sg-button-1-qm !tw-leading-[20px] tw-h-[52px] tw-uppercase"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  {{ $t('qm_home_discover_more') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style lang="scss">
#qm-about sup {
  @apply tw-text-[#0366D8] tw-cursor-pointer;
}
</style>
