<template>
  <div
    class="tw-w-full"
    :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <swiper
      id="unique-dumplings-swiper"
      :slides-per-view="'auto'"
      :space-between="20"
      :modules="[FreeMode, Mousewheel, Autoplay]"
      :free-mode="{
        enabled: true,
        momentum: false,
      }"
      :autoplay="{
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
        delay: 1,
        waitForTransition: true,
      }"
      :speed="5000"
      loop
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in data"
        :key="index"
        class="tw-w-[248px] sgTablet:tw-w-[280px] sgDesktop:tw-w-[300px]"
      >
        <img
          :src="item.image"
          class="tw-h-[248px] tw-w-[248px] sgTablet:tw-h-[280px] sgTablet:tw-w-[280px] sgDesktop:tw-h-[300px] sgDesktop:tw-w-[300px] tw-object-cover tw-object-center"
        >
        <div class="tw-py-4 sgTablet:tw-pb-5 tw-px-4">
          <div
            class="tw-text-sg-sh3-mobile sgTablet:tw-text-sg-sh3-tablet sgDesktop:tw-text-sg-sh3 tw-min-h-[80px] sgTablet:tw-min-h-[60px] tw-line-clamp-4 sgTablet:tw-line-clamp-3 tw-text-sg-dw24-blue-b60"
            :title="item.description"
          >
            {{ item.description }}
            <span v-if="item.reference">
              <sup
                class="tw-cursor-pointer tw-text-[#0366D8]"
                @click="handleOpenImageSourceModal"
              >[{{ item.reference }}]</sup>
            </span>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import type { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { FreeMode, Mousewheel, Autoplay } from 'swiper/modules';
import { useI18n } from 'vue-i18n';

import { onMounted, toRefs, ref, Ref } from 'vue';
import { computed } from 'vue';
import { checkLang } from '../../support/helper';
const { t } = useI18n();
const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper: any) => {
  swiperInstance.value = swiper;
};
const onMouseEnter = () => {
  swiperInstance.value?.autoplay.stop();
};
const onMouseLeave = () => {
  swiperInstance.value?.autoplay.start();
};

const props = defineProps<{
  list?: any[];
  handleOpenImageSourceModal: any;
}>();
const data = computed(() => {
  // Loop to 12 slides
  if (!props.list || props?.list?.length == 0) return [];

  const neededCount = props.list.length > 12 ? props.list.length : 12;

  const copyCount = Math.floor(neededCount / props.list.length);
  return Array.from({ length: copyCount }).flatMap(() => props.list);
});
</script>
<style lang="scss">
#unique-dumplings-swiper .swiper-wrapper {
  @apply tw-ease-linear;
}
</style>
