<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-navigation="true"
    />

    <secondary-navigation :menu-items-data="menuItems" />

    <!-- Header -->
    <section>
      <div class="tw-relative">
        <picture>
          <source
            media="(max-width: 375px)"
            :srcset="$cdn('images/cny2024/banner_375.png')"
          >
          <source
            media="(max-width: 680px)"
            :srcset="$cdn('images/cny2024/banner_680.png')"
          >
          <source
            media="(max-width: 800px)"
            :srcset="$cdn('images/cny2024/banner_800.png')"
          >
          <source
            media="(max-width: 1025px)"
            :srcset="$cdn('images/cny2024/banner_1025.png')"
          >
          <img
            :src="$cdn('images/cny2024/banner_1440.png')"
            class="tw-w-full tw-object-cover"
          >
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny tw-pb-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_visiting_101') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-mt-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_good_luck_guides') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="tw-max-w-[800px] tw-m-auto tw-pt-10 tw-px-4 sgTablet:tw-px-0 tw-text-sg-tablet-3 tw-w-full tw-break-words"
      v-html="wordpressData.description"
    />
    <!-- <p class="tw-text-sg-label-3">
        {{ $t('cny_good_luck_paragraph1') }}
      </p>
      <br>
      <p class="tw-text-sg-label-3">
        {{ $t('cny_good_luck_paragraph2') }}
      </p>
      <br>
      <p class="tw-text-sg-caption-italic tw-italic">
        {{ $t('cny_good_luck_disclaimer') }}
      </p> -->

    <div class="tw-pt-[70px]">
      <cny-swiper
        swiper-type="do-these"
        custom-id="do-these"
      />
      <cny-swiper
        swiper-type="taboos"
        custom-id="taboos"
      />
      <cny-swiper
        swiper-type="do-and-dont"
        custom-id="do-and-dont"
      />
    </div>
    <!-- <div class="tw-max-w-[800px] tw-m-auto tw-py-5 tw-px-4 sgTablet:tw-py-10">
      <p class="tw-text-sg-caption-italic tw-italic">
        {{ $t('cny_good_luck_story_extract') }}
      </p>
      <br>
      <ol class="tw-text-sg-caption">
        <li class="tw-pb-1">
          1.
          <a
            href="http://rportal.lib.ntnu.edu.tw:8080/server/api/core/bitstreams/4fd9dd4f-8fea-4de9-87bb-47729a9caf39/content"
            target="_blank"
            rel="noopener"
          >國立臺灣師範大學 - 岁时节俗</a>
        </li>
        <li class="tw-pb-1">
          2.
          <a
            href="https://www.scmp.com/yp/discover/advice/personal-development/article/3060380/9-chinese-new-year-taboos-avoid"
            target="_blank"
            rel="noopener"
          >SCMP - 9 Chinese New Year taboos to avoid</a>
        </li>
        <li class="tw-pb-1">
          3.
          <a
            href="https://www.scmp.com/yp/discover/lifestyle/article/3165166/chinese-new-year-different-days-new-year-and-what-each-them"
            target="_blank"
            rel="noopener"
          >SCMP - Chinese New Year: The different days of the new year and what each of them mean</a>
        </li>
        <li class="tw-pb-1">
          4. <a
            href="https://www.wayfengshui.com/chinese-new-year-dos-and-donts/"
            target="_blank"
            rel="noopener"
          >Way Fengshui - Chinese New Year Dos and Don’ts</a>
        </li>
        <li class="tw-pb-1">
          5.
          <a
            href="https://ex.chinadaily.com.cn/exchange/partners/82/rss/channel/language/columns/v0m20b/stories/WS602c6a93a31024ad0baa91f3.html"
            target="_blank"
            rel="noopener"
          >China Daily - 红包在中国文化中的意义</a>
        </li>
        <li class="tw-pb-1">
          6. <a
            href="https://books.google.com.sg/books?id=F3XYDwAAQBAJ&printsec=frontcover#v=onepage&q&f=false"
            target="_blank"
            rel="noopener"
          >陳雅芳 - 華人社會與文化</a>
        </li>
        <li class="tw-pb-1">
          7. <a
            href="https://sfcca.sg/en/publications-others/"
            target="_blank"
            rel="noopener"
          >新加坡宗乡会馆联合总会 - 《华人礼俗节日手册》</a>
        </li>
      </ol>
      <br>
      <p class="tw-text-sg-caption-italic tw-italic">
        {{ $t('cny_good_luck_story_disclaimer2') }}
      </p> -->
    <!-- </div> -->

    <!-- Image Source -->
    <ap-wysiwyg-viewer
      v-if="!!imageSources"
      :data="imageSources"
      class="tw-py-[20px] tw-px-4 tablet:tw-px-10 tablet:tw-py-[40px] tw-grid-cols-1 desktop:tw-px-20 desktop:tw-py-[60px]"
    />

    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref, toRefs, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../../support/helper';
const { t } = useI18n();

const props = defineProps<{
  wordpressData: any;
}>();

const imageSources = computed(() => props?.wordpressData?.image_sources);

const menuItems = [
  {
    label: 'cny_good_luck_menu_do_these',
    id: 'do-these',
  },
  {
    label: 'cny_good_luck_menu_taboos',
    id: 'taboos',
  },
  {
    label: 'cny_good_luck_menu_do_donts',
    id: 'do-and-dont',
  },
];
</script>
